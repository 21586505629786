import LifeInsurance from "./LifeInsurance.vm";

export const LifeInsurancePages = {
  lifeInsurance: {
    anon: false,
    path: "/life-insurance/:scheduleId?",
    title: "Life Insurance",
    type: "PAGE_LIFE_INSURANCE",
    view: LifeInsurance,
  },
};
export default LifeInsurancePages;

export const LifeInsuranceArea = {
  pages: LifeInsurancePages,
};
