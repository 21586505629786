import React from "react";

export default () => (
  <div className="kinus-page-form-state">
    <img src="/images/enrollmentComplete.svg" alt="" height="240" />
    <p className="fw-700 mt-24" style={{ fontSize: "34px" }}>
      Thank you!
    </p>
    <p className="mt-16 large-text">
      Your registration was completed successfully.
    </p>
  </div>
);
