import React from "react";
import ErrorDisplay from "../../../components/ErrorDisplay";
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import ShliachDetails from "./ShliachDetails";
import { AuthRequest, Navigation, PageNavLink } from "../../../lib";
import pages from "../../../pages";

export default class ProgramRegistrationModal extends React.PureComponent {
  state = {
    didConfirmProfileDetails: false,
    iframeLoading: false,
    profileDetails: null,
    profileDetailsError: null,
    profileDetailsLoading: false,
  };

  componentDidMount() {
    this.getProfileDetails();
  }

  componentDidUpdate(prevProps) {
    //attempt to re-retrieve profile details on show if previously errored
    if (this.props.show && !prevProps.show && this.state.profileDetailsError) {
      this.getProfileDetails();
    }
    //reset account details confirmation on hide modal
    else if (
      !this.props.show &&
      prevProps.show &&
      this.state.didConfirmProfileDetails
    ) {
      this.setState({ didConfirmProfileDetails: false });
    }
  }

  getProfileDetails = async () => {
    this.setState({ profileDetailsLoading: true });

    try {
      const response = await AuthRequest.get(
        `Shluchim/${this.props.shliachID}/fullProfile`,
      );
      this.setState({ profileDetails: response.data.payload });
    } catch (err) {
      this.setState({ profileDetailsError: err });
    }

    this.setState({ profileDetailsLoading: false });
  };

  onConfirmProfile = async () => {
    // I don't want to catch errors because if there is an error than it will just pop up again the next time...
    await AuthRequest.post(
      `shluchim/${this.props.shliachID}/accountLastReviewedDate`,
      new Date(),
    );
    this.setState({
      didConfirmProfileDetails: true,
      iframeLoading: true,
    });
  };

  render() {
    const { close, registrationURL, show, title } = this.props;
    const {
      didConfirmProfileDetails,
      iframeLoading,
      profileDetails,
      profileDetailsError,
      profileDetailsLoading,
    } = this.state;

    return (
      <Modal show={show}>
        <div className="modal-container full-page-mobile-modal-container">
          <div className="modal modal-card card program-jotform-modal">
            <div className="flex flex-justify-end mobile-flex-justify-start mb-8 program-jotform-modal-header">
              <i
                className="material-icons pointer link-text-secondary"
                onClick={close}
              >
                close
              </i>
            </div>
            {profileDetailsLoading ? (
              <Loader />
            ) : profileDetailsError ? (
              <ErrorDisplay errorMessage="Something went wrong and we could not account information. Please try again." />
            ) : !didConfirmProfileDetails &&
              !!profileDetails &&
              profileDetails.needsReview ? (
              <div className="shliach-details">
                <p className="medium-text fw-700">
                  Review your account information before proceeding
                </p>
                <ShliachDetails details={profileDetails} />
                <div className="shliach-details-buttons flex flex-align-center flex-justify-end">
                  <PageNavLink
                    className="link-text"
                    to={pages.profile.profile}
                    query={{ after: Navigation.locationURL + "&register=true" }}
                  >
                    Update my account information
                  </PageNavLink>
                  <p
                    className="btn btn-accent text-center ml-24"
                    onClick={this.onConfirmProfile}
                    style={{ width: "140px" }}
                  >
                    Looks good
                  </p>
                </div>
              </div>
            ) : (
              <React.Fragment>
                {iframeLoading && (
                  <div style={{ padding: "16px 0px" }}>
                    <Loader />
                  </div>
                )}
                <iframe
                  onLoad={() => this.setState({ iframeLoading: false })}
                  src={registrationURL}
                  style={{
                    height: "calc(100% - 24px)",
                    width: "100%",
                  }}
                  title={title}
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
