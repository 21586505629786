import React from "react";

export default class NoEduAccess extends React.PureComponent {
  render() {
    const { eduProgramName, eduLogoSrc } = this.props;

    return (
      <div className="flex flex-align-center flex-justify-center full-page-state">
        {eduLogoSrc && <img src={eduLogoSrc} alt="" height="100" />}
        <p className="fw-700 xxl-text mt-32 mb-40">
          You do not have access to {eduProgramName}
        </p>
        <p className="accent-text large-text">
          Contact your administrator to request access
        </p>
      </div>
    );
  }
}
