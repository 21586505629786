import React from "react";
import { Navigation, PageURL } from "../../../../../../lib";

export default class CourseScheduleTabs extends React.PureComponent {
  componentDidMount() {
    if (!this.getCurrentTab()) {
      this.toTab(this.props.tabs[0].id, true); //redirect nav instead of pushing new route for initial load
    }
  }

  getCurrentTab = () => {
    const {
      pageRoute: {
        query: { tab },
      },
      tabs,
    } = this.props;
    return tabs.find((t) => t.id === tab);
  };

  toTab = (tabId, redirect) => {
    const {
      pageRoute: { query, page, params },
    } = this.props;

    const url = PageURL.to(page, params, {
      ...query,
      tab: tabId,
    });
    redirect ? Navigation.redirect(url) : Navigation.go(url);
  };

  render() {
    const {
      orderBooksURL,
      resourceURL,
      pageRoute: {
        query: { tab },
      },
      tabs,
    } = this.props;

    return (
      <div className="card edu-sidebar-card edu-sidebar-menu-card">
        <div className="edu-sidebar-card-navigation">
          {tabs.map((t, index) => (
            <p
              className={`fw-700 uppercase-text mb-24 link-text-secondary ${
                tab === t.id ? "active" : ""
              }`}
              key={index}
              onClick={() => this.toTab(t.id)}
            >
              {t.title}
            </p>
          ))}
          {orderBooksURL && (
            <p className="fw-700 uppercase-text link-text-secondary mb-24">
              <a
                className="flex flex-align-center"
                href={orderBooksURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Order Books
                <i className="material-icons accent-text-light large-text ml-8">
                  open_in_new
                </i>
              </a>
            </p>
          )}
          {resourceURL && (
            <p className="fw-700 uppercase-text link-text-secondary mb-24">
              <a
                className="flex flex-align-center"
                href={resourceURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Resources
                <i className="material-icons accent-text-light large-text ml-8">
                  open_in_new
                </i>
              </a>
            </p>
          )}
        </div>
      </div>
    );
  }
}
