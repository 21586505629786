import React from "react";
import Select from "react-select";
import { Box } from "@material-ui/core";

import { Navigation, PageURL } from "../../../lib";

const sortOptions = [
  { label: "Last Name", value: "DonorLastName" },
  { label: "Total Amount: Low-High", value: "TotalAmountAsc" },
  { label: "Total Amount: High-Low", value: "TotalAmountDesc" },
];

const timeframeOptions = [
  { label: "All Donors", value: "" },
  {
    label: "Donated Last Year (not this year)",
    value: "LastYearButNotThisYear",
  },
  {
    label: "Donated Any Year (not this year)",
    value: "AnyYearButNotThisYear",
  },
  {
    label: "Donated This Year",
    value: "ThisYear",
  },
];

export default function DonorsTableFilters(props: any) {
  const {
    pageRoute: { query, page, params },
  } = props;

  const onChange = async (name: any, val: any) => {
    const url = PageURL.to(page, params, {
      ...query,
      [name]: val || val === false ? encodeURIComponent(val) : undefined,
      p: 1,
    });
    Navigation.redirect(url);
  };

  const { so = "DonorLastName", tf = "" } = query;

  return (
    <Box
      className="flex flex-align-center mobile-block full-width"
      style={{ flexFlow: "wrap" }}
    >
      <Box className="flex flex-align-center mobile-ml-0 filter-width">
        <p className="mr-8 nowrap-text">Sort by:</p>
        <Select
          className="filter-select"
          classNamePrefix="filter-select"
          isSearchable={false}
          onChange={(val: any) => onChange("so", val.value)}
          options={sortOptions}
          value={sortOptions.find((s) => s.value === decodeURIComponent(so))}
        />
      </Box>
      <div>
        <Box className="flex flex-align-center mt-16 ml-24 mobile-ml-0 mb-4 donors-year-filter">
          <p className="mr-8 nowrap-text">Filter by:</p>
          <Select
            className="filter-select"
            classNamePrefix="filter-select"
            isSearchable={false}
            onChange={(val: any) => onChange("tf", val.value)}
            options={timeframeOptions}
            value={timeframeOptions.find(
              (s) => s.value === decodeURIComponent(tf),
            )}
          />
        </Box>
        <div className="ml-24 mobile-ml-0 nowrap-text small-text accent-text">
          To view donations for all years, check off View all Years
        </div>
      </div>
    </Box>
  );
}
