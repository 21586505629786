import React from "react";
import { formatFullName, PageLink } from "../../../../lib";
import Pages from "../../../../pages";
import GrantCard from "./GrantCard";

export default class StudentsListRow extends React.PureComponent {
  render() {
    const {
      grantsForDisplay,
      detailsOpened,
      didScheduleEnd,
      didScheduleStart,
      onToggleStudentGrant,
      onLogStudentInteraction,
      onSubmitStudentGrant,
      onSetPrimaryStudentGrant,
      student: {
        grants: studentGrants = [],
        studentCell,
        studentFirstName,
        studentID,
        studentLastName,
      },
      toggleMobileDetails,
    } = this.props;
    const isLamplightersActive = studentGrants.some(
      (s) => s.isEnrolledInThisGrant,
    );
    //show primary grant indication if there are 2 active unsubmitted grants
    const showPrimaryGrant =
      isLamplightersActive &&
      studentGrants.filter((s) => s.isEnrolledInThisGrant && !s.isSubmitted)
        .length > 1;

    return (
      <div
        className={`paginated-table-row lamplighters-students-table-row students-table-row small-text ${
          detailsOpened[studentID] === true ? "open" : "closed"
        }`}
        style={{
          gridTemplateColumns: `130px repeat(${grantsForDisplay.length}, 220px)`,
        }}
      >
        <div className="desktop-hidden tablet-hidden">
          <i
            className="material-icons accent-text-secondary medium-text pointer"
            onClick={() => toggleMobileDetails(studentID)}
          >
            {detailsOpened[studentID] ? "remove" : "add"}
          </i>
        </div>
        <div className="desktop-display-contents flex-justify-space full-width">
          <div className="desktop-display-contents ">
            <div className="fixed-column" style={{ fontSize: 14 }}>
              <PageLink
                className="link-text-secondary fw-600"
                params={{ studentId: studentID }}
                query={
                  isLamplightersActive && didScheduleStart && !didScheduleEnd //For a current schedule, Lamplighters-active students are directed to activity tab filtered to LL interactions, Inactive students who are directed to the Lamplighters tab
                    ? {
                        studentTab: "activity",
                        li: true,
                      }
                    : { studentTab: "lamplighters" }
                }
                to={Pages.students.studentDetails}
              >
                {formatFullName(studentFirstName, studentLastName)}
              </PageLink>
              <p
                className="flex flex-align-center mt-8"
                style={{ flexFlow: "wrap" }}
              >
                <a href={`tel:${studentCell}`}>{studentCell}</a>
              </p>
              {!didScheduleEnd && (
                <p
                  className="link-text uppercase-text flex flex-align-center mt-16"
                  onClick={onLogStudentInteraction}
                >
                  + Interaction
                </p>
              )}
            </div>
            <div className="student-details desktop-display-contents">
              {grantsForDisplay &&
                grantsForDisplay.map((grant, index) => {
                  const studentGrant = studentGrants.find(
                    (sg) => sg.grantID === grant.id,
                  );

                  if (!studentGrant) return <div key={index} />;

                  return (
                    <div className="student-grant-container" key={index}>
                      <p className="flex flex-align-center desktop-hidden tablet-hidden fw-700 mt-16 mb-8">
                        {grant.name}
                      </p>
                      <GrantCard
                        studentGrant={studentGrant}
                        onSubmitStudentGrant={onSubmitStudentGrant}
                        didScheduleEnd={didScheduleEnd}
                        onToggleStudentGrant={onToggleStudentGrant}
                        showPrimaryGrant={showPrimaryGrant}
                        onSetPrimaryStudentGrant={onSetPrimaryStudentGrant}
                        width={220}
                        shouldDisplaySubmittedScheduleTitle={false}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
