import React, { memo } from "react";
import { useSelector } from "react-redux";

import { IconButton } from "@material-ui/core";
import { Send } from "@material-ui/icons";

import Pages from "../pages";
import { Navigation, PROFILE_PLACEHOLDER_URL, PageLink } from "../lib";
import { AuthActions, AuthSelectors, useAction } from "../state";

interface ProfileDropdownProps {
  accountDropdownOpen: boolean;
  overrideProfileImageURL?: string;
  showAccountLink: boolean;
  showSendSmsIcon: boolean;
  toggleDropdown: () => void;
}

function ProfileDropdown(props: ProfileDropdownProps) {
  const {
    accountDropdownOpen,
    overrideProfileImageURL,
    showAccountLink,
    showSendSmsIcon,
    toggleDropdown,
  } = props;

  const { firstName, lastName, profileImageURL } = useSelector(
    AuthSelectors.user,
  );
  const doSignout = useAction(AuthActions.doSignout);

  return (
    <div className="account-dropdown">
      {showSendSmsIcon && (
        <IconButton
          className="mr-16"
          color="primary"
          onClick={() => {
            Navigation.go("/sms/wizard");
          }}
        >
          <Send />
        </IconButton>
      )}
      <div className="account-dropdown-header" onClick={toggleDropdown}>
        <img
          src={
            overrideProfileImageURL ||
            profileImageURL ||
            PROFILE_PLACEHOLDER_URL
          }
          alt="profile-pic"
          className="user-profile-pic"
        />
        <p className="user-name fw-700 header-user-name mobile-hidden">
          {firstName === null ? "Hello" : firstName ? `Hi, ${firstName}` : ""}
          <i className="material-icons">expand_more</i>
        </p>
      </div>
      <div
        className={`account-dropdown-box dropdown-box ${
          accountDropdownOpen ? "open" : ""
        }`}
      >
        <div className="account-dropdown-box-header">
          <img
            src={profileImageURL || PROFILE_PLACEHOLDER_URL}
            alt="profile-pic"
            className="user-profile-pic"
          />
          <div>
            <p className="user-name fw-700">
              {firstName} {lastName}
            </p>
            {showAccountLink &&
              Navigation.location.pathname.toLowerCase().indexOf("account") <
                0 && (
                <PageLink
                  to={Pages.profile.profile}
                  className="link-text small-text"
                >
                  View Account
                </PageLink>
              )}
          </div>
        </div>
        <div className="account-dropdown-box-body">
          <p>
            <span className="accent-text-dark block small-text mb-8">
              <a
                href="mailto:help@chabadoncampus.org"
                target="_blank"
                rel="noopener noreferrer"
                className="link-with-icon flex flex-align-center"
              >
                <i className="material-icons mr-8" style={{ fontSize: "15px" }}>
                  email
                </i>
                <span>help@chabadoncampus.org</span>
              </a>
            </span>
            <span className="accent-text-dark block mb-16 small-text">
              <a
                href="tel:718-510-8181"
                className="link-with-icon flex flex-align-center"
              >
                <i className="material-icons mr-8" style={{ fontSize: "15px" }}>
                  phone
                </i>
                <span>718-510-8181 ext 0</span>
              </a>
            </span>
          </p>
          <p className="fw-500" onClick={() => doSignout()}>
            Sign Out
          </p>
        </div>
      </div>
    </div>
  );
}

export default memo(ProfileDropdown);
