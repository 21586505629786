import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  makeStyles,
  Collapse,
  List,
  ListItemText,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import CopyToClipboardTextbox from "../../../components/CopyToClipboardTextbox";
import { RaffleSelectors } from "../../../state";
import {
  EmbedWebPageInstructionText,
  EmbedWebPageIframeText,
} from "../raffleUtils";
import { PageLink, Navigation } from "../../../lib";
import Pages from "../../../pages";

const useStyles = makeStyles((theme) => ({
  title: { marginBottom: 16 },
  container: { maxWidth: 950, padding: 12 },
}));

export default function EnrollmentCompleted() {
  const raffleTitle = useSelector(RaffleSelectors.raffleTitle);
  const donorSiteURL = useSelector(RaffleSelectors.donorSiteURL);
  const chabadHouseID = useSelector(RaffleSelectors.chabadHouseID);
  const donorSiteIframeText = useSelector(RaffleSelectors.donorSiteIframeText);
  const classes = useStyles();

  const [showExpanded, setShowExpanded] = useState(false);
  const handleToggleExpand = () => {
    setShowExpanded(!showExpanded);
  };

  return (
    <>
      <div className="text-center mb-48">
        <Typography variant="h3" className={classes.title}>
          Thank you for enrolling in {raffleTitle}
        </Typography>
        <p className="large-text mb-16">
          Take the next step to drive your campaign forward!
        </p>
      </div>
      <div className="two-column-grid raffle-mobile-grid">
        <div className="card raffle-enrollment-completed-card flex flex-column flex-justify-space">
          <div>
            <p className="large-text fw-900 mb-16 text-center">Create Teams</p>
            <div className="accent-text-dark mb-16 line-height-double medium-text">
              Engage friends and family to join your cause by forming teams to
              expand your reach and achieve your fundraising goals. Each team
              will have its own web page and goal.
            </div>
          </div>
          <div className="flex flex-justify-center text-center">
            <PageLink
              className="btn btn-accent btn-link"
              to={`${Navigation.location.pathname}?tab=teams`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Create a team{" "}
              <i className="material-icons medium-text ml-16">open_in_new</i>
            </PageLink>
          </div>
        </div>
        <div className="card raffle-enrollment-completed-card flex flex-column flex-justify-space">
          <p className="large-text fw-900 mb-16 text-center">
            View & Share your Web Page{" "}
          </p>
          <CopyToClipboardTextbox
            className="iframe-container copy-code-container flex flex-justify-space mb-16 accent-text-dark "
            id="raffle-url"
            label="Copy Link"
            multiline={true}
            text={donorSiteURL}
            style={{ fontSize: "16px", lineHeight: "1.4" }}
          />
          {!!chabadHouseID && (
            <p className="accent-text-dark mb-16 line-height-double medium-text">
              The URL Friendly Name (the part of the URL that references your
              Chabad House) can be updated in your Chabad House profile by
              clicking{" "}
              <PageLink
                className="link-text underline-text"
                // TODO phase 1.5 doing linking to sections so update this to go to chabad house section
                to={Pages.profile.profile}
                params={{ chabadHouseId: chabadHouseID }}
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </PageLink>
            </p>
          )}
          <div className="flex flex-justify-center text-center">
            <a
              href={donorSiteURL}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-accent btn-link"
            >
              Go to my web page{" "}
              <i className="material-icons medium-text ml-16">open_in_new</i>
            </a>
          </div>
        </div>
        <div
          className="card raffle-enrollment-completed-card flex flex-column flex-justify-space"
          style={{ overflow: showExpanded ? "scroll" : undefined }}
        >
          <div>
            <p className="large-text fw-900 mb-4 text-center">
              Embed your Web Page{" "}
            </p>
            <p className="accent-text-dark mb-16 line-height-double text-center">
              (for advanced users)
            </p>
          </div>
          <EmbedWebPageInstructionText paragraphTextClass="accent-text-dark mb-16 line-height-double medium-text" />
          <div
            className="fw-900 flex flex-align-center pointer mb-16"
            onClick={handleToggleExpand}
          >
            {showExpanded ? "Hide" : "View"} embed code
            {showExpanded ? (
              <ExpandLess fontSize="small" />
            ) : (
              <ExpandMore fontSize="small" />
            )}
          </div>

          <Collapse in={showExpanded}>
            <EmbedWebPageIframeText
              donorSiteIframeText={donorSiteIframeText}
              iframeStyle={{
                height: "100px",
                lineHeight: "1.4",
                fontSize: "14px",
              }}
              paragraphTextClass="accent-text-dark line-height-double medium-text mb-16"
            />
          </Collapse>
        </div>
        <div className="card raffle-enrollment-completed-card flex flex-column flex-justify-space">
          <p className="large-text fw-900 mb-16 text-center">
            View your Grand Draw
          </p>
          <div className="flex flex-justify-center accent-text-dark medium-text">
            <List style={{ listStyleType: "disc" }}>
              <ListItemText style={{ display: "list-item" }}>
                Manage orders
              </ListItemText>
              <ListItemText style={{ display: "list-item" }}>
                Edit your Grand Draw settings
              </ListItemText>
              <ListItemText style={{ display: "list-item" }}>
                View your Grand Draw metrics
              </ListItemText>
              <ListItemText style={{ display: "list-item" }}>
                Manage your donors
              </ListItemText>
            </List>
          </div>

          <div className="flex flex-justify-center text-center">
            <PageLink
              to={Navigation.location.pathname}
              className="btn btn-accent btn-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to my Grand Draw{" "}
              <i className="material-icons medium-text ml-16">open_in_new</i>
            </PageLink>
          </div>
        </div>
      </div>
    </>
  );
}
