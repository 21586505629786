import React, { memo, useState } from "react";
import {
  Collapse,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons";
import { SubMenuProps } from "./types";

const routeTypes = {
  Current: "Current",
  Managed: "Managed",
};

const useStyles = makeStyles((theme) => ({
  nestedItem: { paddingLeft: theme.spacing(12) },
  title: { paddingLeft: theme.spacing(8), paddingRight: theme.spacing(4) },
  link: { paddingLeft: theme.spacing(8), color: theme.palette.primary.main },
}));

function _ProgramsSubMenu({ routes, handleMenuClick }: SubMenuProps) {
  const classes = useStyles();
  const [showCurrentPrograms, setShowCurrentPrograms] =
    useState<boolean>(false);
  const [showMyPrograms, setShowMyPrograms] = useState<boolean>(true);

  if (!routes) {
    return null;
  }

  const currentProgramRoutes = routes.filter(
    (r: any) => r.types.indexOf(routeTypes.Current) >= 0,
  );
  const managedProgramRoutes = routes.filter(
    (r: any) => r.types.indexOf(routeTypes.Managed) >= 0,
  );

  return (
    <div>
      <ListItem
        className={classes.link}
        button
        onClick={() => handleMenuClick("/programs")}
      >
        <ListItemText>View all programs</ListItemText>
      </ListItem>

      <ListItem
        selected={showCurrentPrograms}
        className={classes.title}
        onClick={() => {
          setShowCurrentPrograms(!showCurrentPrograms);
        }}
      >
        <ListItemText>Current programs</ListItemText>
        {showCurrentPrograms ? <ArrowDropUp /> : <ArrowDropDown />}
      </ListItem>

      <Collapse in={showCurrentPrograms} timeout="auto" unmountOnExit>
        {currentProgramRoutes
          .sort((r1: any, r2: any) =>
            r1.programScheduleName > r2.programScheduleName ? 1 : -1,
          )
          .map((route: any, index: number) => (
            <ListItem
              key={index}
              button
              onClick={() =>
                handleMenuClick(`${route.baseUrl}/${route.scheduleID}`)
              }
              className={classes.nestedItem}
            >
              <ListItemText>{route.programScheduleName}</ListItemText>
            </ListItem>
          ))}
      </Collapse>

      <ListItem
        selected={showMyPrograms}
        className={classes.title}
        onClick={() => {
          setShowMyPrograms(!showMyPrograms);
        }}
      >
        <ListItemText>My programs</ListItemText>
        {showMyPrograms ? <ArrowDropUp /> : <ArrowDropDown />}
      </ListItem>

      <Collapse in={showMyPrograms} timeout="auto" unmountOnExit>
        {managedProgramRoutes
          .sort((r1: any, r2: any) =>
            r1.programName > r2.programName ? 1 : -1,
          )
          .map((route: any, index: number) => (
            <ListItem
              key={index}
              button
              onClick={() =>
                handleMenuClick(`${route.baseUrl}/${route.scheduleID}`)
              }
              className={classes.nestedItem}
            >
              <ListItemText>{route.programName}</ListItemText>
            </ListItem>
          ))}
      </Collapse>
    </div>
  );
}

export const ProgramsSubMenu = memo(_ProgramsSubMenu);
