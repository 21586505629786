import { AuthRequest } from "../../lib";

export const ProgramScheduleActions = {
  SET_PROGRAM_SCHEDULE_LOADING: "SET_PROGRAM_SCHEDULE_LOADING",
  UPDATE_PROGRAM_SCHEDULE: "UPDATE_PROGRAM_SCHEDULE",

  getProgramSchedule(programId, scheduleId) {
    return async (dispatch, getState) => {
      dispatch(ProgramScheduleActions.setProgramScheduleLoading(true));

      let details;
      let success = true;

      try {
        const response = await AuthRequest.get(
          `Programs/${programId}/Schedules/${scheduleId}`,
        );
        details = response.data.payload;
      } catch (err) {
        success = false;
      }

      const newState = {
        loading: false,
        details,
        success,
      };

      dispatch(ProgramScheduleActions.updateProgramSchedule(newState));
    };
  },
  clearProgramSchedule() {
    return (dispatch) => {
      dispatch(
        ProgramScheduleActions.updateProgramSchedule({
          details: undefined,
        }),
      );
    };
  },
  setProgramScheduleLoading(payload) {
    return {
      type: ProgramScheduleActions.SET_PROGRAM_SCHEDULE_LOADING,
      payload,
    };
  },
  updateProgramSchedule(payload) {
    return {
      type: ProgramScheduleActions.UPDATE_PROGRAM_SCHEDULE,
      payload,
    };
  },
};
