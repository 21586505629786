import { ProgramActions } from "./actions";

export const ProgramState = {
  name: "program",
  persist: false,
  defaults: {
    loading: false,
  },
  handlers: {
    [ProgramActions.SET_PROGRAM_LOADING]: function setProgramLoading(
      state,
      action,
    ) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [ProgramActions.UPDATE_PROGRAM]: function updateProgram(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
