import {
  Assignment,
  BarChart,
  Email,
  Event,
  Inbox,
  PieChart,
  Schedule,
  Send,
  Settings,
} from "@material-ui/icons";
import EngagementPages from ".";

export { activityMenuItems, eventMenuItems, smsMenuItems, studentMenuItems };

const activityMenuItems = [
  {
    text: "Activity",
    icon: BarChart,
    url: EngagementPages.studentsActivity.path,
    activeUrl: EngagementPages.engagement.path,
  },
  {
    text: "Goals & Metrics",
    icon: PieChart,
    url: EngagementPages.studentEngagement.path,
    isEnrolledInEngagementRequired: true,
  },
  {
    text: "Email Preferences",
    icon: Email,
    url: EngagementPages.engagementEmailSettings.path,
    isEnrolledInEngagementRequired: true,
  },
];
const studentMenuItems = [
  {
    text: "Students",
    url: EngagementPages.students.path,
  },
  {
    text: "Groups",
    url: EngagementPages.studentsGroups.path,
  },
];

const eventMenuItems = [
  {
    text: "Events",
    icon: Event,
    url: EngagementPages.rsvp.path.replace(
      ":pageView?/:programScheduleId?/:rsvpItemId?",
      "events",
    ),
  },
  {
    text: "Resources",
    icon: Assignment,
    url: EngagementPages.rsvp.path.replace(
      ":pageView?/:programScheduleId?/:rsvpItemId?",
      "resources",
    ),
  },
  {
    text: "RSVP settings",
    icon: Settings,
    url: EngagementPages.rsvp.path.replace(
      ":pageView?/:programScheduleId?/:rsvpItemId?",
      "settings",
    ),
  },
];

const smsMenuItems = [
  {
    text: "Inbox",
    icon: Inbox,
    url: EngagementPages.messaging.path.replace(":view", "inbox"),
  },
  {
    text: "Sent",
    icon: Send,
    url: EngagementPages.messaging.path.replace(":view", "sent"),
  },
  {
    text: "Scheduled",
    icon: Schedule,
    url: EngagementPages.messaging.path.replace(":view", "scheduled"),
  },
];
