import React from "react";
import Modal from "../../../../components/Modal";

export default class TourAlertsModal extends React.PureComponent {
  render() {
    const {
      show,
      studentRegistrationWarning,
      toggleTourAlertsModal,
    } = this.props;
    return (
      <Modal show={show}>
        <div className="delete-promo-modal-container modal-container">
          <div
            className="modal-card card delete-promo-modal"
            style={{ width: "440px", maxWidth: "98%" }}
          >
            <p className="large-text fw-500 mb-16">
              Schedule Alerts
            </p>
            {studentRegistrationWarning && (
              <p className="accent-text-dark line-height-double mb-16">
                Student registration is currently open. Changing the schedule
                time will affect any students that are already registered.
              </p>
            )}
            <div className="modal-btns flex flex-align-center flex-justify-end mt-16">
              <button
                className="btn link-text uppercase-text fw-500 ml-24"
                onClick={() => toggleTourAlertsModal(false)}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
