import React from "react";
import ChildcareQuestions from "./ChildcareQuestions/ChildcareQuestions";
import NumberFormat from "react-number-format";
import Toggle from "../../../components/form/Toggle";
import { parseNumber } from "../../../lib";

export default class Childcare extends React.PureComponent {
  componentDidMount() {
    const {
      formProps: {
        formFunctions: { isBringingOwnBabysitter },
        onChange,
        values: { childCare },
      },
    } = this.props;

    if (
      !isBringingOwnBabysitter() &&
      (childCare.privateBabysitterFullName ||
        childCare.privateBabysitterRelationship)
    ) {
      onChange("childCare", {
        ...childCare,
        privateBabysitterFullName: "",
        privateBabysitterRelationship: "",
      });
    }
  }

  render() {
    const {
      editProfile,
      formProps,
      formProps: {
        formFunctions: {
          canGetNightBabysitting,
          childIsAttending,
          isBringingOwnBabysitter,
        },
        onChange,
        onChangeEvent,
        values,
        validation,
      },
      profile: { children, husband, wife },
      settings,
      validationHeader,
    } = this.props;

    const attendingChildren = children
      .filter((child) => childIsAttending(child))
      .sort(
        (childA, childB) =>
          childA.ageInMonthsAtKinus - childB.ageInMonthsAtKinus,
      )
      .map((child) => {
        const childCareIndex = values.childCare.children.findIndex(
          (childCare) => childCare.childID === child.id,
        );
        return {
          ...child,
          childCareIndex,
          childCare: values.childCare.children[childCareIndex],
        };
      });

    return (
      <div className="kinus-page-form">
        <p className="xxl-text fw-500">
          Childcare
          {validationHeader && validationHeader}
        </p>
        {attendingChildren.length > 0 && (
          <React.Fragment>
            <div className="kinus-form-section parents-phone-numbers">
              <p className="medium-text fw-700 mb-16">
                Phone number Childcare staff can reach you at
              </p>
              <p className="accent-text-dark mb-16">
                If you have a different cell number for the kinus please specify
                in the fields below.
              </p>

              {!!husband && (
                <div
                  className={`flex flex-align-center mb-12 ${
                    !values.attendees.wifePersonID ? "disabled" : ""
                  }`}
                >
                  <p
                    className="small-text accent-text"
                    style={{ width: "168px" }}
                  >
                    Shliach Cell
                  </p>
                  <p style={{ width: "120px" }}>{husband.personalPhone}</p>
                  <NumberFormat
                    disabled={!values.attendees.husbandPersonID}
                    className={`custom-input ml-32 ${
                      validation.fathersCell ? "error" : ""
                    }`}
                    format="###-###-####"
                    name="childCare.fathersCell"
                    placeholder="000-000-0000"
                    value={values.childCare.fathersCell}
                    onChange={(e) =>
                      onChange(
                        "childCare.fathersCell",
                        parseNumber(e.target.value),
                      )
                    }
                  />
                </div>
              )}
              {!!wife && (
                <div
                  className={`flex flex-align-center mb-12 ${
                    !values.attendees.wifePersonID ? "disabled" : ""
                  }`}
                >
                  <p
                    className="small-text accent-text"
                    style={{ width: "168px" }}
                  >
                    Shlucha Cell
                  </p>
                  <p style={{ width: "120px" }}>{wife.personalPhone}</p>
                  <NumberFormat
                    disabled={!values.attendees.wifePersonID}
                    className={`custom-input ml-32 ${
                      validation.motherCell ? "error" : ""
                    }`}
                    format="###-###-####"
                    name="childCare.motherCell"
                    placeholder="000-000-0000"
                    value={values.childCare.motherCell}
                    onChange={(e) =>
                      onChange(
                        "childCare.motherCell",
                        parseNumber(e.target.value),
                      )
                    }
                  />
                </div>
              )}
            </div>

            {attendingChildren.map((child) => (
              <ChildcareQuestions
                key={child.id}
                child={child}
                editProfile={editProfile}
                formProps={formProps}
                settings={settings}
              />
            ))}

            <div className="kinus-form-section">
              <p className="medium-text fw-700 mb-8">Night Babysitting</p>
              <p
                className="accent-text-dark mb-24"
                style={{ lineHeight: "1.5" }}
              >
                Night babysitting is available upon request. This service is
                only available for children 4 months to 7 years of age. 1
                babysitter will be assigned for every 2 families. In addition,
                an adult supervisor will be stationed on each floor.
              </p>
              <div className="flex flex-align-center mobile-block">
                <p className="kinus-form-label">Night Babysitting?</p>
                <Toggle
                  error={validation.nightBabysitting}
                  disabled={!canGetNightBabysitting()}
                  name="childCare.nightBabysitting"
                  onChange={onChange}
                  options={[
                    { value: true, display: "Yes" },
                    { value: false, display: "No" },
                  ]}
                  value={values.childCare.nightBabysitting}
                />
              </div>
            </div>

            {isBringingOwnBabysitter() && (
              <div>
                <p>
                  You have opted out of COCI babysitting and will need to bring
                  your own babysitter
                </p>
                <p className="kinus-form-label mt-16 mb-4">Babysitter Name</p>
                <input
                  type="text"
                  className={`custom-input ${
                    validation.privateBabysitterFullName ? "error" : ""
                  }`}
                  name="childCare.privateBabysitterFullName"
                  onChange={onChangeEvent}
                  value={values.childCare.privateBabysitterFullName}
                />
                <p className="kinus-form-label mt-16 mb-4">Relationship</p>
                <input
                  type="text"
                  className={`custom-input ${
                    validation.privateBabysitterRelationship ? "error" : ""
                  }`}
                  name="childCare.privateBabysitterRelationship"
                  onChange={onChangeEvent}
                  value={values.childCare.privateBabysitterRelationship}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
