import React from "react";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { useMobile } from "../../../themes";

export default function ProgressBar(props) {
  let { steps, activeIndex } = props;
  steps = Object.values(steps).sort((a, b) => a.stepIndex - b.stepIndex);
  const isMobile = useMobile();

  return (
    //TODO: set done circle to this color #00b900;
    <Stepper activeStep={activeIndex} style={{ width: "100%" }}>
      {steps.map(({ stepName }) => (
        <Step key={stepName}>
          <StepLabel> {isMobile ? "" : stepName}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
