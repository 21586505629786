import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormFieldProps } from "../form";
import { ToggleInput, ToggleInputProps } from "../inputs/ToggleInput";
import { hasTrueFalseValue } from "../../../lib";

type FormToggleFieldProps = {
  emptyValue?: any;
  enforceValue?: boolean;
  onChange?: (name: string, val: any) => void; // optional change handler
  onValueChange?: (val: any) => void; // optional callback for value change - in addition to the default handler
  value?: any; // optional value
} & FormFieldProps &
  Omit<ToggleInputProps, "onChange" | "value">;

export const FormToggleField = React.memo(
  /**
   *
   */
  function FormToggleField({
    emptyValue,
    enforceValue,
    name,
    onValueChange,
    ...passProps
  }: FormToggleFieldProps) {
    const { control } = useFormContext();
    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => {
          const { onChange, ...fieldProps } = field;
          const { error } = fieldState;

          return (
            <ToggleInput
              {...fieldProps}
              id={name}
              onChange={(name, value) => {
                if (!enforceValue || value || hasTrueFalseValue(value)) {
                  const changeValue =
                    emptyValue && !value && !hasTrueFalseValue(value)
                      ? emptyValue
                      : value;
                  onChange({ target: { name, value: changeValue } });
                  onValueChange && onValueChange(changeValue);
                }
              }} // override field.onChange which expects an event param
              {...passProps}
              {...(error
                ? {
                    error: true,
                    helperText: error.message,
                  }
                : {})}
            />
          );
        }}
      />
    );
  },
);
