import React, { memo } from "react";
import { useSelector } from "react-redux";

import { Typography, Avatar, makeStyles, Box } from "@material-ui/core";

import { PROFILE_PLACEHOLDER_URL } from "../lib";
import { AuthSelectors, ActivitySelectors } from "../state";
import { useMobile } from "../themes";

const useStyles = makeStyles((theme) => ({
  header: {
    alignItems: "center",
    color: "#000",
    display: "flex",
    gap: 16,
    marginBottom: 32,
    [theme.breakpoints.down("xs")]: {
      display: "block",
      textAlign: "center",
    },
  },
  avatar: {
    height: 80,
    width: 80,
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
    },
  },
}));

function WelcomeUser() {
  const user = useSelector(AuthSelectors.user);
  const lastLoginAt = useSelector(ActivitySelectors.lastLoginAt);
  const loadingActivity = useSelector(ActivitySelectors.loading);

  const isMobile = useMobile();

  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Avatar
        src={user?.profileImageURL || PROFILE_PLACEHOLDER_URL}
        alt="profile-pic"
        className={classes.avatar}
      />
      <Box>
        <Typography variant={isMobile ? "h6" : "h4"}>
          {user.firstName
            ? `Welcome Back, ${user.firstName} ${user.lastName}`
            : "Hello There"}
        </Typography>
        <Typography variant="body2" style={{ color: "#0000007a" }}>
          {/* TODO fix up styling */}
          {loadingActivity || !lastLoginAt
            ? ""
            : `Your last login was on ${new Date(
                lastLoginAt,
              ).toLocaleDateString()} at ${new Date(
                lastLoginAt,
              ).toLocaleTimeString()}`}
        </Typography>
      </Box>
    </Box>
  );
}

export default memo(WelcomeUser);
