import React, { useState } from "react";
import { Box, Typography, Link } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { ProfileFormSectionProps } from "../../ProfileFormHelpers";
import { FamilyInfo, ShliachInfo } from "./";
import { ProfileFormHeaderStyled } from "../../ProfileForm.styles";
//TODO: move this component to new folder
import ConfidentialityPolicyModal from "../../../personal/general/ConfidentialityPolicyModal";

export const Personal = React.memo(
  /**
   *
   */
  function Personal(props: ProfileFormSectionProps) {
    const [policyModalOpen, setPolicyModalOpen] = useState(false);

    return (
      <>
        <Box marginBottom="68px">
          <ProfileFormHeaderStyled>
            <Typography variant="h4">My personal account</Typography>
            <Box>
              <Info />
              <Typography variant="body2" color="textSecondary">
                All fields are required unless marked optional. Names will be
                shared publicly and contact information will be visible to other
                campus Shluchim in the Shluchim portal. All other information
                will be kept private.{" "}
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setPolicyModalOpen(true);
                  }}
                >
                  View COCI's confidentiality policy
                </Link>
              </Typography>
            </Box>
          </ProfileFormHeaderStyled>
          <ShliachInfo {...props} personType="person" />
          <ShliachInfo {...props} personType="spouse" />
          <FamilyInfo {...props} />
        </Box>
        <ConfidentialityPolicyModal
          show={policyModalOpen}
          close={() => setPolicyModalOpen(false)}
        />
      </>
    );
  },
);
