import React, { useState } from "react";
import Select from "react-select";
import ConfirmationModal from "../../../../../../components/ConfirmationModal";

export default function CancelScheduleModal(props) {
  const {
    scheduleName,
    hasApprovedOrPendingEnrollments,
    onCancelSchedule,
    onKeepSchedule,
    show,
    sys: { eduCourseCancellationReasons = [] },
  } = props;

  const [errorMessage, setErrorMessage] = useState("");
  const [eduCourseCancellationReasonID, setEduCourseCancellationReasonID] =
    useState("");

  const cancellationReasonOptions = eduCourseCancellationReasons.map(
    (reason) => ({
      value: reason.id,
      label: reason.name,
    }),
  );

  return (
    <ConfirmationModal
      className={"small-modal-overflow"}
      cancel={onKeepSchedule}
      cancelText="Keep course"
      confirm={() => {
        if (!eduCourseCancellationReasonID) {
          setErrorMessage("Cancellation reason is required");
        } else {
          onCancelSchedule(eduCourseCancellationReasonID);
        }
      }}
      confirmText="Cancel course"
      message={
        <>
          {hasApprovedOrPendingEnrollments ? (
            <div className="flex">
              <i className="material-icons mr-16">warning</i>
              <div>
                <p>
                  Are you sure you want to cancel{" "}
                  {scheduleName || "this course"}, even though there are{" "}
                  <span className="fw-700">students already registered</span>
                  {"? "}
                  This will{" "}
                  <span className="fw-700">
                    cancel the student registrations for this course.
                  </span>{" "}
                  This cannot be undone.
                </p>
                <p className="mt-8">
                  Before cancelling, you may want to export the list of enrolled
                  students so that you can reach out to them when you are ready
                  to offer this course again.
                </p>
              </div>
            </div>
          ) : (
            `Are you sure you want to cancel ${
              scheduleName || "this course"
            }? This cannot be undone.`
          )}
          <div className="mt-16">
            <p>Select cancellation reason:</p>
            <Select
              className={`custom-select ${errorMessage ? "error" : ""} mt-8`}
              classNamePrefix="custom-select"
              isSearchable={false}
              onChange={(val) => {
                setEduCourseCancellationReasonID(val.value);
                setErrorMessage("");
              }}
              options={cancellationReasonOptions}
              value={cancellationReasonOptions.find(
                (r) => r.value === eduCourseCancellationReasonID,
              )}
            />
            {errorMessage && (
              <p className="error-message mt-4">{errorMessage}</p>
            )}
          </div>
        </>
      }
      show={show}
      title="Cancellation Confirmation"
    />
  );
}
