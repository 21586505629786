import { KinusActions } from "./actions";
import { AuthActions } from "../auth/actions";

export const KinusState = {
  name: "kinus",
  persist: false,
  defaults: {
    profile: {
      loading: false,
    },
    settings: {
      loading: false,
    },
    registration: {
      loading: false,
    },
  },
  handlers: {
    [AuthActions.DO_SIGNOUT]: (state, action) => {
      return KinusState.defaults;
    },
    [KinusActions.SET_KINUS_PROFILE_LOADING]: (state, action) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: !!action.payload,
        },
      };
    },
    [KinusActions.SET_KINUS_PROFILE]: (state, action) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      };
    },
    [KinusActions.SET_KINUS_PROFILE_LOADING]: (state, action) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: !!action.payload,
        },
      };
    },
    [KinusActions.SET_KINUS_SETTINGS]: (state, action) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    },
    [KinusActions.SUBMIT_KINUS_REGISTRATION]: (state, action) => {
      return {
        ...state,
        registration: {
          ...state.registration,
          ...action.payload,
        },
      };
    },
  },
};
