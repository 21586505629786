import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export default (props) => {
  const {
    label,
    color,
    checkedColor,
    labelColor,
    labelStyle,
    ...checkboxProps
  } = props;

  let StyledCheckbox = withStyles({
    root: {
      color: color || "#2774ae",
      "&$checked": {
        color: checkedColor || "#2774ae",
      },
    },
    checked: {},
  })((styleProps) => (
    <Checkbox color="default" {...styleProps} {...checkboxProps} />
  ));

  return label ? (
    <FormControlLabel
      style={{
        color: labelColor || "rgba(0, 0, 0, 0.54)",
        ...(labelStyle || {}),
      }}
      control={<StyledCheckbox />}
      label={label}
    />
  ) : (
    <StyledCheckbox />
  );
};
