import React, { memo, useCallback } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Navigation, PageURL } from "../../../lib";
import { GenericObject } from "../../../state/types";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface DashboardCardProps {
  imageSrc: string;
  title: string;
  page: { path: string };
  pageParams?: GenericObject;
}
function DashboardCard(props: DashboardCardProps) {
  const { imageSrc, title, page, pageParams } = props;

  const onClickCard = useCallback(() => {
    const url = PageURL.to(page.path, pageParams || {}, {});
    Navigation.go(url);
  }, [page.path, pageParams]);

  const classes = useStyles();
  return (
    <Card>
      <CardActionArea onClick={onClickCard}>
        <CardMedia component="img" image={imageSrc} />
        <CardContent className={classes.content}>
          <Typography variant="h6">{title}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default memo(DashboardCard);
