import React, { memo } from "react";
import { useSelector } from "react-redux";
import { AuthSelectors } from "../../../../../state";
import { PageLink } from "../../../../../lib";
import Pages from "../../../..";

function MyCourseCard(props) {
  const {
    course: {
      courseID,
      courseScheduleID,
      imageURL,
      isCompleted,
      isCurrentlyOfferedByChabadHouse,
      isUpcomingByChabadHouse,
      programScheduleID,
      courseScheduleName,
    },
  } = props;

  const allowScheduleCourse = useSelector(AuthSelectors.canManageEduSchedule);

  const manageCourseParams = {
    pageView: "courses",
    programScheduleId: programScheduleID,
    courseId: courseID,
    courseScheduleId: courseScheduleID,
    courseView: "manage",
  };

  const courseOverviewParams = {
    pageView: "courses",
    programScheduleId: programScheduleID,
    courseId: courseID,
    courseView: "overview",
  };

  return (
    <div className="edu-course-card card">
      <PageLink to={Pages.edu.edu} params={manageCourseParams}>
        <img src={imageURL} alt="" />
        <div className="flex flex-justify-space">
          <p className="medium-text fw-700 mb-8 inline-block" dir="auto">
            {courseScheduleName}
          </p>
        </div>
        <p className="medium-text">
          {isCurrentlyOfferedByChabadHouse
            ? "Current"
            : isUpcomingByChabadHouse
            ? "Upcoming"
            : isCompleted
            ? "Completed"
            : ""}
        </p>
      </PageLink>
      <div className="edu-course-card-footer flex flex-justify-end">
        {allowScheduleCourse && (
          <PageLink
            className="link-text"
            to={Pages.edu.edu}
            params={courseOverviewParams}
          >
            General Info
          </PageLink>
        )}
        <PageLink
          className="link-text ml-24"
          to={Pages.edu.edu}
          params={manageCourseParams}
        >
          Manage My Course
        </PageLink>
      </div>
    </div>
  );
}

export default memo(MyCourseCard);
