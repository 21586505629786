import React, { useState, useEffect, useCallback } from "react";
import { Typography } from "@material-ui/core";
import { Person, LocalActivity } from "@material-ui/icons";
import moment from "moment";

import Loader from "../../../components/Loader";
import EnrollmentSalesByReferrerChart from "./EnrollmentSalesByReferrerChart";
import EnrollmentSalesOverTime from "./EnrollmentSalesOverTimeChart";
import EnrollmentSalesOverTimeComparison from "./EnrollmentSalesOverTimeComparisonChart";
import ProgressStatusBar from "../ProgressStatusBar";
// import RaffleTopSellersChart from "./RaffleTopSellersChart";
import { formatNumber, formatCurrency, pluralizeText } from "../../../lib";
import {
  connectToSignalRMessages,
  subscribeToMessages,
} from "../../../signalRConnection";

const Metrics = (props) => {
  const {
    raffle: {
      enrollmentDetails: {
        id,
        bonusRaffleGoal,
        currencyCode,
        donorGoal,
        raffleGoal,
      } = {},
      details: {
        id: raffleEventId,
        isOpenForManualTicketEntry,
        daysRemaining,
        startDate,
        endDate,
        raffleTitle,
      } = {},
      metrics: {
        cociOwes,
        chabadHouseOwes,
        offlineTicketsSold,
        onlineTicketsSold,
        ordersCount,
        ordersToday,
        raffleEventID,
        referrerSales = [],
        ticketsSoldToday,
        totalInFees,
        totalProcessingFeeDonorAmountRaised,
        totalRaised,
        totalRaisedByTeams,
        totalTicketAmountRaised,
        totalTicketsSold,
      } = {},
      sales = [],
      salesComparison = [],
      metricsLoading,
      salesLoading,
      salesComparisonLoading,
    },
    sys: { yearOverYearChartIntervals = [] },
    getRaffleMetrics,
    getRaffleSales,
    getRaffleSalesComparison,
    navigate,
  } = props;

  const [blockLoading, setBlockLoading] = useState(false);

  const getRaffleData = useCallback(async () => {
    await getRaffleMetrics(id);
    await getRaffleSales(
      id,
      isOpenForManualTicketEntry ? "last7Days" : "all",
      raffleEventId,
    );
    await getRaffleSalesComparison(id, false, raffleEventId);
  }, [
    getRaffleMetrics,
    getRaffleSales,
    getRaffleSalesComparison,
    id,
    isOpenForManualTicketEntry,
    raffleEventId,
  ]);

  const onMessageReceived = useCallback(async () => {
    setBlockLoading(true);
    await getRaffleData();
    setBlockLoading(false);
  }, [getRaffleData]);

  useEffect(() => {
    const loadRaffleData = async () => {
      await getRaffleData();
    };
    loadRaffleData();
    if (id) {
      const onConnectionSuccess = () => {
        subscribeToMessages(id);
      };
      connectToSignalRMessages(onConnectionSuccess, onMessageReceived);
      //no need to unsubscribe because connection closes automatically on unmount/refresh
    }
  }, [getRaffleData, onMessageReceived, id]);

  const raffleFeesPaid =
    !metricsLoading && moment().isAfter(moment(endDate).add(2, "days"));
  const isInBonusRound = !!bonusRaffleGoal && totalTicketsSold >= raffleGoal;
  const totalRaffleGoal = raffleGoal + (isInBonusRound ? bonusRaffleGoal : 0);
  return (
    <div className="raffle-shliach-card">
      <Typography variant="h5" className="mb-32">
        My Grand Draw Metrics
      </Typography>
      {metricsLoading && !blockLoading ? (
        <Loader />
      ) : (
        <>
          {daysRemaining > 0 && (
            <>
              <div className="flex flex-justify-space mt-24">
                <p className="medium-text fw-700">Grand Draw Progress</p>
                <p className="medium-text fw-700">
                  {formatNumber(daysRemaining)}{" "}
                  {pluralizeText("Day", daysRemaining)} Remaining
                </p>
              </div>
              <ProgressStatusBar
                className="mt-24"
                endDate={endDate}
                startDate={startDate}
                daysRemaining={daysRemaining}
                type="raffleDays"
              />
            </>
          )}
          {!!raffleGoal && (
            <div>
              <div className="flex flex-justify-space mt-24">
                <p className="medium-text fw-700">
                  Ticket Goal{isInBonusRound ? " - Bonus Round" : ""}
                </p>
                <p className="medium-text fw-700">
                  {formatNumber(
                    totalTicketsSold >= totalRaffleGoal
                      ? 0
                      : totalRaffleGoal - totalTicketsSold,
                  )}{" "}
                  Remaining
                </p>
              </div>
              <ProgressStatusBar
                className="mt-8"
                raffleGoal={raffleGoal}
                totalRaffleGoal={totalRaffleGoal}
                isInBonusRound={isInBonusRound}
                ticketsSold={totalTicketsSold}
                type="raffleGoal"
              />
            </div>
          )}
          {!!donorGoal && (
            <div>
              <div className="flex flex-justify-space mt-24">
                <p className="medium-text fw-700">Donor Goal</p>
                <p className="medium-text fw-700">
                  {formatNumber(
                    ordersCount >= donorGoal ? 0 : donorGoal - ordersCount,
                  )}{" "}
                  Remaining
                </p>
              </div>
              <ProgressStatusBar
                className="mt-8"
                donorGoal={donorGoal}
                donors={ordersCount}
                type="donorGoal"
              />
            </div>
          )}
          <div className="mt-40 flex money-status-cards">
            <div
              className="money-status-card relative"
              onClick={() => navigate("orders")}
            >
              <p className="mb-16">
                {formatCurrency(totalRaised, currencyCode)}
              </p>
              <p>Total Raised</p>
              <div className="money-status-card-substatus mobile-hidden">
                <div>
                  {formatCurrency(totalTicketAmountRaised, currencyCode)} Ticket
                  Costs
                </div>
                <div>
                  {formatCurrency(
                    totalProcessingFeeDonorAmountRaised,
                    currencyCode,
                  )}{" "}
                  Donated Processing Fees
                </div>
                <div>
                  {formatCurrency(totalRaisedByTeams, currencyCode)} Total
                  Raised by Teams
                </div>
              </div>
            </div>
            <div
              className="money-status-card"
              onClick={() => navigate("orders")}
            >
              <p className="mb-16">
                {formatCurrency(totalInFees, currencyCode)}
              </p>
              <p>Total in Fees</p>
            </div>
            <div
              className="money-status-card"
              onClick={() => navigate("orders")}
            >
              <p className="mb-16">{formatCurrency(cociOwes, currencyCode)}</p>
              <p>COCI Owe{raffleFeesPaid ? "d" : "s"} Me</p>
            </div>
            <div
              className="money-status-card"
              onClick={() => navigate("orders")}
            >
              <p className="mb-16">
                {formatCurrency(chabadHouseOwes, currencyCode)}
              </p>
              <p>I Owe{raffleFeesPaid ? "d" : ""} COCI</p>
            </div>
          </div>
          <div className="mt-40 flex mobile-block">
            <div className="flex ticket-status-cards flex-column">
              <div className="ticket-status-card">
                <div>
                  <div className="icon-wrapper person-icon">
                    <Person htmlColor={"#63C674"} />
                  </div>
                  <p className="large-text fw-700 relative mb-8">
                    {formatNumber(ordersCount)}{" "}
                    {pluralizeText("Donor", ordersCount)}
                  </p>
                  <p className="fs-14 fw-300">Total</p>
                </div>
                <div>
                  <p className="accent-text small-text">
                    {formatNumber(ordersToday)}{" "}
                    {pluralizeText("donor", ordersToday)} today
                  </p>
                </div>
              </div>

              <div className="ticket-status-card">
                <div>
                  <div className="icon-wrapper ticket-icon">
                    <LocalActivity htmlColor={"#F3893D"} />
                  </div>
                  <p className="large-text fw-700 relative mb-8">
                    {formatNumber(totalTicketsSold)}{" "}
                    {pluralizeText("Ticket", totalTicketsSold)}
                  </p>
                  <p className="fs-14 fw-300">Total sold</p>
                </div>

                <div>
                  <p className="accent-text small-text mb-8">
                    {formatNumber(ticketsSoldToday)}{" "}
                    {pluralizeText("ticket", ticketsSoldToday)} sold today
                  </p>
                  <p className="accent-text small-text mb-8">
                    {formatNumber(onlineTicketsSold)}{" "}
                    {pluralizeText("ticket", onlineTicketsSold)} sold online
                  </p>
                  <p className="accent-text small-text">
                    {formatNumber(offlineTicketsSold)}{" "}
                    {pluralizeText("ticket", offlineTicketsSold)} sold offline
                  </p>
                </div>
              </div>
            </div>

            <EnrollmentSalesByReferrerChart
              currencyCode={currencyCode}
              data={referrerSales}
            />
          </div>
          <EnrollmentSalesOverTime
            currencyCode={currencyCode}
            data={sales}
            getRaffleSales={getRaffleSales}
            loading={salesLoading && !blockLoading}
            id={id}
            raffleId={raffleEventID}
            isOpenForManualTicketEntry={isOpenForManualTicketEntry}
          />
          <EnrollmentSalesOverTimeComparison
            currencyCode={currencyCode}
            data={salesComparison}
            daysRemaining={daysRemaining}
            getRaffleSalesComparison={getRaffleSalesComparison}
            loading={salesComparisonLoading && !blockLoading}
            id={id}
            raffleDaysToEnd={yearOverYearChartIntervals}
            raffleId={raffleEventID}
            raffleTitle={raffleTitle}
          />
          {/* <p className="xl-text fw-700 mt-40">Global Grand Draw Metrics</p>
            <RaffleTopSellersChart
              currencyCode={currencyCode}
              data={topSellers}
              loading={topSellersLoading}
            /> */}
        </>
      )}
    </div>
  );
};

export default React.memo(Metrics);
