import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AccountActions, TripActions } from "../../state";
import { ProgramsActions } from "../../state/programs/actions";

import Trip from "./Trip";

export default connect(
  function mapState(state) {
    return {
      account: state.account,
      auth: state.auth,
      loading: state.trip.loading,
      programs: state.programs,
      shliachID: state.auth.shliachID,
      sys: state.sys,
      trip: state.trip,
      ui: state.ui,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          getAccountInfo: AccountActions.getAccountInfo,
          getProgramRoutes: ProgramsActions.getProgramRoutes,
          getGeneralSettings: TripActions.getTripGeneralSettings,
          getShliachTrip: TripActions.getShliachTrip,
          getTripEnrollment: TripActions.getTripEnrollment,
          submitTripEnrollment: TripActions.submitTripEnrollment,
          getTripEvent: TripActions.getTripEvent,
          getTripSchedule: TripActions.getTripSchedule,
          getTripRegistrations: TripActions.getTripRegistrations,
          getTripRegistrationsForExport:
            TripActions.getTripRegistrationsForExport,
          getTripRegistrationDetails: TripActions.getTripRegistrationDetails,
          updateTripRegistration: TripActions.updateTripRegistration,
          submitTripRegistrationRecommendation:
            TripActions.submitTripRegistrationRecommendation,
          getTripMetrics: TripActions.getTripMetrics,
          getHistoricTripEnrollments: TripActions.getHistoricTripEnrollments,
          getHistoricTrip: TripActions.getHistoricTrip,
        },
        dispatch,
      ),
    };
  },
)(Trip);
