import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { AccountActions, LifeInsuranceActions, SysActions } from "../../state";
import LifeInsurance from "./LifeInsurance";

export default connect(
  function mapState(state) {
    return {
      ...state.lifeInsurance,
      account: state.account,
      auth: state.auth,
      sys: state.sys,
      ui: state.ui,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...LifeInsuranceActions,
          ...SysActions,
          getAccountInfo: AccountActions.getAccountInfo,
        },
        dispatch,
      ),
    };
  },
)(LifeInsurance);
