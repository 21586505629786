import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LamplightersActions, LamplightersSelectors } from "../../../state";
import { EnrollmentSettingsData } from "../../../state/lamplighters/state";
import _isEqual from "lodash.isequal";
import { Button, makeStyles } from "@material-ui/core";
import EmailRecipientsSection from "../../../components/EmailRecipientsSection";
import Loader from "../../../components/Loader";

const initialState = {
  id: null,
  emailRecipients: [],
};

const LamplightersScheduleSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [invalidEntryError, setInvalidEntryError] = useState("");
  const [state, setState] = useState<EnrollmentSettingsData>(initialState);
  const [hasChanges, setHasChanges] = useState(false);

  const enrollmentSettingsLoading = useSelector(
    LamplightersSelectors.enrollmentSettingsLoading,
  );

  const enrollmentSettingsData = useSelector(
    LamplightersSelectors.enrollmentSettingsData,
  );

  const { id, chabadHouseID } = useSelector(
    LamplightersSelectors.enrollmentData,
  );

  useEffect(() => {
    (async () => {
      await dispatch(
        LamplightersActions.getLamplightersEnrollmentSettings(id) as any,
      );
    })();
  }, [dispatch, id]);

  const setInitialState = useCallback(() => {
    setState({ ...enrollmentSettingsData });
  }, [enrollmentSettingsData]);

  useEffect(() => {
    if (enrollmentSettingsData && !enrollmentSettingsLoading) {
      setHasChanges(false);
      setInitialState();
    }
  }, [enrollmentSettingsData, enrollmentSettingsLoading, setInitialState]);

  const handleChange = useCallback(
    (field: keyof EnrollmentSettingsData, value: any) => {
      const fieldChanged = !_isEqual(state[field], value);
      setHasChanges(fieldChanged);
      setState((prev) => {
        return {
          ...prev,
          [field]: value,
        };
      });
    },
    [state],
  );

  const handleEmailRecipientChange = useCallback(
    (newEmailRecipients) => {
      handleChange("emailRecipients", newEmailRecipients);
    },
    [handleChange],
  );

  const validate = useCallback(() => {
    if (state.emailRecipients.length === 0) {
      setSubmitting(false);
      setInvalidEntryError("Please correct any invalid or missing entries.");
      return false;
    }
    return true;
  }, [state.emailRecipients]);

  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    setSubmitAttempted(true);
    setInvalidEntryError("");

    const isValid = validate();
    if (isValid) {
      await dispatch(
        LamplightersActions.submitLamplightersEnrollmentSettings(
          id,
          state,
        ) as any,
      );
    }
    setSubmitting(false);
  }, [dispatch, id, state, validate]);

  return (
    <div className={classes.card}>
      {!enrollmentSettingsData && enrollmentSettingsLoading ? (
        <Loader />
      ) : (
        <>
          <h2 className={classes.title}>Lamplighters Settings</h2>
          <EmailRecipientsSection
            chabadHouseID={chabadHouseID}
            emailRecipients={state?.emailRecipients || []}
            formSectionClassName={classes.formSection}
            onChange={handleEmailRecipientChange}
            submitAttempted={submitAttempted}
          />
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              disabled={!hasChanges}
              onClick={setInitialState} //reset to initial state
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="ml-16"
              disabled={submitting || !hasChanges}
              onClick={onSubmit}
            >
              {submitting ? "Saving..." : "Save"}
            </Button>
          </div>
          {submitAttempted && !submitting && invalidEntryError && (
            <p className="error-message">{invalidEntryError}</p>
          )}
        </>
      )}
    </div>
  );
};

export default LamplightersScheduleSettings;

const useStyles = makeStyles((theme) => ({
  card: {
    [theme.breakpoints.only("xs")]: {
      padding: 0,
      border: "none",
    },
  },
  title: {
    color: "#1E1C1C",
    fontFamily: "Archivo",
    fontSize: 20,
    fontWeight: 800,
    letterSpacing: 0,
    lineHeight: "22px",
    marginBottom: 24,
  },
  formSection: {
    maxWidth: 1040,
    borderTop: "none",
    paddingTop: 0,
    [theme.breakpoints.only("xs")]: {
      maxWidth: "100%",
    },
  },
  buttonContainer: {
    paddingTop: 40,
    paddingBottom: 8,
  },
}));
