import React from "react";
import Events from "./events/EventsContainer";
import Resources from "./resources/ResourcesContainer";
import Settings from "./settings/SettingsContainer";
import { Navigation, PageURL } from "../../../lib";

export default class MyRsvp extends React.PureComponent {
  componentDidMount() {
    this.goToDefaultPageView();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pageRoute.params.pageView &&
      !this.props.pageRoute.params.pageView
    ) {
      this.goToDefaultPageView();
    }
  }

  myRsvpPageViews = {
    events: Events,
    resources: Resources,
    settings: Settings,
  };

  goToDefaultPageView = () => {
    const {
      pageRoute: {
        page,
        params: { pageView, ...params },
        query,
      },
    } = this.props;
    //default to 'events' view
    if (!pageView || !this.myRsvpPageViews[pageView]) {
      Navigation.redirect(
        PageURL.to(
          page,
          {
            ...params,
            pageView: "events",
          },
          query,
        ),
      );
    }
  };

  render() {
    const {
      pageRoute: {
        params: { pageView },
      },
    } = this.props;

    const myRsvpPage = this.myRsvpPageViews[pageView];

    return (
      !!myRsvpPage && (
        <div className="flex tablet-block mobile-block">
          {React.createElement(myRsvpPage, this.props)}
        </div>
      )
    );
  }
}
