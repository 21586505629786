import React from "react";
import { formatNumber } from "../../../lib";
import moment from "moment";

export default class LamplightersScheduleWidget extends React.PureComponent {
  constructor(props) {
    super(props);

    const numGrants = props.enrollment.allocations.length;
    this.state = {
      showDetails: true,
      showGrantBreakdown: Array(numGrants).fill(false),
    };
  }

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.toggleDetails();
    }
  }

  toggleDetails = () => {
    this.setState({
      showDetails: !this.state.showDetails,
    });
  };

  toggleShowGrantBreakdown = (index) => {
    const newShowGrantBreakdown = [...this.state.showGrantBreakdown];
    newShowGrantBreakdown.splice(
      index,
      1,
      !this.state.showGrantBreakdown[index],
    );
    this.setState({
      showGrantBreakdown: newShowGrantBreakdown,
    });
  };

  render() {
    const {
      enrollment: { allocations } = {},
      getGrantName,
      schedule: {
        deadlines,
        programEndDate,
        programStartDate,
        programScheduleName,
      },
    } = this.props;
    const { showDetails, showGrantBreakdown } = this.state;

    const sortedDeadlines = deadlines.sort((d1, d2) =>
      moment(d1.date).isBefore(moment(d2.date)) ? -1 : 1,
    );
    const nextDeadline = sortedDeadlines.find((d) =>
      moment(d.date).isAfter(moment()),
    );

    return (
      <div className="lamplighters-schedule-widget-wrapper">
        <div className="card lamplighters-schedule-widget-card">
          <div>
            <div className="flex flex-justify-space flex-align-center">
              <p className="lamplighters-schedule-info fw-700">
                {programScheduleName} Info
              </p>
              <i
                className="material-icons desktop-hidden pointer"
                style={{ fontSize: "18px" }}
                onClick={this.toggleDetails}
              >
                {showDetails ? "keyboard_arrow_up" : "keyboard_arrow_down"}
              </i>
            </div>
            {showDetails && (
              <React.Fragment>
                <div className="mt-24 mb-24">
                  <label className="mb-8">Dates</label>
                  <p className="flex flex-align-center">
                    <i className="material-icons mr-8 large-text">
                      calendar_today
                    </i>
                    <span>
                      {programStartDate
                        ? moment(programStartDate).format("MMM D, YYYY")
                        : "TBD"}{" "}
                      -{" "}
                      {programEndDate
                        ? moment(programEndDate).format("MMM D, YYYY")
                        : "TBD"}
                    </span>
                  </p>
                  {nextDeadline && (
                    <div className="accent-text-secondary small-text mt-16">
                      <p className="mb-8">
                        Next Deadline:{" "}
                        {moment(nextDeadline.date).format("MMM D, YYYY")}
                      </p>
                      <p>{nextDeadline.name}</p>
                    </div>
                  )}
                </div>
                <div
                  style={{ borderTop: "1px solid #edecec", margin: "16px 0px" }}
                />
                <div className="mb-24">
                  {allocations &&
                    allocations.map((allocation, index) => (
                      <div className="small-text mb-8" key={index}>
                        <div className="flex flex-align-center flex-justify-space mb-8">
                          <p className="fw-700">
                            {getGrantName(
                              allocation.grantTypeDisplay,
                              allocation.grantDescription,
                            )}
                          </p>
                          <div className="flex flex-align-center">
                            <p className="nowrap-text mr-12">
                              {formatNumber(allocation.quantity)} Total
                            </p>
                            <i
                              className="material-icons xl-text pointer"
                              onClick={() =>
                                this.toggleShowGrantBreakdown(index)
                              }
                            >
                              {showGrantBreakdown[index]
                                ? "keyboard_arrow_up"
                                : "keyboard_arrow_down"}
                            </i>
                          </div>
                        </div>
                        {showGrantBreakdown[index] && (
                          <div className="lamplighters-schedule-widget accent-text">
                            <div className="flex flex-align-center flex-justify-space">
                              <p className="mb-4">
                                {formatNumber(allocation.activeQuantity)} Active
                              </p>
                              {allocation.activeFlaggedQuantity > 0 && (
                                <p className="mb-4 mr-32 flagged-grant-text italic-text">
                                  {formatNumber(
                                    allocation.activeFlaggedQuantity,
                                  )}{" "}
                                  flagged
                                </p>
                              )}
                            </div>
                            <div className="flex flex-align-center flex-justify-space">
                              <p className="mb-4">
                                {formatNumber(allocation.submittedQuantity)}{" "}
                                Submitted
                              </p>
                              {allocation.submittedFlaggedQuantity > 0 && (
                                <p className="mb-4 mr-32 flagged-grant-text italic-text">
                                  {formatNumber(
                                    allocation.submittedFlaggedQuantity,
                                  )}{" "}
                                  flagged
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
                <div
                  style={{ borderTop: "1px solid #edecec", margin: "16px 0px" }}
                />
                <div
                  className="accent-text"
                  style={{ fontSize: "11px", lineHeight: "2" }}
                >
                  <div className="flex flex-align-center accent-text mr-8">
                    <i className="material-icons mr-4 small-text active-grant-text">
                      circle
                    </i>
                    Active Grant
                  </div>
                  <div className="flex flex-align-center accent-text mr-8">
                    <i className="material-icons mr-4 small-text submitted-grant-text">
                      circle
                    </i>
                    Submitted Grant
                  </div>
                  <div className="flex flex-align-center accent-text">
                    <i className="material-icons mr-4 small-text flagged-grant-text">
                      circle
                    </i>
                    <i className="material-icons mr-4 small-text flagged-grant-text-2">
                      circle
                    </i>
                    <i className="material-icons mr-4 small-text flagged-grant-text-3">
                      circle
                    </i>
                    Flagged Grant
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
