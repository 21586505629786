import React from "react";
import { Cell, PieChart, Pie, Tooltip } from "recharts";

const colors = [
  "#0B425D",
  "#53B7E8",
  "#007AB5",
  "#F3893D",
  "#6369D1",
  "#72D798",
  "#F36464",
  "#FDB827",
];

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{ padding: "6px", backgroundColor: "black", color: "#fff" }}
      >
        <p className="label">{payload[0].payload.percentageValue}%</p>
      </div>
    );
  }

  return null;
};

export default class MetricsPieChart extends React.PureComponent {
  render() {
    const data = this.props.data || [];
    const emptyState = data.every((d) => !d.value);
    return (
      <div className="flex flex-align-center">
        {emptyState ? (
          <div style={{ width: "184px" }}>
            <img
              src="/images/pie-chart.svg"
              alt="pie chart placeholder"
              height="120"
            />
          </div>
        ) : (
          /* <ResponsiveContainer width={185} height={176}> */
          <PieChart width={184} height={176}>
            <Pie
              data={data}
              dataKey="value"
              cx="50%"
              cy="50%"
              outerRadius={52}
              label
            >
              {data.map((entry, index) => (
                <Cell key={index} fill={colors[index % colors.length]} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
          /* </ResponsiveContainer> */
        )}
        <div className="metrics-pie-chart-labels ml-24">
          {data.map((data, index) => (
            <div className="flex" key={index}>
              <div
                className="metrics-pie-chart-label-square"
                style={{ background: colors[index % colors.length] }}
              />
              <p>{data.name}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
