import React from "react";
import ResourcesListActions from "./ResourceListActions";
import RsvpListFilters from "../../shared/list/RsvpListFilters";

export default function ResourcesListFilters(props) {
  const {
    chabadHouseName,
    rsvpEnrollmentID,
    getResources,
    hasResources,
    pageRoute,
  } = props;

  const soOptions = [
    { label: "Date Created", value: "createdOn" },
    { label: "Name", value: "name" },
  ];

  return (
    <React.Fragment>
      <RsvpListFilters
        rsvpType="resource"
        chabadHouseName={chabadHouseName}
        getRsvpItems={getResources}
        pageRoute={pageRoute}
        soOptions={soOptions}
        actionsComponent={
          <ResourcesListActions
            hasResources={hasResources}
            rsvpEnrollmentID={rsvpEnrollmentID}
            pageRoute={pageRoute}
          />
        }
      />
    </React.Fragment>
  );
}
