import React from "react";
import { ThumbDown, ThumbUp } from "@material-ui/icons";
import { Grid } from "@material-ui/core";

interface ApproveRejectProps {
  data: any;
  onApproveRejectStudent: (approve: boolean, data: any) => void;
}

export default function ApproveReject(props: ApproveRejectProps) {
  const { data, onApproveRejectStudent } = props;

  const handleApproveRejectClick = (e: any, value: boolean) => {
    e.stopPropagation();
    onApproveRejectStudent(value, data);
  };

  return (
    <Grid container style={{ gap: 8, cursor: "pointer" }}>
      <ThumbUp
        htmlColor="#4FCEC5"
        onClick={(e) => handleApproveRejectClick(e, true)}
      />
      <ThumbDown
        color="error"
        onClick={(e) => handleApproveRejectClick(e, false)}
      />
    </Grid>
  );
}
