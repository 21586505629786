import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Chip } from "@material-ui/core";
import ApproveReject from "../../../components/ApproveRejects";
import { formatFullName, formatNumber, isMobileView } from "../../../../../lib";
import { EduProgramTypes } from "../../../eduUtils";
import { AuthSelectors } from "../../../../../state";

function StudentsListRow({
  detailsOpened,
  didClassesEnd,
  eduProgramType,
  isStudentsByCourseView,
  onApproveRejectStudent,
  onViewStudentDetails,
  student,
  student: {
    courseName,
    courseScheduleName,
    didCompleteAttendance,
    didCompleteSurvey,
    numOfClassesAttended,
    numOfClassesScheduled,
    studentEnrollmentStatusDisplay,
    courseScheduleEnrollmentStatus,
    studentCell,
    studentEmail,
    studentFirstName,
    studentLastName,
    studentID: id,
  },
  toggleMobileDetails,
}) {
  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);
  const allowViewStudentDetails = useSelector(AuthSelectors.canManageStudents);

  // students who are globally rejected are not displayed in the course-specific student list
  // so there is no need to take into account the global status
  const courseScheduleStatus = (
    <div>
      {/* non-shluchim cannot approve/reject students, or even see that shluchim are doing the approvals/rejections */}
      {courseScheduleEnrollmentStatus === "Pending" && isShliach ? (
        <ApproveReject
          data={student}
          onApproveRejectStudent={onApproveRejectStudent}
        />
      ) : (
        <div>{courseScheduleEnrollmentStatus}</div>
      )}
    </div>
  );

  const courseScheduleInfo = (
    <div className="student-table-row-mobile-small-text">
      <p>{courseScheduleName}</p>
      <p className="italic-text small-text">{courseName}</p>
    </div>
  );

  return (
    <div
      className={`paginated-table-row students-table-row ${
        allowViewStudentDetails && "pointer"
      } ${detailsOpened[id] === true ? "open" : "closed"}`}
      onClick={
        isMobileView()
          ? () => toggleMobileDetails(id)
          : allowViewStudentDetails
          ? () => onViewStudentDetails(id)
          : () => {}
      }
    >
      {/* open/close icon for mobile view */}
      <div className="desktop-hidden tablet-hidden">
        <i
          className="material-icons accent-text-secondary medium-text pointer mr-16"
          onClick={() => toggleMobileDetails(id)}
        >
          {detailsOpened[id] ? "remove" : "add"}
        </i>
      </div>

      <div className="desktop-display-contents mobile-flex flex-justify-space full-width">
        <div className="desktop-display-contents">
          {/* name section */}
          <div>
            {formatFullName(studentFirstName, studentLastName)}
            {!isStudentsByCourseView &&
              studentEnrollmentStatusDisplay === "Rejected" && (
                <Chip
                  label={studentEnrollmentStatusDisplay}
                  className="rejected-chip"
                />
              )}
          </div>

          {/* accent text under name for mobile view */}
          <div className="desktop-hidden tablet-hidden">
            {isStudentsByCourseView ? (
              <>
                <div className="student-table-row-mobile-small-text">
                  <p>{studentEmail}</p>
                  <p>{studentCell}</p>
                </div>
              </>
            ) : (
              courseScheduleInfo
            )}
          </div>

          {/* row details for desktop view */}
          <div className="mobile-hidden desktop-display-contents">
            <div>{studentEmail}</div>
            {isStudentsByCourseView ? (
              <>
                <div>{studentCell}</div>
                <div>
                  {formatNumber(numOfClassesAttended)}/
                  {formatNumber(numOfClassesScheduled)}
                </div>
                <div>
                  {didCompleteSurvey ? "Yes" : "No"}
                  {!didCompleteSurvey && (
                    <p className="italic-text accent-text">
                      {(
                        eduProgramType === EduProgramTypes.YourIsrael
                          ? didCompleteAttendance
                          : didClassesEnd
                      )
                        ? "email sent"
                        : ""}
                    </p>
                  )}
                </div>
                <div>
                  <div>{courseScheduleStatus}</div>
                </div>
              </>
            ) : (
              <div>{courseScheduleInfo}</div>
            )}
          </div>

          {/* more details for mobile view */}
          <div
            className="student-details desktop-hidden tablet-hidden mb-8"
            style={{ paddingTop: 16 }}
          >
            {!isStudentsByCourseView && (
              <div className="mb-16">
                <p className="fw-700 student-mobile-label">Email</p>
                <p>{studentEmail}</p>
              </div>
            )}

            {isStudentsByCourseView && (
              <>
                <div className="mb-16">
                  <p className="fw-700 student-mobile-label">Status</p>
                  <div>{courseScheduleStatus}</div>
                </div>
                <div className="mb-16">
                  <p className="fw-700 student-mobile-label">Attendance</p>
                  <div>
                    {formatNumber(numOfClassesAttended)}/
                    {formatNumber(numOfClassesScheduled)}
                  </div>
                </div>
                <div className="mb-16">
                  <p className="fw-700 student-mobile-label">Review</p>
                  <div>
                    {didCompleteSurvey ? "Yes" : "No"}
                    {!didCompleteSurvey && (
                      <span className="italic-text accent-text ml-8">
                        {(
                          eduProgramType === EduProgramTypes.YourIsrael
                            ? didCompleteAttendance
                            : didClassesEnd
                        )
                          ? "email sent"
                          : ""}
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
            {allowViewStudentDetails && (
              <p className="link-text" onClick={() => onViewStudentDetails(id)}>
                View Details
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(StudentsListRow);
