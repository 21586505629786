import React from "react";
import DateTimePicker from "../../../../../../../components/DateTimePicker";
import Toggle from "../../../../../../../components/form/Toggle";
import ValidatedInput from "../../../../../../../components/form/ValidatedInput";

export default function RegistrationQuestions(props) {
  const {
    onChangeRegistration,
    registration: { notes, registrationQuestionResponses = [] },
    submitAttempted,
  } = props;

  const getRegistrationQuestionResponseInput = (qr) => {
    switch (qr.responseType) {
      case "Date":
        return <DateTimePicker dateFormat="MM/dd/yyyy" />;
      case "Number":
        return <input type="number" />;
      case "YesNoToggle":
        return (
          <Toggle
            allowNoOption={!qr.isMandatory}
            options={[
              {
                value: true,
                display: "Yes",
              },
              {
                value: false,
                display: "No",
              },
            ]}
            style={{ padding: "0px" }}
          />
        );
      case "Text":
      default:
        return (
          <input style={{ resize: "none", width: "100%" }} type="textarea" />
        );
    }
  };

  const onChangeRegistrationQuestionResponse = (responseType, changeProps) => {
    let name, value;

    switch (responseType) {
      case "YesNoToggle":
      case "Date":
        name = changeProps[0];
        value = changeProps[1];
        break;
      case "Number":
      case "Text":
      default:
        const evtTarget = changeProps[0].target;
        name = evtTarget.name;
        value = evtTarget.value;
    }

    onChangeRegistration(name, value);
  };

  return (
    <div className="separatorTop">
      <p className="fw-700 medium-text mt-16 mb-16">
        {registrationQuestionResponses.length
          ? "Some Quick Questions"
          : "Quick Question"}
      </p>
      {registrationQuestionResponses.map((qr, qrIndex) => (
        <div className="mt-24" key={qrIndex}>
          <p className="fw-700 mt-16 mb-16">Question {qrIndex + 1}</p>
          <div>
            <p className="accent-text-dark mb-16">{qr.questionText}</p>
            <ValidatedInput
              input={getRegistrationQuestionResponseInput(qr)}
              inputClassName="custom-input"
              name={`registrationQuestionResponses[${qrIndex}].value`}
              onChange={(...changeProps) =>
                onChangeRegistrationQuestionResponse(
                  qr.responseType,
                  changeProps,
                )
              }
              required={
                qr.isMandatory &&
                (qr.responseType !== "YesNoToggle" ||
                  (qr.value !== true && qr.value !== false))
              }
              showRequired={submitAttempted}
              value={qr.value}
            />
          </div>
        </div>
      ))}
      <div className="mt-24">
        {!!registrationQuestionResponses.length && (
          <p className="fw-700 mt-16 mb-16">
            Question {registrationQuestionResponses.length + 1}
          </p>
        )}
        <p className="accent-text-dark mb-16">
          Anything we should know about, or any other comments?
        </p>
        <textarea
          className="custom-input custom-message full-width"
          name="notes"
          onChange={(e) => onChangeRegistration(e.target.name, e.target.value)}
          style={{ height: "60px" }}
          value={notes}
        />
      </div>
    </div>
  );
}
