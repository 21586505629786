import EngagementEmailPreferences from "./EngagementEmailPreferencesPage";
import Rsvp from "../rsvp/Rsvp.vm";
import StudentEngagementPage from "../students/StudentEngagementPage";
import Students from "../students/Students.vm";
import StudentsActivityPage from "../students/StudentsActivityPage";
import StudentDetails from "../students/StudentDetailsPage";
import StudentGroups from "../students/StudentGroups.vm";
import SMSMessagingPage from "../sms/SMSMessagingPage";
import SMSInfoPage from "../sms/SMSInfoPage";
import StudentMergeDuplicatesPage from "../students/mergeDuplicates/StudentMergeDuplicatesPage";
import { InternPermissions } from "../../lib";

export const EngagementPages = {
  engagement: {
    anon: false,
    path: "/engagement",
    title: "Engagement",
    type: "PAGE_ENGAGEMENT",
    view: StudentsActivityPage,
    layout: "engagement",
  },
  students: {
    path: "/engagement/students",
    type: "PAGE_STUDENT_ENGAGEMENT",
    title: "Students",
    view: Students,
    layout: "engagement",
    externalUserAuthorization: InternPermissions.manageStudents,
  },
  studentsGroups: {
    path: "/engagement/students/groups",
    type: "PAGE_STUDENT_GROUPS_ENGAGEMENT",
    title: "Student Groups",
    view: StudentGroups,
    layout: "engagement",
    externalUserAuthorization: InternPermissions.manageStudents,
  },
  studentMerge: {
    path: "/engagement/students/merge",
    type: "PAGE_ENGAGEMENT_STUDENT_MERGE_DUPLICATES",
    title: "Student Merge Duplicates",
    view: StudentMergeDuplicatesPage,
    layout: "engagement",
  },
  studentDetails: {
    path: "/engagement/students/:studentId",
    type: "PAGE_ENGAGEMENT_STUDENT_DETAILS",
    title: "Student Details",
    view: StudentDetails,
    layout: "engagement",
    externalUserAuthorization: InternPermissions.manageStudents,
  },
  studentsActivity: {
    anon: false,
    path: "/engagement/activity",
    type: "PAGE_ENGAGEMENT_ACTIVITY",
    title: "Students Activity",
    view: StudentsActivityPage,
    layout: "engagement",
    externalUserAuthorization: InternPermissions.manageActivity,
  },
  // defining a duplicate route because specifying programScheduleId as an optional
  // parameter on the studentEngagement path above is displaying a URL of:
  // /engagement/activity/%3AprogramScheduleId%3F
  studentsActivityWithProgramScheduleId: {
    path: "/engagement/activity/:programScheduleId",
    type: "PAGE_ENGAGEMENT_ACTIVITY_WITH_PROGRAM_SCHEDULE_ID",
    title: "Student Activity",
    view: StudentsActivityPage,
    layout: "engagement",
  },
  studentEngagement: {
    anon: false,
    path: "/engagement/metrics",
    type: "PAGE_ENGAGEMENT_METRICS",
    title: "Student Goals and Metrics",
    view: StudentEngagementPage,
    layout: "engagement",
  },
  engagementEmailSettings: {
    anon: false,
    path: "/engagement/email",
    title: "Engagement",
    type: "PAGE_ENGAGEMENT_EMAIL",
    view: EngagementEmailPreferences,
    layout: "engagement",
  },
  rsvp: {
    anon: false,
    path: "/engagement/rsvp/:pageView?/:programScheduleId?/:rsvpItemId?",
    type: "PAGE_EVENTS",
    title: "RSVP",
    view: Rsvp,
    layout: "engagement",
    externalUserAuthorization: InternPermissions.manageRsvp,
  },
  smsInfo: {
    anon: false,
    path: "/engagement/messaging/info",
    type: "PAGE_ENGAGEMENT_MESSAGING_INFO",
    title: "Messaging Info",
    view: SMSInfoPage,
    layout: "engagement",
  },
  messaging: {
    anon: false,
    path: "/engagement/messaging/:view",
    type: "PAGE_ENGAGEMENT_MESSAGING",
    title: "Messaging",
    view: SMSMessagingPage,
    layout: "engagement",
  },
};
export default EngagementPages;

export const EngagementArea = {
  pages: EngagementPages,
};
