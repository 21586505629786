import React from "react";
import SummerCampConfirmationModal from "./SummerCampConfirmationModal";

import _set from "lodash.set";
import _cloneDeep from "lodash.clonedeep";

export default class Attendees extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      didToggleAttendees: false,
      previousAttendees: props.formProps.values.attendees,
      familyRowsOpened: {},
      summerCampConfirmationModal: {
        show: false,
        message: "",
        onConfirm: null,
      },
    };

    this.initialState = this.state;
  }

  componentDidMount() {
    this.props.setOnIndexWizard(this.onUnmount);
  }

  onUnmount = () => {
    const { didToggleAttendees, previousAttendees } = this.state;
    const {
      formProps: { formFunctions, onChange, values },
    } = this.props;

    if (didToggleAttendees) {
      const formValues = formFunctions.getAttendeeUpdates(
        values,
        previousAttendees,
      );
      onChange("formValues", formValues);
    }
  };

  openFamilyRow = (id) => {
    this.setState({
      familyRowsOpened: {
        ...this.state.familyRowsOpened,
        [id]: {
          open: true,
        },
      },
    });
  };

  closeFamilyRow = (id) => {
    this.setState({
      familyRowsOpened: {
        ...this.state.familyRowsOpened,
        [id]: {
          open: false,
        },
      },
    });
  };

  toggleAttendingParent = (event, parentPersonId) => {
    const {
      profile: { husband },
    } = this.props;

    const parentId = event.target.checked ? parentPersonId : 0;
    const parentIdType =
      husband && parentPersonId === husband.id
        ? "husbandPersonID"
        : "wifePersonID";

    this.onToggleAttendees(`attendees.${parentIdType}`, parentId);
  };

  toggleAttendingChild = (event, childPersonId) => {
    const {
      formProps: { values },
    } = this.props;

    let attendingChildrenPersonIDs = [...values.attendees.childrenPersonIDs];
    if (event.target.checked)
      attendingChildrenPersonIDs = [
        ...attendingChildrenPersonIDs,
        childPersonId,
      ];
    else
      attendingChildrenPersonIDs = attendingChildrenPersonIDs.filter(
        (personId) => personId !== childPersonId,
      );

    this.onToggleAttendees(
      "attendees.childrenPersonIDs",
      attendingChildrenPersonIDs,
    );
  };

  toggleAllAttendees = (event, girlsNotAllowedIds, boysNotAllowedIds) => {
    const {
      profile: { husband, wife, children },
    } = this.props;

    const attendees = event.target.checked
      ? {
          husbandPersonID: husband ? husband.id : 0,
          wifePersonID: wife ? wife.id : 0,
          childrenPersonIDs: children
            .filter(
              (child) =>
                (child.person.gender === "Female"
                  ? girlsNotAllowedIds.indexOf(child.id)
                  : boysNotAllowedIds.indexOf(child.id)) < 0,
            )
            .map((child) => child.person.id),
        }
      : {
          husbandPersonID: 0,
          wifePersonID: 0,
          childrenPersonIDs: [],
        };

    this.onToggleAttendees("attendees", attendees);
  };

  onToggleAttendees(name, value) {
    const {
      formProps: { formFunctions, onChange, values },
    } = this.props;

    if (!this.state.didToggleAttendees)
      this.setState({ didToggleAttendees: true });

    let formValues = _cloneDeep(values);
    _set(formValues, name, value);
    formValues = formFunctions.getAttendeeRegistrationUpdates(
      formValues,
      values.attendees,
    );
    onChange("formValues", formValues);
  }

  onChangeSummerCamp = (event, child) => {
    const {
      formProps: {
        onChange,
        onChangeEvent,
        values: { attendees },
      },
      kinusStartDate,
      summerCamps,
    } = this.props;

    //deal with ramifications of summer camp choices
    const summerCamp = summerCamps.find(
      (camp) => camp.name === event.target.value,
    );
    if (
      summerCamp &&
      new Date(summerCamp.startDate) < new Date(kinusStartDate)
    ) {
      const eventName = event.target.name;
      const eventValue = event.target.value;

      this.setState({
        ...this.state,
        summerCampConfirmationModal: {
          show: true,
          message:
            "By selecting this summer camp you are confirming that this child will not be attending the kinus",
          onConfirm: async () => {
            await onChange(eventName, eventValue);
            this.onToggleAttendees(
              "attendees.childrenPersonIDs",
              attendees.childrenPersonIDs.filter(
                (id) => id !== child.person.id,
              ),
            );
          },
        },
      });
    } else {
      onChangeEvent(event);
    }
  };

  onChangeGrade = async (event, child) => {
    const {
      formProps: {
        formFunctions: { childCannotAttendKinus },
        onChange,
        values: {
          attendees: { childrenPersonIDs },
        },
      },
    } = this.props;

    const grade = event.target.value;
    await onChange(event.target.name, grade);

    //deal with ramifications of grade choices
    const updatedChild = {
      ...child,
      childCare: {
        ...child.childCare,
        grade,
      },
    };
    if (
      childrenPersonIDs.indexOf(child.person.id) >= 0 &&
      childCannotAttendKinus(updatedChild)
    ) {
      this.onToggleAttendees(
        "attendees.childrenPersonIDs",
        childrenPersonIDs.filter((id) => id !== child.person.id),
      );
    }
  };

  render() {
    const { summerCampConfirmationModal, familyRowsOpened } = this.state;
    const {
      ageGrades,
      applyForChildCarePositionURL,
      childrenAttending,
      editProfile,
      formProps: {
        formFunctions: { childCannotAttendKinus },
        values,
        validation,
      },
      hebrewYear,
      maxBoyAge,
      maxGirlAge,
      parentsAttending,
      profile,
      summerCamps,
    } = this.props;

    const children = profile.children.map((child) => {
      const childCareIndex = values.childCare.children.findIndex(
        (childCare) => childCare.childID === child.id,
      );
      const childCare = values.childCare.children[childCareIndex] || {};
      return {
        ...child,
        childCareIndex,
        childCare,
      };
    });
    const girlsNotAllowedIds = children
      .filter(
        (child) =>
          child.person.gender === "Female" && childCannotAttendKinus(child),
      )
      .map((child) => child.id);
    const boysNotAllowedIds = children
      .filter(
        (child) =>
          child.person.gender === "Male" && childCannotAttendKinus(child),
      )
      .map((child) => child.id);
    const allowedFamilyMembersCount =
      [profile.husband, profile.wife, ...children].filter(
        (familyMember) => familyMember,
      ).length -
      (girlsNotAllowedIds.length + boysNotAllowedIds.length);

    return (
      <div className="kinus-form-section family-info">
        <div className="flex flex-justify-space flex-align-center mb-8">
          <p className="medium-text fw-700 relative">
            Who will be attending the Kinus from your family?{" "}
            <span
              className={validation.attendees ? "error-message" : ""}
              style={{
                top: "18px",
                fontFamily: "AvenirBook",
                fontSize: "12px",
                left: "0",
              }}
            >
              {!!validation.attendees && `*${validation.attendees}`}
            </span>
          </p>
          <p
            className="btn btn-accent btn-medium ml-8"
            style={{ fontSize: "12px", padding: "0 8px" }}
            onClick={editProfile}
          >
            Edit Family
          </p>
        </div>

        <div className="full-width">
          <div className="kinus-form-family-table">
            <div className="kinus-form-family-table-row kinus-form-family-table-header">
              <div className="flex custom-checkbox-container">
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  id="family-member-all"
                  checked={
                    childrenAttending + parentsAttending ===
                    allowedFamilyMembersCount
                  }
                  onChange={(event) =>
                    this.toggleAllAttendees(
                      event,
                      girlsNotAllowedIds,
                      boysNotAllowedIds,
                    )
                  }
                />
                <label htmlFor="family-member-all" />
              </div>
              <p>Name</p>
              <p>Date of Birth</p>
              <p>{hebrewYear} Grade</p>
              <p>Overnight Camp</p>
            </div>
            {[profile.husband, profile.wife]
              .filter((parent) => parent)
              .map((parent) => (
                <div
                  key={parent.id}
                  className={`kinus-form-family-table-row ${
                    familyRowsOpened &&
                    familyRowsOpened[parent.id] &&
                    familyRowsOpened[parent.id].open
                      ? "opened"
                      : "closed"
                  }`}
                >
                  <div className="flex custom-checkbox-container">
                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      id={`family-member-${parent.id}`}
                      checked={
                        values.attendees.husbandPersonID === parent.id ||
                        values.attendees.wifePersonID === parent.id
                      }
                      onChange={(event) =>
                        this.toggleAttendingParent(event, parent.id)
                      }
                    />
                    <label htmlFor={`family-member-${parent.id}`} />
                  </div>
                  <p>
                    {parent.firstName} {parent.lastName}
                  </p>
                  <div>
                    <p className="accent-text xs-text uppercase-text desktop-hidden tablet-hidden">
                      Date of Birth
                    </p>
                    <p>{parent.formattedDOB}</p>
                  </div>
                  <div>
                    <p className="accent-text xs-text uppercase-text desktop-hidden tablet-hidden">
                      5779 Grade
                    </p>
                    <select disabled={true}>
                      <option defaultChecked={true}>N/A</option>
                    </select>
                  </div>
                  <div>
                    <p className="accent-text xs-text uppercase-text desktop-hidden tablet-hidden">
                      Overnight Camp
                    </p>
                    <select disabled={true}>
                      <option defaultChecked={true}>N/A</option>
                    </select>
                  </div>
                  <div className="mobile-collapse">
                    <i
                      className="material-icons accent-text-secondary open"
                      onClick={() => this.openFamilyRow(parent.id)}
                    >
                      add
                    </i>
                    <i
                      className="material-icons accent-text-secondary close"
                      onClick={() => this.closeFamilyRow(parent.id)}
                    >
                      remove
                    </i>
                  </div>
                </div>
              ))}
            {children
              .sort(
                (childA, childB) =>
                  childB.ageInMonthsAtKinus - childA.ageInMonthsAtKinus,
              )
              .map((child) => {
                const cannotAttendKinus =
                  (child.person.gender === "Female"
                    ? girlsNotAllowedIds.indexOf(child.id)
                    : boysNotAllowedIds.indexOf(child.id)) >= 0;

                const overMaxAge =
                  child.ageAtKinus >
                  (child.person.gender === "Female" ? maxGirlAge : maxBoyAge);

                return (
                  <div
                    key={child.person.id}
                    className={`kinus-form-family-table-row ${
                      cannotAttendKinus ? "disabled" : ""
                    } ${
                      familyRowsOpened &&
                      familyRowsOpened[child.person.id] &&
                      familyRowsOpened[child.person.id].open
                        ? "opened"
                        : "closed"
                    }`}
                  >
                    <div className="flex custom-checkbox-container">
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        id={`family-member-${child.person.id}`}
                        checked={
                          values.attendees.childrenPersonIDs.indexOf(
                            child.person.id,
                          ) >= 0
                        }
                        onChange={(event) =>
                          this.toggleAttendingChild(event, child.person.id)
                        }
                        disabled={cannotAttendKinus}
                      />
                      <label htmlFor={`family-member-${child.person.id}`} />
                    </div>
                    <p>
                      {cannotAttendKinus && "*"}
                      {child.person.firstName} {child.person.lastName}
                    </p>
                    <div>
                      <p className="accent-text xs-text uppercase-text desktop-hidden tablet-hidden">
                        Date of Birth
                      </p>
                      <p>{child.formattedDOB}</p>
                    </div>
                    <div>
                      <p className="accent-text xs-text uppercase-text desktop-hidden tablet-hidden">
                        5779 Grade
                      </p>
                      <select
                        disabled={overMaxAge}
                        name={`childCare.children[${child.childCareIndex}].grade`}
                        value={child.childCare.grade}
                        onChange={(event) => this.onChangeGrade(event, child)}
                      >
                        <option defaultChecked={true}>N/A</option>
                        {ageGrades.map(({ grade }) => (
                          <option key={grade} value={grade}>
                            {grade}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <p className="accent-text xs-text uppercase-text desktop-hidden tablet-hidden">
                        Overnight Camp
                      </p>
                      <select
                        disabled={overMaxAge}
                        name={`childCare.children[${child.childCareIndex}].campName`}
                        value={child.childCare.campName}
                        onChange={(event) =>
                          this.onChangeSummerCamp(event, child)
                        }
                      >
                        <option defaultChecked={true}>N/A</option>
                        {summerCamps
                          .filter((camp) => camp.gender === child.person.gender)
                          .map(({ name }) => (
                            <option key={name} value={name}>
                              {name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="mobile-collapse">
                      <i
                        className="material-icons accent-text-secondary open"
                        onClick={() => this.openFamilyRow(child.person.id)}
                      >
                        add
                      </i>
                      <i
                        className="material-icons accent-text-secondary close"
                        onClick={() => this.closeFamilyRow(child.person.id)}
                      >
                        remove
                      </i>
                    </div>
                  </div>
                );
              })}
          </div>
          {boysNotAllowedIds.length > 0 && (
            <p
              className="small-text accent-text-dark mb-8"
              style={{ lineHeight: "1.5" }}
            >
              *Tzeirei Hashluchim camp is for boys up to and including 6th
              grade. For them to join the kinus as a staff member or for any
              other questions related to this please contact Nechoma Dina
              Dubrowski at{" "}
              <a
                href="mailto:ndd@chabadoncampus.org"
                className="link-text"
                target="_blank"
                rel="noopener noreferrer"
              >
                ndd@chabadoncampus.org
              </a>
              .
            </p>
          )}
          {girlsNotAllowedIds.length > 0 && (
            <p
              className="small-text accent-text-dark"
              style={{ lineHeight: "1.5" }}
            >
              *Tzeirei Hashluchim camp is for girls up to and including 6th
              grade. For them to join the Kinus, they can apply for a position
              in{" "}
              <a
                href={applyForChildCarePositionURL}
                className="link-text"
                target="_blank"
                rel="noopener noreferrer"
              >
                childcare division
              </a>{" "}
              or Tzeirei Hashluchim camp (contact{" "}
              <a
                href="mailto:ndd@chabadoncampus.org"
                className="link-text"
                target="_blank"
                rel="noopener noreferrer"
              >
                ndd@chabadoncampus.org
              </a>
              ). Questions? Please contact Nechoma Dina Dubrowski at{" "}
              <a
                href="mailto:ndd@chabadoncampus.org"
                className="link-text"
                target="_blank"
                rel="noopener noreferrer"
              >
                ndd@chabadoncampus.org
              </a>
              .
            </p>
          )}
        </div>

        <SummerCampConfirmationModal
          {...summerCampConfirmationModal}
          onClose={() =>
            this.setState({
              ...this.state,
              summerCampConfirmationModal:
                this.initialState.summerCampConfirmationModal,
            })
          }
        />
      </div>
    );
  }
}
