import React, { useState, useCallback } from "react";
import Loader from "../../../components/Loader";

import {
  Checkbox,
  FormControlLabel,
  Button,
  Collapse,
  Typography,
  Box,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Visibility } from "@material-ui/icons";

import axios from "axios";
import { getTripEmailPreviewText } from "../../../lib";

const { REACT_APP_API_URL } = process.env;

function StudentRegistrationAction(props) {
  const {
    pageRoute: {
      query: { accountID, registrationID, status = "", token },
    },
  } = props;

  const [didConfirmAction, setDidConfirmAction] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(true);
  const [sendRejectionEmail, setSendRejectionEmail] = useState(true);
  const [showPreview, setShowPreview] = useState(false);

  const studentRegistrationAction = useCallback(async () => {
    setLoading(true);

    if (!accountID || !registrationID || !status || !token) {
      setSuccess(false);
    } else {
      try {
        await axios.post(
          `${REACT_APP_API_URL}TripRegistrations/StatusWithToken`,
          {
            accountID,
            registrationID,
            status,
            skipStudentRejectionEmail: !sendRejectionEmail,
            token,
          },
        );
      } catch (err) {
        setSuccess(false);
        if (err.response && err.response.status === 401) {
          setErrorMessage("Unauthorized Request");
        } else if (
          err.response &&
          err.response.data &&
          err.response.data.messages &&
          err.response.data.messages[0]
        ) {
          setErrorMessage(err.response.data.messages[0]);
        }
      }
    }
    setDidConfirmAction(true);
    setLoading(false);
  }, [accountID, registrationID, status, token, sendRejectionEmail]);

  const isAcceptAction = status.toLowerCase() === "accepted";
  const errorToShow =
    errorMessage ||
    "Something went wrong and this action could not be completed.  Refresh the page to try again.";

  return (
    <div className="student-registration-action-container">
      <div className="card action-card">
        {loading ? (
          <Loader />
        ) : !success ? (
          <React.Fragment>
            <img
              alt="error-robot"
              height="240"
              src="/images/error.svg"
              className="mb-64"
            />
            <p className="error-message medium-text relative">{errorToShow}</p>
          </React.Fragment>
        ) : !didConfirmAction ? (
          <React.Fragment>
            <p className="fw-700 medium-text mb-24">
              Click the button below to {isAcceptAction ? "accept" : "reject"}{" "}
              this student
            </p>
            <p
              className="btn btn-accent action-btn"
              onClick={studentRegistrationAction}
            >
              <i className="material-icons mr-8 xl-text">
                thumb_{isAcceptAction ? "up" : "down"}
              </i>
              {isAcceptAction ? "Accept" : "Reject"} Student
            </p>
            {!isAcceptAction && (
              <>
                <FormControlLabel
                  className="mt-24 send-email-checkbox"
                  label="Send rejection email to student"
                  control={
                    <Checkbox
                      checked={sendRejectionEmail}
                      name="sendStudentEmail"
                      onChange={() =>
                        setSendRejectionEmail(!sendRejectionEmail)
                      }
                    />
                  }
                />
                <Button
                  onClick={() => setShowPreview(!showPreview)}
                  color="primary"
                  disableRipple
                  className={"preview-btn"}
                >
                  <Visibility fontSize="small" className="visibility-icon" />{" "}
                  Preview Email
                  {showPreview ? (
                    <ExpandLess fontSize="small" />
                  ) : (
                    <ExpandMore fontSize="small" />
                  )}
                </Button>

                <Collapse in={showPreview}>
                  <Box className="email-preview-wrapper">
                    <Typography variant="body2">
                      {getTripEmailPreviewText()}
                    </Typography>
                  </Box>
                </Collapse>
              </>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <img
              alt={status}
              src={`/images/${isAcceptAction ? "check" : "x"}-circle.svg`}
              className="mb-64"
            />
            <p className="fw-700 medium-text">
              Student has been successfully {status?.toLowerCase()}
            </p>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
export default React.memo(StudentRegistrationAction);
