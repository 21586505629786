import React, { useState, useCallback } from "react";
import MergeDuplicateHeader from "./MergeDuplicateHeader";
import MergeDuplicateSection from "./MergeDuplicateSection";

const initialSelectedSectionIndexState = {
  account: null,
  general: null,
  education: null,
  allergy: null,
  family: null,
  jewishEducation: null,
  jewishStatus: null,
  currentJewishStatus: null,
};

const sections = [
  { name: "account", title: "Account" },
  { name: "general", title: "General" },
  { name: "education", title: "Current Education" },
  { name: "allergy", title: "Allergies" },
  { name: "family", title: "Family" },
  { name: "jewishEducation", title: "Jewish Education" },
  { name: "jewishStatus", title: "Jewish Status" },
  { name: "currentJewishStatus", title: "Current Jewish Status" },
];

const MergeDuplicateForm = (props) => {
  const {
    fields,
    ids,
    removeStudent,
    resolveField,
    selectAllFields,
    selectAllSectionFields,
    students,
  } = props;

  const [showAllFields, setShowAllFields] = useState(false);
  const [selectedAllIndex, setSelectedAllIndex] = useState(null);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(
    initialSelectedSectionIndexState,
  );
  const [showAccountDetailsInHeader, setShowAccountDetailsInHeader] =
    useState(false);

  const handleSelectAll = (studentIndex) => {
    setSelectedAllIndex(studentIndex);
    setSelectedSectionIndex(initialSelectedSectionIndexState);
    selectAllFields(studentIndex);
  };

  const getCategoryFields = useCallback(
    (category) => fields.filter((f) => f.category === category),
    [fields],
  );

  return (
    <div className="merge-duplicates-form mb-16">
      <MergeDuplicateHeader
        students={students}
        removeStudent={removeStudent}
        selectedAllIndex={selectedAllIndex}
        handleSelectAll={handleSelectAll}
        showAllFields={showAllFields}
        setShowAllFields={setShowAllFields}
        showAccountDetails={showAccountDetailsInHeader}
      />
      {sections.map((section) => (
        <MergeDuplicateSection
          sectionFields={getCategoryFields(section.name)}
          students={students}
          ids={ids}
          key={section.name}
          sectionName={section.name}
          sectionTitle={section.title}
          showAllFields={showAllFields}
          selectedSectionIndex={selectedSectionIndex}
          setSelectedSectionIndex={setSelectedSectionIndex}
          selectAllSectionFields={selectAllSectionFields}
          selectedAllIndex={selectedAllIndex}
          setShowAccountDetailsInHeader={setShowAccountDetailsInHeader}
          resolveField={resolveField}
        />
      ))}
    </div>
  );
};

export default React.memo(MergeDuplicateForm);
