import { AuthRequest, ApiCallErrorMessageHandler } from "../../lib";
import queryString from "query-string";

export const LamplightersActions = {
  SET_LAMPLIGHTERS_SCHEDULES_LOADING: "SET_LAMPLIGHTERS_SCHEDULES_LOADING",
  SET_LAMPLIGHTERS_SCHEDULES: "SET_LAMPLIGHTERS_SCHEDULES",

  SET_LAMPLIGHTERS_SCHEDULE_LOADING: "SET_LAMPLIGHTERS_SCHEDULE_LOADING",
  SET_LAMPLIGHTERS_SCHEDULE: "SET_LAMPLIGHTERS_SCHEDULE",
  CLEAR_LAMPLIGHTERS_SCHEDULE: "CLEAR_LAMPLIGHTERS_SCHEDULE",

  SET_LAMPLIGHTERS_SCHEDULE_ENROLLMENT_LOADING:
    "SET_LAMPLIGHTERS_SCHEDULE_ENROLLMENT_LOADING",
  SET_LAMPLIGHTERS_SCHEDULE_ENROLLMENT_REFRESHING:
    "SET_LAMPLIGHTERS_SCHEDULE_ENROLLMENT_REFRESHING",
  SET_LAMPLIGHTERS_SCHEDULE_ENROLLMENT: "SET_LAMPLIGHTERS_SCHEDULE_ENROLLMENT",
  CLEAR_LAMPLIGHTERS_SCHEDULE_ENROLLMENT:
    "CLEAR_LAMPLIGHTERS_SCHEDULE_ENROLLMENT",

  SET_LAMPLIGHTERS_SCHEDULE_DATA_PERCENTAGE_LOADING:
    "SET_LAMPLIGHTERS_SCHEDULE_DATA_PERCENTAGE_LOADING",
  SET_LAMPLIGHTERS_SCHEDULE_DATA_PERCENTAGE:
    "SET_LAMPLIGHTERS_SCHEDULE_DATA_PERCENTAGE",
  CLEAR_LAMPLIGHTERS_SCHEDULE_DATA_PERCENTAGE:
    "CLEAR_LAMPLIGHTERS_SCHEDULE_DATA_PERCENTAGE",

  SET_LAMPLIGHTERS_STUDENTS_LOADING: "SET_LAMPLIGHTERS_STUDENTS_LOADING",
  SET_LAMPLIGHTERS_STUDENTS: "SET_LAMPLIGHTERS_STUDENTS",

  SET_SUBMIT_LAMPLIGHTERS_STUDENT_GRANT_LOADING:
    "SET_SUBMIT_LAMPLIGHTERS_STUDENT_GRANT_LOADING",
  SET_SUBMIT_LAMPLIGHTERS_STUDENT_GRANT:
    "SET_SUBMIT_LAMPLIGHTERS_STUDENT_GRANT",

  SET_LAMPLIGHTERS_STUDENT_GRANTS_LOADING:
    "SET_LAMPLIGHTERS_STUDENT_GRANTS_LOADING",
  SET_LAMPLIGHTERS_STUDENT_GRANTS: "SET_LAMPLIGHTERS_STUDENT_GRANTS",
  CLEAR_LAMPLIGHTERS_STUDENT_GRANTS: "CLEAR_LAMPLIGHTERS_STUDENT_GRANTS",
  SET_LAMPLIGHTERS_ENROLLMENT_SETTINGS_LOADING:
    "SET_LAMPLIGHTERS_ENROLLMENT_SETTINGS_LOADING",
  SET_LAMPLIGHTERS_ENROLLMENT_SETTINGS: "SET_LAMPLIGHTERS_ENROLLMENT_SETTINGS",

  getLamplightersSchedules() {
    return async (dispatch) => {
      dispatch(LamplightersActions.setLamplightersSchedulesLoading(true));

      let newState = {};
      let success = true;

      const response = await AuthRequest.get("GrantSchedules").catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(LamplightersActions.setLamplightersSchedules(newState));
    };
  },
  setLamplightersSchedulesLoading(payload) {
    return {
      type: LamplightersActions.SET_LAMPLIGHTERS_SCHEDULES_LOADING,
      payload,
    };
  },
  setLamplightersSchedules(payload) {
    return {
      type: LamplightersActions.SET_LAMPLIGHTERS_SCHEDULES,
      payload,
    };
  },

  getLamplightersSchedule(programScheduleId, getEnrollment = false) {
    return async (dispatch) => {
      dispatch(LamplightersActions.setLamplightersScheduleLoading(true));

      let newState = {};
      let success = true;

      const response = await AuthRequest.get(
        `GrantSchedules/byProgramSchedule/${programScheduleId}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
          errorStatus: err.response && err.response.status,
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;

        if (getEnrollment) {
          const scheduleId = newState.data.id;
          if (scheduleId) {
            await dispatch(
              LamplightersActions.getLamplightersEnrollment(scheduleId),
            );
          }
        }
      }

      newState.loading = false;
      newState.success = success;

      dispatch(LamplightersActions.setLamplightersSchedule(newState));
    };
  },
  setLamplightersScheduleLoading(payload) {
    return {
      type: LamplightersActions.SET_LAMPLIGHTERS_SCHEDULE_LOADING,
      payload,
    };
  },
  setLamplightersSchedule(payload) {
    return {
      type: LamplightersActions.SET_LAMPLIGHTERS_SCHEDULE,
      payload,
    };
  },
  clearLamplightersSchedule() {
    return {
      type: LamplightersActions.CLEAR_LAMPLIGHTERS_SCHEDULE,
    };
  },

  getLamplightersEnrollment(scheduleId, isRefresh = false) {
    return async (dispatch) => {
      if (isRefresh) {
        // on refresh set 'refreshing' state, rather than loading state, to avoid loading indication in UI
        dispatch(LamplightersActions.setLamplightersEnrollmentRefreshing(true));
      } else {
        dispatch(LamplightersActions.setLamplightersEnrollmentLoading(true));
      }

      let newState = {};
      let success = true;

      const response = await AuthRequest.get(
        `GrantScheduleEnrollments/byShliach?grantScheduleId=${scheduleId}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
          errorStatus: err.response && err.response.status,
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(LamplightersActions.setLamplightersEnrollment(newState));
    };
  },
  setLamplightersEnrollmentLoading(payload) {
    return {
      type: LamplightersActions.SET_LAMPLIGHTERS_SCHEDULE_ENROLLMENT_LOADING,
      payload,
    };
  },
  setLamplightersEnrollmentRefreshing(payload) {
    return {
      type: LamplightersActions.SET_LAMPLIGHTERS_SCHEDULE_ENROLLMENT_REFRESHING,
      payload,
    };
  },
  setLamplightersEnrollment(payload) {
    return {
      type: LamplightersActions.SET_LAMPLIGHTERS_SCHEDULE_ENROLLMENT,
      payload,
    };
  },
  clearLamplightersEnrollment() {
    return {
      type: LamplightersActions.CLEAR_LAMPLIGHTERS_SCHEDULE_ENROLLMENT,
    };
  },

  getLamplightersDataPercentage(scheduleId) {
    return async (dispatch) => {
      dispatch(LamplightersActions.setLamplightersDataPercentageLoading(true));

      let newState = {};
      let success = true;

      const response = await AuthRequest.get(
        `GrantScheduleEnrollments/byShliach/ActiveDataPercentage?grantScheduleId=${scheduleId}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
          errorStatus: err.response && err.response.status,
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(LamplightersActions.setLamplightersDataPercentage(newState));
    };
  },
  setLamplightersDataPercentageLoading(payload) {
    return {
      type: LamplightersActions.SET_LAMPLIGHTERS_SCHEDULE_DATA_PERCENTAGE_LOADING,
      payload,
    };
  },
  setLamplightersDataPercentage(payload) {
    return {
      type: LamplightersActions.SET_LAMPLIGHTERS_SCHEDULE_DATA_PERCENTAGE,
      payload,
    };
  },
  clearLamplightersDataPercentage() {
    return {
      type: LamplightersActions.CLEAR_LAMPLIGHTERS_SCHEDULE_DATA_PERCENTAGE,
    };
  },

  getLamplightersStudents(
    page,
    results,
    grantScheduleId,
    filters,
    isExport = false,
  ) {
    return async (dispatch) => {
      dispatch(LamplightersActions.setLamplightersStudentsLoading(true));

      let newState = {};
      let success = true;

      const {
        allStudents,
        grantId,
        grantStatus,
        grantColumnExclusions,
        includeArchived,
        search,
        sortByOption,
        studentAgeMax,
        studentAgeMin,
        studentClasses,
        studentCreatedWithinYears,
        studentId,
      } = filters || {};
      const params = {
        grantId,
        grantScheduleId,
        grantStatus,
        excludedGrantIDs: grantColumnExclusions,
        includeArchived: !!includeArchived,
        isEnrolled: !allStudents,
        isExport,
        page,
        results,
        sortByOption,
        studentAgeMax,
        studentAgeMin,
        studentClasses,
        studentCreatedWithinYears,
        studentId,
        text: studentId ? undefined : search, //don't include search text if studentId is selected
      };

      const response = await AuthRequest.get(
        `GrantScheduleStudents?${queryString.stringify(params)}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(LamplightersActions.setLamplightersStudents(newState));
    };
  },
  setLamplightersStudentsLoading(payload) {
    return {
      type: LamplightersActions.SET_LAMPLIGHTERS_STUDENTS_LOADING,
      payload,
    };
  },
  setLamplightersStudents(payload) {
    return {
      type: LamplightersActions.SET_LAMPLIGHTERS_STUDENTS,
      payload,
    };
  },

  submitLamplightersStudentGrant(studentGrant) {
    return async (dispatch) => {
      dispatch(
        LamplightersActions.setSubmitLamplightersStudentGrantLoading(true),
      );

      let newState = {};
      let success = true;

      await (!studentGrant.isEnrolled
        ? AuthRequest.delete(`GrantScheduleStudents/grant/${studentGrant.id}`)
        : AuthRequest.post("GrantScheduleStudents/grant", studentGrant)
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      newState.loading = false;
      newState.success = success;

      dispatch(LamplightersActions.setSubmitLamplightersStudentGrant(newState));
    };
  },
  setSubmitLamplightersStudentGrantLoading(payload) {
    return {
      type: LamplightersActions.SET_SUBMIT_LAMPLIGHTERS_STUDENT_GRANT_LOADING,
      payload,
    };
  },
  setSubmitLamplightersStudentGrant(payload) {
    return {
      type: LamplightersActions.SET_SUBMIT_LAMPLIGHTERS_STUDENT_GRANT,
      payload,
    };
  },

  getLamplightersStudentGrants(studentId) {
    return async (dispatch) => {
      dispatch(LamplightersActions.setLamplightersStudentGrantsLoading(true));

      let newState = {};
      let success = true;

      const response = await AuthRequest.get(
        `GrantScheduleStudents/student/${studentId}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
          errorStatus: err.response && err.response.status,
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(LamplightersActions.setLamplightersStudentGrants(newState));
    };
  },
  setLamplightersStudentGrantsLoading(payload) {
    return {
      type: LamplightersActions.SET_LAMPLIGHTERS_STUDENT_GRANTS_LOADING,
      payload,
    };
  },
  setLamplightersStudentGrants(payload) {
    return {
      type: LamplightersActions.SET_LAMPLIGHTERS_STUDENT_GRANTS,
      payload,
    };
  },
  clearLamplightersStudentGrants() {
    return {
      type: LamplightersActions.CLEAR_LAMPLIGHTERS_STUDENT_GRANTS,
    };
  },
  getLamplightersEnrollmentSettings(id) {
    return async (dispatch) => {
      dispatch(
        LamplightersActions.setLamplightersEnrollmentSettingsLoading(true),
      );

      let newState = {};
      let success = true;

      const response = await AuthRequest.get(
        `GrantScheduleEnrollments/${id}/shliachSettings`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
          errorStatus: err.response && err.response.status,
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(LamplightersActions.setLamplightersEnrollmentSettings(newState));
    };
  },
  submitLamplightersEnrollmentSettings(id, payload) {
    return async (dispatch) => {
      dispatch(LamplightersActions.setLamplightersEnrollmentSettingsLoading);
      let newState = {
        loading: false,
        success: true,
        ...payload,
      };
      try {
        const { data } = await AuthRequest.post(
          `GrantScheduleEnrollments/${id}/shliachSettings`,
          payload,
        );
        newState.data = data.payload;
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }

      dispatch(LamplightersActions.setLamplightersEnrollmentSettings(newState));
    };
  },
  setLamplightersEnrollmentSettingsLoading(payload) {
    return {
      type: LamplightersActions.SET_LAMPLIGHTERS_ENROLLMENT_SETTINGS_LOADING,
      payload,
    };
  },
  setLamplightersEnrollmentSettings(payload) {
    return {
      type: LamplightersActions.SET_LAMPLIGHTERS_ENROLLMENT_SETTINGS,
      payload,
    };
  },
};
