import React from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { FormPrompt, FormPromptProps } from "./FormPrompt";

export interface FormProps extends FormPromptProps {
  children: any;
  form: UseFormReturn<any>;
  onSubmit: (...args: any[]) => void;
  style?: React.CSSProperties;
}

export const Form = React.memo(function Form({
  children,
  form,
  onSubmit,
  noPrompt,
  promptMessage,
  style = {},
}: FormProps) {
  // force re-render on submission to ensure up-to-date value in getSetFieldValueOptions reference
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _ = form.formState.isSubmitted;

  return (
    <FormProvider {...form}>
      <FormPrompt noPrompt={noPrompt} promptMessage={promptMessage} />
      <form onSubmit={form.handleSubmit(onSubmit)} style={style}>
        {children}
      </form>
    </FormProvider>
  );
});
