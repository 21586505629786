import React from "react";

export default class NotEligibleForEnrollment extends React.PureComponent {
  render() {
    const {
      policy: { programName },
    } = this.props;

    return (
      <div className="flex flex-align-center flex-justify-center full-page-state">
        <img src="/images/not_eligible.svg" alt="" height="240" />
        <p className="fw-700 xxl-text mt-24 mb-40">
          Sorry, it seems that you’re not eligible
        </p>
        <div className="large-text mb-32">
          <p className="mb-8 text-center">
            Only full time Shluchim, and Shluchim in the USA are eligible for
            our {programName || "Life Insurance"} Plan.
          </p>
          <p className="text-center">
            Questions? Please contact Nechoma Dina Dubrowski at{" "}
            <a
              href="mailto:ndd@chabadoncampus.org"
              className="link-text"
              target="_blank"
              rel="noopener noreferrer"
            >
              ndd@chabadoncampus.org
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}
