import * as yup from "yup";

// #region basic validation schemas

export const nullNumberSchema = yup
  .number()
  .transform((val, origValue) => (!origValue ? null : val)); // transform empty strings to null

export const nullDateSchema = yup
  .date()
  .transform((val, origValue) => (!origValue ? null : val)); // transform empty strings to null

export const nullBooleanSchema = yup
  .boolean()
  .transform((val, origValue) => (origValue === "" ? null : val)); // transform empty strings to null

// The DatePicker will return date-only values in the mm/dd/yyyy format to eliminate timezone specification
// We are using a string for the validation schema to ensure that they don't get converted to local date objects upon submission
export const dateOnlySchema = yup.string();

// #endregion

// #region password validation schema //TODO: update with relevant PW requirements

export const pwLengthValidator = (pw: string | null | undefined) =>
  pw && pw.length >= 8;
export const pwUpperCharValidator = (pw: string | null | undefined) =>
  pw?.match(/[A-Z]/g);
export const pwLowerCharValidator = (pw: string | null | undefined) =>
  pw?.match(/[a-z]/g);
export const pwDigitCharValidator = (pw: string | null | undefined) =>
  pw?.match(/[0-9]/g);

export const passwordSchema = (fullValidation = true, nullable = false) =>
  (nullable
    ? yup
        .string()
        .nullable()
        .transform((val) => (!val ? null : val)) // transform empty string value to null
    : yup.string().required("Password is required")
  )
    .min(8, "Password must be at least 8 characters long")
    .test(
      "pw-upper-char",
      "Password must contain at least one uppercase letter",
      (value) => !value || !fullValidation || !!pwUpperCharValidator(value),
    )
    .test(
      "pw-lower-char",
      "Password must contain at least one lowercase letter",
      (value) => !value || !fullValidation || !!pwLowerCharValidator(value),
    )
    .test(
      "pw-digit-char",
      "Password must contain at least one number",
      (value) => !value || !fullValidation || !!pwDigitCharValidator(value),
    );

// #endregion
