import React from "react";
import moment from "moment";

import { Navigation } from "../../../lib";

import CustomSelect from "../../../components/form/CustomSelect";
import ExportCSVButton from "../../../components/ExportCSVButton";
import StudentConfirmationModal from "./StudentConfirmationModal";
import StudentDetailsModal from "./StudentDetailsModal";
import StudentRecommendationModal from "./StudentRecommendationModal";
import StudentsTable from "./StudentsTable";

export default class Students extends React.PureComponent {
  state = {
    searchFocused: false,
    searchInputValue: this.props.pageRoute.query.q || "",
    showStudentConfirmationModal: false,
    showStudentDetailsModal: false,
    showStudentRecommendationModal: false,
    status: this.props.pageRoute.query.f || "",
    updatedRegistrations: {},
  };

  componentDidMount() {
    //if studentId is sent in the url, toggle the student details modal to open on initial page load
    const {
      pageRoute: {
        query: { studentId },
      },
    } = this.props;
    if (studentId) {
      this.toggleStudentDetailsModal(studentId);
    }
  }

  exportHeaders = [
    { label: "First Name", value: "studentFirstName" },
    { label: "Last Name", value: "studentLastName" },
    { label: "Email", value: "studentEmail" },
    { label: "Phone", value: "studentCell" },
    {
      label: "Birthday",
      value: (registration) =>
        registration.dob ? moment(registration.dob).format("MM/DD/YYYY") : null,
    },
    { label: "Age", value: "ageAtTrip" },
    { label: "Gender", value: "gender" },
    { label: "Demographic", value: "classDisplay" },
    { label: "Graduation Year", value: "graduationYear" },
    { label: "Hebrew Name", value: "hebrewName" },
    {
      label: "Student Identifies as Jewish",
      value: (registration) =>
        registration.isJewish
          ? "Y"
          : registration.isJewish === false
          ? "N"
          : "",
    },
    { label: "Shirt Size", value: "tShirtSizeDisplay" },
    { label: "Campus", value: "campusName" },
    { label: "Status", value: "status" },
    { label: "Track", value: "trackName" },
    //waiting for BE to build out
    // { label: 'Tour times', value: 'tourTimes' },
    {
      label: "Date Applied",
      value: (registration) =>
        registration.registrationDate
          ? moment(registration.registrationDate).format("MM/DD/YYYY")
          : null,
    },
    { label: "Amount", value: "total" },
    {
      label: "Early Bird",
      value: (registration) => (registration.wasEarlyBird ? "Y" : "N"),
    },
    {
      label: "Cancelled",
      value: (registration) =>
        registration.cancelledDate
          ? moment(registration.cancelledDate).format("MM/DD/YYYY")
          : null,
    },
    ...(!this.props.trip.event.isTravelTrip
      ? [
          {
            label: "Checked-In",
            value: (registration) =>
              registration.checkedInDate
                ? moment(registration.checkedInDate).format(
                    "ddd, MMM Do YYYY, h:mm a",
                  )
                : null,
          },
        ]
      : []),
    {
      label: "Has Proof Of Vaccination",
      value: ({ hasProofOfVaccination }) => (hasProofOfVaccination ? "Y" : "N"),
    },
  ];

  getExportHeaders = () => {
    return this.exportHeaders;
  };

  getTripRegistrationsForExport = async () => {
    const {
      enrollment: { id: enrollmentID },
      event: { id: eventID },
    } = this.props.trip;
    const {
      query: { q, f },
    } = this.props.pageRoute;
    await this.props.getTripRegistrationsForExport(enrollmentID, eventID, q, f);
    return this.props.trip.registrationsForExport.results;
  };

  onStatusChange = (status) => {
    const page = this.props.pageRoute.query.p || 1;
    const { tab } = this.props.pageRoute.query;
    const { searchInputValue } = this.state;
    this.props.getTripRegistrations(
      1,
      10,
      this.state.searchInputValue,
      this.props.trip.enrollment.id,
      this.props.trip.event.id,
      status,
    );
    Navigation.redirect(
      `${this.props.pageRoute.location.pathname}?tab=${tab}&p=${page}&q=${searchInputValue}&r=10&f=${status}`,
    );
  };

  onUpdateRegistrationStatus = async (
    id,
    status,
    skipStudentRejectionEmail = false,
  ) => {
    this.setState({
      updatedRegistrations: { [id]: { loading: true } },
    });
    const registration = {
      registrationID: id,
      status,
      skipStudentRejectionEmail,
    };
    await this.props.updateTripRegistration(registration);

    if (this.props.updatedRegistration.success) {
      this.refreshRegistrationsList();

      this.setState({
        updatedRegistrations: {
          [id]: { loading: false, status: status },
        },
      });

      if (this.state.showStudentDetailsModal) {
        this.toggleStudentDetailsModal();
      }
    } else {
      this.setState({
        updatedRegistrations: {
          [id]: {
            loading: false,
            errorMessages:
              this.props.updatedRegistration.errorMessage.response.data
                .messages,
          },
        },
      });
      setTimeout(() => {
        this.setState({
          updatedRegistrations: { [id]: { errorMessages: [] } },
        });
      }, 3000);
    }
  };

  search = (searchTerm) => {
    const page = this.props.pageRoute.query.p || 1;
    const status = this.props.pageRoute.query.f || "";
    const { tab } = this.props.pageRoute.query;
    this.props.getTripRegistrations(
      1,
      10,
      searchTerm,
      this.props.trip.enrollment.id,
      this.props.trip.event.id,
    );
    Navigation.redirect(
      `${this.props.pageRoute.location.pathname}?tab=${tab}&p=${page}&q=${searchTerm}&r=10&f=${status}`,
    );
  };

  toggleStudentConfirmationModal = (
    confirmationModalStudent,
    confirmationModalStudentStatus,
  ) => {
    this.setState({
      showStudentConfirmationModal: !this.state.showStudentConfirmationModal,
      confirmationModalStudent,
      confirmationModalStudentStatus,
    });
  };

  toggleStudentDetailsModal = (id) => {
    this.setState({
      showStudentDetailsModal: !this.state.showStudentDetailsModal,
      detailsModalStudentId: id,
    });
  };

  toggleStudentRecommendationModal = (recommendationModalStudent) => {
    this.setState({
      showStudentRecommendationModal:
        !this.state.showStudentRecommendationModal,
      recommendationModalStudent,
    });
  };

  refreshRegistrationsList = () => {
    const { p = 1, r = 10, q = "" } = this.props.pageRoute.query;

    this.props.getTripRegistrations(
      p,
      r,
      q,
      this.props.trip.enrollment.id,
      this.props.trip.event.id,
    );
  };

  render() {
    const {
      trip: {
        event: {
          adminAcceptancePolicyOption,
          isTravelTrip,
          programScheduleName,
          rewardCreditValue,
        },
        registration,
      },
      sys: { tripRegistrationStatuses },
      pageRoute,
      getTripRegistrations,
      trip,
      getTripRegistrationDetails,
      submitRecommendation,
    } = this.props;
    const {
      detailsModalStudentId,
      recommendationModalStudent,
      searchInputValue,
      status,
      searchFocused,
      showStudentDetailsModal,
      showStudentRecommendationModal,
      confirmationModalStudent,
      confirmationModalStudentStatus,
      showStudentConfirmationModal,
      updatedRegistrations,
    } = this.state;

    return (
      <div className="card trip-card full-width">
        <div className="mb-24">
          <p className="xl-text fw-700 mb-24">
            My {programScheduleName || "Trip"} Students
          </p>
        </div>
        <div className="flex flex-align-center mb-16 mobile-block relative students-filters-header">
          <div
            className={
              searchFocused
                ? "search-input search-input-focused"
                : "search-input"
            }
            style={{ width: "312px" }}
          >
            <input
              type="text"
              placeholder="Search by name"
              onChange={(e) => {
                this.setState({ searchInputValue: e.target.value });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") this.search(searchInputValue);
              }}
              value={searchInputValue}
            />
            <i
              className="material-icons accent-text-secondary pointer"
              onClick={() => this.search(searchInputValue)}
            >
              search
            </i>
          </div>
          <div className="ml-24 flex flex-align-center students-filter-by">
            <p className="accent-text-dark mr-8">Filter by</p>
            <div className="filter-by-select">
              <CustomSelect
                name="status"
                onChange={(_, value) => this.onStatusChange(value)}
                options={
                  tripRegistrationStatuses &&
                  tripRegistrationStatuses
                    .filter(
                      (status) =>
                        status.enumValue !==
                        (isTravelTrip ? "CheckedIn" : "Waitlisted"),
                    )
                    .map((status) => ({
                      key: status.displayValue,
                      value: status.enumValue,
                      label: status.displayValue,
                    }))
                }
                placeholder="Select status..."
                defaultValue={
                  status !== "" ? { label: status, value: status } : ""
                }
                isClearable={true}
              />
            </div>
          </div>

          <ExportCSVButton
            className="btn btn-accent students-export-btn"
            fileName="Student-Registrations"
            getExportData={() => this.getTripRegistrationsForExport()}
            getHeaders={() => this.getExportHeaders()}
            title="Export"
          />
        </div>
        <StudentsTable
          adminAcceptancePolicyOption={adminAcceptancePolicyOption}
          getTripRegistrations={getTripRegistrations}
          pageRoute={pageRoute}
          showStudentDetailsModal={showStudentDetailsModal}
          toggleStudentConfirmationModal={this.toggleStudentConfirmationModal}
          toggleStudentDetailsModal={this.toggleStudentDetailsModal}
          toggleStudentRecommendationModal={
            this.toggleStudentRecommendationModal
          }
          trip={trip}
          updatedRegistrations={updatedRegistrations}
        />
        {showStudentRecommendationModal ? (
          <StudentRecommendationModal
            onRecommendationSubmitted={this.refreshRegistrationsList}
            studentData={recommendationModalStudent}
            show={showStudentRecommendationModal}
            submitRecommendation={submitRecommendation}
            toggleStudentRecommendationModal={
              this.toggleStudentRecommendationModal
            }
          />
        ) : showStudentDetailsModal ? (
          <StudentDetailsModal
            adminAcceptancePolicyOption={adminAcceptancePolicyOption}
            getTripRegistrationDetails={getTripRegistrationDetails}
            id={detailsModalStudentId}
            isTravelTrip={isTravelTrip}
            onUpdateRegistrationStatus={this.onUpdateRegistrationStatus}
            programScheduleName={programScheduleName}
            registration={registration}
            rewardCreditValue={rewardCreditValue}
            show={showStudentDetailsModal}
            toggleStudentDetailsModal={this.toggleStudentDetailsModal}
            toggleStudentRecommendationModal={
              this.toggleStudentRecommendationModal
            }
            updatedRegistrations={updatedRegistrations}
          />
        ) : null}
        {showStudentConfirmationModal && (
          <StudentConfirmationModal
            show={showStudentConfirmationModal}
            toggleModal={this.toggleStudentConfirmationModal}
            onUpdate={this.onUpdateRegistrationStatus}
            programScheduleName={programScheduleName}
            studentData={{
              ...confirmationModalStudent,
              status: confirmationModalStudentStatus,
            }}
          />
        )}
      </div>
    );
  }
}
