import React, { memo } from "react";
import { Box, Card, Typography } from "@material-ui/core";

import TripSidebar from "../sidebar/TripSidebar";

interface EnrollmentClosedProps {
  closedDescription?: string;
  getHistoricTripEnrollments: () => {};
  getTripSchedule: () => {};
  pageRoute: any;
  shliachID: number;
  trip: any;
  programScheduleName: string;
}

function EnrollmentClosed(props: EnrollmentClosedProps) {
  const {
    closedDescription,
    getHistoricTripEnrollments,
    getTripSchedule,
    pageRoute,
    shliachID,
    trip,
    programScheduleName,
  } = props;

  return (
    <Box className="flex tablet-block mobile-block container trip-form-cards-container">
      <TripSidebar
        getHistoricTripEnrollments={getHistoricTripEnrollments}
        getTripSchedule={getTripSchedule}
        pageRoute={pageRoute}
        shliachID={shliachID}
        trip={trip}
      />
      <Card className="card trip-card full-width flex flex-align-center flex-justify-center flex-column">
        <img src="/images/closedRegistration.svg" alt="" height="200" />
        <Typography variant="h3" className="mt-24 fw-700">
          {programScheduleName} Registration is {closedDescription || "closed"}
        </Typography>
        <Typography variant="h6" className="fw-500 mb-24">
          Questions? Please contact us at{" "}
          <a
            href="mailto:help@chabadoncampus.org"
            className="link-text"
            target="_blank"
            rel="noopener noreferrer"
          >
            help@chabadoncampus.org
          </a>{" "}
          or{" "}
          <a href="tel:+1-718-510-8181,0" className="link-text">
            718 510 8181 x 0
          </a>
        </Typography>
      </Card>
    </Box>
  );
}
export default memo(EnrollmentClosed);
