import React from "react";
import EventCreatedModal from "./EventCreatedModal";
import EventSettings from "./settings/EventSettings";
import CreateRsvpItem from "../../shared/manage/CreateRsvpItem";

export default function CreateEvent(props) {
  const {
    actions: { getRsvpEventSchedule, clearRsvpEventSchedule },
    eventSchedule,
  } = props;

  return (
    <CreateRsvpItem
      {...props}
      SettingsComponent={EventSettings}
      ModalComponent={EventCreatedModal}
      getRsvpItem={getRsvpEventSchedule}
      clearRsvpItem={clearRsvpEventSchedule}
      eventSchedule={eventSchedule}
    />
  );
}
