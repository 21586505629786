import React from "react";

interface ErrorDisplayProps {
  className: string;
  errorMessage: string;
  imgSrc: string;
}

export default ({ className, errorMessage, imgSrc }: ErrorDisplayProps) => (
  <div className={`text-center ${className ? className : ""}`}>
    <img
      src={imgSrc || "/images/error.svg"}
      alt={imgSrc ? "error" : "error-robot"}
      height="320"
    />
    <p
      className="text-center error-message mt-24 line-height-double"
      style={{ position: "relative" }}
    >
      {errorMessage || "Sorry, something went wrong. Please try again."}
    </p>
  </div>
);
