import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ProgramActions } from "../../state/program/actions";
import { ProgramScheduleActions } from "../../state/programSchedule/actions";

import ProgramDetails from "./ProgramDetails";

export default connect(
  function mapState(state) {
    return {
      program: state.program,
      schedule: state.programSchedule,
      shliachID: state.auth.shliachID,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          getProgram: ProgramActions.getProgram,
          clearProgram: ProgramActions.clearProgram,
          getProgramSchedule: ProgramScheduleActions.getProgramSchedule,
          clearProgramSchedule: ProgramScheduleActions.clearProgramSchedule,
          trackProgramRegistration:
            ProgramScheduleActions.trackProgramRegistration,
        },
        dispatch,
      ),
    };
  },
)(ProgramDetails);
