import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import NoMyCourses from "../NoMyCourses";
import PaginatedTable from "../../../../../../components/PaginatedTableDeprecated";
import { PageURL, Navigation } from "../../../../../../lib";
import CourseScheduleListRow from "./CourseScheduleListRow";
import CourseSchedulesListHeader from "./CourseSchedulesListHeader";
import { EduProgramTypes } from "../../../../eduUtils";
import { AuthSelectors } from "../../../../../../state";

function CourseSchedulesList(props) {
  const {
    addNewScheduleLinkComponent,
    courses: {
      data: { results = [], numberOfRows = 0 } = {},
      loading,
      success,
    },
    eduProgramName,
    eduProgramType,
    getCourses,
    pageRoute,
    resultsView,
  } = props;

  const {
    query,
    query: { p: pageNum = 1 },
    page,
    params,
  } = pageRoute;

  const canScheduleCourses = useSelector(AuthSelectors.canManageEduSchedule);

  const getCourseRecords = useCallback(
    (pageNum) => {
      const newPageNum = pageNum > 1 ? pageNum : undefined;
      const url = PageURL.to(page, params, {
        ...query,
        p: newPageNum,
      });
      Navigation.redirect(url);

      setTimeout(() => getCourses(newPageNum), 0);
    },
    [query, page, params, getCourses],
  );

  const getURLForPagination = useCallback(
    (pageNum) =>
      PageURL.to(page, params, {
        ...query,
        p: pageNum,
      }),
    [query, page, params],
  );

  const addNewScheduleBtn =
    eduProgramType === EduProgramTypes.YourIsrael &&
    canScheduleCourses &&
    addNewScheduleLinkComponent;

  return success && !loading && !results.length ? (
    <NoMyCourses
      actionLink={addNewScheduleLinkComponent}
      eduProgramName={eduProgramName}
      pageRoute={pageRoute}
    />
  ) : (
    <div className="card full-width">
      <div className="flex flex-align-center flex-justify-space">
        <p className="xxl-text fw-700">View All Courses</p>
        {addNewScheduleBtn}
      </div>
      <PaginatedTable
        className="students-table"
        getURL={getURLForPagination}
        loadData={getCourseRecords}
        loading={loading}
        name="Schedules"
        page={pageNum}
        records={results}
        renderHeaderRow={() => <CourseSchedulesListHeader />}
        renderRow={(schedule, index) => (
          <CourseScheduleListRow
            key={index}
            pageRoute={pageRoute}
            schedule={schedule}
          />
        )}
        resultsView={resultsView}
        success={success}
        totalCount={numberOfRows}
      />
    </div>
  );
}

export default memo(CourseSchedulesList);
