import React, { forwardRef, useCallback } from "react";
import { FormHelperText, InputLabel } from "@material-ui/core";
import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from "@material-ui/lab";
import { Option } from "../../../lib/types";

type ToggleInputOption = {
  disabled?: boolean;
  id: string | number | boolean;
  width?: string;
  color?: any;
} & Omit<Option, "id">;

export type ToggleInputProps = {
  disabled?: boolean;
  enforceValue?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  label?: string | JSX.Element;
  name: string;
  onChange: (name: string, val: any) => void;
  options: ToggleInputOption[];
  value: any;
} & Omit<ToggleButtonGroupProps, "onChange">;

export const ToggleInput = React.memo(
  forwardRef(
    /**
     *
     */
    function ToggleInput(
      {
        disabled,
        enforceValue,
        error,
        fullWidth = true,
        helperText,
        id,
        label,
        name,
        onChange,
        options,
        size = "small",
        value,
        ...passProps
      }: ToggleInputProps,
      ref: React.Ref<any> | null,
    ) {
      const handleChange = useCallback(
        (_, newValue: string | null) => {
          if (!enforceValue || newValue) {
            onChange(name, newValue);
          }
        },
        [enforceValue, name, onChange],
      );

      return (
        <>
          {label && (
            <InputLabel error={error} htmlFor={id || name}>
              {label}
            </InputLabel>
          )}
          <ToggleButtonGroup
            exclusive
            id={id || name}
            onChange={handleChange}
            ref={ref}
            size={size}
            style={fullWidth ? { width: "100%" } : {}}
            value={value}
            {...passProps}
          >
            {options.map((o) => (
              <ToggleButton
                disabled={disabled || o.disabled}
                key={o.id.toString()}
                style={{
                  color: "#1E1C1C",
                  width: o.width || (fullWidth ? "100%" : undefined),
                  ...(size === "small" ? { padding: "7.5px 16px" } : {}),
                  ...(value === o.id ? { backgroundColor: "#FFC602" } : {}),
                  ...(error ? { borderColor: "#e94746" } : {}),
                }}
                value={o.id}
              >
                {o.display || o.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {helperText && (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          )}
        </>
      );
    },
  ),
);
