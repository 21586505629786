import React, { memo, useState } from "react";
import { pluralizeText } from "../../../lib";

interface TeamsDirectoryTableRowProps {
  contact: any; //TODO: define contact type in state
}

function TeamsDirectoryTableRow(props: TeamsDirectoryTableRowProps) {
  const [showProgramsMobile, setShowProgramsMobile] = useState<boolean>(false);
  const { contact } = props;

  return (
    <div>
      <div className="table-row directory-table-row teams-directory-table-row">
        <div>
          <p className="table-label tablet-hidden mobile-hidden">Name</p>
          <p className="table-data directory-contact-name">
            {contact.titleDisplay} {contact.firstName} {contact.lastName}
          </p>
        </div>
        <div>
          <p className="table-label tablet-hidden mobile-hidden">Position</p>
          <p className="table-data">{contact.jobDescription}</p>
        </div>
        <div className="directory-contact-contact-info">
          <div>
            <p className="table-label tablet-hidden mobile-hidden">Email</p>
            <p className="table-data email">
              {contact.email && (
                <a className="link-text" href={`mailto:${contact.email}`}>
                  <i className="material-icons accent-text-secondary desktop-hidden">
                    email
                  </i>
                  <span>{contact.email}</span>
                </a>
              )}
            </p>
          </div>

          <div>
            <p className="table-label tablet-hidden mobile-hidden">
              Work Phone
            </p>
            <p className="table-data">
              {contact.workPhone && (
                <a
                  className="link-text"
                  href={`tel:${contact.workPhone.replace(" Ext: ", ",")}`}
                >
                  <i className="material-icons accent-text-secondary desktop-hidden">
                    phone
                  </i>
                  {contact.workPhone}
                </a>
              )}
            </p>
          </div>
          <div>
            <p className="table-label tablet-hidden mobile-hidden">
              Cell Phone
            </p>
            <p className="table-data">
              {contact.cell && (
                <a className="link-text" href={`tel:${contact.cell}`}>
                  <i className="material-icons accent-text-secondary desktop-hidden">
                    phone
                  </i>
                  {contact.cell}
                </a>
              )}
            </p>
          </div>
        </div>
        {!!contact.programs?.length && (
          <div className="directory-contact-programs-info mt-16">
            <p className="table-label mobile-hidden">Programs</p>
            <p
              className="table-label pointer mb-8 flex flex-align-center desktop-hidden tablet-hidden"
              onClick={() => setShowProgramsMobile(!showProgramsMobile)}
            >
              {contact.programs.length}{" "}
              {pluralizeText("Program", contact.programs.length)}
              <i className="material-icons large-text ml-8">
                {showProgramsMobile
                  ? "keyboard_arrow_up"
                  : "keyboard_arrow_down"}
              </i>
            </p>
            <div
              className={`tag-container ${
                !showProgramsMobile ? "mobile-hidden" : ""
              }`}
            >
              {contact.programs.map((p: any) => (
                <p className="tag medium light rounded" key={p.id}>
                  {p.name}
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(TeamsDirectoryTableRow);
