import React, { memo, useCallback } from "react";
import { Typography, Grid } from "@material-ui/core";
import { PageNavLink } from "../lib";
import {
  activityMenuItems,
  eventMenuItems,
  smsMenuItems,
  studentMenuItems,
} from "../pages/engagements/menuItems";
import { Lock } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { AuthSelectors } from "../state";

interface EngagementMenuLinkProps {
  url: string;
  text: string;
  disabled?: boolean;
}

const EngagementMenuLink = memo(function ({
  url,
  text,
  disabled = false,
}: EngagementMenuLinkProps) {
  const handleClick = useCallback(
    (e) => {
      if (disabled) e.preventDefault();
    },
    [disabled],
  );

  return (
    <PageNavLink
      className={`fw-500 link-text-secondary line-height-double mt-8 engagement-menu-link ${
        disabled ? "disabled" : ""
      }`}
      exact={true}
      activeClassName="active-nav-link"
      onClick={handleClick}
      to={url}
      key={text}
    >
      {text} {disabled && <Lock fontSize="small" className="lock-icon" />}
    </PageNavLink>
  );
});

function DynamicEngagementMenu() {
  const {
    isEnrolledInEngagement,
    isEnrolledInRsvp,
    isEnrolledInMessaging,
    isPendingForRsvpEnrollment,
  } = useSelector(AuthSelectors.engagementAccess);

  return (
    <Grid
      container
      className={`program-dropdown-box engagement dropdown-box`}
      justifyContent="space-between"
      spacing={2}
    >
      <Grid item xs={3}>
        <Typography variant="subtitle2">Students</Typography>
        {studentMenuItems.map(({ url, text }) => (
          <EngagementMenuLink url={url} key={text} text={text} />
        ))}
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle2">Activity</Typography>
        {activityMenuItems.map(
          ({ url, text, isEnrolledInEngagementRequired }) => (
            <EngagementMenuLink
              url={url}
              key={text}
              disabled={
                isEnrolledInEngagementRequired && !isEnrolledInEngagement
              }
              text={text}
            />
          ),
        )}
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle2">RSVP</Typography>
        {eventMenuItems.map(({ url, text }) => (
          <EngagementMenuLink
            url={url}
            key={text}
            disabled={!isEnrolledInRsvp && !isPendingForRsvpEnrollment}
            text={text}
          />
        ))}
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle2">Messages</Typography>
        {smsMenuItems.map(({ url, text }) => (
          <EngagementMenuLink
            url={url}
            key={text}
            disabled={!isEnrolledInMessaging}
            text={text}
          />
        ))}
      </Grid>
    </Grid>
  );
}

export default memo(DynamicEngagementMenu);
