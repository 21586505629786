import React from "react";
import ExportCSVButton from "../../../../../components/ExportCSVButton";
import { AuthRequest } from "../../../../../lib";
import moment from "moment";

export default class ExportStudentSurveyResponsesButton extends React.PureComponent {
  getStudentSurveyResponses = async () => {
    const { courseScheduleId, eduEnrollmentId } = this.props;

    const response = await AuthRequest.get(
      `EduChabadHouses/${eduEnrollmentId}/CourseSchedules/${courseScheduleId}/SurveyResponses`,
    );
    return response.data.payload;
  };

  getStudentSurveyResponsesExportHeaders = (data) => {
    //build survey questions list with all questions within data records
    const surveyQuestions = [];
    data &&
      data.forEach((s) =>
        s.questionResponses.forEach(({ questionText }) => {
          if (surveyQuestions.indexOf(questionText) < 0) {
            surveyQuestions.push(questionText);
          }
        }),
      );

    return [
      { label: "Course Schedule", value: "courseScheduleName" },
      { label: "First Name", value: "studentFirstName" },
      { label: "Last Name", value: "studentLastName" },
      { label: "Email", value: "studentEmail" },
      { label: "Phone", value: "studentCell" },
      { label: "Gender", value: "studentGender" },
      { label: "Graduation Year", value: "studentGraduationYear" },
      { label: "Campus", value: "studentCampusName" },
      {
        label: "Review Date",
        value: ({ responseCreatedDate }) =>
          responseCreatedDate
            ? moment(responseCreatedDate).format("MM/DD/YYYY hh:mm a")
            : "",
      },
      ...surveyQuestions.map((question, qIndex) => ({
        key: `question-${qIndex}`,
        label: question,
        value: ({ questionResponses }) => {
          var response =
            questionResponses &&
            questionResponses.find((qr) => qr.questionText === question);
          if (response && response.answers) {
            return response.answers.join(", ");
          }
          return "";
        },
      })),
    ];
  };

  render() {
    const { disabled, fileName } = this.props;

    return (
      <ExportCSVButton
        className="btn btn-accent ml-16 mobile-ml-0"
        disabled={disabled}
        fileName={fileName}
        getExportData={this.getStudentSurveyResponses}
        getHeaders={this.getStudentSurveyResponsesExportHeaders}
        title="Export Reviews"
      />
    );
  }
}
