import Wizard from "./Wizard.vm";

export const SmsPages = {
  wizard: {
    anon: false,
    path: "/sms/wizard/:pageView?",
    title: "Sms Wizard",
    type: "PAGE_WIZARD",
    view: Wizard,
  },
};
export default SmsPages;

export const SmsArea = {
  pages: SmsPages,
};
