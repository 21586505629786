import React from "react";
import { Button, Typography } from "@material-ui/core";

import { withAppInsights } from "../../lib/AppInsights";
import { PageLink } from "../../lib";
import Pages from "..";

import Loader from "../../components/Loader";

class Updates extends React.PureComponent {
  state = {
    page: 1,
    results: 5,
    searchTerm: "",
    searchFocused: false,
  };

  componentDidMount() {
    const { getPosts } = this.props.actions;
    let { results, searchTerm } = this.state;
    getPosts(results, searchTerm);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pageRoute.location.key !== this.props.pageRoute.location.key
    ) {
      this.setState({ searchTerm: "" });
      this.props.actions.getPosts(5, "");
    }
  }

  async getMorePosts() {
    const { getPosts } = this.props.actions;
    let { page, results, searchTerm } = this.state;
    page += page;
    results = 5 * page;
    this.setState({ page: page, results: results, loadingMore: true });
    await getPosts(results, searchTerm);
    this.setState({ loadingMore: false });
  }

  searchPrograms = async () => {
    this.setState({ searching: true });
    const { getPosts } = this.props.actions;
    let { results, searchTerm } = this.state;
    if (searchTerm.length < 1) {
      this.setState({ searchFocused: false });
    }
    await getPosts(results, searchTerm);
    this.setState({ searching: false });
  };

  render() {
    let { loading, posts = [], totalPosts } = this.props;
    let { loadingMore, results, searching } = this.state;
    return (
      <div className="updates-page page">
        <Typography variant="h4" className="mobile-hidden mb-24">
          Latest News & Updates
        </Typography>
        {loading && posts.length < 1 ? null : (
          <div
            className={
              this.state.searchFocused
                ? "search-input search-input-focused mb-40"
                : "search-input mb-40"
            }
          >
            <input
              type="text"
              placeholder="Search..."
              onChange={(e) => {
                this.setState({ searchTerm: e.target.value });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") this.searchPrograms();
              }}
              onFocus={() => this.setState({ searchFocused: true })}
              onBlur={() => this.setState({ searchFocused: false })}
              value={this.state.searchTerm}
            />
            <i
              className="material-icons accent-text-secondary pointer"
              onClick={() => this.searchPrograms()}
            >
              search
            </i>
          </div>
        )}
        {loading && (!loadingMore || searching) ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : posts.length > 0 ? (
          <>
            {posts.map((p) => {
              let imageURL =
                p._embedded["wp:featuredmedia"] &&
                p._embedded["wp:featuredmedia"][0].source_url;
              let getTitle = () => {
                return { __html: p.title.rendered };
              };
              let date = new Date(p.date).toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              });
              let getContent = () => {
                return { __html: p.content.rendered };
              };
              return (
                <PageLink
                  to={Pages.updates.updateDetails}
                  params={{ updateId: p.id }}
                  key={p.id}
                  className="card post-card mb-8"
                >
                  <img src={imageURL || "/images/placeholder.svg"} alt="" />
                  <div className="post-details">
                    <p
                      className="large-text fw-700"
                      dangerouslySetInnerHTML={getTitle()}
                    />
                    <p className="small-text accent-text mt-8 mb-16">{date}</p>
                    <div
                      className="post-content"
                      dangerouslySetInnerHTML={getContent()}
                    />
                  </div>
                </PageLink>
              );
            })}
            {loading ? (
              <div className="more-updates-loader">
                <Loader />
              </div>
            ) : (
              results < totalPosts && (
                <div className="more-updates-btn-container">
                  <Button
                    variant="contained"
                    color="primary"
                    className="more-updates-btn"
                    onClick={() => this.getMorePosts()}
                  >
                    More News & Updates
                  </Button>
                </div>
              )
            )}
          </>
        ) : (
          <div className="empty-state">
            <img src="/images/no_results.svg" alt="no results" />
            <>
              <p className="xxl-text mt-24 mb-16">No results found</p>
              <p className="medium-text accent-text fw-500">
                Try adjusting your search terms
              </p>
            </>
          </div>
        )}
      </div>
    );
  }
}

export default withAppInsights(Updates);
