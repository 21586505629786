import React from "react";
import ErrorDisplay from "../../components/ErrorDisplay";
import Loader from "../../components/Loader";
import ProgramBreadcrumbsHeader from "../../components/ProgramBreadcrumbsHeader";
import LamplightersSchedulesList from "./LamplightersSchedulesList";
import LamplightersScheduleActivity from "./schedule/LamplightersScheduleActivity";
import LamplightersScheduleDashboard from "./schedule/LamplightersScheduleDashboard";
import LamplightersScheduleNotStarted from "./schedule/LamplightersScheduleNotStarted";
import LamplightersScheduleStudents from "./schedule/LamplightersScheduleStudents";
import LamplightersScheduleTabs from "./schedule/LamplightersScheduleTabs";
import LamplightersScheduleWidget from "./schedule/LamplightersScheduleWidget";
import LamplightersScheduleSettings from "./schedule/LamplightersScheduleSettings";
import NoLamplightersAccess from "./NoLamplightersAccess";
import { withAppInsights } from "../../lib/AppInsights";

class Lamplighters extends React.Component {
  tabs = [
    {
      component: LamplightersScheduleDashboard,
      id: "dashboard",
      title: "Dashboard",
    },
    {
      component: LamplightersScheduleStudents,
      id: "students",
      title: "Students",
    },
    {
      component: LamplightersScheduleActivity,
      id: "activity",
      title: "Activity",
    },
    {
      component: LamplightersScheduleSettings,
      id: "settings",
      title: "Settings",
    },
  ];

  componentDidUpdate(prevProps) {
    const {
      pageRoute: {
        params: { programScheduleId },
      },
    } = this.props;
    if (
      programScheduleId &&
      prevProps.pageRoute.params.programScheduleId !== programScheduleId
    ) {
      this.getScheduleDetails(programScheduleId);
    }
  }

  componentWillUnmount() {
    //clear schedule before unmounting to avoid initial mount and re-mount on get schedule details when returning to lamplighters page
    this.props.actions.clearLamplightersSchedule();
  }

  getSchedules = async () => {
    const {
      actions: { getLamplightersSchedules },
    } = this.props;
    await getLamplightersSchedules();
  };

  getScheduleDetails = async () => {
    const {
      actions: { getLamplightersSchedule },
      pageRoute: {
        params: { programScheduleId },
      },
    } = this.props;
    await getLamplightersSchedule(programScheduleId, true);
  };

  getGrantName = (grantTypeDisplay, grantDescription) =>
    `${grantTypeDisplay} Grants${
      grantDescription ? ` - ${grantDescription}` : ""
    }`;

  render() {
    const { actions, lamplighters, pageRoute, sys } = this.props;

    const {
      enrollment: {
        data: enrollment,
        errorStatus: enrollmentErrorStatus,
        errorMessage: enrollmentErrorMessage,
        loading: enrollmentLoading,
      },
      schedules,
      schedules: { data: schedulesList, loading: schedulesLoading },
      schedule: {
        data: schedule,
        errorMessage: scheduleErrorMessage,
        errorStatus: scheduleErrorStatus,
        loading: scheduleLoading,
      },
    } = lamplighters;

    const {
      params: { programScheduleId },
      query: { tab },
    } = pageRoute;

    const currentTab =
      (tab && this.tabs.find((t) => t.id.toString() === tab.toString())) ||
      this.tabs[0];

    const loading = schedulesLoading || scheduleLoading || enrollmentLoading;
    const errorMessage = scheduleErrorMessage || enrollmentErrorMessage;
    const noAccessError =
      (scheduleErrorMessage || enrollmentErrorMessage) &&
      (scheduleErrorStatus === 403 || enrollmentErrorStatus === 404);

    return (
      <div className="lamplighters-page page">
        <div className="container" style={{ paddingTop: "16px" }}>
          <ProgramBreadcrumbsHeader scheduleId={programScheduleId} />
        </div>

        <div
          className="flex tablet-block mobile-block container"
          style={{ alignItems: "flex-start", height: "calc(100% - 40px)" }}
        >
          <div className="mr-24">
            <LamplightersSchedulesList
              getScheduleDetails={this.getScheduleDetails}
              getSchedules={this.getSchedules}
              pageRoute={pageRoute}
              schedules={schedules}
            />
            <LamplightersScheduleTabs
              currentTab={currentTab}
              pageRoute={pageRoute}
              tabs={this.tabs}
            />
            {schedule && (
              <LamplightersScheduleWidget
                enrollment={enrollment}
                getGrantName={this.getGrantName}
                schedule={schedule}
              />
            )}
          </div>
          <div className="card lamplighters-card full-width">
            {loading ? (
              <Loader />
            ) : noAccessError ? ( //no access to requested lamplighters schedule
              <NoLamplightersAccess
                scheduleName={
                  schedule
                    ? schedule.programScheduleName
                    : "this Lamplighters schedule"
                }
              />
            ) : errorMessage ? (
              <ErrorDisplay
                errorMessage={
                  errorMessage === "Event404"
                    ? "No additional details available for this schedule"
                    : "Our server is being stubborn. Please try again."
                }
                imgSrc={`/images/${
                  errorMessage === "Event404" ? "not_available" : "error"
                }.svg`}
              />
            ) : schedulesList && !schedulesList.length ? ( //no access to any lamplighters schedules
              <NoLamplightersAccess />
            ) : (
              schedule &&
              schedule.id &&
              programScheduleId?.toString() ===
                schedule.programScheduleID.toString() && // ensure that schedule details are rendered only when the schedule for the correct url query param id is loaded (to avoid duplicate renders when switching between schedules where query param is updated prior to schedule retrieval)
              (!schedule.didScheduleStart ? (
                <LamplightersScheduleNotStarted schedule={schedule} />
              ) : (
                React.createElement(currentTab.component, {
                  actions,
                  getGrantName: this.getGrantName,
                  ...lamplighters,
                  pageRoute,
                  refreshEnrollment: () =>
                    actions.getLamplightersEnrollment(schedule.id, true),
                  sys,
                })
              ))
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withAppInsights(Lamplighters);
