import React from "react";
import { Box } from "@material-ui/core";
import EngagementSideBar from "../pages/engagements/EngagementSideBar";
import { withAppInsights } from "../lib";
import { useMobile } from "../themes";
import DefaultAppBar from "../components/DefaultAppBar";
import { LayoutProps } from "../state/types";
import { useStyles } from "./Engagement.styles";
import { useSelector } from "react-redux";
import { AuthSelectors } from "../state";
import InternsAppBar from "../components/InternsAppBar";

function EngagementLayout({ children }: LayoutProps) {
  const isMobile = useMobile();
  const classes = useStyles();
  const isIntern = useSelector(AuthSelectors.isLoggedInAsIntern);

  if (isIntern) {
    return <InternsAppBar>{children}</InternsAppBar>;
  }

  return (
    <DefaultAppBar>
      <Box className={classes.mainContainer}>
        {!isMobile && <EngagementSideBar />}
        <Box className={classes.component}>{children}</Box>
      </Box>
    </DefaultAppBar>
  );
}

export default withAppInsights(EngagementLayout);
