import React from "react";
import Select from "react-select";
import Search from "../../../../components/Search";
import { Navigation, PageURL } from "../../../../lib";

export default function ActivityListFilters(props) {
  const {
    pageRoute: { query, page, params },
  } = props;

  const { rt = "", se = "", so = "" } = query;

  const onChange = (name, val) => {
    const url = PageURL.to(page, params, {
      ...query,
      [name]: val ? encodeURIComponent(val) : undefined,
      p: undefined, //reset page to 1
    });
    Navigation.redirect(url);
  };

  const rtOptions = [
    { label: "All Time", value: "" },
    { label: "Past 6 months", value: "last6Months" },
    { label: "Past year", value: "lastYear" },
    { label: "Past 2 years", value: "last2Years" },
  ];

  const soOptions = [
    { label: "Date Desc", value: "dateDescending" },
    { label: "Date Asc", value: "dateAscending" },
    { label: "Name", value: "name" },
  ];

  return (
    <div className="flex flex-align-center mobile-block">
      <Search
        value={decodeURIComponent(se)}
        onSearch={onChange}
        style={{ width: "auto" }}
        placeholder="Search activity"
      />
      <div className="flex flex-align-center ml-16 mobile-ml-0 filter-width">
        <p className="mr-8 nowrap-text">Filter by</p>
        <Select
          className="filter-select"
          classNamePrefix="filter-select"
          defaultValue={rtOptions[0]}
          isSearchable={false}
          onChange={(val) => onChange("rt", val.value)}
          options={rtOptions}
          value={
            rtOptions.find(
              (t) => t.value.toString() === decodeURIComponent(rt),
            ) || rtOptions[0]
          }
        />
      </div>
      <div className="flex flex-align-center ml-16 mobile-ml-0 filter-width">
        <p className="mr-8 nowrap-text">Sort by</p>
        <Select
          className="filter-select"
          classNamePrefix="filter-select"
          defaultValue={soOptions[0]}
          isSearchable={false}
          onChange={(val) => onChange("so", val.value)}
          options={soOptions}
          value={
            soOptions.find((s) => s.value === decodeURIComponent(so)) ||
            soOptions[0]
          }
        />
      </div>
    </div>
  );
}
