export const Categories = {
  Folders: "Folders",
  Images: "Images",
  Audios: "Audios",
  Videos: "Videos",
  Documents: "Documents",
};

export const FolderItemType = {
  Folder: "Folder",
  File: "File",
};
