import { createTheme, useMediaQuery, useTheme } from "@material-ui/core";

export const defaultTheme = createTheme({
  palette: {
    primary: { main: "#2774AE", contrastText: "#FFFFFF", light: "#2774ae29" },
    secondary: { main: "#FFC602", contrastText: "#00175C" },
    success: { main: "#4FCEC5", dark: "#49ABA4" },
    error: { main: "#FF6B6B", dark: "#CC5C5C" },
    warning: { main: "#F3A94F" },
    info: { contrastText: "#0D324E", main: "#2774ae29" },
  },
  typography: {
    h1: {
      fontFamily: "AvenirBlack",
      fontSize: 58,
      "@media only screen and (max-width: 560px)": {
        fontSize: 24,
      },
    },
    h2: {
      fontFamily: "AvenirBlack",
      fontSize: 35,
      letterSpacing: 0.38,
    },
    h3: {
      fontFamily: "Archivo",
      fontSize: 34,
      fontWeight: 800,
      letterSpacing: 0,
      "@media only screen and (max-width: 560px)": {
        fontSize: 20,
      },
    },
    h4: {
      fontFamily: "AvenirBlack",
      fontSize: 24,
      fontWeight: 800,
      letterSpacing: 0,
      "@media only screen and (max-width: 560px)": {
        fontSize: 20,
      },
    },
    h5: {
      fontFamily: "AvenirBlack",
      fontSize: 20,
      fontWeight: 500,
    },
    h6: {
      fontFamily: "Archivo",
      fontSize: 18,
      fontWeight: 800,
      letterSpacing: 0,
    },
    subtitle1: {
      fontFamily: "AvenirHeavy",
      fontSize: 16,
      letterSpacing: 0,
    },
    subtitle2: {
      fontFamily: "AvenirHeavy",
      fontSize: 14,
      letterSpacing: 0,
    },
    body1: {
      fontFamily: "AvenirRoman",
      fontSize: 16,
      letterSpacing: 0,
    },
    body2: {
      fontFamily: "AvenirRoman",
      fontSize: 14,
      letterSpacing: 0,
    },
    //TODO: When switch to MUI5 everything thats body2 with color: "#747579" should change to body2Medium
    //Everything thats body2 change to body2Dark
    // body2Light: {
    //   fontFamily: "Avenir",
    //   fontSize: 14,
    //   letterSpacing: 0,
    //   color: "#B4B5B9",
    // },
    // body2Medium: {
    //   fontFamily: "Avenir",
    //   fontSize: 14,
    //   letterSpacing: 0,
    //   color: "#747579",
    // },
    // body2Dark: {
    //   fontFamily: "Avenir",
    //   fontSize: 14,
    //   letterSpacing: 0,
    //   color: "#1E1C1C",
    // },
  },
  overrides: {
    MuiDialog: {
      paperFullWidth: {
        "@media only screen and (max-width: 560px)": {
          width: "100%",
          margin: 0,
          padding: 0,
          maxHeight: "100%",
          borderRadius: 0,
        },
      },
    },
    MuiTabs: {
      root: {
        margin: "0 -24px",
        "@media only screen and (max-width: 560px)": {
          margin: "0 -16px",
        },
        borderBottom: "1px solid #E0E0E0",
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: "#F4F4F5",
        boxShadow: "none",
        "& .MuiButton-label": { whiteSpace: "nowrap" },
        "&:hover": { boxShadow: "none" },
        "&.MuiButton-containedPrimary.Mui-disabled": {
          backgroundColor: "#2774ae8a",
          color: "#FFFFFF",
        },
        "&.MuiButton-containedSecondary.Mui-disabled": {
          backgroundColor: "#ffc60278",
        },
      },
    },
  },
});
/**
 * Returns `true` if the screen is sized for mobile. Depends on theme
 * breakpoints, otherwise this function would be defined in `lib/hooks.js`
 */
export function useMobile(size: any = "xs") {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(size));
}
