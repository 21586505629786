import React from "react";
import Select from "react-select";
import { Navigation, PageURL } from "../../../../../../lib";
import debounce from "lodash.debounce";

export default class StudentsListFilters extends React.PureComponent {
  onChange = (name, val, debounce) => {
    const {
      pageRoute: { query, page, params },
    } = this.props;

    const url = PageURL.to(page, params, {
      ...query,
      [name]: val ? encodeURIComponent(val) : undefined,
      p: undefined, //reset page to 1
    });
    Navigation.redirect(url);

    setTimeout(debounce ? this.onFilterDebounce : this.onFilter, 0);
  };

  onFilter = () => {
    this.props.getStudents();
  };
  onFilterDebounce = debounce(this.onFilter, 500);

  render() {
    const {
      registrationStatuses,
      sortOptions: soOptions,
      pageRoute: { query },
    } = this.props;

    const { se = "", st = "", so = "" } = query;

    const stOptions = [
      { label: "All Statuses", value: "" },
      ...(registrationStatuses
        ? registrationStatuses.map((s) => ({
            value: s.enumValue,
            label: s.displayValue,
          }))
        : []),
    ];

    return (
      <div className="flex flex-justify-space flex-align-center mobile-block full-width students-filters">
        <div className="search-input">
          <input
            name="se"
            onChange={(event) =>
              this.onChange(event.target.name, event.target.value, true)
            }
            placeholder="Search by Name"
            type="text"
            value={decodeURIComponent(se)}
          />
          <i className="material-icons accent-text-secondary pointer">search</i>
        </div>
        <div className="flex flex-justify-space">
          <div className="flex flex-align-center mr-16 mobile-ml-0 filter-width">
            <p className="mr-8 nowrap-text">Filter by</p>
            <Select
              className="filter-select"
              classNamePrefix="filter-select"
              defaultValue={stOptions[0]}
              isSearchable={false}
              onChange={(val) => this.onChange("st", val.value)}
              options={stOptions}
              value={
                stOptions.find((s) => s.value === decodeURIComponent(st)) ||
                stOptions[0]
              }
            />
          </div>
          <div
            className="flex flex-align-center mr-16 mobile-ml-0"
            style={{ width: "255px" }}
          >
            <p className="mr-8 nowrap-text">Sort by</p>
            <Select
              className="filter-select"
              classNamePrefix="filter-select"
              defaultValue={soOptions[0]}
              isSearchable={false}
              onChange={(val) => this.onChange("so", val.value)}
              options={soOptions}
              value={
                soOptions.find((s) => s.value === decodeURIComponent(so)) ||
                soOptions[0]
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
