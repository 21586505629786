import React from "react";
import ResourceCard from "./ResourceCard";
import NoResources from "./NoResources";
import RsvpItemsList from "../../shared/list/RsvpItemsList";

export default function ResourcesList(props) {
  const { getResources, resources, hasResources, pageRoute, resultsView } =
    props;

  return (
    <RsvpItemsList
      rsvpType="resources"
      getRsvpItems={getResources}
      rsvpItems={resources}
      noRecordsDisplay={!hasResources && <NoResources pageRoute={pageRoute} />}
      renderCardComponent={(r, index) => (
        <ResourceCard resource={r} key={index} pageRoute={pageRoute} />
      )}
      gridClass="rsvp-resources-cards-grid"
      pageRoute={pageRoute}
      resultsView={resultsView}
    />
  );
}
