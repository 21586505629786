import React, { Fragment, useEffect, useState } from "react";
import MergeDuplicateRow from "./MergeDuplicateRow";
import _isEqual from "lodash.isequal";
const selectedInputColor = "#33b7ae";
const MergeDuplicateSection = (props) => {
  const {
    sectionFields,
    students,
    ids,
    sectionName,
    sectionTitle,
    showAllFields,
    selectedSectionIndex,
    setSelectedSectionIndex,
    selectAllSectionFields,
    selectedAllIndex,
    setShowAccountDetailsInHeader,
    resolveField,
  } = props;
  const isEqual = (values) => {
    return values.every((item) => _isEqual(item, values[0]));
  };

  const [matchingFields, setMatchingFields] = useState([]);

  const hasNonMatchingFields = matchingFields?.length !== sectionFields?.length;

  useEffect(() => {
    if (sectionName === "account") {
      setShowAccountDetailsInHeader(!hasNonMatchingFields);
    }
  }, [hasNonMatchingFields, sectionName, setShowAccountDetailsInHeader]);

  return (
    <>
      {(showAllFields || hasNonMatchingFields) && (
        <>
          <div className="section-divider fw-700 medium-text">
            {sectionTitle}
          </div>
          {sectionName !== "account" && (
            <div
              className="field-row"
              style={{
                gridTemplateColumns: `180px repeat(${students.length}, 300px)`,
              }}
            >
              <p className="mb-16" />

              {students.map((val, studentIndex) => (
                <div key={studentIndex}>
                  <div
                    className="exclude-student mb-12"
                    style={{
                      borderColor:
                        selectedSectionIndex[sectionName] === studentIndex
                          ? selectedInputColor
                          : "#edecec",
                    }}
                  >
                    <p className="accent-text medium-text merge-radio-container">
                      <input
                        type="radio"
                        value={studentIndex || ""}
                        style={{ marginRight: 8 }}
                        checked={
                          selectedSectionIndex[sectionName] === studentIndex
                        }
                        onChange={() => {
                          setSelectedSectionIndex({
                            ...selectedSectionIndex,
                            [sectionName]: studentIndex,
                          });
                          selectAllSectionFields(studentIndex, sectionName);
                        }}
                      />
                      Select all fields in this section
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
      {sectionFields?.map((props, index) => {
        const showEmailFieldNote =
          props.name === "email" && !matchingFields.includes("email");
        return (
          <Fragment key={index}>
            <MergeDuplicateRow
              {...props}
              students={students}
              isEqual={(values) => isEqual(values, props.name)}
              equalFields={matchingFields}
              setEqualFields={setMatchingFields}
              ids={ids}
              resolveField={(val, name) => {
                resolveField(val, name);
              }}
              selectedAllIndex={selectedAllIndex}
              selectedSectionIndex={selectedSectionIndex[sectionName]}
              showAllFields={showAllFields}
            />
            {showEmailFieldNote && (
              <div
                className="merge-duplicates-warning mb-12"
                style={{
                  width: `${students.length * 312 - 12}px`,
                }}
              >
                <p className="flex flex-align-center fw-700 mb-8">
                  <i className="material-icons mr-4">warning</i>
                  Note regarding email selection:
                </p>
                <p className="mb-8">
                  • The merged student will receive the user login and credit
                  card on file of the student with the selected email.
                </p>
                <p>
                  • Only one email can be saved per student. If you would like
                  to save the other email addresses, please copy them into the
                  student notes.
                </p>
              </div>
            )}
          </Fragment>
        );
      })}
    </>
  );
};
export default React.memo(MergeDuplicateSection);
