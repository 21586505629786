import { StudentsActions } from "./actions";
import { AuthActions } from "../auth/actions";

export const StudentsState = {
  name: "students",
  persist: false,
  defaults: {
    student: {
      loading: false,
    },
    submitStudent: {
      loading: false,
    },
    students: {
      loading: false,
    },
    studentCampuses: {
      loading: false,
    },
    studentsImport: {
      loading: false,
    },
    studentProfileSettings: {
      loading: false,
    },
    submitStudentProfileSettings: {
      loading: false,
    },
    studentsActivity: {
      loading: false,
    },
    studentInteraction: {
      loading: false,
    },
    submitStudentInteraction: {
      loading: false,
    },
    createEventInteractionsForChabadHouse: {
      loading: false,
    },
    deleteStudentInteraction: {
      loading: false,
    },
    studentInteractions: {
      loading: false,
    },
    studentObservance: {
      loading: false,
    },
  },
  handlers: {
    [StudentsActions.SET_STUDENT_LOADING]: (state, action) => {
      return {
        ...state,
        student: {
          loading: !!action.payload,
        },
      };
    },
    [StudentsActions.SET_STUDENT]: (state, action) => {
      return {
        ...state,
        student: action.payload,
      };
    },

    [StudentsActions.SET_SUBMIT_STUDENT_LOADING]: (state, action) => {
      return {
        ...state,
        submitStudent: {
          loading: !!action.payload,
        },
      };
    },
    [StudentsActions.SET_SUBMIT_STUDENT]: (state, action) => {
      return {
        ...state,
        submitStudent: action.payload,
      };
    },

    [StudentsActions.SET_STUDENTS_LOADING]: (state, action) => {
      return {
        ...state,
        students: {
          loading: !!action.payload,
        },
      };
    },
    [StudentsActions.SET_STUDENTS]: (state, action) => {
      return {
        ...state,
        students: action.payload,
      };
    },

    [StudentsActions.SET_STUDENT_CAMPUSES_LOADING]: (state, action) => {
      return {
        ...state,
        studentCampuses: {
          loading: !!action.payload,
        },
      };
    },
    [StudentsActions.SET_STUDENT_CAMPUSES]: (state, action) => {
      return {
        ...state,
        studentCampuses: action.payload,
      };
    },

    [StudentsActions.SET_STUDENT_PROFILE_SETTINGS_LOADING]: (state, action) => {
      return {
        ...state,
        studentProfileSettings: {
          loading: !!action.payload,
        },
      };
    },
    [StudentsActions.SET_STUDENT_PROFILE_SETTINGS]: (state, action) => {
      return {
        ...state,
        studentProfileSettings: action.payload,
      };
    },

    [StudentsActions.SET_SUBMIT_STUDENT_PROFILE_SETTINGS_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        submitStudentProfileSettings: {
          loading: !!action.payload,
        },
      };
    },
    [StudentsActions.SET_SUBMIT_STUDENT_PROFILE_SETTINGS]: (state, action) => {
      return {
        ...state,
        submitStudentProfileSettings: action.payload,
      };
    },

    [StudentsActions.SET_STUDENTS_ACTIVITY_LOADING]: (state, action) => {
      return {
        ...state,
        studentsActivity: {
          loading: !!action.payload,
        },
      };
    },
    [StudentsActions.SET_STUDENTS_ACTIVITY]: (state, action) => {
      return {
        ...state,
        studentsActivity: action.payload,
      };
    },

    [StudentsActions.SET_STUDENT_INTERACTION_LOADING]: (state, action) => {
      return {
        ...state,
        studentInteraction: {
          loading: !!action.payload,
        },
      };
    },
    [StudentsActions.SET_STUDENT_INTERACTION]: (state, action) => {
      return {
        ...state,
        studentInteraction: action.payload,
      };
    },

    [StudentsActions.SET_SUBMIT_STUDENT_INTERACTION_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        submitStudentInteraction: {
          loading: !!action.payload,
        },
      };
    },
    [StudentsActions.SET_SUBMIT_STUDENT_INTERACTION]: (state, action) => {
      return {
        ...state,
        submitStudentInteraction: action.payload,
      };
    },

    [StudentsActions.SET_CREATE_EVENT_INTERACTIONS_FOR_CHABAD_HOUSE_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        createEventInteractionsForChabadHouse: {
          loading: !!action.payload,
        },
      };
    },
    [StudentsActions.SET_CREATE_EVENT_INTERACTIONS_FOR_CHABAD_HOUSE]: (
      state,
      action,
    ) => {
      return {
        ...state,
        createEventInteractionsForChabadHouse: action.payload,
      };
    },

    [StudentsActions.SET_DELETE_STUDENT_INTERACTION_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        deleteStudentInteraction: {
          loading: !!action.payload,
        },
      };
    },
    [StudentsActions.SET_DELETE_STUDENT_INTERACTION]: (state, action) => {
      return {
        ...state,
        deleteStudentInteraction: action.payload,
      };
    },

    [StudentsActions.SET_STUDENT_INTERACTIONS_LOADING]: (state, action) => {
      return {
        ...state,
        studentInteractions: {
          loading: !!action.payload,
        },
      };
    },
    [StudentsActions.SET_STUDENT_INTERACTIONS]: (state, action) => {
      return {
        ...state,
        studentInteractions: action.payload,
      };
    },

    [StudentsActions.SET_STUDENT_OBSERVANCE_LOADING]: (state, action) => {
      return {
        ...state,
        studentObservance: {
          loading: !!action.payload,
        },
      };
    },
    [StudentsActions.SET_STUDENT_OBSERVANCE]: (state, action) => {
      return {
        ...state,
        studentObservance: action.payload,
      };
    },

    [AuthActions.DO_SIGNOUT]: (state, action) => {
      return StudentsState.defaults;
    },
    [StudentsActions.SET_CHABAD_HOUSE_TAGS]: (state, action) => {
      return {
        ...state,
        student: {
          ...state.student,
          chabadHouseTags: { ...action.payload },
        },
      };
    },
    [StudentsActions.SET_ALL_CHABAD_HOUSE_TAGS]: (state, action) => {
      return {
        ...state,
        chabadHouseTags: { ...action.payload },
      };
    },
  },
};
