import React from "react";
import Modal from "react-modal";

export default class PendingRenewalModal extends React.Component {
  render() {
    const { close, show } = this.props;

    return (
      <Modal isOpen={show} className="modal-container">
        <div className="modal-card card small-modal">
          <p className="medium-text fw-700 flex flex-align-center">
            <i className="material-icons mr-8" style={{ fontSize: "26px" }}>
              check_circle_outline
            </i>
            Your payment method has been updated
          </p>
          <div className="accent-text mt-16 line-height-double">
            Note that though your card has been successfully updated, your
            enrollment status will remain ‘Failed Renewal’ until your renewal
            has been processed. This may take up to several minutes; please
            refresh your page to check for a status update.
          </div>
          <div className="modal-btns relative">
            <p className="link-text uppercase-text" onClick={close}>
              OK
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}
