import React from "react";
import StudentsActivity from "../../students/activity/StudentsActivity.vm";
import moment from "moment";

export default (passProps) => {
  const scheduleStart = passProps.schedule?.data.programStartDate;
  const isImportedSchedule =
    scheduleStart && moment(scheduleStart).year() < 2021;
  return isImportedSchedule ? (
    <div className="flex flex-column flex-align-center mt-40 mb-40">
      <img
        src="/images/lamplighters-logo.jpg"
        alt=""
        style={{ height: "96px" }}
      />
      <p className="text-center mt-24">
        Activity is not available for imported Lamplighters schedules
      </p>
    </div>
  ) : (
    <StudentsActivity isLamplightersActivity={true} {...passProps} />
  );
};
