import React, { useState, useCallback } from "react";
import Loader from "../../../components/Loader";
import PaginatedTable from "../../../components/PaginatedTableDeprecated";

function StudentsTable(props) {
  const {
    adminAcceptancePolicyOption,
    showStudentDetailsModal,
    toggleStudentConfirmationModal,
    toggleStudentDetailsModal,
    toggleStudentRecommendationModal,
    updatedRegistrations = {},
    pageRoute: {
      location: { pathname },
      query: {
        tab,
        q: searchTerm = "",
        p: page = 1,
        r: resultsView = 10,
        f: status,
      },
    },
    getTripRegistrations,
    trip: {
      registrations: { results, numberOfRows, loading, success } = {},
      enrollment: { id: enrollmentID },
      event: { id: eventID, isTravelTrip },
    },
  } = props;

  const [detailsOpened, setDetailsOpened] = useState({});

  const toggleDetails = useCallback(
    (id) => {
      setDetailsOpened({
        ...detailsOpened,
        [id]: !detailsOpened[id],
      });
    },
    [setDetailsOpened, detailsOpened],
  );

  const getHeaderRow = useCallback(() => {
    return (
      <div className="paginated-table-header-row students-table-row">
        <p>Name</p>
        <p>Email</p>
        <p>Status</p>
        <p>Track</p>
        {isTravelTrip ? (
          <p>Shliach recommendation</p>
        ) : (
          adminAcceptancePolicyOption !== "NoAcceptance" && (
            <p className="text-center">Accept/Reject</p>
          )
        )}
      </div>
    );
  }, [adminAcceptancePolicyOption, isTravelTrip]);

  const getTableRow = useCallback(
    (student) => {
      return (
        <div
          className={`paginated-table-row students-table-row ${
            detailsOpened[student.id] === true ? "open" : "closed"
          }`}
          key={student.id}
        >
          <div className="desktop-hidden tablet-hidden collapse-icon">
            <i
              className="material-icons accent-text-secondary medium-text pointer"
              onClick={() => toggleDetails(student.id)}
            >
              {detailsOpened[student.id] ? "remove" : "add"}
            </i>
          </div>
          <div className="desktop-display-contents">
            <div
              className="link-text-secondary"
              onClick={() => toggleStudentDetailsModal(student.id)}
            >
              <p className="fw-700 accent-text-dark">
                {student.studentFirstName} {student.studentLastName}
              </p>
            </div>
            <div className="fw-700 accent-text-dark">
              <p>{student.studentEmail}</p>
            </div>
            <div className="fw-700 accent-text-dark mobile-hidden">
              <p>{student.statusDisplay}</p>
            </div>
            <div className="fw-700 accent-text-dark mobile-hidden">
              <p>{student.trackName}</p>
            </div>
            {isTravelTrip ? (
              student.wasRecommendationSubmitted ? (
                <i className="material-icons medium-text mobile-hidden">
                  check
                </i>
              ) : (
                student.workflowStep === "Applied" && (
                  <p
                    className="link-text underline-text mobile-hidden"
                    onClick={() =>
                      toggleStudentRecommendationModal({
                        id: student.id,
                        studentFirstName: student.studentFirstName,
                        studentLastName: student.studentLastName,
                      })
                    }
                  >
                    Complete recommendation
                  </p>
                )
              )
            ) : (
              adminAcceptancePolicyOption !== "NoAcceptance" && (
                <div className="student-actions">
                  {!showStudentDetailsModal &&
                  updatedRegistrations[student.id] &&
                  updatedRegistrations[student.id].loading ? (
                    <div className="xs-loader">
                      <Loader />
                    </div>
                  ) : !showStudentDetailsModal &&
                    updatedRegistrations[student.id] &&
                    updatedRegistrations[student.id].errorMessages &&
                    updatedRegistrations[student.id].errorMessages.length >
                      0 ? (
                    <p
                      className="error-message xs-text"
                      style={{ position: "unset" }}
                    >
                      {updatedRegistrations[student.id].errorMessages[0]}
                    </p>
                  ) : (
                    student.status === "Pending" && (
                      <React.Fragment>
                        <p className="text-center">
                          <i
                            className="material-icons link-text-secondary"
                            style={{ color: "#76E269" }}
                            onClick={() =>
                              toggleStudentConfirmationModal(
                                student,
                                "Accepted",
                              )
                            }
                          >
                            thumb_up
                          </i>
                          <i
                            className="material-icons ml-8 link-text-secondary"
                            style={{ color: "#DC313F" }}
                            onClick={() =>
                              toggleStudentConfirmationModal(
                                student,
                                "Rejected",
                              )
                            }
                          >
                            thumb_down
                          </i>
                        </p>
                      </React.Fragment>
                    )
                  )}
                </div>
              )
            )}
          </div>
          <div className="student-details desktop-hidden tablet-hidden mb-8">
            <div className="flex">
              <p className="fw-700 student-mobile-label">Status</p>
              <p>
                {(updatedRegistrations[student.id] &&
                  updatedRegistrations[student.id].status) ||
                  student.statusDisplay}
              </p>
            </div>
            <div className="flex">
              <p className="fw-700 student-mobile-label">Track</p>
              <p>{student.trackName}</p>
            </div>
            <p
              className="link-text"
              onClick={() => toggleStudentDetailsModal(student.id)}
            >
              View Details
            </p>
            {isTravelTrip &&
              !student.wasRecommendationSubmitted &&
              student.workflowStep === "Applied" && (
                <p
                  className="link-text"
                  onClick={() =>
                    toggleStudentRecommendationModal({
                      id: student.id,
                      studentFirstName: student.studentFirstName,
                      studentLastName: student.studentLastName,
                    })
                  }
                >
                  Complete recommendation
                </p>
              )}
          </div>
        </div>
      );
    },
    [
      detailsOpened,
      isTravelTrip,
      adminAcceptancePolicyOption,
      showStudentDetailsModal,
      updatedRegistrations,
      toggleDetails,
      toggleStudentDetailsModal,
      toggleStudentRecommendationModal,
      toggleStudentConfirmationModal,
    ],
  );

  const getNavigationURL = (page) => {
    return `${pathname}?tab=${tab}&p=${page}&q=${searchTerm}&r=${resultsView}${
      status !== undefined ? `&f=${status}` : ""
    }`;
  };

  return (
    <PaginatedTable
      className="students-table"
      enrollmentID={enrollmentID}
      eventID={eventID}
      getURL={getNavigationURL}
      loadData={getTripRegistrations}
      loading={loading}
      name="Students"
      page={page}
      records={results || []}
      renderHeaderRow={getHeaderRow}
      renderRow={getTableRow}
      resultsView={resultsView}
      success={success}
      totalCount={numberOfRows}
    />
  );
}

export default React.memo(StudentsTable);
