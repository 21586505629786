import React, { forwardRef, useCallback, useMemo } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  InputProps,
  ListItemText,
  MenuItem,
  SelectProps,
  Typography,
} from "@material-ui/core";
import { ArrowDropDown, Close, CancelRounded } from "@material-ui/icons";
import { TextInput, TextInputProps } from "./TextInput";
import { Option } from "../../../lib/types";
import { ChipStyled } from "../../StyledComponents";

interface SelectInputOption extends Option {
  disabled?: boolean;
}

export type SelectInputProps = {
  clearable?: boolean;
  divider?: boolean;
  groupOpts?: boolean;
  header?: JSX.Element;
  ignoreInvalid?: boolean;
  inputProps?: InputProps;
  loading?: boolean;
  menuTag?: JSX.Element;
  multiple?: boolean;
  name: string;
  onChange: (name: string, val: any) => void;
  options: SelectInputOption[];
  selectAll?: boolean;
  selectProps?: SelectProps;
  value: any;
} & Omit<TextInputProps, "onChange">;

export const SelectInput = React.memo(
  forwardRef(
    /**
     *
     */
    function SelectInput(
      {
        clearable = true,
        divider,
        disabled,
        groupOpts,
        header,
        ignoreInvalid,
        inputProps = {},
        loading,
        menuTag,
        multiple,
        name,
        onChange,
        options,
        placeholder,
        selectAll,
        selectProps = {},
        size = "small",
        value,
        // variant,
        ...passProps
      }: SelectInputProps,
      ref: React.Ref<any> | null,
    ) {
      const handleChange = useCallback(
        (e) => {
          if (selectAll && e.target.value.indexOf("all") >= 0) {
            onChange(
              name,
              value?.length === options.length
                ? []
                : options?.filter((o) => !o.disabled).map((o) => o.id),
            );
          } else {
            onChange(name, e.target.value);
          }
        },
        [name, onChange, options, selectAll, value],
      );

      const handleClear = useCallback(
        () => onChange(name, multiple ? [] : ""),
        [onChange, multiple, name],
      );

      const groupedOptions = useMemo(() => {
        if (!groupOpts) return;

        const groupedOptions: SelectInputOption[] = [];

        options
          .sort(
            (o1, o2) => o1.groupName?.localeCompare(o2.groupName || "") ?? 0,
          )
          .forEach((option) => {
            const optionGroupName = option.groupName || "";
            // add optGroup item if there is none matching the option's group name
            if (
              !groupedOptions.find(
                (o) => o.isOptGroup && o.name === optionGroupName,
              )
            ) {
              groupedOptions.push({
                id: optionGroupName,
                name: optionGroupName,
                isOptGroup: true,
              });
            }
            groupedOptions.push(option);
          });

        return groupedOptions;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [groupOpts, options.length]);

      const getDisplay = (value: any) => {
        const option = options?.find((o) => o.id === value);
        return option?.name || "Other";
      };

      const selectValue =
        value == null || (ignoreInvalid && !options.find((o) => o.id === value))
          ? ""
          : value;

      return (
        <TextInput
          select
          disabled={disabled}
          InputProps={inputProps}
          label={
            placeholder && (value === null || value === "") ? placeholder : ""
          }
          onChange={handleChange}
          ref={ref}
          SelectProps={{
            endAdornment: loading ? (
              <CircularProgress size={14} style={{ marginRight: "12px" }} />
            ) : clearable && !disabled && selectValue?.toString() ? (
              <Close
                onClick={handleClear}
                style={{
                  fontSize: "16px",
                  position: "absolute",
                  right: "26px",
                  cursor: "pointer",
                }}
              />
            ) : null,
            IconComponent: ({ ...props }) => <ArrowDropDown {...props} />,
            multiple,
            renderValue: (selection: any) =>
              multiple ? (
                selection.map((v: any, i: number) => (
                  <ChipStyled
                    key={i}
                    label={getDisplay(v)}
                    clickable
                    deleteIcon={
                      <CancelRounded
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                    onDelete={() => {
                      const filteredValues = selection.filter(
                        (sv: any) => sv !== v,
                      );
                      onChange(name, filteredValues);
                    }}
                    size="small"
                  />
                ))
              ) : (
                <Typography component="span">
                  {getDisplay(selection)}
                </Typography>
              ),
            ...selectProps,
          }}
          size={size}
          value={selectValue}
          {...passProps}
        >
          {selectAll && (
            <MenuItem value="all">
              {multiple && (
                <Checkbox
                  checked={
                    value?.length === options?.filter((o) => !o.disabled).length
                  }
                />
              )}
              <ListItemText primary="Select all" />
            </MenuItem>
          )}
          {header}
          {(groupedOptions || options)?.map((o) =>
            o.isOptGroup ? (
              <MenuItem
                disabled={true}
                divider={divider}
                key={o.id}
                style={{
                  fontSize: "12px",
                  color: "text.secondary", //TODO SR: color
                  opacity: "1 !important",
                }}
                value={o.id}
              >
                {o.display || o.name}
              </MenuItem>
            ) : (
              <MenuItem
                disabled={o.disabled}
                divider={divider}
                key={o.id}
                style={{
                  padding: divider ? "14px 12px 15px 12px" : "",
                }}
                value={o.id}
              >
                {multiple && <Checkbox checked={value?.indexOf(o.id) >= 0} />}
                <ListItemText primary={o.display || o.name} />
              </MenuItem>
            ),
          )}
          {menuTag && <Box padding="8px 16px">{menuTag}</Box>}
        </TextInput>
      );
    },
  ),
);
