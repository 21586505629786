import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      maxWidth: "100%",
    },
  },
  contentContainer: {
    width: "100%",
    padding: 32,
    borderLeft: "1px solid #d8d8dd",
    minHeight: "90vh",
    maxWidth: "100%",
    "& .raffle-settings-container": {
      maxWidth: "100%",
    },
    "& .not-active": { cursor: "not-allowed" },
    "& .not-active > *:not(.pointer-form-section)": {
      pointerEvents: "none",
    },
    [theme.breakpoints.down("md")]: {
      padding: "24px 16px 0",
      borderLeft: "none",
    },
  },
  breadcrumbsDesktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  breadcrumbsTablet: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
}));
