import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import PaginatedTable from "../../../components/PaginatedTableDeprecated";
import { formatCurrency, PageURL, pluralizeText } from "../../../lib";
import DonorsTableFilters from "./DonorsTableFilters";
import { RafflePaymentTypes, RafflePurchaseSources } from "./../constants";
const currentYear = new Date().getFullYear();
const yearsAgoDefault = 3;
const DonorsTable = (props) => {
  const {
    getDonors,
    getDonorDetails,
    raffle: { donors: { results, numberOfRows, loading } = {}, success },
    pageRoute,
    pageRoute: {
      query: { p: page = 1, r: resultsView = 10, all: showAllYears },
    },
    isMobileView,
  } = props;
  const [donationsOpened, setDonationsOpened] = useState({});
  const [donationDetails, setDonationDetails] = useState([]);
  const [yearsAgo, setYearsAgo] = useState(yearsAgoDefault);
  const pastYears = useMemo(
    () => [...Array(yearsAgo).keys()].map((i) => currentYear - i),
    [yearsAgo],
  );

  const toggleDonations = useCallback(
    async (email) => {
      const donationDetails = await getDonorDetails(email, !!showAllYears);
      setDonationDetails(donationDetails);
      setDonationsOpened({
        [email]: !donationsOpened[email],
      });
    },
    [donationsOpened, getDonorDetails, showAllYears],
  );

  const getHeaderRow = useCallback(() => {
    return (
      <div className="raffle-details-table-header raffle-details-donors-table-row fw-700">
        <p />
        <p>Donor</p>
        {pastYears.map((year) => (
          <p key={year}> {year}</p>
        ))}
      </div>
    );
  }, [pastYears]);

  const getMobileHeaderRow = useCallback(() => {
    return (
      <div className="raffle-details-mobile-table-header raffle-details-donors-table-row fw-700">
        <p></p>
        <p>Donor info</p>
        <p>Amount</p>
      </div>
    );
  }, []);

  const getTableRow = useCallback(
    (donor, i) => {
      const { donorEmail, donorName, donationsByYear } = donor;
      const sortedDonations = donationDetails.sort(
        (a, b) => a.gregorianYear - b.gregorianYear,
      );
      const latestDonation = [...sortedDonations].pop(); //destructuring so the original list isn't mutated

      return (
        <div
          onClick={() => toggleDonations(donorEmail)}
          className={`raffle-details-table-row raffle-details-donors-table-row pointer`}
          key={donorEmail + i}
        >
          <p>
            {donationsOpened[donorEmail] === true ? (
              <ExpandLess fontSize="small" />
            ) : (
              <ExpandMore fontSize="small" />
            )}
          </p>
          <div className="donors-table-summary-row">
            <div className="fw-700">{donorName}</div>
            <div
              className={`donors-table-section ${
                donationsOpened[donorEmail] ? "open" : "closed"
              }`}
              key={"details" + donorEmail + i}
            >
              <div className="contents-mobile-grid">
                <p></p>
                <div>
                  <p>
                    {latestDonation?.donorFirstName}{" "}
                    {latestDonation?.donorLastName}
                  </p>
                  <p
                    className="link-text small-text mt-8"
                    style={{ wordBreak: "break-word" }}
                  >
                    <a href={`mailto:${latestDonation?.donorEmail}`}>
                      {latestDonation?.donorEmail}
                    </a>
                  </p>
                  <p className="link-text small-text">
                    <a href={`tel:${latestDonation?.donorPhone}`}>
                      {latestDonation?.donorPhone}
                    </a>
                  </p>
                </div>
                <div>
                  <p className="mt-8" style={{ wordBreak: "break-word" }}>
                    {latestDonation?.billingAddress1}
                    {latestDonation?.billingAddress2}
                  </p>
                  <p>
                    {latestDonation?.billingCity}
                    {latestDonation?.billingState && ", "}
                    {latestDonation?.billingState} {latestDonation?.billingZip}{" "}
                    {latestDonation?.billingCountry}
                  </p>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          {pastYears.map((year) => {
            const yearTotals = donationsByYear?.find(
              (d) => d.gregorianYear === year,
            );
            const yearDonationDetails = donationDetails?.find(
              (d) => d.gregorianYear === year,
            );
            return (
              <div key={year}>
                {yearTotals?.total ? (
                  <>
                    <div key={"total" + donorEmail + year}>
                      ${formatCurrency(yearTotals.total)}
                    </div>
                    <div
                      key={"qty" + donorEmail + year}
                      className="accent-text"
                    >
                      {yearTotals.quantity}{" "}
                      {yearTotals.quantity &&
                        pluralizeText("ticket", yearTotals.quantity)}
                    </div>
                    <div
                      className={`donors-table-section ${
                        donationsOpened[donorEmail] ? "open" : "closed"
                      }`}
                      key={"details" + donorEmail + i}
                    >
                      <div>
                        <div className="contents-mobile-grid">
                          <div>
                            <p>
                              {yearDonationDetails?.donorFirstName}{" "}
                              {yearDonationDetails?.donorLastName}
                            </p>
                            <p
                              className="link-text small-text mt-8"
                              style={{ wordBreak: "break-word" }}
                            >
                              <a
                                href={`mailto:${yearDonationDetails?.donorEmail}`}
                              >
                                {yearDonationDetails?.donorEmail}
                              </a>
                            </p>
                            <p className="link-text small-text">
                              <a
                                href={`tel:${yearDonationDetails?.donorPhone}`}
                              >
                                {yearDonationDetails?.donorPhone}
                              </a>
                            </p>
                          </div>
                          <div>
                            <p
                              className="mt-8"
                              style={{ wordBreak: "break-word" }}
                            >
                              {yearDonationDetails?.billingAddress1}
                              {yearDonationDetails?.billingAddress2}
                            </p>
                            <p>
                              {yearDonationDetails?.billingCity}
                              {yearDonationDetails?.billingState && ", "}
                              {yearDonationDetails?.billingState}{" "}
                              {yearDonationDetails?.billingZip}{" "}
                              {yearDonationDetails?.billingCountry}
                            </p>
                          </div>
                          <div className="mt-8">
                            {yearDonationDetails?.refundAmount * -1 > 0 && (
                              <p
                                style={{
                                  color: "#e94746",
                                  textDecoration: "line-through",
                                }}
                              >
                                {formatCurrency(
                                  yearDonationDetails?.refundAmount * -1,
                                  yearDonationDetails?.currencyCode,
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                        <div>
                          <p className="mt-8">
                            {yearDonationDetails?.paymentType ===
                            RafflePaymentTypes.Cash
                              ? yearDonationDetails?.cashType
                              : yearDonationDetails?.paymentType ===
                                RafflePaymentTypes.CreditCard
                              ? "CC"
                              : yearDonationDetails?.paymentType}
                          </p>
                          <p className="accent-text mt-8">
                            {yearDonationDetails?.source !==
                            RafflePurchaseSources.Unknown
                              ? yearDonationDetails?.source
                              : ""}
                          </p>
                        </div>
                        <div className="order-details">
                          {yearDonationDetails?.association !== "None" && (
                            <p className="accent-text mt-8">
                              {yearDonationDetails?.associationDisplay}
                            </p>
                          )}
                          {yearDonationDetails?.referrerName && (
                            <p className="mt-8">
                              {yearDonationDetails?.referrerName}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    className={`donors-table-section ${
                      donationsOpened[donorEmail] ? "open" : "closed"
                    }`}
                    key={"details" + donorEmail + i}
                  ></div>
                )}
              </div>
            );
          })}
        </div>
      );
    },
    [donationDetails, donationsOpened, pastYears, toggleDonations],
  );

  const getMobileTableRow = useCallback(
    (donor, i) => {
      const { donorEmail, donorName, donationsByYear } = donor;
      const sortedDonations = donationDetails.sort(
        (a, b) => a.gregorianYear - b.gregorianYear,
      );
      const latestDonation = [...sortedDonations].pop(); //destructuring so the original list isn't mutated
      return (
        <>
          <div
            onClick={() => toggleDonations(donorEmail)}
            className={`raffle-details-table-row raffle-details-donors-table-row pointer`}
            key={donorEmail + i}
          >
            <p>
              {donationsOpened[donorEmail] === true ? (
                <ExpandLess fontSize="small" />
              ) : (
                <ExpandMore fontSize="small" />
              )}
            </p>
            <div>
              <div className="fw-700">{donorName}</div>
              <div>{donorEmail}</div>
              {donationsOpened[donorEmail] && (
                <div className="link-text">
                  <a href={`tel:${latestDonation?.donorPhone}`}>
                    {latestDonation?.donorPhone}
                  </a>
                </div>
              )}
            </div>
          </div>
          <div
            className={`donors-table-section ${
              donationsOpened[donorEmail] ? "open" : "closed"
            }`}
            key={"details" + donorEmail + i}
          >
            {pastYears.map((year) => {
              const yearTotals = donationsByYear?.find(
                (d) => d.gregorianYear === year,
              );
              return (
                <div
                  key={year}
                  className="flex raffle-details-mobile-table-row"
                >
                  <div className="fw-700">{year}</div>
                  <div>
                    {yearTotals?.total ? (
                      <>
                        <span
                          key={"total" + donorEmail + year}
                          className="mr-6"
                        >
                          ${formatCurrency(yearTotals.total)}
                        </span>
                        <span className="xs-text">•</span>
                        <span
                          key={"qty" + donorEmail + year}
                          className="ml-6 accent-text"
                        >
                          {yearTotals.quantity}{" "}
                          {yearTotals.quantity &&
                            pluralizeText("ticket", yearTotals.quantity)}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      );
    },
    [donationDetails, donationsOpened, pastYears, toggleDonations],
  );

  const getNavigationURL = (pageNumber, resultsView) => {
    const {
      pageRoute: { query, page, params },
    } = props;
    const url = PageURL.to(page, params, {
      ...query,
      p: pageNumber,
      r: resultsView,
    });
    return url;
  };

  useEffect(() => {
    setYearsAgo(showAllYears ? currentYear - 2004 : yearsAgoDefault);
  }, [showAllYears]);

  return (
    <PaginatedTable
      className="raffles-details-donors-table raffle-details-table"
      filterComponent={<DonorsTableFilters pageRoute={pageRoute} />}
      getURL={getNavigationURL}
      loadData={getDonors}
      loading={loading}
      name="Donors"
      page={page}
      pageRoute={pageRoute}
      records={results || []}
      renderHeaderRow={isMobileView ? getMobileHeaderRow : getHeaderRow}
      renderRow={isMobileView ? getMobileTableRow : getTableRow}
      resultsIncrement={10}
      resultsView={resultsView}
      showResultsView={true}
      success={success}
      totalCount={numberOfRows}
    />
  );
};

export default React.memo(DonorsTable);
