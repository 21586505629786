import React from "react";
import moment from "moment";
import MergeDuplicateAccountDetails from "./MergeDuplicateAccountDetails";
const selectedInputColor = "#33b7ae";
const MergeDuplicateHeader = (props) => {
  const {
    students,
    removeStudent,
    selectedAllIndex,
    handleSelectAll,
    showAllFields,
    setShowAllFields,
    showAccountDetails,
  } = props;
  return (
    <>
      <div
        className="field-row"
        style={{
          gridTemplateColumns: `180px repeat(${students.length}, 300px)`,
        }}
      >
        <p className="mb-16" />
        {students.map((val, index) => (
          <div key={index}>
            <div className="exclude-student mb-12">
              <div>
                <div className="flex flex-justify-space">
                  <span className="fw-700">
                    {val.firstName} {val.lastName}
                    <a
                      href={`/students/${val.studentID}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="material-icons ml-8 large-text"
                        style={{
                          verticalAlign: "middle",
                          color: "#53B7E8",
                        }}
                      >
                        open_in_new
                      </i>
                    </a>
                  </span>
                  <span className="mr-16">
                    <i
                      className="material-icons"
                      style={{
                        opacity: 0.55,
                        cursor: "pointer",
                        fontSize: 18,
                        lineHeight: "25px",
                        position: "absolute",
                      }}
                      onClick={() => {
                        removeStudent(index);
                      }}
                    >
                      close
                    </i>
                  </span>
                </div>
                <p
                  className={`relative ${val.isActive ? "active" : "inactive"}`}
                  style={{ marginBottom: "0", fontSize: 12 }}
                >
                  <span
                    className="mr-4 xs-text"
                    style={{ color: val.isActive ? selectedInputColor : "red" }}
                  >
                    {"\u2B24"}
                  </span>
                  <span className="mr-16">
                    {val.isActive ? "Active" : "Inactive"}
                  </span>
                  ID: {val.studentID}
                </p>
                <p className="fs-14 accent-text">
                  Created{val.createdBy ? ` by ${val.createdBy}` : ""} on{" "}
                  {moment(val.createdOn).format("M/D/YYYY")}
                </p>
                {showAccountDetails && (
                  <MergeDuplicateAccountDetails
                    studentIndex={index}
                    students={students}
                  />
                )}
              </div>
            </div>
            <div
              className="field-row exclude-student mb-12"
              style={{
                borderColor:
                  selectedAllIndex === index ? selectedInputColor : "#edecec",
              }}
            >
              <div>
                <p className="accent-text medium-text merge-radio-container">
                  <input
                    type="radio"
                    value={index || ""}
                    style={{ marginRight: 8 }}
                    checked={selectedAllIndex === index}
                    onChange={() => {
                      handleSelectAll(index);
                    }}
                  />
                  Select all fields in this profile
                </p>
                <p className="accent-text fs-14 ml-28">
                  You can still override individual fields.
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className="field-row accent-text fs-14 mb-16 mt-8"
        style={{
          gridTemplateColumns: `180px 300px`,
        }}
      >
        <p></p>
        <p>
          Showing all {showAllFields ? "" : "non-matching"} fields.{" "}
          <span
            className="pointer link-text"
            onClick={() => setShowAllFields(!showAllFields)}
          >
            {showAllFields ? "Hide matching" : "Show all"} fields
          </span>
        </p>
      </div>
    </>
  );
};
export default React.memo(MergeDuplicateHeader);
