import React from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements";

export default class CreditCardInfo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      incompleteCardFields: {
        cardNumber: true,
        cardExpiry: true,
        cardCvc: true,
      },
    };
  }

  componentWillUnmount() {
    if (this.props.hasCompleteCardInfo) {
      this.props.onChange("billing.hasCompleteCardInfo", false);
    }
  }

  onChangeCC = ({ elementType, complete }) => {
    this.setState(
      {
        incompleteCardFields: {
          ...this.state.incompleteCardFields,
          [elementType]: !complete,
        },
      },
      () => {
        this.props.onChange(
          "billing.hasCompleteCardInfo",
          !Object.keys(this.state.incompleteCardFields).filter(
            (cardField) => this.state.incompleteCardFields[cardField],
          ).length,
        );
      },
    );
  };

  toggleUseCConFile = async (event) => {
    const checked = event.target.checked;
    await this.props.onChange(event.target.name, checked);

    if (checked) {
      this.setState(
        {
          incompleteCardFields: {
            cardNumber: true,
            cardExpiry: true,
            cardCvc: true,
          },
        },
        () => this.props.onChange("billing.hasCompleteCardInfo", false),
      );
    }
  };

  render() {
    const {
      cardHolderFullName,
      cardOnFile,
      onChangeEvent,
      useCardOnFile,
      validation,
    } = this.props;
    const { incompleteCardFields } = this.state;

    return (
      <div className="kinus-form-section">
        <p className="medium-text fw-700 mb-16">Credit Card Info</p>
        <form className="cc-info-form">
          <div className="flex flex-align-center mb-12">
            <div
              className={`flex custom-checkbox-container mb-24 ${
                !cardOnFile ? "disabled" : ""
              }`}
            >
              <input
                type="checkbox"
                className="custom-checkbox"
                id="use-cc"
                disabled={!cardOnFile}
                checked={useCardOnFile}
                name="billing.useCardOnFile"
                onChange={this.toggleUseCConFile}
              />
              <label htmlFor="use-cc">
                Use card on file{" "}
                {cardOnFile &&
                  cardOnFile.lastFour &&
                  ` - ending in ${cardOnFile.lastFour}`}
              </label>
            </div>
          </div>
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="kinus-form-label">Card Number</label>
            <CardNumberElement
              className={`custom-input ${
                validation.completeCardInfo && incompleteCardFields.cardNumber
                  ? "error"
                  : ""
              }`}
              disabled={useCardOnFile}
              onChange={this.onChangeCC}
            />
          </div>
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="kinus-form-label">Cardholder Full Name</label>
            <input
              type="text"
              className={`custom-input ${
                validation.completeCardInfo && !cardHolderFullName
                  ? "error"
                  : ""
              }`}
              placeholder="Full Name"
              disabled={useCardOnFile}
              name="billing.cardHolderFullName"
              value={cardHolderFullName}
              onChange={onChangeEvent}
            />
          </div>
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="kinus-form-label">Exp Date</label>
            <CardExpiryElement
              className={`custom-input ${
                validation.completeCardInfo && incompleteCardFields.cardExpiry
                  ? "error"
                  : ""
              }`}
              disabled={useCardOnFile}
              onChange={this.onChangeCC}
            />
          </div>
          <div className="flex flex-align-center mobile-block">
            <label className="kinus-form-label">CVC Code</label>
            <CardCVCElement
              className={`custom-input ${
                validation.completeCardInfo && incompleteCardFields.cardCvc
                  ? "error"
                  : ""
              }`}
              disabled={useCardOnFile}
              onChange={this.onChangeCC}
            />
          </div>
        </form>
      </div>
    );
  }
}
