import React from "react";
import { useSelector } from "react-redux";
import { InputLabel, Typography, Tooltip } from "@material-ui/core";
import { FormSelectField } from "../../../../../components/react-hook-form";
import { ShliachInfoProps } from "./ShliachInfo";
import {
  ProfileFormGridItemStyled,
  ProfileFormGridStyled,
  ProfileFormInfoIcon,
} from "../../ProfileForm.styles";
import { SystemSelectors } from "../../../../../state";

const getRoleTooltip = (roleName: string) => {
  switch (roleName) {
    case "FullTime":
      return "Shluchim & Shluchos for whom the vast majority of their Shlichus is a university or college campus. Shluchim/os who fit this category live close to campus, offer weekly Friday night meals during the semester for students, teach classes regularly to students, etc.";
    case "PartTime":
      return "Shluchim & Shluchos who have a campus that they serve as a large component of their Shlichus, but still have other primary Shlichus responsibilities to a community. Shluchim/os who fit this category may have students on Friday night together with community members or have student-only Friday night meals monthly, etc.";
    case "Community":
      return "Shluchim & Shluchos who have a campus in their Shlichus territory and serve it infrequently. Shluchim/os who fit this category may visit the campus a few times a year, have infrequent events for students, etc.";
    default:
      return "";
  }
};
export const ShliachShlichusInfo = React.memo(
  /**
   *
   */

  function ShliachShlichusInfo(props: ShliachInfoProps) {
    const { personType, initialFormValues } = props;

    const demographics = useSelector(SystemSelectors.demographics);

    const roleName = initialFormValues[personType]?.outreach?.category || "";
    const tooltipText = getRoleTooltip(roleName);

    return (
      <>
        <Typography variant="subtitle1">Campus Shlichus</Typography>
        {roleName && (
          <InputLabel>
            {/* using regex to add spaces to the role which is in camel case  */}
            {roleName.replace(/([A-Z])/g, " $1")} campus shlichus
            <Tooltip title={tooltipText}>
              <ProfileFormInfoIcon />
            </Tooltip>
          </InputLabel>
        )}
        <ProfileFormGridStyled
          style={{ marginTop: "16px", marginBottom: "32px" }}
        >
          <ProfileFormGridItemStyled>
            <FormSelectField
              label="Demographics served"
              multiple={true}
              name={`${personType}.outreach.demographics`}
              options={demographics.map((d: any) => ({
                id: d.enumValue,
                name: d.displayValue,
              }))}
            />
          </ProfileFormGridItemStyled>
        </ProfileFormGridStyled>
      </>
    );
  },
);
