import React, { useEffect, useState } from "react";
import { Alert } from "@material-ui/lab";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Typography,
  Collapse,
  Box,
} from "@material-ui/core";
import { useStyles } from "./StudentStatusUpdateModal.styles";
import { ExpandLess, ExpandMore, Visibility } from "@material-ui/icons";
import { getEduEmailPreviewText } from "../../../../../lib";
import { useSelector } from "react-redux";
import { ProgramsSelectors } from "../../../../../state/programs/selector";

interface StatusUpdateModalProps {
  show: boolean;
  handleClose: () => void;
  data: any;
  handleSubmit: (values: any) => Promise<void>;
  statusType: string;
  loading: boolean;
  errorMessage: string;
  programScheduleId: string;
}

interface ProgramObject {
  programName: string;
  programScheduleName: string;
  programID: number;
  scheduleID: number;
  baseUrl: string;
  isRegistrationProgram: boolean;
  programType: string | null;
  types: string[];
}

export default function StudentStatusUpdateModal(
  props: StatusUpdateModalProps,
) {
  const {
    show,
    handleClose,
    data,
    handleSubmit,
    statusType = "",
    loading,
    errorMessage,
    programScheduleId,
  } = props;
  const classes = useStyles({ statusType });
  const eduPrograms = useSelector(ProgramsSelectors.eduPrograms);
  const [sendEmail, setSendEmail] = useState(true);
  const [showPreview, setShowPreview] = useState(false);
  const [program, setProgram] = useState<ProgramObject>();
  const [isYourIsrael, setIsYourIsrael] = useState<Boolean>(false);
  const [rejectionType, setRejectionType] = useState<
    "course_reject" | "global_reject"
  >("course_reject");

  useEffect(() => {
    const program = eduPrograms.find(
      (program: any) => program.scheduleID.toString() === programScheduleId,
    );
    if (program) {
      setProgram(program);
      setIsYourIsrael(program.programType === "YourIsrael");
    }
  }, [eduPrograms, programScheduleId]);

  useEffect(() => {
    if (isYourIsrael) setRejectionType("global_reject");
  }, [isYourIsrael]);

  const onConfirm = () => {
    const values: any = {
      id: data.eduStudentCourseScheduleEnrollmentID,
      eduStudentEnrollmentID: data.eduStudentEnrollmentID,
      sendStudentEmail: sendEmail,
      eduCourseScheduleEnrollmentStatus:
        statusType === "approve" ? "Approved" : "Rejected",
    };
    //only send back global status if new student
    if (data.studentEnrollmentStatus === "Pending") {
      values.eduStudentEnrollmentStatus =
        statusType === "approve" || rejectionType === "course_reject"
          ? "Approved"
          : "Rejected";
    }
    handleSubmit(values);
  };

  const handleEmailChange = (event: any) => {
    setSendEmail(event.target.checked);
  };

  const handleTogglePreview = () => {
    setShowPreview(!showPreview);
  };

  const handleChange = (event: any) => {
    setRejectionType(event.target.value);
  };

  const isGlobalRejectionForm =
    data.studentEnrollmentStatus === "Pending" &&
    statusType === "reject" &&
    !isYourIsrael;

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle id="student-reject" disableTypography>
        <Typography variant="h4">
          {statusType === "reject" ? "Reject" : "Approve"} Student
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isGlobalRejectionForm
            ? `This student is new to ${program?.programName}. Choose the rejection status for this student.`
            : `Are you sure you want to ${statusType} this student? This cannot be undone`}
        </DialogContentText>
        {isGlobalRejectionForm && (
          <RadioGroup
            aria-label="reject"
            name="reject"
            value={rejectionType}
            onChange={handleChange}
            className={classes.radioContainer}
          >
            <FormControlLabel
              value="course_reject"
              control={<Radio color="primary" />}
              label={
                <Typography>
                  Reject from this course{" "}
                  <span className={classes.boldLabel}>only</span> (and accept to{" "}
                  {program?.programName}).
                </Typography>
              }
            />
            <FormControlLabel
              value="global_reject"
              control={<Radio color="primary" />}
              label={
                <Typography>
                  Reject from <span className={classes.boldLabel}>all</span>{" "}
                  {program?.programName} courses
                </Typography>
              }
            />
          </RadioGroup>
        )}
        <Collapse in={rejectionType === "global_reject" && !isYourIsrael}>
          <Alert severity="error" className={classes.alert}>
            The student will not be allowed to register for any{" "}
            {program?.programName} courses.
          </Alert>
        </Collapse>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={sendEmail}
              onChange={handleEmailChange}
              name="sendEmail"
              color="secondary"
            />
          }
          label={`Send ${
            statusType === "approve" ? "approval" : "rejection"
          } email to student`}
        />
        {rejectionType === "course_reject" && isGlobalRejectionForm && (
          <Typography variant="body2" className={classes.sendEmailText}>
            Email notifies of acceptance to {program?.programName} but not this
            course
          </Typography>
        )}

        {statusType !== "approve" && (
          <Button
            onClick={handleTogglePreview}
            color="primary"
            disableRipple
            className={classes.previewBtn}
          >
            <Visibility fontSize="small" /> preview Email
            {showPreview ? (
              <ExpandLess fontSize="small" />
            ) : (
              <ExpandMore fontSize="small" />
            )}
          </Button>
        )}

        <Collapse in={showPreview}>
          {/* @ts-ignore */}
          <Box className={classes.box}>
            <Typography variant="body2">
              {getEduEmailPreviewText(rejectionType, program?.programName)}
            </Typography>
          </Box>
        </Collapse>

        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </DialogContent>
      <DialogActions disableSpacing>
        <Button onClick={handleClose} color="default" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          className={classes.submitBtn}
          variant="contained"
          disabled={loading}
        >
          {statusType.toUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
