import React from "react";
import Toggle from "../../../../components/form/Toggle";
import { getNameDisplay } from "../../../../lib/utils";

export default ({
  child,
  formProps: { onChange, onChangeEvent, validation },
  settings,
}) => (
  <div className="kinus-form-section child">
    <p className="medium-text fw-700 mb-24">
      {getNameDisplay(child.person.firstName, child.person.lastName)}
    </p>
    <div className="flex flex-align-center mb-16 mobile-block">
      <p className="kinus-form-label">Needs Kinus Childcare</p>
      <Toggle
        error={validation[`child.${child.id}.needsKinusChildcare`]}
        name={`childCare.children[${child.childCareIndex}].needsKinusChildcare`}
        onChange={(name, value) => {
          if (!value) {
            onChange(`childCare.children[${child.childCareIndex}]`, {
              ...child.childCare,
              needsKinusChildcare: value,
              childMovesBy: null,
              generalComments: "",
              medicalNotes: "",
            });
          } else {
            onChange(name, value);
          }
        }}
        options={[
          { value: true, display: "Yes" },
          { value: false, display: "No" },
        ]}
        value={child.childCare.needsKinusChildcare}
      />
    </div>

    {!!child.childCare.needsKinusChildcare && (
      <React.Fragment>
        <div className="flex flex-align-center mb-16 mobile-block">
          <p className="kinus-form-label">Child moves around by</p>
          <Toggle
            error={validation[`child.${child.id}.childMovesBy`]}
            disabled={!child.childCare.needsKinusChildcare}
            name={`childCare.children[${child.childCareIndex}].childMovesBy`}
            onChange={onChange}
            options={settings.childMovesBy.map((method) => ({
              value: method.enumValue,
              display: method.displayValue,
            }))}
            value={child.childCare.childMovesBy}
          />
        </div>
        <div className="mb-16">
          <p className="fw-700 mb-8">
            Please detail any medical conditions, medications and/or allergies
          </p>
          <textarea
            name={`childCare.children[${child.childCareIndex}].medicalNotes`}
            onChange={onChangeEvent}
            placeholder="Type notes here..."
            value={child.childCare.medicalNotes}
          />
        </div>
        <div>
          <p className="fw-700 mb-8">General Comments</p>
          <textarea
            name={`childCare.children[${child.childCareIndex}].generalComments`}
            onChange={onChangeEvent}
            placeholder="Type notes here..."
            value={child.childCare.generalComments}
          />
        </div>
      </React.Fragment>
    )}
  </div>
);
