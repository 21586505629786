import React from "react";
import FadeOutErrorMessage from "./../FadeOutErrorMessage";

export default class ValidatedInput extends React.Component {
  constructor(props) {
    super(props);

    const { required, validate, validationValue, value } = props;
    //note - validationValue is the value that we are validating our input's value against
    this.state = {
      requiredError: required ? !this.hasValue(value) : false,
      validationError: validate
        ? value && !validate(value, validationValue)
        : false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { required, validate, validationValue, value } = nextProps;
    if (
      this.props.value !== value ||
      this.props.validationValue !== validationValue
    ) {
      if (required) {
        this.require(value);
      }
      if (
        validate &&
        (!value ||
          this.state.validationError ||
          this.props.validationValue !== validationValue)
      ) {
        //validate if clear out value, or if currently has validation error, or if validation dependant value changed
        this.validate(value, validationValue);
      }
    }
  }

  require = (value) => {
    const { required } = this.props;

    if (required) {
      this.setState({ requiredError: !this.hasValue(value) });
    }
  };

  hasValue = (value) => {
    let hasVal = value;
    if (value === "0") {
      hasVal = false;
    } else if (value instanceof Array) {
      hasVal = value.length;
    } else if (value instanceof Object && !(value instanceof Date)) {
      hasVal = Object.keys(value).length;
    }
    return !!hasVal;
  };

  validate = (value, validationValue) => {
    const { showRequiredOnBlur, validate } = this.props;

    if (showRequiredOnBlur && !value) {
      this.setState({ setRequiredError: this.props.showRequiredOnBlur });
    }

    if (validate) {
      this.setState({
        validationError: value && !validate(value, validationValue),
      });
    }
  };

  render() {
    const {
      className,
      input,
      inputClassName,
      label,
      labelClassName,
      name,
      onBlur,
      onChange,
      onKeyDown,
      onSubmit,
      type,
      value,
      required,
      showRequired,
      validate,
      validationMessage,
      validationValue,
      showValidation,
    } = this.props;
    const { requiredError, setRequiredError, validationError } = this.state;

    const requiredErr = !!(
      required &&
      (setRequiredError || showRequired) &&
      requiredError
    );
    const validationErr = !!(validate && showValidation && validationError);

    return (
      <div
        className={`full-width ${className || ""} ${
          validationErr || requiredErr ? "error" : ""
        }`}
      >
        {!!label && <label className={labelClassName}>{label}</label>}
        {React.cloneElement(input || <input />, {
          className: `${inputClassName || ""} ${
            (input && input.props.className) || ""
          }`,
          name,
          type,
          value,
          onChange,
          onBlur: (e) => {
            this.validate(e.target.value, validationValue);
            if (onBlur) onBlur(name, e.target.value);
          },
          onKeyDown: (e) => {
            if (e.key === "Enter") {
              this.validate(e.target.value, validationValue);
              if (onSubmit) onSubmit(e);
            }
            if (onKeyDown) onKeyDown(e);
            if (input && input.props.onKeyDown) input.props.onKeyDown(e);
          },
        })}
        {validationErr && (
          <FadeOutErrorMessage message={validationMessage || "Invalid entry"} />
        )}
      </div>
    );
  }
}
