import React from "react";
import { PageLink } from "../../../lib";
import Pages from "../..";

function EnrollmentCompleted(props) {
  const {
    programScheduleID,
    programScheduleName,
    setEnrollmentSubmitted,
    studentRegistrationPreviewUrl,
  } = props;

  return (
    <div className="flex flex-align-center flex-justify-center full-page-state">
      <img src="/images/enrollmentComplete.svg" alt="" height="240" />
      <p className="fw-700 xxl-text mt-24 mb-40">
        You have successfully enrolled in {programScheduleName}
      </p>
      {!!studentRegistrationPreviewUrl && (
        <div className="flex flex-align-center large-text mb-32">
          <p>
            Please make sure to preview your student form{" "}
            <a
              className="link-text pre"
              href={studentRegistrationPreviewUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>{" "}
            to ensure that all settings are correct.
          </p>
        </div>
      )}
      <PageLink
        to={Pages.trips.trip}
        params={{ programScheduleID: programScheduleID }}
        query={{ tab: "settings" }}
        className="btn btn-accent btn-large"
        style={{ padding: "0 40px", maxWidth: "98%" }}
        onClick={() => setEnrollmentSubmitted(false)}
      >
        My {programScheduleName}
      </PageLink>
    </div>
  );
}
export default React.memo(EnrollmentCompleted);
