import React from "react";
import Modal from "../../../../components/Modal";

export default class DeletePromoModal extends React.PureComponent {
  render() {
    const { deletePromoCode, index, promoCodes, show, toggleDeletePromoModal } =
      this.props;
    return (
      <Modal show={show}>
        <div className="delete-promo-modal-container modal-container">
          <div className="modal-card card delete-promo-modal">
            <p className="large-text fw-500 mb-16">Confirm</p>
            <p className="accent-text-dark">
              Are you sure you want to delete promo code{" "}
              {promoCodes[index] ? promoCodes[index].code : ""}?
            </p>
            <div className="modal-btns flex flex-align-center flex-justify-end mt-48">
              <button
                className="btn link-text uppercase-text fw-500"
                onClick={() => toggleDeletePromoModal()}
              >
                Cancel
              </button>
              <button
                className="btn link-text uppercase-text fw-500 ml-24"
                onClick={() => deletePromoCode(promoCodes[index])}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
