import React from "react";
import Big from "big.js";

import { getCurrencyDisplay, getCcIcon, sum } from "../../../../lib";

import CreditCardInfo from "./CreditCardInfo";
import BillingAddress from "./BillingAddress";

export default class Payment extends React.PureComponent {
  state = {
    errorMessage: "",
    promoCode: "",
    totalCost: this.props.shliachPrice,
  };

  addPromoCode = () => {
    const {
      enrollmentSettings: { billing },
      event: { promoCodes },
    } = this.props;
    let { promoCode, totalCost } = this.state;
    let discount = 0;
    let billingPromoCodes = promoCodes.filter(
      (promoCode) => promoCode.appliesTo === "Shluchim",
    );
    let index = billingPromoCodes.findIndex(
      (i) => i.code.toLowerCase() === promoCode.toLowerCase(),
    );
    if (index >= 0) {
      const validatedPromoCode = this.validatePromoCode(
        billingPromoCodes[index],
      );
      if (validatedPromoCode) {
        this.props.onArrayChange(
          "billingPromoCodes",
          billingPromoCodes[index],
          true,
        );
        discount = billingPromoCodes[index].discountAmount;
        totalCost -= discount;
        this.setState({ totalCost, promoCode: "" }, () => {
          if (!totalCost && !billing.saveNewCardToFile) {
            this.props.onChange("billing.saveNewCardToFile", true);
          }
        });
      } else {
        this.setState({
          errorMessage: "The promo code is no longer available",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "", promoCode: "" });
        }, 2000);
      }
    } else {
      this.setState({ errorMessage: "Invalid Promo Code" });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 2000);
    }
  };

  validatePromoCode = (promoCode) => {
    const available =
      !promoCode.threshold || promoCode.threshold > promoCode.counter;
    const expired = !promoCode.isPromoCodeValid;
    const used =
      this.props.enrollmentSettings.billingPromoCodes.indexOf(promoCode) > -1;
    return available && !expired && !used;
  };

  removePromoCode = (promoCode) => {
    let { totalCost } = this.state;
    this.props.onArrayChange("billingPromoCodes", promoCode, false);
    const discount = promoCode.discountAmount;
    totalCost += discount;
    this.setState({ totalCost });
  };

  toggleNewPayment = () => {
    this.setState({ showNewPayment: !this.state.showNewPayment });
  };

  isCCRequired = () => {
    const { enrollmentSettings } = this.props;
    const { totalCost } = this.state;
    const enrollmentFee = totalCost > 0;
    const promoCodesTotal = sum(
      enrollmentSettings.promoCodes.map((p) => p.discountAmount),
    );
    const mayOweMoneyForTrack = enrollmentSettings.tracks.map((track) => {
      if (
        track.earlyBirdPriceOverride - promoCodesTotal <
          track.earlyBirdPlaceholder ||
        track.regularPriceOverride - promoCodesTotal < track.regularPlaceholder
      ) {
        return true;
      } else {
        return false;
      }
    });
    const ccRequired = enrollmentFee || mayOweMoneyForTrack.indexOf(true) > -1;
    return ccRequired;
  };

  render() {
    const {
      account: { primaryChabadHouse, credCardInfo, familyAddress } = {},
      countries,
      enrollmentSettings,
      onChange,
      validation,
    } = this.props;
    const { errorMessage, promoCode, totalCost } = this.state;
    const enrollmentCost = getCurrencyDisplay(Big(this.props.shliachPrice));
    const ccRequired = this.isCCRequired();
    return (
      <div>
        <div className="trip-page-form-section">
          <p className="xxl-text fw-500">Payment</p>
        </div>
        <div className="trip-page-form-section">
          <p className="mb-16 fw-700 medium-text">Promotions</p>
          {enrollmentSettings.billingPromoCodes.map((promoCode, index) => (
            <div className="flex flex-align-center" key={index}>
              <p className="accent-text-secondary-color fw-300">
                {promoCode.code}
              </p>
              <i
                className="accent-text ml-8 material-icons large-text link-text-secondary pointer"
                onClick={() => this.removePromoCode(promoCode)}
              >
                close
              </i>
            </div>
          ))}
          <div className="relative">
            <div className="flex flex-align-center mt-8">
              <input
                className={`custom-input ${errorMessage ? "error" : ""}`}
                onChange={(e) => this.setState({ promoCode: e.target.value })}
                onKeyDown={(e) => {
                  if (e.key === "Enter") this.addPromoCode();
                }}
                placeholder="Enter Promo Code"
                style={{ minWidth: "240px" }}
                type="text"
                value={promoCode}
              />
              <p
                className="link-text uppercase-text fw-700 ml-16"
                onClick={() => this.addPromoCode()}
              >
                Apply
              </p>
            </div>
            <p className="error-message" style={{ bottom: "-16px" }}>
              {errorMessage}
            </p>
          </div>
        </div>
        <div className="trip-page-form-section">
          <p className="mb-16 fw-700 medium-text">Billing Summary</p>
          <div className="trip-billing-summary">
            <div className="flex flex-justify-space flex-align-center">
              <p>Enrollment Fee</p>
              <p>{enrollmentCost}</p>
            </div>
            {enrollmentSettings.billingPromoCodes.length > 0 && (
              <p className="mt-8 mb-8">Promo Codes</p>
            )}
            {enrollmentSettings.billingPromoCodes.map((promoCode, index) => (
              <div className="billing-summary-promo-code" key={index}>
                <div className="flex flex-justify-space flex-align-center mb-8">
                  <p className="accent-text-dark fw-300">{promoCode.code}</p>
                  <p className="error-text">
                    -{getCurrencyDisplay(promoCode.discountAmount)}
                  </p>
                </div>
              </div>
            ))}
            <div className="flex flex-justify-space flex-align-center mt-16">
              <p className="fw-700 medium-text">Total</p>
              <p className="fw-700 medium-text">
                {getCurrencyDisplay(totalCost)}
              </p>
            </div>
          </div>
        </div>
        {ccRequired && (
          <React.Fragment>
            <div>
              <p className="mb-16 fw-700 medium-text">Payment Method</p>
              <p className="accent-text-dark line-height-double mb-24">
                Your credit card will be{" "}
                {totalCost > 0
                  ? "charged now to cover enrollment fee and / or stored on file"
                  : "stored on file"}{" "}
                to cover any expenses from custom pricing or student promo
                codes.
              </p>
              {credCardInfo && (
                <div className="radio-button-container mb-8">
                  <div className="flex">
                    <input
                      type="checkbox"
                      id="saved-payment-method"
                      name="paymentMethod"
                      value="saved"
                      checked={
                        enrollmentSettings.billing.useCardOnFile === true
                      }
                      onChange={() => onChange("billing.useCardOnFile", true)}
                    />
                    <label
                      htmlFor="saved-payment-method"
                      className="flex-align-center"
                      style={{ display: "flex", paddingLeft: "32px" }}
                    >
                      {getCcIcon(credCardInfo.cardBrand)} Visa ending in{" "}
                      {credCardInfo.lastFour}
                    </label>
                  </div>
                </div>
              )}
            </div>

            {credCardInfo && (
              <div className="radio-button-container mb-8">
                <div className="flex">
                  <input
                    type="checkbox"
                    id="new-payment-method"
                    name="paymentMethod"
                    value="new"
                    checked={enrollmentSettings.billing.useCardOnFile === false}
                    onChange={() => onChange("billing.useCardOnFile", false)}
                  />
                  <label
                    htmlFor="new-payment-method"
                    className="flex-align-center"
                    style={{ display: "flex", paddingLeft: "32px" }}
                  >
                    New Payment Method
                    {credCardInfo && totalCost <= 0 && (
                      <span className="ml-8 accent-text">
                        (Card will be saved and will replace card on file)
                      </span>
                    )}
                  </label>
                </div>
              </div>
            )}

            {(!credCardInfo ||
              enrollmentSettings.billing.useCardOnFile === false) && (
              <React.Fragment>
                <CreditCardInfo
                  enrollmentSettings={enrollmentSettings}
                  onChange={onChange}
                  validation={validation}
                />

                {credCardInfo && totalCost > 0 && (
                  <div className="trip-page-form-section new-payment-options">
                    {[
                      {
                        value: false,
                        label: "Use card just once",
                      },
                      {
                        value: true,
                        label: "Save and replace card on file",
                      },
                    ].map((option, index) => (
                      <div
                        key={index}
                        className={`flex radio-button-container mb-8`}
                      >
                        <input
                          type="radio"
                          className="radio-button-container"
                          id={`save-card=${index}`}
                          name="saveCC"
                          onChange={() =>
                            onChange("billing.saveNewCardToFile", option.value)
                          }
                          checked={
                            enrollmentSettings.billing.saveNewCardToFile ===
                            option.value
                          }
                        />
                        <label htmlFor={`save-card=${index}`}>
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
                <BillingAddress
                  chabadHouseAddress={
                    primaryChabadHouse && primaryChabadHouse.address
                  }
                  countries={countries}
                  enrollmentSettings={enrollmentSettings}
                  familyAddress={familyAddress}
                  onChange={onChange}
                  validation={validation}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
