import React from "react";
import EduEnrollmentCompleted from "./enrollment/EnrollmentCompleted";
import EduEnrollmentForm from "./enrollment/EnrollmentForm";
import EduHeader from "./components/EduHeader";
import ErrorDisplay from "../../components/ErrorDisplay";
import Loader from "../../components/Loader";
import MyEdu from "./myEdu/MyEdu";
import NoEduAccess from "./components/NoEduAccess";
import JewishUMenuDropdown from "../../components/JewishUMenuDropdown";
import ProgramBreadcrumbsHeader from "../../components/ProgramBreadcrumbsHeader";
import { AuthTypes, IsApiErrorStatus, Navigation, PageURL } from "../../lib";
import { withAppInsights } from "../../lib/AppInsights";
import { getEduProgramLogoSrc } from "./eduUtils";
import { EduCourseListTypes, EduPageViews } from "./constants";

class Edu extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      enrollmentId: null,
      menuDropdownOpen: false,
      showEnrollmentCompleted: false,
    };

    this.isShliach = props.auth.loggedInAs === AuthTypes.shliach;
  }

  componentDidMount() {
    this.getEduEnrollment();
  }

  getEduEnrollment = async () => {
    const {
      actions: { getEduEnrollment, getEduPrograms },
      pageRoute: { page, params, query },
    } = this.props;

    let { programScheduleId } = params;

    let {
      programs: { data: programs },
    } = this.props;

    if (!programs || !programs.length) {
      await getEduPrograms();
      programs = this.props.programs.data;
    }

    if (!programScheduleId && programs && programs.length) {
      programScheduleId = programs[0].programScheduleID;
      Navigation.redirect(
        PageURL.to(
          page,
          {
            ...params,
            programScheduleId,
          },
          query,
        ),
      );
    }

    if (programScheduleId) {
      getEduEnrollment(programScheduleId);
    }
  };

  toggleShowEnrollmentCompleted = () => {
    const {
      pageRoute: { page, params },
    } = this.props;
    const { showEnrollmentCompleted } = this.state;

    //redirect to all courses when toggle off showEnrollmentCompleted to get started
    if (showEnrollmentCompleted) {
      Navigation.redirect(
        PageURL.to(
          page,
          {
            pageView: EduPageViews.courses,
            programScheduleId: params.programScheduleId,
          },
          { type: EduCourseListTypes.all },
        ),
      );
    }
    setTimeout(
      () =>
        this.setState({
          showEnrollmentCompleted: !showEnrollmentCompleted,
        }),
      0,
    );
  };

  renderJewishUMenuDropdownContent = () => {
    const {
      pageRoute: { page, params },
    } = this.props;

    return (
      <div>
        <p
          className="fw-700 medium-text edu-page-menu-title flex flex-align-center"
          onClick={() =>
            this.setState((prevState) => ({
              menuDropdownOpen: !prevState.menuDropdownOpen,
            }))
          }
        >
          JewishU
          <i className="material-icons ml-4">keyboard_arrow_down</i>
        </p>
        <div
          className={`edu-page-menu-dropdown-box dropdown-box ${
            this.state.menuDropdownOpen ? "open" : ""
          }`}
        >
          <JewishUMenuDropdown
            path={page}
            programScheduleId={params?.programScheduleId}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      auth,
      enrollment: {
        data: enrollment,
        error: enrollmentError,
        errorMessage: enrollmentErrorMessage,
        loading,
      },
      pageRoute,
      programs: {
        data: eduPrograms,
        error: eduProgramsError,
        errorMessage: eduProgramsErrorMessage,
        loading: eduProgramsLoading,
      },
      programRoutes,
    } = this.props;

    const { showEnrollmentCompleted } = this.state;

    const error = enrollmentError || eduProgramsError;
    const errorMessage = enrollmentErrorMessage || eduProgramsErrorMessage;

    //set eduProgram info for error - when no enrollment with associated program info is present
    let eduProgram;
    if (error) {
      eduProgram =
        eduPrograms &&
        eduPrograms.find(
          (program) =>
            program.programScheduleID.toString() ===
            pageRoute.params.programScheduleId,
        );
    }

    return (
      <>
        <EduHeader
          eduPrograms={eduPrograms}
          enrollment={enrollment}
          pageRoute={pageRoute}
          programRoutes={programRoutes}
        />
        {enrollment && (
          <div className="edu-page-breadcrumbs container">
            {this.isShliach ? (
              <ProgramBreadcrumbsHeader
                scheduleId={
                  eduPrograms?.find((p) => p.id === enrollment.eduProgramID)
                    ?.programScheduleID
                }
              />
            ) : (
              // dummy div to keep the mobile menu right-aligned
              <div />
            )}
            {enrollment.eduProgramName === "JewishU" && (
              <div className="desktop-hidden tablet-hidden mr-16">
                {this.renderJewishUMenuDropdownContent()}
              </div>
            )}
          </div>
        )}
        <div className="edu-page page container">
          {loading || eduProgramsLoading ? (
            <div className="full-page-loader">
              <Loader />
            </div>
          ) : error ? (
            IsApiErrorStatus(error, 404) ? (
              <NoEduAccess
                eduProgramName={
                  eduProgram ? eduProgram.name : "this learning initiative"
                }
                eduLogoSrc={eduProgram && getEduProgramLogoSrc(eduProgram.type)}
              />
            ) : (
              <ErrorDisplay
                className="edu-error-page"
                errorMessage={
                  errorMessage ||
                  `Something went wrong and we could not retrieve ${
                    eduProgram ? eduProgram.name : "this learning initiative"
                  } information. Please try again.`
                }
              />
            )
          ) : (
            enrollment && (
              <React.Fragment>
                {enrollment.isPending ? (
                  <EduEnrollmentForm
                    {...this.props}
                    toggleShowEnrollmentCompleted={
                      this.toggleShowEnrollmentCompleted
                    }
                  />
                ) : showEnrollmentCompleted ? (
                  <EduEnrollmentCompleted
                    auth={auth}
                    enrollment={enrollment}
                    toggleShowEnrollmentCompleted={
                      this.toggleShowEnrollmentCompleted
                    }
                  />
                ) : (
                  <MyEdu {...this.props} />
                )}
              </React.Fragment>
            )
          )}
        </div>
      </>
    );
  }
}

export default withAppInsights(Edu);
