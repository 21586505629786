import React, { useCallback, useEffect, useRef } from "react";
import { useFormState } from "react-hook-form";
import { scrollToFormErrors } from "./form_utils";

type FormSubmissionWrapperProps = {
  children: (onSubmit: () => void) => any;
};

export const FormSubmissionWrapper = React.memo(
  /**
   *
   */

  function FormSubmissionWrapper({ children }: FormSubmissionWrapperProps) {
    const { errors } = useFormState();

    const scrollToError = useRef(false); // ref to control the scroll window - watch for form errors within 1 second after submission click

    const onSubmit = useCallback(() => {
      scrollToError.current = true;
      setTimeout(() => (scrollToError.current = false), 1000); // close scroll window after 1 second
    }, []);

    useEffect(() => {
      if (scrollToError.current) scrollToFormErrors(errors);
    }, [errors]);

    return children(onSubmit);
  },
);
