import React from "react";
import Autocomplete from "react-autocomplete";
import Loader from "./Loader";
import { AuthRequest, Navigation, PageURL } from "../lib";
import TableResultsView from "./TableResultsViewDeprecated";

function getSearchAutocompleteItemValue(item) {
  return item.value;
}

/**
 * @deprecated Use the updated PaginatedTable for functional components
 */
export default class PaginatedTable extends React.Component {
  state = {
    autocompleteItems: [],
    searchFocused: false,
    searchInputValue: this.props.searchTerm || "",
    scrollPage: 1,
  };

  componentDidMount() {
    const {
      loadData,
      page,
      resultsView,
      searchTerm,
      enrollmentID,
      eventID,
      status,
    } = this.props;
    loadData(page, resultsView, searchTerm, enrollmentID, eventID, status);
  }

  doAutocomplete = async (value) => {
    if (!value) {
      return;
    }
    const { autocompleteEndpoint } = this.props;
    const text = encodeURIComponent(value);
    const {
      data: { payload: items = [] },
    } = await AuthRequest.get(`${autocompleteEndpoint}/${text}`);
    const autocompleteItems = items.map((value, index) => ({
      index,
      value,
    }));
    this.setState({
      autocompleteItems,
    });
  };

  getRows() {
    return this.props.records.map((item, index) =>
      this.props.renderRow(item, index),
    );
  }

  search = (searchQuery) => {
    const { pageRoute, resultsView } = this.props;
    searchQuery = searchQuery || this.state.searchInputValue;
    const url = PageURL.to(pageRoute.page, null, {
      q: searchQuery,
      r: resultsView,
    });
    Navigation.go(url);
  };

  searchFocusOutline = () => {
    this.setState({ searchFocused: !this.state.searchFocused });
  };

  getPaginationFooter() {
    let numberOfPages = Math.ceil(
      (this.props.totalCount || 0) / (this.props.resultsView || 1),
    );
    if (numberOfPages < 2) {
      return null;
    }
    let pagesArray = Array(numberOfPages)
      .fill()
      .map((_, i) => i + 1);
    let page = parseInt(this.props.page, 10);
    if (pagesArray.length > 10) {
      let pagesArrayCopy = pagesArray.slice(0, pagesArray.length);
      let startPg = pagesArrayCopy.shift();
      let endPg = pagesArrayCopy.pop();
      if (page < 7 && page !== 6) {
        pagesArray = pagesArray.slice(0, 6);
        pagesArray.push("afterPages", endPg);
      } else if (page === 6) {
        pagesArray = pagesArray.slice(page - 3, page + 2);
        pagesArray.unshift(startPg, "beforePages");
        pagesArray.push("afterPages", endPg);
      } else if (page + 4 < endPg) {
        pagesArray = pagesArray.slice(page - 3, page + 2);
        pagesArray.unshift(startPg, "beforePages");
        pagesArray.push("afterPages", endPg);
      } else {
        pagesArray = pagesArray.slice(pagesArray.length - 6, pagesArray.length);
        pagesArray.unshift(startPg, "beforePages");
      }
    } else {
      pagesArray = pagesArray.slice(0, 10);
    }

    return (
      <ul className={`pagination ${this.props.loading ? "disabled" : ""}`}>
        {page > 1 ? (
          <li
            key="prev"
            className="pagination-arrow"
            onClick={this.paginate.bind(this, this.props.page - 1)}
          >
            <i className="material-icons">keyboard_arrow_left</i>
          </li>
        ) : null}

        {pagesArray.map((pageNumber) =>
          typeof pageNumber === "number" ? (
            <li
              key={pageNumber}
              className={
                this.props.page.toString() === pageNumber.toString()
                  ? "active"
                  : ""
              }
              onClick={() => this.paginate(pageNumber)}
            >
              {pageNumber}
            </li>
          ) : (
            <li key={pageNumber}>...</li>
          ),
        )}

        {page === numberOfPages ? null : (
          <li
            key="next"
            className="pagination-arrow"
            onClick={this.paginate.bind(this, page + 1)}
          >
            <i className="material-icons">keyboard_arrow_right</i>
          </li>
        )}
      </ul>
    );
  }

  paginate(page) {
    const {
      enrollmentID,
      eventID,
      getURL,
      loadData,
      loading,
      resultsView,
      searchTerm,
      status,
    } = this.props;
    if (loading) {
      return;
    }
    Navigation.redirect(getURL(page, resultsView));
    setTimeout(
      () =>
        loadData(page, resultsView, searchTerm, enrollmentID, eventID, status),
      0,
    );
    window.scrollTo({ left: 0, top: 0 });
  }

  onScroll = (event) => {
    const hasMore =
      this.props.page <
      Math.ceil((this.props.totalCount || 0) / this.props.resultsView);
    if (
      !this.props.loading &&
      hasMore &&
      event.target.scrollHeight -
        event.target.clientHeight -
        event.target.scrollTop <=
        100
    ) {
      this.setState((state) => {
        this.props.loadData(
          state.scrollPage + 1,
          15,
          this.props.searchTerm,
          true,
        );
        return {
          ...state,
          scrollPage: state.scrollPage + 1,
        };
      });
    }
  };

  renderSearchAutocompleteItem = (item, isHighlighted) => {
    return (
      <div
        key={item.index}
        style={{
          background: isHighlighted ? "#f5f5f5" : "white",
          cursor: "pointer",
          padding: "8px 16px",
        }}
      >
        {item.value}
      </div>
    );
  };

  renderSearchAutocompleteMenu = (items, value, style) => {
    if (items.length < 1) {
      return <div />;
    }
    return (
      <div
        style={{ ...style }}
        children={items}
        className="autocomplete-menu"
      />
    );
  };

  renderSearchInput = (props) => {
    return (
      <div
        className={
          this.state.searchFocused
            ? "search-input search-input-focused"
            : "search-input"
        }
      >
        <input
          type="text"
          placeholder="Search anything (i.e: name, school, Jewish student life, etc...)"
          {...props}
        />
        <i
          className="material-icons accent-text-secondary pointer"
          onClick={() => this.search()}
        >
          search
        </i>
      </div>
    );
  };

  render() {
    const { autocompleteItems, searchInputValue, scrollPage } = this.state;
    const {
      autocompleteEndpoint,
      className,
      filterComponent,
      filterComponentClassName,
      fullHeight,
      isPhone,
      loaderComponent,
      loading,
      name,
      noRecordsDisplay,
      records,
      renderHeaderRow,
      totalCount,
      searchTerm,
      showResultsTotal,
      showResultsView,
      success,
      hideViewResults = false,
    } = this.props;

    return (
      <div className={fullHeight ? "full-height" : ""}>
        {name === "Contacts" ? (
          <div className="table-header">
            {autocompleteEndpoint ? (
              <Autocomplete
                autoHighlight={false}
                getItemValue={getSearchAutocompleteItemValue}
                items={autocompleteItems}
                value={searchInputValue}
                renderInput={this.renderSearchInput}
                renderMenu={this.renderSearchAutocompleteMenu}
                renderItem={this.renderSearchAutocompleteItem}
                inputProps={{
                  onFocus: this.searchFocusOutline,
                  onBlur: this.searchFocusOutline,
                  onKeyDown: (event) => {
                    if (event.key === "Enter") this.search();
                  },
                }}
                onChange={(event) => {
                  const value = event.target.value;
                  this.setState({ searchInputValue: value });
                  this.doAutocomplete(value);
                }}
                onSelect={this.search}
              />
            ) : (
              filterComponent || ""
            )}
            {totalCount > 0 && (
              <div className="table-header-left">
                <p className="accent-text-dark">
                  <span className="fw-700">{totalCount}</span> Results
                </p>
                {!isPhone && !hideViewResults && (
                  <div className="flex flex-align-center ml-8">
                    <span className="accent-text-dark">|</span>{" "}
                    <TableResultsView
                      increment={this.props.resultsIncrement}
                      {...this.props}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div
            className={`flex flex-justify-space flex-align-center  mb-24 ${
              filterComponentClassName ? filterComponentClassName : ""
            }`}
          >
            {filterComponent || <div />}

            {showResultsView && !hideViewResults ? (
              <TableResultsView
                increment={this.props.resultsIncrement}
                {...this.props}
              />
            ) : null}
          </div>
        )}
        {!loading && !records.length ? (
          <div className="empty-state">
            {success === false ? (
              <React.Fragment>
                <img src="/images/error.svg" alt="error robot" height="240" />
                <p className="xxl-text mt-24 mb-16">An error has occured</p>
                <p className="medium-text accent-text fw-500">
                  Our server is being stubborn, please try again
                </p>
              </React.Fragment>
            ) : !records.length && searchTerm ? (
              <React.Fragment>
                <img
                  src="/images/no_results.svg"
                  alt="no results"
                  height="200"
                />
                <p className="xxl-text mt-24 mb-16">
                  No results found for '{searchTerm}'
                </p>
                <p className="medium-text accent-text fw-500">
                  Try adjusting your search terms
                </p>
              </React.Fragment>
            ) : (
              noRecordsDisplay || (
                <React.Fragment>
                  <img
                    src={
                      name === "Orders"
                        ? "/images/no_raffle_orders.svg"
                        : "/images/no_results.svg"
                    }
                    height="200"
                    alt="no results"
                  />
                  <p className="xxl-text mt-24 mb-16">No results found</p>
                </React.Fragment>
              )
            )}
          </div>
        ) : (
          <React.Fragment>
            <div
              className={`${isPhone ? "table full-width-table" : "table"} ${
                className ? className : ""
              }`}
            >
              {renderHeaderRow && renderHeaderRow()}
              {loading && (!isPhone || scrollPage === 1) ? (
                loaderComponent || (
                  <div
                    className={name === "Contacts" ? "full-page-loader" : ""}
                    style={{ clear: "both" }}
                  >
                    <Loader />
                  </div>
                )
              ) : (
                <div
                  className={isPhone ? "table-rows" : null}
                  onScroll={isPhone ? this.onScroll : null}
                >
                  {this.getRows()}
                  {isPhone && loading && (
                    <div className="mobile-loader">
                      <Loader />
                    </div>
                  )}
                </div>
              )}
            </div>
            {!isPhone && records.length > 0 && (
              <div className="flex flex-align-center flex-justify-space">
                {showResultsTotal && (
                  <p className="accent-text-dark mt-24 mobile-hidden full-width">
                    <span className="fw-700">{totalCount}</span> Results
                  </p>
                )}
                {this.getPaginationFooter()}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
