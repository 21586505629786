import React, { memo, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Divider, Grid, Typography } from "@material-ui/core";
import Loader from "../../components/Loader";
import { UpdateActions, UpdateSelectors, useAppDispatch } from "../../state";
import { useSelector } from "react-redux";

function UpdateDetails() {
  const dispatch = useAppDispatch();
  const { updateId }: any = useParams();
  const { goBack } = useHistory();

  const loading = useSelector(UpdateSelectors.loading);
  const postDetails: any = useSelector(UpdateSelectors.details);

  useEffect(() => {
    dispatch(UpdateActions.getPost(updateId));
  }, [dispatch, updateId]);

  const featuredImage =
    postDetails._embedded &&
    postDetails._embedded["wp:featuredmedia"] &&
    postDetails._embedded["wp:featuredmedia"][0].source_url;

  const getTitle = () => {
    return { __html: postDetails.title && postDetails.title.rendered };
  };

  const date = new Date(postDetails.date).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const getContent = () => {
    return { __html: postDetails.content && postDetails.content.rendered };
  };

  return (
    <div className="page update-details-page">
      <p onClick={goBack} className="link-text mobile-hidden">
        <i className="material-icons back-arrow">arrow_back</i>
        Go back
      </p>
      {loading ? (
        <div className="full-page-loader">
          <Loader />
        </div>
      ) : (
        <>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className="update-feature-top"
          >
            <Grid item md={5}>
              <Typography
                variant="h1"
                className="mb-8"
                dangerouslySetInnerHTML={getTitle()}
              />

              <Typography variant="subtitle1">{date}</Typography>
            </Grid>
            <Grid item className="update-featured-img">
              <img
                src={featuredImage ?? "/images/placeholder.svg"}
                alt="blog post featured"
              />
            </Grid>
          </Grid>
          <Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              container
              className="post-body-container mt-8"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                lg={8}
                item
                className="post-body"
                dangerouslySetInnerHTML={getContent()}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

export default memo(UpdateDetails);
