import Students from "./Students.vm";
import StudentDetails from "./StudentDetailsPage";
import StudentMergeDuplicatesPage from "../students/mergeDuplicates/StudentMergeDuplicatesPage";

// Student pages without the Engagement layout
export const StudentsPages = {
  students: {
    path: "/students",
    type: "PAGE_STUDENTS",
    title: "Students",
    view: Students,
  },
  studentMerge: {
    path: "/students/merge",
    type: "PAGE_STUDENT_MERGE_DUPLICATES",
    title: "Student Merge Duplicates",
    view: StudentMergeDuplicatesPage,
  },
  studentDetails: {
    path: "/students/:studentId",
    type: "PAGE_STUDENT_DETAILS",
    title: "Student Details",
    view: StudentDetails,
  },
};
export default StudentsPages;

export const StudentsArea = {
  pages: StudentsPages,
};
