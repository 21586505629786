import React from "react";

export default class Modal extends React.Component {
  componentDidMount() {
    this.setOverflowStyles();
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      this.setOverflowStyles();
    }
  }

  setOverflowStyles = () => {
    const body = document.getElementsByTagName("body")[0];
    if (this.props.show === true) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
      body.style.paddingRight = "0";
    }
  };

  componentWillUnmount() {
    const body = document.getElementsByTagName("body")[0];
    body.style.overflow = "auto";
    body.style.paddingRight = "0";
  }

  render() {
    return <>{this.props.show && this.props.children}</>;
  }
}
