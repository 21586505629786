import React from "react";
import NumberFormat from "react-number-format";
import Loader from "../../components/Loader";

import { getCurrencyDisplay, parseNumber } from "../../lib";
import Big from "big.js";

export default class BillingSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      installmentDatesError: false,
      promoCodeString: "",
      promoCodeError: "",
      scholarshipFundDonationAmount: "",
    };
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleOnEnter = (event, onEnter) => {
    if (event.key === "Enter") onEnter();
  };

  handleApplyPromo = (invoiceTotal) => {
    const { promoCodeString } = this.state;
    const {
      eventSettings: { promoCode },
      invoice,
      onChange,
    } = this.props;

    if (!promoCodeString) return;

    const isValidPromoString =
      promoCode.trim().toLowerCase() === promoCodeString.trim().toLowerCase();
    const validatedPromo = this.validatePromocode(invoiceTotal);

    if (!invoice.applyPromoCode && isValidPromoString && validatedPromo) {
      onChange("invoice.applyPromoCode", true);
      this.setState({ promoCodeString: "" });
    } else {
      let promoCodeError = "";

      if (isValidPromoString && validatedPromo && invoice.applyPromoCode) {
        promoCodeError = `Promo code ${promoCode} has already been applied`;
      } else if (isValidPromoString && !validatedPromo) {
        promoCodeError = `Order is not eligible for promo code ${promoCode}`;
      } else {
        promoCodeError = "Invalid promo code";
      }

      this.setState({ promoCodeError }, () =>
        setTimeout(() => {
          this.setState({ promoCodeError: "" });
        }, 3000),
      ); //TODO: handle still mounted?
    }
  };

  validatePromocode = (invoiceTotal) => {
    const {
      attendance: { childrenAttending, parentsAttending },
      eventSettings: { promoCodeThreshold, promoCodeCounter, promoCodeRules },
    } = this.props;

    return (
      promoCodeThreshold > promoCodeCounter &&
      childrenAttending >= promoCodeRules.minimumChildren &&
      parentsAttending >= promoCodeRules.minimumAdults &&
      invoiceTotal >= promoCodeRules.minimumTotal
    );
  };

  handleApplyDonation = () => {
    const { scholarshipFundDonationAmount } = this.state;
    const { invoice, onChange } = this.props;

    if (!scholarshipFundDonationAmount) return;

    const donationAmount = Big(scholarshipFundDonationAmount).plus(
      invoice.scholarshipFundDonation || 0,
    );
    onChange("invoice.scholarshipFundDonation", donationAmount);

    this.setState({ scholarshipFundDonationAmount: "" });
  };

  handleRemovePromo = () => {
    this.props.onChange("invoice.applyPromoCode", false);
  };

  handleRemoveDonation = () => {
    this.props.onChange("invoice.scholarshipFundDonation", 0);
  };

  onSubmitPayment = () => {
    const { billingInstallmentDates, invoice, submitPayment } = this.props;

    //If submit before remounting payment (which recalculates installment dates) after installment date is invalidated, need to catch here
    if (!invoice.payInFull) {
      const today = new Date().setHours(0, 0, 0, 0);
      const installmentDatesAreValid =
        billingInstallmentDates.filter((date) => date.localDate > today)
          .length === billingInstallmentDates.length;

      this.setState({ installmentDatesError: !installmentDatesAreValid });
      if (!installmentDatesAreValid) return;
    } else if (this.state.installmentDatesError) {
      this.setState({ installmentDatesError: false });
    }

    submitPayment();
  };

  render() {
    const {
      billingInstallmentDates,
      completeRequiredBeforePayment,
      eventSettings: { promoCode, promoCodeDiscount },
      invoice: {
        lineItems,
        applyPromoCode,
        payInFull,
        scholarshipFundDonation,
      },
      registration,
      showPayButton,
    } = this.props;
    const {
      installmentDatesError,
      promoCodeString,
      promoCodeError,
      scholarshipFundDonationAmount,
    } = this.state;

    let lineItemsWithPrices = [];
    let totalFullCost = Big(0);
    let totalSubsidizedCost = Big(0);
    lineItems.forEach((lineItem) => {
      const fullPrice = Big(lineItem.fullCost).times(lineItem.quantity);
      const subsidizedPrice = Big(lineItem.subsidizedCost).times(
        lineItem.quantity,
      );
      totalFullCost = totalFullCost.plus(fullPrice);
      totalSubsidizedCost = totalSubsidizedCost.plus(subsidizedPrice);

      lineItemsWithPrices.push({ ...lineItem, fullPrice, subsidizedPrice });
    });

    const totalSubsidy = totalFullCost.minus(totalSubsidizedCost);

    let totalPayment = Big(totalSubsidizedCost);
    let paymentInstallments = 1;
    let initialInstallmentPayment;
    if (applyPromoCode) {
      totalPayment = totalPayment.minus(promoCodeDiscount);
      if (totalPayment < 0) totalPayment = Big(0);
    }
    if (scholarshipFundDonation) {
      totalPayment = totalPayment.plus(scholarshipFundDonation);
    }
    if (!payInFull && billingInstallmentDates) {
      paymentInstallments += billingInstallmentDates.length;

      if (paymentInstallments > 1) {
        initialInstallmentPayment = totalPayment
          .div(paymentInstallments)
          .round();
      }
    }

    const invalidAppliedPromo = applyPromoCode
      ? !this.validatePromocode(totalSubsidizedCost)
      : false;

    return (
      <div className="billing-summary card">
        <p className="large-text fw-700 mb-24">Billing Summary</p>
        <div className="billing-summary-top-section">
          <div className="billing-summary-row">
            <p className="accent-text-secondary small-text">Item</p>
            <p className="accent-text-secondary small-text">Qty</p>
            <p className="accent-text-secondary small-text">Full Cost</p>
            <p className="accent-text-secondary small-text">Your Cost</p>
          </div>
          {lineItemsWithPrices.map((lineItem, index) => (
            <div key={index} className="billing-summary-row">
              <p>{lineItem.description}</p>
              <p>{lineItem.quantity}</p>
              <p
                className="small-text accent-text"
                style={{ textDecoration: "line-through" }}
              >
                {lineItem.fullPrice > 0
                  ? getCurrencyDisplay(lineItem.fullPrice)
                  : ""}
              </p>
              <p>{getCurrencyDisplay(lineItem.subsidizedPrice)}</p>
            </div>
          ))}
        </div>
        <div className="billing-summary-bottom-section mb-40">
          <div className="flex flex-justify-space flex-align-center mb-16">
            <p>Full Cost</p>
            <p>{getCurrencyDisplay(totalFullCost)}</p>
          </div>
          <div className="flex flex-justify-space flex-align-center mb-16">
            <p>COCI Subsidy</p>
            <p>{getCurrencyDisplay(totalSubsidy)}</p>
          </div>
          {applyPromoCode && (
            <div className="flex flex-justify-space flex-align-center mb-16">
              <p style={{ color: "#F3893D" }}>Promo: {promoCode}</p>
              <div className="flex">
                <p>-{getCurrencyDisplay(promoCodeDiscount)}</p>
                <p onClick={this.handleRemovePromo} className="ml-8 pointer">
                  &times;
                </p>
              </div>
            </div>
          )}
          {scholarshipFundDonation > 0 && (
            <div className="flex flex-justify-space flex-align-center mb-16">
              <p style={{ color: "#63C674" }}>Scholarship Fund Donation</p>
              <div className="flex">
                <p>{getCurrencyDisplay(scholarshipFundDonation)}</p>
                <p onClick={this.handleRemoveDonation} className="ml-8 pointer">
                  &times;
                </p>
              </div>
            </div>
          )}
          <div className="flex flex-justify-space flex-align-center mb-16">
            <p className="large-text fw-500">Your Cost</p>
            <p className="large-text fw-500">
              {getCurrencyDisplay(totalPayment)}
            </p>
          </div>
          {paymentInstallments > 1 && (
            <div className="flex flex-justify-space flex-align-center mb-8">
              <p className="large-text fw-500">Due Today</p>
              <p className="large-text fw-500">
                {getCurrencyDisplay(initialInstallmentPayment)}
              </p>
            </div>
          )}

          {paymentInstallments > 1 && (
            <p
              className="accent-text-dark small-text mb-32"
              style={{ lineHeight: "1.5" }}
            >
              {`
                        The outstanding balance will be automatically charged to your card
                        ${
                          billingInstallmentDates.length === 1
                            ? ` on ${billingInstallmentDates[0].formattedDate}`
                            : ` in installments, on ${billingInstallmentDates
                                .slice(0, -1)
                                .map((date) => date.formattedDate)
                                .join(", ")}
                                    and ${billingInstallmentDates
                                      .slice(-1)
                                      .map((date) => date.formattedDate)
                                      .join(" ")}`
                        }.
                        `}
            </p>
          )}
        </div>
        <div className="promo-code">
          <p className="fw-700" style={{ fontSize: "13px" }}>
            Enter Family Discount Code
          </p>
          <p
            className="accent-text-dark small-text mb-32"
            style={{ lineHeight: "1.5" }}
          >
            *for registrations with at least two adults & two children
          </p>
          <div className="flex flex-align-center">
            <input
              type="text"
              className="custom-input full-width"
              placeholder="Enter Code"
              name="promoCodeString"
              value={promoCodeString}
              onChange={(event) =>
                this.handleChange(event.target.name, event.target.value)
              }
              onKeyDown={(event) =>
                this.handleOnEnter(event, () =>
                  this.handleApplyPromo(totalSubsidizedCost),
                )
              }
            />
            <p
              className={`link-text uppercase-text ml-16 ${
                !promoCodeString ? "disabled" : ""
              }`}
              onClick={() => this.handleApplyPromo(totalSubsidizedCost)}
            >
              Apply
            </p>
          </div>
          {promoCodeError && <p className="error-message">{promoCodeError}</p>}
        </div>
        <div className="scholarship-fund" style={{ marginTop: "56px" }}>
          <p className="large-text fw-700 mb-8">Scholarship Fund Donation</p>
          <p
            className="accent-text-dark small-text mb-32"
            style={{ lineHeight: "1.5" }}
          >
            Although highly subsidized, the travel & Kinus costs are prohibitive
            for some Shluchim. You can help a fellow shliach by contributing to
            the Kinus Scholarship fund.
          </p>
          <p className="fw-700 mb-8" style={{ fontSize: "13px" }}>
            Please charge my card an additional
          </p>
          <div className="flex flex-align-center">
            <NumberFormat
              className="custom-input full-width"
              placeholder="$100.00"
              thousandSeparator={true}
              name="scholarshipFundDonationAmount"
              value={scholarshipFundDonationAmount}
              onKeyDown={(event) =>
                this.handleOnEnter(event, this.handleApplyDonation)
              }
              onChange={(e) =>
                this.handleChange(
                  "scholarshipFundDonationAmount",
                  parseNumber(e.target.value),
                )
              }
            />
            <p
              className={`link-text uppercase-text ml-16 ${
                !scholarshipFundDonationAmount ? "disabled" : ""
              }`}
              onClick={this.handleApplyDonation}
            >
              Apply
            </p>
          </div>
        </div>

        {showPayButton &&
          totalPayment > 0 &&
          (registration.loading ? (
            <div className="kinus-btn-loader">
              <Loader />
            </div>
          ) : (
            <React.Fragment>
              <button
                className="btn btn-accent btn-large btn-fullWidth mt-24"
                style={{ fontSize: "12px" }}
                disabled={completeRequiredBeforePayment || invalidAppliedPromo}
                onClick={this.onSubmitPayment}
              >
                REGISTER & PAY
              </button>
              {invalidAppliedPromo ? (
                <p
                  className="error-message"
                  style={{ fontSize: "11px", marginTop: "4px" }}
                >
                  Applied promocode is no longer valid. Please remove before
                  proceeding.
                </p>
              ) : completeRequiredBeforePayment ? (
                <p
                  className="error-message"
                  style={{ fontSize: "11px", marginTop: "4px" }}
                >
                  Please complete all required fields before registering.
                </p>
              ) : !payInFull && installmentDatesError ? (
                <p
                  className="error-message"
                  style={{ fontSize: "11px", marginTop: "4px" }}
                >
                  Payment installment dates are no longer valid. Please refresh
                  the page to update the information and review before
                  proceeding.
                </p>
              ) : (
                registration.success === false && (
                  <p
                    className="error-message"
                    style={{ fontSize: "11px", marginTop: "4px" }}
                  >
                    {registration.message ||
                      "Sorry, something went wrong and your registration could not be completed.  Please try again."}
                  </p>
                )
              )}
            </React.Fragment>
          ))}
      </div>
    );
  }
}
