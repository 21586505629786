import React from "react";
import Select from "react-select";
import { Navigation, PageURL } from "../../lib";

export default class LamplightersSchedulesList extends React.PureComponent {
  componentDidMount() {
    this.getSchedules();
  }

  getSchedules = async () => {
    await this.props.getSchedules();

    const {
      getScheduleDetails,
      pageRoute: {
        params: { programScheduleId },
      },
      schedules: { data: schedules = [] },
    } = this.props;

    if (programScheduleId) {
      //if programScheduleId is selected, call to parent to get details for currently selected schedule (request details even if not included in schedules list - so No Access to schedule view can be shown)
      getScheduleDetails();
      return;
    }

    const defaultSchedule = schedules[0];
    if (defaultSchedule) {
      this.onSelectSchedule(defaultSchedule.programScheduleID);
    }
  };

  onSelectSchedule = (programScheduleId) => {
    const {
      pageRoute: {
        page,
        query: { tab },
      },
    } = this.props;
    Navigation.go(PageURL.to(page, { programScheduleId }, { tab }));
  };

  render() {
    const {
      pageRoute: {
        params: { programScheduleId },
      },
      schedules: { data: schedules = [], errorMessage, loading },
    } = this.props;

    const scheduleOptions = schedules.map((sched) => ({
      label: sched.programScheduleName,
      value: sched.programScheduleID,
    }));

    return (
      <div className="card trip-sidebar-card mb-32">
        <Select
          className="custom-select"
          classNamePrefix="custom-select"
          onChange={({ value }) => this.onSelectSchedule(value)}
          options={scheduleOptions}
          placeholder={
            loading ? "Loading schedules..." : "Select past schedule..."
          }
          styles={{
            indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
            placeholder: (styles) => ({ ...styles, fontSize: "12px" }),
            clearIndicator: (styles) => ({
              ...styles,
              marginRight: "-8px",
              padding: "0",
            }),
          }}
          value={
            programScheduleId &&
            scheduleOptions.find(
              ({ value }) => value.toString() === programScheduleId.toString(),
            )
          }
        />
        {errorMessage && (
          <p className="error-text small-text line-height-double mt-8">
            Something went wrong and Lamplighters schedules could not be
            retrieved. Please refresh the page to try again.
          </p>
        )}
      </div>
    );
  }
}
