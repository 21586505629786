import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ActivityActions } from "../../state/activity/actions";
import { RaffleActions } from "../../state/raffle/actions";
import { UpdatesActions } from "../../state/updates/actions";
import DefaultHome from "./DefaultHome";

export default connect(
  function mapState(state) {
    return {
      shliachID: state.auth.shliachID,
      loadingActivity: state.activity.loading,
      shliachViews: state.activity.shliachViews || [],
      programViews: state.activity.programViews || [],
      raffle: state.raffle,
      updates: state.updates,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          getActivity: ActivityActions.getActivity,
          getPosts: UpdatesActions.getPosts,
          getCurrentRaffle: RaffleActions.getCurrentRaffle,
          getRaffleEnrollment: RaffleActions.getRaffleEnrollment,
          getRaffleEnrollmentStatus: RaffleActions.getRaffleEnrollmentStatus,
        },
        dispatch,
      ),
    };
  },
)(DefaultHome);
