import React from "react";
import { Prompt } from "react-router";

import RegistrationInfo from "./registrationInfo/RegistrationInfo";
import SeatingPreferences from "./seatingPreferences/SeatingPreferences";
import Childcare from "./childcare/Childcare";
import Confirm from "./confirm/Confirm";
import Payment from "./payment/Payment";
import BillingSummary from "./BillingSummary";
import RegistrationCompleted from "./registrationStates/RegistrationCompleted";

import { injectStripe } from "react-stripe-elements";
import * as formFunctions from "./FormLogic";
import _set from "lodash.set";
import _isEqual from "lodash.isequal";
import _cloneDeep from "lodash.clonedeep";

class KinusForm extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      profile,
      profile: { children, credCardInfo },
      settings: { ageGrades, hotelExtras, hotelNights },
    } = props;

    this.state = {
      currentProfile: profile,

      formValues: {
        attendees: {
          husbandPersonID: 0,
          wifePersonID: 0,
          childrenPersonIDs: [],
        },
        hotelStay: {
          nights: hotelNights
            .filter((night) => night.isKinusNight)
            .map((night) => night.date),
          numberOfRooms: 0,
          connectingRooms: false,
          extras: hotelExtras.map((extra) => ({
            hotelExtras: extra.enumValue,
            numberOfExtras: 0,
            displayValue: extra.displayValue,
          })),
        },
        seating: {
          seatingPreference: null,
          separateSeatingChildPreferences: [],
        },
        toKinusBussing: {
          numberOfAdults: 0,
          numberOfChildren: 0,
        },
        fromKinusBussing: {
          numberOfAdults: 0,
          numberOfChildren: 0,
        },
        childCare: {
          fathersCell: "",
          motherCell: "",
          nightBabysitting: null,
          privateBabysitterFullName: "",
          privateBabysitterRelationship: "",
          children: children.map((child) => ({
            childID: child.id,
            grade:
              (ageGrades.find((ag) => ag.ageYears === child.ageAtKinus) || {})
                .grade || "",
            campName: "",
            transportationToCamp: null,
            canJoinOffsite: false,
            medicalNotes: "",
            generalComments: "",
            toiletTraining: null,
            ageGroupID: child.kinusAgeGroupID,
            needsKinusChildcare: null,
            tShirtSize: null,
            childMovesBy: null,
          })),
        },
        billing: {
          stripeToken: null,
          address: {
            id: 0,
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            discriminator: "None",
          },
          cardHolderFullName: "",
          useCardOnFile: !!credCardInfo,
          hasCompleteCardInfo: !!credCardInfo,
          installmentDates: [],
        },
        invoice: {
          lineItems: [],
          payInFull: true,
          applyPromoCode: false,
          scholarshipFundDonation: 0,
        },
        waiver: {
          didAcceptGeneralTerms: false,
          didAcceptNoShowDisclaimer: false,
          didConfirmAttendeesImmunizations: false,
          didAcceptChildcareTerms: false,
          didAcceptNightBabysittingTerms: false,
          didAcceptBringYourOwnBabysitter: false,
        },
        generalComments: "",
      },

      formValidation: {
        registrationInfo: {},
        seatingPreferences: {},
        childCare: {},
        confirm: {},
        payment: {},
      },

      initialized: false,

      onIndexWizard: null,

      submitAttempted: false,

      wizardIndex: 0,

      wizardSteps: [
        {
          title: "Registration Info",
          name: "registrationInfo",
          touched: false,
          component: RegistrationInfo,
        },
        {
          title: "Seating Preferences",
          name: "seatingPreferences",
          touched: false,
          component: SeatingPreferences,
        },
        {
          title: "Childcare",
          name: "childCare",
          touched: false,
          component: Childcare,
        },
        {
          title: "Confirm",
          name: "confirm",
          touched: false,
          component: Confirm,
        },
        {
          title: "Payment",
          name: "payment",
          touched: false,
          component: Payment,
        },
      ],
    };

    this.state.initialState = _cloneDeep(this.state);

    this.formFunctions = {};
    Object.keys(formFunctions).forEach(
      (func) => (this.formFunctions[func] = formFunctions[func].bind(this)),
    );
  }

  componentWillUnmount() {
    this.cacheKinusForm();
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      this.cacheKinusForm();
      return null;
    };

    this.rehydrateKinusForm();
  }

  cacheKinusForm = () => {
    if (
      !this.props.registration.success &&
      (!_isEqual(this.state.formValues, this.state.initialState.formValues) ||
        !_isEqual(
          this.state.wizardSteps,
          this.state.initialState.wizardSteps,
        ) ||
        !_isEqual(this.state.wizardIndex, this.state.initialState.wizardIndex))
    ) {
      const kinusForm = JSON.stringify(this.state);
      localStorage.setItem(`kinusForm-${this.props.userId}`, kinusForm);
    }
  };

  rehydrateKinusForm = async () => {
    const kinusForm = JSON.parse(
      localStorage.getItem(`kinusForm-${this.props.userId}`),
    );
    if (kinusForm) {
      //set form values
      const updatedFormValues = !_isEqual(
        kinusForm.currentProfile,
        this.props.profile,
      )
        ? this.formFunctions.getFormAfterProfileUpdate(
            kinusForm.currentProfile,
            kinusForm.formValues,
          )
        : kinusForm.formValues;

      updatedFormValues.billing.hasCompleteCardInfo = false; //set hasCompleteCardInfo to false bec stripe clears values on remount
      await this.onChangeForm("formValues", updatedFormValues); //set formvalues and validation

      //set wizard values
      const updatedWizardSteps =
        this.formFunctions.getWizardStepsAfterProfileUpdate(
          kinusForm.wizardSteps,
        ); //cannot maintain wizard step components in local state so need to use current wizard steps and copy dynamic values from storage
      await this.onChange("wizardSteps", updatedWizardSteps);
      const updatedWizardIndex =
        this.formFunctions.getWizardIndexAfterProfileUpdate(
          kinusForm.wizardIndex,
        );
      await this.onChange("wizardIndex", updatedWizardIndex);

      //set validation
      await this.validateForm();

      //set initialState
      await this.onChange("initialState", _cloneDeep(this.state));
    }

    this.setState({ initialized: true });
  };

  setOnIndexWizard = (onIndex) => {
    this.setState({ onIndexWizard: onIndex });
  };

  stepTo = async (index, blockNavOnValidationErrors) => {
    const { onIndexWizard, wizardIndex } = this.state;

    window.scrollTo({ behavior: "smooth", left: 0, top: 0 });

    if (index > wizardIndex)
      await this.onChange(`wizardSteps[${wizardIndex}].touched`, true);

    await this.validateForm();
    if (
      blockNavOnValidationErrors &&
      this.formFunctions.hasValidationErrors() &&
      index > wizardIndex
    ) {
      return; //cannot go forward with validation errors (allow to go back)
    }

    if (onIndexWizard) {
      await onIndexWizard();
      await this.validateForm();
    }

    this.setState({
      onIndexWizard: null,
      wizardIndex: index,
    });
  };

  stepNav = (next, childrenAttending) => {
    const { wizardIndex } = this.state;

    let index = next ? wizardIndex + 1 : wizardIndex - 1;

    if (index === 2 && !childrenAttending) {
      index = next ? index + 1 : index - 1;
    }

    this.stepTo(index, true);
  };

  onChange = (name, value) => {
    let state = _cloneDeep(this.state);
    _set(state, name, value);

    return new Promise((resolve, reject) => {
      this.setState(state, () => {
        resolve();
      });
    });
  };

  onChangeForm = (name, value, calculateAndValidate = true) => {
    let formState = _cloneDeep(this.state.formValues);

    if (name === "formValues") {
      formState = value;
    } else {
      _set(formState, name, value);
    }

    return new Promise((resolve, reject) => {
      this.setState(
        {
          ...this.state,
          formValues: formState,
        },
        async () => {
          if (calculateAndValidate) {
            if (this.formFunctions.fieldAffectsBilling(name))
              await this.calculateInvoice();

            const { wizardSteps, wizardIndex } = this.state;
            if (
              (this.formFunctions.hasValidationErrors() ||
                wizardSteps[wizardIndex].touched) &&
              !this.formFunctions.fieldDoesNotAffectValidation(name)
            )
              await this.validateForm();
          }

          resolve();
        },
      );
    });
  };

  onChangeFormEvent = (event) => {
    return this.onChangeForm(
      event.target.name,
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value,
    );
  };

  validateForm = async () => {
    const validation = this.formFunctions.getFormValidation();
    await this.onChange("formValidation", validation);

    return validation;
  };

  calculateInvoice = () => {
    const invoiceLineItems = this.formFunctions.getInvoiceLineItems();
    this.onChangeForm("invoice.lineItems", invoiceLineItems, false);
  };

  submitForm = async () => {
    await this.onChange(`wizardSteps[${this.state.wizardIndex}].touched`, true);
    await this.validateForm();

    const { registerForKinus, userId } = this.props;
    const { wizardSteps } = this.state;

    this.setState({ submitAttempted: true });

    const hasValidationErrors =
      wizardSteps
        .map((s, index) => this.formFunctions.hasValidationErrors(index))
        .indexOf(true) >= 0;
    if (hasValidationErrors) {
      return;
    }

    const { registrationValues, stripeValues } =
      this.formFunctions.getValuesForSubmission();

    await registerForKinus(
      registrationValues,
      stripeValues,
      this.props.stripe.createToken,
      //refresh system banners if banner exists for this event
      this.props.sys.portalBanners &&
        this.props.sys.portalBanners.find(
          (pb) =>
            pb.programScheduleID ===
            this.props.settings.eventSettings.programScheduleID,
        ),
    );

    const { registration } = this.props;

    if (registration.success) {
      localStorage.removeItem(`kinusForm-${userId}`);
    } else {
      if (
        registration.message &&
        registration.message.indexOf("Promocode is invalid") === 0
      ) {
        await this.onChangeForm("invoice.applyPromoCode", false);
      }
    }
  };

  render() {
    const { editProfile, profile, navigating, registration, settings, sys } =
      this.props;
    const {
      formValues,
      formValidation,
      initialized,
      initialState,
      submitAttempted,
      wizardIndex,
      wizardSteps,
    } = this.state;

    const childrenAttending = formValues.attendees.childrenPersonIDs.length;
    const parentsAttending =
      (formValues.attendees.husbandPersonID ? 1 : 0) +
      (formValues.attendees.wifePersonID ? 1 : 0);

    const currentStepValidationErrors =
      this.formFunctions.hasValidationErrors();
    const lastTouchedWizardIndex = wizardSteps
      .map((s) => s.touched)
      .lastIndexOf(true);

    const { loading: registrationInProcess, success: registrationCompleted } =
      registration;

    return (
      <div style={{ paddingBottom: "40px" }}>
        <Prompt
          when={
            !navigating &&
            !registrationCompleted &&
            !_isEqual(formValues, initialState.formValues)
          }
          message="Your changes have not been submitted. Are you sure you want to leave this page?"
        />
        <div className="kinus-page-wizard-steps">
          <div className="container">
            {wizardSteps.map((s, index) => {
              const isActive = wizardIndex === index && !registrationCompleted;
              const isDisabled =
                lastTouchedWizardIndex < index ||
                (index === 2 && !childrenAttending);
              const isError =
                !registrationCompleted &&
                this.formFunctions.hasValidationErrors(index);
              const isCompleted =
                registrationCompleted || (s.touched && !isError);

              return (
                <div
                  key={index}
                  className={`wizard-step ${
                    isActive
                      ? "active"
                      : isDisabled
                      ? "disabled"
                      : isError
                      ? "error"
                      : isCompleted
                      ? "completed"
                      : ""
                  }`}
                  onClick={() =>
                    !registrationCompleted &&
                    !registrationInProcess &&
                    !isDisabled &&
                    this.stepTo(index)
                  }
                >
                  <p className="wizard-step-number">
                    {!isActive && !isDisabled && (isError || isCompleted) ? (
                      <i className="material-icons">
                        {isError ? "close" : "check"}
                      </i>
                    ) : (
                      index + 1
                    )}
                  </p>
                  <p className="wizard-step-title">{s.title}</p>
                </div>
              );
            })}
          </div>
        </div>

        {initialized && (
          <div className="flex container mobile-block relative">
            {registrationCompleted ? (
              <RegistrationCompleted />
            ) : (
              <React.Fragment>
                <div
                  className={`kinus-page-form-container ${
                    wizardSteps[wizardIndex].title === "Payment"
                      ? "last-step"
                      : ""
                  }`}
                >
                  {!!wizardSteps[wizardIndex] &&
                    React.createElement(wizardSteps[wizardIndex].component, {
                      attendance: {
                        childrenAttending,
                        parentsAttending,
                      },
                      editProfile,
                      formProps: {
                        formFunctions: this.formFunctions,
                        onChange: this.onChangeForm,
                        onChangeEvent: this.onChangeFormEvent,
                        validation:
                          formValidation[wizardSteps[wizardIndex].name],
                        values: formValues,
                      },
                      profile,
                      settings,
                      setOnIndexWizard: this.setOnIndexWizard,
                      sys,
                      validationHeader: currentStepValidationErrors ? (
                        <span className="block error-message">
                          *Please review all required fields
                        </span>
                      ) : null,
                    })}

                  <div className="wizard-btns">
                    {wizardIndex > 0 && (
                      <button
                        className="btn btn btn-medium btn-light mt-32"
                        style={{
                          fontSize: "12px",
                          width: "96px",
                          float: "left",
                        }}
                        onClick={() => this.stepNav(false, childrenAttending)}
                      >
                        Prev
                      </button>
                    )}

                    {wizardIndex < wizardSteps.length - 1 && (
                      <React.Fragment>
                        <button
                          className="btn btn-accent btn-medium mt-32"
                          style={{
                            fontSize: "12px",
                            width: "96px",
                            float: "right",
                          }}
                          onClick={() => this.stepNav(true, childrenAttending)}
                          disabled={currentStepValidationErrors}
                        >
                          Next
                        </button>
                      </React.Fragment>
                    )}
                    <p
                      className="prev-last-step small-text link-text-secondary accent-text-dark"
                      onClick={() => this.stepNav(false, childrenAttending)}
                    >
                      Previous Step
                    </p>
                  </div>
                </div>

                <div>
                  <BillingSummary
                    attendance={{
                      childrenAttending,
                      parentsAttending,
                    }}
                    eventSettings={settings.eventSettings}
                    invoice={formValues.invoice}
                    billingInstallmentDates={
                      formValues.billing.installmentDates
                    }
                    onChange={this.onChangeForm}
                    showPayButton={wizardIndex === wizardSteps.length - 1}
                    completeRequiredBeforePayment={
                      submitAttempted && currentStepValidationErrors
                    } //allow 1x click to validate before disabling when validateBeforePayment is true
                    submitPayment={this.submitForm}
                    registration={registration}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default injectStripe(KinusForm);
