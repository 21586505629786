import React from "react";

export default function NoEngagementGoals() {
  return (
    <div className="flex flex-column flex-align-center mt-32 mb-32 full-height">
      <img
        src="/images/enrollmentComplete.svg"
        alt=""
        height="240"
        style={{ maxWidth: "100%" }}
      />
      <p className="fw-700 large-text mt-40 mb-40 line-height-double text-center">
        Enter your Student Engagement goals to get started
      </p>
    </div>
  );
}
