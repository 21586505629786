import React, { useEffect } from "react";
import { withAppInsights } from "../../lib";
import { RsvpActions, useAction } from "../../state";
import SmsInfoSidebar from "../rsvp/myRsvp/sms/SmsInfoSidebar";

function SMSInfoPage() {
  //TODO: calls getRsvpEnrollment to get the forChabadHouseId => really needs a better way of
  // getting the forChabadHouseId instead of making this call for this page and the messaging page
  // needs more looking into on BE and FE as per @risa.
  const getRsvpEnrollment = useAction(RsvpActions.getRsvpEnrollment);

  useEffect(() => {
    getRsvpEnrollment();
  }, [getRsvpEnrollment]);

  return <SmsInfoSidebar />;
}

export default withAppInsights(SMSInfoPage);
