import React from "react";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import PaginatedTable from "../../../../components/PaginatedTableDeprecated";
import StudentsListActions from "./StudentsListActions";
import StudentsListFilters from "./StudentsListFilters";
import StudentsListHeader from "./StudentsListHeader";
import StudentsListRow from "./StudentsListRow";
import {
  AuthRequest,
  convertToArray,
  formatFullName,
  Navigation,
  PageLink,
  PageURL,
  sum,
} from "../../../../lib";
import pages from "../../../../pages";
import queryString from "query-string";

export default class StudentsList extends React.PureComponent {
  state = {
    action: null,
    actionPayload: null,
    actionValidationMessage: "",
    errorMessage: "",
    detailsOpened: {},
    showActionConfirmationModal: false,
    showStudentInteractionModal: false,
  };

  //Students List / Filters

  getStudents = async (page, resultsView) => {
    const {
      pageRoute: {
        query: { p = 1, r = 10 },
      },
      schedule: {
        data: { id: grantScheduleId },
      },
    } = this.props;

    await this.props.actions.getLamplightersStudents(
      page || p,
      resultsView || r,
      grantScheduleId,
      this.getListFilters(),
    );
  };

  getStudentsForExport = async () => {
    const {
      schedule: {
        data: { id: grantScheduleId },
      },
    } = this.props;

    const {
      allStudents,
      grantId,
      grantStatus,
      includeArchived,
      search,
      sortByOption,
      studentAgeMax,
      studentAgeMin,
      studentClasses,
      studentCreatedWithinYears,
      studentId,
    } = this.getListFilters();

    const params = {
      grantId,
      grantScheduleId,
      grantStatus,
      includeArchived: !!includeArchived,
      isEnrolled: !allStudents,
      isExport: true,
      sortByOption,
      studentAgeMax,
      studentAgeMin,
      studentClasses,
      studentCreatedWithinYears,
      studentId: studentId,
      text: studentId ? undefined : search,
    };

    const response = await AuthRequest.get(
      `GrantScheduleStudents?${queryString.stringify(params)}`,
    );
    return response.data.payload && response.data.payload.results;
  };

  getListFilters = () => {
    const {
      pageRoute: {
        query: { as, g, gs, gcx, ia, se, st, samn, samx, sc, sy, so },
      },
    } = this.props;

    return {
      [this.studentsFilterDefinitions.g]: g,
      [this.studentsFilterDefinitions.gs]: gs,
      [this.studentsFilterDefinitions.gcx]: gcx,
      [this.studentsFilterDefinitions.as]: as,
      [this.studentsFilterDefinitions.ia]: ia,
      [this.studentsFilterDefinitions.se]: se && decodeURIComponent(se),
      [this.studentsFilterDefinitions.st]: st,
      [this.studentsFilterDefinitions.samn]: samn,
      [this.studentsFilterDefinitions.samx]: samx,
      [this.studentsFilterDefinitions.sc]: sc,
      [this.studentsFilterDefinitions.sy]: sy,
      [this.studentsFilterDefinitions.so]:
        so || this.filterSortOptions[0].value,
    };
  };

  clearActiveStudentsFilter = () => {
    const { page, params, query } = this.props.pageRoute;

    const url = PageURL.to(page, params, {
      ...query,
      as: true, //all students
      p: undefined, //reset page to 1
    });
    Navigation.redirect(url);

    setTimeout(this.getStudents);
  };

  studentsFilterDefinitions = {
    as: "allStudents", //include inactive
    g: "grantId",
    gcx: "grantColumnExclusions", // grants not to display
    gs: "grantStatus",
    ia: "includeArchived",
    p: "page",
    r: "resultsView",
    se: "search",
    so: "sortByOption",
    st: "studentId",
    samn: "studentAgeMin",
    samx: "studentAgeMax",
    sc: "studentClasses",
    sy: "studentCreatedWithinYears",
  };

  filterSortOptions = [
    { label: "Name", value: "StudentLastName" },
    { label: "Latest Interaction", value: "LatestInteractionDate" },
    { label: "Student Created Asc", value: "StudentDateCreatedAsc" },
    { label: "Student Created Desc", value: "StudentDateCreatedDesc" },
  ];

  getURLForPagination = (pg, resultsView) => {
    const {
      pageRoute: { query, page, params },
    } = this.props;
    return PageURL.to(page, params, {
      ...query,
      p: pg,
      r: resultsView || query.r,
    });
  };

  toggleMobileDetails = (id) => {
    this.setState({
      detailsOpened: {
        ...this.state.detailsOpened,
        [id]: !this.state.detailsOpened[id],
      },
    });
  };

  //Students Grant Actions
  studentGrantActions = {
    Activate: "Activate",
    Deactivate: "Deactivate",
    SetPrimary: "SetPrimary",
    Submit: "Submit",
    LogInteraction: "LogInteraction",
  };

  onStudentGrantAction = (action, actionPayload) => {
    //validate availability for student grant activation
    const { grant: { grantID, grantTypeDisplay } = "" } = actionPayload;
    const studentName =
      (actionPayload &&
        formatFullName(
          actionPayload.studentFirstName,
          actionPayload.studentLastName,
        )) ||
      "this student";
    if (action === this.studentGrantActions.Activate) {
      const {
        enrollment: { data: { allocations } = {} },
      } = this.props;
      const grantAllocation =
        (allocations && allocations.find((a) => a.grantID === grantID)) || "";
      if (!grantAllocation.availableQuantity) {
        this.setState({
          actionValidationMessage: `This student cannot be activated for this ${
            grantTypeDisplay || ""
          } grant because you have met your allocated quantity for this grant`,
        });
      }
    } else if (
      action === this.studentGrantActions.SetPrimary &&
      actionPayload.grant.isPrimary
    ) {
      this.setState({
        actionValidationMessage: `${
          grantTypeDisplay || ""
        } grant is already set as the primary grant for ${studentName}`,
      });
    }

    this.setState({
      action,
      actionPayload,
      errorMessage: "",
      [action === this.studentGrantActions.LogInteraction
        ? "showStudentInteractionModal"
        : "showActionConfirmationModal"]: true,
    });
  };

  getGrantActionConfirmationTitle = () => {
    const { action } = this.state;

    let actionName = this.studentGrantActions[action];
    if (action === this.studentGrantActions.SetPrimary) {
      actionName = "Set Primary";
    }

    return `${actionName} Student Grant Confirmation`;
  };

  getGrantActionConfirmationMessage = () => {
    const { action, actionPayload } = this.state;

    const actionGrantType =
      (actionPayload &&
        actionPayload.grant &&
        actionPayload.grant.grantTypeDisplay) ||
      "";

    const studentName =
      (actionPayload &&
        formatFullName(
          actionPayload.studentFirstName,
          actionPayload.studentLastName,
        )) ||
      "this student";

    if (action === this.studentGrantActions.SetPrimary) {
      return `Are you sure you want to set this ${actionGrantType} grant to the primary grant for ${studentName}?`;
    }

    const actionName = (this.studentGrantActions[action] || "").toLowerCase();

    const actionDescriptor =
      this.studentGrantActions[action] === this.studentGrantActions.Deactivate
        ? " from the"
        : this.studentGrantActions[action] === this.studentGrantActions.Activate
        ? " for the"
        : "'s";

    let actionDisclaimer = "";
    if (
      this.studentGrantActions[action] === this.studentGrantActions.Activate &&
      actionGrantType === "Learning"
    ) {
      actionDisclaimer = `\n\nPlease confirm that ${studentName}: \n • is not currently enrolled in Sinai Scholars \n • did not participate in Sinai Scholars in the past \n • does not have plans to participate in Sinai Scholars in the future`;
    } else if (
      this.studentGrantActions[action] === this.studentGrantActions.Submit &&
      !actionPayload.grant.cityOfOrigin
    ) {
      actionDisclaimer = (
        <span>
          <br />
          <br />
          Student’s city of origin is not set. Setting the city of origin prior
          to submission is strongly recommended.
          <br />
          Click{" "}
          <PageLink
            className="link-text"
            params={{ studentId: actionPayload.studentID }}
            query={{
              editDemographicsMode: true,
              goToOnSubmitProfile: Navigation.locationURL,
            }}
            to={pages.students.studentDetails}
          >
            here
          </PageLink>{" "}
          to view student profile and set city of origin.
        </span>
      );
    }

    return (
      <p>
        Are you sure you want to {actionName} {studentName}
        {actionDescriptor} {actionGrantType} grant?{actionDisclaimer}
      </p>
    );
  };

  onCloseStudentGrantAction = () => {
    this.setState({
      action: null,
      actionPayload: null,
      actionValidationMessage: "",
      errorMessage: "",
      showActionConfirmationModal: false,
      showStudentInteractionModal: false,
    });
  };

  submitStudentGrantAction = async () => {
    const {
      action,
      actionPayload: {
        grant: {
          grantID,
          isPrimary,
          isSubmitted,
          studentGrantID,
          studentID,
        } = "",
      },
    } = this.state;

    const studentGrant = {
      grantID,
      id: studentGrantID,
      isEnrolled: action !== this.studentGrantActions.Deactivate,
      isPrimary:
        action === this.studentGrantActions.SetPrimary ? true : isPrimary,
      isSubmitted:
        action === this.studentGrantActions.Submit ? true : isSubmitted,
      studentID,
    };

    await this.props.actions.submitLamplightersStudentGrant(studentGrant);

    const {
      refreshEnrollment,
      submitStudentGrant: { errorMessage, success },
    } = this.props;

    if (success) {
      this.getStudents(); //TODO: when reload list on mobile, does it lose scroll position?
      this.setState({
        action: null,
        actionPayload: null,
        showActionConfirmationModal: false,
      });

      // refresh enrollment details after grant action (for stats in dashboard and sidebar widget)
      refreshEnrollment();
    } else {
      this.setState({
        errorMessage:
          errorMessage || "Something went wrong.  Please try again.",
      });
    }
  };

  render() {
    const {
      enrollment: { data: { allocations } = {} },
      pageRoute,
      schedule: {
        data: {
          didScheduleEnd,
          didScheduleStart,
          grants: allGrants,
          id: scheduleId,
          programEndDate,
          programScheduleName,
          programStartDate,
        } = {},
      },
      students: { data: { results, numberOfRows } = {}, loading, success },
      submitStudentGrant: { loading: submittingStudentGrant },
    } = this.props;

    const {
      query: {
        as: allStudents = false,
        g: grantID,
        gcx: grantColumnExclusions,
        gs: grantStatus,
        p: page = 1,
        r: resultsView = 10,
        se: search,
        st: studentId,
        samn: studentAgeMin,
        samx: studentAgeMax,
        sc: studentClasses,
        sy: studentCreatedWithinYears,
      },
    } = pageRoute;

    const {
      action,
      actionPayload,
      actionValidationMessage,
      errorMessage,
      detailsOpened,
      showActionConfirmationModal,
      showStudentInteractionModal,
    } = this.state;

    const grantsForDisplay = allGrants.filter(
      (g) =>
        allocations.some((a) => a.grantID === g.id) &&
        !convertToArray(grantColumnExclusions).includes(g.id.toString()),
    );

    const isLamplightersActiveOnlyUnfilteredList =
      !allStudents &&
      !grantID &&
      !grantStatus &&
      !search &&
      !studentId &&
      !studentAgeMin &&
      !studentAgeMax &&
      !studentCreatedWithinYears &&
      !convertToArray(studentClasses).length;

    return (
      <div className="montserrat full-height">
        <div className="flex flex-align-center flex-justify-space mobile-block mb-24 edu-students-header">
          <h2 className="mb-0 archivo-black xxl-text">All Students</h2>
          <StudentsListActions
            didScheduleEnd={didScheduleEnd}
            getStudentsForExport={this.getStudentsForExport}
            hasStudents={!!(results && results.length)}
            interactionStudentId={
              action === this.studentGrantActions.LogInteraction &&
              actionPayload
                ? actionPayload.studentID
                : ""
            }
            programScheduleName={programScheduleName}
            refreshStudents={this.getStudents}
            showStudentInteractionModal={showStudentInteractionModal}
            toggleShowStudentInteractionModal={() => {
              !showStudentInteractionModal
                ? this.setState({ showStudentInteractionModal: true })
                : this.onCloseStudentGrantAction();
            }}
          />
        </div>
        <PaginatedTable
          className="lamplighters-students-table"
          fullHeight
          filterComponent={
            <StudentsListFilters
              getStudents={this.getStudents}
              allGrants={allGrants}
              grantsForDisplay={grantsForDisplay}
              pageRoute={pageRoute}
              scheduleId={scheduleId}
              sortOptions={this.filterSortOptions}
            />
          }
          getURL={this.getURLForPagination}
          loadData={this.getStudents}
          loading={loading}
          name="Lamplighters Students"
          noRecordsDisplay={
            isLamplightersActiveOnlyUnfilteredList && (
              <>
                <img src="/images/no_recent_profiles.svg" alt="" />
                <p className="mt-16 mb-8">No active lamplighter students</p>
                <p className="accent-text mb-16">
                  You have {sum(allocations.map((a) => a.quantity))} allocations
                  but no active lamplighter students
                </p>
                <button
                  className="btn btn-medium btn-accent"
                  onClick={this.clearActiveStudentsFilter}
                  style={{ fontSize: "13px", padding: "0px 16px" }}
                >
                  Show all students
                </button>
              </>
            )
          }
          page={page}
          records={results || []}
          renderHeaderRow={() => (
            <StudentsListHeader
              grantsForDisplay={grantsForDisplay}
              allGrants={allGrants}
              programEndDate={programEndDate}
              programStartDate={programStartDate}
            />
          )}
          renderRow={(student, index) => (
            <StudentsListRow
              grantsForDisplay={grantsForDisplay}
              detailsOpened={detailsOpened}
              didScheduleEnd={didScheduleEnd}
              didScheduleStart={didScheduleStart}
              key={index}
              onToggleStudentGrant={(grant, value) => {
                if (value) {
                  this.onStudentGrantAction(this.studentGrantActions.Activate, {
                    ...student,
                    grant,
                  });
                } else {
                  this.onStudentGrantAction(
                    this.studentGrantActions.Deactivate,
                    {
                      ...student,
                      grant,
                    },
                  );
                }
              }}
              onLogStudentInteraction={() =>
                this.onStudentGrantAction(
                  this.studentGrantActions.LogInteraction,
                  student,
                )
              }
              onSetPrimaryStudentGrant={(grant) =>
                this.onStudentGrantAction(this.studentGrantActions.SetPrimary, {
                  ...student,
                  grant,
                })
              }
              onSubmitStudentGrant={(grant) =>
                this.onStudentGrantAction(this.studentGrantActions.Submit, {
                  ...student,
                  grant,
                })
              }
              student={student}
              toggleMobileDetails={this.toggleMobileDetails}
            />
          )}
          resultsView={resultsView}
          success={success}
          totalCount={numberOfRows}
        />

        <ConfirmationModal
          cancel={this.onCloseStudentGrantAction}
          cancelText={actionValidationMessage && "OK"}
          confirm={this.submitStudentGrantAction}
          errorMessage={errorMessage}
          loading={submittingStudentGrant}
          message={
            actionValidationMessage || this.getGrantActionConfirmationMessage()
          }
          noConfirm={!!actionValidationMessage}
          show={showActionConfirmationModal}
          title={
            actionValidationMessage
              ? `Cannot ${this.studentGrantActions[action].replace(
                  /([a-z])([A-Z])/g,
                  "$1 $2",
                )} Student Grant`
              : this.getGrantActionConfirmationTitle()
          }
        />
      </div>
    );
  }
}
