import React from "react";
import RegistrationSeating from "./RegistrationSeating";
import RegistrationQuestions from "../../../../shared/manage/students/registerStudent/RegistrationQuestions";

export default function EventRegistrationSeatingAndQuestions(props) {
  const {
    onChangeRegistration,
    registration,
    scheduleDetails,
    scheduleDetails: { attendeesByTable = {} },
    scheduleDetailsErrorMessage,
    scheduleDetailsLoading,
    studentName,
    submitAttempted,
  } = props;

  return (
    <div className="mt-24 separatorTop">
      {scheduleDetailsLoading ? (
        <p className="mt-24 accent-text fw-700">Loading event settings...</p>
      ) : scheduleDetailsErrorMessage ? (
        <p className="mt-24 error-text fw-700">{scheduleDetailsErrorMessage}</p>
      ) : (
        <React.Fragment>
          {attendeesByTable && attendeesByTable.length > 0 && (
            <RegistrationSeating
              onChangeRegistration={onChangeRegistration}
              registration={registration}
              scheduleDetails={scheduleDetails}
              studentName={studentName}
            />
          )}
          <RegistrationQuestions
            onChangeRegistration={onChangeRegistration}
            registration={registration}
            submitAttempted={submitAttempted}
          />
        </React.Fragment>
      )}
    </div>
  );
}
