import React from "react";
import PaginatedTable from "../../../components/PaginatedTableDeprecated";
import TeamsDirectorySearch from "./TeamsDirectorySearch";
import TeamsDirectoryTableRow from "./TeamsDirectoryTableRow";
import { PageURL } from "../../../lib";

export default class TeamsDirectoryTable extends React.PureComponent {
  getNavigationURL = (page) => {
    const {
      pageRoute,
      pageRoute: {
        query: { q: searchTerm = "", r: resultsView = 15 },
      },
    } = this.props;
    return PageURL.to(pageRoute.page, null, {
      q: searchTerm,
      p: page,
      r: resultsView,
    });
  };

  render() {
    const {
      contacts: { total = 0, records = [], loading, success },
      getContacts,
      pageRoute,
      pageRoute: {
        query: { q: searchTerm = "", p: page = 1, r: resultsView = 15 },
      },
      ui: { isPhone },
    } = this.props;

    return (
      <PaginatedTable
        filterComponent={<TeamsDirectorySearch pageRoute={pageRoute} />}
        getURL={this.getNavigationURL}
        isPhone={isPhone}
        loadData={(p, r, _, i) =>
          getContacts(p, r, searchTerm ? decodeURIComponent(searchTerm) : "", i)
        }
        loading={loading}
        name="Contacts"
        page={page}
        pageRoute={pageRoute}
        records={records}
        renderRow={(contact, index) => (
          <TeamsDirectoryTableRow contact={contact} key={index} />
        )}
        resultsIncrement={15}
        resultsView={resultsView}
        success={success}
        totalCount={total}
      />
    );
  }
}
