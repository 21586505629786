import React from "react";
import Modal from "../../components/Modal";
import { useStyles } from "./resourceTab.styles";

export const ResourcePreviewModal = ({
  show,
  file,
  handleClose,
  trackFileView,
}) => {
  const classes = useStyles();
  const { name, mimeType, webViewLink, webContentLink, exportLinks } = file;

  const getFileExportType = () => {
    switch (mimeType) {
      case "application/vnd.google-apps.spreadsheet":
        return exportLinks[
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ];
      case "application/vnd.google-apps.document":
        return exportLinks[
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ];
      case "application/vnd.google-apps.presentation":
        return exportLinks["application/pdf"];
      default:
        return webContentLink;
    }
  };

  const getSrcUrl = () => {
    const replacementUrlSegment = webViewLink.includes("edit")
      ? "/edit"
      : "/view";
    const replacementValue = mimeType.includes("google-apps.form")
      ? "/disabledform"
      : "/preview";
    return webViewLink.replace(replacementUrlSegment, replacementValue);
  };

  const hideDownload = mimeType.includes("audio") || mimeType.includes("video");

  return (
    <Modal show={show}>
      <div className="modal-container full-page-mobile-modal-container">
        <div className={`${classes.previewModal} modal modal-card card`}>
          <div className={classes.previewHeader}>
            <p>{name}</p>
            <div className={classes.previewBtns}>
              {!hideDownload && (
                <a
                  download
                  href={getFileExportType()}
                  onClick={() => trackFileView(file, "download")}
                >
                  <i className="material-icons pointer link-text-secondary mr-8">
                    download
                  </i>
                </a>
              )}
              <span>
                <i
                  className="material-icons pointer link-text-secondary mr-8"
                  onClick={() => handleClose(false)}
                >
                  close
                </i>
              </span>
            </div>
          </div>
          <iframe
            src={getSrcUrl()}
            title={name}
            allowFullScreen
            className={classes.iframe}
            target="_parent"
            allow="autoplay"
            onLoad={() => trackFileView(file)}
          ></iframe>
        </div>
      </div>
    </Modal>
  );
};
