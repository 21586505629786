import { AppState } from "../types";

const emptyObject = {};
export const UpdateSelectors = {
  loading(state: AppState) {
    return state.update.loading;
  },
  details(state: AppState) {
    return state.update.details || emptyObject;
  },
};
