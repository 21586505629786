import React from "react";
import { formatNumber, PageLink } from "../../../../../../lib";
import moment from "moment";

export default ({
  pageRoute: { page, params },
  schedule: {
    courseID,
    courseScheduleID,
    courseScheduleName,
    firstClassDate,
    isCompleted,
    isCurrentlyOfferedByChabadHouse,
    isUpcomingByChabadHouse,
    lastClassDate,
    numOfStudents,
  },
}) => (
  <PageLink
    className="paginated-table-row students-table-row"
    to={page}
    params={{
      ...params,
      courseId: courseID,
      courseScheduleId: courseScheduleID,
      courseView: "manage",
    }}
  >
    <div className="link-text-secondary">{courseScheduleName}</div>
    <div>
      {firstClassDate
        ? `${moment(firstClassDate).format("M/D/YY")} - ${moment(
            lastClassDate,
          ).format("M/D/YY")}`
        : ""}
    </div>
    <div>{formatNumber(numOfStudents)}</div>
    <div>
      {isCurrentlyOfferedByChabadHouse
        ? "Active"
        : isUpcomingByChabadHouse
        ? "Upcoming"
        : isCompleted
        ? "Completed"
        : ""}
    </div>
  </PageLink>
);
