import { ContactsActions } from "./actions";

export const ContactsState = {
  name: "contacts",
  persist: false,
  defaults: {
    loading: false,
    page: 1,
    resultsView: 10,
  },
  handlers: {
    [ContactsActions.SET_CONTACTS_LOADING]: function setContactsLoading(
      state,
      action,
    ) {
      return {
        ...state,
        loading: !!action.payload,
        contactType: action.payload,
      };
    },
    [ContactsActions.UPDATE_CONTACTS]: function updateContacts(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    [ContactsActions.CLEAR_CONTACTS]: function clearContacts(state, action) {
      return ContactsState.defaults;
    },
  },
};
