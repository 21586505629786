import React from "react";
import Big from "big.js";
import PaginatedTable from "../../../components/PaginatedTableDeprecated";
import CheckIcon from "@material-ui/icons/Check";
import {
  formatCurrency,
  formatNumber,
  pluralizeText,
  PageURL,
} from "../../../lib";
import { RafflePaymentTypes } from "../constants";
import OrdersTableFilters from "./OrdersTableFilters";
import { EditOrderModal } from "./editOrder/EditOrderModal";

export default class OrdersTable extends React.PureComponent {
  state = {
    detailsOpened: {},
    showEditOrderModal: false,
    orderToEdit: null,
  };

  toggleDetails = (id) => {
    this.setState({
      detailsOpened: {
        ...this.state.detailsOpened,
        [id]: !this.state.detailsOpened[id],
      },
    });
  };

  openEditOrderModal = (order) => {
    this.setState({
      showEditOrderModal: true,
      orderToEdit: order,
    });
  };

  closeEditOrderModal = (reload) => {
    this.setState({
      showEditOrderModal: false,
      orderToEdit: null,
    });

    if (reload) {
      window.location.reload();
    }
  };

  getHeaderRow = () => {
    return (
      <div className="raffle-details-table-header raffle-details-order-table-row">
        <p>Donor Info</p>
        <p className="mobile-hidden">Donor Address</p>
        <p>Amount</p>
        <p className="mobile-hidden">Date</p>
        <p className="mobile-hidden">Campus / Team</p>
        <p className="mobile-hidden">Method / Source</p>
        <p className="mobile-hidden">Donor Thanked</p>
        <p className="mobile-hidden" />
      </div>
    );
  };

  formatPaymentAmount = (
    ticketPriceAmount,
    quantity,
    processingFeeDonorAmount,
    currencyCode,
    className,
  ) => (
    <>
      <p className={className}>
        {formatCurrency(Math.abs(ticketPriceAmount), currencyCode)}{" "}
        {quantity > 0 &&
          `(${formatNumber(quantity)} ${pluralizeText(
            "ticket",
            parseFloat(quantity),
          )})`}
      </p>
      {Math.abs(processingFeeDonorAmount) > 0 && (
        <p className={`${className ? className : "accent-text"}`}>
          + {formatCurrency(Math.abs(processingFeeDonorAmount), currencyCode)}{" "}
          CC Fee
        </p>
      )}
    </>
  );

  // TODO: store refund breakdown in database instead - see: https://bitbean.atlassian.net/browse/COC-2676
  calculateRefundBreakdown = (
    refundAmount,
    cociCcProcessingFeePercentage,
    singleTicketPrice,
  ) => {
    if (!singleTicketPrice) {
      // historical raffles pre-2019 have some bad records, and don't have breakdowns anyways
      return {
        refundTicketPriceAmount: 0,
        refundProcessingFeeDonorAmount: 0,
        refundQuantity: 0,
      };
    }

    const cociCcProcessingFeeAsDecimal = Big(cociCcProcessingFeePercentage).div(
      100,
    );
    const refundAmountBig = Big(refundAmount);
    const wasDonorPaidProcessingFeeRefunded = !refundAmountBig
      .mod(singleTicketPrice)
      .abs()
      .eq(0);

    const refundTicketPriceAmount = wasDonorPaidProcessingFeeRefunded
      ? refundAmountBig.div(cociCcProcessingFeeAsDecimal.plus(1))
      : refundAmountBig;
    const refundProcessingFeeDonorAmount = wasDonorPaidProcessingFeeRefunded
      ? refundAmountBig.minus(refundTicketPriceAmount)
      : 0;
    const refundQuantity = refundTicketPriceAmount.div(singleTicketPrice).abs();

    return {
      refundTicketPriceAmount,
      refundProcessingFeeDonorAmount,
      refundQuantity,
    };
  };

  getTableRow = (order, cociCcProcessingFeePercentage) => {
    let { detailsOpened } = this.state;

    const {
      refundTicketPriceAmount,
      refundProcessingFeeDonorAmount,
      refundQuantity,
    } = this.calculateRefundBreakdown(
      order.refundAmount,
      cociCcProcessingFeePercentage,
      order.singleTicketPrice,
    );
    return (
      <div key={order.purchaseID}>
        <div
          className={`raffle-details-table-row raffle-details-order-table-row ${
            detailsOpened[order.orderID] === true ? "open" : "closed"
          }`}
          key={order.purchaseID}
        >
          <div className="desktop-hidden tablet-hidden collapse-icon">
            <i
              className="material-icons accent-text-secondary medium-text pointer"
              onClick={() => this.toggleDetails(order.orderID)}
            >
              {detailsOpened[order.orderID] ? "remove" : "add"}
            </i>
            <p
              className="link-text"
              onClick={() => this.openEditOrderModal(order)}
            >
              <i className="material-icons accent-text-secondary medium-text pointer">
                edit
              </i>
            </p>
          </div>
          <div className="contents-mobile-grid">
            <div>
              <p>
                {order.donorFirstName} {order.donorLastName}
              </p>
              <p className="accent-text" style={{ wordBreak: "break-word" }}>
                {order.donorEmail}
              </p>
              <p className="accent-text">{order.donorPhone}</p>
              <p className="accent-text">
                {order.association !== "None" ? order.associationDisplay : ""}
              </p>
            </div>
            <div className="mobile-hidden">
              <p>
                {order.billingAddress1} {order.billingAddress2}
              </p>
              <p>
                {order.billingCity}
                {order.billingCity && order.billingState && ","}{" "}
                {order.billingState}
              </p>
              <p>
                {order.billingCountry} {order.billingZip}
              </p>
            </div>
            <div>
              {this.formatPaymentAmount(
                order.ticketPriceAmount,
                order.quantity,
                order.processingFeeDonorAmount,
                order.currencyCode,
              )}
              {!!order.refundAmount &&
                this.formatPaymentAmount(
                  refundTicketPriceAmount,
                  refundQuantity,
                  refundProcessingFeeDonorAmount,
                  order.currencyCode,
                  "refunded",
                )}
              {order.isPrivate && (
                <p className="accent-text fw-700 flex flex-align-center desktop-hidden tablet-hidden">
                  <i className="material-icons medium-text mr-4">
                    visibility_off
                  </i>{" "}
                  Private
                </p>
              )}
            </div>
          </div>
          <div className="order-details">
            <div className="desktop-hidden tablet-hidden flex">
              <p className="small-text fw-700 orders-mobile-label">
                Donor Address
              </p>
              <div>
                <p className="small-text">
                  {order.billingAddress1} {order.billingAddress2}
                </p>
                <p className="small-text">
                  {order.billingCity}
                  {order.billingCity && order.billingState && ","}{" "}
                  {order.billingState}
                </p>
                <p>
                  {order.billingCountry} {order.billingZip}
                </p>
              </div>
            </div>
            <div className="desktop-hidden tablet-hidden flex">
              <p className="small-text fw-700 orders-mobile-label">Qty</p>
              <p className="small-text">{order.quantity}</p>
            </div>
            <div className="flex">
              <p className="small-text fw-700 orders-mobile-label desktop-hidden tablet-hidden">
                Date
              </p>
              <p>
                {new Date(order.dateOrderedOn).toLocaleDateString("en-us", {
                  month: "numeric",
                  day: "numeric",
                })}
              </p>
            </div>
            <div className="mobile-flex">
              <p className="small-text fw-700 orders-mobile-label desktop-hidden tablet-hidden">
                Campus
              </p>
              <p>{order.sellerName}</p>
              <p className="accent-text mobile-hidden">{order.referrerName}</p>
            </div>
            <div className="desktop-hidden tablet-hidden flex">
              <p className="small-text fw-700 orders-mobile-label">Team</p>
              <p className="small-text">{order.referrerName}</p>
            </div>
            <div className="mobile-flex">
              <p className="small-text fw-700 orders-mobile-label desktop-hidden tablet-hidden">
                Method
              </p>
              <p>
                {order.paymentMethod === RafflePaymentTypes.Cash
                  ? order.cashType
                  : order.paymentMethod === RafflePaymentTypes.CreditCard
                  ? "CC"
                  : order.paymentMethod}
              </p>
              <p className="accent-text mobile-hidden">{order.source}</p>
              {order.isPrivate && (
                <p className="accent-text fw-700 flex flex-align-center mobile-hidden">
                  <i className="material-icons medium-text mr-4">
                    visibility_off
                  </i>{" "}
                  Private
                </p>
              )}
            </div>
            <div className="mobile-hidden custom-checkbox-container flex flex-align-center">
              {order.isDonorThanked && <CheckIcon />}
            </div>
            <div className="mobile-hidden flex flex-align-center">
              <p
                className="link-text"
                onClick={() => this.openEditOrderModal(order)}
              >
                <i className="material-icons raffle-edit-icon">edit</i>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getNavigationURL = (pageNumber, resultsView) => {
    const {
      pageRoute: { page, params, query },
    } = this.props;
    const url = PageURL.to(page, params, {
      ...query,
      p: pageNumber,
      r: resultsView,
    });
    return url;
  };

  render() {
    const {
      cociCcProcessingFeePercentage,
      getOrders,
      raffle,
      sys,
      ticketPrice,
      raffle: {
        orders: { results, numberOfRows, loading } = {},
        enrollmentDetails: { id },
        details: { id: raffleID },
        referrers,
        success,
      },
      pageRoute,
      pageRoute: {
        query: { p: page = 1, r: resultsView = 10 },
      },
      sys: { paymentTypes, cashTypes, raffleDonorAssociations },
    } = this.props;

    const { showEditOrderModal, orderToEdit } = this.state;

    return (
      <>
        <PaginatedTable
          className={"raffles-details-order-table raffle-details-table"}
          enrollmentID={id}
          eventID={raffleID}
          filterComponent={
            <OrdersTableFilters
              getOrders={getOrders}
              pageRoute={pageRoute}
              paymentTypes={paymentTypes}
              cashTypes={cashTypes}
              raffleDonorAssociations={raffleDonorAssociations}
              referrers={referrers}
            />
          }
          getURL={this.getNavigationURL}
          loadData={getOrders}
          loading={loading}
          name="Orders"
          page={page}
          pageRoute={pageRoute}
          records={results || []}
          renderHeaderRow={this.getHeaderRow}
          renderRow={(order) =>
            this.getTableRow(order, cociCcProcessingFeePercentage)
          }
          resultsIncrement={10}
          resultsView={resultsView}
          showResultsView={true}
          success={success}
          totalCount={numberOfRows}
        />
        {showEditOrderModal && (
          <EditOrderModal
            show={showEditOrderModal}
            closeEditOrderModal={this.closeEditOrderModal}
            order={orderToEdit}
            ticketPrice={ticketPrice}
            sys={sys}
            raffle={raffle}
          />
        )}
      </>
    );
  }
}
