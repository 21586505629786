import React from "react";
import { PageLink } from "../../../../../lib";

export default function NoRsvpItems(props) {
  const {
    rsvpType,
    textBlanks: { noItemsMessage, addItemsMessage },
    pageRoute: { page, params },
  } = props;

  return (
    <div className="text-center">
      <img src="/images/edu-courses.svg" alt="" />
      <p className="mt-32 mb-16 fw-700 large-text">{noItemsMessage}</p>
      <p
        className="line-height-double medium-text text-center"
        style={{ maxWidth: 376, margin: "0 auto 24px" }}
      >
        {addItemsMessage}
      </p>
      <PageLink
        className="btn btn-accent"
        to={page}
        params={{
          ...params,
          rsvpItemId: "new",
        }}
        style={{ margin: "auto", width: "200px" }}
      >
        Add new {rsvpType}
      </PageLink>
    </div>
  );
}
