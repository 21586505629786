import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { RaffleActions, RaffleSelectors, AuthSelectors } from "../../state";
import { Navigation } from "../../lib";
import Loader from "../../components/Loader";
import ProgramBreadcrumbsHeader from "../../components/ProgramBreadcrumbsHeader";
import Enrollment from "./enrollment/Enrollment";
import MyRaffle from "./MyRaffle";
import RaffleErrorPage from "./RaffleErrorPage";
import EnrollmentCompleted from "./enrollment/EnrollmentCompleted";
import RaffleSidebar from "./sidebar/RaffleSidebar";
import { useStyles } from "./Raffle.styles";

export default function Raffle(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  //LOCAL STATE
  const [loadingData, setLoadingData] = useState(false);
  const [tabContentShowing, setTabContentShowing] = useState();
  const [isNewEnrollmentSubmitted, setIsNewEnrollmentSubmitted] =
    useState(false);

  //SELECTORS
  const raffle = useSelector(RaffleSelectors.raffle);
  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);

  //ACTIONS
  const getHistoricEnrollments = RaffleActions.getHistoricEnrollments;
  const getCurrentRaffle = RaffleActions.getCurrentRaffle;
  const getRaffleEvent = RaffleActions.getRaffleEvent;
  const getRaffleEnrollment = RaffleActions.getRaffleEnrollment;

  const historicEnrollments = useRef();

  const {
    loading,
    enrollmentDetails,
    success,
    errorMessage,
    details: {
      programScheduleID,
      id: raffleEventId,
      isActive,
      loading: detailsLoading,
    } = {},
  } = raffle;

  const { actions, sys, shliachID, pageRoute } = props;
  const programScheduleID_url = pageRoute.params.programScheduleID;
  const defaultTab = isShliach ? "metrics" : "orders";

  useEffect(() => {
    // loads raffle event
    (async () => {
      setLoadingData(true);
      //get all raffles that shliach is enrolled in
      const _historicEnrollments = await dispatch(
        getHistoricEnrollments(shliachID),
      );
      if (programScheduleID_url) {
        //get raffle event from URL
        await dispatch(getRaffleEvent(programScheduleID_url));
      } else {
        //if there's no ID in the URL, get the currently active/latest raffle (unrelated to shliach's enrollment)
        const currentRaffle = await dispatch(getCurrentRaffle());
        if (currentRaffle) {
          Navigation.redirect(
            `${pageRoute.location.pathname}/${currentRaffle.details?.programScheduleID}?tab=${defaultTab}`,
          );
        } else {
          //Use the latest raffle from the shliach's enrollments if there's no current raffle (not likely unless there is bad data - getCurrentRaffle always returns the latest raffle)
          Navigation.redirect(
            `${pageRoute.location.pathname}/${_historicEnrollments[0]?.programScheduleID}?tab=${defaultTab}`,
          );
        }
      }
      historicEnrollments.current = _historicEnrollments;
      setLoadingData(false);
    })();
  }, [
    defaultTab,
    dispatch,
    getCurrentRaffle,
    getRaffleEvent,
    pageRoute.location.pathname,
    programScheduleID_url,
    getHistoricEnrollments,
    shliachID,
  ]);

  useEffect(() => {
    // get selected raffle enrollment once raffle event loads
    if (raffleEventId && !detailsLoading && !!historicEnrollments.current) {
      const selectedRaffle = historicEnrollments.current.find(
        (enrollment) =>
          enrollment.programScheduleID === parseInt(programScheduleID_url, 10),
      );

      dispatch(
        getRaffleEnrollment(
          shliachID,
          selectedRaffle?.sellerEnrollmentID || 0,
          raffleEventId,
        ),
      );
    }
  }, [
    detailsLoading,
    dispatch,
    getRaffleEnrollment,
    raffleEventId,
    shliachID,
    programScheduleID_url,
  ]);

  const setSubmitted = () => {
    setIsNewEnrollmentSubmitted(true);
  };
  const error = success === false || (!loading && !enrollmentDetails);

  const showMyRaffle =
    !error && enrollmentDetails?.id && !isNewEnrollmentSubmitted;

  const showEnrollment = !error && !isNewEnrollmentSubmitted && isShliach;

  const showEnrollmentCompleted = !error && isNewEnrollmentSubmitted;

  return (
    <div className="raffles-page page">
      {loading || detailsLoading || loadingData ? (
        <Loader />
      ) : (
        <Box className={classes.pageContainer}>
          <RaffleSidebar
            programScheduleID={programScheduleID}
            getHistoricRaffle={actions.getHistoricRaffle}
            pageRoute={pageRoute}
            raffle={raffle}
            showMenu={showMyRaffle}
            tabContentShowing={tabContentShowing}
            isShliach={isShliach}
          />

          <Box className={classes.contentContainer}>
            {isShliach && (
              <Box className={classes.breadcrumbsDesktop}>
                <ProgramBreadcrumbsHeader scheduleId={programScheduleID} />
              </Box>
            )}
            {showMyRaffle ? (
              <MyRaffle
                tabContentShowing={tabContentShowing}
                setTabContentShowing={setTabContentShowing}
                pageRoute={pageRoute}
                actions={actions}
                sys={sys}
                shliachID={shliachID}
                success={success}
                errorMessage={errorMessage}
              />
            ) : showEnrollment ? (
              <Enrollment isActive={isActive} setSubmitted={setSubmitted} />
            ) : showEnrollmentCompleted ? (
              <EnrollmentCompleted />
            ) : (
              <RaffleErrorPage errorMessage={errorMessage} />
            )}
          </Box>
        </Box>
      )}
    </div>
  );
}
