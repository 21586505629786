import React from "react";
import { Chip } from "@material-ui/core";
import { getJewishInterestLevelColors } from "./engagement/shared";
import { JewishInterestLevel } from "../../state/engagement/types";

type Props = {
  jewishInterestLevel: JewishInterestLevel;
  narrow?: boolean;
};

export default function JewishInterestLevelChip({
  jewishInterestLevel,
  narrow,
}: Props) {
  /*
   * There is a corner case where the student has an account but does not have any StudentDemographics.
   * In that situation, he does have a jewishInterestLevel in the DB but the BE is returning a blank demographics object,
   * so we're just hiding the chip.
   */
  if (!jewishInterestLevel) {
    return null;
  }

  return (
    <div>
      <Chip
        className="archivo-regular"
        label={jewishInterestLevel}
        style={{
          backgroundColor:
            getJewishInterestLevelColors(jewishInterestLevel).dark,
          ...(narrow && { height: "24px" }),
        }}
      />
    </div>
  );
}
