import { useEffect, useMemo, useRef } from "react";
import debounce from "lodash.debounce";

// this hook accommodates debouncing a function (callback) that relies on local state/props
// storing the function in a ref so that it's value can be mutated without breaking the reference for the debounce
// see https://www.developerway.com/posts/debouncing-in-react
export const useDebounce = (callback, wait = 500) => {
  const callbackRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      if (callbackRef.current) callbackRef.current();
    };

    return debounce(func, wait);
  }, [wait]);

  return debouncedCallback;
};
