import React from "react";
import Loader from "../../components/Loader";
import StripeProvider from "../../components/StripeProvider";
import ProgramBreadcrumbsHeader from "../../components/ProgramBreadcrumbsHeader";
import { withAppInsights } from "../../lib/AppInsights";

import LifeInsuranceInfoCard from "./LifeInsuranceInfoCard";
import MyLifeInsurance from "./MyLifeInsurance";
import EnrollmentCompleted from "./enrollmentStates/EnrollmentCompleted";
import NotEligibleForEnrollment from "./enrollmentStates/NotEligibleForEnrollment";

const { REACT_APP_STRIPE_API_KEY } = process.env;

class LifeInsurance extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showEnrollmentCompleted: false,
    };
  }

  componentDidMount() {
    this.getLifeInsurancePolicyAndEnrollment();
  }

  getLifeInsurancePolicyAndEnrollment = async () => {
    const {
      actions,
      auth,
      pageRoute: {
        params: { scheduleId },
      },
    } = this.props;

    this.setState({ loading: true });

    await actions.getLifeInsurancePolicy(scheduleId);

    const { data, success } = this.props.lifeInsurancePolicy;
    if (success && data) {
      const { id, enrollmentID } = data;

      await Promise.all([
        actions.getLifeInsuranceEnrollment(id, enrollmentID),
        actions.getAccountInfo(auth.shliachID),
      ]);
    }

    this.setState({ loading: false });
  };

  render() {
    const {
      account,
      actions,
      auth: { shliachID },
      lifeInsurancePolicy: {
        data: policy,
        error: policyError,
        errorMessage: policyErrorMessage,
      },
      lifeInsuranceEnrollment: {
        data: enrollment,
        error: enrollmentError,
        errorMessage: enrollmentErrorMessage,
      },
      submitEnrollment,
      sys,
    } = this.props;

    const { loading, showEnrollmentCompleted } = this.state;

    return (
      <div className="life-insurance-page page container">
        {policy && (
          <ProgramBreadcrumbsHeader scheduleId={policy.programScheduleID} />
        )}
        <>
          {loading ? (
            <Loader />
          ) : policyError || enrollmentError ? (
            <div className="text-center mt-32">
              <img src="/images/error.svg" alt="error-robot" height="320" />
              <p
                className="text-center error-message mt-24"
                style={{ position: "relative" }}
              >
                {policyErrorMessage ||
                  enrollmentErrorMessage ||
                  "Something went wrong and we could not retrieve Life Insurance information. Please try again."}
              </p>
            </div>
          ) : policy && enrollment ? (
            showEnrollmentCompleted ? (
              <EnrollmentCompleted
                policy={policy}
                onViewEnrollment={() =>
                  this.setState({ showEnrollmentCompleted: false })
                }
              />
            ) : !enrollment.isEligibleForEnrollment ? (
              <NotEligibleForEnrollment policy={policy} />
            ) : (
              <div className="flex flex-align-top tablet-block mobile-block">
                <LifeInsuranceInfoCard policy={policy} />

                <StripeProvider apiKey={REACT_APP_STRIPE_API_KEY}>
                  <MyLifeInsurance
                    account={account}
                    enrollment={enrollment}
                    policy={policy}
                    onEnrollmentCompleted={() =>
                      this.setState({ showEnrollmentCompleted: true })
                    }
                    shliachID={shliachID}
                    submitEnrollment={{
                      ...submitEnrollment,
                      action: actions.submitLifeInsuranceEnrollment,
                    }}
                    sys={sys}
                    uploadDocument={actions.uploadFile}
                  />
                </StripeProvider>
              </div>
            )
          ) : null}
        </>
      </div>
    );
  }
}

export default withAppInsights(LifeInsurance);
