import React, { useState, useCallback, useMemo } from "react";
import Autocomplete from "react-autocomplete";
import Loader from "./Loader";
import { AuthRequest, Navigation, PageURL } from "../lib";
import TableResultsView from "./TableResultsView";

/**
 * For use with functional components.
 * Note the table simply displays the data; the data is loaded external to the table.
 */
export default function PaginatedTable(props) {
  const {
    autocompleteEndpoint,
    className,
    filterComponent,
    filterComponentClassName,
    fullHeight,
    getURL,
    isMultilineFilter,
    isPhone,
    loaderComponent,
    loading,
    name,
    noRecordsDisplay,
    page,
    pageRoute,
    records,
    renderHeaderRow,
    renderRow,
    resultsIncrement,
    resultsView,
    searchTerm,
    showResultsTotal,
    showResultsView,
    success,
    totalCount,
  } = props;

  const [autocompleteItems, setAutocompleteItems] = useState([]);
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState(searchTerm || "");
  const [scrollPage, setScrollPage] = useState(1);

  const doAutocomplete = useCallback(
    async (value) => {
      if (!value) {
        return;
      }
      const text = encodeURIComponent(value);
      const {
        data: { payload: items = [] },
      } = await AuthRequest.get(`${autocompleteEndpoint}/${text}`);
      const mappedItems = items.map((value, index) => ({
        index,
        value,
      }));
      setAutocompleteItems(mappedItems);
    },
    [autocompleteEndpoint],
  );

  const getRows = () => records.map((item, index) => renderRow(item, index));

  const search = (searchQuery) => {
    searchQuery = searchQuery || searchInputValue;
    const url = PageURL.to(pageRoute.page, null, {
      q: searchQuery,
      r: resultsView,
    });
    Navigation.go(url);
  };

  const searchFocusOutline = () => setSearchFocused(!searchFocused);

  const paginate = useCallback(
    (page) => {
      if (loading) {
        return;
      }
      Navigation.redirect(getURL(page, resultsView));
    },
    [resultsView, loading, getURL],
  );

  const getPaginationFooter = useMemo(() => {
    let numberOfPages = Math.ceil((totalCount || 0) / (resultsView || 1));
    if (numberOfPages < 2) {
      return null;
    }
    let pagesArray = Array(numberOfPages)
      .fill()
      .map((_, i) => i + 1);
    let page = parseInt(props.page, 10);
    if (pagesArray.length > 10) {
      let pagesArrayCopy = pagesArray.slice(0, pagesArray.length);
      let startPg = pagesArrayCopy.shift();
      let endPg = pagesArrayCopy.pop();
      if (page < 7 && page !== 6) {
        pagesArray = pagesArray.slice(0, 6);
        pagesArray.push("afterPages", endPg);
      } else if (page === 6) {
        pagesArray = pagesArray.slice(page - 3, page + 2);
        pagesArray.unshift(startPg, "beforePages");
        pagesArray.push("afterPages", endPg);
      } else if (page + 4 < endPg) {
        pagesArray = pagesArray.slice(page - 3, page + 2);
        pagesArray.unshift(startPg, "beforePages");
        pagesArray.push("afterPages", endPg);
      } else {
        pagesArray = pagesArray.slice(pagesArray.length - 6, pagesArray.length);
        pagesArray.unshift(startPg, "beforePages");
      }
    } else {
      pagesArray = pagesArray.slice(0, 10);
    }

    return (
      <ul className={`pagination ${loading ? "disabled" : ""}`}>
        {page > 1 ? (
          <li
            key="prev"
            className="pagination-arrow"
            onClick={() => paginate(props.page - 1)}
          >
            <i className="material-icons">keyboard_arrow_left</i>
          </li>
        ) : null}

        {pagesArray.map((pageNumber) =>
          typeof pageNumber === "number" ? (
            <li
              key={pageNumber}
              className={
                props.page.toString() === pageNumber.toString() ? "active" : ""
              }
              onClick={() => paginate(pageNumber)}
            >
              {pageNumber}
            </li>
          ) : (
            <li key={pageNumber}>...</li>
          ),
        )}

        {page === numberOfPages ? null : (
          <li
            key="next"
            className="pagination-arrow"
            onClick={() => paginate(page + 1)}
          >
            <i className="material-icons">keyboard_arrow_right</i>
          </li>
        )}
      </ul>
    );
  }, [loading, paginate, props.page, resultsView, totalCount]);

  // TODO not yet updated for functional components - please remove if/when it's verified that this code is not needed!
  const onScroll = (event) => {
    const hasMore = page < Math.ceil((totalCount || 0) / resultsView);
    const someDimension =
      event.target.scrollHeight -
      event.target.clientHeight -
      event.target.scrollTop;
    if (!loading && hasMore && someDimension <= 100) {
      const newScrollPage = scrollPage + 1;
      // when update this logic for functional component, ensure the data is reloaded properly in the parent component
      // loadData(
      //   newScrollPage,
      //   15,
      //   searchTerm,
      //   true
      // );
      setScrollPage(newScrollPage);
    }
  };

  const renderSearchInput = (props) => {
    return (
      <div
        className={
          searchFocused ? "search-input search-input-focused" : "search-input"
        }
      >
        <input
          type="text"
          placeholder="Search anything (i.e: name, school, Jewish student life, etc...)"
          {...props}
        />
        <i
          className="material-icons accent-text-secondary pointer"
          onClick={search}
        >
          search
        </i>
      </div>
    );
  };

  const renderSearchAutocompleteItem = (item, isHighlighted) => {
    return (
      <div
        key={item.index}
        style={{
          background: isHighlighted ? "#f5f5f5" : "white",
          cursor: "pointer",
          padding: "8px 16px",
        }}
      >
        {item.value}
      </div>
    );
  };

  const renderSearchAutocompleteMenu = (items, value, style) => {
    if (items.length < 1) {
      return <div />;
    }
    return (
      <div
        style={{ ...style }}
        children={items}
        className="autocomplete-menu"
      />
    );
  };

  return (
    <div className={fullHeight ? "full-height" : ""}>
      {name === "Contacts" ? (
        <div className="table-header">
          {autocompleteEndpoint ? (
            <Autocomplete
              autoHighlight={false}
              getItemValue={(item) => item.value}
              items={autocompleteItems}
              value={searchInputValue}
              renderInput={renderSearchInput}
              renderMenu={renderSearchAutocompleteMenu}
              renderItem={renderSearchAutocompleteItem}
              inputProps={{
                onFocus: searchFocusOutline,
                onBlur: searchFocusOutline,
                onKeyDown: (event) => {
                  if (event.key === "Enter") search();
                },
              }}
              onChange={(event) => {
                const value = event.target.value;
                setSearchInputValue(value);
                doAutocomplete(value);
              }}
              onSelect={search}
            />
          ) : (
            filterComponent || ""
          )}
          {totalCount > 0 && (
            <div className="table-header-left">
              <p className="accent-text-dark">
                <span className="fw-700">{totalCount}</span> Results
              </p>
              {!isPhone && (
                <div className="flex flex-align-center ml-8">
                  <span className="accent-text-dark">|</span>{" "}
                  <TableResultsView increment={resultsIncrement} {...props} />
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div
          className={`flex flex-justify-space
            ${isMultilineFilter ? "flex-align-end" : "flex-align-center"}
            mb-24 
            ${filterComponentClassName || ""}
          `}
        >
          {filterComponent || <div />}

          {showResultsView ? (
            <TableResultsView increment={resultsIncrement} {...props} />
          ) : null}
        </div>
      )}
      {!loading && !records.length ? (
        <div className="empty-state">
          {success === false ? (
            <>
              <img src="/images/error.svg" alt="error robot" height="240" />
              <p className="xxl-text mt-24 mb-16">An error has occured</p>
              <p className="medium-text accent-text fw-500">
                Our server is being stubborn, please try again
              </p>
            </>
          ) : !records.length && searchTerm ? (
            <>
              <img src="/images/no_results.svg" alt="no results" height="200" />
              <p className="xxl-text mt-24 mb-16">
                No results found for '{searchTerm}'
              </p>
              <p className="medium-text accent-text fw-500">
                Try adjusting your search terms
              </p>
            </>
          ) : (
            noRecordsDisplay || (
              <>
                <img
                  src={
                    name === "Orders"
                      ? "/images/no_raffle_orders.svg"
                      : "/images/no_results.svg"
                  }
                  height="200"
                  alt="no results"
                />
                <p className="xxl-text mt-24 mb-16">No results found</p>
              </>
            )
          )}
        </div>
      ) : (
        <>
          <div
            className={`${isPhone ? "table full-width-table" : "table"} ${
              className ? className : ""
            }`}
          >
            {renderHeaderRow && renderHeaderRow()}
            {loading && (!isPhone || scrollPage === 1) ? (
              loaderComponent || (
                <div
                  className={name === "Contacts" ? "full-page-loader" : ""}
                  style={{ clear: "both" }}
                >
                  <Loader />
                </div>
              )
            ) : (
              <div
                className={isPhone ? "table-rows" : null}
                onScroll={isPhone ? onScroll : null}
              >
                {getRows()}
                {isPhone && loading && (
                  <div className="mobile-loader">
                    <Loader />
                  </div>
                )}
              </div>
            )}
          </div>
          {!isPhone && records.length > 0 && (
            <div className="flex flex-align-center flex-justify-space">
              {showResultsTotal && (
                <p className="accent-text-dark mt-24 mobile-hidden full-width">
                  <span className="fw-700">{totalCount}</span> Results
                </p>
              )}
              {getPaginationFooter}
            </div>
          )}
        </>
      )}
    </div>
  );
}
