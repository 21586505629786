import React, { memo } from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  bh: {
    fontSize: 12,
    fontFamily: "AvenirRoman",
    position: "absolute",
    top: 12,
    right: 32,
    [theme.breakpoints.down("md")]: {
      top: 8,
    },
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      top: 0,
      right: 0,
      marginLeft: 8,
    },
  },
}));

function Bh() {
  const classes = useStyles();
  return <Typography className={classes.bh}>ב״ה</Typography>;
}

export default memo(Bh);
