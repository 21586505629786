import React from "react";
import { useFieldArray, UseFieldArrayReturn } from "react-hook-form";

export type FormFieldArrayProps = {
  children: (fieldArray: UseFieldArrayReturn<any, any, any>) => any;
  name: string;
};

export const FormFieldArray = React.memo(
  /**
   *
   */
  function FormFieldArray({ children, name }: FormFieldArrayProps) {
    const fieldArray = useFieldArray({ name });

    return children(fieldArray);
  },
);
