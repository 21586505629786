import Directory from "./Directory.vm";
import DirectoryDetails from "./shluchim/DirectoryDetails.vm";

export const DirectoryPages = {
  directory: {
    path: "/directory",
    type: "PAGE_DIRECTORY",
    title: "Shluchim Directory",
    view: Directory,
    /** This key specifies which parts of the URL uniquely identify a page view.
     * Here, if the query parameters `p`, `q` or `r` change, the page component
     * will be reloaded (componentDidMount will be called.) If any other query
     * parameters change, the component will not reload.
     */
    key: { query: ["p", "q", "r"] },
  },
  teamsDirectory: {
    path: "/directory/teams",
    type: "PAGE_TEAMS_DIRECTORY",
    title: "Teams Directory",
    view: Directory,
    /** This key specifies which parts of the URL uniquely identify a page view.
     * Here, if the query parameters `p`, `q` or `r` change, the page component
     * will be reloaded (componentDidMount will be called.) If any other query
     * parameters change, the component will not reload.
     */
    key: { query: ["p", "q", "r"] },
  },
  directoryDetails: {
    path: "/directory/:contactId",
    type: "PAGE_DIRECTORY_DETAILS",
    title: "Shluchim Details",
    view: DirectoryDetails,
  },
};
export default DirectoryPages;

export const DirectoryArea = {
  pages: DirectoryPages,
};
