import { AppState } from "../types";

export const UiSelectors = {
  isPhone(state: AppState) {
    return state.ui.isPhone;
  },
  isTablet(state: AppState) {
    return state.ui.isTablet;
  },
};
