import React from "react";
import { Modal, Button } from "@material-ui/core";
import Loader from "./Loader";

export default class ConfirmationModal extends React.Component {
  render() {
    const {
      cancel,
      cancelText,
      className,
      confirm,
      confirmColor,
      confirmText,
      secondOptionText,
      onClickSecondOption,
      errorMessage,
      message,
      noConfirm,
      loading,
      show,
      title,
    } = this.props;

    return (
      <Modal open={show} className="modal-container">
        <div className={`modal-card card small-modal ${className || ""}`}>
          <p className="xl-text fw-900">{title || "Confirm"}</p>
          <div className="accent-text mt-16 line-height-double pre-wrap">
            {message}
          </div>
          <hr className="modal-separator" />
          {loading ? (
            <div className="btn-loader modal-btns relative">
              <Loader />
            </div>
          ) : (
            <div className="text-right">
              <div className="modal-btns-wrapper">
                <Button variant="contained" onClick={cancel}>
                  {cancelText || "Cancel"}
                </Button>
                {secondOptionText && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onClickSecondOption}
                  >
                    {secondOptionText}
                  </Button>
                )}
                {!noConfirm && (
                  <Button
                    style={{ backgroundColor: confirmColor }}
                    color="primary"
                    variant="contained"
                    onClick={confirm}
                  >
                    {confirmText || "Confirm"}
                  </Button>
                )}
              </div>

              {!!errorMessage && (
                <div className="mt-8 error-text">{errorMessage}</div>
              )}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
