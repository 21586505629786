import React from "react";

const resourcesStudentsHeaderNames = [
  { title: "Name" },
  { title: "Email" },
  { title: "Phone" },
  { title: "Details" },
  { title: "Date Requested" },
  { title: "Status" },
  { title: "Fee / Donation", checkShowPaidDonated: true },
];
export default (props) => (
  <div
    className={`paginated-table-header-row ${
      props.showPaidDonated
        ? "rsvp-students-table-row-extended"
        : "rsvp-students-table-row"
    }`}
  >
    <p /> {/* Person classification icon */}
    {resourcesStudentsHeaderNames
      .filter((h) => (h.checkShowPaidDonated ? props.showPaidDonated : true))
      .map(({ title }) => (
        <p className="fw-900">{title}</p>
      ))}
    <p /> {/* Delete icon */}
  </div>
);
