import React, { useCallback, useMemo, useState } from "react";
import {
  messagesView,
  getPhoneFormatted,
} from "../rsvp/myRsvp/RsvpMessageUtils";
import { Button, IconButton, Popover } from "@material-ui/core";
import { Schedule } from "@material-ui/icons";
import ConfirmationModal from "../../components/ConfirmationModal";
import FadeOutErrorMessage from "../../components/FadeOutErrorMessage";
import { isDateEqual, dateAddDays } from "../../lib";
import { deleteScheduledMessage } from "../../state/sms/SmsApi";
import { Navigation, PageURL } from "../../lib";
import { messageRecipientLimit } from "./smsUtils";

const THREE_MINUTES_MS = 180000;

type Message = {
  body: string | null;
  messageId: string;
  media: string[];
  direction: "OutboundApi" | "Inbound" | "OutboundReply";
  people: Person[];
  time: string;
  smsBlastId: number | null;
};

type Person = {
  name: string;
  phoneNumber: string;
  studentId: number;
  timeSent: string;
};

interface Props {
  message: Message;
  resetMailboxes: () => void;
  timezoneID: string | null;
  view: "inbox" | "sent" | "scheduled";
  index: number;
  activeMessageId: string | null;
  anonymousPhoneNumber: string | null;
}

const SmsMessageDetails = ({
  message,
  activeMessageId,
  resetMailboxes,
  timezoneID,
  view,
  index,
  anonymousPhoneNumber,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [messageDetailsOpen, setMessageDetailsOpen] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const { time: date, people, messageId, direction, smsBlastId } = message;
  const firstPerson = people[0];

  const handleDelete = useCallback(async () => {
    if (messageId) {
      const { success, errorMessage } = await deleteScheduledMessage(messageId);
      if (success) {
        resetMailboxes();
      } else {
        setErrorMsg(errorMessage);
      }
      setShowDeleteConfirmation(false);
    }
  }, [messageId, resetMailboxes, setErrorMsg]);

  const media = message.media?.map((image, ind) => (
    <img key={ind} src={image} style={{ width: "50%" }} alt="" />
  ));
  const body = message.body?.split("\n").map((l, i) => (
    <span key={i}>
      <br></br>
      {l}
    </span>
  ));

  const timeZone = timezoneID ? { timeZone: timezoneID } : {};
  const timeParams: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    ...timeZone,
  };
  const dateParams: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    ...timeZone,
  };
  const currentDate = new Date();
  const messageDate = new Date(date);
  const dateString = messageDate.toLocaleDateString("en-US", dateParams);
  const timeString = messageDate
    .toLocaleTimeString("en-US", timeParams)
    .replace(/^0/, "");

  var shouldShowDeleteButton =
    view === messagesView.scheduled &&
    messageDate.getTime() - currentDate.getTime() >= THREE_MINUTES_MS;

  const fromName =
    direction === "OutboundApi" ||
    direction === "OutboundReply" ||
    view === messagesView.scheduled
      ? "You"
      : firstPerson?.name;

  const toNames =
    view === messagesView.inbox
      ? "You"
      : people
          .slice(0, 2)
          .map((r) => r.name)
          .join(", ");

  const scheduledText = useMemo(() => {
    if (isDateEqual(currentDate, messageDate)) {
      return `Scheduled for ${timeString} today`;
    } else if (isDateEqual(dateAddDays(currentDate, 1), messageDate)) {
      return `Scheduled for ${timeString} tomorrow`;
    } else {
      return `Scheduled for ${dateString} ${timeString}`;
    }
  }, [currentDate, dateString, messageDate, timeString]);

  //anonymousPhoneNumber is currently only possible when there is only one recipient (who does not have an account) - we don't include non-accounts in sending blasts
  const phoneNumber = firstPerson?.phoneNumber
    ? getPhoneFormatted(firstPerson.phoneNumber)
    : anonymousPhoneNumber
    ? getPhoneFormatted(anonymousPhoneNumber)
    : null;

  const showPhoneNumberInAccentText =
    fromName && fromName !== "You" && people?.length === 1;

  const totalRecipients = people.length;

  return (
    <div
      className={`${index === 0 ? "sms-top-message" : "sms-message"} ${
        messageId === activeMessageId ? "active-message" : ""
      }`}
    >
      <div className="message-thread-title-line">
        <div>
          <span className="large-text">{fromName ?? phoneNumber}</span>
          {showPhoneNumberInAccentText && (
            <span className="fs-14 accent-text ml-8">{phoneNumber}</span>
          )}
        </div>
        <div className="accent-text">
          {smsBlastId && dateString}
          <span className="fw-700"> {timeString}</span>
        </div>
      </div>
      {smsBlastId && (
        <div className="accent-text message-to">
          To:{" "}
          {totalRecipients <= messageRecipientLimit
            ? `${toNames}${
                totalRecipients > 2 ? ` +${totalRecipients - 2}` : ""
              }`
            : `${messageRecipientLimit}+ recipients`}
          <IconButton
            size="small"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setMessageDetailsOpen(true);
            }}
          >
            <i
              className="material-icons accent-text"
              style={{ fontSize: "20px" }}
            >
              arrow_drop_down
            </i>
          </IconButton>
          <Popover
            open={messageDetailsOpen}
            anchorEl={anchorEl}
            onClose={() => {
              setMessageDetailsOpen(false);
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div className="sms-message-details-popup">
              <div className="flex">
                <div className="labels-container">
                  <div>To:</div>
                </div>
                <div className="accent-text content-container">
                  <div>
                    {totalRecipients > messageRecipientLimit && (
                      <p className="accent-text xs-text mb-8">
                        Showing {messageRecipientLimit} of many recipients
                      </p>
                    )}
                    {people.map((r, i) => {
                      return (
                        <div className="flex" key={i}>
                          <div
                            className="recipient-person-name link-text"
                            onClick={() =>
                              r.studentId &&
                              Navigation.go(
                                PageURL.to(
                                  `/engagement/students/${r.studentId}`,
                                ),
                              )
                            }
                          >
                            {r.name}
                          </div>
                          <div className="ml-4">
                            {r.phoneNumber && getPhoneFormatted(r.phoneNumber)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      )}
      <div className="mt-8 mr-16 ml-16">{media}</div>
      <div className="accent-text mr-16 ml-16 line-height-double">{body}</div>
      {view === messagesView.scheduled && (
        <div className="scheduled-active-message">
          <div className="scheduled-info-footer">
            <div className="scheduled-info">
              <Schedule className="scheduled-icon" />
              {scheduledText}
            </div>

            {shouldShowDeleteButton && (
              <Button
                variant="text"
                style={{ color: "#FF6B6B" }}
                onClick={() => setShowDeleteConfirmation(true)}
              >
                DELETE
              </Button>
            )}
          </div>
        </div>
      )}

      {errorMsg && (
        <FadeOutErrorMessage
          message={errorMsg}
          onTimeout={() => setErrorMsg(null)}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationModal
          cancel={() => setShowDeleteConfirmation(false)}
          confirm={handleDelete}
          message="Are you sure you want to delete this scheduled message?"
          show={true}
        />
      )}
    </div>
  );
};

export default SmsMessageDetails;
