import React from "react";
import {
  CartesianGrid,
  ResponsiveContainer,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import moment from "moment";

const ObservanceTooltip = ({ payload }) => {
  if (payload && payload.length) {
    const observanceAtDate = payload[0].payload;
    if (observanceAtDate) {
      return (
        <div
          style={{
            backgroundColor: "#666666",
            color: "#fff",
            fontSize: "11px",
            borderRadius: "4px",
            boxShadow: "0 0 16px -4px rgba(0,0,0,0.5)",
            padding: "4px 8px",
          }}
        >
          As of {observanceAtDate.dateTimeDisplay}:{" "}
          {observanceAtDate.statusDisplay}
        </div>
      );
    }
  }

  return "";
};

export default class StudentObservanceOverTimeChart extends React.PureComponent {
  formatTickY = (statusInt) => {
    const { statuses = [], statusDisplayFormatter } = this.props;
    const status = statuses.find((s) => s.intValue === statusInt);
    if (!status) {
      return "";
    }
    return statusDisplayFormatter
      ? statusDisplayFormatter(status)
      : status.displayValue;
  };

  render() {
    const { className, data, name, statuses } = this.props;

    const orderedData = data
      .sort((d1, d2) =>
        d1.isChildhood
          ? -1
          : new Date(d1.dateTime) > new Date(d2.dateTime)
          ? 1
          : 0,
      )
      .map((d) => {
        const status = statuses.find((s) => s.enumValue === d.value);
        return {
          ...d,
          dateTimeDisplay: d.isChildhood
            ? "Childhood"
            : moment(d.dateTime).format("MMM D, YYYY"),
          statusDisplay: status?.displayValue || "",
          statusValue: status?.intValue,
        };
      });

    return (
      <div className="graph-outline">
        <p className="medium-text fw-700 mb-24">{name}</p>
        <div
          className="flex flex-align-center flex-justify-center"
          style={{ height: "200px" }}
        >
          {!data.length ? (
            <div className="chart-no-results chart-no-results-small">
              <img src="/images/no_results.svg" alt="no results" height="120" />
              <p className="accent-text mt-8">
                No {name} tracked for this student
              </p>
            </div>
          ) : (
            <ResponsiveContainer height={200} width="100%">
              <LineChart className={className} data={orderedData}>
                <CartesianGrid />
                <Line dataKey="statusValue" type="monotone" stroke="#ff7300" />

                <Tooltip content={<ObservanceTooltip />} />

                <XAxis dataKey="dateTimeDisplay" tickLine={false} />

                <YAxis
                  domain={["dataMin", "dataMax"]}
                  tickFormatter={this.formatTickY}
                  tickLine={false}
                  tickMargin={5}
                  ticks={statuses && statuses.map((s) => s.intValue)}
                  type="number"
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    );
  }
}
