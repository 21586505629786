import { AccountActions } from "./actions";

export const AccountState = {
  name: "account",
  persist: false,
  defaults: {
    loading: false,
  },
  handlers: {
    [AccountActions.SET_ACCOUNT_LOADING]: function setAccountLoading(
      state,
      action,
    ) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [AccountActions.UPDATE_ACCOUNT]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
