import React from "react";
import NoRsvpItems from "../../shared/list/NoRsvpItems";

export default function NoResources(props) {
  const { pageRoute } = props;

  return (
    <NoRsvpItems
      rsvpType="resource"
      textBlanks={{
        noItemsMessage:
          "Hmmm, it seems like you don't have any resources set up yet",
        addItemsMessage:
          "Add your resources to the RSVP system to distribute your Chabad House's resources smoothly",
      }}
      pageRoute={pageRoute}
    />
  );
}
