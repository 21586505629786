import React from "react";
import { useSelector } from "react-redux";
import { Avatar, InputLabel, Tooltip, Typography } from "@material-ui/core";
import {
  FormDateField,
  FormFileUploadField,
  FormSelectField,
  FormTextField,
  FormToggleField,
} from "../../../../../components/react-hook-form";
import { ShliachInfoProps } from "./ShliachInfo";
import {
  FormUploadContainerStyled,
  ProfileFormGridItemStyled,
  ProfileFormGridStyled,
  ProfileFormInfoIcon,
  ProfileFormReadOnlyFieldStyled,
  ProfileFormGridFullRowItemStyled,
} from "../../ProfileForm.styles";
import { SystemSelectors } from "../../../../../state";
import { PROFILE_PLACEHOLDER_URL } from "../../../../../lib";

interface ShliachGeneralInfoProps extends ShliachInfoProps {
  shliachDisabled: boolean;
}

export const ShliachGeneralInfo = React.memo(
  /**
   *
   */
  function ShliachGeneralInfo(props: ShliachGeneralInfoProps) {
    const { personType, initialFormValues, shliachDisabled } = props;

    const titles = useSelector(SystemSelectors.titles);

    return (
      <ProfileFormGridStyled
        style={{ marginTop: "24px", marginBottom: "36px" }}
      >
        <ProfileFormGridFullRowItemStyled>
          {/* TODO: upload image to storage UPON SUBMISSION */}
          <FormFileUploadField
            image={true}
            name={`${personType}.profileImageURL`}
            uploadComponent={(fileOrUrl) => (
              <FormUploadContainerStyled>
                <Avatar
                  alt="profile"
                  src={
                    !fileOrUrl
                      ? PROFILE_PLACEHOLDER_URL
                      : typeof fileOrUrl === "string"
                      ? fileOrUrl
                      : fileOrUrl.previewUrl
                  }
                />
                <Typography variant="body2">
                  {fileOrUrl ? "Change" : "Upload"} profile image
                </Typography>
              </FormUploadContainerStyled>
            )}
          />
        </ProfileFormGridFullRowItemStyled>
        <ProfileFormGridItemStyled>
          <FormTextField
            disabled={shliachDisabled}
            label="First name"
            name={`${personType}.firstName`}
          />
        </ProfileFormGridItemStyled>
        <ProfileFormGridItemStyled>
          <FormTextField
            disabled={shliachDisabled}
            label="Last name"
            name={`${personType}.lastName`}
          />
        </ProfileFormGridItemStyled>
        <ProfileFormGridItemStyled>
          <FormTextField
            disabled={shliachDisabled}
            label="Name called by"
            name={`${personType}.nickname`}
          />
        </ProfileFormGridItemStyled>
        <ProfileFormGridItemStyled>
          <FormTextField
            disabled={shliachDisabled}
            label="Hebrew name"
            name={`${personType}.hebrewName`}
            placeholder="ex: Chana bas Rivkah"
          />
        </ProfileFormGridItemStyled>
        <ProfileFormGridItemStyled>
          <FormSelectField
            disabled={shliachDisabled}
            label="Title"
            name={`${personType}.title`}
            options={titles.map((t: any) => ({
              id: t.enumValue,
              name: t.displayValue,
            }))}
          />
        </ProfileFormGridItemStyled>
        <ProfileFormGridItemStyled>
          <InputLabel>Gender</InputLabel>
          <ProfileFormReadOnlyFieldStyled>
            {initialFormValues[personType]?.gender}
          </ProfileFormReadOnlyFieldStyled>
        </ProfileFormGridItemStyled>
        <ProfileFormGridItemStyled>
          <FormDateField
            disabled={shliachDisabled}
            name={`${personType}.dob`}
            label="Birthday"
          />
        </ProfileFormGridItemStyled>
        <ProfileFormGridItemStyled>
          <FormToggleField
            color="secondary"
            disabled={shliachDisabled}
            label="Nightfall/Sunset"
            name={`${personType}.beforeNightfall`}
            options={[
              {
                id: true,
                name: "Before",
              },
              {
                id: false,
                name: "After",
              },
            ]}
          />
        </ProfileFormGridItemStyled>
        <ProfileFormGridItemStyled>
          <InputLabel>
            Hebrew birthday
            <Tooltip title="Hebrew Birthday is calculated based on Birthday and Nightfall/Sunset. It will update on save.">
              <ProfileFormInfoIcon />
            </Tooltip>
          </InputLabel>
          <ProfileFormReadOnlyFieldStyled>
            {initialFormValues[personType]?.hebrewDOB}
          </ProfileFormReadOnlyFieldStyled>
        </ProfileFormGridItemStyled>
      </ProfileFormGridStyled>
    );
  },
);
