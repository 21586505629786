const newArray = [];

export const RaffleSelectors = {
  raffle(state) {
    return state.raffle;
  },
  teams(state) {
    return state.raffle.referrers;
  },
  donorSiteURL(state) {
    return state.raffle.enrollmentDetails.donorSiteURL;
  },
  chabadHouseDonateURL(state) {
    return state.raffle.enrollmentDetails.chabadHouseDonateURL;
  },
  chabadHouseID(state) {
    return state.raffle.enrollmentDetails.chabadHouseID;
  },
  donorSiteIframeText(state) {
    return state.raffle.enrollmentDetails.donorSiteIframeText;
  },
  teamSales(state) {
    return state.raffle?.metrics?.referrerSales || newArray;
  },
  raffleTitle(state) {
    return state.raffle.details.raffleTitle;
  },
};
