import React from "react";
import {
  rsvpMessageTypes,
  sendResourceSmsMessage,
} from "../../RsvpMessageUtils";
import RsvpItemCreatedModal from "../../shared/manage/RsvpItemCreatedModal";

export default function ResourceCreatedModal(props) {
  const {
    enrollment: { data: { chabadHouseRsvpURL } = {} },
    resource: { data: { resourceId } = {} },
    actions,
  } = props;

  return (
    <RsvpItemCreatedModal
      rsvpType="resource"
      directUrl={`${chabadHouseRsvpURL}/resources/${resourceId}`}
      sendSmsMessage={() =>
        sendResourceSmsMessage(
          resourceId,
          rsvpMessageTypes.newResource,
          actions,
        )
      }
      {...props}
    />
  );
}
