import React from "react";
import { Avatar, Typography } from "@material-ui/core";

interface Props {
  name: string;
  id: string | number;
  getColors: (value: any) => {
    dark: string;
    light: string;
    text: string;
  };
}

export default function ColoredLegendEntry({ getColors, id, name }: Props) {
  return (
    <Typography component="div" className="flex mr-8 flex-align-center mb-16">
      <Avatar
        variant="square"
        style={{
          backgroundColor: getColors(id)?.dark,
          color: getColors(id)?.dark,
          width: 16,
          height: 16,
          marginRight: 8,
        }}
      />
      {name}
    </Typography>
  );
}
