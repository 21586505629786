import React, { forwardRef, useCallback } from "react";
import { KeyboardDatePicker, DatePickerProps } from "@material-ui/pickers";
// TODO: Note known deprecation warnings: https://github.com/mui/material-ui-pickers/issues/2200
// the @material-ui/pickers lib is deprecated and should be upgraded to @mui/x-date-pickers per https://github.com/mui/material-ui-pickers/issues/2157
// however @mui/x requires mui v5 and react >= v17 so upgrading is not possible at this time
import { InputLabel } from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";

export type DateInputProps = {
  name: string;
  onChange: (name: string, val: any) => void;
  value: Date | string;
} & Omit<DatePickerProps, "onChange">;

export const DateInput = React.memo(
  forwardRef(
    /**
     *
     */
    function DateInput(
      {
        fullWidth = true,
        id,
        label,
        name,
        onChange,
        size = "small",
        value,
        ...passProps
      }: DateInputProps,
      ref: React.Ref<any> | null,
    ) {
      const handleChange = useCallback(
        (value) => onChange(name, value ? value.format("MM/DD/YYYY") : ""),
        [name, onChange],
      );

      return (
        <>
          {label && (
            <InputLabel error={passProps.error} htmlFor={id || name}>
              {label}
            </InputLabel>
          )}
          <KeyboardDatePicker
            autoOk={true} //close picker on select
            fullWidth={fullWidth}
            format="MM/DD/YYYY"
            id={id || name}
            inputVariant="outlined"
            inputRef={ref}
            onChange={(val) => handleChange(val)}
            size={size}
            value={value || null}
            variant="inline"
            InputProps={{
              endAdornment: (
                <CalendarToday style={{ fontSize: 20, color: "#524E4E" }} />
              ),
            }}
            {...passProps}
          />
        </>
      );
    },
  ),
);
