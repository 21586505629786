import React from "react";
import Radio from "../../../../components/form/Radio";
import Toggle from "../../../../components/form/Toggle";
import { getNameDisplay } from "../../../../lib/utils";

export default ({
  child,
  formProps: { onChange, onChangeEvent, validation },
}) => (
  <div className="kinus-form-section child">
    <p className="medium-text fw-700 mb-24">
      {getNameDisplay(child.person.firstName, child.person.lastName)}
    </p>
    <div className="flex flex-align-center mb-12 mobile-block">
      <p className="kinus-form-label">Is your child toilet-trained</p>
      <Toggle
        error={
          !child.childCare.toiletTraining &&
          validation[`child.${child.id}.toiletTraining`]
        }
        name={`childCare.children[${child.childCareIndex}].toiletTraining`}
        onChange={onChange}
        options={[
          { value: "ToiletTrained", display: "Yes" },
          { value: "NonTrained", display: "No" },
        ]}
        value={
          child.childCare.toiletTraining === "ToiletTrained"
            ? child.childCare.toiletTraining
            : child.childCare.toiletTraining
            ? "NonTrained"
            : null
        }
      />
    </div>
    <div className="mb-24">
      <p className="small-text accent-text mb-12">
        Counselors cannot change diapers. Please select one of the options
        below.
      </p>
      <Radio
        error={
          child.childCare.toiletTraining &&
          validation[`child.${child.id}.toiletTraining`]
        }
        disabled={child.childCare.toiletTraining === "ToiletTrained"}
        name={`childCare.children[${child.childCareIndex}].toiletTraining`}
        onChange={onChange}
        options={[
          {
            value: "NonToiletTrainedParentWillDiaper",
            display:
              "Keep my child in his/her age group. One of his/her parents will change the diaper when necessary.",
          },
          {
            value: "NonToiletTrainedJoinTwoYearOlds",
            display: "Place my child with the two-year-olds.",
          },
        ]}
        value={child.childCare.toiletTraining}
      />
    </div>
    <div className="mb-16">
      <p className="fw-700 mb-8">
        Please detail any medical conditions, medications and/or allergies
      </p>
      <textarea
        name={`childCare.children[${child.childCareIndex}].medicalNotes`}
        onChange={onChangeEvent}
        placeholder="Type notes here..."
        value={child.childCare.medicalNotes}
      />
    </div>
    <div>
      <p className="fw-700 mb-8">General Comments</p>
      <textarea
        name={`childCare.children[${child.childCareIndex}].generalComments`}
        onChange={onChangeEvent}
        placeholder="Type notes here..."
        value={child.childCare.generalComments}
      />
    </div>
  </div>
);
