import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { StudentsActions } from "../../../state";
import { SchoolsActions } from "../../../state/schools/actions";
import { SmsActions } from "../../../state/sms/actions";
import { ProgramsActions } from "../../../state/programs/actions";
import SelectRecipients from "./SelectRecipients";
import { RsvpActions } from "../../../state";

export default connect(
  function mapState(state) {
    return {
      sys: state.sys,
      shliachID: state.auth.shliachID,
      ...state.students,
      ...state.schools,
      ...state.sms,
      ...state.programs,
      ...state.rsvp,
    };
  },
  (dispatch) => {
    return {
      actions: bindActionCreators(
        {
          ...ProgramsActions,
          ...StudentsActions,
          ...SmsActions,
          ...SchoolsActions,
          ...RsvpActions,
        },
        dispatch,
      ),
    };
  },
)(SelectRecipients);
