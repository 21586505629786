import { AccountArea } from "./account";
import { UpdatesArea } from "./updates";
import { DirectoryArea } from "./directory";
import { EduArea } from "./edu";
import { ExampleArea } from "./example";
import { KinusArea } from "./kinus";
import { LamplightersArea } from "./lamplighters";
import { LifeInsuranceArea } from "./lifeInsurance";
import { MainArea } from "./main";
import { ProfileArea } from "./profile";
import { ProgramsArea } from "./programs";
import { RaffleArea } from "./raffle";
import { StudentsArea } from "./students";
import { SmsArea } from "./sms";
import { TripArea } from "./trips";
import { EngagementArea } from "./engagements";
import { DefaultLayout, EngagementLayout } from "../layouts";

export const AppPages = {
  account: AccountArea.pages,
  updates: UpdatesArea.pages,
  directory: DirectoryArea.pages,
  edu: EduArea.pages,
  example: ExampleArea.pages,
  kinus: KinusArea.pages,
  lamplighters: LamplightersArea.pages,
  lifeInsurance: LifeInsuranceArea.pages,
  main: MainArea.pages,
  profile: ProfileArea.pages,
  programs: ProgramsArea.pages,
  raffle: RaffleArea.pages,
  sms: SmsArea.pages,
  students: StudentsArea.pages,
  trips: TripArea.pages,
  engagement: EngagementArea.pages,
};
export default AppPages;

export const AppArea = {
  areas: [
    AccountArea,
    UpdatesArea,
    DirectoryArea,
    EduArea,
    ExampleArea,
    KinusArea,
    LamplightersArea,
    LifeInsuranceArea,
    MainArea,
    ProfileArea,
    ProgramsArea,
    RaffleArea,
    SmsArea,
    StudentsArea,
    TripArea,
    EngagementArea,
  ],
  layouts: {
    default: {
      path: "/",
      view: DefaultLayout,
    },
    engagement: {
      path: "/engagement",
      view: EngagementLayout,
    },
  },
};
