import React from "react";

export default class Confirm extends React.PureComponent {
  componentDidMount() {
    // Update waivers for if they already accepted waivers that are no longer relevant due to attendee/childcare changes
    // Not updating each time change is made to allow preservation if changes are un-done,
    // but want to update before waivers are shown without non-applicable waivers
    const {
      formProps: {
        formFunctions: { getWaiverUpdates },
        onChange,
        values: { waiver },
      },
    } = this.props;

    if (
      waiver.didAcceptNightBabysittingTerms ||
      waiver.didAcceptChildcareTerms ||
      waiver.didAcceptBringYourOwnBabysitter
    ) {
      onChange("waiver", getWaiverUpdates());
    }
  }

  render() {
    const {
      formProps: {
        formFunctions: {
          isGettingChildcare,
          isGettingNightBabysitting,
          isBringingOwnBabysitter,
        },
        onChangeEvent,
        values,
        validation,
      },
      settings: {
        eventSettings: {
          attendeeNoShowFee,
          gregorianYear,
          hebrewYear,
          registrationCancelationDate,
        },
      },
      validationHeader,
    } = this.props;

    const gettingChildcare = isGettingChildcare();
    const gettingNightBabysitting = isGettingNightBabysitting();
    const bringingOwnBabysitter = isBringingOwnBabysitter();

    return (
      <div className="kinus-page-form confirm">
        <p className="xxl-text fw-500">
          Confirm
          {validationHeader && validationHeader}
        </p>
        <div className="kinus-form-section">
          <p className="medium-text fw-700 mb-16">General Comments</p>
          <textarea
            name={`generalComments`}
            onChange={onChangeEvent}
            placeholder="Type notes here..."
            value={values.generalComments}
          />
        </div>
        <div className="kinus-form-section">
          <p className="medium-text fw-700 mb-16">
            General Terms and Conditions
          </p>
          <div className="terms mb-16">
            <p>
              If you are registering on behalf of another individual, it is your
              responsibility to ensure the person attending is aware of these
              terms and accepts them. By completing the registration on behalf
              of another individual you are warranting that you have made the
              attendee or participant aware of these terms and that they have
              accepted these terms.
            </p>
            <p>
              I/We, the undersigned, agree to release and hold harmless Chabad
              on Campus International (COCI), its employees, volunteers, and
              event attendees from any and all claims, demands, suits, cost, and
              charges in connection with or arising out of my attendance at the
              Chabad on Campus International Kinus (Kinus), including, but not
              limited to, bodily harm or injury, whether on or off premises,
              except only for loss, harms or injury occasioned by gross
              negligence or intentional misconduct by COCI Staff.
            </p>
            <p>
              I agree to permit COCI to use photos, video footage, and quotes
              taken within the program and to be used for funding, PR and
              marketing purposes.
            </p>
            <p>
              I understand that neither COCI nor its employees, volunteers, or
              event attendees are responsible for the safety or maintenance of
              the facility(ies) where the COCI Events are held.
            </p>
            <p>
              I also understand that COCI is not responsible for any lost items.
            </p>
            <p>
              The maximum aggregate liability of COCI for any claim in any way
              connected with, or arising from, the Kinus or this Agreement,
              whether in contract, tort, or otherwise (including any negligent
              act or omission), shall be limited to the amount paid by you to
              COCI under this Agreement.
            </p>
            <p>
              I also agree to Indemnify and hold harmless, COCI, its employees,
              volunteers, and event attendees from any and all claims, actions,
              suits, procedures, costs, expenses, damages and liabilities,
              including attorney’s fees brought as a result of my/our
              involvement in Kinus and to reimburse them for any such expenses
              incurred.
            </p>
            <p>
              In the event a court or arbitrator of competent jurisdiction finds
              the waiver of liability herein to be overly broad, the waiver
              shall be limited to the fullest amount allowable by such court or
              arbitrator.
            </p>
            <p>
              This agreement shall be governed by the substantive laws of the
              state of New York without regard to the conflicts of law provision
              therein. Any claims made by me/us in any way connected with, or
              arising from, the Kinus or this Agreement, whether in contract,
              tort, or otherwise (including any negligent act or omission),
              shall be arbitrated solely in front of a Zabla Beis Din. Such
              arbitration shall be held in Kings County, New York.
            </p>
            <p>
              I have read this Waiver & Release form in its entirety, fully
              understand its terms, and have agreed to its terms freely and
              voluntarily without any inducement, assurance, or guarantee being
              made to me. I intend for my COCI registration to be a complete and
              unconditional release of liability to the greatest extent allowed
              by law for all persons I have registered, including my minor
              child(ren).
            </p>
          </div>
          <div
            className={`flex custom-checkbox-container mb-24
                                ${
                                  validation.didAcceptGeneralTerms
                                    ? "error"
                                    : ""
                                }`}
          >
            <input
              type="checkbox"
              className="custom-checkbox"
              id="general-waiver"
              name="waiver.didAcceptGeneralTerms"
              checked={values.waiver.didAcceptGeneralTerms}
              onChange={onChangeEvent}
            />
            <label htmlFor="general-waiver">
              I have read, understand and agree with the terms and conditions
              above of Kinus attendence.
            </label>
          </div>
        </div>
        <div className="kinus-form-section">
          <p className="medium-text fw-700 mb-16">No Show Disclaimer</p>
          <div
            className={`flex custom-checkbox-container mb-24
                                ${
                                  validation.didAcceptNoShowDisclaimer
                                    ? "error"
                                    : ""
                                }`}
          >
            <input
              type="checkbox"
              className="custom-checkbox"
              id="no-show-waiver"
              name="waiver.didAcceptNoShowDisclaimer"
              checked={values.waiver.didAcceptNoShowDisclaimer}
              onChange={onChangeEvent}
            />
            <label htmlFor="no-show-waiver">
              I understand there are substantial costs involved for each
              attendee and will inform you before {registrationCancelationDate}{" "}
              if any attendee cancels. I understand that without prior
              cancellation a ${attendeeNoShowFee} no show fee per person will be
              charged to the card on file.
            </label>
          </div>
        </div>
        {gettingChildcare && (
          <div className="kinus-form-section">
            <p className="medium-text fw-700 mb-16">
              Childcare Terms and Conditions
            </p>
            <div className="terms mb-16">
              <p>
                I/We, the undersigned, are the parent(s) of the above named
                child/children and agree, in taking advantage of childcare
                services, to release and hold harmless Chabad on Campus
                International (COCI) its employees, volunteers, and event
                attendees from any and all claims, demands, suits, cost, and
                charges in connection with or arising out of the child care
                service, including, but not limited to, bodily harm or injury to
                our children, whether on or off premises, except only for loss,
                harms or injury occasioned by gross negligence or intentional
                misconduct by COCI.
              </p>
              <p>
                I hereby grant COCI and its employees full authority to take
                whatever actions they deem necessary regarding my child’s health
                and safety in the event I cannot be reached or in the situation
                where time is of the essence; and fully release COCI and its
                employees from any liability in connection with those decisions,
                I grant permission for emergency treatment by a rescue squad,
                private physician and/or hospital or emergency health care
                facility staff if needed.
              </p>
            </div>
            <div
              className={`flex custom-checkbox-container mb-24
                                ${
                                  validation.didAcceptChildcareTerms
                                    ? "error"
                                    : ""
                                }`}
            >
              <input
                type="checkbox"
                className="custom-checkbox"
                id="childcare-waiver"
                name="waiver.didAcceptChildcareTerms"
                checked={values.waiver.didAcceptChildcareTerms}
                onChange={onChangeEvent}
              />
              <label htmlFor="childcare-waiver">
                I have read, understand and agree with the terms and conditions
                above of Kinus Childcare.
              </label>
            </div>
          </div>
        )}
        {gettingNightBabysitting && (
          <div className="kinus-form-section relative">
            <p className="medium-text fw-700 mb-16">
              Night Babysitting Terms and Conditions
            </p>
            <div className="terms mb-16">
              <p>
                Kinus Night Babysitting is an additional service offered by
                Chabad on Campus International (COCI) specifically to those
                families who voluntarily request it below. This service consists
                of an assigned babysitter at the ratio of one babysitter per two
                families, supervised by an adult stationed on each floor during
                Babysitting hours. The babysitters arrive at the beginning of
                the Babysitting period and depart immediately at the end of the
                Babysitting period, at which point you are fully responsible for
                your children.
              </p>
              <p>
                In taking advantage of this service, I understand that there are
                risks inherent to leaving children with a babysitter and I agree
                to release and hold harmless COCI, its employees, volunteers,
                and event attendees from any and all claims, demands, suits,
                cost, and charges in connection with or arising out of night
                babysitting, including, but not limited to, bodily harm or
                injury to our children, except only for loss, harms or injury
                occasioned by gross negligence or intentional misconduct by
                COCI.
              </p>
            </div>
            <div
              className={`flex custom-checkbox-container mb-24
                                ${
                                  validation.didAcceptNightBabysittingTerms
                                    ? "error"
                                    : ""
                                }`}
            >
              <input
                type="checkbox"
                className="custom-checkbox"
                id="night-babysitting-waiver"
                name="waiver.didAcceptNightBabysittingTerms"
                checked={values.waiver.didAcceptNightBabysittingTerms}
                onChange={onChangeEvent}
              />
              <label htmlFor="night-babysitting-waiver">
                I have read, understand the Night Babysitting waiver. By
                checking this box I agree to all of the above.
              </label>
            </div>
            {validation.didAcceptNightBabysittingTerms && (
              <p className="error-message" style={{ marginTop: "-16px" }}>
                {validation.didAcceptNightBabysittingTerms}
              </p>
            )}
          </div>
        )}
        {bringingOwnBabysitter && (
          <div className="kinus-form-section relative">
            <p className="medium-text fw-700 mb-16">
              Bringing Your Own Babysitter Waiver
            </p>
            <div
              className={`flex custom-checkbox-container mb-24
                                ${
                                  validation.didAcceptBringYourOwnBabysitter
                                    ? "error"
                                    : ""
                                }`}
            >
              <input
                type="checkbox"
                className="custom-checkbox"
                id="bring-babysitter-waiver"
                name="waiver.didAcceptBringYourOwnBabysitter"
                checked={values.waiver.didAcceptBringYourOwnBabysitter}
                onChange={onChangeEvent}
              />
              <label htmlFor="bring-babysitter-waiver">
                I understand that I am responsible for rooming and payment of my
                babysitter and I will be responsible for childcare, including
                night babysitting. Chabad on Campus will provide catering for
                babysitter. I understand that babysitter must behave and dress
                as appropriate for a Kinus of Shluchim and Shluchos.
              </label>
            </div>
            {validation.didAcceptBringYourOwnBabysitter && (
              <p className="error-message">
                {validation.didAcceptBringYourOwnBabysitter}
              </p>
            )}
          </div>
        )}

        <div className="kinus-form-section relative">
          <div
            className={`flex custom-checkbox-container mb-24
              ${validation.didConfirmAttendeesImmunizations ? "error" : ""}`}
          >
            <input
              type="checkbox"
              className="custom-checkbox"
              id="attendees-immunizations-waiver"
              name="waiver.didConfirmAttendeesImmunizations"
              checked={values.waiver.didConfirmAttendeesImmunizations}
              onChange={onChangeEvent}
            />
            <label className="fw-700" htmlFor="attendees-immunizations-waiver">
              I confirm that I and all members of my family who are attending
              the Chabad on Campus Kinus this summer {hebrewYear} /{" "}
              {gregorianYear} - including adults and children - are up to date
              with our vaccinations including MMR and boosters.
            </label>
          </div>
        </div>
      </div>
    );
  }
}
