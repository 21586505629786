import React from "react";
import ExportCSVButton from "../../../../components/ExportCSVButton";
import StudentInteractionModal from "../../../students/activity/StudentInteractionModal.vm";
import { flattenArray } from "../../../../lib";
import moment from "moment";

export default class StudentsListActions extends React.PureComponent {
  getStudentGrants = async () => {
    const students = await this.props.getStudentsForExport();

    var studentGrants = students.map((student) =>
      student.grants.map((studentGrant) => ({
        studentID: student.studentID,
        studentFirstName: student.studentFirstName,
        studentLastName: student.studentLastName,
        studentEmail: student.studentEmail,
        studentCell: student.studentCell,
        studentCity: student.studentCity,
        studentState: student.studentState,
        studentCountry: student.studentCountry,
        studentGender: student.studentGender,
        graduationYear: student.graduationYear,
        campusID: student.campusID,
        campusName: student.campusName,
        chabadHouseID: student.chabadHouseID,
        chabadHouseName: student.chabadHouseName,
        shliachID: student.shliachID,
        shliachFirstName: student.shliachFirstName,
        shliachLastName: student.shliachLastName,
        grantType: studentGrant.grantTypeDisplay,
        submittedDate: studentGrant.submittedDate
          ? moment(studentGrant.submittedDate).format("MM/DD/YYYY")
          : "",
        studentAgeAtSubmission: studentGrant.studentAgeAtSubmission,
        status: studentGrant.isSubmitted ? "Submitted" : "Activated",
        isFlagged:
          studentGrant.isIneligibleFlagged ||
          studentGrant.isMissingDataFlagged ||
          studentGrant.isStudentPotentialDuplicateFlagged
            ? "Flagged"
            : "",
        submittedEngagementInteractionsCount:
          studentGrant.submittedEngagementInteractionsCount,
        submittedLearningInteractionsCount:
          studentGrant.submittedLearningInteractionsCount,
        submittedShabbosMealWithMaxCount:
          studentGrant.submittedShabbosMealWithMaxCount,
        submittedIsraelRelatedWithMinCount:
          studentGrant.submittedIsraelRelatedWithMinCount,
      })),
    );

    return flattenArray(studentGrants);
  };

  render() {
    const {
      didScheduleEnd,
      hasStudents,
      interactionStudentId,
      programScheduleName,
      refreshStudents,
      showStudentInteractionModal,
      toggleShowStudentInteractionModal,
    } = this.props;

    return (
      <div className="flex flex-align-center mobile-mt-8">
        <ExportCSVButton
          className="btn btn-accent students-export-btn"
          disabled={!hasStudents}
          fileName={`${programScheduleName || "Lamplighters"}-Students`}
          getExportData={this.getStudentGrants}
          getHeaders={() => [
            { label: "COCI Student ID", value: "studentID" },
            { label: "Student First Name", value: "studentFirstName" },
            { label: "Student Last Name", value: "studentLastName" },
            { label: "Student Email", value: "studentEmail" },
            { label: "Student Phone", value: "studentCell" },
            { label: "Student Gender", value: "studentGender" },
            {
              label: "Student Age at Submission",
              value: "studentAgeAtSubmission",
            },
            { label: "Student City", value: "studentCity" },
            { label: "Student State", value: "studentState" },
            { label: "Student Country", value: "studentCountry" },
            { label: "Graduation Year", value: "graduationYear" },
            { label: "Campus ID", value: "campusID" },
            { label: "Campus Name", value: "campusName" },
            { label: "Chabad House ID", value: "chabadHouseID" },
            { label: "Chabad House Name", value: "chabadHouseName" },
            { label: "Shliach ID", value: "shliachID" },
            { label: "Shliach First Name", value: "shliachFirstName" },
            { label: "Shliach Last Name", value: "shliachLastName" },
            { label: "Grant Type", value: "grantType" },
            { label: "Grant Status", value: "status" },
            { label: "Flagged Grant", value: "isFlagged" },
            { label: "Grant Submitted Date", value: "submittedDate" },
            {
              label: "Learning Count (Submitted Interactions)",
              value: "submittedLearningInteractionsCount",
            },
            {
              label: "Engagement Count (Submitted Interactions)",
              value: "submittedEngagementInteractionsCount",
            },
            {
              label:
                "Shabbos Meal Count (Submitted Interactions with Shabbos Meal max)",
              value: "submittedShabbosMealWithMaxCount",
            },
            {
              label:
                "Israel/Antisemitism Related Count (Submitted Interactions with Israel/Antisemitism Related min)",
              value: "submittedIsraelRelatedWithMinCount",
            },
          ]}
        />
        {!didScheduleEnd && (
          <button
            className="btn btn-accent flex flex-align-center ml-16"
            onClick={toggleShowStudentInteractionModal}
            style={{ padding: "0 24px" }}
          >
            Log Interaction
          </button>
        )}
        <StudentInteractionModal
          close={toggleShowStudentInteractionModal}
          onSubmitInteraction={() => refreshStudents()}
          show={showStudentInteractionModal}
          studentId={interactionStudentId}
        />
      </div>
    );
  }
}
