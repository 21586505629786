import React, { useRef, useEffect, useCallback, useMemo } from "react";
import Select from "react-select";
import debounce from "lodash.debounce";
import { Navigation, PageURL, toTitleCase } from "../../../../../lib";
import {
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  Tabs,
  Tab,
} from "@material-ui/core";

export default function RsvpListFilters(props) {
  const {
    rsvpType,
    chabadHouseName,
    hasItems,
    pageRoute,
    soOptions,
    ocOptions,
    actionsComponent,
    getRsvpItems,
  } = props;

  const { query, page, params } = pageRoute;

  const {
    se = "",
    so = soOptions[0].value,
    oc = ocOptions && ocOptions.length > 0 ? ocOptions[0].value : null,
  } = query;

  const getLatestItems = useRef();

  useEffect(() => {
    getLatestItems.current = getRsvpItems;
  }, [getRsvpItems]);

  const onFilter = useCallback(() => {
    getLatestItems.current();
  }, []);

  const onFilterDebounce = useMemo(() => {
    return debounce(onFilter, 500);
  }, [onFilter]);

  const onChange = (name, val, debounce) => {
    const url = PageURL.to(page, params, {
      ...query,
      [name]: val ? (name !== "ss" ? encodeURIComponent(val) : val) : undefined,
      p: undefined, //reset page to 1
    });
    Navigation.redirect(url);
    setTimeout(debounce ? onFilterDebounce : onFilter, 0);
  };

  const onCheckChange = (event) => {
    if (event.target.checked) {
      onChange("ss", ["Active", "Paused"]);
    } else {
      onChange("ss", ["Active"]);
    }
  };

  const disableIncludePausedFilter = oc !== "completed";

  return (
    <>
      <Box className="flex flex-justify-space mobile-flex-column">
        <Typography variant="h4">
          {toTitleCase(rsvpType)}s at {chabadHouseName || "my Chabad House"}
        </Typography>
        <div className="flex flex-justify-end">{actionsComponent}</div>
      </Box>

      {ocOptions && ocOptions.length > 0 && hasItems && (
        <Tabs
          className="mt-16"
          indicatorColor="primary"
          textColor="primary"
          value={decodeURIComponent(oc)}
          onChange={(_, value) => onChange("oc", value)}
        >
          {ocOptions.map((o) => (
            <Tab label={o.label} value={o.value} key={o.value} />
          ))}
        </Tabs>
      )}
      <Box className="flex flex-justify-space mt-24 mobile-flex-column mobile-flex-align-top">
        <div className="search-input">
          <input
            name="se"
            onChange={(event) =>
              onChange(event.target.name, event.target.value, true)
            }
            placeholder={`Search ${toTitleCase(rsvpType)} Name`}
            type="text"
            value={decodeURIComponent(se)}
          />
          <i className="material-icons accent-text-secondary pointer">search</i>
        </div>

        <Box className="flex flex-justify-end">
          {rsvpType === "event" && (
            <div className="tooltip-container">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      Array.isArray(pageRoute.query.ss) &&
                      !!pageRoute.query.ss?.find((s) => s === "Paused")
                    }
                    onChange={onCheckChange}
                    color="primary"
                  />
                }
                disabled={disableIncludePausedFilter}
                label="Include Paused Events"
              />
              {disableIncludePausedFilter && (
                <span className="tooltip">
                  Manage your paused events in the Completed tab
                </span>
              )}
            </div>
          )}
          <div
            className="flex flex-align-center ml-16"
            style={{ width: "200px" }}
          >
            <p className="mr-8 nowrap-text">Sort by</p>
            <Select
              className="program-filter-select"
              classNamePrefix="program-filter-select"
              isSearchable={false}
              onChange={(val) => onChange("so", val.value)}
              options={soOptions}
              value={
                soOptions.find((s) => s.value === decodeURIComponent(so)) ||
                soOptions[0]
              }
            />
          </div>
        </Box>
      </Box>
    </>
  );
}
