import { Country } from "../types";
import parsePhoneNumber, { CountryCode } from "libphonenumber-js";
import * as yup from "yup";

const usCountryCode = "us";

let countries: Country[] = [];

export function getSupportedCountryCodes(systemCountries: Country[]) {
  countries = systemCountries;

  return countries.map((c) => c.code.toLowerCase());
}

export function getDefaultCountryCode(
  systemCountries: Country[],
  countryId?: number | null,
  defaultCountryName?: string | null,
) {
  countries = systemCountries;

  const countryCode = countryId
    ? getCountryCodeById(countryId)
    : defaultCountryName
    ? getCountryCodeByName(defaultCountryName)
    : null;
  return countryCode || usCountryCode;
}

export function getCountryIdByCode(countryCode: string) {
  const country = countries.find(
    (c) => c.code.toLowerCase() === countryCode.toLowerCase(),
  );
  return country?.id || null;
}

export function getCountryCodeById(countryId: number) {
  const country = countries.find((c) => c.id === countryId);
  return country?.code.toLowerCase() || null;
}

export function getCountryCodeByName(countryName: string) {
  if (countryName === "Scotland") countryName = "United Kingdom"; //note: no country support for scotland, use UK instead
  const country = countries.find((c) => c.name === countryName);
  return country?.code.toLowerCase() || usCountryCode;
}

// #region validation

export const isPhoneValid = (phone: string, countryCode: string) => {
  try {
    const parsedPhoneNumber = parsePhoneNumber(
      phone,
      countryCode.toUpperCase() as CountryCode,
    );
    return parsedPhoneNumber?.isValid();
  } catch (error) {
    return false;
  }
};

export const getValidatedPhoneSchema = (schema: yup.Schema) =>
  schema.test("valid-phone", "Enter a valid phone", function (value) {
    if (!value) return true;

    const countryIdKey = Object.keys(this.parent).find((k) =>
      k.endsWith("CountryID"),
    );
    if (!countryIdKey) return true;

    const countryCode = getCountryCodeById(this.parent[countryIdKey]);
    if (!countryCode) return true;

    return isPhoneValid(value, countryCode);
  });

// #endregion
