import React from "react";
import ErrorDisplay from "../../../components/ErrorDisplay";
import Modal from "../../../components/Modal";
import ProgramRegistrationModal from "./ProgramRegistrationModal";
import { AuthRequest, Navigation, PageURL } from "../../../lib";

export default class ProgramRegistrationButton extends React.PureComponent {
  state = {
    hideButton: false, // hide button after registration where multiple registrations is not allowed
    showRegistrationModal: false,
    showRegistrationCompletedModal: false,
    trackRegistrationLoading: false,
    trackRegistrationError: false,
  };

  componentDidMount() {
    if (this.shouldTrackRegistration()) {
      this.trackRegistration();
    }
    //toggle registration modal open if register query param is set
    else if (this.props.pageRoute.query.register) {
      this.toggleRegistrationModal();
    }
  }

  // when correct post-registration query params are present, this indicates a post registration form submission redirect and we need to track registration
  shouldTrackRegistration = () => {
    const {
      query: { registered, sid },
    } = this.props.pageRoute;
    return registered === "true" && sid;
  };

  trackRegistration = async () => {
    const {
      allowMultipleRegistrations,
      pageRoute: {
        page,
        params,
        query: { registerSpouse, sid: submissionId, ...query },
      },
      programId,
      scheduleId,
    } = this.props;

    this.setState({
      showRegistrationCompletedModal: true,
      trackRegistrationLoading: true,
    });

    try {
      const registerSpouseValue =
        registerSpouse?.toLowerCase() === "yes" ? true : false;
      await AuthRequest.post(
        `Programs/${programId}/schedules/${scheduleId}/registrations?registerSpouse=${registerSpouseValue}`,
        {
          jotformSubmissionId: submissionId,
        },
      );

      // hide button upon registration if allowMultipleRegistrations is false (because programSchedule isStudentRegistered is not refreshed upon submission)
      if (!allowMultipleRegistrations) {
        this.setState({ hideButton: true });
      }

      // clear out registered (and registerSpouse and sid) param and redirect
      const redirectUrl = PageURL.to(page, params, {
        ...query,
        registered: undefined,
      });
      Navigation.redirect(redirectUrl);
    } catch (err) {
      const { retryCount } = this.state;
      if (retryCount < 3) {
        this.setState({ retryCount: retryCount + 1 }, this.trackRegistration);
        return;
      }

      //error tracking registration: display msg to shluchim prompting them to reach out to admin for assistance
      this.setState({
        trackRegistrationError: true,
      });
    }

    this.setState({
      trackRegistrationLoading: false,
    });
  };

  toggleRegistrationModal = () => {
    const showRegistrationModal = !this.state.showRegistrationModal;
    //toggle the sidebar zIndex - when displaying the modal we set it to 2 to match the page header in order to ensure that the modal is rendered on top of the header
    //this is necessary because the sidebar element has a position sticky so its content will automatically render below an item with a specified zIndex and the modal will t/f be hidden underneath the header
    const sidebarContainer = document.getElementById("program-details-sidebar");
    if (sidebarContainer) {
      sidebarContainer.style.zIndex = showRegistrationModal ? 2 : "auto";
    }
    this.setState({ showRegistrationModal });
  };

  render() {
    const { buttonText, registrationURL, shliachID } = this.props;
    const {
      hideButton,
      showRegistrationModal,
      showRegistrationCompletedModal,
      trackRegistrationError,
      trackRegistrationLoading,
    } = this.state;

    const registerButton = (
      <button
        className="btn btn-accent apply-btn full-width mt-16 whitespace-normal"
        onClick={this.toggleRegistrationModal}
        style={{
          lineHeight: "normal",
          textAlign: "center",
        }}
      >
        {buttonText}
      </button>
    );

    const registrationModal = (
      <ProgramRegistrationModal
        close={this.toggleRegistrationModal}
        registrationURL={registrationURL}
        shliachID={shliachID}
        show={showRegistrationModal}
        title={buttonText}
      />
    );

    const registrationCompleteModal = (
      <Modal show={showRegistrationCompletedModal}>
        <div className="modal-container full-page-mobile-modal-container">
          <div className="modal modal-card card">
            <div className="flex flex-justify-end mobile-flex-justify-start mb-8">
              <i
                className="material-icons pointer link-text-secondary"
                onClick={() =>
                  this.setState({ showRegistrationCompletedModal: false })
                }
              >
                close
              </i>
            </div>
            <div>
              {trackRegistrationError ? (
                <ErrorDisplay
                  errorMessage={
                    <span className="line-height-double">
                      We were unable to track your submision
                      <br />
                      Please reach out to{" "}
                      <a
                        href="mailto:help@chabadoncampus.org"
                        className="link-text"
                      >
                        help@chabadoncampus.org
                      </a>{" "}
                      to notify them that you've submitted this form
                    </span>
                  }
                  errorTitle="Sorry, something went wrong!"
                />
              ) : (
                <div className="text-center" style={{ padding: "24px" }}>
                  <img src="/images/coci-logo.png" alt="" />
                  <p className="large-text fw-700 mt-24 mb-16">
                    {trackRegistrationLoading
                      ? "Processing your submission..."
                      : "Thank you for your submission!"}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );

    return (
      <React.Fragment>
        {!hideButton && registerButton}
        {registrationModal}
        {registrationCompleteModal}
      </React.Fragment>
    );
  }
}
