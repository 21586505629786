import React from "react";
import NumberFormat from "react-number-format";
import { withAppInsights } from "../../lib/AppInsights";
import { Navigation, PROFILE_PLACEHOLDER_URL, PageURL } from "../../lib";
import { PageLink } from "../../lib";
import Pages from "..";
import Loader from "../../components/Loader";
import RecentViewTimeStamp from "./RecentViewTimeStamp";
import ProgressStatusBar from "../raffle/ProgressStatusBar";
import WelcomeUser from "../../components/WelcomeUser";

class DefaultHome extends React.PureComponent {
  async componentDidMount() {
    this.props.actions.getActivity();
    this.props.actions.getPosts(5);
    //check if shliach is enrolled in current raffle before getting raffle widget details
    await this.props.actions.getRaffleEnrollmentStatus(this.props.shliachID);
    if (this.props.raffle.isEnrolledInCurrent) {
      this.props.actions.getRaffleEnrollment(this.props.shliachID);
      this.props.actions.getCurrentRaffle();
    }
  }

  goToRecentView = (page, payload) => {
    const url = PageURL.to(page, payload, null);
    Navigation.go(url);
  };

  render() {
    const {
      loadingActivity,
      programViews,
      shliachViews,
      raffle: {
        loading,
        details: { startDate, endDate, raffleTitle, daysRemaining } = {},
        enrollmentDetails: {
          raffleGoal,
          ticketsSold,
          totalRaised,
          donorSiteURL,
        } = {},
        isEnrolledInCurrent,
      },
      updates: { loading: loadingPosts, posts },
    } = this.props;
    return (
      <div className="home-page page container">
        <div className="home-page-header flex flex-justify-space">
          <WelcomeUser />
          <PageLink
            to={Pages.profile.profile}
            className="btn btn-accent btn-medium ml-16"
            style={{ padding: "0 16px", fontSize: "12px" }}
          >
            View Account
          </PageLink>
        </div>
        {loading ? (
          <div className="half-width mb-40">
            <Loader />
          </div>
        ) : (
          isEnrolledInCurrent && (
            <div className="raffle-card half-width mb-40">
              <div className="card mr-8">
                <div className="flex flex-justify-space tablet-block mobile-block">
                  <div>
                    <p
                      className="xl-text fw-700 mb-16"
                      style={{ marginTop: "10px" }}
                    >
                      {raffleTitle && raffleTitle}
                    </p>
                    <div>
                      <p>
                        <span
                          className="fw-700 inline-block mb-12"
                          style={{ width: "96px" }}
                        >
                          Total Raised
                        </span>
                        <span>
                          $
                          <NumberFormat
                            thousandSeparator={true}
                            defaultValue={totalRaised ? totalRaised : 0}
                            displayType={"text"}
                          />
                        </span>
                      </p>
                      <p>
                        <span
                          className="fw-700 inline-block"
                          style={{ width: "96px" }}
                        >
                          Tickets Sold
                        </span>
                        <NumberFormat
                          thousandSeparator={true}
                          defaultValue={ticketsSold ? ticketsSold : 0}
                          displayType={"text"}
                        />
                      </p>
                    </div>
                  </div>
                  {donorSiteURL ? (
                    <div className="raffles-link flex flex-align-center flex-justify-space">
                      <p>{donorSiteURL}</p>
                      <a
                        href={donorSiteURL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link-text small-text flex flex-align-center ml-16 no-wrap"
                      >
                        <i className="material-icons medium-text mr-8">
                          open_in_new
                        </i>
                        Go to Link
                      </a>
                    </div>
                  ) : null}
                </div>

                <ProgressStatusBar
                  className="mt-24"
                  endDate={endDate}
                  startDate={startDate}
                  daysRemaining={daysRemaining}
                  type="raffleDays"
                />

                {!!raffleGoal && (
                  <ProgressStatusBar
                    className="mt-24"
                    raffleGoal={raffleGoal}
                    ticketsSold={ticketsSold}
                    type="raffleGoal"
                  />
                )}

                <PageLink
                  to={"/grand-draw"}
                  className="link-text uppercase-text fw-700 view-details block"
                >
                  View Details
                </PageLink>
              </div>
            </div>
          )
        )}

        <div className="rv-cards flex">
          <div className="rv-cards-column mr-20">
            <div className="card">
              <div className="flex flex-justify-space flex-align-center large-text mb-28">
                <p className="fw-700 xl-text">Recent Updates</p>
                <PageLink
                  to={Pages.updates.updates}
                  className="btn btn-accent btn-small ml-16"
                  style={{ padding: "0 12px", fontSize: "10px" }}
                >
                  View All
                </PageLink>
              </div>
              {loadingPosts ? (
                <div className="rv-card-loader">
                  <Loader />
                </div>
              ) : posts && posts.length > 0 ? (
                posts.map((p) => {
                  const postImageURL =
                    p._embedded["wp:featuredmedia"] &&
                    p._embedded["wp:featuredmedia"][0].source_url;
                  const postDate = new Date(p.date).toLocaleString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  });
                  return (
                    <div
                      key={p.id}
                      className="rv-card card flat-card"
                      onClick={() =>
                        this.goToRecentView(Pages.updates.updateDetails, {
                          updateId: p.id,
                        })
                      }
                    >
                      <div>
                        <div className="flex flex-align-center">
                          <img
                            src={postImageURL || "/images/placeholder.svg"}
                            alt=""
                            style={{
                              height: "72px",
                              width: "72px",
                              minWidth: "72px",
                              objectFit: "cover",
                              marginRight: "16px",
                            }}
                          />
                          <div>
                            <p
                              className="large-text fw-700"
                              dangerouslySetInnerHTML={{
                                __html: p.title.rendered,
                              }}
                            />
                            <p className="small-text accent-text mt-8">
                              {postDate}
                            </p>
                          </div>
                        </div>
                        <div
                          className="post-content mt-16"
                          dangerouslySetInnerHTML={{
                            __html: p.content.rendered,
                          }}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="rv-empty-state">
                  <img src="/images/no_recent_profiles.svg" alt="" />
                  <p className="medium-text mt-16 mb-8">No Recent Updates</p>
                  <p className="accent-text mb-4 small-text">
                    There are no recent updates.
                  </p>
                  <p className="accent-text small-text">Come back later!</p>
                </div>
              )}
            </div>
          </div>
          <div className="rv-cards-column">
            <div className="card mb-32">
              <p className="fw-700 xl-text mb-32">Recently Viewed Programs</p>
              {loadingActivity ? (
                <div className="rv-card-loader">
                  <Loader />
                </div>
              ) : programViews.length ? (
                programViews.map((program) => (
                  <div
                    key={program.programID}
                    className="card rv-program flat-card"
                    onClick={() =>
                      this.goToRecentView(Pages.programs.programDetails, {
                        programId: program.programID,
                      })
                    }
                  >
                    <div className="flex">
                      <div className="program-bullet mr-16" />
                      <p className="large-text fw-700">{program.name}</p>
                    </div>
                    <p className="accent-text small-text fw-500">
                      <RecentViewTimeStamp viewTime={program.viewedAt} />
                    </p>
                  </div>
                ))
              ) : (
                <div className="rv-empty-state">
                  <img src="/images/no_recent_profiles.svg" alt="" />
                  <p className="medium-text mt-16 mb-8">
                    No Recently Viewed Programs
                  </p>
                  <p className="accent-text mb-4 small-text">
                    You haven't viewed any programs recently.
                  </p>
                  <p className="accent-text small-text">Come back later!</p>
                </div>
              )}
            </div>
            <div className="card">
              <p className="fw-700 xl-text mb-32">Recently Viewed Profiles</p>
              {loadingActivity ? (
                <div className="rv-card-loader">
                  <Loader />
                </div>
              ) : shliachViews.length ? (
                shliachViews.map((shliach) => (
                  <div
                    key={shliach.shliachID}
                    className="card flat-card rv-card"
                    onClick={() =>
                      this.goToRecentView(Pages.directory.directoryDetails, {
                        contactId: shliach.shliachID,
                      })
                    }
                  >
                    <div className="flex full-width">
                      <div className="rv-profile-pic">
                        <img
                          src={
                            shliach.profileImageURL || PROFILE_PLACEHOLDER_URL
                          }
                          alt="profile"
                        />
                      </div>
                      <div className="ml-16 full-width">
                        <div className="flex flex-justify-space flex-align-center">
                          <p className="fw-700 large-text mb-4">
                            {shliach.name}
                          </p>
                          <p className="accent-text small-text fw-500">
                            <RecentViewTimeStamp viewTime={shliach.viewedAt} />
                          </p>
                        </div>
                        <p className="small-text mb-8">{shliach.position}</p>
                        <p className="small-text">{shliach.chabadHouseName}</p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="rv-empty-state">
                  <img src="/images/no_recent_profiles.svg" alt="" />
                  <p className="medium-text mt-16 mb-8">
                    No Recently Viewed Profiles
                  </p>
                  <p className="accent-text mb-4 small-text">
                    You haven't viewed any profiles recently.
                  </p>
                  <p className="accent-text small-text">Come back later!</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withAppInsights(DefaultHome);
