import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { Box, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import { TabPanel } from "../../../../components/TabPanel";

import StudentBackground from "./StudentBackground";
import StudentDemographics from "./StudentDemographics";
import StudentOther from "./StudentOther";

import { useMobile } from "../../../../themes";
import { AuthSelectors } from "../../../../state";

interface StudentProfileProps {
  mobileView: any;
  subTabId: string;
  toSubTab: any;
  student: any;
  pageRoute: any;
  sys: any;
}

export const useStyles = makeStyles(
  () => ({
    container: { flexGrow: 1, display: "flex" },
    title: { marginBottom: 16 },
    mobileContainer: { marginBottom: 16 },
    tabs: {
      minWidth: 300,
      marginRight: 40,
      borderRight: "1px solid #D8D8DD",
      borderBottom: "none",
    },
  }),
  { classNamePrefix: "StudentProfile" },
);

export default function StudentProfile(props: StudentProfileProps) {
  const isMobile = useMobile();
  const { toSubTab, subTabId } = props;
  const [activeTab, setActiveTab] = useState("background");
  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);

  const tabs = useMemo(
    () =>
      [
        {
          component: StudentBackground,
          id: "background",
          title: "Profile & Education",
          condition: true,
        },
        {
          component: StudentDemographics,
          id: "demographics",
          title: "Demographics",
          condition: isShliach,
        },
        {
          component: StudentOther,
          id: "other",
          title: "Other",
          condition: true,
        },
      ].filter((c) => c.condition),
    [isShliach],
  );

  const classes = useStyles();

  const handleChange = useCallback(
    (value: any) => {
      setActiveTab(value);
      toSubTab(value);
    },
    [toSubTab],
  );

  useEffect(() => {
    if (subTabId) {
      handleChange(subTabId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isMobile ? (
        tabs.map((t: any) => (
          <Box className={classes.mobileContainer}>
            <Typography variant="h6" className={classes.title}>
              {t.title}
            </Typography>
            {React.createElement(t.component, props)}
          </Box>
        ))
      ) : (
        <Box className={classes.container}>
          <Tabs
            className={classes.tabs}
            orientation="vertical"
            indicatorColor="primary"
            textColor="primary"
            value={activeTab}
            onChange={(e, value) => handleChange(value)}
          >
            {tabs.map((t) => (
              <Tab label={t.title} value={t.id} key={t.id} />
            ))}
          </Tabs>

          {tabs.map((t: any) => (
            <TabPanel value={activeTab} index={t.id} key={t.id}>
              {React.createElement(t.component, props)}
            </TabPanel>
          ))}
        </Box>
      )}
    </>
  );
}
