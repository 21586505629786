import React, { memo } from "react";
import Modal from "react-modal";
import { Button, createStyles, makeStyles } from "@material-ui/core";

import {
  JewishInterestLevels,
  JewishInterestOverTime,
} from "../../../../state/engagement/types";
import { formatMonthYear } from "../../engagement/shared";

const useStyles = makeStyles(() =>
  createStyles({
    buttonCtr: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "48px",
    },
  }),
);

type Props = {
  jewishInterestsOverTime: JewishInterestOverTime[];
  onClose: () => {};
};

function StudentTrajectoryModal(props: Props) {
  const { jewishInterestsOverTime, onClose } = props;

  const classes = useStyles();

  return (
    <Modal className="modal-container" isOpen={true}>
      <div className="card large-modal" style={{ maxHeight: "75vh" }}>
        <div className="flex flex-align-center flex-justify-space mt-24 mb-32">
          <p className="archivo-extra-bold xxl-text">Student trajectory</p>
          <i className="material-icons link-text-secondary" onClick={onClose}>
            close
          </i>
        </div>
        <div>
          {jewishInterestsOverTime.map((ji) => {
            var dateToDisplay =
              ji.value === JewishInterestLevels.New
                ? ji.studentCreatedOn // valueAsOfDate for "New" levels is a low date of 1/1/2000, so rather display the date the student was created
                : ji.valueAsOfDate;
            return (
              <div key={ji.valueAsOfDate} className="mb-32">
                <div className="flex flex-align-center fw-700 medium-text mb-8">
                  {formatMonthYear(dateToDisplay)} &#x00B7; {ji.value}
                </div>
                <p className="fw-400 medium-text">{ji.reasonDisplay}</p>
              </div>
            );
          })}
        </div>
        <hr className="modal-separator" />
        <div className={classes.buttonCtr}>
          <Button color="primary" onClick={onClose} variant="contained">
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default memo(StudentTrajectoryModal);
