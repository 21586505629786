import { AuthRequest } from "../../lib";

export const LifeInsuranceActions = {
  SET_LIFE_INS_POLICY_LOADING: "SET_LIFE_INS_POLICY_LOADING",
  SET_LIFE_INS_POLICY: "SET_LIFE_INS_POLICY",
  SET_LIFE_INS_ENROLLMENT_LOADING: "SET_LIFE_INS_ENROLLMENT_LOADING",
  SET_LIFE_INS_ENROLLMENT: "SET_LIFE_INS_ENROLLMENT",
  SET_SUBMIT_LIFE_INS_ENROLLMENT_LOADING:
    "SET_SUBMIT_LIFE_INS_ENROLLMENT_LOADING",
  SET_SUBMIT_LIFE_INS_ENROLLMENT: "SET_SUBMIT_LIFE_INS_ENROLLMENT",

  getLifeInsurancePolicy(scheduleId = "") {
    return async (dispatch) => {
      dispatch(LifeInsuranceActions.setLifeInsurancePolicyLoading(true));

      let newState;
      let success = true;

      const response = await AuthRequest.get(
        `LifeInsurancePolicies/${scheduleId}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage:
            err.response &&
            err.response.data &&
            err.response.data.messages &&
            err.response.data.messages.join(" \n"),
        };
      });

      if (success && response.data) {
        newState = {
          data: response.data.payload,
        };
      }

      newState.loading = false;
      newState.success = success;

      dispatch(LifeInsuranceActions.setLifeInsurancePolicy(newState));
    };
  },
  setLifeInsurancePolicyLoading(payload) {
    return {
      type: LifeInsuranceActions.SET_LIFE_INS_POLICY_LOADING,
      payload,
    };
  },
  setLifeInsurancePolicy(payload) {
    return {
      type: LifeInsuranceActions.SET_LIFE_INS_POLICY,
      payload,
    };
  },

  getLifeInsuranceEnrollment(policyId, enrollmentId) {
    return async (dispatch) => {
      dispatch(LifeInsuranceActions.setLifeInsuranceEnrollmentLoading(true));

      let newState;
      let success = true;

      const response = await AuthRequest.get(
        `LifeInsuranceEnrollments/${enrollmentId}?policyId=${policyId}&returnBlank=${true}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage:
            (err.response &&
              err.response.data &&
              err.response.data.messages &&
              err.response.data.messages.join(" \n")) ||
            (err.response &&
              err.response.status === 403 &&
              "Unauthorized Request") ||
            "",
        };
      });

      if (success && response.data) {
        newState = {
          data: response.data.payload,
        };
      }

      newState.loading = false;
      newState.success = success;

      dispatch(LifeInsuranceActions.setLifeInsuranceEnrollment(newState));
    };
  },

  setLifeInsuranceEnrollmentLoading(payload) {
    return {
      type: LifeInsuranceActions.SET_LIFE_INS_ENROLLMENT_LOADING,
      payload,
    };
  },
  setLifeInsuranceEnrollment(payload) {
    return {
      type: LifeInsuranceActions.SET_LIFE_INS_ENROLLMENT,
      payload,
    };
  },

  submitLifeInsuranceEnrollment(enrollment, activity) {
    return async (dispatch) => {
      dispatch(
        LifeInsuranceActions.setSubmitLifeInsuranceEnrollmentLoading(true),
      );

      let newState = {};
      let success = true;

      const response = await AuthRequest.post(
        activity
          ? "LifeInsuranceEnrollments/Action"
          : "LifeInsuranceEnrollments",
        activity
          ? {
              enrollmentID: enrollment.id,
              activityType: activity,
            }
          : enrollment,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage:
            err.response &&
            err.response.data &&
            err.response.data.messages &&
            err.response.data.messages.join(" \n"),
        };
      });

      newState.loading = false;
      newState.success = success;

      dispatch(LifeInsuranceActions.setSubmitLifeInsuranceEnrollment(newState));

      if (success) {
        newState.data = response.data.payload;
        dispatch(LifeInsuranceActions.setLifeInsuranceEnrollment(newState));
      }
    };
  },
  setSubmitLifeInsuranceEnrollmentLoading(payload) {
    return {
      type: LifeInsuranceActions.SET_SUBMIT_LIFE_INS_ENROLLMENT_LOADING,
      payload,
    };
  },
  setSubmitLifeInsuranceEnrollment(payload) {
    return {
      type: LifeInsuranceActions.SET_SUBMIT_LIFE_INS_ENROLLMENT,
      payload,
    };
  },
};
