import React, { memo } from "react";
import { useSelector } from "react-redux";
import { AuthSelectors } from "../../../../../../state";
import { formatFullName, formatNumber } from "../../../../../../lib";

function AttendanceListRecord(props) {
  const {
    classAttendances,
    classSchedules,
    disabled,
    eduProgramType,
    onToggleAttendance,
    onViewStudentDetails,
    student,
    studentIndex,
  } = props;
  const {
    eduStudentCourseScheduleEnrollmentID,
    numOfClassesAttended,
    numOfClassesScheduled,
    studentFirstName,
    studentLastName,
    courseScheduleEnrollmentStatus,
  } = student;

  const allowViewStudentDetails = useSelector(AuthSelectors.canManageStudents);

  return (
    <div
      className={`attendance-table-row attendance-table-row-${eduProgramType} ${
        allowViewStudentDetails && "pointer"
      }`}
      onClick={() =>
        allowViewStudentDetails && onViewStudentDetails(student.studentID)
      }
    >
      <div className="desktop-display-contents">
        <p>{formatFullName(studentFirstName, studentLastName)}</p>
        <div className="desktop-hidden tablet-hidden">
          {formatNumber(numOfClassesAttended)} /{" "}
          {formatNumber(numOfClassesScheduled)}
        </div>
      </div>
      <div
        className={`attendance-table-class-schedules-grid attendance-table-class-schedules-grid-${eduProgramType}`}
      >
        {classSchedules
          .sort((cs1, cs2) => cs1.sortOrder - cs2.sortOrder)
          .map((cs) => {
            const eduClassScheduleID = cs.id;
            const didAttend = classAttendances.find(
              (a) =>
                a.eduClassScheduleID === eduClassScheduleID &&
                a.eduStudentCourseScheduleEnrollmentID ===
                  eduStudentCourseScheduleEnrollmentID,
            );
            const attendanceDisabled =
              disabled ||
              !cs.isOpenForAttendance ||
              courseScheduleEnrollmentStatus === "Pending";
            return (
              <p className="tooltip-container" key={cs.id}>
                <i
                  className={`material-icons${
                    !attendanceDisabled ? " link-text-secondary" : ""
                  }`}
                  style={{
                    color: didAttend ? "#76E269" : "rgba(0, 0, 0, 0.16)",
                    margin: "-16px",
                    padding: 16,
                    cursor: attendanceDisabled ? "not-allowed" : "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!attendanceDisabled) {
                      onToggleAttendance(
                        eduStudentCourseScheduleEnrollmentID,
                        eduClassScheduleID,
                        studentIndex,
                      );
                    }
                  }}
                >
                  {didAttend ? "check_box" : "check_box_outline_blank"}
                </i>
                {(!cs.isOpenForAttendance ||
                  courseScheduleEnrollmentStatus === "Pending") && (
                  <span
                    className="tooltip pre-wrap"
                    style={{ width: "200px", zIndex: 1 }}
                  >
                    {!cs.isOpenForAttendance
                      ? "Attendance cannot be marked prior to a half hour before class begins."
                      : "Attendance cannot be marked for students who are pending acceptance to the course. Please update the acceptance status in the Students tab."}
                  </span>
                )}
              </p>
            );
          })}
      </div>
    </div>
  );
}

export default memo(AttendanceListRecord);
