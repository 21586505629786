import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  GoogleAddressInput,
  GoogleAddressInputProps,
} from "../inputs/GoogleAddressInput";

type FormGoogleAddressFieldProps = Omit<
  GoogleAddressInputProps,
  "onChange" | "value"
>;

export const FormGoogleAddressField = React.memo(
  /**
   *
   */
  function FormGoogleAddressField({
    name,
    ...passProps
  }: FormGoogleAddressFieldProps) {
    const { control } = useFormContext();
    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => {
          const { onChange, ...fieldProps } = field;
          const { error } = fieldState;

          return (
            <GoogleAddressInput
              {...fieldProps}
              id={name}
              onChange={(name, value) => onChange({ target: { name, value } })}
              {...passProps}
              {...(error
                ? {
                    error: true,
                    helperText: error.message,
                  }
                : {})}
              name={name}
            />
          );
        }}
      />
    );
  },
);
