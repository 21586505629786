import React from "react";

export default class CopyToClipboardTextbox extends React.PureComponent {
  state = {
    copySuccessAlert: false,
  };
  mounted = false;

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  isOverflowing = (element) => {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  };

  copyToClipboard = () => {
    let textarea = document.getElementById(this.props.id);
    textarea.select();
    document.execCommand("copy");
    this.setState({ copySuccessAlert: true }, () => {
      setTimeout(() => {
        if (this.mounted) {
          window.getSelection().removeAllRanges();
          this.setState({ copySuccessAlert: false });
        }
      }, 1000);
    });
  };

  render() {
    const {
      className,
      goToLabel,
      id,
      label,
      multiline,
      showGoTo,
      style,
      text,
    } = this.props;
    const { copySuccessAlert } = this.state;
    return (
      <div className={className}>
        <div className="fading-out relative full-width">
          {multiline ? (
            <textarea
              className={copySuccessAlert ? "no-selection-hover" : ""}
              defaultValue={text}
              id={id}
              style={style}
              readOnly
            />
          ) : (
            <input
              id={id}
              style={style}
              defaultValue={text}
              readOnly
              className={copySuccessAlert ? "no-selection-hover" : ""}
            />
          )}
        </div>
        <div className="flex flex-align-center">
          {!copySuccessAlert ? (
            <p
              className="link-text flex flex-align-center ml-16 mobile-ml-0 no-wrap"
              onClick={() => this.copyToClipboard()}
            >
              <i className="material-icons" style={{ fontSize: "14px" }}>
                file_copy
              </i>
              <span className="small-text ml-4">{label}</span>
            </p>
          ) : (
            <p className="accent-text small-text">Copied!</p>
          )}
          {showGoTo && (
            <a
              className="link-text flex flex-align-center ml-16 mobile-mt-8 no-wrap"
              href={text}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="material-icons" style={{ fontSize: "14px" }}>
                open_in_new
              </i>
              <span className="small-text ml-4">{goToLabel}</span>
            </a>
          )}
        </div>
      </div>
    );
  }
}
