export function ApiCallErrorMessageHandler(err, defaultMsg) {
  const { response, status } = err || {};
  const statusCode = (response && response.status) || status || -1;

  switch (statusCode) {
    case 401:
    case 403:
      return "Unauthorized Request.";
    case 400:
      if (response && response.data && response.data.messages) {
        const errorMessage = response.data.messages.join(", \n");
        if (errorMessage) return errorMessage;
      }
      return defaultMsg || "Sorry, something went wrong.  Please try again.";
    default:
      //500 & other
      return defaultMsg || "Sorry, an unexpected error has occurred.";
  }
}

export function IsApiErrorStatus(err, status) {
  return !!(
    err &&
    err.response &&
    (err.response.status === status ||
      (err.response.data && err.response.data.status === status))
  );
}

export default ApiCallErrorMessageHandler;
