import React from "react";
import { Button } from "@material-ui/core";

import { Navigation } from "../../../lib";
import StripeProvider from "../../../components/StripeProvider";
import SmsAddCreditModal from "../SmsAddCreditModal.vm";
import AppPages from "../..";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const { REACT_APP_STRIPE_API_KEY } = process.env;

export default class MessageStatus extends React.PureComponent {
  componentDidMount() {
    const { reset, success } = this.props;
    if (success) {
      reset();
    }
  }
  state = { showSmsAddCreditModal: false };
  render() {
    const {
      chabadHouseID,
      reset,
      rsvpProgramScheduleID,
      success,
      scheduledMessage,
    } = this.props;
    return (
      <div>
        <div className="mt-48">
          {success ? (
            <div className="message-status-content">
              <img
                width="400px"
                src="/images/sms_sent.svg"
                alt="message sent"
              />

              <div className="xxl-text mt-24 mb-24 fw-900">
                Your message was successfully{" "}
                {scheduledMessage ? "scheduled" : "sent"}
              </div>

              <div className="mt-24">
                <Button
                  size="small"
                  onClick={() => {
                    Navigation.go(
                      AppPages.engagement.messaging.path.replace(
                        ":view",
                        "inbox",
                      ),
                    );
                  }}
                  variant="contained"
                  color="primary"
                >
                  Go to inbox
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <StripeProvider apiKey={REACT_APP_STRIPE_API_KEY}>
                <SmsAddCreditModal
                  chabadHouseID={chabadHouseID}
                  show={this.state.showSmsAddCreditModal}
                  onPaymentComplete={() => {
                    this.setState({ showSmsAddCreditModal: false }, () => {
                      Navigation.goBack();
                      toast.success("Credits successfully added!");
                    });
                  }}
                  onClose={() => {
                    this.setState({ showSmsAddCreditModal: false });
                  }}
                />
              </StripeProvider>
              <div className="message-status-content">
                <img
                  width="400px"
                  src="/images/message_sent_error.svg"
                  alt="insufficient credit"
                />
                <div className="message-container">
                  <div className="message">
                    Sorry, you have insufficient credit on file and cannot send
                    messages at this time.
                  </div>
                </div>
                <div>
                  <div>
                    <button
                      className="btn btn-light mr-16"
                      onClick={() => {
                        reset();
                        Navigation.go(`/rsvp/${rsvpProgramScheduleID}`);
                      }}
                    >
                      Cancel message
                    </button>
                    <button
                      onClick={() => {
                        this.setState({ showSmsAddCreditModal: true });
                      }}
                      className="btn btn-accent"
                    >
                      Add credit now
                    </button>
                  </div>
                  <div className="mt-24">
                    <span
                      onClick={() => {
                        Navigation.goBack();
                      }}
                      className="sms-status-page-link"
                    >
                      GO BACK AND EDIT MESSAGE
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
