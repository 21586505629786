import React, { memo, useCallback, useMemo } from "react";
import CourseSchedulesList from "./schedules/CourseSchedulesList";
import { PageLink } from "../../../../../lib";

const courseSchedulesListResultsView = 10;

function CourseSchedulesListContainer(props) {
  const {
    actions: { getEduCourses },
    courses,
    enrollment: {
      data: { eduProgramName, eduProgramType, exclusiveEduCourseID } = {},
    },
    pageRoute,
  } = props;

  const { params } = pageRoute;

  const addNewScheduleLinkParams = useMemo(
    () => ({
      ...params,
      courseId: exclusiveEduCourseID,
      courseView: "offer",
    }),
    [exclusiveEduCourseID, params],
  );

  const getCourses = useCallback(
    (pageNum) => {
      getEduCourses(pageNum, courseSchedulesListResultsView, null, true);
    },
    [getEduCourses],
  );

  return (
    <CourseSchedulesList
      addNewScheduleLinkComponent={
        <PageLink
          className="btn btn-accent edu-courses-add-new-schedule-btn mobile-full-width"
          to={pageRoute.page}
          params={addNewScheduleLinkParams}
        >
          Add new schedule
        </PageLink>
      }
      courses={courses}
      eduProgramName={eduProgramName}
      eduProgramType={eduProgramType}
      getCourses={getCourses}
      pageRoute={pageRoute}
      resultsView={courseSchedulesListResultsView}
    />
  );
}

export default memo(CourseSchedulesListContainer);
