import React from "react";
import CreateEvent from "./manage/CreateEvent";
import ManageEventScheduleContainer from "./manage/ManageEventScheduleContainer";
import EventSchedulesListContainer from "./list/EventSchedulesListContainer";

export default class EventsContainer extends React.PureComponent {
  eventViews = {
    create: CreateEvent,
    list: EventSchedulesListContainer,
    manage: ManageEventScheduleContainer,
  };

  getEventView = () => {
    const {
      pageRoute: {
        params: { rsvpItemId },
      },
    } = this.props;

    return !rsvpItemId
      ? this.eventViews.list
      : rsvpItemId === "new"
      ? this.eventViews.create
      : this.eventViews.manage;
  };

  render() {
    return React.createElement(this.getEventView(), this.props);
  }
}
