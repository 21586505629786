import React from "react";
import Select from "react-select";
import { Navigation, PageURL } from "../../../../../lib";
import debounce from "lodash.debounce";

export default class CourseListFilters extends React.PureComponent {
  state = {
    mobileFiltersDropdownOpen: false,
  };

  onChange = (name, val, debounce) => {
    const {
      pageRoute: { query, page, params },
    } = this.props;

    const url = PageURL.to(page, params, {
      ...query,
      [name]: val ? encodeURIComponent(val) : undefined,
      p: undefined, //reset page to 1
    });
    Navigation.redirect(url);

    setTimeout(debounce ? this.onFilterDebounce : this.onFilter, 0);
  };

  onFilter = () => {
    this.props.getCourses();
  };
  onFilterDebounce = debounce(this.onFilter, 500);

  render() {
    const {
      courseCategories,
      courseLevels,
      pageRoute: { query },
      sortDefault,
    } = this.props;

    const { ca = "", le = "", se = "", so = "" } = query;

    const caOptions = [
      { label: "All Categories", value: "" },
      ...(courseCategories
        ? courseCategories.map((c) => ({
            value: c.enumValue,
            label: c.displayValue,
          }))
        : []),
    ];

    const soOptions = [
      { label: "Date", value: "date" },
      { label: "Category", value: "category" },
      { label: "Name", value: "name" },
    ];

    const leOptions = [
      { label: "All Levels", value: "" },
      ...(courseLevels
        ? courseLevels.map((l) => ({
            value: l.enumValue,
            label: l.displayValue,
          }))
        : []),
    ];

    return (
      <div className="flex flex-justify-space flex-align-center tablet-block mobile-block">
        <div className="search-input">
          <input
            name="se"
            onChange={(event) =>
              this.onChange(event.target.name, event.target.value, true)
            }
            placeholder="Search"
            type="text"
            value={decodeURIComponent(se)}
          />
          <i className="material-icons accent-text-secondary pointer">search</i>
        </div>
        <p className="desktop-hidden tablet-hidden flex flex-align-center flex-justify-end mt-16">
          Filter & Sort{" "}
          <i
            className="material-icons ml-16 accent-text-secondary xl-text"
            onClick={() =>
              this.setState({
                mobileFiltersDropdownOpen:
                  !this.state.mobileFiltersDropdownOpen,
              })
            }
          >
            more_horiz
          </i>
        </p>
        <div
          className={`flex flex-align-center ml-16 tablet-ml-0 tablet-mt-8 mobile-filters-dropdown  ${
            this.state.mobileFiltersDropdownOpen ? "open" : ""
          }`}
        >
          <div className="flex flex-align-center filter-width">
            <p className="mr-8 nowrap-text">Filter by</p>
            <Select
              className="filter-select"
              classNamePrefix="filter-select"
              defaultValue={caOptions[0]}
              isSearchable={false}
              onChange={(val) => this.onChange("ca", val.value)}
              options={caOptions}
              value={
                (ca &&
                  caOptions.find((c) => c.value === decodeURIComponent(ca))) ||
                caOptions[0]
              }
            />
          </div>

          <div
            className="flex flex-align-center ml-16 mobile-ml-0"
            style={{ width: "184px" }}
          >
            <p className="mr-8 nowrap-text">Filter by</p>
            <Select
              className="filter-select"
              classNamePrefix="filter-select"
              defaultValue={leOptions[0]}
              isSearchable={false}
              onChange={(val) => this.onChange("le", val.value)}
              options={leOptions}
              value={
                (le &&
                  leOptions.find((l) => l.value === decodeURIComponent(le))) ||
                leOptions[0]
              }
            />
          </div>

          <div
            className="flex flex-align-center ml-16 mobile-ml-0"
            style={{ width: "160px" }}
          >
            <p className="mr-8 nowrap-text">Sort by</p>
            <Select
              className="filter-select"
              classNamePrefix="filter-select"
              defaultValue={soOptions[0]}
              isSearchable={false}
              onChange={(val) => this.onChange("so", val.value)}
              options={soOptions}
              value={
                (so &&
                  soOptions.find((s) => s.value === decodeURIComponent(so))) ||
                soOptions.find(
                  (s) => s.value === decodeURIComponent(sortDefault),
                )
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
