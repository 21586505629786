import React from "react";
import { getAddressDisplay, getNameDisplay, Navigation, PageURL } from "../lib";

import Loader from "../components/Loader";
import Modal from "./Modal";
import Pages from "../pages";

export default class AccountInfoHeader extends React.PureComponent {
  state = {
    showDetails: false,
  };

  navigate = (page, params, query) => {
    this.setState({ navigating: true }, () => {
      const url = PageURL.to(page, params, query);
      Navigation.go(url);
      window.scrollTo({ behavior: "smooth", left: 0, top: 0 });

      if (page === Navigation.page) {
        this.setState({ navigating: false });
      }
    });
  };

  goToProfile = () => {
    this.navigate(Pages.profile.profile, null, {
      after: encodeURIComponent(Navigation.locationURL),
    });
  };

  render() {
    let {
      profile: {
        familyAddress,
        homePhone,
        husband,
        primaryChabadHouse,
        wife,
        loading,
      },
    } = this.props;
    return (
      <div className="container">
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className="account-info-header">
              <div>
                {primaryChabadHouse && (
                  <p className="large-text fw-700 mb-16">
                    {primaryChabadHouse.name}
                    <span
                      className="link-text desktop-hidden mobile-hidden ml-24"
                      style={{ fontSize: "14px" }}
                      onClick={this.goToProfile}
                    >
                      Edit Account
                    </span>
                  </p>
                )}
                <p className="mb-16">
                  {[husband, wife]
                    .filter((val) => !!val)
                    .map(({ firstName, lastName, title }) =>
                      getNameDisplay(firstName, lastName, title),
                    )
                    .join(" | ")}
                </p>
                <p
                  className="link-text tablet-hidden mobile-hidden"
                  onClick={this.goToProfile}
                >
                  Edit Account
                </p>
              </div>
              {primaryChabadHouse && (
                <div className="mobile-hidden">
                  <p className="accent-text small-text uppercase-text mb-16">
                    Chabad House
                  </p>
                  {primaryChabadHouse.address && (
                    <p className="mb-16 flex">
                      <i className="material-icons accent-text-light mr-8">
                        location_on
                      </i>
                      <span className="line-height-double">
                        {getAddressDisplay(primaryChabadHouse.address, true)}
                      </span>
                    </p>
                  )}
                  {primaryChabadHouse.primaryPhoneNumber && (
                    <p className="flex flex-align-center">
                      <i className="material-icons accent-text-light mr-8">
                        phone
                      </i>
                      <span>{primaryChabadHouse.primaryPhoneNumber}</span>
                    </p>
                  )}
                </div>
              )}
              {(familyAddress || homePhone) && (
                <div className="mobile-hidden">
                  <p className="accent-text small-text uppercase-text mb-16">
                    {" "}
                    Home
                  </p>
                  {familyAddress && (
                    <p className="mb-16 flex">
                      <i className="material-icons accent-text-light mr-8">
                        location_on
                      </i>
                      <span className="line-height-double">
                        {getAddressDisplay(familyAddress, true)}
                      </span>
                    </p>
                  )}
                  {homePhone && (
                    <p className="flex flex-align-center">
                      <i className="material-icons accent-text-light mr-8">
                        phone
                      </i>
                      <span>{homePhone}</span>
                    </p>
                  )}
                </div>
              )}
              <p
                className="desktop-hidden tablet-hidden link-text"
                onClick={() => {
                  this.setState({ showDetails: !this.state.showDetails });
                }}
              >
                View Details
              </p>
            </div>

            <Modal show={this.state.showDetails}>
              <div className="modal-container" key="detailsModal">
                <div className="modal card details-modal">
                  <div>
                    {primaryChabadHouse && (
                      <p className="fw-700 mb-8">{primaryChabadHouse.name}</p>
                    )}
                    <p className="mb-16 small-text">
                      {[husband, wife]
                        .filter((val) => !!val)
                        .map(({ firstName, lastName, title }) =>
                          getNameDisplay(firstName, lastName, title),
                        )
                        .join(" | ")}
                    </p>
                  </div>
                  {primaryChabadHouse && (
                    <div className="mb-24">
                      <p className="accent-text xs-text uppercase-text mb-12">
                        Chabad House
                      </p>
                      {primaryChabadHouse.address && (
                        <p className="mb-12">
                          <i className="material-icons accent-text-light mr-8">
                            location_on
                          </i>
                          <span className="small-text">
                            {getAddressDisplay(
                              primaryChabadHouse.address,
                              true,
                            )}
                          </span>
                        </p>
                      )}
                      {primaryChabadHouse.primaryPhoneNumber && (
                        <p>
                          <i className="material-icons accent-text-light mr-8">
                            phone
                          </i>
                          <span className="small-text">
                            {primaryChabadHouse.primaryPhoneNumber}
                          </span>
                        </p>
                      )}
                    </div>
                  )}
                  {(familyAddress || homePhone) && (
                    <div>
                      <p className="accent-text xs-text uppercase-text mb-16">
                        {" "}
                        Home
                      </p>
                      {familyAddress && (
                        <p className="mb-12">
                          <i className="material-icons accent-text-light mr-8">
                            location_on
                          </i>
                          <span className="small-text">
                            {getAddressDisplay(familyAddress, true)}
                          </span>
                        </p>
                      )}
                      {homePhone && (
                        <p>
                          <i className="material-icons accent-text-light mr-8">
                            phone
                          </i>
                          <span className="small-text">{homePhone}</span>
                        </p>
                      )}
                    </div>
                  )}
                  <div className="modal-btns">
                    <p
                      className="link-text uppercase-text fw-700"
                      onClick={this.goToProfile}
                    >
                      Edit Account
                    </p>
                    <p
                      className="link-text uppercase-text ml-40 fw-700"
                      onClick={() =>
                        this.setState({ showDetails: !this.state.showDetails })
                      }
                    >
                      Close
                    </p>
                  </div>
                </div>
              </div>
            </Modal>
          </React.Fragment>
        )}
      </div>
    );
  }
}
