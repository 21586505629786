import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormFieldProps } from "../form";
import {
  RadioGroupInput,
  RadioGroupInputProps,
} from "../inputs/RadioGroupInput";

type FormRadioGroupFieldProps = {
  onChange?: (name: string, val: any) => void; // optional change handler
} & FormFieldProps &
  Omit<RadioGroupInputProps, "onChange">;

export const FormRadioGroupField = React.memo(
  /**
   *
   */
  function FormRadioGroupField({
    name,
    ...passProps
  }: FormRadioGroupFieldProps) {
    const { control } = useFormContext();
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => {
          const { onChange, ...fieldProps } = field;
          const { error } = fieldState;

          return (
            <RadioGroupInput
              {...fieldProps}
              id={name}
              onChange={(name, value) => onChange({ target: { name, value } })}
              {...passProps}
              {...(error
                ? {
                    error: true,
                    helperText: error.message,
                  }
                : {})}
            />
          );
        }}
      />
    );
  },
);
