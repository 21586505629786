import React from "react";

export default ({ name }) => (
  <div className="flex flex-align-center flex-justify-center full-page-state">
    <p className="fw-700 xxl-text text-center mt-32 mb-40">
      You do not have access to view the {name} folder
    </p>
    <p className="accent-text large-text text-center">
      Contact your administrator to request access
    </p>
  </div>
);
