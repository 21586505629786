import React from "react";
import { Add } from "@material-ui/icons";
import { PageLink } from "../../../../../lib";

export default function RsvpListActions(props) {
  const {
    rsvpType,
    hasItems,
    paramsForNew,
    ExportButtonComponent,
    getRegistrationsForExport,
    pageRoute: {
      page,
      params: { programScheduleId },
    },
  } = props;

  return (
    <div className="flex flex-align-center rsvp-events-list-header-actions">
      <PageLink
        className="btn btn-secondary btn-medium"
        params={{
          ...paramsForNew,
          programScheduleId,
        }}
        to={page}
      >
        <Add className="mr-8" /> {rsvpType}
      </PageLink>
      {hasItems && (
        <div>
          <ExportButtonComponent
            className="btn btn-secondary btn-medium full-width text-center"
            getStudentRegistrations={getRegistrationsForExport}
          />
        </div>
      )}
    </div>
  );
}
