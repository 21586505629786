import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import Courses from "./courses/CoursesContainer";
import Settings from "./settings/SettingsContainer";
import Students from "./students/StudentsContainer";
import { EduPageViews } from "../constants";
import {
  InternPermissions,
  Navigation,
  PageURL,
  doArraysIntersect,
} from "../../../lib";
import { AuthSelectors } from "../../../state";

const myEduPageViews = {
  [EduPageViews.courses]: {
    component: Courses,
    externalUserAuthorization: [
      InternPermissions.eduAttendance,
      InternPermissions.eduSchedule,
      InternPermissions.eduStudents,
    ],
  },
  [EduPageViews.settings]: {
    component: Settings,
    externalUserAuthorization: [],
  },
  [EduPageViews.students]: {
    component: Students,
    externalUserAuthorization: [InternPermissions.eduStudents],
  },
};

function MyEdu(props) {
  const {
    pageRoute: {
      page,
      params: { pageView, ...params },
    },
  } = props;

  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);
  const userClaims = useSelector(AuthSelectors.claims);

  useEffect(() => {
    if (!pageView || !myEduPageViews[pageView]) {
      Navigation.redirect(
        PageURL.to(
          page,
          {
            ...params,
            pageView: EduPageViews.courses,
          },
          null,
        ),
      );
    }
  }, [page, pageView, params]);

  // verify non-shliach is authorized for the selected pageView. otherwise, redirect
  useEffect(() => {
    if (pageView && !isShliach) {
      const isAuthorizedForPageView = doArraysIntersect(
        userClaims,
        myEduPageViews[pageView].externalUserAuthorization,
      );
      if (!isAuthorizedForPageView) {
        Navigation.redirect("/404");
      }
    }
  }, [pageView, isShliach, userClaims]);

  const myEduPage = myEduPageViews[pageView]?.component;

  return myEduPage ? React.createElement(myEduPage, props) : null;
}

export default memo(MyEdu);
