export const messageRecipientLimit = 500;

export const getRecipientStudentsOptions = (students) =>
  students.map((p, i) => ({
    ...p,
    key: p.studentID,
    value: p.studentID,
    label: `${p.studentFirstName} ${p.studentLastName}`,
  }));

export const getStudentRecipientDetails = async (
  getStudents,
  messageThread,
) => {
  const studentId = messageThread[0]?.people[0]?.studentId;
  if (!studentId) {
    return [];
  }
  const response = await getStudents(1, 15, {
    studentIDsToExclude: [studentId], //when selectAll is set to false, then studentIDsToExclude is really the list of ids to include
    selectAll: false,
    isExport: true,
  });
  if (!response.success) {
    return [];
  }

  return getRecipientStudentsOptions(response.data.payload.results);
};
