import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  resourceGrid: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Avenir",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(0, 200px))",
    rowGap: 16,
    columnGap: 16,
    maxWidth: 687,
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  folderName: {
    display: "flex",
    alignContent: "center",
    fontSize: 22,
    fontWeight: 900,
    letterSpacing: "0.17px",
    height: 48,
    borderBottom: "1px solid #edecec",
    gridColumn: "1 / -1",
    [theme.breakpoints.down("sm")]: {
      padding: "0 15px",
      margin: "15px auto",
    },
  },
  section: {
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: "0.14px",
    height: 38,
    borderBottom: "1px solid #edecec",
    marginTop: 30,
    gridColumn: "1 / -1",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 15,
    },
  },
  file: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "space-between",
    transition: "opacity 300ms linear",
    "&:hover": {
      opacity: 0.6,
      cursor: "pointer",
    },
  },
  fileName: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "0.1px",
    textAlign: "center",
  },
  thumbnailContainer: {
    height: 140,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  thumbnail: {
    padding: 15,
    maxHeight: "100%",
    maxWidth: 200,
    objectFit: "contain",
  },
  previewModal: {
    width: "95%",
    height: "100% !important",
    maxHeight: "100% !important",
    overflow: "auto",
    padding: 16,
  },
  previewHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: 8,
    fontSize: 18,
    fontWeight: 900,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row-reverse",
    },
  },
  previewBtns: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row-reverse",
    },
  },
  iframe: {
    width: "100%",
    height: "calc(100% - 48px)",
  },
}));
