import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip, Divider } from "@material-ui/core";
import { getEngagementPeriodLabel } from "./";
import { pluralizeText } from "../../../../lib";
import { SystemSelectors, EngagementActions } from "../../../../state";

const StudentEngagementPeriodDetails = ({
  engagementPeriods,
  engagementPeriodDetailsId,
  closeEngagmentPeriodDetails,
}) => {
  const [engagementPeriodDetails, setEngagementPeriodDetails] = useState();
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [detailsErrorMessage, setDetailsErrorMessage] = useState();

  useEffect(() => {
    const getEngagementPeriodDetails = async (engagementPeriodId) => {
      setDetailsLoading(true);
      const response =
        await EngagementActions.getEngagementPeriodStudentDetails(
          engagementPeriodId,
        );
      if (response.success) {
        setEngagementPeriodDetails(response.data);
      } else if (response.errorMessage) {
        setDetailsErrorMessage(response.errorMessage);
      }
      setDetailsLoading(false);
    };
    getEngagementPeriodDetails(engagementPeriodDetailsId);
  }, [engagementPeriodDetailsId]);

  return (
    <>
      <Divider />
      <div className="mt-24 mb-24">
        <div className="flex flex-justify-end">
          <i
            className="material-icons large-text link-text-secondary"
            onClick={closeEngagmentPeriodDetails}
          >
            close
          </i>
        </div>
        {detailsLoading ? (
          <p className="small-text text-center">Loading...</p>
        ) : detailsErrorMessage ? (
          <p className="error-text small-text text-center">
            {detailsErrorMessage}
          </p>
        ) : (
          !!engagementPeriodDetails && (
            <>
              <div className="mb-24">
                <div className="flex flex-justify-space mb-12">
                  <p className="large-text fw-700">
                    {getEngagementPeriodLabel(
                      engagementPeriodDetailsId,
                      engagementPeriods,
                    )}{" "}
                    tagged goals details
                  </p>
                </div>
                <div>
                  {engagementPeriodDetails.length}{" "}
                  {pluralizeText("student", engagementPeriodDetails.length)}
                </div>
              </div>
              <EngagementPeriodDetailsHeader />
              {engagementPeriodDetails.map((row) => {
                return <EngagementPeriodDetailsRow row={row} />;
              })}
            </>
          )
        )}
      </div>
    </>
  );
};

const EngagementPeriodDetailsHeader = () => {
  return (
    <div className="engagement-period-details-header flex flex-align-top flex-justify-space accent-text">
      <div>Students</div>
      <div>Status</div>
      <div style={{ paddingRight: "20px", paddingLeft: "10px" }}>
        Engagement goals
      </div>
      <div>Interest level</div>
    </div>
  );
};

const getTargetStatusIcon = (status) => {
  switch (status) {
    case "TargetReached":
      return (
        <Tooltip title="Met full goal">
          <img src="/images/target_reached.svg" alt={status} height="24px" />
        </Tooltip>
      );
    case "TargetReachedPartial":
      return (
        <Tooltip title="Met partial goal">
          <img
            src="/images/target_reached_partial.svg"
            alt={status}
            height="24px"
          />
        </Tooltip>
      );
    case "TargetReachedOtherwise":
      return (
        <Tooltip title="Missed goal, but otherwise reached">
          <img
            src="/images/target_reached_otherwise.svg"
            alt={status}
            height="24px"
          />
        </Tooltip>
      );
    case "TargetMissed":
      return (
        <Tooltip title="Missed goal">
          <img src="/images/target_missed.svg" alt={status} height="24px" />
        </Tooltip>
      );
    default:
      return;
  }
};

const DetailsProgressBar = ({ reached, total }) => {
  return (
    <div className="engagement-period-details-progress-bar">
      <div className="engagement-period-details-progress-total">
        <div
          className="engagement-period-details-progress-reached"
          style={{
            width: `${(reached * 100) / total}%`,
          }}
        >
          <div className="ml-8" style={{ paddingTop: "2px" }}>
            {reached}/{total}
          </div>
        </div>
      </div>
    </div>
  );
};
const EngagementPeriodDetailsRow = ({ row }) => {
  const interactionTypes = useSelector(SystemSelectors.interactionTypes);
  const {
    targetedInteractionTypesReached,
    targetedInteractionTypesMissed,
    nonTargetedInteractionTypesReached,
    studentFullName,
    status,
    targetedInteractionTypeCount,
    targetJil,
  } = row;
  return (
    <div className="flex flex-align-center flex-justify-space mt-4 mb-4 engagement-period-details-row">
      <div>{studentFullName}</div>
      <div className="mt-4">{getTargetStatusIcon(status)}</div>
      <div>
        {!!targetedInteractionTypeCount && (
          <Tooltip
            title={
              <div className="text-center">
                {!!targetedInteractionTypesReached.length && (
                  <div className="mb-4">
                    Tagged and reached:
                    {targetedInteractionTypesReached.map((id) => {
                      return (
                        <div>
                          {interactionTypes?.find((it) => it.id === id)?.name}
                        </div>
                      );
                    })}
                  </div>
                )}
                {!!targetedInteractionTypesMissed.length && (
                  <div className="mb-4">
                    Tagged but not reached:
                    {targetedInteractionTypesMissed.map((id) => {
                      return (
                        <div>
                          {interactionTypes?.find((it) => it.id === id)?.name}
                        </div>
                      );
                    })}
                  </div>
                )}
                {!!nonTargetedInteractionTypesReached.length && (
                  <div className="mb-4">
                    Additional engagements:
                    {nonTargetedInteractionTypesReached.map((id) => {
                      return (
                        <div>
                          {interactionTypes?.find((it) => it.id === id)?.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            }
            arrow
          >
            <div style={{ paddingRight: "10px" }}>
              <DetailsProgressBar
                reached={targetedInteractionTypesReached.length}
                total={targetedInteractionTypeCount}
              />
            </div>
          </Tooltip>
        )}
      </div>
      <div>{targetJil}</div>
    </div>
  );
};
export default React.memo(StudentEngagementPeriodDetails);
