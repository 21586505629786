import React from "react";
import { useFormState } from "react-hook-form";

export type FormIsDirtyWatchProps = {
  children: (isDirty: boolean) => any;
};

export const FormIsDirtyWatch = React.memo(
  /**
   *
   */
  function FormIsDirtyWatch({ children }: FormIsDirtyWatchProps) {
    const { isDirty } = useFormState();

    return children(isDirty);
  },
);
