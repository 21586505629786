import React, { useState, useEffect } from "react";
import { Collapse } from "@material-ui/core";
import Toggle from "../../../components/form/Toggle";

export default function RequireDonorAddressSection(props) {
  const { onChange, requireDonorAddress, showAllExpanded } = props;
  const [showExpanded, setShowExpanded] = useState(showAllExpanded);
  const handleToggleExpand = () => {
    setShowExpanded(!showExpanded);
  };

  useEffect(() => {
    setShowExpanded(showAllExpanded);
  }, [showAllExpanded]);

  return (
    <div className="raffles-form-section">
      <div className="raffles-section-title" onClick={handleToggleExpand}>
        <div className="flex">
          <p className="large-text fw-900 flex flex-align-center pointer mb-8">
            Require Donor Address
          </p>
        </div>
        {showExpanded ? (
          <i className="material-icons accent-text-secondary">
            keyboard_arrow_up
          </i>
        ) : (
          <i className="material-icons accent-text-secondary">
            keyboard_arrow_down
          </i>
        )}
      </div>

      <Collapse in={showExpanded}>
        <div className="expanded-section">
          <p className="accent-text-dark mb-16 line-height-double">
            The more info needed, the less likely donors are to complete
            purchase.
          </p>
          <Toggle
            name="requireDonorAddress"
            onChange={onChange}
            options={[
              {
                value: true,
                display: "Yes",
              },
              {
                value: false,
                display: "No",
              },
            ]}
            value={requireDonorAddress}
          />
        </div>
      </Collapse>
    </div>
  );
}
