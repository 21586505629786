import React from "react";
import BillingAddress from "./BillingAddress";
import CreditCardInfo from "./CreditCardInfo";
import FadeOutErrorMessage from "../../../../components/FadeOutErrorMessage";
import { formatCurrency, getCcIcon } from "../../../../lib";

export default class Payment extends React.PureComponent {
  render() {
    const {
      account: { credCardInfo, familyAddress, primaryChabadHouse } = {},
      countries,
      enrollment: {
        id: enrollmentId,
        billing,
        creditCardInfo: enrollmentCredCardInfo,
        enrolledByShliachID,
        status,
      },
      enrollmentStatuses,
      onChange,
      onChangeEvt,
      policyPrice,
      shliachID,
      showReplacePaymentMethod,
      showReplacePaymentMethodSuccessMessage,
      submitAttempted,
      subscriptionAgreementAccepted,
      toggleReplacePaymentMethodState,
      toggleSubscriptionAgreement,
    } = this.props;

    return (
      <div className="life-insurance-payment-section">
        <h2 className="section-title">
          Payment - ${formatCurrency(policyPrice)}
        </h2>

        {enrollmentId && !showReplacePaymentMethod ? (
          <div>
            {enrollmentCredCardInfo && (
              <div className="flex flex-align-center mb-32">
                {getCcIcon(enrollmentCredCardInfo.cardBrand)}{" "}
                {enrollmentCredCardInfo.cardBrand} ending in{" "}
                {enrollmentCredCardInfo.lastFour}
              </div>
            )}
            {status !== enrollmentStatuses.Terminated &&
              (enrolledByShliachID !== shliachID ? (
                <p>
                  *Payment method can be updated by the shliach who submitted
                  the Life Insurance policy enrollment
                </p>
              ) : (
                <React.Fragment>
                  <div className="flex flex-align-center">
                    <p
                      className={`uppercase-text link-text${
                        status === enrollmentStatuses.FailedRenewal
                          ? " error-text"
                          : ""
                      }`}
                      onClick={() => toggleReplacePaymentMethodState(true)}
                    >
                      Replace payment method
                    </p>
                  </div>
                  {showReplacePaymentMethodSuccessMessage && (
                    <FadeOutErrorMessage
                      className="mt-8 block success-message"
                      message="Payment method has been updated"
                      onTimeout={() =>
                        toggleReplacePaymentMethodState(null, {
                          showReplacePaymentMethodSuccessMessage: false,
                        })
                      }
                    />
                  )}
                  {status !== enrollmentStatuses.FailedRenewal && (
                    <p className="small-text accent-text mt-16">
                      *New payment method will be used starting with next
                      renewal
                    </p>
                  )}
                </React.Fragment>
              ))}
          </div>
        ) : (
          <React.Fragment>
            {!enrollmentId && (
              <div
                className={`flex custom-checkbox-container mb-32 ${
                  submitAttempted && !subscriptionAgreementAccepted
                    ? "error"
                    : ""
                }`}
              >
                <input
                  checked={subscriptionAgreementAccepted}
                  className="custom-checkbox"
                  id="li-subscription-agreement"
                  onChange={toggleSubscriptionAgreement}
                  type="checkbox"
                />
                <label htmlFor="li-subscription-agreement">
                  I understand that the contract will automatically renew
                  annually.
                </label>
              </div>
            )}
            <div className="mb-40">
              <CreditCardInfo
                billing={billing}
                cardOnFile={credCardInfo}
                getCcIcon={getCcIcon}
                isReplacingPaymentMethod={showReplacePaymentMethod}
                onChange={onChange}
                onChangeEvt={onChangeEvt}
                submitAttempted={submitAttempted}
              />
            </div>
            {!billing.useCardOnFile && (
              <BillingAddress
                billing={billing}
                chabadHouseAddress={
                  primaryChabadHouse && primaryChabadHouse.address
                }
                countries={countries}
                familyAddress={familyAddress}
                onChange={onChange}
                onChangeEvt={onChangeEvt}
                submitAttempted={submitAttempted}
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
