import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SmsActions } from "../../../state/sms/actions";
import ReviewRecipients from "./ReviewRecipients";

export default connect(
  function mapState(state) {
    return {
      ...state.sms,
      ui: state.ui,
    };
  },
  (dispatch) => {
    return {
      actions: bindActionCreators({ ...SmsActions }, dispatch),
    };
  },
)(ReviewRecipients);
