import React from "react";

export default ({
  student: {
    allergiesDisplay,
    dietaryNotes,
    doubleMajorName,
    majorName,
    studentIDNumber,
    tShirtSizeDisplay,
  },
}) => (
  <div className="student-background">
    <p className="fw-900 medium-text mb-16">Misc</p>
    <div className="two-column-grid">
      <div className="two-column-grid">
        <p className="fw-700">Major / Double Major</p>
        <p>
          {majorName || "—"} / {doubleMajorName || "—"}
        </p>
        <p className="fw-700">School Student ID</p>
        <p>{studentIDNumber || "—"}</p>
        <p className="fw-700">Preferred Shirt Size</p>
        <p>{tShirtSizeDisplay || "—"}</p>
      </div>
    </div>
    <div className="student-background-divider" />
    <p className="fw-900 medium-text mb-16">Allergies</p>
    <div>
      {allergiesDisplay && allergiesDisplay.length
        ? allergiesDisplay.map((allergy) => (
            <span className="mr-8 student-profile-tag" key={allergy}>
              {allergy}
            </span>
          ))
        : "None"}
    </div>
    <div className="student-background-divider" />
    <p className="fw-900 medium-text mb-16">
      Additional Dietary Information and/or Severity of Allergies
    </p>
    <p>{dietaryNotes || "None"}</p>
  </div>
);
