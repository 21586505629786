import { EduActions } from "./actions";
import { AuthActions } from "../auth/actions";

export const EduState = {
  name: "edu",
  persist: false,
  defaults: {
    programs: {
      loading: false,
    },

    enrolledPrograms: {
      loading: false,
      data: [],
    },

    enrollment: {
      data: {
        eduProgramName: "",
      },
      loading: false,
    },
    submitEnrollment: {
      loading: false,
    },

    courses: {
      loading: false,
    },
    course: {
      loading: false,
    },
    courseSchedules: {
      loading: false,
    },
    courseSchedule: {
      loading: false,
    },
    submitCourseSchedule: {
      loading: false,
    },
    courseScheduleAttendance: {
      loading: false,
    },
    submitCourseScheduleAttendance: {
      loading: false,
    },

    students: {
      loading: false,
    },
    studentEnrollments: {
      loading: false,
    },
    studentEnrollmentStatuses: {
      data: {},
      loading: false,
      success: true,
    },
    studentEnrollment: {
      loading: false,
    },
    submitStudentEnrollment: {
      loading: false,
    },
    studentCourses: {
      loading: false,
    },
    studentRewards: {
      loading: false,
    },
  },
  handlers: {
    [EduActions.SET_EDU_PROGRAMS_LOADING]: (state, action) => {
      return {
        ...state,
        programs: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_EDU_PROGRAMS]: (state, action) => {
      return {
        ...state,
        programs: action.payload,
      };
    },

    [EduActions.SET_EDU_ENROLLED_PROGRAMS_LOADING]: (state, action) => {
      return {
        ...state,
        enrolledPrograms: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_EDU_ENROLLED_PROGRAMS]: (state, action) => {
      return {
        ...state,
        enrolledPrograms: action.payload,
      };
    },

    [EduActions.SET_EDU_ENROLLMENT_LOADING]: (state, action) => {
      return {
        ...state,
        enrollment: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_EDU_ENROLLMENT]: (state, action) => {
      return {
        ...state,
        enrollment: action.payload,
      };
    },

    [EduActions.SET_SUBMIT_EDU_ENROLLMENT_LOADING]: (state, action) => {
      return {
        ...state,
        submitEnrollment: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_SUBMIT_EDU_ENROLLMENT]: (state, action) => {
      return {
        ...state,
        submitEnrollment: action.payload,
      };
    },

    [EduActions.SET_EDU_COURSES_LOADING]: (state, action) => {
      return {
        ...state,
        courses: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_EDU_COURSES]: (state, action) => {
      return {
        ...state,
        courses: action.payload,
      };
    },

    [EduActions.SET_EDU_COURSE_LOADING]: (state, action) => {
      return {
        ...state,
        course: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_EDU_COURSE]: (state, action) => {
      return {
        ...state,
        course: action.payload,
      };
    },
    [EduActions.CLEAR_EDU_COURSE]: (state, action) => {
      return {
        ...state,
        course: EduState.defaults.course,
      };
    },

    [EduActions.SET_EDU_COURSE_SCHEDULES_LOADING]: (state, action) => {
      return {
        ...state,
        courseSchedules: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_EDU_COURSE_SCHEDULES]: (state, action) => {
      return {
        ...state,
        courseSchedules: action.payload,
      };
    },

    [EduActions.SET_EDU_COURSE_SCHEDULE_LOADING]: (state, action) => {
      return {
        ...state,
        courseSchedule: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_EDU_COURSE_SCHEDULE]: (state, action) => {
      return {
        ...state,
        courseSchedule: action.payload,
      };
    },
    [EduActions.CLEAR_EDU_COURSE_SCHEDULE]: (state, action) => {
      return {
        ...state,
        courseSchedule: EduState.defaults.courseSchedule,
      };
    },

    [EduActions.SET_SUBMIT_EDU_COURSE_SCHEDULE_LOADING]: (state, action) => {
      return {
        ...state,
        submitCourseSchedule: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_SUBMIT_EDU_COURSE_SCHEDULE]: (state, action) => {
      return {
        ...state,
        submitCourseSchedule: action.payload,
      };
    },

    [EduActions.SET_EDU_COURSE_SCHEDULE_ATTENDANCE_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        courseScheduleAttendance: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_EDU_COURSE_SCHEDULE_ATTENDANCE]: (state, action) => {
      return {
        ...state,
        courseScheduleAttendance: action.payload,
      };
    },

    [EduActions.SET_SUBMIT_EDU_COURSE_SCHEDULE_ATTENDANCE_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        submitCourseScheduleAttendance: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_SUBMIT_EDU_COURSE_SCHEDULE_ATTENDANCE]: (state, action) => {
      return {
        ...state,
        submitCourseScheduleAttendance: action.payload,
      };
    },

    [EduActions.SET_EDU_STUDENTS_LOADING]: (state, action) => {
      return {
        ...state,
        students: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_EDU_STUDENTS]: (state, action) => {
      return {
        ...state,
        students: action.payload,
      };
    },

    [EduActions.SET_EDU_STUDENT_ENROLLMENTS_LOADING]: (state, action) => {
      return {
        ...state,
        studentEnrollments: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_EDU_STUDENT_ENROLLMENTS]: (state, action) => {
      return {
        ...state,
        studentEnrollments: action.payload,
      };
    },

    [EduActions.SET_EDU_STUDENT_ENROLLMENT_LOADING]: (state, action) => {
      return {
        ...state,
        studentEnrollment: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_EDU_STUDENT_ENROLLMENT]: (state, action) => {
      return {
        ...state,
        studentEnrollment: action.payload,
      };
    },

    [EduActions.SET_EDU_STUDENT_ENROLLMENT_STATUSES_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        studentEnrollmentStatuses: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_EDU_STUDENT_ENROLLMENT_STATUSES]: (state, action) => {
      return {
        ...state,
        studentEnrollmentStatuses: action.payload,
      };
    },

    [EduActions.SET_SUBMIT_EDU_STUDENT_ENROLLMENT_LOADING]: (state, action) => {
      return {
        ...state,
        submitStudentEnrollment: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_SUBMIT_EDU_STUDENT_ENROLLMENT]: (state, action) => {
      return {
        ...state,
        submitStudentEnrollment: action.payload,
      };
    },

    [EduActions.SET_EDU_STUDENT_COURSES_LOADING]: (state, action) => {
      return {
        ...state,
        studentCourses: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_EDU_STUDENT_COURSES]: (state, action) => {
      return {
        ...state,
        studentCourses: action.payload,
      };
    },

    [EduActions.SET_EDU_STUDENT_REWARDS_LOADING]: (state, action) => {
      return {
        ...state,
        studentRewards: {
          loading: !!action.payload,
        },
      };
    },
    [EduActions.SET_EDU_STUDENT_REWARDS]: (state, action) => {
      return {
        ...state,
        studentRewards: action.payload,
      };
    },

    [AuthActions.DO_SIGNOUT]: (state, action) => {
      return EduState.defaults; //reset EDU enrollment on user signout
    },
  },
};
