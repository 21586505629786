export function getFieldOptionsForProgramType(
  programType,
  interactionDurations,
  interactionTypes,
) {
  const durationOptions = interactionDurations.map(
    ({ displayValue, enumValue }) => ({
      label: displayValue,
      value: enumValue,
    }),
  );

  const rsvpInteractionTypes = interactionTypes.filter(
    (t) => t.programType === programType,
  );
  const typeCategories = rsvpInteractionTypes
    .map((t) => t.category)
    .filter((v, i, arr) => arr.findIndex((c) => c.id === v.id) === i);
  const typeOptions = typeCategories.map((category) => ({
    label:
      category.name +
      (category.classification ? ` [${category.classification}]` : ""),
    options: rsvpInteractionTypes
      .filter((t) => t.categoryID === category.id)
      .map((type) => ({
        label: type.name,
        value: type.id,
      })),
  }));

  return { durationOptions, typeOptions };
}
