import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { UpdateActions } from "../../state/update/actions";
import UpdateDetails from "./UpdateDetails";

export default connect(
  function mapState(state) {
    return {
      loading: state.update.loading,
      postDetails: state.update.details,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators({ getPost: UpdateActions.getPost }, dispatch),
    };
  },
)(UpdateDetails);
