import React from "react";
import { Creatable } from "react-select";
export default class StudentGroupDemographics extends React.PureComponent {
  render() {
    const { selectedStudentGroups, studentGroupOptions } = this.props;
    return (
      <React.Fragment>
        <div className="profile-form-section">
          <p className="profile-section-title">Groups</p>
          <p className="mb-12 accent-text">
            Start typing to add student to a group.{" "}
          </p>
          <Creatable
            onChange={(vals) => {
              this.props.onChangeStudentGroups(
                "chabadHouseTags",
                vals.map((g) => ({ ...g, tagName: g.value })),
              );
            }}
            isMulti
            options={studentGroupOptions}
            value={selectedStudentGroups}
            name={"chabadHouseTags"}
          />
        </div>
      </React.Fragment>
    );
  }
}
