import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { SysActions } from "../state";

import Loader from "./Loader";

class ImageUpload extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      errorMessage: "",
      showError: "",
      loading: "",
    };

    this.dropzoneRef = React.createRef();
  }

  onDrop = async (acceptedFiles, rejectedFiles) => {
    this.setState({ loading: true, showError: false });
    const {
      actions: { uploadFile },
      imageType,
      name,
      onChange,
    } = this.props;
    if (acceptedFiles.length) {
      const fileURL = await uploadFile(acceptedFiles[0], imageType);
      onChange(name, fileURL);
      this.setState({ loading: false });
    }
  };

  onDropRejected = (files) => {
    if (files?.length > 0) {
      const errorMessage = files[0]?.errors?.map((e) => e.message).join(", ");
      this.setState({ errorMessage, showError: true });
    } else {
      this.setState({ showError: true });
    }
  };

  imageError = (touched, errors) => {
    return (
      touched &&
      touched.person &&
      touched.person.profileImageURL === true &&
      errors &&
      errors.missingImageURL === true
    );
  };

  render() {
    const {
      accept,
      className,
      disabled,
      errors,
      errorStyle = {},
      imageURL,
      label,
      maxSize,
      touched,
      required,
      uploadedLabel,
      hidePlaceholder,
    } = this.props;
    let error = this.imageError(touched, errors) && required;

    const { errorMessage, loading, showError } = this.state;
    let optionalProps = {};
    if (maxSize) {
      optionalProps.maxSize = maxSize;
    }

    return (
      <div
        className={`flex flex-align-center dropzone ${
          className ? className : ""
        }${disabled ? " disabled" : ""}`}
      >
        <Dropzone
          accept={
            accept || "image/png,image/jpeg,image/gif,image/bmp,image/jpg"
          }
          disabled={disabled}
          multiple={false}
          onDrop={this.onDrop}
          onDropRejected={this.onDropRejected}
          ref={this.dropzoneRef}
          {...optionalProps}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className="image-upload-container flex flex-align-center"
            >
              <input {...getInputProps()} />
              {loading && !hidePlaceholder ? (
                <div className="image-upload-loader mr-32">
                  <Loader />
                </div>
              ) : imageURL || !hidePlaceholder ? (
                <img
                  src={
                    imageURL
                      ? imageURL
                      : !hidePlaceholder
                      ? "/images/placeholder.svg"
                      : ""
                  }
                  alt=""
                />
              ) : (
                <div></div>
              )}
            </div>
          )}
        </Dropzone>
        {!imageURL ? (
          <div
            className={`link-text uppercase-text relative ${
              error ? "error" : ""
            }`}
            onClick={() => this.dropzoneRef.current.open()}
          >
            {label}
            <span
              className="error-message no-wrap"
              style={{
                left: "0",
                top: "16px",
                textTransform: "none",
                ...errorStyle,
              }}
            >
              {showError
                ? errorMessage || "Something went wrong, please try again"
                : ""}
            </span>
          </div>
        ) : (
          <div
            className={`link-text uppercase-text relative`}
            onClick={() => this.dropzoneRef.current.open()}
          >
            {uploadedLabel}
            <span
              className="error-message no-wrap"
              style={{
                left: "0",
                top: "16px",
                textTransform: "none",
                ...errorStyle,
              }}
            >
              {showError
                ? errorMessage || "Something went wrong, please try again"
                : ""}
            </span>
          </div>
        )}
      </div>
    );
  }
}
export default connect(null, function mapDispatch(dispatch) {
  return {
    actions: {
      ...bindActionCreators(SysActions, dispatch),
    },
  };
})(ImageUpload);
