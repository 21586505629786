const newArray = [];

export const TripSelectors = {
  registrations(state) {
    return state.trip.registrations?.results || newArray;
  },

  eventID(state) {
    return state.trip.event?.id || "";
  },

  enrollmentID(state) {
    return state.trip.enrollment?.id || "";
  },
};
