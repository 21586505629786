import React from "react";

import { PageLink } from "../../../lib";
import Pages from "../..";
import Loader from "../../../components/Loader";

export default class TripInfoCard extends React.PureComponent {
  state = {
    showTripInfo: true,
  };

  componentDidMount() {
    const { programID, programScheduleID } = this.props.trip.event;
    if (programID && programScheduleID) {
      this.props.getTripSchedule(programID, programScheduleID);
    }
    if (window.innerWidth < 560) {
      this.setState({ showTripInfo: false });
    }
  }

  toggleTripInfo = () => {
    this.setState({ showTripInfo: !this.state.showTripInfo });
  };

  render() {
    let {
      studentRegistrationPreviewUrl,
      trip: {
        schedule: {
          hebrewProgramStartDate,
          hebrewProgramEndDate,
          id: programScheduleID,
          loading,
          programStartDate,
          programEndDate,
          officeStaff,
          title,
        } = {},
        event: { loading: eventLoading, programID },
      },
    } = this.props;
    let { showTripInfo } = this.state;
    let tripStartDate = new Date(programStartDate).toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    });
    let tripEndDate = new Date(programEndDate).toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    });

    return (
      <div className="card trip-sidebar-card mt-32">
        {loading ? (
          <div className="medium-loader">
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            <div>
              <div className="flex flex-justify-space flex-align-center">
                <p className="medium-text fw-700 line-height-double">
                  {title} Info
                </p>
                <i
                  className="material-icons desktop-hidden pointer"
                  style={{ fontSize: "18px" }}
                  onClick={() => this.toggleTripInfo()}
                >
                  {showTripInfo ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                </i>
              </div>
              {showTripInfo && (
                <React.Fragment>
                  <div className="mt-24 mb-24">
                    <label className="mb-8">Dates</label>
                    {tripStartDate && tripEndDate ? (
                      <React.Fragment>
                        <p className="flex flex-align-center mb-8">
                          <i className="material-icons mr-8 large-text">
                            calendar_today
                          </i>
                          <span className="small-text">
                            {hebrewProgramStartDate} - {hebrewProgramEndDate}
                          </span>
                        </p>
                        <p className="flex flex-align-center">
                          <i className="material-icons mr-8 large-text">
                            calendar_today
                          </i>
                          <span className="small-text">
                            {tripStartDate} - {tripEndDate}
                          </span>
                        </p>
                      </React.Fragment>
                    ) : (
                      <p className="flex flex-align-center">
                        <i className="material-icons mr-8 large-text">
                          calendar_today
                        </i>
                        <span className="small-text">TBD</span>
                      </p>
                    )}
                  </div>
                  {officeStaff && (
                    <React.Fragment>
                      <div className="mb-8">
                        <label>Program Coordinator</label>
                        <p>{officeStaff.fullName}</p>
                      </div>
                      <div className="program-contact-info">
                        <p>
                          <i className="material-icons">phone</i>
                          <a
                            href={`tel:${officeStaff.primaryPhone}, ${officeStaff.primaryPhoneExt}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {officeStaff.primaryPhone}
                            {officeStaff.primaryPhoneExt && (
                              <span className="ml-8 small-text">
                                Ext: {officeStaff.primaryPhoneExt}
                              </span>
                            )}
                          </a>
                        </p>

                        <p>
                          <i className="material-icons">email</i>
                          <a
                            href={`mailto:${officeStaff.primaryEmail}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {officeStaff.primaryEmail}
                          </a>
                        </p>
                      </div>
                    </React.Fragment>
                  )}
                  {!eventLoading && (
                    <div className="program-external-link">
                      <p className="flex flex-align-center">
                        <i className="material-icons large-text accent-text mr-8">
                          open_in_new
                        </i>
                        <PageLink
                          to={Pages.programs.programDetails}
                          params={{ programId: programID && programID }}
                          query={{ sched: programScheduleID }}
                          className="link-text"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {title} Info
                        </PageLink>
                      </p>
                      {!!studentRegistrationPreviewUrl && (
                        <p className="flex flex-align-center mt-8">
                          <i className="material-icons large-text accent-text mr-8">
                            open_in_new
                          </i>
                          <a
                            className="link-text"
                            href={studentRegistrationPreviewUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Student registration preview
                          </a>
                        </p>
                      )}
                    </div>
                  )}
                  {/* TODO: add back in registered students when @ctevel has the data */}
                  {/* <p className="flex flex-justify-space small-text"><span className="fw-700">Registered Students</span></p> */}
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
