import React, { memo } from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import StudentProfileForm from "./StudentProfileForm.vm";

function StudentProfileModal(props) {
  return (
    <Dialog open={props.show} fullWidth maxWidth="md">
      <div className="flex flex-justify-end student-profile-modal-btn">
        <IconButton onClick={props.close}>
          <Close />
        </IconButton>
      </div>

      <DialogContent>
        <div key="studentProfileModal">
          <div className="student-profile-modal">
            <StudentProfileForm {...props} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default memo(StudentProfileModal);
