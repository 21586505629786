export const JewishInterestLevels = {
  New: "New",
  Uninterested: "Uninterested",
  Interested: "Interested",
  Connected: "Connected",
  Immersed: "Immersed",
  Other: "Other",
};
export const JewishnessStatuses = {
  Yes: "Yes",
  No: "No",
  Unknown: "Unknown",
};
export const JewishObservanceStatuses = {
  Absent: "Absent",
  Ethnic: "Ethnic",
  Cultural: "Cultural",
  Traditional: "Traditional",
  Observant: "Observant",
};

export type JewishInterestLevel = keyof typeof JewishInterestLevels;
export type JewishnessStatuses = keyof typeof JewishnessStatuses;
export type JewishObservanceStatuses = keyof typeof JewishObservanceStatuses;

export type JewishInterestOverTime = {
  isOnMonth: boolean;
  reason: string;
  reasonDisplay: string;
  studentCreatedOn: string;
  value: JewishInterestLevel;
  valueAsOfDate: string;
};
