import React from "react";
import Modal from "../../../components/Modal";

export default class EmailPreviewModal extends React.Component {
  render() {
    const { close, emailMessage, emailSignature, messageBorderColor, signatureBorderColor, show } =
      this.props;

    return (
      <Modal show={show}>
        <div className="modal-container full-page-mobile-modal-container">
          <div className="modal large-modal modal-card card">
            <div className="flex flex-align-center flex-justify-space mb-24">
              <div className="flex flex-align-center">
                <p className="xl-text fw-700 ml-4 mr-8">Email Preview</p>
                <p>
                  (customizable sections outlined below)
                </p>
              </div>
              <i
                className="material-icons link-text-secondary accent-text-dark xl-text"
                onClick={close}
              >
                close
              </i>
            </div>
            <div className="accent-text mt-16 line-height-double pre-wrap">
              <div style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                Dear {"{donor first name}"},{"\n\n"}
                Congratulations! You’re in the Grand Draw.{"\n\n"}
                Thank you for purchasing {"{tickets purchased}"} tickets to
                support Jewish life at {"{campus}"}.{"\n\n"}
              </div>
              <div
                style={{
                  border: `1px solid ${messageBorderColor}`,
                  borderRadius: 3,
                  padding: 4,
                  minHeight: 24,
                }}
              >
                {emailMessage}
              </div>
              <div style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                {"\n"}
                Order Number: {"\n"}
                Payment: {"\n"}
                Total Charged: {"\n\n"}
                The Grand Draw will be drawn on {"{drawing date}"} and the
                winners will be contacted via phone, email and/or postal mail.
                Results will be posted on {"{website url}"} on{" "}
                {"{results posting date}"}.{"\n\n"}
              </div>
              <div
                style={{
                  border: `1px solid ${signatureBorderColor}`,
                  borderRadius: 3,
                  padding: 4,
                  minHeight: 24,
                }}
              >
                {emailSignature}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
