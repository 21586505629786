import Protected from "./Protected";

const ExamplePages = {
  protected: {
    // anon: false, // Nothing to do...This is the default for the app already!
    path: "/example/protected",
    title: "Protected Page Example",
    type: "PAGE_PROTECTED_EXAMPLE",
    view: Protected,
  },
};
export default ExamplePages;

export const ExampleArea = {
  pages: ExamplePages,
};
