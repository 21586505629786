import React from "react";
import Loader from "../../../components/Loader";
import isEqual from "lodash.isequal";

export default class EnrollmentActions extends React.PureComponent {
  render() {
    const {
      enrollment,
      enrollment: { id: enrollmentId, status },
      enrollmentActionType,
      enrollmentActionTypes,
      enrollmentStatuses,
      errorMessage,
      initialEnrollment,
      loading,
      onCancel,
      onSubmit,
      showReplacePaymentMethod,
    } = this.props;

    return (
      <div className="flex flex-align-center flex-justify-space life-insurance-form-btns">
        {enrollmentActionType === enrollmentActionTypes.TerminationRequest &&
        loading ? (
          <div className="btn-large-loader mt-24">
            <Loader />
          </div>
        ) : (
          <div>
            {!enrollmentId || showReplacePaymentMethod ? (
              <button
                className="btn btn btn-large btn-light mt-32"
                disabled={
                  loading ||
                  (!showReplacePaymentMethod &&
                    isEqual(initialEnrollment, enrollment))
                }
                onClick={onCancel}
              >
                Cancel
              </button>
            ) : status === enrollmentStatuses.Active ||
              status === enrollmentStatuses.FailedRenewal ? (
              <button
                className="btn btn btn-large btn-light mt-32"
                onClick={() =>
                  onSubmit(enrollmentActionTypes.TerminationRequest)
                }
              >
                Request Termination
              </button>
            ) : (
              ""
            )}
            {enrollmentActionType ===
              enrollmentActionTypes.TerminationRequest &&
              errorMessage && (
                <span className="error-text">{errorMessage}</span>
              )}
          </div>
        )}
        {enrollmentActionType !== enrollmentActionTypes.TerminationRequest &&
        loading ? (
          <div className="btn-large-loader mt-24 mr-40">
            <Loader />
          </div>
        ) : (
          <div className="relative  mr-40">
            {!enrollmentId ? (
              <button
                className="btn btn-accent btn-large mt-32"
                onClick={() => onSubmit(enrollmentActionTypes.Enrollment)}
              >
                Enroll Now
              </button>
            ) : showReplacePaymentMethod ? (
              status === enrollmentStatuses.FailedRenewal ? (
                <button
                  className="btn btn-accent btn-large mt-32"
                  onClick={() =>
                    onSubmit(
                      enrollmentActionTypes.UpdatePaymentMethodAndProcess,
                    )
                  }
                >
                  Update & Process Card
                </button>
              ) : status !== enrollmentStatuses.Terminated ? (
                <button
                  className="btn btn-accent btn-large mt-32"
                  onClick={() =>
                    onSubmit(enrollmentActionTypes.UpdatePaymentMethod)
                  }
                >
                  Update Payment Method
                </button>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {enrollmentActionType !==
              enrollmentActionTypes.TerminationRequest &&
              errorMessage && (
                <span
                  className="error-text block small-text mt-8"
                  style={{
                    position: "absolute",
                    right: 0,
                    width: "92vw",
                    textAlign: "right",
                    lineHeight: 1.4,
                  }}
                >
                  {errorMessage}
                </span>
              )}
          </div>
        )}
      </div>
    );
  }
}
