import React, { memo, useState } from "react";
import { ListItem, ListItemText, makeStyles } from "@material-ui/core";
import StudentInteractionModal from "../../pages/students/activity/StudentInteractionModal.vm";
import StudentProfileModal from "../../pages/students/studentProfile/StudentProfileModal";
import { SubMenuProps } from "./types";
import { useSelector } from "react-redux";
import { AuthSelectors } from "../../state";

const useStyles = makeStyles((theme) => ({
  nestedItem: { paddingLeft: theme.spacing(12) },
  title: { paddingLeft: theme.spacing(8), paddingRight: theme.spacing(4) },
}));

function _ShortcutsSubMenu({ handleMenuClick }: SubMenuProps) {
  const classes = useStyles();
  const [showAddStudentModal, setShowAddStudentModal] = useState(false);
  const [showAddInteractionModal, setShowAddInteractionModal] = useState(false);
  const { isEnrolledInMessaging } = useSelector(AuthSelectors.engagementAccess);
  return (
    <>
      <ListItem
        className={classes.title}
        button
        onClick={() => {
          setShowAddStudentModal(true);
        }}
      >
        <ListItemText>Create new student</ListItemText>
      </ListItem>
      <ListItem
        className={classes.title}
        button
        onClick={() => {
          setShowAddInteractionModal(true);
        }}
      >
        <ListItemText>Create new interaction</ListItemText>
      </ListItem>
      <ListItem
        className={classes.title}
        button
        disabled={!isEnrolledInMessaging}
        onClick={() => handleMenuClick("/sms/wizard")}
      >
        <ListItemText>Create new SMS message</ListItemText>
      </ListItem>

      {showAddStudentModal && (
        <StudentProfileModal
          close={() => setShowAddStudentModal(false)}
          show={showAddStudentModal}
        />
      )}
      {showAddInteractionModal && (
        <StudentInteractionModal
          /* @ts-ignore */
          close={() => setShowAddInteractionModal(false)}
          show={showAddInteractionModal}
        />
      )}
    </>
  );
}

export const ShortcutsSubMenu = memo(_ShortcutsSubMenu);
