import React from "react";

export default ({ scheduleName }) => (
  <div className="flex flex-align-center flex-justify-center full-page-state">
    <img src="/images/lamplighters-logo.jpg" alt="" height="100" />
    <p className="fw-700 xxl-text text-center mt-32 mb-40">
      You do not have access to {scheduleName || "Lamplighters"}
    </p>
    <p className="accent-text large-text">
      Contact your administrator to request access
    </p>
  </div>
);
