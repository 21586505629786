import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ProgramsActions } from "../../state/programs/actions";
import Programs from "./Programs";

export default connect(
  function mapState(state) {
    return {
      programs: state.programs,
      sys: state.sys,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          getPrograms: ProgramsActions.getPrograms,
          searchPrograms: ProgramsActions.searchPrograms,
        },
        dispatch,
      ),
    };
  },
)(Programs);
