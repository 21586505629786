import React from "react";
import Attendees from "./Attendees";
import BusTransportation from "./BusTransportation";
import HotelStay from "./HotelStay";

export default class RegistrationInfo extends React.PureComponent {
  render() {
    const {
      attendance: { childrenAttending, parentsAttending },
      formProps,
      editProfile,
      profile,
      setOnIndexWizard,
      settings: { ageGrades, eventSettings, hotelNights, pricing, summerCamps },
      validationHeader,
    } = this.props;

    return (
      <React.Fragment>
        <div className="kinus-page-form">
          <p className="xxl-text fw-500">
            Registration Info
            {validationHeader && validationHeader}
          </p>

          <Attendees
            formProps={formProps}
            ageGrades={ageGrades}
            applyForChildCarePositionURL={
              eventSettings.applyForChildCarePositionURL
            }
            childrenAttending={childrenAttending}
            editProfile={editProfile}
            hebrewYear={eventSettings.hebrewYear}
            kinusStartDate={eventSettings.startDate}
            maxBoyAge={eventSettings.maxBoyAge}
            maxGirlAge={eventSettings.maxGirlAge}
            parentsAttending={parentsAttending}
            profile={profile}
            setOnIndexWizard={setOnIndexWizard}
            summerCamps={summerCamps}
          />

          <HotelStay
            formProps={formProps}
            childrenAttending={childrenAttending}
            hotelNights={hotelNights}
            kinusEndDate={eventSettings.endDate}
            kinusStartDate={eventSettings.startDate}
            multipleRoomsThreshold={eventSettings.multipleRoomsThreshold}
            pricing={pricing}
          />

          <BusTransportation
            formProps={formProps}
            busLeavingCrownHeightsDate={
              eventSettings.busLeavingCrownHeightsDate
            }
            busLeavingCrownHeightsTime={
              eventSettings.busLeavingCrownHeightsTime
            }
            busLeavingKinusDate={eventSettings.busLeavingKinusDate}
            busLeavingKinusTime={eventSettings.busLeavingKinusTime}
            childrenAttending={childrenAttending}
            parentsAttending={parentsAttending}
            pricing={pricing}
          />
        </div>
      </React.Fragment>
    );
  }
}
