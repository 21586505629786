import React from "react";

export default function RsvpItemNotFound({ rsvpType }) {
  return (
    <div className="flex flex-align-center flex-justify-center full-page-state">
      <p className="fw-700 xxl-text mt-32 mb-40">
        Sorry, this {rsvpType} does not exist
      </p>
      <div className="accent-text large-text">
        Select an active {rsvpType} from the dropdown above
      </div>
    </div>
  );
}
