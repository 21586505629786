import { AuthRequest, ApiCallErrorMessageHandler } from "../../lib";
import { ErrorResponse } from "../types";
import { BasicClaimApi } from "./types";

export async function getAllInternClaims() {
  try {
    const response = await AuthRequest.get("Interns/claims");
    return response.data.payload;
  } catch (err) {
    return {
      error: err,
      errorMessage: ApiCallErrorMessageHandler(err),
    };
  }
}

export async function getClaimsForIntern(chabadHousePersonnelId: number) {
  try {
    const response = await AuthRequest.get(
      `Interns/${chabadHousePersonnelId}/claims`,
    );
    return response.data.payload as BasicClaimApi[];
  } catch (err) {
    return {
      error: err,
      errorMessage: ApiCallErrorMessageHandler(err),
    } as ErrorResponse;
  }
}

export async function updateClaimsForIntern(
  chabadHousePersonnelId: number,
  internPermissions: BasicClaimApi[],
) {
  try {
    const response = await AuthRequest.post(
      `Interns/${chabadHousePersonnelId}/claims`,
      internPermissions,
    );
    return response;
  } catch (err) {
    return {
      error: err,
      errorMessage: ApiCallErrorMessageHandler(err),
    };
  }
}
