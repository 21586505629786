import React, { memo, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { AuthSelectors } from "../../../../../state";
import Attendance from "./manageCourseSchedule/Attendance";
import CourseScheduleTabs from "./manageCourseSchedule/CourseScheduleTabs";
import CourseSchedulesDropdown from "./manageCourseSchedule/CourseSchedulesDropdown";
import ErrorDisplay from "../../../../../components/ErrorDisplay";
import Loader from "../../../../../components/Loader";
import Schedule from "./manageCourseSchedule/Schedule";
import Students from "./manageCourseSchedule/Students";
import CourseNotFound from "./CourseNotFound";
import { InternPermissions, IsApiErrorStatus } from "../../../../../lib";
import { getEduProgramLogoSrc } from "../../../eduUtils";

const tabs = [
  {
    component: Schedule,
    id: "schedule",
    title: "Schedule",
    internPermission: InternPermissions.eduSchedule,
  },
  {
    component: Students,
    id: "students",
    title: "Students",
    internPermission: InternPermissions.eduStudents,
  },
  {
    component: Attendance,
    id: "attendance",
    title: "Attendance",
    internPermission: InternPermissions.eduAttendance,
  },
];

function ManageCourseSchedule(props) {
  const {
    actions: { getEduCourseSchedules },
    enrollment: {
      data: { eduProgramType, eduProgramOrderBooksURL },
    },
    course: {
      data: { resourceURL },
    },
    courseSchedule: { data: courseSchedule, error, errorMessage, loading },
    courseSchedules,
    getCourseSchedule,
    pageRoute,
  } = props;

  const {
    params: { courseScheduleId },
    query: { tab },
  } = pageRoute;

  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);
  const userClaims = useSelector(AuthSelectors.claims);

  const allowedTabs = useMemo(() => {
    if (isShliach) return tabs;
    return tabs.filter((t) => userClaims.includes(t.internPermission));
  }, [isShliach, userClaims]);

  useEffect(() => {
    //scroll to top of page on mount for when navigating from low scroll position within courses list
    if (window.scrollY > 0) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(
    () => {
      getCourseSchedule();
    },
    // getCourseSchedule() is passed in from a class component, so it is not properly memoized
    // therefore we need to specify exactly when it should be called: only when the courseScheduleId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [courseScheduleId],
  );

  const currentTab = useMemo(
    () => allowedTabs.find((t) => t.id === tab),
    [allowedTabs, tab],
  );

  return (
    <div className="flex tablet-block mobile-block flex-align-top">
      <div className="mr-24 tablet-mr-0 mobile-mr-0">
        <div className="card edu-sidebar-card mobile-flat-card mb-32">
          <CourseSchedulesDropdown
            getCourseSchedules={getEduCourseSchedules}
            courseSchedules={courseSchedules}
            pageRoute={pageRoute}
          />
        </div>

        {courseSchedule && (
          <CourseScheduleTabs
            orderBooksURL={eduProgramOrderBooksURL}
            resourceURL={resourceURL}
            pageRoute={pageRoute}
            tabs={allowedTabs}
          />
        )}
      </div>
      {loading ? (
        <div className="full-page-loader full-width">
          <Loader />
        </div>
      ) : error ? (
        IsApiErrorStatus(error, 404) ? ( //deactivated course schedule
          <CourseNotFound
            display={
              <div className="accent-text large-text">
                Select an active schedule from the dropdown above
              </div>
            }
            logoSrc={getEduProgramLogoSrc(eduProgramType)}
            title="Sorry, this course schedule is no longer active"
          />
        ) : (
          <ErrorDisplay
            errorMessage={
              errorMessage ||
              "Something went wrong and we could not retrieve course information. Please try again."
            }
          />
        )
      ) : courseSchedule && currentTab ? (
        <div className="edu-content-card">
          {React.createElement(currentTab.component, props)}
        </div>
      ) : null}
    </div>
  );
}

export default memo(ManageCourseSchedule);
