import { ProgramScheduleActions } from "./actions";

export const ProgramScheduleState = {
  name: "programSchedule",
  persist: false,
  defaults: {
    loading: false,
  },
  handlers: {
    [ProgramScheduleActions.SET_PROGRAM_SCHEDULE_LOADING]:
      function setProgramScheduleLoading(state, action) {
        return {
          ...state,
          loading: !!action.payload,
        };
      },
    [ProgramScheduleActions.UPDATE_PROGRAM_SCHEDULE]:
      function updateProgramSchedule(state, action) {
        return {
          ...state,
          ...action.payload,
        };
      },
  },
};
