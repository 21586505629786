import React from "react";
import { Checkbox } from "@material-ui/core";
import Toggle from "../../../../components/form/Toggle";
import {
  DEFAULT_REGISTERED_STUDENTS_REMINDER_MINUTES,
  DEFAULT_UNREGISTERED_STUDENTS_REMINDER_MINUTES,
} from "../../RsvpHelpers";
import ReminderTimeSelect from "./ReminderTimeSelect";

export default function DefaultReminders({
  globalDefaultHasReminders,
  onChangeEnrollment,
  globalDefaultRegisteredStudentsReminderMinutes,
  globalDefaultUnregisteredStudentsReminderMinutes,
  onSetHasReminders,
  error,
}) {
  return (
    <div className="program-form-section mb-16 mt-16 separatorTop">
      <p className="medium-text fw-700 mb-8">Default Reminders</p>
      <p className="accent-text-dark line-height-double">
        Choose default reminder recipients and times for all events. You can
        override these defaults on each event setting page. Note: Reminders will
        be sent within 15 minutes of the selected time.
      </p>
      <p className="accent-text-dark mt-8 mb-24 line-height-double small-text italic-text">
        If edited, reminders for upcoming events that are set to use the default
        reminder settings may be resent.
      </p>
      <div className="mb-16">
        <Toggle
          options={[
            {
              value: true,
              display: "Create default reminders",
            },
            {
              value: false,
              display: "Set reminders per event",
            },
          ]}
          name="globalDefaultHasReminders"
          onChange={(_, value) => {
            onSetHasReminders(value);
          }}
          value={!!globalDefaultHasReminders}
        />
      </div>
      {globalDefaultHasReminders ? (
        <div>
          {error &&
            !globalDefaultRegisteredStudentsReminderMinutes &&
            !globalDefaultUnregisteredStudentsReminderMinutes && (
              //I'm checking the values again here so that this error can disapear when a value is set (after validation ran)
              <span className="accent-text small-text error-text block mt-4">
                You must select a reminder time.
              </span>
            )}
          <div className="flex flex-align-center">
            <Checkbox
              checked={!!globalDefaultRegisteredStudentsReminderMinutes}
              onChange={(_, value) => {
                onChangeEnrollment(
                  "globalDefaultRegisteredStudentsReminderMinutes",
                  value ? DEFAULT_REGISTERED_STUDENTS_REMINDER_MINUTES : "",
                );
              }}
            />
            <ReminderTimeSelect
              isRegistered
              setReminderMinutes={onChangeEnrollment}
              fieldName="globalDefaultRegisteredStudentsReminderMinutes"
              fieldValue={globalDefaultRegisteredStudentsReminderMinutes}
            />
          </div>
          <div className="flex flex-align-center">
            <Checkbox
              checked={globalDefaultUnregisteredStudentsReminderMinutes}
              onChange={(_, value) =>
                onChangeEnrollment(
                  "globalDefaultUnregisteredStudentsReminderMinutes",
                  value ? DEFAULT_UNREGISTERED_STUDENTS_REMINDER_MINUTES : "",
                )
              }
            />
            <ReminderTimeSelect
              isRegistered={false}
              setReminderMinutes={onChangeEnrollment}
              fieldName="globalDefaultUnregisteredStudentsReminderMinutes"
              fieldValue={globalDefaultUnregisteredStudentsReminderMinutes}
            />
          </div>
          <p className="flex flex-align-center accent-text small-text mt-8">
            <i className="material-icons ml-32 mr-4 xl-text">info</i>
            This includes all Jewish students and alumni across all grad years
            who are registered with your Chabad House, but are not registered
            for the event.
          </p>
        </div>
      ) : (
        <div className="accent-text">
          By default, no event reminders will go out until you set them up on
          specific events.
        </div>
      )}
    </div>
  );
}
