import _isEqual from "lodash.isequal";
import _cloneDeep from "lodash.clonedeep";
import { removeEmptyFromObj } from "../../lib";

export function getChildGrade(ageAtKinus) {
  const { ageGrades } = this.props.settings;

  const ageGrade = ageGrades.find((ag) => ag.ageYears === ageAtKinus);
  return ageGrade ? ageGrade.grade : "";
}

export function getSummerCamp(campName, gender) {
  const { summerCamps } = this.props.settings;

  return summerCamps.find(
    (camp) => camp.name === campName && camp.gender === gender,
  );
}

export function childCannotAttendKinus(child) {
  const {
    eventSettings: { startDate, maxBoyAge, maxGirlAge },
  } = this.props.settings;

  const summerCamp = getSummerCamp.bind(this)(
    child.childCare.campName,
    child.person.gender,
  );

  return (
    (summerCamp && new Date(summerCamp.startDate) < new Date(startDate)) ||
    (child.person.gender === "Female" &&
      (child.ageAtKinus > maxGirlAge ||
        child.childCare.grade === "Grade 7" ||
        child.childCare.grade === "Grade 8")) ||
    (child.person.gender === "Male" && child.ageAtKinus > maxBoyAge)
  );
}

export function childIsAttending(child, functionState) {
  //functionState - { formValues }
  const {
    formValues: {
      attendees: { childrenPersonIDs },
    },
  } = functionState || this.state;

  return childrenPersonIDs.indexOf(child.person.id) >= 0;
}

export function canGetNightBabysitting(functionState) {
  const {
    profile: { children },
  } = this.props;

  return (
    children.filter(
      (child) =>
        childIsAttending.bind(this)(child, functionState) &&
        child.isEligibleForNightBabysitting,
    ).length > 0
  );
}

export function isGettingNightBabysitting(functionState) {
  //functionState - { formValues }
  const {
    formValues: { childCare },
  } = functionState || this.state;

  return childCare.nightBabysitting;
}

export function isGettingChildcare(functionState) {
  //functionState - { formValues }
  const {
    formValues: { childCare },
  } = functionState || this.state;
  const {
    profile: { children },
  } = this.props;

  return (
    childCare.children.filter((childCare) => {
      const child = children.find((c) => c.id === childCare.childID);
      return (
        childIsAttending.bind(this)(child, functionState) &&
        child.isEligibleForChildCare && //eligible
        (childCare.ageGroupId === 2 ? childCare.needsKinusChildcare : true)
      ); //did not opt out of care for 4-24m age group
    }).length > 0
  );
}

export function isBringingOwnBabysitter(functionState) {
  //functionState - { formValues }
  const {
    formValues: { childCare },
  } = functionState || this.state;
  const {
    profile: { children },
  } = this.props;

  return (
    childCare.children.filter((childCare) => {
      const child = children.find((c) => c.id === childCare.childID);
      return (
        childIsAttending.bind(this)(child, functionState) &&
        childCare.ageGroupID === 2 &&
        childCare.needsKinusChildcare === false
      ); //opted out of childcare
    }).length > 0
  );
}

export function getAttendeeRegistrationUpdates(formValues, previousAttendees) {
  const {
    settings: {
      eventSettings: { multipleRoomsThreshold },
    },
  } = this.props;

  const {
    attendees,
    hotelStay,
    toKinusBussing,
    fromKinusBussing,
    ...remainingFormValues
  } = _cloneDeep(formValues);

  const previousChildCount = previousAttendees.childrenPersonIDs.length;
  const updatedChildCount = attendees.childrenPersonIDs.length;
  const previousParentCount =
    (previousAttendees.husbandPersonID ? 1 : 0) +
    (previousAttendees.wifePersonID ? 1 : 0);
  const updatedParentCount =
    (attendees.husbandPersonID ? 1 : 0) + (attendees.wifePersonID ? 1 : 0);

  if (updatedChildCount !== previousChildCount) {
    //hotel rooms
    if (
      updatedChildCount < multipleRoomsThreshold &&
      previousChildCount >= multipleRoomsThreshold &&
      hotelStay.numberOfRooms === 2
    ) {
      hotelStay.numberOfRooms = 1;
      hotelStay.connectingRooms = false;

      const tooManyExtras = hotelStay.extras.filter(
        (extra) => extra.numberOfExtras > 1,
      );
      if (tooManyExtras.length) {
        hotelStay.extras.forEach((extra) => {
          if (extra.numberOfExtras > 1) {
            extra.numberOfExtras = 1;
          }
        });
      }
    }

    //bussing
    if (toKinusBussing.numberOfChildren > updatedChildCount) {
      toKinusBussing.numberOfChildren = updatedChildCount;
    }
    if (fromKinusBussing.numberOfChildren > updatedChildCount) {
      fromKinusBussing.numberOfChildren = updatedChildCount;
    }
  }

  if (updatedParentCount !== previousParentCount) {
    //bussing
    if (toKinusBussing.numberOfAdults > updatedParentCount) {
      toKinusBussing.numberOfAdults = updatedParentCount;
    }
    if (fromKinusBussing.numberOfAdults > updatedParentCount) {
      fromKinusBussing.numberOfAdults = updatedParentCount;
    }
  }

  return {
    attendees,
    hotelStay,
    toKinusBussing,
    fromKinusBussing,
    ...remainingFormValues,
  };
}

export function getAttendeeUpdates(
  formValues,
  previousAttendees,
  getRegistrationUpdates,
) {
  const {
    profile: { children },
  } = this.props;

  const { attendees, seating, childCare, ...remainingFormValues } =
    getRegistrationUpdates
      ? getAttendeeRegistrationUpdates.bind(this)(formValues, previousAttendees)
      : _cloneDeep(formValues);

  const previousChildCount = previousAttendees.childrenPersonIDs.length;
  const updatedChildCount = attendees.childrenPersonIDs.length;
  const previousParentCount =
    (previousAttendees.husbandPersonID ? 1 : 0) +
    (previousAttendees.wifePersonID ? 1 : 0);
  const updatedParentCount =
    (attendees.husbandPersonID ? 1 : 0) + (attendees.wifePersonID ? 1 : 0);

  if (updatedParentCount === 1 || !updatedChildCount) {
    //handle default seating pref if one attending parent or no attending children
    seating.seatingPreference = "Separate";
    seating.separateSeatingChildPreferences = attendees.childrenPersonIDs
      .map((childPersonId) =>
        children.find((c) => c.person.id === childPersonId),
      )
      .filter((child) => child && child.isEligibleForSeating)
      .map((child) => ({
        childID: child.id,
        parent: attendees.husbandPersonID
          ? "Father"
          : attendees.wifePersonID
          ? "Mother"
          : "",
      }));
  } else if (previousParentCount === 1 || !previousChildCount) {
    //remove seating pref default if was applicable and no longer applies
    seating.seatingPreference = null;
    seating.separateSeatingChildPreferences = [];
  } else if (seating.seatingPreference === "Separate") {
    //filter/add to child seating prefs for separate seating
    seating.separateSeatingChildPreferences = attendees.childrenPersonIDs
      .map((childPersonId) =>
        children.find((c) => c.person.id === childPersonId),
      )
      .filter((child) => child && child.isEligibleForSeating)
      .map(
        (child) =>
          seating.separateSeatingChildPreferences.find(
            (seatingPref) => seatingPref.childID === child.id,
          ) || {
            childID: child.id,
            parent: "",
          },
      );
  }

  //childcare
  if (
    childCare.nightBabysitting !== null &&
    !canGetNightBabysitting.bind(this)({
      formValues: { attendees, childCare }, //send in relevant updated formValues
    })
  ) {
    childCare.nightBabysitting = null;
  }

  if (!attendees.husbandPersonID && childCare.fathersCell) {
    childCare.fathersCell = "";
  }
  if (!attendees.wifePersonID && childCare.motherCell) {
    childCare.motherCell = "";
  }

  // [ not updating childcare children in order to preserve values if re-toggle,
  //  and to preserve camp and grade. filtering before submission. ]

  //waiver
  // [ not updating, in order to preserve values if re-toggle,
  //      filtering before submission and before mounting confirm page. ]

  return {
    attendees,
    seating,
    childCare,
    ...remainingFormValues,
  };
}

export function getWaiverUpdates(functionState) {
  //functionState - { formValues }
  const {
    formValues,
    formValues: { waiver },
  } = _cloneDeep(functionState || this.state);

  if (
    waiver.didAcceptNightBabysittingTerms &&
    !isGettingNightBabysitting.bind(this)({ formValues })
  ) {
    waiver.didAcceptNightBabysittingTerms = false;
  }

  if (
    waiver.didAcceptChildcareTerms &&
    !isGettingChildcare.bind(this)({ formValues })
  ) {
    waiver.didAcceptChildcareTerms = false;
  }

  if (
    waiver.didAcceptBringYourOwnBabysitter &&
    !isBringingOwnBabysitter.bind(this)({ formValues })
  ) {
    waiver.didAcceptBringYourOwnBabysitter = false;
  }

  return waiver;
}

export function getFormAfterProfileUpdate(previousProfile, previousFormValues) {
  const {
    profile: { children, credCardInfo, husband, wife },
    settings: { ageGrades, summerCamps },
  } = this.props;

  let formValues = _cloneDeep(previousFormValues);

  //upate attendees
  let didUpdateAttendees = false;

  if (
    !husband &&
    previousProfile.husband &&
    formValues.attendees.husbandPersonID
  ) {
    formValues.attendees.husbandPersonID = 0;
    didUpdateAttendees = true;
  }
  if (!wife && previousProfile.wife && formValues.attendees.wifePersonID) {
    formValues.attendees.wifePersonID = 0;
    didUpdateAttendees = true;
  }

  if (!_isEqual(children, previousProfile.children)) {
    //handle new & updated children
    children.forEach((child) => {
      const previousChildInfo = previousProfile.children.find(
        (c) => c.id === child.id,
      );

      if (!previousChildInfo) {
        // new child
        // add childcare record
        formValues.childCare.children.push({
          childID: child.id,
          grade: getChildGrade.bind(this)(child.ageAtKinus),
          campName: "",
          transportationToCamp: null,
          canJoinOffsite: false,
          medicalNotes: "",
          generalComments: "",
          toiletTraining: null,
          ageGroupID: child.kinusAgeGroupID,
          needsKinusChildcare: null,
          tShirtSize: null,
          childMovesBy: null,
        });
      } else {
        const childCareRecordIndex = formValues.childCare.children.findIndex(
          (c) => c.childID === child.id,
        );
        const childCareRecord =
          formValues.childCare.children[childCareRecordIndex];

        if (child.person.gender !== previousChildInfo.gender) {
          //update summer camp
          if (
            childCareRecord.campName &&
            summerCamps.find((camp) => camp.name === childCareRecord.campName)
              .gender !== child.person.gender
          ) {
            formValues.childCare.children[childCareRecordIndex].campName = "";
            formValues.childCare.children[
              childCareRecordIndex
            ].transportationToCamp = null;
          }
        }

        if (child.ageInMonthsAtKinus !== previousChildInfo.ageInMonthsAtKinus) {
          //update grade
          childCareRecord.grade = getChildGrade.bind(this)(child.ageAtKinus);
        }

        if (child.kinusAgeGroupID !== previousChildInfo.kinusAgeGroupID) {
          //reset childcare record
          formValues.childCare.children[childCareRecordIndex] = {
            childID: child.id,
            grade:
              (ageGrades.find((ag) => ag.ageYears === child.ageAtKinus) || {})
                .grade || "",
            campName: childCareRecord.campName,
            transportationToCamp: null,
            canJoinOffsite: false,
            medicalNotes: childCareRecord.medicalNotes,
            generalComments: childCareRecord.generalComments,
            toiletTraining: null,
            ageGroupID: child.kinusAgeGroupID,
            needsKinusChildcare: null,
            tShirtSize: null,
            childMovesBy: null,
          };
        }
      }
    });

    //update attendees
    const updatedAttendingChildrenPersonIDs =
      formValues.attendees.childrenPersonIDs.filter((childPersonId) => {
        const child = children.find((c) => c.person.id === childPersonId); //child still exists
        if (child) {
          child.childCare = formValues.childCare.children.find(
            (c) => c.childID === child.id,
          );
        }
        return child && !childCannotAttendKinus.bind(this)(child); //child is still eligible to attend kinus
      });

    if (
      updatedAttendingChildrenPersonIDs.length <
      formValues.attendees.childrenPersonIDs.length
    ) {
      //changed attending children (in other words, removed - bec dont add new children as attendees)
      formValues.attendees.childrenPersonIDs =
        updatedAttendingChildrenPersonIDs;
      didUpdateAttendees = true;

      //update childcare children to exclude removed children (already added records for new ones)
      formValues.childCare.children = formValues.childCare.children.filter(
        (childCare) =>
          children.findIndex((child) => child.id === childCare.childID) >= 0,
      );
    }

    //update waivers
    formValues.waiver = getWaiverUpdates.bind(this)({ formValues });
  }

  if (didUpdateAttendees) {
    //get registration, seating, childcare updates for attendee updates
    formValues = getAttendeeUpdates.bind(this)(
      formValues,
      previousFormValues.attendees,
      true,
    );
  }

  //update billing and invoice
  if (formValues.billing.useCardOnFile && !credCardInfo) {
    formValues.billing.useCardOnFile = false;
  }

  formValues.invoice.lineItems = getInvoiceLineItems.bind(this)({ formValues });

  return formValues;
}

export function getWizardStepsAfterProfileUpdate(previousWizardSteps) {
  const { wizardSteps } = this.state;

  return wizardSteps.map((step) => {
    const prevStep = previousWizardSteps.find((ws) => ws.name === step.name);
    return {
      ...step,
      touched: prevStep ? prevStep.touched : false,
    };
  });
}

export function getWizardIndexAfterProfileUpdate(previousWizardIndex) {
  const {
    formValues: {
      attendees: { childrenPersonIDs },
    },
  } = this.state;

  const childrenAttending = childrenPersonIDs.length;

  if (previousWizardIndex === 2 && !childrenAttending) {
    return previousWizardIndex + 1;
  }

  return previousWizardIndex;
}

export function getInvoiceLineItems(functionState) {
  //functionState - { formValues }
  const {
    profile,
    settings: {
      eventSettings: { endDate, minimumMonthsForRegistrationFee, startDate },
      pricing,
    },
  } = this.props;

  const {
    formValues: {
      attendees,
      hotelStay,
      toKinusBussing,
      fromKinusBussing,
      childCare,
    },
  } = functionState || this.state;

  const lineItems = [];

  //registration fees
  const childrenCount = attendees.childrenPersonIDs.filter(
    (id) =>
      profile.children.find((c) => c.person.id === id).ageInMonthsAtKinus >=
      minimumMonthsForRegistrationFee,
  ).length;
  const parentsCount =
    (attendees.husbandPersonID ? 1 : 0) + (attendees.wifePersonID ? 1 : 0);
  if (parentsCount) {
    const adultRegistrationPrice = pricing.find(
      (price) => price.priceCode === "AdultRegistration",
    );
    lineItems.push({
      ...adultRegistrationPrice,
      quantity: parentsCount,
    });
  }
  if (childrenCount) {
    const childRegistrationPrice = pricing.find(
      (price) => price.priceCode === "ChildRegistration",
    );
    lineItems.push({
      ...childRegistrationPrice,
      quantity: childrenCount,
    });
  }

  //hotel stay
  if (hotelStay.numberOfRooms) {
    const kinusNights = hotelStay.nights.filter(
      (date) =>
        new Date(date) >= new Date(startDate) &&
        new Date(date) < new Date(endDate),
    );
    const extraNights = hotelStay.nights.filter(
      (date) =>
        new Date(date) < new Date(startDate) ||
        new Date(date) >= new Date(endDate),
    );
    if (kinusNights.length) {
      const hotelRoomNightsPrice = pricing.find(
        (price) => price.priceCode === "HotelRoom3Nights",
      );
      lineItems.push({
        ...hotelRoomNightsPrice,
        quantity: 1,
      });

      if (hotelStay.numberOfRooms > 1) {
        const additionalHotelRoomNightsPrice = pricing.find(
          (price) => price.priceCode === "AdditionalHotelRoom3Nights",
        );
        lineItems.push({
          ...additionalHotelRoomNightsPrice,
          quantity: 1,
        });
      }
    }
    if (extraNights.length) {
      const extraHotelRoomNightPrice = pricing.find(
        (price) => price.priceCode === "SingleExtraNightInHotel",
      );
      lineItems.push({
        ...extraHotelRoomNightPrice,
        quantity: extraNights.length * parseInt(hotelStay.numberOfRooms, 10),
      });
    }

    const hotelExtras = hotelStay.extras.filter(
      (extra) => extra.numberOfExtras > 0,
    );
    if (hotelExtras.length) {
      hotelExtras.forEach((extra) => {
        const hotelExtraPrice = pricing.find(
          (price) => price.itemCode === extra.hotelExtras,
        );
        if (hotelExtraPrice) {
          lineItems.push({
            ...hotelExtraPrice,
            quantity: extra.numberOfExtras,
          });
        }
      });
    }
  }

  //bussing
  const busTransportation =
    toKinusBussing.numberOfAdults ||
    toKinusBussing.numberOfChildren ||
    fromKinusBussing.numberOfChildren ||
    fromKinusBussing.numberOfChildren;
  if (busTransportation) {
    const adultBussingPrice = pricing.find(
      (price) => price.priceCode === "AdultBussing",
    );
    const childBussingPrice = pricing.find(
      (price) => price.priceCode === "ChildBussing",
    );

    const priceByIndivsTo =
      toKinusBussing.numberOfAdults * adultBussingPrice.subsidizedCost +
      toKinusBussing.numberOfChildren * childBussingPrice.subsidizedCost;
    const priceByIndivsFrom =
      fromKinusBussing.numberOfAdults * adultBussingPrice.subsidizedCost +
      fromKinusBussing.numberOfChildren * childBussingPrice.subsidizedCost;

    const familyBussingOneWayPrice = pricing.find(
      (price) => price.priceCode === "FamilyBussingOneWay",
    );
    const familyBussingTwoWaysPrice = pricing.find(
      (price) => price.priceCode === "FamilyBussingTwoWays",
    );

    const bussingLineItems = [];
    let bussingTotal = 0;
    let adultBussingLineItemQuantity = 0;
    let childBussingLineItemQuantity = 0;
    let familyBussingOneWayLineItemQuantity = 0;

    if (priceByIndivsTo < familyBussingOneWayPrice.subsidizedCost) {
      bussingTotal += priceByIndivsTo;

      adultBussingLineItemQuantity += toKinusBussing.numberOfAdults;
      childBussingLineItemQuantity += toKinusBussing.numberOfChildren;
    } else {
      bussingTotal += familyBussingOneWayPrice.subsidizedCost;
      familyBussingOneWayLineItemQuantity++;
    }

    if (priceByIndivsFrom < familyBussingOneWayPrice.subsidizedCost) {
      bussingTotal += priceByIndivsFrom;

      adultBussingLineItemQuantity += fromKinusBussing.numberOfAdults;
      childBussingLineItemQuantity += fromKinusBussing.numberOfChildren;
    } else {
      bussingTotal += familyBussingOneWayPrice.subsidizedCost;
      familyBussingOneWayLineItemQuantity++;
    }

    if (familyBussingTwoWaysPrice.subsidizedCost < bussingTotal) {
      bussingTotal = familyBussingTwoWaysPrice;
      bussingLineItems.push({
        ...familyBussingTwoWaysPrice,
        quantity: 1,
      });
    } else {
      if (adultBussingLineItemQuantity) {
        bussingLineItems.push({
          ...adultBussingPrice,
          quantity: adultBussingLineItemQuantity,
        });
      }
      if (childBussingLineItemQuantity) {
        bussingLineItems.push({
          ...childBussingPrice,
          quantity: childBussingLineItemQuantity,
        });
      }
      if (familyBussingOneWayLineItemQuantity) {
        bussingLineItems.push({
          ...familyBussingOneWayPrice,
          quantity: familyBussingOneWayLineItemQuantity,
        });
      }
    }

    lineItems.push(...bussingLineItems);
  }

  //childcare
  if (childCare.nightBabysitting) {
    const nightBabysittingPrice = pricing.find(
      (price) => price.priceCode === "NightSitting",
    );
    lineItems.push({
      ...nightBabysittingPrice,
      quantity: 1,
    });
  }

  return lineItems;
}

export function getFormValidation() {
  const {
    formValues: { attendees, hotelStay, seating, childCare, waiver, billing },
    wizardSteps,
  } = this.state;
  const {
    profile: { children },
    settings: { hotelNights },
  } = this.props;

  const formValidation = _cloneDeep(this.state.formValidation);
  const lastTouchedWizardIndex = wizardSteps
    .map((s) => s.touched)
    .lastIndexOf(true);

  /* Registration Info */
  if (lastTouchedWizardIndex >= 0) {
    //attendees
    if (!attendees.husbandPersonID && !attendees.wifePersonID) {
      //no attending parents
      formValidation.registrationInfo.attendees =
        "At least one parent is required to attend the Kinus";
    } else {
      delete formValidation.registrationInfo.attendees;
    }

    //hotel rooms
    if (
      !hotelStay.nights.length ||
      !hotelStay.nights.filter(
        (date) =>
          (hotelNights.find((night) => night.date === date) || {}).isKinusNight,
      ).length
    ) {
      //no kinus night selection
      formValidation.registrationInfo.hotelStayNights =
        "Minimum one kinus night hotel stay required";
    } else {
      delete formValidation.registrationInfo.hotelStayNights;
    }

    if (!hotelStay.numberOfRooms) {
      //no room count selection
      formValidation.registrationInfo.hotelStayNumberOfRooms =
        "Please select desired number of rooms";
    } else {
      delete formValidation.registrationInfo.hotelStayNumberOfRooms;
    }
  }

  /* Seating Preferences */
  if (lastTouchedWizardIndex >= 1) {
    if (!seating.seatingPreference) {
      //no seating preference selection
      formValidation.seatingPreferences.seatingSeatingPreference =
        "Please select seating preference";
    } else {
      delete formValidation.seatingPreferences.seatingSeatingPreference;

      if (
        seating.seatingPreference === "Separate" &&
        seating.separateSeatingChildPreferences.filter((pref) => !pref.parent)
          .length
      ) {
        formValidation.seatingPreferences.seatingSeparateSeatingChildPreferences =
          "Please select a seating preference for each eligible child";
      } else {
        delete formValidation.seatingPreferences
          .seatingSeparateSeatingChildPreferences;
      }
    }
  }

  /* Childcare */
  if (lastTouchedWizardIndex >= 2) {
    const attendingChildren = children
      .filter(
        (child) => attendees.childrenPersonIDs.indexOf(child.person.id) >= 0,
      )
      .map((child) => {
        const childCareIndex = childCare.children.findIndex(
          (cc) => cc.childID === child.id,
        );
        return {
          ...child,
          childCareIndex,
          childCare: childCare.children[childCareIndex],
        };
      });

    if (attendingChildren.length) {
      if (childCare.fathersCell && childCare.fathersCell.length < 10) {
        formValidation.childCare.fathersCell = "Invalid phone number";
      } else {
        delete formValidation.childCare.fathersCell;
      }

      if (childCare.motherCell && childCare.motherCell.length < 10) {
        formValidation.childCare.motherCell = "Invalid phone number";
      } else {
        delete formValidation.childCare.motherCell;
      }

      if (
        childCare.nightBabysitting === null &&
        attendingChildren.filter((child) => child.isEligibleForNightBabysitting)
          .length > 0
      ) {
        formValidation.childCare.nightBabysitting = "Required";
      } else {
        delete formValidation.childCare.nightBabysitting;
      }

      const isBringingBabysitter = isBringingOwnBabysitter.bind(this)();
      if (isBringingBabysitter && !childCare.privateBabysitterFullName) {
        formValidation.childCare.privateBabysitterFullName = "Required";
      } else {
        delete formValidation.childCare.privateBabysitterFullName;
      }

      if (isBringingBabysitter && !childCare.privateBabysitterRelationship) {
        formValidation.childCare.privateBabysitterRelationship = "Required";
      } else {
        delete formValidation.childCare.privateBabysitterRelationship;
      }

      attendingChildren.forEach((child) => {
        switch (child.kinusAgeGroupID) {
          case 2:
            if (child.childCare.needsKinusChildcare === null) {
              formValidation.childCare[
                `child.${child.id}.needsKinusChildcare`
              ] = "Required";
            } else {
              delete formValidation.childCare[
                `child.${child.id}.needsKinusChildcare`
              ];
            }

            if (
              child.childCare.needsKinusChildcare &&
              !child.childCare.childMovesBy
            ) {
              formValidation.childCare[`child.${child.id}.childMovesBy`] =
                "Required";
            } else {
              delete formValidation.childCare[`child.${child.id}.childMovesBy`];
            }
            break;
          case 3:
            if (
              !child.childCare.toiletTraining ||
              child.childCare.toiletTraining === "NonTrained"
            ) {
              formValidation.childCare[`child.${child.id}.toiletTraining`] =
                "Required";
            } else {
              delete formValidation.childCare[
                `child.${child.id}.toiletTraining`
              ];
            }
            break;
          case 4:
          case 5:
            if (!child.childCare.tShirtSize) {
              formValidation.childCare[`child.${child.id}.tShirtSize`] =
                "Required";
            } else {
              delete formValidation.childCare[`child.${child.id}.tShirtSize`];
            }

            if (!child.childCare.canJoinOffsite) {
              formValidation.childCare[`child.${child.id}.canJoinOffsite`] =
                "Required";
            } else {
              delete formValidation.childCare[
                `child.${child.id}.canJoinOffsite`
              ];
            }

            if (
              child.kinusAgeGroupID === 5 &&
              child.childCare.campName &&
              child.childCare.transportationToCamp === null
            ) {
              formValidation.childCare[
                `child.${child.id}.transportationToCamp`
              ] = "Required";
            } else {
              delete formValidation.childCare[
                `child.${child.id}.transportationToCamp`
              ];
            }
            break;
          default:
            break;
        }
      });
    }
  }

  /* Confirm */
  if (lastTouchedWizardIndex >= 3) {
    if (!waiver.didAcceptGeneralTerms) {
      formValidation.confirm.didAcceptGeneralTerms = "Required";
    } else {
      delete formValidation.confirm.didAcceptGeneralTerms;
    }

    if (!waiver.didAcceptNoShowDisclaimer) {
      formValidation.confirm.didAcceptNoShowDisclaimer = "Required";
    } else {
      delete formValidation.confirm.didAcceptNoShowDisclaimer;
    }

    if (!waiver.didConfirmAttendeesImmunizations) {
      formValidation.confirm.didConfirmAttendeesImmunizations = "Required";
    } else {
      delete formValidation.confirm.didConfirmAttendeesImmunizations;
    }

    if (isGettingChildcare.bind(this)() && !waiver.didAcceptChildcareTerms) {
      formValidation.confirm.didAcceptChildcareTerms = "Required";
    } else {
      delete formValidation.confirm.didAcceptChildcareTerms;
    }

    if (
      isGettingNightBabysitting.bind(this)() &&
      !waiver.didAcceptNightBabysittingTerms
    ) {
      formValidation.confirm.didAcceptNightBabysittingTerms = `You must agree to the Night Babysitting Terms and Conditions in order to complete your registration.
            You can opt out of night babysitting by unchecking the option in the Childcare section of the form.`;
    } else {
      delete formValidation.confirm.didAcceptNightBabysittingTerms;
    }

    if (
      isBringingOwnBabysitter.bind(this)() &&
      !waiver.didAcceptBringYourOwnBabysitter
    ) {
      formValidation.confirm.didAcceptBringYourOwnBabysitter = `You must agree to the Bring Your Own Babysitter Waiver in order to complete your registration.
                You can opt into Kinus Childcare by selecting the option in the Childcare section of the form.`;
    } else {
      delete formValidation.confirm.didAcceptBringYourOwnBabysitter;
    }
  }

  /* Payment */
  if (lastTouchedWizardIndex === 4) {
    if (
      !billing.useCardOnFile &&
      (!billing.hasCompleteCardInfo || !billing.cardHolderFullName)
    ) {
      formValidation.payment.completeCardInfo = "Required";
    } else {
      delete formValidation.payment.completeCardInfo;
    }

    const hasBillingAddress =
      billing.address.address1 ||
      billing.address.address2 ||
      billing.address.city ||
      billing.address.state ||
      billing.address.country ||
      billing.address.zip;
    if (
      !billing.address.id &&
      hasBillingAddress &&
      (!billing.address.address1 ||
        !billing.address.city ||
        !billing.address.country)
    ) {
      formValidation.payment.billingAddress =
        "If entering a billing address, Street Address, City, and Country are required.";
    } else {
      delete formValidation.payment.billingAddress;
    }
  }

  return formValidation;
}

export function hasValidationErrors(index) {
  const wizardIndex = index >= 0 ? index : this.state.wizardIndex;
  const { formValidation, wizardSteps } = this.state;

  if (wizardSteps[wizardIndex])
    return (
      Object.keys(formValidation[wizardSteps[wizardIndex].name]).length > 0
    );
}

export function fieldAffectsBilling(fieldName) {
  return (
    fieldName === "formValues" ||
    fieldName.indexOf("attendees") === 0 ||
    fieldName.indexOf("hotelStay") === 0 ||
    fieldName.indexOf("toKinusBussing") === 0 ||
    fieldName.indexOf("fromKinusBussing") === 0 ||
    fieldName === "childCare.nightBabysitting"
  );
}

export function fieldDoesNotAffectValidation(fieldName) {
  return (
    fieldName.indexOf("hotelStay.extras") === 0 ||
    fieldName.indexOf("toKinusBussing") === 0 ||
    fieldName.indexOf("fromKinusBussing") === 0 ||
    fieldName.indexOf("generalComments") >= 0 ||
    fieldName.indexOf("medicalNotes") >= 0 ||
    fieldName.indexOf("billing.address.address2") === 0 ||
    fieldName.indexOf("billing.address.zip") === 0
  );
}

export function getValuesForSubmission() {
  const {
    profile: { children },
    sys: { countries },
  } = this.props;
  const {
    formValues,
    formValues: { billing },
  } = this.state;

  const stripeValues = {
    name: billing.cardHolderFullName,
    address_line1: billing.address.address1,
    address_line2: billing.address.address2,
    address_city: billing.address.city,
    address_state: billing.address.state,
    address_zip: billing.address.zip,
    address_country: (
      countries.find((country) => country.name === billing.address.country) ||
      {}
    ).code,
  };

  const registrationValues = _cloneDeep(formValues);

  //filter hotel extras:
  // [ API expects ony extras where quanity is over 0. ]
  if (registrationValues.hotelStay.extras.length) {
    registrationValues.hotelStay.extras = registrationValues.hotelStay.extras
      .filter((extra) => extra.numberOfExtras > 0)
      .map((extra) => {
        delete extra.displayValue;
        return extra;
      });
  }

  if (!registrationValues.attendees.childrenPersonIDs.length) {
    delete registrationValues.childCare;
  } else {
    //filter childcare to only attending children:
    // [ We are not removing childcare records when children attendance is toggled
    //     in order to preserve value if re-toggle attendance. ]
    registrationValues.childCare.children =
      registrationValues.childCare.children.filter((childCare) => {
        const child = children.find((c) => c.id === childCare.childID);
        return childIsAttending.bind(this)(child);
      });
    //update childcare privateBabysitter info
    // [ Removing babysitter info if not bringing own babysitter.
    //    Did not remove on toggle to preserve value if re-toggle.
    //    We are updating on re-mount of childcare step to clear out stale data. ]
    if (
      !isBringingOwnBabysitter.bind(this)() &&
      (registrationValues.childCare.privateBabysitterFullName ||
        registrationValues.childCare.privateBabysitterRelationship)
    ) {
      registrationValues.childCare.privateBabysitterFullName = "";
      registrationValues.childCare.privateBabysitterRelationship = "";
    }
  }

  //update irrelevant waivers:
  // [ We are not resetting waiver terms when toggle nightbabysitting / childcare
  //     or toggle attending children that affect childcare eligibility,
  //     in order to preserve value if re-togle so don't have to keep re-accepting waivers.
  //     instead updating here and when mount confirmation step.
  //     Note - we are updating waivers when update profile externally if affects waivers. ]
  registrationValues.waiver = getWaiverUpdates.bind(this)();

  //remove billing address if no address supplied
  const billingAddress = registrationValues.billing.address;
  if (
    !billingAddress.id &&
    !billingAddress.address1 &&
    !billingAddress.address2 &&
    !billingAddress.city &&
    !billingAddress.state &&
    !billingAddress.zip &&
    !billingAddress.country
  ) {
    delete registrationValues.billing.address;
  }

  //remove extra billing logic-only properties
  delete registrationValues.billing.cardHolderFullName;
  delete registrationValues.billing.hasCompleteCardInfo;
  delete registrationValues.billing.installmentDates;

  removeEmptyFromObj(registrationValues);

  return { registrationValues, stripeValues };
}
