import React, { useEffect, useState } from "react";
import { InputAdornment, IconButton } from "@material-ui/core";
import { VisibilityOutlined, VisibilityOffOutlined } from "@material-ui/icons";
import { FormTextField, FormTextFieldProps } from "./FormTextField";

type FormPasswordFieldProps = {
  showPassword?: boolean;
  showVisibilityIcon?: boolean;
} & FormTextFieldProps;

export const FormPasswordField = React.memo(
  /**
   *
   */
  function FormPasswordField({
    showPassword: initalShowPassword = false,
    showVisibilityIcon = true,
    ...passProps
  }: FormPasswordFieldProps) {
    const [showPassword, setShowPassword] = useState(initalShowPassword);

    useEffect(() => {
      if (!showVisibilityIcon) {
        setShowPassword(false);
      }
    }, [showVisibilityIcon]);

    return (
      <FormTextField
        {...passProps}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: showVisibilityIcon && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <VisibilityOffOutlined />
                ) : (
                  <VisibilityOutlined />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  },
);
