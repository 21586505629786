import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { KinusActions } from "../../state";
import Kinus from "./Kinus";

export default connect(
  function mapState(state) {
    return {
      loading: state.kinus.profile.loading || state.kinus.settings.loading,
      error:
        state.kinus.profile.success === false ||
        state.kinus.settings.success === false,
      profile: state.kinus.profile.payload,
      registration: state.kinus.registration,
      settings: state.kinus.settings.payload,
      user: state.auth.person,
      sys: state.sys,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(KinusActions, dispatch),
    };
  },
)(Kinus);
