import React from "react";
import PaginatedTable from "../../../../../components/PaginatedTableDeprecated";
import StudentDetailsModal from "../StudentDetailsModal";
import StudentsListHeader from "./StudentsListHeader";
import StudentsListRow from "./StudentsListRow";
import { PageURL, Navigation } from "../../../../../lib";
import StudentStatusUpdateModal from "./StudentStatusUpdateModal";

export default class StudentsList extends React.PureComponent {
  state = {
    approveRejectStudentAction: null,
    approveRejectStudentErrorMessage: "",
    detailsOpened: {},
    selectedStudent: null,
    showUpdateStatusModal: false,
  };

  approveRejectActions = {
    approve: "approve",
    reject: "reject",
  };

  componentDidMount() {
    //set studentTab query param to open student details modal if studentId query param is specified
    const {
      pageRoute: {
        query: { studentId },
      },
    } = this.props;
    if (studentId) {
      this.toggleViewStudentDetails(studentId);
    }
  }

  getURLForPagination = (pg, resultsView) => {
    const {
      pageRoute: { query, page, params },
    } = this.props;
    return PageURL.to(page, params, {
      ...query,
      p: pg,
      r: resultsView || query.r,
    });
  };

  onApproveRejectStudent = (approve, student) => {
    this.setState({
      approveRejectStudentAction: approve
        ? this.approveRejectActions.approve
        : this.approveRejectActions.reject,
      approveRejectStudentErrorMessage: "",
      selectedStudent: student || this.state.selectedStudent, //if doing approve/reject from details modal, don't reset selectedStudent
      showUpdateStatusModal: true,
    });
  };

  onCancelApproveRejectStudent = () => {
    this.setState({
      approveRejectStudentAction: null,
      approveRejectStudentErrorMessage: "",
      selectedStudent: null,
      showUpdateStatusModal: false,
    });
  };

  updateStudentEnrollmentStatus = async (values) => {
    await this.props.doSubmitStudentEnrollment(values);

    const {
      getStudents,
      submitStudentEnrollment: { errorMessage, success },
    } = this.props;

    if (success) {
      getStudents();
      this.setState({
        approveRejectStudentAction: null,
        selectedStudent: null,
        showUpdateStatusModal: false,
      });
    } else {
      this.setState({
        approveRejectStudentErrorMessage:
          errorMessage || "Something went wrong.  Please try again.",
      });
    }
  };

  toggleViewStudentDetails = (studentId) => {
    const {
      eduProgramType,
      pageRoute: { query, page, params },
    } = this.props;

    Navigation.redirect(
      PageURL.to(page, params, {
        ...query,
        studentId: studentId || undefined,
        studentTab: studentId ? eduProgramType : undefined,
      }),
    );
  };

  toggleMobileDetails = (id) => {
    this.setState({
      detailsOpened: {
        ...this.state.detailsOpened,
        [id]: !this.state.detailsOpened[id],
      },
    });
  };

  render() {
    const {
      didClassesEnd,
      eduProgramType,
      filterComponent,
      getStudents,
      isStudentsByCourseView,
      pageRoute,
      students: { data: { results, numberOfRows } = {}, loading, success },
      submitStudentEnrollment,
    } = this.props;

    const {
      query: {
        p: page = 1,
        r: resultsView = 10,
        studentId: studentModalId,
        studentTab: studentModalTab,
      },
      params = {},
    } = pageRoute;

    const {
      approveRejectStudentAction,
      approveRejectStudentErrorMessage,
      detailsOpened,
      selectedStudent,
      showUpdateStatusModal,
    } = this.state;

    return (
      <React.Fragment>
        <PaginatedTable
          className="students-table students-list"
          filterComponent={filterComponent}
          getURL={this.getURLForPagination}
          loadData={getStudents}
          loading={loading}
          name="My Students"
          page={page}
          records={results || []}
          renderHeaderRow={() => (
            <StudentsListHeader
              isStudentsByCourseView={isStudentsByCourseView}
            />
          )}
          renderRow={(student, index) => (
            <StudentsListRow
              detailsOpened={detailsOpened}
              didClassesEnd={didClassesEnd}
              eduProgramType={eduProgramType}
              isStudentsByCourseView={isStudentsByCourseView}
              key={index}
              onApproveRejectStudent={this.onApproveRejectStudent}
              onViewStudentDetails={this.toggleViewStudentDetails}
              student={student}
              toggleMobileDetails={this.toggleMobileDetails}
            />
          )}
          resultsIncrement={10}
          resultsView={resultsView}
          showResultsView={true}
          success={success}
          totalCount={numberOfRows}
        />

        <StudentDetailsModal
          close={() => this.toggleViewStudentDetails()}
          isStudentsByCourseView={isStudentsByCourseView}
          pageRoute={pageRoute}
          show={!!(studentModalId && studentModalTab)}
        />

        {/* Confirm approve/reject student */}
        {showUpdateStatusModal && (
          <StudentStatusUpdateModal
            programScheduleId={params.programScheduleId}
            loading={submitStudentEnrollment.loading}
            handleClose={this.onCancelApproveRejectStudent}
            show={showUpdateStatusModal}
            data={selectedStudent}
            handleSubmit={this.updateStudentEnrollmentStatus}
            errorMessage={approveRejectStudentErrorMessage}
            statusType={
              this.approveRejectActions[approveRejectStudentAction] ===
              this.approveRejectActions.approve
                ? "approve"
                : "reject"
            }
          />
        )}
      </React.Fragment>
    );
  }
}
