import React from "react";
import { getNameDisplay, pluralizeText } from "../../../../../../../lib";

export default function RegistrationSeating(props) {
  const {
    onChangeRegistration,
    registration: { tableID },
    scheduleDetails: { attendeesByTable } = {},
    studentName,
  } = props;

  return (
    <div className="mb-24">
      <p className="fw-700 medium-text mt-16 mb-16">
        Select a table for seating
      </p>
      <div className="two-column-grid mobile-one-column-grid">
        {attendeesByTable.map((table) => {
          const {
            tableID: thisTableID,
            capacity,
            seatsRemaining: initialSeatsRemaining,
            tableName,
            sortOrder,
          } = table;
          const isFullyBooked = capacity && !initialSeatsRemaining;
          const isTableSelected = tableID === thisTableID;
          const seatsRemaining = isTableSelected
            ? initialSeatsRemaining - 1
            : initialSeatsRemaining;
          return (
            <div
              className={`card flat-card mr-8 flex flex-justify-space flex-column ${
                isTableSelected ? " selected" : ""
              }`}
              style={{ padding: 16 }}
              key={thisTableID}
            >
              <div
                className="flex flex-justify-space flex-align-center mb-16"
                style={{
                  paddingBottom: 8,
                  borderBottom: "1px solid #edecec",
                }}
              >
                <p className="fw-700">{tableName || `Table ${sortOrder}`}</p>
                {!!capacity && (
                  <p className="accent-text-secondary">
                    {seatsRemaining} {pluralizeText("Seat", seatsRemaining)}{" "}
                    left
                  </p>
                )}
              </div>
              <div>
                {table.attendees &&
                  table.attendees.map((attendee) => (
                    <p
                      className="accent-text small-text mb-8"
                      key={attendee.id}
                    >
                      {getNameDisplay(attendee.firstName, attendee.lastName)}
                    </p>
                  ))}
                {isTableSelected && studentName && (
                  <p className="accent-text small-text mb-8">{studentName}</p>
                )}
              </div>
              <div className="mt-24">
                <p
                  className={`link-text uppercase-text ${
                    isFullyBooked ? " disabled" : ""
                  }`}
                  onClick={
                    isFullyBooked
                      ? null
                      : () =>
                          onChangeRegistration(
                            "tableID",
                            isTableSelected ? "" : thisTableID,
                          )
                  }
                >
                  {isTableSelected ? "Deselect" : "Choose this table"}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
