import React, { memo } from "react";
import { useSelector } from "react-redux";

import DefaultAppBar from "./DefaultAppBar";
import InternsAppBar from "./InternsAppBar";

import { AuthSelectors } from "../state";
import { GenericObject } from "../state/types";

interface AppBarProps {
  children: React.ReactNode;
  props?: GenericObject;
}

function AppBar({ children, props = {} }: AppBarProps) {
  const isIntern = useSelector(AuthSelectors.isLoggedInAsIntern);

  return isIntern ? (
    <InternsAppBar>{children}</InternsAppBar>
  ) : (
    <DefaultAppBar props={props}>{children}</DefaultAppBar>
  );
}

export default memo(AppBar);
