import React from "react";

export default class Terms extends React.PureComponent {
  state = {
    acceptedTerms: [],
  };

  getShortTerms = (terms) => {
    const shortTerms = terms.filter((term) => term.type === "ShortText");
    return shortTerms;
  };

  getLongTerms = (terms) => {
    const longTerms = terms.filter((term) => term.type === "LongText");
    return longTerms;
  };

  onChangeTerms = (term) => {
    let { acceptedTerms } = this.props.enrollmentSettings;

    const toggleOff =
      acceptedTerms.findIndex((acceptedTerm) => acceptedTerm.id === term.id) >=
      0;
    if (toggleOff) {
      this.props.onArrayChange("acceptedTerms", term, false);
    } else {
      this.props.onArrayChange("acceptedTerms", term, true);
    }
  };

  render() {
    const {
      enrollmentSettings: { acceptedTerms },
      event: { programScheduleName, termsAndConditions },
      validation,
    } = this.props;
    return (
      <div>
        <div className="trip-page-form-section">
          <p className="xxl-text fw-500">Terms and Conditions</p>
        </div>
        <div>
          {termsAndConditions &&
            termsAndConditions
              .filter((term) => term.appliesTo === "Shluchim")
              .map((term) => {
                const { id, text, title, type } = term;
                const accepted =
                  acceptedTerms.findIndex(
                    (acceptedTerm) => acceptedTerm.id === term.id,
                  ) >= 0;
                return (
                  <div className="trip-page-form-section term" key={id}>
                    {type === "LongText" && (
                      <React.Fragment>
                        <p className="medium-text fw-700 mb-16">{title}</p>
                        <div className="terms line-height-double mb-16 pre-wrap">
                          {text}
                        </div>
                      </React.Fragment>
                    )}
                    <div className="flex custom-checkbox-container mb-12">
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        id={`school-${text}`}
                        onChange={() => this.onChangeTerms(term)}
                        checked={accepted}
                      />
                      <label
                        htmlFor={`school-${text}`}
                        className={`flex-align-center line-height-double pre-wrap ${
                          validation.didAcceptTermsAndConditions && !accepted
                            ? "error"
                            : ""
                        }`}
                      >
                        {type === "LongText"
                          ? `I have read, understand and agree with the terms and conditions above of ${programScheduleName} attendance.`
                          : text}
                      </label>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    );
  }
}
