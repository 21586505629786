import React from "react";

import CreditCardInfo from "./CreditCardInfo";
import BillingAddress from "./BillingAddress";
import Radio from "../../../components/form/Radio";

import _isEqual from "lodash.isequal";

export default class Payment extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      settings: { eventSettings },
    } = props;

    const today = new Date().setHours(0, 0, 0, 0);
    const installmentDates = eventSettings.installmentDates
      .map((date) => ({
        ...date,
        localDate: new Date(date.date).setHours(0, 0, 0, 0),
      }))
      .filter((date) => date.localDate > today);

    this.state = {
      installmentDates,
      today,
    };
  }

  componentWillMount() {
    const {
      formProps: { onChange, values },
    } = this.props;
    const { installmentDates } = this.state;

    if (
      !values.invoice.payInFull &&
      !_isEqual(installmentDates, values.billing.installmentDates)
    ) {
      if (!installmentDates.length) {
        this.togglePayInFull(true);
      } else {
        onChange("billing.installmentDates", installmentDates);
      }
    }
  }

  togglePayInFull = async (payInFull) => {
    const {
      formProps: { onChange },
    } = this.props;

    await onChange("invoice.payInFull", payInFull);

    onChange(
      "billing.installmentDates",
      payInFull ? [] : this.state.installmentDates,
    );
  };

  render() {
    const {
      formProps: { onChange, onChangeEvent, values, validation },
      profile: { credCardInfo, familyAddress, primaryChabadHouse },
      settings: {
        eventSettings: {
          scholarshipApplicationFormURL,
          travelSubsidyRequestFormURL,
          subsidyRequestDeadline,
        },
      },
      sys: { countries },
      validationHeader,
    } = this.props;
    const { installmentDates } = this.state;

    return (
      <div className="kinus-page-form payment">
        <p className="xxl-text fw-500">
          Payment
          {validationHeader && validationHeader}
        </p>
        <div className="kinus-form-section payment-options">
          <Radio
            name="invoice.payInFull"
            options={[
              { value: true, display: "Pay in full" },
              {
                value: false,
                display: `Pay in ${
                  installmentDates.length
                    ? `${installmentDates.length + 1} `
                    : ""
                }installments`,
                tag: installmentDates.length > 0 && (
                  <div className="installments flex">
                    <div>
                      <p className="fw-700 small-text">TODAY</p>
                    </div>
                    {installmentDates.map((date, index) => (
                      <div key={index}>
                        <p className="small-text mb-4">
                          {date.formattedMonthShort}
                        </p>
                        <p className="fw-700 medium-text">{date.day}</p>
                      </div>
                    ))}
                  </div>
                ),
                disabled: !installmentDates.length,
              },
            ]}
            value={values.invoice.payInFull}
            onChange={(name, val) => this.togglePayInFull(val)}
          />
        </div>

        <CreditCardInfo
          billingAddress={values.billing.address}
          cardHolderFullName={values.billing.cardHolderFullName}
          cardOnFile={credCardInfo}
          hasCompleteCardInfo={values.billing.hasCompleteCardInfo}
          useCardOnFile={values.billing.useCardOnFile}
          onChange={onChange}
          onChangeEvent={onChangeEvent}
          validation={validation}
        />

        <BillingAddress
          billingAddress={values.billing.address}
          countries={countries}
          chabadHouseAddress={
            primaryChabadHouse ? primaryChabadHouse.address : null
          }
          personalAddress={familyAddress}
          onChange={onChange}
          onChangeEvent={onChangeEvent}
          validation={validation}
        />

        <div className="kinus-form-section">
          <p className="medium-text fw-700 mb-16">Scholarships</p>
          <p className="mb-16" style={{ lineHeight: "1.5" }}>
            If the subsidized rate is still beyond your means, a limited number
            of needs-based scholarships are available. Please complete this{" "}
            <a
              className="link-text"
              href={scholarshipApplicationFormURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              scholarship application form
            </a>
            .
          </p>
          <p className="mb-16" style={{ lineHeight: "1.5" }}>
            If you are applying for a scholarship please choose the “Pay in
            installments” option above. The first installment will be charged at
            the regular rate. The scholarship will be applied to the next
            installments.
          </p>
          <p className="mb-16" style={{ lineHeight: "1.5" }}>
            If you have extensive travel costs, a limited number of travel
            subsidies are available. Please complete the{" "}
            <a
              className="link-text"
              href={travelSubsidyRequestFormURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              subsidy request form
            </a>
            .
          </p>
          <p style={{ lineHeight: "1.5" }}>
            All discounts and subsidies must be requested before{" "}
            {subsidyRequestDeadline}.
          </p>
        </div>
      </div>
    );
  }
}
