import { ApiCallErrorMessageHandler, AuthRequest } from "../../lib";

export const ChabadHousesActions = {
  SET_CHABAD_HOUSES_LOADING: "SET_CHABAD_HOUSES_LOADING",
  UPDATE_CHABAD_HOUSES: "UPDATE_CHABAD_HOUSES",

  getChabadHouses(id) {
    return async (dispatch, getState) => {
      dispatch(ChabadHousesActions.setChabadHousesLoading(true));

      const newState = {
        chabadHouses: [],
        loading: false,
        success: true,
      };

      try {
        const { data } = await AuthRequest.get(
          `Shluchim/${id}/chabadhouses/public`,
        );
        newState.chabadHouses = data;
      } catch (err) {
        newState.success = false;
      }

      dispatch(ChabadHousesActions.updateChabadHouses(newState));
    };
  },
  setChabadHousesLoading(payload) {
    return {
      type: ChabadHousesActions.SET_CHABAD_HOUSES_LOADING,
      payload,
    };
  },
  updateChabadHouses(payload) {
    return {
      type: ChabadHousesActions.UPDATE_CHABAD_HOUSES,
      payload,
    };
  },

  async getStudents(chabadHouseId) {
    let newState = {};
    let success = true;

    try {
      const response = await AuthRequest.get(
        `ChabadHouses/${chabadHouseId}/Students/Basic?isPrimary=false`,
      );
      if (!response.data.payload || !response.data.payload.length) {
        success = false;
        newState.errorMessage = "No students found";
      } else {
        newState.data = response.data.payload;
      }
    } catch (err) {
      success = false;
      newState.err = err;
      newState.errorMessage = ApiCallErrorMessageHandler(
        err,
        "Something went wrong and students could not be retrieved, please reload the page to try again",
      );
    }

    newState.loading = false;
    newState.success = success;
    return newState;
  },
};
