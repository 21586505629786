import React from "react";
import CourseContainer from "./course/CourseContainer";
import CoursesListContainer from "./list/CoursesListContainer";
import CourseSchedulesListContainer from "./list/CourseSchedulesListContainer";
import { EduProgramTypes } from "../../eduUtils";

export default class CoursesContainer extends React.PureComponent {
  render() {
    const {
      enrollment: { data: { eduProgramType } = {} },
      pageRoute: {
        params: { courseId },
      },
    } = this.props;

    return courseId ? (
      <CourseContainer {...this.props} />
    ) : eduProgramType === EduProgramTypes.JewishU ? (
      <CoursesListContainer {...this.props} />
    ) : (
      <CourseSchedulesListContainer {...this.props} />
    );
  }
}
