import { AuthActions } from "../auth/actions";
import { AppState } from "../types";
import { LamplightersActions } from "./actions";

export interface EnrollmentSettingsData {
  id: number | null;
  emailRecipients: any;
}

export interface EnrollmentData {
  id: number;
  chabadHouseID: number;
}

export const LamplightersState = {
  name: "lamplighters",
  persist: false,
  defaults: {
    dataPercentage: {
      loading: false,
    },
    enrollment: {
      loading: false,
      data: {} as EnrollmentData,
    },
    enrollmentSettings: {
      loading: false,
      data: {} as EnrollmentSettingsData,
    },
    schedule: {
      loading: false,
    },
    schedules: {
      loading: false,
    },
    studentGrants: {
      loading: false,
    },
    students: {
      loading: false,
    },
    submitStudentGrant: {
      loading: false,
    },
  },
  handlers: {
    [LamplightersActions.SET_LAMPLIGHTERS_SCHEDULES_LOADING]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        schedules: {
          loading: !!action.payload,
        },
      };
    },
    [LamplightersActions.SET_LAMPLIGHTERS_SCHEDULES]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        schedules: action.payload,
      };
    },

    [LamplightersActions.SET_LAMPLIGHTERS_SCHEDULE_LOADING]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        schedule: {
          loading: !!action.payload,
        },
      };
    },
    [LamplightersActions.SET_LAMPLIGHTERS_SCHEDULE]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        schedule: action.payload,
      };
    },
    [LamplightersActions.CLEAR_LAMPLIGHTERS_SCHEDULE]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        schedule: LamplightersState.defaults.schedule,
      };
    },

    [LamplightersActions.SET_LAMPLIGHTERS_SCHEDULE_ENROLLMENT_LOADING]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        enrollment: {
          loading: !!action.payload,
        },
      };
    },
    [LamplightersActions.SET_LAMPLIGHTERS_SCHEDULE_ENROLLMENT_REFRESHING]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          refreshing: !!action.payload,
        },
      };
    },
    [LamplightersActions.SET_LAMPLIGHTERS_SCHEDULE_ENROLLMENT]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        enrollment: action.payload,
      };
    },
    [LamplightersActions.CLEAR_LAMPLIGHTERS_SCHEDULE_ENROLLMENT]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        enrollment: LamplightersState.defaults.enrollment,
      };
    },

    [LamplightersActions.SET_LAMPLIGHTERS_SCHEDULE_DATA_PERCENTAGE_LOADING]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        dataPercentage: {
          loading: !!action.payload,
        },
      };
    },
    [LamplightersActions.SET_LAMPLIGHTERS_SCHEDULE_DATA_PERCENTAGE]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        dataPercentage: action.payload,
      };
    },
    [LamplightersActions.CLEAR_LAMPLIGHTERS_SCHEDULE_DATA_PERCENTAGE]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        dataPercentage: LamplightersState.defaults.dataPercentage,
      };
    },

    [LamplightersActions.SET_LAMPLIGHTERS_STUDENTS_LOADING]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        students: {
          loading: !!action.payload,
        },
      };
    },
    [LamplightersActions.SET_LAMPLIGHTERS_STUDENTS]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        students: action.payload,
      };
    },

    [LamplightersActions.SET_SUBMIT_LAMPLIGHTERS_STUDENT_GRANT_LOADING]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        submitStudentGrant: {
          loading: !!action.payload,
        },
      };
    },
    [LamplightersActions.SET_SUBMIT_LAMPLIGHTERS_STUDENT_GRANT]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        submitStudentGrant: action.payload,
      };
    },

    [LamplightersActions.SET_LAMPLIGHTERS_STUDENT_GRANTS_LOADING]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        studentGrants: {
          loading: !!action.payload,
        },
      };
    },
    [LamplightersActions.SET_LAMPLIGHTERS_STUDENT_GRANTS]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        studentGrants: action.payload,
      };
    },
    [LamplightersActions.CLEAR_LAMPLIGHTERS_STUDENT_GRANTS]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        student: LamplightersState.defaults.studentGrants,
      };
    },

    [LamplightersActions.SET_LAMPLIGHTERS_ENROLLMENT_SETTINGS_LOADING]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        enrollmentSettings: {
          loading: !!action.payload,
        },
      };
    },
    [LamplightersActions.SET_LAMPLIGHTERS_ENROLLMENT_SETTINGS]: (
      state: AppState | any,
      action: any,
    ) => {
      return {
        ...state,
        enrollmentSettings: action.payload,
      };
    },

    [AuthActions.DO_SIGNOUT]: (state: AppState | any, action: any) => {
      return LamplightersState.defaults; //reset Lamplighters state on user signout
    },
  },
};
