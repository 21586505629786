import { AuthRequest } from "../../lib";

export const AccountActions = {
  SET_ACCOUNT_LOADING: " SET_ACCOUNT_LOADING",
  UPDATE_ACCOUNT: "UPDATE_ACCOUNT",

  getAccountInfo(id, reload) {
    return async (dispatch) => {
      if (reload) {
        dispatch(AccountActions.setAccountLoading(true));
      }
      let success = true;

      const { data } = await AuthRequest.get(`Shluchim/${id}/profile`).catch(
        (err) => {
          success = false;
          return err;
        },
      );

      const newState = {
        ...(data || {}).payload,
        loading: false,
        success,
      };

      dispatch(AccountActions.updateAccount(newState));
    };
  },
  setAccountLoading(payload) {
    return {
      type: AccountActions.SET_ACCOUNT_LOADING,
      payload,
    };
  },
  updateAccount(payload) {
    return {
      type: AccountActions.UPDATE_ACCOUNT,
      payload,
    };
  },
};
