import React from "react";
import ScheduleSettings from "./manageCourseSchedule/ScheduleSettings";

export default class OfferCourse extends React.PureComponent {
  componentDidMount() {
    //scroll to top of page on mount for when navigating from low scroll position within courses list
    if (window.scrollY > 0) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <React.Fragment>
        <ScheduleSettings {...this.props} newSchedule={true} />
      </React.Fragment>
    );
  }
}
