import React, { useCallback } from "react";
import ResourcesList from "./ResourcesList";
import ResourcesListFilters from "./ResourcesListFilters";

const resourcesFilterDefinitions = {
  p: "page",
  se: "search",
  so: "sortBy",
};

export default function ResourcesListContainer(props) {
  const listResultsView = props.ui.isPhone ? 10 : 18;

  const {
    actions: { getRsvpResources },
    enrollment: {
      data: { chabadHouseName, hasResources, id: rsvpEnrollmentID } = {},
    },
    resources,
    pageRoute,
    pageRoute: {
      query: { se, so = "createdOn" },
    },
  } = props;

  const getResources = useCallback(
    (page) => {
      getRsvpResources(page, listResultsView, {
        [resourcesFilterDefinitions.se]: se && decodeURIComponent(se),
        [resourcesFilterDefinitions.so]: so,
      });
    },
    [getRsvpResources, listResultsView, se, so],
  );

  return (
    <div
      className="flex mobile-block flex-justify-space rsvp-events-list-card"
      style={{ width: "100%" }}
    >
      <div className="rsvp-list-container">
        <div>
          <ResourcesListFilters
            chabadHouseName={chabadHouseName}
            rsvpEnrollmentID={rsvpEnrollmentID}
            getResources={getResources}
            hasResources={hasResources}
            pageRoute={pageRoute}
          />
        </div>
        <div className="program-form-section full-width">
          <ResourcesList
            getResources={getResources}
            resources={resources}
            hasResources={hasResources}
            pageRoute={pageRoute}
            resultsView={listResultsView}
          />
        </div>
      </div>
    </div>
  );
}
