export const RafflePaymentTypes = {
  CreditCard: "CreditCard",
  Cash: "Cash",
  PayPal: "PayPal",
};
export const RafflePurchaseSources = {
  Unknown: "Unknown",
  Website: "Website",
  Shliach: "Shliach",
};
