import React, { useEffect } from "react";
import MaterialCheckbox from "../../../../components/form/MaterialCheckbox";
import MaterialRadioGroup from "../../../../components/form/MaterialRadioGroup";
import CustomSelect from "../../../../components/form/CustomSelect";
import { SystemSelectors } from "../../../../state";
import { useSelector } from "react-redux";

export default function RsvpCustomQuestion({
  question,
  onChange,
  onChangeEvent,
  submitAttempted,
  onAddRemoveQuestion,
  isEvent,
  isResource,
}) {
  const customQuestionResponseTypes =
    useSelector(SystemSelectors.customQuestionResponseTypes) || []; // value is undefined on signout

  const eventRadioName = `registrationQuestions[${question.index}].isEventSpecific`;
  const resourceRadioName = `registrationQuestions[${question.index}].isResourceSpecific`;

  useEffect(() => {
    async function setUpRadios() {
      // without using async the 2 onchange actions override eachother
      if (!question.questionText) {
        await onChange(resourceRadioName, true);
        await onChange(eventRadioName, true);
      }
    }
    setUpRadios();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="full-width"
      style={{
        border: "1px solid #edecec",
        borderRadius: 3,
        padding: 16,
        paddingBottom: 8,
        marginBottom: 16,
      }}
    >
      <div className="flex">
        <div
          className="accent-text-dark mr-16 mobile-mr-0 mobile-mb-4 mt-8"
          style={{ minWidth: "98px" }}
        >
          Question
        </div>
        <div className="flex flex-column" style={{ flex: 1 }}>
          <div className="flex flex-align-center mobile-block mb-4">
            <input
              className={`custom-input full-width${
                submitAttempted && !question.questionText ? " error" : ""
              }`}
              name={`registrationQuestions[${question.index}].questionText`}
              onChange={onChangeEvent}
              value={question.questionText}
            />
          </div>
          <div className="flex flex-align-center mobile-block">
            <div className="custom-question-answer full-width mobile-mb-16">
              <CustomSelect
                className={
                  submitAttempted && !question.responseType ? "error" : ""
                }
                name={`registrationQuestions[${question.index}].responseType`}
                onChange={onChange}
                options={
                  customQuestionResponseTypes &&
                  customQuestionResponseTypes.map((rt) => ({
                    value: rt.enumValue,
                    label: rt.displayValue,
                  }))
                }
                value={question.responseType}
              />
            </div>
            <div className="ml-32 mobile-ml-0">
              <MaterialCheckbox
                label="Make this question mandatory"
                checked={question.isMandatory}
                onChange={(e) => onChange(e.target.name, e.target.checked)}
                name={`registrationQuestions[${question.index}].isMandatory`}
              />
            </div>
          </div>
        </div>
        <div>
          <i
            className="material-icons link-text ml-16 mt-4"
            onClick={() => onAddRemoveQuestion(question.index)}
          >
            delete
          </i>
        </div>
      </div>
      <div className="flex mt-16">
        <div className="mr-40">
          <div className="accent-text-dark custom-questions-radio-title">
            Choose when to ask this question for future
            <span className="fw-700">events</span>
            {isResource && <RadioInfoTooltip />}
          </div>
          <MaterialRadioGroup
            name={eventRadioName}
            onChange={(e) => {
              const value = e.target.value === "true";
              onChange(eventRadioName, value);
            }}
            fieldValue={question.isEventSpecific}
            options={[
              { label: "Always ask", value: false },
              { label: "Decide each time", value: true },
            ]}
            radioGroupProps={{ row: true }}
          />
        </div>
        <div>
          <div className="custom-questions-radio-title accent-text-dark">
            Choose when to ask this question for future
            <span className="fw-700">resources</span>
            {isEvent && <RadioInfoTooltip />}
          </div>
          <MaterialRadioGroup
            name={resourceRadioName}
            onChange={(e) => {
              const value = e.target.value === "true";
              onChange(resourceRadioName, value);
            }}
            fieldValue={question.isResourceSpecific}
            options={[
              { label: "Always ask", value: false },
              { label: "Decide each time", value: true },
            ]}
            radioGroupProps={{ row: true }}
          />
        </div>
      </div>
    </div>
  );
}

function RadioInfoTooltip() {
  return (
    <span className="tooltip-container flex ml-4 mobile-full-width">
      <i className="material-icons link-text no-hover medium-text">info</i>
      <span className="tooltip">
        This question will be saved to your general RSVP settings, so you can
        include it in any future event or resource
      </span>
    </span>
  );
}
