import React from "react";

export default ({
  student: {
    doParentsShareResidence,
    father,
    fatherBackgroundDisplay,

    hadBarBatMitzvah,
    hasTakenIsraelCourses,
    hebrewLevelDisplay,

    jewishEducations,
    hasNoJewishEducation,
    jewishIsraelKnowledgeLevelDisplay,
    mother,
    motherBackgroundDisplay,
    person: { age, gender, hebrewName },
  },
}) => (
  <div className="student-background">
    <div>
      <p className="fw-900 medium-text mb-16">Profile</p>
      <div className="two-column-grid">
        <div className="two-column-grid">
          <p className="fw-700">Jewish Name</p>
          <p>{hebrewName || "—"}</p>
          <p className="fw-700">Gender</p>
          <p>{gender || "Unknown"}</p>
          <p className="fw-700">Age</p>
          <p>{age || "—"}</p>
        </div>
      </div>
    </div>
    <div className="student-background-divider" />
    <div>
      <p className="fw-900 medium-text mb-16">Family Background</p>
      <div className="two-column-grid">
        <div className="two-column-grid">
          <p className="fw-700">Mother's Jewish Background</p>
          <p>{motherBackgroundDisplay || "—"}</p>
          {mother && (
            <React.Fragment>
              <p className="fw-700">Mother's Name</p>
              <p>{mother.name || "—"}</p>
              <p className="fw-700">Mother's Email</p>
              <p>{mother.email || "—"}</p>
              <p className="fw-700">Mother's Phone</p>
              <p>{mother.phoneNumber || "—"}</p>
            </React.Fragment>
          )}
        </div>
        <div className="two-column-grid">
          <p className="fw-700">Father's Jewish Background</p>
          <p>{fatherBackgroundDisplay || "—"}</p>
          {father && (
            <React.Fragment>
              <p className="fw-700">Father's Name</p>
              <p>{father.name || "—"}</p>
              <p className="fw-700">Father's Email</p>
              <p>{father.email || "—"}</p>
              <p className="fw-700">Father's Phone</p>
              <p>{father.phoneNumber || "—"}</p>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="two-column-grid mt-16">
        <div className="two-column-grid">
          <p className="fw-700">Parents Share Address</p>
          <p>
            {doParentsShareResidence === true ||
            doParentsShareResidence === false
              ? doParentsShareResidence
                ? "Yes"
                : "No"
              : "—"}
          </p>
        </div>
      </div>
    </div>
    <div className="student-background-divider" />
    <div>
      <p className="fw-900 medium-text mb-16">Jewish Educational Background</p>
      <div className="two-column-grid">
        <div className="two-column-grid">
          {hasNoJewishEducation ? (
            <>
              <p className="fw-700">None</p>
              <p></p>
            </>
          ) : (
            jewishEducations &&
            jewishEducations.map((je, index) => (
              <React.Fragment key={index}>
                <p className="fw-700">{je.typeDisplay}</p>
                <p>
                  {je.name}{" "}
                  {je.affiliationDisplay && `(${je.affiliationDisplay})`}
                </p>
              </React.Fragment>
            ))
          )}
          <p className="fw-700">Hebrew Language</p>
          <p>{hebrewLevelDisplay || "—"}</p>
          <p className="fw-700">Had Bar/Bat Mitzvah</p>
          <p>
            {hadBarBatMitzvah ? "Yes" : hadBarBatMitzvah === false ? "No" : "—"}
          </p>
        </div>
      </div>
    </div>
    <div className="student-background-divider" />
    <div>
      <p className="fw-900 medium-text mb-16">Israel Educational Background</p>
      <div className="two-column-grid">
        <div className="two-column-grid">
          <p className="fw-700">Courses on Israel</p>
          <p>
            {hasTakenIsraelCourses === true || hasTakenIsraelCourses === false
              ? hasTakenIsraelCourses
                ? "Yes"
                : "No"
              : "—"}
          </p>
          <p className="fw-700">Knows about Jewish Connection to Israel</p>
          <p>{jewishIsraelKnowledgeLevelDisplay || "—"}</p>
        </div>
      </div>
    </div>
  </div>
);
