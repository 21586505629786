import React from "react";
import CreateResource from "./manage/CreateResource";
import ResourcesListContainer from "./list/ResourcesListContainer";
import ManageResourceContainer from "./manage/ManageResourceContainer";

export default class ResourcesContainer extends React.PureComponent {
  views = {
    create: CreateResource,
    list: ResourcesListContainer,
    manage: ManageResourceContainer,
  };

  getView = () => {
    const {
      pageRoute: {
        params: { rsvpItemId },
      },
    } = this.props;

    return !rsvpItemId
      ? this.views.list
      : rsvpItemId === "new"
      ? this.views.create
      : this.views.manage;
  };

  render() {
    return React.createElement(this.getView(), this.props);
  }
}
