import { AuthRequest, ApiCallErrorMessageHandler } from "../../lib";

export async function updateOrder(order) {
  const { data, status } = await AuthRequest.post("raffleOrders", order).catch(
    (e) => {
      //On error, different object is returned so pull out data & status manually
      return { data: e.response.data, status: e.response.status };
    },
  );

  if (status !== 200) {
    return {
      success: false,
      error: status !== 500 ? data?.messages[0] : "",
    };
  }
  return {
    success: true,
    order: data.payload,
  };
}

export async function getStudents(chabadHouseID) {
  const students = {
    list: [],
    error: "",
  };
  try {
    const response = await AuthRequest.get(
      `ChabadHouses/${chabadHouseID}/Students/Basic?isPrimary=false`,
    );
    students.list = response.data.payload;
  } catch (err) {
    students.error = ApiCallErrorMessageHandler(
      err,
      "Something went wrong and available students could not be retrieved. Please try again.",
    );
  }
  return students;
}

export async function getStudentBasicData(studentId) {
  const response = {
    success: false,
    data: {},
    error: "",
  };
  const { data, status } = await AuthRequest.get(
    `/Students/${studentId}/basic`,
  ).catch((e) => {
    return e;
  });
  if (status !== 200) {
    response.error = status !== 500 ? data?.messages[0] : "";
  } else {
    response.success = true;
    response.data = data;
  }
  return response;
}
