import React from "react";
import Select from "react-select";
import { Navigation, PageURL } from "../../../lib";

export default function EngagementPeriodsDropdown(props) {
  const onSelectPeriod = (periodId) => {
    const {
      pageRoute: { page, params },
    } = props;

    Navigation.go(
      PageURL.to(page, params, {
        id: periodId,
      }),
    );
  };

  const {
    engagementPeriodId,
    engagementPeriods,
    errorMessage,
    hasUnsavedChanges,
    loading,
  } = props;

  const engagementPeriodOptions = engagementPeriods
    ? engagementPeriods.map((p) => ({
        value: p.identifier,
        label: `${p.monthDisplay} ${p.year}`,
      }))
    : [];

  return (
    <div className="mb-16">
      <div className="tooltip-container">
        <Select
          className="custom-select"
          classNamePrefix="custom-select"
          isDisabled={hasUnsavedChanges}
          isSearchable={false}
          onChange={(val) => onSelectPeriod(val.value)}
          options={engagementPeriodOptions}
          placeholder={
            loading
              ? "Loading Engagement Periods..."
              : "Select Engagement Period..."
          }
          value={
            engagementPeriodId &&
            engagementPeriodOptions.find(
              (p) => p.value.toString() === engagementPeriodId.toString(),
            )
          }
        />
        {hasUnsavedChanges && (
          <span className="tooltip">
            Save or Cancel changes before selecting another engagement period
          </span>
        )}
      </div>
      {!loading && errorMessage && (
        <p className="error-text small-text">{errorMessage}</p>
      )}
    </div>
  );
}
