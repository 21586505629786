import React from "react";
import ChabadHouseDetails from "./ChabadHouseDetails";
import { formatFullName } from "../../../lib";

export default class ShliachDetails extends React.PureComponent {
  render() {
    const {
      details: {
        categoryDisplay,
        cell,
        email,
        familyImageURL,
        numberOfChildren,
        positionDisplay,
        primaryChabadHouse,
        shliach,
        spouse,
      },
    } = this.props;

    const { firstName, lastName, profileImageURL, titleDisplay } =
      shliach || {};

    const {
      firstName: spouseFirstName,
      id: spouseShliachId,
      lastName: spouseLastName,
      profileImageURL: spouseProfileImageURL,
      titleDisplay: spouseTitleDisplay,
    } = spouse || {};

    const { facebookURL, instagramURL, snapchatURL, twitterURL } =
      primaryChabadHouse || {};

    return (
      <div className="shliach-details-card">
        <div className="flex flex-align-center flex-justify-space mobile-block">
          <div className="flex flex-align-center mobile-block">
            {profileImageURL && (
              <img
                alt="shliach-profile"
                className="mr-16 profile-image mobile-mb-16"
                src={profileImageURL}
              />
            )}
            <div>
              <p className="large-text fw-700 mb-4">
                {formatFullName(firstName, lastName, titleDisplay)}
              </p>
              <div className="flex flex-align-center mobile-block">
                <div className="mr-16">
                  {(positionDisplay || categoryDisplay) && (
                    <div className="flex flex-align-center mb-8">
                      <i className="material-icons medium-text mr-4">person</i>
                      <p style={{ fontSize: "12px" }}>
                        {positionDisplay}
                        {positionDisplay && categoryDisplay ? (
                          <span className="mr-8 ml-8">|</span>
                        ) : (
                          ""
                        )}
                        {categoryDisplay}
                      </p>
                    </div>
                  )}
                  <div className="flex flex-align-center">
                    {email && (
                      <p className="flex flex-align-center mr-8">
                        <i className="material-icons large-text accent-text-secondary mr-4">
                          email
                        </i>
                        <a
                          className="small-text link-text-secondary"
                          href={`mailto:${email}`}
                        >
                          {email}
                        </a>
                      </p>
                    )}
                    {cell && (
                      <p className="flex flex-align-center">
                        <i className="material-icons large-text accent-text-secondary mr-4">
                          phone
                        </i>
                        <a
                          className="small-text link-text-secondary"
                          href={`tel:${cell}`}
                        >
                          {cell}
                        </a>
                      </p>
                    )}
                  </div>
                </div>
                {spouseShliachId && (
                  <div className="mr-16 mobile-mr-0 mobile-mt-16">
                    <p className="uppercase-text accent-text small-text mb-4">
                      Spouse
                    </p>
                    <div className="flex flex-align-center">
                      {spouseProfileImageURL ? (
                        <img
                          alt="spouse-profile"
                          className="mr-16 profile-image-sm"
                          src={spouseProfileImageURL}
                        />
                      ) : (
                        <div className="profile-image-sm-placeholder" />
                      )}
                      <p>
                        {formatFullName(
                          spouseFirstName,
                          spouseLastName,
                          spouseTitleDisplay,
                        )}
                      </p>
                    </div>
                  </div>
                )}
                {!!numberOfChildren && (
                  <div className="mr-16 mobile-mr-0 mobile-mt-16">
                    <p className="uppercase-text accent-text small-text mb-4">
                      Children
                    </p>
                    <div className="flex flex-align-center">
                      {familyImageURL ? (
                        <img
                          alt="family-profile"
                          className="mr-16 profile-image-sm"
                          src={familyImageURL}
                          style={{
                            height: "32px",
                            width: "32px",
                            objectFit: "cover",
                            borderRadius: "10%",
                          }}
                        />
                      ) : (
                        <div className="profile-image-sm-placeholder" />
                      )}
                      <p className="accent-text-secondary">
                        {numberOfChildren}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-align-center flex-justify-end social-links mobile-mt-8">
            {facebookURL && (
              <a
                className="link-text"
                href={facebookURL}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/images/social-icons/facebook.png" alt="facebook" />
              </a>
            )}
            {instagramURL && (
              <a
                className="link-text ml-8"
                href={instagramURL}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/images/social-icons/instagram.png" alt="instagram" />
              </a>
            )}
            {twitterURL && (
              <a
                className="link-text ml-8"
                href={twitterURL}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/images/social-icons/twitter.png" alt="twitter" />
              </a>
            )}
            {snapchatURL && (
              <a
                className="link-text ml-8"
                href={snapchatURL}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/images/social-icons/snapchat.png" alt="snapchat" />
              </a>
            )}
          </div>
        </div>
        <ChabadHouseDetails chabadHouse={primaryChabadHouse} />
      </div>
    );
  }
}
