import React, { useState, useRef } from "react";
import debounce from "lodash.debounce";

/**
 * Search component to use within the filterComponent for functional PaginatedTable
 */
export default function Search(props) {
  const {
    value,
    onSearch,
    name = "se",
    searchDelay = 500,
    debounced = true,
    placeholder = "Search",
    className = "search-input",
    style = {},
  } = props;

  const [searchValue, setSearchValue] = useState(value || "");

  // searchFunc must be passed into debouncedSearch instead of using the onSearch prop
  // else it causes stale props issues in onSearch
  const debouncedSearch = useRef(
    debounce((searchFunc, value) => searchFunc(name, value), searchDelay),
  );

  const onChange = (val) => {
    setSearchValue(val);
    if (debounced) {
      debouncedSearch.current(onSearch, val);
    } else {
      onSearch(name, val);
    }
  };

  return (
    <div className={className} style={style}>
      <input
        name={name}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        type="text"
        value={searchValue}
      />
      <i className="material-icons accent-text-secondary pointer">search</i>
    </div>
  );
}
