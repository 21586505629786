import React from "react";
import Modal from "../../../components/Modal";

export default class SummerCampConfirmationModal extends React.PureComponent {
  confirm = () => {
    this.props.onConfirm();
    this.props.onClose();
  };

  render() {
    const { onClose, message, show } = this.props;

    return (
      <Modal show={show}>
        <div className="modal-container" key="campConfirmationModal">
          <div
            className="modal card confirmation-modal"
            style={{ maxWidth: "440px", padding: "32px 32px 24px 32px" }}
          >
            <p className="medium-text fw-500">Confirmation</p>
            <p className="accent-text mt-16" style={{ lineHeight: "1.2" }}>
              {message}
            </p>
            <div className="modal-btns">
              <p className="link-text uppercase-text fw-700" onClick={onClose}>
                Cancel
              </p>
              <p
                className="link-text uppercase-text ml-40 fw-700"
                onClick={this.confirm}
              >
                Confirm
              </p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
