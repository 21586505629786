import React from "react";
import EventsStudentsExportButton from "../manage/students/EventsStudentsExportButton";
import RsvpListActions from "../../shared/list/RsvpListActions";
import { AuthRequest } from "../../../../../lib";

export default function EventSchedulesListActions(props) {
  const { hasScheduledEvents, rsvpEnrollmentID, pageRoute } = props;

  const getRegistrationsForExport = async () => {
    const response = await AuthRequest.get(
      `RsvpEnrollments/${rsvpEnrollmentID}/events/registrations?export=true`,
    );
    return response.data.payload && response.data.payload.results;
  };

  return (
    <RsvpListActions
      rsvpType="event"
      hasItems={hasScheduledEvents}
      paramsForNew={{
        rsvpItemId: "new",
        pageView: "events",
      }}
      ExportButtonComponent={EventsStudentsExportButton}
      getRegistrationsForExport={getRegistrationsForExport}
      pageRoute={pageRoute}
    />
  );
}
