import { TripActions } from "./actions";

export const TripState = {
  name: "trip",
  persist: false,
  defaults: {
    loading: false,
  },
  handlers: {
    [TripActions.SET_TRIP_EVENT_LOADING]: function setTripEventLoading(
      state,
      action,
    ) {
      return {
        ...state,
        event: {
          loading: !!action.payload,
        },
      };
    },
    [TripActions.SET_TRIP_ENROLLMENT_LOADING]:
      function setTripEnrollmentLoading(state, action) {
        return {
          ...state,
          enrollment: {
            loading: !!action.payload,
          },
        };
      },
    [TripActions.SET_SUMBIT_ENROLLMENT_LOADING]:
      function setSubmitEnrollmentLoading(state, action) {
        return {
          ...state,
          enrollment: {
            submitting: !!action.payload,
          },
        };
      },
    [TripActions.SET_TRIP_SCHEDULE_LOADING]: function setTripScheduleLoading(
      state,
      action,
    ) {
      return {
        ...state,
        schedule: {
          loading: !!action.payload,
        },
      };
    },
    [TripActions.SET_TRIP_REGISTRATIONS_LOADING]:
      function setTripRegistrationsLoading(state, action) {
        return {
          ...state,
          registrations: {
            loading: !!action.payload,
          },
        };
      },
    [TripActions.SET_TRIP_REGISTRATION_LOADING]:
      function setTripRegistrationLoading(state, action) {
        return {
          ...state,
          registration: {
            loading: !!action.payload,
          },
        };
      },
    [TripActions.SET_TRIP_METRICS_LOADING]: function setTripMetricsLoading(
      state,
      action,
    ) {
      return {
        ...state,
        metrics: {
          loading: !!action.payload,
        },
      };
    },
    [TripActions.UPDATE_TRIP]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    [TripActions.SET_TRIP_SETTINGS_LOADING]: function setTripSettingsLoading(
      state,
      action,
    ) {
      return {
        ...state,
        settings: {
          loading: !!action.payload,
        },
      };
    },
    [TripActions.SET_TRIP_ENROLLMENT_FORM_SETTINGS]: (state, action) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    },
  },
};
