import React from "react";
import Select from "react-select";
import Loader from "./Loader";

export default class PaginatedList extends React.Component {
  state = {
    initialized: false,
    scrollPage: 1,
  };

  componentDidMount() {
    this.loadInitialData();
  }

  loadInitialData = () => {
    const { loadData, page, resultsView } = this.props;
    loadData(page, resultsView);

    //we have the 'initialized' state property that is initially set to 'false' to ensure that we don't show the error/no results displays when the list mounts, before the action to load data is called and loading begins
    //we set loading in the render to true if !initialized yet
    //we set 'initialized' to true after a 20ms pause to allow redux state to be updated (cannot await loadData because in some cases that call only updates the URL which then triggers the loading action)
    setTimeout(() => this.setState({ initialized: true }), 20);
  };

  getRows() {
    const { records, renderRecords, renderRow } = this.props;
    return renderRecords
      ? renderRecords(records)
      : records.map((item, index) => renderRow(item, index));
  }

  getResultsView(increment) {
    let options = Array(3)
      .fill()
      .map((_, i) => ++i * increment)
      .map((resultSize) => ({ value: resultSize, label: resultSize }));
    const { resultsView, totalCount } = this.props;

    return (
      totalCount > 10 && (
        <div>
          <p className="accent-text-dark flex flex-align-center">View </p>
          <Select
            className="view-select"
            classNamePrefix="view-select"
            defaultValue={{
              value: resultsView,
              label: resultsView,
            }}
            options={options}
            onChange={(e) => this.changeResultsView(e)}
          />
        </div>
      )
    );
  }

  changeResultsView(event) {
    const { loadData, page } = this.props;

    //pass in the event value as the second prop instead of the resultsView coming from props
    loadData(page, event.value);
    window.scrollTo({ left: 0, top: 0 });
  }

  getPaginationFooter() {
    const { loading, paginationClassName, resultsView, totalCount } =
      this.props;

    let numberOfPages = Math.ceil((totalCount || 0) / resultsView);
    if (numberOfPages < 2) {
      return null;
    }

    let pagesArray = Array(numberOfPages)
      .fill()
      .map((_, i) => i + 1);
    const page = parseInt(this.props.page, 10);

    if (pagesArray.length > 10) {
      let pagesArrayCopy = pagesArray.slice(0, pagesArray.length);
      let startPg = pagesArrayCopy.shift();
      let endPg = pagesArrayCopy.pop();
      if (page < 7 && page !== 6) {
        pagesArray = pagesArray.slice(0, 6);
        pagesArray.push("afterPages", endPg);
      } else if (page === 6) {
        pagesArray = pagesArray.slice(page - 3, page + 2);
        pagesArray.unshift(startPg, "beforePages");
        pagesArray.push("afterPages", endPg);
      } else if (page + 4 < endPg) {
        pagesArray = pagesArray.slice(page - 3, page + 2);
        pagesArray.unshift(startPg, "beforePages");
        pagesArray.push("afterPages", endPg);
      } else {
        pagesArray = pagesArray.slice(pagesArray.length - 6, pagesArray.length);
        pagesArray.unshift(startPg, "beforePages");
      }
    } else {
      pagesArray = pagesArray.slice(0, 10);
    }

    return (
      <ul
        className={`pagination ${loading ? "disabled" : ""} ${
          paginationClassName ? paginationClassName : ""
        }`}
      >
        {page > 1 ? (
          <li
            key="prev"
            className="pagination-arrow"
            onClick={this.paginate.bind(this, page - 1)}
          >
            <i className="material-icons">keyboard_arrow_left</i>
          </li>
        ) : null}

        {pagesArray.map((pageNumber) =>
          typeof pageNumber === "number" ? (
            <li
              key={pageNumber}
              className={
                page.toString() === pageNumber.toString() ? "active" : ""
              }
              onClick={() => this.paginate(pageNumber)}
            >
              {pageNumber}
            </li>
          ) : (
            <li key={pageNumber}>...</li>
          ),
        )}

        {page === numberOfPages ? null : (
          <li
            key="next"
            className="pagination-arrow"
            onClick={this.paginate.bind(this, page + 1)}
          >
            <i className="material-icons">keyboard_arrow_right</i>
          </li>
        )}
      </ul>
    );
  }

  paginate(page) {
    const { loadData, loading, resultsView } = this.props;

    if (loading) {
      return;
    }

    loadData(page, resultsView);
    window.scrollTo({ left: 0, top: 0 });
  }

  onScroll = (event) => {
    const { loadData, loading, page, resultsView, totalCount } = this.props;
    const hasMore = page < Math.ceil((totalCount || 0) / resultsView);

    if (
      !loading &&
      hasMore &&
      event.target.scrollHeight -
        event.target.clientHeight -
        event.target.scrollTop <=
        100
    ) {
      this.setState((state) => {
        loadData(state.scrollPage + 1, resultsView);
        return {
          ...state,
          scrollPage: state.scrollPage + 1,
        };
      });
    }
  };

  render() {
    const { initialized, scrollPage } = this.state;
    const {
      className,
      errorMessage,
      isPhone,
      noRecordsDisplay,
      records,
      renderHeaderRow,
      resultsIncrement,
      showResultsView,
      showTotalCount,
      success,
      totalCount,
    } = this.props;

    const loading = !initialized || this.props.loading;

    return loading && (!isPhone || scrollPage === 1) ? (
      <div style={{ clear: "both" }}>
        <Loader />
      </div>
    ) : (
      <div>
        {(showTotalCount || showResultsView) && (
          <div className="flex flex-justify-space flex-align-center mb-24">
            <div className="flex">
              {showTotalCount && (
                <p className="accent-text-dark">
                  <span className="fw-700">{totalCount}</span> Results
                </p>
              )}
              {showResultsView ? this.getResultsView(resultsIncrement) : null}
            </div>
          </div>
        )}
        {!loading && !records.length ? (
          <div className="paginated-list-empty-state">
            {!success ? (
              <React.Fragment>
                <img src="/images/error.svg" alt="error robot" height="240" />
                <p className="xxl-text mt-24 mb-16">An error has occured</p>
                <p className="medium-text accent-text fw-500">
                  {errorMessage ||
                    "Our server is being stubborn, please try again"}
                </p>
              </React.Fragment>
            ) : (
              noRecordsDisplay || (
                <React.Fragment>
                  <img
                    src="/images/no_results.svg"
                    height="200"
                    alt="no results"
                  />
                  <p className="xxl-text mt-24 mb-16">No results found</p>
                </React.Fragment>
              )
            )}
          </div>
        ) : (
          <React.Fragment>
            <div
              className={`${isPhone ? "table full-width-table" : "table"} ${
                className ? className : ""
              }`}
            >
              {renderHeaderRow && renderHeaderRow()}
              <div
                className={isPhone ? "table-rows" : null}
                onScroll={isPhone ? this.onScroll : null}
              >
                {this.getRows()}
                {isPhone && loading && (
                  <div className="mobile-loader">
                    <Loader />
                  </div>
                )}
              </div>
            </div>
            {!isPhone && records.length > 0 && this.getPaginationFooter()}
          </React.Fragment>
        )}
      </div>
    );
  }
}
