import {
  formatDateTimeForInput,
  hasValue,
  isPositiveIntBelowMax,
  removeEmptyFromObj,
} from "../../lib";
import _cloneDeep from "lodash.clonedeep";
import moment from "moment";

export function checkOverlappingSchedules(tours, enrolledTours) {
  // get list of all schedules
  const tourSchedules = [];
  enrolledTours.forEach((tour) =>
    tour.tourScheduleIDs.forEach((tourScheduleID) => {
      const tourSchedule = tours
        .find((t) => t.id === tour.tourID)
        .schedules.find((s) => s.id === tourScheduleID);
      tourSchedules.push({
        tourScheduleID,
        tourId: tour.id || tour.tourID,
        start: new Date(tourSchedule.time),
        end: new Date(tourSchedule.calculatedEndTime),
        preventOverlap:
          tour.isOhelVisit ||
          tour.autoEnrollStudentsOverride ||
          tours.find((t) => t.id === tour.tourID)?.autoEnrollStudents,
      });
    }),
  );
  // compare each tour schedule with all other tour schedules to check for overlap
  // create set to avoid duplicates vs array bec in a double loop, each item is compared 2x
  const overlappingSchedules = new Set();
  tourSchedules.forEach((a) => {
    tourSchedules.forEach((b) => {
      if (a.tourId === b.tourId) return; // disregard the same tour or same schedule

      if (a.preventOverlap || b.preventOverlap) {
        if (checkOverlap([a, b])) {
          overlappingSchedules.add(a.tourId);
          overlappingSchedules.add(b.tourId);
        }
      }
    });
  });
  return Array.from(overlappingSchedules);
}

//function taken from https://gist.github.com/derickbailey/63241c2064471b9d5b6a
function checkOverlap(dateRanges) {
  const sortedRanges = dateRanges.sort((previous, current) => {
    // get the start date from previous and current
    const previousTime = previous.start.getTime();
    const currentTime = current.start.getTime();
    // if the previous is earlier than the current
    if (previousTime < currentTime) return -1;
    // if the previous time is the same as the current time
    if (previousTime === currentTime) return 0;
    // if the previous time is later than the current time
    return 1;
  });

  const result = sortedRanges.reduce((result, current, idx, arr) => {
    // get the previous range
    if (idx === 0) return result;
    const previous = arr[idx - 1];
    // check for any overlap
    const previousEnd = previous.end.getTime();
    const currentStart = current.start.getTime();
    const overlap = previousEnd > currentStart;
    // store the result
    if (overlap) result = true;
    return result;
    // seed the reduce
  }, false);
  // return the final results
  return result;
}

export function getFormValidation() {
  const {
    enrollmentSettings: {
      acceptedTerms,
      attendees,
      billing,
      billing: {
        cardHolderFullName,
        ccRequired,
        hasCompleteCardInfo,
        useCardOnFile,
      },
      campuses,
      didAcceptTermsAndConditions,
      emailRecipients,
      latestCancellationDateOverride,
      studentRegistrationEndDateOverride,
      tours,
      tracks,
    },
  } = this.state;
  const {
    event: {
      latestCancellationDate,
      studentRegistrationEndDate,
      termsAndConditions,
      tours: toursInfo,
    },
  } = this.props.trip;

  const formValidation = _cloneDeep(this.state.validation);

  /*** General Settings ***/
  /* one campus is required */
  if (campuses && campuses.length < 1) {
    formValidation.campuses = "At least one campus is required";
  } else {
    delete formValidation.campuses;
  }

  /* one chaperone is required */
  if (!attendees.length) {
    formValidation.attendees = true;
  } else {
    delete formValidation.attendees;
  }

  /* one email recipient is required */
  if (!emailRecipients?.length) {
    formValidation.emailRecipients = "At least one email recipient is required";
  } else {
    delete formValidation.emailRecipients;
  }

  /*** Pricing ***/
  /* check that registration end and latest cancellation dates are before COCIs */
  if (
    latestCancellationDateOverride >
    formatDateTimeForInput(latestCancellationDate)
  ) {
    formValidation.latestCancellationDate = true;
  } else {
    delete formValidation.latestCancellationDate;
  }

  if (
    studentRegistrationEndDateOverride >
    formatDateTimeForInput(studentRegistrationEndDate)
  ) {
    formValidation.studentRegistrationEndDate = true;
  } else {
    delete formValidation.studentRegistrationEndDate;
  }

  tracks.forEach((track) => {
    const { regularPriceOverride, trackID } = track;

    const trackPriceFieldName = `track-${trackID}-regularPriceOverride`;
    if (!regularPriceOverride && regularPriceOverride !== 0) {
      formValidation[trackPriceFieldName] = true;
    } else {
      delete formValidation[trackPriceFieldName];
    }
  });

  /*** Tours ***/
  formValidation.tours = [];
  /* check that enrolled tour has at least one schedule */
  tours.map((tour) => {
    if (
      tour.tourScheduleIDs < 1 ||
      transportationIsMissing(tour, toursInfo) ||
      !hasValue(tour.numberOfChaperones) ||
      !isPositiveIntBelowMax(tour.numberOfChaperones, attendees.length, true)
    ) {
      let index = formValidation.tours.indexOf(tour.tourID);
      if (index < 0) {
        formValidation.tours.push(tour.tourID);
      }
    }
    return "";
  });
  if (!formValidation.tours.length) {
    delete formValidation.tours;
  }

  formValidation.overlappingSchedules = checkOverlappingSchedules(
    toursInfo,
    tours,
  );
  if (!formValidation.overlappingSchedules.length) {
    delete formValidation.overlappingSchedules;
  }

  /*** Terms and Conditions ***/
  /* check if all terms and conditions are accepted */
  if (
    termsAndConditions.filter((term) => term.appliesTo === "Shluchim")
      .length !== acceptedTerms.length &&
    !didAcceptTermsAndConditions
  ) {
    formValidation.didAcceptTermsAndConditions =
      "please accept all terms and conditions";
  } else {
    delete formValidation.didAcceptTermsAndConditions;
  }

  /*** Payment ***/
  /* check if required cc card info is filled out */
  if (
    ccRequired &&
    !useCardOnFile &&
    (!hasCompleteCardInfo || !cardHolderFullName)
  ) {
    formValidation.hasCompleteCardInfo = true;
  } else {
    delete formValidation.hasCompleteCardInfo;
  }

  /* check if required billing address info is filled out */
  if (
    ccRequired &&
    !useCardOnFile &&
    (!billing.address.address1 ||
      !billing.address.country ||
      !billing.address.city)
  ) {
    formValidation.hasCompleteBillingAddress = true;
  } else {
    delete formValidation.hasCompleteBillingAddress;
  }
  return formValidation;
}

function transportationIsMissing(enrolledTour, originalTours) {
  const originalTour = originalTours.find(
    (tour) => tour.id === enrolledTour.tourID,
  );
  return originalTour.hasTransportation && !enrolledTour.transportationOption;
}

export function hasValidationErrors() {
  const { validation } = this.state;
  return Object.keys(validation).length > 0;
}

export function getValidationErrorMessage() {
  const { validation } = this.state;

  const requiredFieldsError = "Please complete/fix all required fields";
  const overlapError = "adjust any overlapping tours.";

  if (validation.tours) {
    return `${requiredFieldsError}${
      validation.overlappingSchedules ? " and " + overlapError : "."
    }`;
  } else if (validation.overlappingSchedules) {
    return "Please " + overlapError;
  }
  if (validation.attendees) {
    return "Please select at least 1 Shliach or Chaperone.";
  }
  if (validation.emailRecipients) {
    return "Please select at least 1 email recipient.";
  }
  return "";
}

export function getValuesForSubmission() {
  const {
    enrollmentSettings,
    enrollmentSettings: {
      billing,
      latestCancellationDateOverride,
      studentRegistrationEndDateOverride,
      tracks: tracksOverride,
    },
  } = this.state;
  const {
    account: { credCardInfo },
    trip: {
      event: { latestCancellationDate, studentRegistrationEndDate, tracks },
    },
  } = this.props;

  const stripeValues = billing.useCardOnFile
    ? {
        name: credCardInfo && credCardInfo.cardHolderFullName,
        address_line1: credCardInfo && credCardInfo.address.address1,
        address_line2: credCardInfo && credCardInfo.address.address2,
        address_city: credCardInfo && credCardInfo.address.city,
        address_state: credCardInfo && credCardInfo.address.state,
        address_zip: credCardInfo && credCardInfo.address.zip,
        address_country: credCardInfo && credCardInfo.address.country,
      }
    : {
        name: billing.cardHolderFullName,
        address_line1: billing.address.address1,
        address_line2: billing.address.address2,
        address_city: billing.address.city,
        address_state: billing.address.state,
        address_zip: billing.address.zip,
        address_country: billing.address.country,
      };

  const updatedEnrollmentSettings = _cloneDeep(enrollmentSettings);

  //set attendees object structure for POST
  let attendees = enrollmentSettings.attendees.map((attendee) => {
    if (attendee.value) {
      return {
        firstName: attendee.key.firstName,
        lastName: attendee.key.lastName,
        isChaperone: !attendee.key.isShliach,
        personID: attendee.key.personID,
      };
    }
    return attendee;
  });

  updatedEnrollmentSettings.attendees = attendees;

  //remove placeholders from tracks
  updatedEnrollmentSettings.tracks.map((track) => {
    delete track.earlyBirdPlaceholder;
    delete track.regularPlaceholder;
    return updatedEnrollmentSettings.tracks;
  });

  //remove isOhelVisit and schedules from tours
  updatedEnrollmentSettings.tours.map((tour) => {
    delete tour.isOhelVisit;
    delete tour.schedules;
    return updatedEnrollmentSettings.tours;
  });

  //remove acceptedTerms add didAcceptTerms
  delete updatedEnrollmentSettings.acceptedTerms;
  updatedEnrollmentSettings.didAcceptTermsAndConditions = true;

  //add address1, city, and country to billing if using card on file
  if (updatedEnrollmentSettings.billing.useCardOnFile) {
    updatedEnrollmentSettings.billing.address.address1 =
      credCardInfo && credCardInfo.address.address1;
    updatedEnrollmentSettings.billing.address.city =
      credCardInfo && credCardInfo.address.city;
    updatedEnrollmentSettings.billing.address.country =
      credCardInfo && credCardInfo.address.country;
  }

  //only pass in names for billing promo codes
  updatedEnrollmentSettings.billingPromoCodes =
    updatedEnrollmentSettings.billingPromoCodes &&
    updatedEnrollmentSettings.billingPromoCodes.map((promo) => {
      return promo.code;
    });

  //set saveNewCardToFile to false if no cc info provided (!ccRequired)
  if (!billing.ccRequired && billing.saveNewCardToFile) {
    updatedEnrollmentSettings.billing.saveNewCardToFile = false;
  }

  //remove date overrides if set to same value as initial dates
  if (
    studentRegistrationEndDateOverride &&
    moment(studentRegistrationEndDate).isSame(
      studentRegistrationEndDateOverride,
    )
  ) {
    updatedEnrollmentSettings.studentRegistrationEndDateOverride = null;
  }
  if (
    latestCancellationDateOverride &&
    moment(latestCancellationDate).isSame(latestCancellationDateOverride)
  ) {
    updatedEnrollmentSettings.latestCancellationDateOverride = null;
  }

  //remove fee overrides if set to same value as initial fees
  for (let i = 0; i < tracksOverride.length; i++) {
    if (
      tracksOverride[i].earlyBirdPriceOverride &&
      tracks[i].earlyBirdStudentPrice ===
        parseInt(tracksOverride[i].earlyBirdPriceOverride, 10)
    ) {
      updatedEnrollmentSettings.tracks[i].earlyBirdPriceOverride = null;
    }
    if (
      tracksOverride[i].regularPriceOverride &&
      tracks[i].regularStudentPrice ===
        parseInt(tracksOverride[i].regularPriceOverride, 10)
    ) {
      updatedEnrollmentSettings.tracks[i].regularPriceOverride = null;
    }
  }

  removeEmptyFromObj(updatedEnrollmentSettings);

  return { updatedEnrollmentSettings, stripeValues };
}
