import React from "react";
import PaginatedTable from "../../../components/PaginatedTableDeprecated";
import { formatFullName, Navigation, PageURL } from "./../../../lib";
import { Button, IconButton, Tooltip, Typography } from "@material-ui/core";

export default class ReviewRecipients extends React.PureComponent {
  getRecipientCount = () => {
    const {
      singleRecipients,
      bulkRecipients: { recipients: bulkRecipients } = {},
    } = this.props;

    return (singleRecipients?.length || 0) + (bulkRecipients?.length || 0);
  };

  getNavigationURL = (pageNumber, resultsView) => {
    const {
      pageRoute: { page, params, query },
    } = this.props;

    const url = PageURL.to(page, params, {
      ...query,
      p: pageNumber,
      r: resultsView,
    });
    return url;
  };

  removeRecipients = (recipients) => {
    const {
      actions: { removeRecipients },
      pageRoute: { query: { p: page = 1, r: resultsView = 10 } = {} } = {},
    } = this.props;
    removeRecipients(recipients, page, resultsView);
  };

  render() {
    const {
      actions: { getPaginatedRecipients },
      goToNextStep,
      page: { nextStep },
      pageRoute,
      pageRoute: {
        page: urlPage,
        query,
        query: { edit, p: page = 1, r: resultsView = 10 } = {},
      } = {},
      recipientPage,
      ui: { isPhone },
    } = this.props;

    return (
      <div className="page">
        <div className="sms-wizard-container">
          <Typography variant="h6" className="mb-24 mobile-mb-16">
            Review recipients
          </Typography>
          <div className="mb-12">
            The following students will receive this SMS message. You may remove
            individual students from the list.
          </div>
        </div>
        <div>
          <div className="mt-24 sms-wizard-container">
            <div>
              <PaginatedTable
                name="Students"
                page={page}
                pageRoute={pageRoute}
                records={recipientPage || []}
                loadData={getPaginatedRecipients}
                getURL={this.getNavigationURL}
                renderHeaderRow={() => <Header />}
                renderRow={(student, index) => (
                  <ListRow
                    student={student}
                    isPhone={isPhone}
                    removeStudent={(st) => {
                      this.removeRecipients([st]);
                    }}
                    key={index}
                  />
                )}
                resultsIncrement={10}
                resultsView={resultsView}
                showResultsTotal={true}
                showResultsView={true}
                success={true}
                totalCount={this.getRecipientCount()}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-justify-space flex-align-center mt-24">
          <Button
            disableElevation
            variant="contained"
            size="large"
            className="sms-button-secondary"
            onClick={() => {
              if (edit) {
                Navigation.go(
                  PageURL.to(
                    urlPage,
                    {
                      pageView: "selectRecipients",
                    },
                    query,
                  ),
                );
              } else {
                Navigation.goBack();
              }
            }}
          >
            Back
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            className="ml-16"
            size="large"
            onClick={() => {
              goToNextStep(nextStep);
            }}
          >
            {"Compose Message"}
          </Button>
        </div>
      </div>
    );
  }
}

const ListRow = (props) => {
  const {
    isPhone,
    removeStudent,
    student: {
      chabadHouseTagNames,
      cell,
      graduationYear,
      isJewish,
      studentFirstName,
      studentLastName,
      campusName,
      rsvpEventsParticipatedIn,
      rsvpResourcesParticipatedIn,
      rsvpTypesParticipatedIn,
      programsParticipatedIn,
    },
    student,
  } = props;

  return isPhone ? (
    <div className="paginated-table-row students-list-table-row pointer sms-review-mobile">
      <div className="first-row">
        <div className="student-name">
          {formatFullName(studentFirstName, studentLastName)}
          {!cell && "  " && (
            <Tooltip
              title="This student has no cell phone number on file and will not get the text message"
              arrow
            >
              <i className="material-icons sms-warning-icon">warning</i>
            </Tooltip>
          )}
        </div>
        <div className="accent-text">
          {isJewish ? "Student identifies as Jewish" : ""}
        </div>
        <div className="tag-name">
          {chabadHouseTagNames && chabadHouseTagNames[0]}
        </div>
      </div>
      <div className="second-row">
        {campusName}
        {campusName && graduationYear && (
          <div className="dash-outer-div">
            <div className="dash-inner-div">
              <hr />
            </div>
          </div>
        )}
        {graduationYear && "Grad year "} {graduationYear}
      </div>
      <div className="program-participated">
        <div>
          {(programsParticipatedIn ||
            rsvpEventsParticipatedIn ||
            rsvpResourcesParticipatedIn ||
            rsvpTypesParticipatedIn) &&
            "Participated in "}
          {(programsParticipatedIn && programsParticipatedIn[0]) ||
            (rsvpEventsParticipatedIn && rsvpEventsParticipatedIn[0]) ||
            (rsvpResourcesParticipatedIn && rsvpResourcesParticipatedIn[0]) ||
            (rsvpTypesParticipatedIn && rsvpTypesParticipatedIn[0])}
        </div>
        <div>
          <IconButton
            aria-label="delete"
            style={{ padding: "0px" }}
            onClick={(e) => {
              removeStudent(student);
            }}
          >
            <i className="material-icons remove-icon">delete</i>
          </IconButton>
        </div>
      </div>
    </div>
  ) : (
    <div className="paginated-table-row students-list-table-row pointer sms-review-grid-cols">
      <div className="desktop-display-contents mobile-flex flex-justify-space full-width">
        <div className="desktop-display-contents ">
          <div>
            {formatFullName(studentFirstName, studentLastName)}
            {!cell && "  " && (
              <Tooltip
                title="This student has no cell phone number on file and will not get the text message"
                arrow
              >
                <i className="material-icons sms-warning-icon">warning</i>
              </Tooltip>
            )}
          </div>
          <div>{chabadHouseTagNames && chabadHouseTagNames[0]}</div>
          <div>{isJewish ? "Yes" : isJewish === "false" ? "No" : ""}</div>
          <div>{graduationYear}</div>
          {/* programs filter data */}
          <div>
            {(programsParticipatedIn && programsParticipatedIn[0]) ||
              (rsvpEventsParticipatedIn && rsvpEventsParticipatedIn[0]) ||
              (rsvpResourcesParticipatedIn && rsvpResourcesParticipatedIn[0]) ||
              (rsvpTypesParticipatedIn && rsvpTypesParticipatedIn[0])}
          </div>
          <div>{campusName}</div>
          <div className="remove-icon-container" style={{ textAlign: "end" }}>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                removeStudent(student);
              }}
            >
              <i className="material-icons link-text-secondary accent-text">
                delete
              </i>
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const Header = () => (
  <div className="paginated-table-header-row students-list-table-row sms-review-grid-cols">
    <p className="fw-900">Name</p>
    <p className="fw-900">Group</p>
    <p className="fw-900">Jewish</p>
    <p className="fw-900">Grad year</p>
    <p className="fw-900">Participated in</p>
    <p className="fw-900">School</p>
  </div>
);
