import React from "react";

import Loader from "../../../components/Loader";
import PieChart from "./MetricsPieChart";

import { formatNumber, pluralizeText, formatCurrency } from "../../../lib";
import moment from "moment";

export default class TripMetrics extends React.PureComponent {
  componentDidMount() {
    const { id } = this.props.trip.enrollment;
    this.props.getTripMetrics(id);
  }

  render() {
    const {
      event: { isTravelTrip, programScheduleName, tripEndDate },
      metrics: {
        chabadHouseOwes,
        cociOwes,
        femaleStudentCount,
        femaleStudentPercentage,
        loading,
        maleStudentCount,
        maleStudentPercentage,
        studentClasses,
        studentsRegisteredCount,
        tourSchedules,
        tracks,
        success,
      } = {},
    } = this.props.trip;
    const eventFeesPaid =
      !loading &&
      success &&
      moment().isAfter(moment(tripEndDate).add(2, "days"));

    return (
      <div className="card full-width trip-card trip-metrics">
        <div className="mb-24">
          <p className="xl-text fw-700 mb-24">
            My {programScheduleName || "Trip"} Metrics
          </p>
        </div>
        {loading ? (
          <div>
            <Loader />
          </div>
        ) : success ? (
          <React.Fragment>
            <div className="number-metrics">
              <div>
                <p className="xxl-text fw-700 mb-8">
                  {formatNumber(studentsRegisteredCount)}
                </p>
                <p>
                  Accepted {pluralizeText("Student", studentsRegisteredCount)}
                </p>
              </div>
              {!isTravelTrip && (
                <>
                  <div>
                    <p className="xxl-text fw-700 mb-8">
                      ${formatCurrency(cociOwes)}
                    </p>
                    <p>COCI Owe{eventFeesPaid ? "d" : "s"} Me</p>
                  </div>
                  <div>
                    <p className="xxl-text fw-700 mb-8">
                      ${formatCurrency(chabadHouseOwes)}
                    </p>
                    <p>I Owe{eventFeesPaid ? "d" : ""} COCI</p>
                  </div>
                </>
              )}
            </div>
            <div
              className={tourSchedules?.length ? "metrics-two-column-grid" : ""}
            >
              <div className="pie-chart-metrics">
                <div>
                  <p className="fw-700 medium-text mb-16">
                    Accepted students by gender
                  </p>
                  <div>
                    <PieChart
                      data={[
                        {
                          name: "Male Students",
                          value: maleStudentCount,
                          percentageValue: maleStudentPercentage,
                        },
                        {
                          name: "Female Students",
                          value: femaleStudentCount,
                          percentageValue: femaleStudentPercentage,
                        },
                      ]}
                    />
                  </div>
                </div>
                <div>
                  <p className="fw-700 medium-text mb-16">
                    Accepted students by track
                  </p>
                  <PieChart
                    data={
                      tracks &&
                      tracks.map(
                        ({
                          trackName,
                          trackRegistrationCount,
                          trackRegistrationPercentage,
                        }) => ({
                          name: trackName,
                          value: trackRegistrationCount,
                          percentageValue: trackRegistrationPercentage,
                        }),
                      )
                    }
                  />
                </div>
                <div>
                  <p className="fw-700 medium-text mb-16">
                    Accepted students by class
                  </p>
                  <PieChart
                    data={
                      studentClasses &&
                      studentClasses.map(
                        ({
                          classDescription,
                          classCount,
                          studentPercentage,
                        }) => ({
                          name: classDescription,
                          value: classCount,
                          percentageValue: studentPercentage,
                        }),
                      )
                    }
                  />
                </div>
              </div>
              <div className="tour-metrics">
                {tourSchedules &&
                  tourSchedules.map((tourSchedule) => {
                    const {
                      studentCount,
                      tourName,
                      tourScheduleName,
                      tripTourScheduleID,
                    } = tourSchedule;

                    return (
                      <div key={tripTourScheduleID}>
                        <div>
                          <div className="flex flex-justify-space flex-align-center">
                            <p className="large-text fw-700 mb-8">{tourName}</p>
                          </div>
                          <p className="accent-text-dark mb-0">
                            {tourScheduleName} - {formatNumber(studentCount)}{" "}
                            {pluralizeText("Student", studentCount)}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className="text-center">
            <img src="/images/error.svg" alt="error-robot" height="320" />
            <p
              className="text-center error-message mt-24"
              style={{ position: "relative" }}
            >
              Something went wrong.
            </p>
            <p
              className="text-center error-message mt-24"
              style={{ position: "relative" }}
            >
              Our server is being stubborn. Sorry for the inconvenience.
            </p>
          </div>
        )}
      </div>
    );
  }
}
