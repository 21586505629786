import { AppState } from "../types";

export const ActivitySelectors = {
  lastLoginAt(state: AppState) {
    return state.activity.login?.lastLoginAt;
  },
  loading(state: AppState) {
    return state.activity.loading;
  },
};
