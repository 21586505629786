import React from "react";

export default (props) => (
  <div
    className={`paginated-table-header-row ${
      props.showPaidDonated
        ? "rsvp-students-table-row-extended"
        : "rsvp-students-table-row"
    }`}
  >
    <p /> {/* Person classification icon */}
    <p className="medium-text fw-700 accent-text-dark">Name</p>
    <p className="medium-text fw-700 accent-text-dark">Email</p>
    <p className="medium-text fw-700 accent-text-dark">Phone</p>
    <p className="medium-text fw-700 accent-text-dark">Table</p>
    <p className="medium-text fw-700 accent-text-dark">Details</p>
    <p className="medium-text fw-700 accent-text-dark">Status</p>
    {props.showPaidDonated && (
      <p className="medium-text fw-700 accent-text-dark">Fee / Donation</p>
    )}
    <p /> {/* Delete icon */}
  </div>
);
