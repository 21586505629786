import React, { Fragment } from "react";
import CopyToClipboardTextbox from "../../components/CopyToClipboardTextbox";
import { RafflePaymentTypes } from "./constants";

export const composeFilterParams = (params) => {
  //page & resultsView sent with loadData from paginatedTable must override pageRoute query params, because data is fetched prior to params being refreshed
  if (params) {
    const {
      page,
      resultsView,
      searchTerm,
      enrollmentID,
      eventID,
      pageRoute,
      raffle,
    } = params;
    const {
      query: { p = 1, r = 10, q = "", assc, ref, pay, dt, onl, so },
    } = pageRoute;

    let cashTypeID;
    let paymentType = pay;
    //The payment method filter combines payment types and cash types into one selection - splitting it out here so it can be passed to the API as 2 separate fields
    if (pay?.includes("cash_")) {
      paymentType = RafflePaymentTypes.Cash;
      cashTypeID = pay.replace("cash_", "");
    }
    return [
      page || p,
      resultsView || r,
      searchTerm || q,
      enrollmentID || raffle.enrollmentDetails.id,
      eventID || raffle.details.id,
      ref && decodeURIComponent(ref),
      paymentType && decodeURIComponent(paymentType),
      cashTypeID && decodeURIComponent(cashTypeID),
      assc && decodeURIComponent(assc),
      dt && decodeURIComponent(dt),
      onl && decodeURIComponent(onl),
      (so && decodeURIComponent(so)) || "DateDesc",
    ];
  }
};
export const EmbedWebPageInstructionText = ({ paragraphTextClass }) => {
  return (
    <Fragment>
      <p className={paragraphTextClass}>
        To embed the Grand Draw web page into your own website, copy and paste
        this code.
      </p>
      <p className={paragraphTextClass}>
        Please note that this embed option comes with certain limitations, for
        example Apple Pay is not available for iframed sites, and potential
        scrolling issues.
      </p>
    </Fragment>
  );
};

export const EmbedWebPageIframeText = ({
  iframeStyle,
  paragraphTextClass,
  donorSiteIframeText,
}) => {
  return (
    <Fragment>
      <CopyToClipboardTextbox
        className="iframe-container copy-code-container flex flex-justify-space mb-16"
        id="raffle-code"
        label="Copy Code"
        multiline={true}
        text={donorSiteIframeText}
        style={iframeStyle}
      />
      <p className={paragraphTextClass}>
        Note that this code has been generated for your site specifically.
        Please do not share it with anyone else.
      </p>
      <p className={paragraphTextClass}>
        If you are embedding the Grand Draw web page onto your site with your
        own copyright information, you can add `?copy=false` to the iframe src
        url to hide the Grand Draw copyright information and display contact
        info instead.
      </p>
      <p className={paragraphTextClass}>
        This iframe has been set to be a full web-page. If your webpage is
        displaying a double scrollbar, you can edit the height of your iframe to
        accommodate your header and footer height. For example if you have a
        header and footer both 72px high, the way you would set the iframe
        height in CSS is as follows -{" "}
        <span style={{ fontWeight: "bold", color: "black" }}>
          height: calc(100vh - 144px)
        </span>
        . The 144px is the total of your 72px header + 72px footer. If you don't
        know your header/footer pixel height, or are still experiencing a double
        scrollbar after accommodating their heights, then use trial and error to
        adjust the subtracted height (144px). For further assistance, please
        contact us at{" "}
        <span className="link-text underline-text">
          <a href="mailto:help@chabadoncampus.org">help@chabadoncampus.org</a>
        </span>{" "}
        or{" "}
        <span className="link-text underline-text">
          <a href="tel:+1-718-510-8181,0">718 510 8181 x 0</a>
        </span>
        .
      </p>
    </Fragment>
  );
};
