import { AuthActions } from "../auth/actions";

export const LoginState = {
  name: "login",
  persist: false,
  defaults: {
    loading: false,
    success: undefined,
  },
  handlers: {
    [AuthActions.AUTH_ERROR]: function handleAuthError(state, action) {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response,
      };
    },
    [AuthActions.DO_GOOGLE_SIGNIN]: function (state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    [AuthActions.DO_BASIC_SIGNIN]: function (state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    [AuthActions.SIGNIN_COMPLETED]: function (state, action) {
      return {
        ...state,
        loading: false,
        success: true,
      };
    },
  },
};
