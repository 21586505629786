import {
  AuthRequest,
  setSentryUser,
  clearSentryUser,
  Navigation,
  removeAuthRequestToken,
  setAuthRequestToken,
  removeImpersonationAuthCookie,
  frontChatInit,
  loadScript,
  logRocketIdentifyUser,
  ApiCallErrorMessageHandler,
  AuthTypes,
} from "../../lib";
import { ActivityActions } from "../activity/actions";
import { SysActions } from "../sys/actions";
import Pages from "../../pages";

function navigateAfterSignin() {
  Navigation.redirect(Navigation.query["after"] || "/");
}

function navigateAfterSignout(autoSignin) {
  const url = "/login?after=" + encodeURIComponent(Navigation.locationURL);
  if (autoSignin) {
    // WARNING: Make sure the page reloads with whatever navigation is done here.
    // Otherwise, the API request that discovered the signout will get an error.
    Navigation.load(url);
  } else {
    // Avoiding a page reload is actually what disabled Google auto signin?
    Navigation.go(url);
  }
}

async function signinCompleted(dispatch, _, payload, trackLogin) {
  const {
    accountID,
    token,
    expiration,
    loggedInAs,
    person,
    data: { shliachID } = {},
    userEmail,
    userEmailHash,
  } = payload;
  setAuthRequestToken(token, expiration);
  if (accountID) {
    setSentryUser(accountID, person, userEmail);
  }
  dispatch({ type: AuthActions.SIGNIN_COMPLETED, payload });
  if (trackLogin) {
    dispatch(ActivityActions.trackLogin());
  }

  frontChatInit(userEmail, userEmailHash);

  logRocketIdentifyUser();

  await dispatch(SysActions.getSystemData());

  //if user is a shliach (excludes admins and CH personnel (interns) logging in to shluchim portal), dispatch getProfile action to block portal access if profile is incomplete
  if (shliachID && loggedInAs === AuthTypes.shliach) {
    dispatch(AuthActions.getEngagementAccess());
    // TODO when we tackle the profile complete screen restriction need to get if shliach profile complete and set that in redux
    // await dispatch(ProfileActions.getProfile(shliachID, true));
  }

  navigateAfterSignin();
}

async function disableGoogleIdentityAutoSelect() {
  // disable google identity auto-select upon signout or signin failure
  // see https://developers.google.com/identity/gsi/web/guides/automatic-sign-in-sign-out#sign-out
  await loadScript("https://accounts.google.com/gsi/client");
  const googleAccountId = window.google?.accounts?.id;
  if (googleAccountId) {
    googleAccountId.disableAutoSelect();
  }
}

export const AuthActions = {
  DO_BASIC_SIGNIN: "DO_BASIC_SIGNIN",
  DO_GOOGLE_SIGNIN: "DO_GOOGLE_SIGNIN",
  DO_SIGNOUT: "DO_SIGNOUT",
  SIGNIN_COMPLETED: "SIGNIN_COMPLETED",
  AUTH_ERROR: "AUTH_ERROR",
  SET_ENGAGEMENT_ACCESS: "SET_ENGAGEMENT_ACCESS",
  SET_ENGAGEMENT_ACCESS_LOADING: "SET_ENGAGEMENT_ACCESS_LOADING",

  doBasicSignin(payload) {
    return async function doingBasicSignin(dispatch, getState) {
      dispatch({ type: AuthActions.DO_BASIC_SIGNIN, payload });
      const response = await AuthRequest.post("auth/login/basic", {
        userName: payload.email,
        password: payload.password,
        authType: "shliach",
      }).catch((err) => {
        dispatch({ type: AuthActions.AUTH_ERROR, payload: err });
        setTimeout(
          () => dispatch({ type: AuthActions.AUTH_ERROR, payload: {} }),
          2500,
        );
        return undefined;
      });
      if (!response) {
        return;
      }
      return signinCompleted(dispatch, getState, response.data, true);
    };
  },

  doImpersonationSignin(impersonationAuth) {
    return async function doingImpersonationSignin(dispatch, getState) {
      return signinCompleted(dispatch, getState, {
        ...impersonationAuth,
        isImpersonated: true,
      });
    };
  },

  doSignout(disableGoogleAutoSelect = true) {
    return async function doingSignout(dispatch) {
      // revoke the token on the API
      try {
        await AuthRequest.post("auth/revoke/token", null);
      } catch (e) {
        console.error(e);
      }

      if (disableGoogleAutoSelect) {
        await disableGoogleIdentityAutoSelect();
      }

      removeAuthRequestToken();
      removeImpersonationAuthCookie();
      clearSentryUser();

      if (Navigation.location.pathname !== Pages.account.login.path) {
        navigateAfterSignout();
      }

      frontChatInit();

      // dispatch action to reset redux state after navigating for signout to avoid null reference errors in state accessors
      dispatch({ type: AuthActions.DO_SIGNOUT });
    };
  },

  doGoogleSignin(token) {
    return async function doingGoogleSignin(dispatch, getState) {
      dispatch({ type: AuthActions.DO_GOOGLE_SIGNIN, payload: { token } });
      const response = await AuthRequest.post("auth/login/google", {
        token,
        authType: "shliach",
      }).catch(async (err) => {
        if (!err.response || err.response.status === 401) {
          await disableGoogleIdentityAutoSelect();
        }
        dispatch({ type: AuthActions.AUTH_ERROR, payload: err });
        setTimeout(
          () => dispatch({ type: AuthActions.AUTH_ERROR, payload: {} }),
          2500,
        );
        return undefined;
      });
      if (!response) {
        return;
      }

      return signinCompleted(dispatch, getState, response.data, true);
    };
  },

  handleAuthFailure(err) {
    const navigationFailedRejection = new Promise((resolve, reject) => {
      // NOTE: This timer should never go off, but if it does, then whatever
      // API request is waiting for a result will get an error.
      setTimeout(reject, 5000, err);
    });
    return () => {
      //return error when request is a login request
      if (Navigation.location.pathname === Pages.account.login.path) {
        return Promise.reject(err);
      }

      navigateAfterSignout(true);
      return navigationFailedRejection;
    };
  },

  getEngagementAccess() {
    return async (dispatch) => {
      dispatch(AuthActions.setEngagementAccessLoading(true));

      let newState = {
        loading: false,
        success: true,
      };

      try {
        const response = await AuthRequest.get("Engagement/access");
        newState.data = response.data.payload;
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }

      dispatch(AuthActions.setEngagementAccess(newState));
    };
  },

  setEngagementAccess(payload) {
    return {
      type: AuthActions.SET_ENGAGEMENT_ACCESS,
      payload,
    };
  },
  setEngagementAccessLoading(payload) {
    return {
      type: AuthActions.SET_ENGAGEMENT_ACCESS_LOADING,
      payload,
    };
  },
};
