import { ContactActions } from "./actions";

export const ContactState = {
  name: "contact",
  persist: false,
  defaults: {
    loading: false,
  },
  handlers: {
    [ContactActions.SET_CONTACT_LOADING]: function setContactLoading(
      state,
      action,
    ) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [ContactActions.UPDATE_CONTACT]: function updateContact(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
