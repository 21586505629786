import React from "react";

export default ({ schedule: { programScheduleName } }) => (
  <div className="flex flex-align-center flex-justify-center flex-column">
    <img src="/images/not_started.svg" alt="" height="200" />
    <p className="fw-700 mt-24 text-center xxl-text">
      {programScheduleName || "This Lamplighters schedule"} is not yet active
    </p>
    <p className="mt-16 large-text text-center line-height-double">
      You can log interactions via the Student Activity List at any time
    </p>
  </div>
);
