import Updates from "./Updates.vm";
import UpdateDetails from "./UpdateDetails.vm";

export const UpdatesPages = {
  updates: {
    path: "/updates",
    title: "News and Updates",
    type: "PAGE_UPDATES",
    view: Updates,
  },
  updateDetails: {
    path: "/updates/:updateId",
    title: "News and Updates",
    type: "PAGE_UPDATE_DETAILS",
    view: UpdateDetails,
  },
};
export default UpdatesPages;

export const UpdatesArea = {
  pages: UpdatesPages,
};
