import React, { useRef, useState } from "react";
import Checkbox from "../../../../components/form/Checkbox";
import Select from "react-select";
import Popover from "@material-ui/core/Popover";
import StudentsListSearch from "./StudentsListSearch";
import {
  Navigation,
  PageURL,
  convertToArray,
  uniqueArray,
  isMobileView,
  pluralizeText,
} from "../../../../lib";
import { useSelector } from "react-redux";
import { SystemSelectors } from "../../../../state";
import _debounce from "lodash.debounce";

export default function StudentsListFilters(props) {
  const {
    allGrants,
    grantsForDisplay = [],
    scheduleId,
    sortOptions,
    pageRoute: { query, page, params },
  } = props;

  const grantStatuses = useSelector(SystemSelectors.grantStatuses);
  const studentClasses = useSelector(SystemSelectors.studentClasses);

  const {
    as = "",
    g = "",
    gcx = [],
    gs = "",
    ia = "",
    se = "",
    st = "",
    samn = "",
    samx = "",
    sc = [],
    sy = "",
    so = "",
  } = query;
  const gcxArray = convertToArray(gcx);
  const scArray = convertToArray(sc);

  const [showGrantColumnsFilterPopover, setShowGrantColumnsFilterPopover] =
    useState(false);
  const [
    grantColumnsFilterPopoverPosition,
    setGrantColumnsFilterPopoverPosition,
  ] = useState("");
  const [showAgeRangeFilterPopover, setShowAgeRangeFilterPopover] =
    useState(false);
  const [ageRangeFilterPopoverPosition, setAgeRangeFilterPopoverPosition] =
    useState("");
  const onFilter = () => {
    props.getStudents();
  };

  const onFilterDebounce = useRef(_debounce(onFilter, 500));

  const onChange = (name, val, debounce, other) => {
    var urlQuery = {
      ...query,
      p: undefined, //reset page to 1
      [name]: val
        ? typeof val === "object"
          ? val
          : encodeURIComponent(val)
        : undefined,
    };

    if (other) {
      Object.keys(other).forEach(
        (otherKey) => (urlQuery[otherKey] = other[otherKey]),
      );
    }

    const url = PageURL.to(page, params, urlQuery);
    Navigation.redirect(url);

    setTimeout(debounce ? onFilterDebounce.current() : onFilter, 0);
  };

  const onChangeGrantColumnSelection = (grantId, value, debounce) => {
    // in case there are multiple entries for the same grantId and we need to remove that grantId,
    // ensure the array values are unique so we only need to do one remove operation
    const copy = uniqueArray(gcxArray);
    value && copy.includes(grantId.toString())
      ? copy.splice(gcx.indexOf(grantId.toString()), 1)
      : copy.push(grantId);

    // in case we added a duplicate entry, filter the array again for uniqueness
    onChange(null, null, debounce, { gcx: uniqueArray(copy) });
  };

  const grantOptions = [
    { label: "All grants", value: "" },
    ...grantsForDisplay.map((g) => ({
      value: g.id,
      label: g.name,
    })),
  ];

  const grantStatusOptions = [
    { label: "All grant statuses", value: "" },
    ...grantStatuses.map((s) => ({
      value: s.enumValue,
      label: s.displayValue,
    })),
  ];

  const studentClassOptions = studentClasses.map((c) => ({
    value: c.enumValue,
    label: c.displayValue,
  }));

  const studentCreatedWithinYearsOptions = [
    {
      value: "",
      label: "All years",
    },
    ...[...Array(10).keys()].map((k) => ({
      value: k + 1,
      label: `Student created within ${k + 1} ${pluralizeText("year", k + 1)}`,
    })),
  ];

  return (
    <div className="full-width">
      <div className="filter-flex flex-align-center flex flex-justify-space full-width">
        <div className="flex mobile-block mr-8 mb-16">
          <div className="lamplighters-search-input-container mr-16 mobile-mr-0">
            <StudentsListSearch
              isLamplightersActiveOnlyList={!as}
              onChange={onChange}
              searchText={se && decodeURIComponent(se)}
              studentId={st}
              scheduleId={scheduleId}
            />
          </div>
          <div className="flex flex-align-center mobile-block small-text">
            <Checkbox
              checked={!as}
              className="lamplighters-students-checkbox mr-16 mobile-mr-0"
              label="Only show active Lamplighters students"
              name="as"
              onChange={(name, val) => onChange(name, !val)}
            />
            <Checkbox
              checked={ia}
              className="lamplighters-students-checkbox mobile-mt-8"
              label="Show archived students"
              name="ia"
              onChange={onChange}
            />
          </div>
        </div>
        <div className="flex flex-align-center mb-16">
          <div
            className="flex flex-align-center mr-24"
            style={{ zIndex: 3 }} //Student search dropdown must display above student filters
          >
            <p className="mr-8 small-text nowrap-text">Sort by</p>
            <Select
              className="lamplighters-filter-select"
              classNamePrefix="lamplighters-filter-select"
              defaultValue={sortOptions[0]}
              isSearchable={false}
              onChange={(val) => onChange("so", val.value)}
              options={sortOptions}
              value={
                sortOptions.find((s) => s.value === decodeURIComponent(so)) ||
                sortOptions[0]
              }
            />
          </div>
          <div>
            <p
              className="nowrap-text link-text pointer"
              onClick={(event) => {
                setGrantColumnsFilterPopoverPosition(event.currentTarget);
                setShowGrantColumnsFilterPopover(true);
              }}
            >
              {isMobileView() ? "Select grants" : "Choose columns"}
            </p>
            <Popover
              open={showGrantColumnsFilterPopover}
              onClose={() => setShowGrantColumnsFilterPopover(false)}
              anchorEl={grantColumnsFilterPopoverPosition}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="lamplighters-grant-column-checkbox-list">
                {allGrants.map((grant) => (
                  <Checkbox
                    checked={!gcxArray.includes(grant.id.toString())}
                    key={grant.id}
                    label={grant.name}
                    name={grant.id}
                    onChange={onChangeGrantColumnSelection}
                    className="lamplighters-grant-column-checkbox"
                  />
                ))}
              </div>
            </Popover>
          </div>
        </div>
      </div>
      <div className="flex flex-align-center mobile-block lamplighters-students-filters">
        {grantOptions.length > 1 && (
          <div className="mr-8 mobile-mr-0">
            <Select
              className="lamplighters-filter-select"
              classNamePrefix="lamplighters-filter-select"
              defaultValue={grantOptions[0]}
              isSearchable={false}
              onChange={(val) => onChange("g", val.value)}
              options={grantOptions}
              value={
                grantOptions.find(
                  (o) => o.value.toString() === decodeURIComponent(g),
                ) || grantOptions[0]
              }
            />
          </div>
        )}
        <div className="mr-8 mobile-mr-0">
          <Select
            className="lamplighters-filter-select"
            classNamePrefix="lamplighters-filter-select"
            defaultValue={grantStatusOptions[0]}
            isSearchable={false}
            onChange={(val) => onChange("gs", val.value)}
            options={grantStatusOptions}
            value={
              grantStatusOptions.find(
                (o) => o.value.toString() === decodeURIComponent(gs),
              ) || grantStatusOptions[0]
            }
          />
        </div>
        <div className="mr-8 mobile-mr-0">
          <Select
            className="lamplighters-filter-select"
            classNamePrefix="lamplighters-filter-select"
            isMulti={true}
            onChange={(vals) =>
              onChange(
                "sc",
                vals.map((v) => v.value),
              )
            }
            options={studentClassOptions}
            placeholder="All classes"
            value={studentClassOptions.filter((o) =>
              scArray.includes(o.value.toString()),
            )}
          />
        </div>
        <div className="mr-8 mobile-mr-0">
          <Select
            className="lamplighters-filter-select"
            classNamePrefix="lamplighters-filter-select"
            onChange={(val) => onChange("sy", val.value)}
            options={studentCreatedWithinYearsOptions}
            value={studentCreatedWithinYearsOptions.find(
              (o) => o.value.toString() === decodeURIComponent(sy),
            )}
          />
        </div>
        <div
          className={`lamplighters-age-picker mr-8 mobile-mr-0' ${
            showAgeRangeFilterPopover && "lamplighters-age-picker-border"
          }`}
        >
          <p
            className="flex flex-align-center accent-text-secondary fw-700 pointer"
            onClick={(event) => {
              setAgeRangeFilterPopoverPosition(event.currentTarget);
              setShowAgeRangeFilterPopover(true);
            }}
          >
            {`Student age range ${
              samn && !samx
                ? `${samn}+`
                : !samn && samx
                ? `until ${samx}`
                : samn && samx && `${samn}-${samx}`
            }`}
            <i className="material-icons" style={{ marginLeft: "6px" }}>
              keyboard_arrow_down
            </i>
          </p>
          <Popover
            className="mt-16"
            open={showAgeRangeFilterPopover}
            onClose={() => setShowAgeRangeFilterPopover(false)}
            anchorEl={ageRangeFilterPopoverPosition}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            TransitionProps={{ timeout: 0 }}
          >
            <div className="flex-align-center" style={{ padding: 3 }}>
              <input
                className="lamplighters-custom-input"
                onChange={(e) => onChange("samn", e.target.value, true)}
                type="number"
                value={samn}
                placeholder="From"
              />
              <input
                className="lamplighters-custom-input"
                onChange={(e) => onChange("samx", e.target.value, true)}
                type="number"
                value={samx}
                placeholder="To"
              />
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
}
