import React from "react";
import Select from "react-select";
import { Navigation, PageURL } from "../../../lib";
import { RafflePaymentTypes } from "../constants";

export default class OrdersTableFilters extends React.PureComponent {
  onChange = async (name, val) => {
    const {
      pageRoute: { query, page, params },
    } = this.props;

    const url = PageURL.to(page, params, {
      ...query,
      [name]: val || val === false ? encodeURIComponent(val) : undefined,
      p: 1,
    });
    Navigation.redirect(url);
  };

  render() {
    const {
      pageRoute: { query },
      paymentTypes,
      cashTypes,
      raffleDonorAssociations,
      referrers,
    } = this.props;

    const { assc = "", ref = "", pay = "", so = "", dt = "", onl = "" } = query;

    const referrerOptions = [
      { label: "All Teams", value: "" },
      ...referrers.map((referrer) => ({
        label: referrer.name,
        value: referrer.id,
      })),
    ];

    const donorAppreciationOptions = [
      { label: "All Donor Appreciations", value: "" },
      { label: "Donor Thanked", value: true },
      { label: "Donor Not Thanked", value: false },
    ];

    const onlinePaymentOptions = [
      { label: "All Donors", value: "" },
      { label: "Online Donors", value: true },
      { label: "Offline Donors", value: false },
    ];

    const paymentOptions = [
      { label: "All Payments", value: "" },
      ...paymentTypes
        .filter((type) => type.enumValue !== RafflePaymentTypes.Cash)
        .map((type) => ({
          label: type.displayValue,
          value: type.enumValue,
        })),
      ...cashTypes.map((type) => ({
        label: type.name,
        value: `cash_${type.id}`,
      })),
    ];

    const associationOptions = [
      { label: "All Associations", value: "" },
      ...raffleDonorAssociations.map((association) => ({
        label: association.displayValue,
        value: association.enumValue,
      })),
    ];

    const sortOptions = [
      { label: "Date: New-Old", value: "DateDesc" },
      { label: "Date: Old-New", value: "DateAsc" },
      { label: "Amount: Low-High", value: "AmountAsc" },
      { label: "Amount: High-Low", value: "AmountDesc" },
    ];

    return (
      <div
        className="flex flex-align-center mobile-block full-width"
        style={{ flexFlow: "wrap" }}
      >
        <div className="flex flex-align-center mr-16 mobile-ml-0 filter-width">
          <p className="mr-8 nowrap-text">Filter by</p>
          <Select
            className="filter-select"
            classNamePrefix="filter-select"
            defaultValue={referrerOptions[0]}
            isSearchable={false}
            onChange={(val) => this.onChange("ref", val.value)}
            options={referrerOptions}
            value={
              referrerOptions.find(
                (s) => s.value.toString() === decodeURIComponent(ref),
              ) || referrerOptions[0]
            }
          />
        </div>
        <div className="flex flex-align-center mr-16 mobile-ml-0 filter-width">
          <p className="mr-8 nowrap-text">Filter by</p>
          <Select
            className="filter-select"
            classNamePrefix="filter-select"
            defaultValue={paymentOptions[0]}
            isSearchable={false}
            onChange={(val) => this.onChange("pay", val.value)}
            options={paymentOptions}
            value={
              paymentOptions.find((s) => s.value === decodeURIComponent(pay)) ||
              paymentOptions[0]
            }
          />
        </div>
        <div className="flex flex-align-center mr-16 mobile-ml-0 filter-width">
          <p className="mr-8 nowrap-text">Filter by</p>
          <Select
            className="filter-select"
            classNamePrefix="filter-select"
            defaultValue={associationOptions[0]}
            isSearchable={false}
            onChange={(val) => this.onChange("assc", val.value)}
            options={associationOptions}
            value={
              associationOptions.find(
                (s) => s.value === decodeURIComponent(assc),
              ) || associationOptions[0]
            }
          />
        </div>
        <div className="flex flex-align-center mr-16 mobile-ml-0 filter-width">
          <p className="mr-8 nowrap-text">Filter by</p>
          <Select
            className="filter-select"
            classNamePrefix="filter-select"
            defaultValue={donorAppreciationOptions[0]}
            isSearchable={false}
            onChange={(val) => this.onChange("dt", val.value)}
            options={donorAppreciationOptions}
            value={
              donorAppreciationOptions.find(
                (s) => s.value.toString() === decodeURIComponent(dt),
              ) || donorAppreciationOptions[0]
            }
          />
        </div>
        <div className="flex flex-align-center mr-16 mobile-ml-0 filter-width">
          <p className="mr-8 nowrap-text">Filter by</p>
          <Select
            className="filter-select"
            classNamePrefix="filter-select"
            defaultValue={onlinePaymentOptions[0]}
            isSearchable={false}
            onChange={(val) => this.onChange("onl", val.value)}
            options={onlinePaymentOptions}
            value={
              onlinePaymentOptions.find(
                (s) => s.value.toString() === decodeURIComponent(onl),
              ) || onlinePaymentOptions[0]
            }
          />
        </div>
        <div className="flex flex-align-center mobile-ml-0 filter-width">
          <p className="mr-8 nowrap-text">Sort by</p>
          <Select
            className="filter-select"
            classNamePrefix="filter-select"
            defaultValue={sortOptions[0]}
            isSearchable={false}
            onChange={(val) => this.onChange("so", val.value)}
            options={sortOptions}
            value={
              sortOptions.find((s) => s.value === decodeURIComponent(so)) ||
              sortOptions[0]
            }
          />
        </div>
      </div>
    );
  }
}
