import { UpdatesActions } from "./actions";

export const UpdatesState = {
  name: "updates",
  persist: false,
  defaults: {
    loading: false,
  },
  handlers: {
    [UpdatesActions.SET_UPDATES_LOADING]: function setUpdatesLoading(
      state,
      action,
    ) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [UpdatesActions.UPDATE_UPDATES]: function updateUpdates(state, action) {
      return {
        ...state,
        posts: {
          ...state.posts,
          ...action.payload.posts,
        },
        ...action.payload,
      };
    },
  },
};
