import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { ProfileFormSectionProps } from "../../ProfileFormHelpers";
import { CampusInfo } from "./";
import { pluralizeText } from "../../../../../lib";
import { ProfileFormHeaderStyled } from "../../ProfileForm.styles";

export const Campuses = React.memo(
  /**
   *
   */
  function Campuses(props: ProfileFormSectionProps) {
    const {
      initialFormValues: { campuses },
    } = props;

    return campuses.length ? (
      <>
        <ProfileFormHeaderStyled>
          <Typography variant="h4">
            My {pluralizeText("School", campuses.length)}
          </Typography>
          <Box>
            <Info />
            <Typography variant="body2" color="textSecondary">
              All fields are required unless marked optional. Read-only fields
              are pulled from public records. All information aside from School
              recognition of Chabad will be displayed publicly.
            </Typography>
          </Box>
        </ProfileFormHeaderStyled>
        {campuses.map((_, index) => (
          <CampusInfo {...props} campusIndex={index} key={index} />
        ))}
      </>
    ) : null;
  },
);
