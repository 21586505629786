import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { RsvpActions } from "../../state/rsvp/actions";
import { StudentsActions } from "../../state/students/actions";
import { SmsActions } from "../../state";
import Rsvp from "./Rsvp";

export default connect(
  function mapState(state) {
    return {
      auth: state.auth,
      ...state.rsvp,
      ...state.students,
      sms: state.sms,
      sys: state.sys,
      ui: state.ui,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...RsvpActions,
          ...SmsActions,
          ...StudentsActions,
        },
        dispatch,
      ),
    };
  },
)(Rsvp);
