export const InternPermissions = {
  eduAttendance: "InternEduAttendance",
  eduSchedule: "InternEduSchedule",
  eduStudents: "InternEduStudents",
  manageActivity: "InternManageActivity",
  manageRsvp: "InternManageRsvp",
  manageStudents: "InternManageStudents",
  manageRaffleDonations: "InternManageRaffleDonations",
};

export const AuthTypes = {
  shliach: "Shliach",
  intern: "Intern", // note this type is for all CH personnel logging into the shluchim portal
};
