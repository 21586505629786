import React from "react";
import Select from "../../../../components/form/CustomSelect";
import Toggle from "../../../../components/form/Toggle";
import ValidatedInput from "../../../../components/form/ValidatedInput";

export default class IsraelEducationInfo extends React.PureComponent {
  render() {
    const {
      getProfileFieldLabel,
      isProfileFieldRequired,
      jewishIsraelKnowledgeLevels,
      onChangeProfile,
      profile: { hasTakenIsraelCourses, jewishIsraelKnowledgeLevel } = {},
      showFormValidation,
    } = this.props;

    return (
      <div className="profile-form-section">
        <p className="profile-section-title">Israel educational background</p>
        <div className="profile-form-grid">
          <div className="relative">
            <ValidatedInput
              input={
                <Toggle
                  allowNoOption={true}
                  className="toggle-container"
                  options={[
                    {
                      value: true,
                      display: "Yes",
                    },
                    {
                      value: false,
                      display: "No",
                    },
                  ]}
                />
              }
              label={getProfileFieldLabel(
                "I have taken courses about Israel",
                "hasTakenIsraelCourses",
              )}
              labelClassName="toggle-label"
              name="hasTakenIsraelCourses"
              onChange={onChangeProfile}
              required={isProfileFieldRequired("hasTakenIsraelCourses")}
              showRequired={
                showFormValidation &&
                hasTakenIsraelCourses !== true &&
                hasTakenIsraelCourses !== false
              }
              value={hasTakenIsraelCourses}
            />
          </div>
          <div className="relative">
            <ValidatedInput
              className="input-container jewishIsraelKnowledgeLevel-select"
              input={
                <Select
                  classNamePrefix="input-container-select"
                  isClearable={true}
                  options={
                    jewishIsraelKnowledgeLevels &&
                    jewishIsraelKnowledgeLevels.map((ikl) => ({
                      label: ikl.displayValue,
                      value: ikl.enumValue,
                    }))
                  }
                />
              }
              label={getProfileFieldLabel(
                "My knowledge about Jewish connection to Israel",
                "jewishIsraelKnowledgeLevel",
              )}
              name="jewishIsraelKnowledgeLevel"
              onChange={onChangeProfile}
              required={isProfileFieldRequired("jewishIsraelKnowledgeLevel")}
              showRequired={showFormValidation}
              value={jewishIsraelKnowledgeLevel}
            />
          </div>
        </div>
      </div>
    );
  }
}
