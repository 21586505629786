import Lamplighters from "./Lamplighters.vm";

export const LamplightersPages = {
  lamplighters: {
    anon: false,
    path: "/lamplighters/:programScheduleId?",
    title: "Lamplighters",
    type: "PAGE_LAMPLIGHTERS",
    view: Lamplighters,
  },
};

export default LamplightersPages;

export const LamplightersArea = {
  pages: LamplightersPages,
};
