import React from "react";
import { Button } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import Modal from "../../components/Modal";
import SmsMessageDetails from "./SmsMessageDetails";

// Note that the styling on this class is optimized for mobile view
const ActiveMessageModal = ({
  activeMessage,
  allowReply,
  close,
  reply,
  show,
  resetMailboxes,
  timezoneID,
  view,
}) => {
  return (
    <Modal show={show}>
      <div className="modal-container" key="activeMessageModal">
        <div className="modal-card card student-profile-modal relative full-width">
          <div className="mb-20">
            <i
              className="material-icons link-text xl-text mr-8"
              onClick={close}
            >
              arrow_back
            </i>
          </div>
          <div className="sms-thread-container" style={{ border: "0px" }}>
            <div className="sms-thread-inner-container">
              {!!activeMessage && (
                <SmsMessageDetails
                  activeMessage={activeMessage}
                  resetMailboxes={resetMailboxes}
                  timezoneID={timezoneID}
                  view={view}
                  message={activeMessage}
                  activeMessageId={activeMessage.messageId}
                  index={0}
                  anonymousPhoneNumber={
                    !activeMessage.firstPersonId &&
                    (activeMessage.toPersonPhone ||
                      activeMessage.fromPersonPhone)
                  }
                />
              )}
              <div>
                {allowReply && (
                  <div className="button-container">
                    <Button
                      disableElevation
                      variant="contained"
                      color="secondary"
                      className="ml-16 reply-button full-width"
                      onClick={reply}
                    >
                      Reply <Send fontSize="small" style={{ marginLeft: 8 }} />
                    </Button>
                  </div>
                )}
                <p
                  className="link-text flex flex-justify-center"
                  onClick={close}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ActiveMessageModal;
