import Home from "./Home";
import NotFound from "./NotFound";

export const MainPages = {
  home: {
    anon: false,
    path: "/",
    title: "Chabad on Campus",
    type: "PAGE_HOME",
    view: Home,
    externalUserAuthorization: "all",
  },
  notFound: {
    anon: true,
    path: "/404",
    title: "Sorry, Not Found",
    type: "PAGE_NOT_FOUND",
    view: NotFound,
    layout: null,
  },
};
export default MainPages;

export const MainArea = {
  pages: MainPages,
};
