import { AuthRequest } from "../../lib";

export const ContactsActions = {
  SET_CONTACTS_LOADING: "SET_CONTACTS_LOADING",
  UPDATE_CONTACTS: "UPDATE_CONTACTS",
  CLEAR_CONTACTS: "CLEAR_CONTACTS",

  getContacts(contactType, page, resultsView, searchTerm, infiniteScroll) {
    return async (dispatch, getState) => {
      dispatch(ContactsActions.setContactsLoading(contactType));
      let success = true;

      let url = `Search/shluchim/${searchTerm}`;
      let params = { page, results: resultsView };
      if (contactType === "Teams") {
        url = "OfficeStaff/search";
        params.text = searchTerm;
      }

      const { data } = await AuthRequest.get(url, { params }).catch((err) => {
        success = false;
        return { data: { results: [], totalRecords: 0 } };
      });

      let records, total;
      if (contactType === "Teams") {
        records = data.payload.results;
        total = data.payload.numberOfRows;
      } else {
        records = data.results;
        total = data.totalRecords;
      }

      const newState = {
        records:
          infiniteScroll && page > 1
            ? [...getState().contacts.records, ...records]
            : records,
        total,
        loading: false,
        page,
        resultsView,
        success,
      };
      dispatch(ContactsActions.updateContacts(newState));
    };
  },
  setContactsLoading(payload) {
    return {
      type: ContactsActions.SET_CONTACTS_LOADING,
      payload,
    };
  },
  updateContacts(payload) {
    return {
      type: ContactsActions.UPDATE_CONTACTS,
      payload,
    };
  },
  clearContacts() {
    return {
      type: ContactsActions.CLEAR_CONTACTS,
    };
  },
};
