import { AuthRequest } from "../../lib";
import { ActivityActions } from "../activity/actions";

export const ProgramActions = {
  SET_PROGRAM_LOADING: "SET_PROGRAM_LOADING",
  UPDATE_PROGRAM: "UPDATE_PROGRAM",

  getProgram(id, trackProgramView) {
    return async (dispatch, getState) => {
      dispatch(ProgramActions.setProgramLoading(true));

      let details;
      let error;
      let success = true;

      try {
        const response = await AuthRequest.get(
          `Programs/${id}?onlyCurrentOrLatest=true`,
        );
        details = response.data.payload;
      } catch (err) {
        error = err;
        success = false;
      }

      const newState = {
        loading: false,
        details,
        error,
        success,
      };

      dispatch(ProgramActions.updateProgram(newState));

      if (success && trackProgramView) {
        dispatch(ActivityActions.trackProgramView(id));
      }
    };
  },
  clearProgram() {
    return (dispatch) => {
      dispatch(
        ProgramActions.updateProgram({
          details: undefined,
        }),
      );
    };
  },
  setProgramLoading(payload) {
    return {
      type: ProgramActions.SET_PROGRAM_LOADING,
      payload,
    };
  },
  updateProgram(payload) {
    return {
      type: ProgramActions.UPDATE_PROGRAM,
      payload,
    };
  },
};
