import React from "react";
import { PageLink } from "../../../../../lib";
import Pages from "../../../..";

export default class CourseCard extends React.PureComponent {
  render() {
    const {
      course: {
        categoryDisplay,
        courseID,
        imageURL,
        levelDisplay,
        name,
        programScheduleID,
      },
    } = this.props;

    return (
      <div className="edu-course-card card">
        <img src={imageURL} alt="" />
        <div className="flex flex-justify-space">
          <p className="medium-text fw-700 mb-8 inline-block" dir="auto">
            {name}
          </p>
        </div>
        <div className="flex flex-justify-space flex-align-center mb-8">
          <p className="accent-text-dark">{categoryDisplay}</p>
          <p className="accent-text-dark">{levelDisplay}</p>
        </div>
        <div className="edu-course-card-footer flex flex-justify-end">
          <PageLink
            className="link-text"
            to={Pages.edu.edu}
            params={{
              pageView: "courses",
              programScheduleId: programScheduleID,
              courseId: courseID,
              courseView: "overview",
            }}
          >
            View Details
          </PageLink>
          <PageLink
            className="link-text ml-24"
            to={Pages.edu.edu}
            params={{
              pageView: "courses",
              programScheduleId: programScheduleID,
              courseId: courseID,
              courseView: "offer",
            }}
          >
            Offer Course
          </PageLink>
        </div>
      </div>
    );
  }
}
