import { UpdateActions } from "./actions";

export const UpdateState = {
  name: "update",
  persist: false,
  defaults: {
    loading: false,
    details: undefined,
  },
  handlers: {
    [UpdateActions.SET_UPDATE_LOADING]: function setUpdateLoading(
      state,
      action,
    ) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [UpdateActions.UPDATE_UPDATE]: function updateUpdate(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
