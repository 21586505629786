import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import Loader from "../../../../components/Loader";
import { SystemSelectors, EngagementSelectors } from "../../../../state";
import {
  StudentsMetricsChartHeader,
  getMetricDetailsAccessor,
  getMonthYearLabel,
  formatStudentMetricsTickY,
} from "./";
import MetricsBarChart from "./MetricsBarChart";
import NoMetrics from "./NoMetrics";
import ColoredLegendEntry from "../../ColoredLegendEntry";

function formatData(data, jewishInterestLevels) {
  return jewishInterestLevels
    .map(({ enumValue }) => enumValue)
    .map((jewishInterestLevel) => {
      const jewishInterestLevelData = {
        jewishInterestLevel,
        total: 0,
      };

      data
        .filter(
          (metrics) => metrics.jewishInterestLevel === jewishInterestLevel,
        )
        .forEach((metrics) => {
          const { monthYear, total } = metrics;
          const formatMonthYear = new Date(monthYear).toISOString();
          jewishInterestLevelData[getMetricDetailsAccessor(formatMonthYear)] = {
            ...metrics,
            monthYear: formatMonthYear,
          };
          jewishInterestLevelData[formatMonthYear] = total;
          jewishInterestLevelData.total += total;
        });

      return jewishInterestLevelData;
    });
}

export default function StudentMetricsChart(props) {
  const { selectedDates, getColors, monthYearsArray } = props;

  const [formattedData, setFormattedData] = useState([]);

  const { data, total, loading, errorMessage } = useSelector(
    EngagementSelectors.studentMetrics,
  );
  const jewishInterestLevels = useSelector(
    SystemSelectors.engagementJewishInterestLevels,
  );

  useEffect(
    function updateLocalData() {
      if (data) {
        //we are setting the data in local state per this issue where the bar labels do not appear on initial mount when reading directly from props https://github.com/recharts/recharts/issues/829
        setFormattedData(formatData(data, jewishInterestLevels));
      }
    },
    [data, jewishInterestLevels],
  );

  const dataMax = useMemo(
    () =>
      formattedData?.reduce(
        (prev, current) => (prev?.total > current?.total ? prev : current),
        null,
      ),
    [formattedData],
  );

  const rowHeight = selectedDates?.length * 20;
  const minRowHeight = 60;

  const chartHeight =
    formattedData.length *
    (rowHeight >= minRowHeight ? rowHeight : minRowHeight);

  return (
    <div className="graph-outline mb-24">
      <StudentsMetricsChartHeader dataTotal={total} loading={loading} />
      <div className="mt-16 mb-16">
        {loading ? (
          <Loader />
        ) : errorMessage ? (
          <p className="error-text small-text text-center">{errorMessage}</p>
        ) : !formattedData.length ? (
          <NoMetrics message="No student metrics found" />
        ) : (
          <>
            <Box className="flex flex-align-center mobile-block">
              {selectedDates.map((monthYear, index) => (
                <ColoredLegendEntry
                  getColors={getColors}
                  id={monthYear}
                  name={getMonthYearLabel(monthYearsArray, monthYear)}
                  key={index}
                />
              ))}
            </Box>
            <MetricsBarChart
              getColors={getColors}
              chartHeight={chartHeight}
              data={formattedData}
              metrics={selectedDates}
              isGoalData={false}
              xDomain={[0, dataMax || 100]}
              tickXFormatter={(key) => key}
              tickYDataKey={"jewishInterestLevel"}
              tickYFormatter={(key) =>
                formatStudentMetricsTickY(
                  jewishInterestLevels,
                  key,
                  formattedData,
                )
              }
            />
          </>
        )}
      </div>
    </div>
  );
}
