import React from "react";
import { useFormState } from "react-hook-form";

export type FormErrorsWatchProps = {
  children: (errors: any) => any;
};

export const FormErrorsWatch = React.memo(
  /**
   *
   */
  function FormErrorsWatch({ children }: FormErrorsWatchProps) {
    const { errors } = useFormState();

    return children(errors);
  },
);
