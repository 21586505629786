import { AppState, GenericObject } from "../types";

const defaultObject: GenericObject = {};

export const LamplightersSelectors = {
  enrollmentSettingsLoading(state: AppState) {
    return state.lamplighters.enrollmentSettings?.loading;
  },
  enrollmentSettingsData(state: AppState) {
    return state.lamplighters.enrollmentSettings?.data || defaultObject;
  },
  enrollmentData(state: AppState) {
    return state.lamplighters.enrollment?.data || defaultObject;
  },
};
