import React from "react";
import Loader from "../../../../components/Loader";
import StudentInteractionsOverTimeChart from "./StudentInteractionsOverTimeChart";
import StudentObservanceOverTimeChart from "./StudentObservanceOverTimeChart";

export default class StudentEngagement extends React.PureComponent {
  componentDidMount() {
    this.getStudentInteractions();
    this.getStudentObservance();
  }

  getStudentInteractions = async (timeFrame = "last6Months", setting) => {
    const {
      getStudentInteractions,
      student: { id: studentId },
    } = this.props;
    await getStudentInteractions(studentId, timeFrame, setting);
  };

  getStudentObservance = async () => {
    const {
      getStudentObservance,
      student: { id: studentId },
    } = this.props;
    await getStudentObservance(studentId);
  };

  render() {
    const {
      student: {
        demographics: { jewishInterestLevel },
        id: studentId,
        person: { gender: studentGender } = {},
      },
      studentInteractions: {
        data: studentInteractions = [],
        errorMessage: studentInteractionsErrorMessage,
        loading: studentInteractionsLoading,
      },
      studentObservance: {
        data: studentObservance = [],
        errorMessage: studentObservanceErrorMessage,
        loading: studentObservanceLoading,
      },
      sys: {
        observanceStatuses = [],
        participationStatuses = [],
        shabbosHolidayObservanceStatuses = [],
      },
    } = this.props;

    return (
      <div>
        <StudentInteractionsOverTimeChart
          data={studentInteractions}
          errorMessage={studentInteractionsErrorMessage}
          getStudentInteractions={this.getStudentInteractions}
          jewishInterestLevel={jewishInterestLevel}
          loading={studentInteractionsLoading}
          mobileView={this.props.mobileView}
          studentId={studentId}
        />
        <div className="mt-40">
          {studentObservanceLoading ? (
            <Loader />
          ) : studentObservanceErrorMessage ? (
            <p className="text-center error-text small-text">
              Something went wrong and Student trajectory stats could not be
              loaded. Refresh the page to try again.
            </p>
          ) : (
            <div className="two-column-grid mobile-one-column-grid">
              <StudentObservanceOverTimeChart
                className="shabbos-observance-chart"
                data={
                  studentObservance
                    ? studentObservance.filter(
                        (o) => o.type === "ShabbosAndHolidayObservance",
                      )
                    : []
                }
                name="Shabbos observance"
                statuses={shabbosHolidayObservanceStatuses}
                statusDisplayFormatter={(status) => {
                  switch (status.enumValue) {
                    case "CelebratedMajorHolidays":
                      return "Major holidays";
                    default:
                      return (
                        status.displayValue?.split(" ").slice(0, 2).join(" ") ||
                        ""
                      );
                  }
                }}
              />
              <StudentObservanceOverTimeChart
                data={
                  studentObservance
                    ? studentObservance.filter(
                        (o) => o.type === "SynagogueAttendance",
                      )
                    : []
                }
                name="Shul attendance"
                statuses={participationStatuses}
              />
              <StudentObservanceOverTimeChart
                data={
                  studentObservance
                    ? studentObservance.filter(
                        (o) => o.type === "KosherObservance",
                      )
                    : []
                }
                name="Kosher observance"
                statuses={observanceStatuses}
              />
              <StudentObservanceOverTimeChart
                data={
                  studentObservance
                    ? studentObservance.filter((o) => o.type === "TorahStudy")
                    : []
                }
                name="Torah study"
                statuses={participationStatuses}
              />
              {studentGender !== "Female" && (
                <StudentObservanceOverTimeChart
                  data={
                    studentObservance
                      ? studentObservance.filter((o) => o.type === "Tefillin")
                      : []
                  }
                  name="Tefillin"
                  statuses={participationStatuses}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
