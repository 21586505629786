import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  EduActions,
  LamplightersActions,
  StudentsActions,
} from "../../../state";

import StudentDetails from "./StudentDetails";

export default connect(
  function mapState(state) {
    return {
      ...state.students,
      auth: state.auth,
      edu: state.edu,
      lamplighters: state.lamplighters,
      sys: state.sys,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...EduActions,
          ...LamplightersActions,
          ...StudentsActions,
        },
        dispatch,
      ),
    };
  },
)(StudentDetails);
