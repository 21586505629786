import React from "react";
import { useSelector } from "react-redux";
import { AuthSelectors } from "../../state";
import InternsDashboard from "../interns/InternsDashboard";
import DefaultHome from "./DefaultHome.vm";

export default function Home() {
  const isIntern = useSelector(AuthSelectors.isLoggedInAsIntern);
  return isIntern ? <InternsDashboard /> : <DefaultHome />;
}
