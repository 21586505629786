import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
/* @ts-ignore */
import querySerializer from "query-string";
import { CommonPageTypes } from "./constants";
import { Navigation } from "./Navigation";
import { PageLoader } from "./PageLoader";
import { PageURL } from "./PageURL";
import { gaTrackPageView } from "../GoogleAnalytics";
import { RouteDefinition } from "./URLBuilder";
import { AppState, GenericObject } from "../../state/types";
import { doArraysIntersect } from "../utils";

interface AppRouterPageOptions {
  anon: boolean;
  pathExact: boolean;
}

interface AppRouterConfig {
  loginCheck: () => boolean;
  loginPath: string;
  loginRedirectParam: string;
  pageOptions: AppRouterPageOptions;
  rootArea: { areas: object[]; pages: object[]; layouts: GenericObject };
  configurePage: any;
}

const history = Navigation.init({
  /* @ts-ignore */
  listen: (location: any) => gaTrackPageView(location),
}).history;

let defaultRoute: any;

const pagesByType = PageURL.types || {};
const pagesByPath = PageURL.paths || {};
const pagesByLayout = PageURL.layout || {};
const routePages: any[] = [];

let NotFoundPageView: any = <></>;
let isAuthenticated = () => false;
let loginRedirectURL = "/login?after=";

const pageOptions: AppRouterPageOptions = {
  anon: false,
  pathExact: true,
};

export class AppRouter extends React.PureComponent {
  static configure(config: AppRouterConfig) {
    const {
      loginCheck = isAuthenticated,
      loginPath = "/login",
      loginRedirectParam = "after",
      pageOptions: { anon = true, pathExact = true },
      rootArea,
      configurePage,
    } = config;
    isAuthenticated = loginCheck;
    loginRedirectURL = `${loginPath}?${loginRedirectParam}=`;
    pageOptions.anon = anon;
    pageOptions.pathExact = pathExact;
    // Build routes. Pages without layouts MUST be first and layout routes last.
    const layoutRoutes = configureLayouts(rootArea);
    configurePageArea(rootArea, { configurePage });
    routePages.push(
      // Layout routes must also be sorted with longest paths first.
      ...layoutRoutes
        .sort((a: any, b: any) => a.path.length - b.path.length)
        .reverse(),
    );
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          {routePages.map(renderRoute)}
          <PrivateRoute path="*">
            <NotFoundPageView />
          </PrivateRoute>
        </Switch>
      </Router>
    );
  }
}
export default AppRouter;

function configureLayouts(area: any, layoutRoutes = []) {
  const { areas: subAreas, defaultLayout, layouts } = area;
  function mapLayout(layout: any, layoutName: any) {
    const { path } = layout;
    if (!path || (pagesByPath[path] && pagesByLayout[path].layout)) {
      throw new Error(`Missing or duplicate layout path found: ${path}`);
    }
    addLayoutRoute(layoutRoutes, layoutName, path, {
      path,
      layout,
      pages: [],
    });
  }
  if (layouts) {
    Object.keys(layouts).forEach((layoutName) => {
      const layout = layouts[layoutName];
      mapLayout(layout, layoutName);
      if (layoutName === defaultLayout || layoutName === "default") {
        if (defaultRoute) {
          throw new Error("Default layout already defined!");
        }
        // TODO: Allow on default layout per area instead of one for the app.
        // CONSIDER: Maybe
        defaultRoute = pagesByPath[layout.path];
      }
    });
  }
  if (subAreas) {
    subAreas.forEach((subArea: any) => configureLayouts(subArea, layoutRoutes));
  }

  return layoutRoutes;
}

function addLayoutRoute(
  layoutRoutes: any[],
  layoutName: string,
  path: string,
  route: any,
) {
  pagesByPath[path] = route;
  pagesByLayout[layoutName] = route;
  layoutRoutes.push(route);
}

function configurePageArea(area: any, options = {}) {
  const { areas: subAreas, pages } = area;
  if (subAreas) {
    subAreas.forEach((subArea: any) => {
      configurePageArea(subArea, options);
    });
  }
  if (!pages) {
    return;
  }
  const { configurePage = () => undefined }: any = options;
  Object.keys(pages).forEach(function mapPage(keyForPage) {
    const page = pages[keyForPage];
    const { path, type } = page;
    if (!type || pagesByType[type]) {
      throw new Error(`Missing or duplicate page type found: ${type}`);
    }
    pagesByPath[path] = page;
    pagesByType[type] = page;
    if (type === CommonPageTypes.NOT_FOUND) {
      NotFoundPageView = page.view;
    }
    if (path) {
      if (!page.getRouteKey) {
        /** MODIFYING: page is modified to ensure required props.
         * Instead of copying, we apply updates to the original page.
         * This was done so that code outside of this module which already has a
         * reference to a given page can access these modifications.
         */
        page.getRouteKey = getRouteKeyWithPage(page);
      }
    }
    mapPageToRoute(page);
    configurePage(page);
  });
}
function mapPageToRoute(page: any) {
  const { layout: layoutPathOrName } = page;
  if (layoutPathOrName) {
    const layoutRoute =
      pagesByLayout[layoutPathOrName] || pagesByPath[layoutPathOrName];
    if (!layoutRoute) {
      throw new Error(`Layout not found: ${layoutPathOrName}`);
    }
    layoutRoute.pages.push(page);
  } else if (!defaultRoute || layoutPathOrName === null) {
    //IF DON'T WANT THE DEFAULT LAYOUT: (ex: 404 page) //TODO: maybe set up an empty layout instead of defining layout as null?

    addRoute(page.path, {
      path: page.path,
      page,
    });
  } else {
    defaultRoute.pages.push(page);
  }
}

function addRoute(path: any, route: any) {
  pagesByPath[path] = route;
  routePages.push(route);
}

function renderRoute(route: RouteDefinition | any) {
  const { path, layout: { view: LayoutView = {} } = {}, page, pages } = route;
  if (page) {
    return renderRouteForPage(page);
  }
  return (
    <Route key={path} path={path}>
      <Switch>
        {pages.map((page: any) => renderRouteForPage(page, LayoutView))}
        <Route path="*">
          <NotFoundPageView />
        </Route>
      </Switch>
    </Route>
  );
}

function renderRouteForPage(page: any, LayoutView?: any) {
  const {
    anon = pageOptions.anon,
    externalUserAuthorization,
    path,
    pathExact: exact = pageOptions.pathExact,
  } = page;
  const RouteComponent = anon ? Route : PrivateRoute;
  const pageProps = { exact, externalUserAuthorization, path };
  const pageView = (
    <RouteComponent
      key={path}
      {...pageProps}
      render={(props: any) => <PageLoader page={page} {...props} />} //TODO: move to child component for easier upgrade to v6 (current props issue pageLoader)
    />
  );
  return LayoutView ? (
    //TODO: when upgrade to react-router-dom v6, dont render layout on each page, instead use Outlet in layout
    // only did this because useParams wasn't accessible if it wasn't the <Route path/>
    // see https://reactrouter.com/en/main/components/outlet
    <LayoutView key={path} {...pageProps}>
      {pageView}
    </LayoutView>
  ) : (
    pageView
  );
}

const PrivateRoute = memo((props: any) => {
  const { externalUserAuthorization } = props;
  const externalUserAuthorizationArr = Array.isArray(externalUserAuthorization)
    ? externalUserAuthorization
    : [externalUserAuthorization];

  const isLoggedInAsShliach = useSelector(
    (state: AppState) => state.auth.loggedInAs === "Shliach",
  );
  const userHasPermission = useSelector((state: AppState) =>
    doArraysIntersect(
      state.auth.permissionClaims,
      externalUserAuthorizationArr,
    ),
  );

  if (!isAuthenticated()) {
    return (
      <Route
        {...props}
        render={({ location }) => (
          <Redirect
            to={
              loginRedirectURL +
              encodeURIComponent(
                location.pathname + location.search + location.hash,
              )
            }
          />
        )}
      />
    );
  }

  const isAvailableToAllAuthenticatedUsers =
    externalUserAuthorization === "all";
  if (
    !isLoggedInAsShliach &&
    !isAvailableToAllAuthenticatedUsers &&
    (!externalUserAuthorization || !userHasPermission)
  ) {
    return <Route {...props} render={() => <Redirect to="/404" />} />;
  }

  return <Route {...props} />;
});

// #region Route Keys - Uniquely identify pages based on parts of the URL
/** The key specifies which parts of the URL uniquely identify a page view.
 * E.g., given a key of:  "key: { query: ["p", "q", "r"] }"
 * this mean if the query parameters `p`, `q` or `r` change, the page component
 * will be reloaded (componentDidMount will be called.) If any other query
 * parameters change, the component will not reload.
 */

/** Returns a function that, when called, returns the route key of the page. */
function getRouteKeyWithPage(page: any) {
  const { key: keySpec } = page;
  function getRouteKeyForPage(params: any) {
    // NOTE: We only want the path, not a query string, so only pass params.
    return PageURL.to(page, { params });
  }
  if (keySpec) {
    return getRouteKeyWithSpec(page, keySpec, getRouteKeyForPage);
  }
  return getRouteKeyForPage;
}
/** Returns a function that, when called, returns the route key of the page. */
function getRouteKeyWithSpec(page: any, keySpec: any, defaultImpl: any) {
  // TODO: Use keySpec.params to create pathnames like '/prop/value/...'
  // so that we can share view state among different urls (think wizards).
  // This way the uniqueness of the key can be independent of the pathname.
  const { params: paramsSpec, query: querySpec } = keySpec;

  if (paramsSpec && Array.isArray(paramsSpec)) {
    return function getRouteKeyWithOnlySpecifiedParams(params: any) {
      if (!params) {
        return PageURL.to(page);
      }

      const keyParams: any = {};
      paramsSpec.forEach((ps) => (keyParams[ps] = params[ps]));
      return PageURL.to(page, { params: keyParams });
    };
  }

  if (querySpec && Array.isArray(querySpec)) {
    const len = querySpec.length;
    return function getRouteKeyIncludingQuery(params: any, query: any) {
      // NOTE: We only want the path, not a query string, so only pass params.
      const pathname = PageURL.to(page, { params });
      if (!query) {
        return pathname;
      }
      const keyValues: any = {};
      let hasKeyValues = false;
      for (var i = 0; i < len; i++) {
        const prop: any = querySpec[i];
        if (prop in query) {
          keyValues[prop] = query[prop];
          hasKeyValues = true;
        }
      }
      if (!hasKeyValues) {
        return pathname;
      }
      return pathname + "?" + querySerializer.stringify(keyValues);
    };
  }

  return defaultImpl;
}
