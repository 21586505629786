import React from "react";

import Settings from "../settings/Settings";

interface EnrollmentProps {
  isActive: Boolean;
  setSubmitted: () => void;
}

function Enrollment(props: EnrollmentProps) {
  const { isActive, setSubmitted } = props;

  return (
    <div>
      {isActive ? (
        <Settings setSubmitted={setSubmitted} />
      ) : (
        <div className="full-width flex flex-justify-center flex-column">
          <img src="/images/closedRegistration.svg" alt="" height="200" />
          <p className="fw-700 mt-24 text-center xl-text">
            Enrollment is closed while grand draw is inactive
          </p>
          <p className="mt-16 large-text text-center line-height-double">
            Questions? Please contact us at{" "}
            <a
              href="mailto:help@chabadoncampus.org"
              className="link-text"
              target="_blank"
              rel="noopener noreferrer"
            >
              help@chabadoncampus.org
            </a>{" "}
            or{" "}
            <a href="tel:+1-718-510-8181,0" className="link-text">
              718 510 8181 x 0
            </a>
          </p>
        </div>
      )}
    </div>
  );
}

export default Enrollment;
