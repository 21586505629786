import { SchoolsActions } from "./actions";

export const SchoolsState = {
  name: "schools",
  persist: false,
  defaults: {
    loading: false,
  },
  handlers: {
    [SchoolsActions.SET_SCHOOLS_LOADING]: function setSchoolsLoading(
      state,
      action,
    ) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [SchoolsActions.UPDATE_SCHOOLS]: function updateSchools(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
