import React from "react";
import { useHistory } from "react-router-dom";
import StudentDetails from "./student/StudentDetails.vm";
import { withAppInsights } from "../../lib/AppInsights";

function StudentDetailsPage(props) {
  const history = useHistory();

  return (
    <div className="page container student-details-page">
      <p
        className="w-700 flex flex-align-center mb-24 link-text"
        style={{ fontSize: "15px" }}
        onClick={history.goBack}
      >
        <i className="material-icons mr-8 medium-text">arrow_back</i>Go back
      </p>
      <StudentDetails pageRoute={props.pageRoute} />
    </div>
  );
}

export default withAppInsights(StudentDetailsPage);
