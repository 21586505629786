import React from "react";
import { Prompt } from "react-router-dom";
import AttendanceListRecord from "./AttendanceListRecord";
import StudentDetailsModal from "../../../students/StudentDetailsModal";
import { Navigation, PageURL } from "../../../../../../lib";
import { EduProgramTypes } from "../../../../eduUtils";
import debounce from "lodash.debounce";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import moment from "moment";

export default class AttendanceList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getInitializedCourseScheduleAttendance(),
      errorMessage: "",
    };
  }

  componentDidMount() {
    window.onbeforeunload = () =>
      !_isEqual(
        this.state.courseScheduleAttendance,
        this.state.initialCourseScheduleAttendance,
      ) || undefined;

    //set studentTab query param to open student details modal if studentId query param is specified
    const {
      pageRoute: {
        query: { studentId },
      },
    } = this.props;
    if (studentId) {
      this.toggleViewStudentDetails(studentId);
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  getInitializedCourseScheduleAttendance = (setStudents = true) => {
    const { courseScheduleAttendance, courseScheduleStudents } = this.props;
    return {
      initialCourseScheduleAttendance: courseScheduleAttendance,
      courseScheduleAttendance: _cloneDeep(courseScheduleAttendance),
      ...(setStudents
        ? {
            courseScheduleStudents: _cloneDeep(courseScheduleStudents.results),
          }
        : {}),
    };
  };

  onToggleAttendance = (
    courseScheduleEnrollmentID,
    classScheduleID,
    studentIndex,
  ) => {
    let courseScheduleAttendance = _cloneDeep(
      this.state.courseScheduleAttendance,
    );
    let courseScheduleStudents = _cloneDeep(this.state.courseScheduleStudents);

    const didAttend = courseScheduleAttendance.find(
      (a) =>
        a.eduClassScheduleID === classScheduleID &&
        a.eduStudentCourseScheduleEnrollmentID === courseScheduleEnrollmentID,
    );
    const numOfClassesAttended =
      courseScheduleStudents[studentIndex].numOfClassesAttended;
    if (didAttend) {
      //remove attendance record
      courseScheduleAttendance = courseScheduleAttendance.filter(
        (a) =>
          !(
            a.eduClassScheduleID === classScheduleID &&
            a.eduStudentCourseScheduleEnrollmentID ===
              courseScheduleEnrollmentID
          ),
      );
      courseScheduleStudents[studentIndex].numOfClassesAttended =
        numOfClassesAttended - 1;
    } else {
      //add attendance record
      courseScheduleAttendance.push({
        eduClassScheduleID: classScheduleID,
        eduStudentCourseScheduleEnrollmentID: courseScheduleEnrollmentID,
      });
      courseScheduleStudents[studentIndex].numOfClassesAttended =
        numOfClassesAttended + 1;
    }

    this.setState({
      courseScheduleAttendance,
      courseScheduleStudents,
      errorMessage: "",
    });

    this.onSaveAttendanceDebounce();
  };

  onSaveAttendance = async () => {
    const {
      submitCourseScheduleAttendance: { loading },
    } = this.props;
    const { courseScheduleAttendance, initialCourseScheduleAttendance } =
      this.state;
    if (
      loading ||
      _isEqual(courseScheduleAttendance, initialCourseScheduleAttendance)
    ) {
      return;
    }

    if (this.state.errorMessage) {
      this.setState({ errorMessage: "" });
    }

    await this.props.doSubmitCourseScheduleAttendance(courseScheduleAttendance);

    const {
      submitCourseScheduleAttendance: { error, errorMessage },
    } = this.props;
    if (error) {
      this.setState({ errorMessage });
    } else {
      this.setState(this.getInitializedCourseScheduleAttendance(false)); //we don't reset students here bec counts are manually updated after attendance update and only attendance is refreshed from the database
    }
  };

  onSaveAttendanceDebounce = debounce(this.onSaveAttendance, 2000);

  toggleViewStudentDetails = (studentId) => {
    const {
      eduProgramType,
      pageRoute: { query, page, params },
    } = this.props;

    Navigation.redirect(
      PageURL.to(page, params, {
        ...query,
        studentId: studentId || undefined,
        studentTab: studentId ? eduProgramType : undefined,
      }),
    );
  };

  render() {
    const {
      classSchedules,
      eduProgramType,
      pageRoute,
      submitCourseScheduleAttendance: { loading },
    } = this.props;

    const {
      query: { studentId: studentModalId, studentTab: studentModalTab },
    } = pageRoute;

    const {
      courseScheduleAttendance,
      courseScheduleStudents,
      errorMessage,
      initialCourseScheduleAttendance,
    } = this.state;

    const TBD = !classSchedules[0].dateTime;
    const readOnly = TBD || eduProgramType === EduProgramTypes.SinaiScholars;

    return (
      <div>
        <Prompt
          when={
            !_isEqual(courseScheduleAttendance, initialCourseScheduleAttendance)
          }
          message="Your changes have not been saved. Are you sure you want to leave this page?"
        />
        <div className="attendance-class-title">
          <div className="flex flex-align-center flex-justify-space">
            <p className="page-title mb-0">Attendance</p>
            {!_isEqual(
              courseScheduleAttendance,
              initialCourseScheduleAttendance,
            ) && (
              <div className="relative">
                <div className="small-text flex flex-align-center">
                  {loading ? "Saving Changes..." : "Unsaved Changes"}
                  {!loading && (
                    <p
                      className="ml-16 link-text"
                      onClick={this.onSaveAttendance}
                    >
                      Save now
                    </p>
                  )}
                </div>
                {errorMessage && (
                  <p
                    className="error-message no-wrap"
                    style={{ fontSize: "12px", right: 0 }}
                  >
                    {errorMessage}
                  </p>
                )}
              </div>
            )}
          </div>
          {TBD ? (
            <p className="error-text small-text mt-8">
              Attendance cannot be marked until Course Dates are set (on
              Schedule Tab)
            </p>
          ) : eduProgramType === EduProgramTypes.YourIsrael &&
            courseScheduleStudents.length ? (
            <p className="accent-text small-text mt-8">
              Note that students will receive an email notification when
              complete attendance is marked for the course
            </p>
          ) : (
            ""
          )}
        </div>
        {courseScheduleStudents.length ? (
          <div className="attendance-table">
            <div
              className={`attendance-table-header-row attendance-table-header-row-${eduProgramType}`}
            >
              <p>Name</p>
              <div
                className={`attendance-table-class-schedules-grid attendance-table-class-schedules-grid-${eduProgramType}`}
              >
                {classSchedules
                  .sort((cs1, cs2) => cs1.sortOrder - cs2.sortOrder)
                  .map((cs) => (
                    <div key={cs.id} className="flex flex-align-center">
                      <p>Class {cs.sortOrder}</p>
                      <div className="tooltip-container ml-8">
                        <i className="material-icons accent-text large-text">
                          info
                        </i>
                        <span className="tooltip">
                          Class {cs.sortOrder} {cs.title ? `- ${cs.title}` : ""}
                          <br />
                          {cs.dateTime &&
                            moment(cs.dateTime).format("M/D/YYYY")}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div>
              {courseScheduleStudents.map((student, index) => (
                <AttendanceListRecord
                  classAttendances={courseScheduleAttendance.filter(
                    (a) =>
                      a.eduStudentCourseScheduleEnrollmentID ===
                      student.eduStudentCourseScheduleEnrollmentID,
                  )}
                  classSchedules={classSchedules}
                  disabled={loading || readOnly}
                  eduProgramType={eduProgramType}
                  key={index}
                  onToggleAttendance={this.onToggleAttendance}
                  onViewStudentDetails={this.toggleViewStudentDetails}
                  student={student}
                  studentIndex={index}
                />
              ))}
            </div>
          </div>
        ) : (
          <div
            className="text-center accent-text fw-700 mt-32 mb-16 line-height-double"
            style={{ padding: "0 16px" }}
          >
            There are no approved students currently enrolled in this course
            schedule
          </div>
        )}

        <StudentDetailsModal
          close={() => this.toggleViewStudentDetails()}
          pageRoute={pageRoute}
          show={!!(studentModalId && studentModalTab)}
        />
      </div>
    );
  }
}
