import React from "react";
import CustomSelect from "../../../../components/form/CustomSelect";

export default class NewOrderAddressInfo extends React.PureComponent {
  render() {
    const { countries, address, onChange, onChangeEvent } = this.props;

    return (
      <div className="mb-24 new-order-address">
        <p className="fw-700 mb-16">Address</p>
        <div className="flex flex-align-center mb-12 mobile-block">
          <label className="small-text accent-text">Street Address</label>
          <input
            type="text"
            className="custom-input full-width"
            name="address.address1"
            onChange={onChangeEvent}
            placeholder="Street Address"
            value={address.address1}
          />
        </div>
        <div className="flex flex-align-center mb-12 mobile-block">
          <label className="small-text accent-text">Street Address 2</label>
          <input
            type="text"
            className="custom-input full-width"
            name="address.address2"
            onChange={onChangeEvent}
            placeholder="Street Address 2"
            value={address.address2}
          />
        </div>
        <div className="flex flex-align-center mb-12 mobile-block">
          <label className="small-text accent-text">Country</label>
          <CustomSelect
            isClearable={true}
            placeholder="Select Country..."
            onChange={onChange}
            name="address.country"
            options={
              countries &&
              countries.map((c) => ({
                key: c.name,
                value: c.name,
                label: c.name,
              }))
            }
            value={address.country}
          />
        </div>
        <div className="flex flex-align-center mb-12 mobile-block">
          <label className="small-text accent-text">City, State</label>
          <div className="flex full-width">
            <input
              type="text"
              className="custom-input mr-8"
              name="address.city"
              onChange={onChangeEvent}
              placeholder="City"
              value={address.city}
            />
            {address.country === "USA" ||
            address.country === "Canada" ||
            address.country === "Mexico" ? (
              <CustomSelect
                placeholder="Select State..."
                className="half-width"
                isClearable={true}
                disabled={!address.country}
                name="address.state"
                onChange={onChange}
                options={(
                  (countries &&
                    countries
                      .filter((c) => c.name === address.country)
                      .map((c) => c.states)[0]) ||
                  []
                ).map((state) => ({
                  key: state.name,
                  value: state.code,
                  label: state.name,
                }))}
                value={address.state}
              />
            ) : (
              <input
                type="text"
                className="custom-input mr-16"
                name="address.state"
                onChange={onChangeEvent}
                placeholder="State"
                value={address.state}
                disabled={!address.country}
              />
            )}
          </div>
        </div>
        <div className="flex flex-align-center mobile-block">
          <label className="small-text accent-text">Zip</label>
          <input
            type="text"
            className="custom-input mr-16"
            name="address.zip"
            onChange={onChangeEvent}
            placeholder="Zip"
            value={address.zip}
          />
        </div>
      </div>
    );
  }
}
