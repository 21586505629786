import React from "react";

export default class RecentViewTimeStamp extends React.PureComponent {
  state = {
    timeStamp: "",
  };

  componentDidMount() {
    this.updateTimeStamp();
    this.setState({
      tick: setInterval(() => {
        this.updateTimeStamp();
      }, 60000),
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.tick);
  }

  updateTimeStamp() {
    const { viewTime } = this.props;
    this.setState({
      timeStamp: this.timeSince(new Date(viewTime)),
    });
  }

  timeSince = (date) => {
    const seconds = Math.floor((new Date() - date) / 1000);
    let interval = Math.floor(seconds / 31536000);

    if (interval) {
      return `${interval} year${interval > 1 ? "s" : ""} ago`;
    }
    interval = Math.floor(seconds / 2592000);
    if (interval) {
      return `${interval} month${interval > 1 ? "s" : ""} ago`;
    }
    interval = Math.floor(seconds / 86400);
    if (interval) {
      return `${interval} day${interval > 1 ? "s" : ""} ago`;
    }
    interval = Math.floor(seconds / 3600);
    if (interval) {
      return `${interval} hour${interval > 1 ? "s" : ""} ago`;
    }
    interval = Math.floor(seconds / 60);
    if (interval) {
      return `${interval} minute${interval > 1 ? "s" : ""} ago`;
    }
    return "Just now";
  };

  render() {
    return this.state.timeStamp;
  }
}
