import { SmsActions } from "./actions";

export const SmsState = {
  name: "sms",
  persist: false,
  defaults: {
    loading: false,
  },
  handlers: {
    [SmsActions.SET_SUBMIT_SMS_LOADING]: (state, action) => {
      return {
        ...state,
        submitSms: { ...state.submitSms, loading: !!action.payload },
      };
    },
    [SmsActions.SET_SUBMIT_SMS_CREDIT_LOADING]: (state, action) => {
      return {
        ...state,
        submitSmsCredit: {
          ...state.submitSmsCredit,
          loading: !!action.payload,
        },
      };
    },
    [SmsActions.SUBMIT_SMS_CREDIT]: (state, action) => {
      return {
        ...state,
        submitSmsCredit: {
          ...state.submitSmsCredit,
          ...action.payload,
        },
      };
    },
    [SmsActions.SET_BULK_RECIPIENTS_LOADING]: (state, action) => {
      return {
        ...state,
        bulkRecipients: { loading: !!action.payload },
      };
    },
    [SmsActions.SET_SMS_SINGLE_RECIPIENTS]: (state, action) => {
      return {
        ...state,
        singleRecipients: action.payload,
      };
    },
    [SmsActions.UPDATE_SMS_TEXT]: (state, action) => {
      return { ...state, text: action.payload };
    },
    [SmsActions.UPDATE_SMS_IMAGE]: (state, action) => {
      return { ...state, image: action.payload };
    },
    [SmsActions.SET_SUBMIT_SMS]: (state, action) => {
      return {
        ...state,
        submitSms: action.payload,
      };
    },
    [SmsActions.SET_SMS_FILTERS]: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },

    [SmsActions.SET_BULK_RECIPIENTS]: (state, action) => {
      return {
        ...state,
        bulkRecipients: action.payload,
      };
    },
    [SmsActions.CLEAR_SMS]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    [SmsActions.CLEAR_SMS_FILTERS]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    [SmsActions.REMOVE_RECIPIENTS]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    [SmsActions.GET_PAGINATED_RECIPIENTS]: (state, action) => {
      return {
        ...state,
        ...action.page,
      };
    },
    [SmsActions.SET_SMS_STATEMENT_LOADING]: (state, action) => {
      return {
        ...state,
        statement: {
          loading: !!action.payload,
        },
      };
    },
    [SmsActions.SET_SMS_STATEMENT]: (state, action) => {
      return {
        ...state,
        statement: action.payload,
      };
    },
    [SmsActions.SET_SMS_ACCOUNT_DATA]: (state, action) => {
      return {
        ...state,
        statement: {
          ...action.payload,
          data: { ...state.statement?.data, ...action.payload.data },
        },
      };
    },

    [SmsActions.SET_SMS_INBOX_LOADING]: (state, action) => {
      return {
        ...state,
        inbox: { ...state.inbox, loading: !!action.payload },
      };
    },
    [SmsActions.SET_SMS_OUTBOX_LOADING]: (state, action) => {
      return {
        ...state,
        outbox: { ...state.outbox, loading: !!action.payload },
      };
    },
    [SmsActions.SET_SMS_MESSAGE_THREAD_LOADING]: (state, action) => {
      return {
        ...state,
        messageThread: {
          ...state.messageThread,
          loading: !!action.payload,
        },
      };
    },
    [SmsActions.SET_SMS_SCHEDULED_MESSAGES_LOADING]: (state, action) => {
      return {
        ...state,
        scheduledMessages: {
          ...state.scheduledMessages,
          loading: !!action.payload,
        },
      };
    },
    [SmsActions.SET_SMS_OUTBOX]: (state, action) => {
      if (state.outbox?.data?.results && action.payload?.data?.results) {
        const results = [
          ...state.outbox.data.results,
          ...action.payload.data.results,
        ];
        return {
          ...state,
          outbox: { ...action.payload, data: { results } },
        };
      }
      return {
        ...state,
        outbox: { ...action.payload },
      };
    },
    [SmsActions.CLEAR_SMS_OUTBOX]: (state) => {
      return {
        ...state,
        outbox: undefined,
      };
    },
    [SmsActions.CLEAR_SMS_INBOX]: (state) => {
      return {
        ...state,
        inbox: undefined,
      };
    },
    [SmsActions.CLEAR_SMS_SCHEDULED_MESSAGES]: (state) => {
      return {
        ...state,
        scheduledMessages: undefined,
      };
    },
    [SmsActions.CLEAR_SMS_MESAGE_THREAD]: (state) => {
      return {
        ...state,
        messageThread: undefined,
      };
    },
    [SmsActions.SET_SMS_INBOX]: (state, action) => {
      if (state.inbox?.data?.results && action.payload?.data?.results) {
        const results = [
          ...state.inbox.data.results,
          ...action.payload.data.results,
        ];
        return {
          ...state,
          inbox: { ...action.payload, data: { results } },
        };
      }
      return {
        ...state,
        inbox: { ...action.payload },
      };
    },
    [SmsActions.SET_SMS_MESSAGE_THREAD]: (state, action) => {
      return {
        ...state,
        messageThread: { ...action.payload },
      };
    },
    [SmsActions.SET_SMS_SCHEDULED_MESSAGES]: (state, action) => {
      if (
        state.scheduledMessages?.data?.results &&
        action.payload?.data?.results
      ) {
        const results = [
          ...state.scheduledMessages.data.results,
          ...action.payload.data.results,
        ];
        return {
          ...state,
          scheduledMessages: { ...action.payload, data: { results } },
        };
      }
      return {
        ...state,
        scheduledMessages: { ...action.payload },
      };
    },
  },
};
