import React from "react";
import { SegmentedMessage } from "sms-segments-calculator";

import { withAppInsights } from "../../lib";
import SelectRecipients from "./wizard/SelectRecipients.vm";
import ReviewRecipients from "./wizard/ReviewRecipients.vm";
import MessageStatus from "./wizard/MessageStatus";
import ComposeMessage from "./wizard/ComposeMessage.vm";
import { Navigation, PageURL, PageLink } from "../../lib";
import ProgressBar from "./wizard/ProgressBar";
import Big from "big.js";
import { getStudentRecipientDetails } from "./smsUtils";
import { Typography } from "@material-ui/core";
import ErrorDisplay from "../../components/ErrorDisplay";

class SmsWizard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.goToDefaultPageView();
  }

  state = {
    errorMessage: "",
  };

  wizardSteps = {
    selectRecipients: {
      pageComponent: SelectRecipients,
      nextStep: "reviewRecipients",
      stepName: "Choose Recipients",
      stepIndex: 0,
    },
    reviewRecipients: {
      pageComponent: ReviewRecipients,
      nextStep: "composeMessage",
      stepName: "Review Recipients",
      stepIndex: 1,
    },
    composeMessage: {
      pageComponent: ComposeMessage,
      nextStep: "confirmMessage",
      stepName: "Compose Message",
      stepIndex: 2,
    },
    confirmMessage: {
      pageComponent: MessageStatus,
      nextStep: "selectRecipients",
      stepName: "Sending",
      stepIndex: 3,
    },
  };

  goToNextStep = (step, success = false, clearQuery = false) => {
    const {
      pageRoute: { page, query },
    } = this.props;

    const { persist } = query;
    const url = PageURL.to(
      page,
      {
        pageView: step,
      },
      step === "confirmMessage"
        ? { persist, success }
        : clearQuery
        ? { persist }
        : query,
    );
    Navigation.go(url);
  };

  setDataforDirectReply = async () => {
    const {
      pageRoute: {
        query: { threadId },
      },
      actions,
    } = this.props;

    const rsvpEnrollmentResponse = await actions.getRsvpEnrollment();
    if (!rsvpEnrollmentResponse.success) {
      this.setState({ errorMessage: "Cannot retrieve RSVP enrollment" });
      return;
    }
    const { forChabadHouseID } = rsvpEnrollmentResponse.data.payload;
    if (forChabadHouseID) {
      const messageThreadResponse = await actions.getMessageThread(
        forChabadHouseID,
        threadId,
      );
      if (!messageThreadResponse.success) {
        this.setState({ errorMessage: "Cannot retrieve message thread" });
        return;
      }

      const studentDetails = await getStudentRecipientDetails(
        actions.getStudents,
        messageThreadResponse.data,
      );
      if (!studentDetails?.length) {
        this.setState({ errorMessage: "Cannot retrieve recipient details" });
        return;
      }
      actions.setSmsSingleRecipients(studentDetails);
    }
  };

  goToDefaultPageView = async () => {
    const {
      bulkRecipients: { recipients: bulkRecipients } = {},
      pageRoute: {
        page,
        params: { pageView },
        query: {
          eventDetails,
          filterByEventId,
          filterByResourceId,
          persist,
          threadId,
        },
      },
    } = this.props;
    if (
      pageView === "composeMessage" &&
      threadId &&
      !this.props.singleRecipients?.length &&
      !bulkRecipients?.length
    ) {
      await this.setDataforDirectReply();
    }
    const { singleRecipients } = this.props;
    let newQuery = {
      eventDetails,
      filterByEventId,
      filterByResourceId,
      persist,
    };
    if (!persist) {
      newQuery.persist = "true";
      this.props.actions.clearSms(true);
    }
    if (this.state.errorMessage) {
      return;
    }
    if (
      !persist ||
      !pageView ||
      !this.wizardSteps[pageView] ||
      (!singleRecipients?.length && !bulkRecipients?.length)
    ) {
      Navigation.redirect(
        PageURL.to(
          page,
          {
            pageView: "selectRecipients",
          },
          newQuery,
        ),
        Navigation.location.state,
      );
    }
  };

  calculateMessageData = async () => {
    const {
      bulkRecipients: { recipients: bulkRecipients } = {},
      image,
      singleRecipients,
      text,
      rsvp: { forChabadHouseID } = {},
      actions,
    } = this.props;

    const date = new Date();
    const { pricePerSegment, pricePerMmsSegment } =
      await actions.getSmsStatement(
        forChabadHouseID,
        new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString(
          "en-US",
        ),
        new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString(
          "en-US",
        ),
      );

    const allRecipients = [
      ...(singleRecipients || []),
      ...(bulkRecipients || []),
    ];

    const recipientCount = allRecipients.length;
    const segments = image
      ? 1
      : text
      ? new SegmentedMessage(text)?.segments?.length
      : 1;
    const pricePerMesssage = image
      ? Big(pricePerMmsSegment)
      : Big(pricePerSegment).times(segments);
    const price = pricePerMesssage.times(recipientCount);
    return { segments, price, allRecipients };
  };

  render() {
    const {
      actions: { clearSms },
      pageRoute,
      pageRoute: {
        params: { pageView },
        query: { success } = {},
      },
      rsvp: { programScheduleID: rsvpProgramScheduleID, forChabadHouseID } = {},
      submitSms: { timeToSend } = {},
      statement: { data: { balance } = {} } = {},
      draft: { selectedRecipients } = {},
    } = this.props;

    const { errorMessage } = this.state;

    const page = this.wizardSteps[pageView];

    return (
      <>
        {pageView && (
          <div className="sms-wizard-page">
            <Typography variant="h4" className="sms-wizard-title">
              Create new SMS message
            </Typography>

            <ProgressBar
              activeIndex={page.stepIndex}
              steps={this.wizardSteps}
            />
            {errorMessage ? (
              <div className="flex flex-column flex-align-center">
                <ErrorDisplay errorMessage={errorMessage} />
                <PageLink
                  className="btn btn-accent btn-medium mt-16"
                  style={{ width: 200 }}
                  to={"/sms/wizard"}
                >
                  Compose new message
                </PageLink>
              </div>
            ) : (
              React.createElement(page.pageComponent, {
                balance,
                page,
                pageRoute,
                selectedRecipients,
                goToNextStep: this.goToNextStep,
                reset: clearSms,
                rsvpProgramScheduleID,
                calculateMessageData: this.calculateMessageData,
                chabadHouseID: forChabadHouseID,
                success: success === "true",
                scheduledMessage: !!timeToSend,
              })
            )}
          </div>
        )}
      </>
    );
  }
}
export default withAppInsights(SmsWizard);
