import { RsvpActions } from "./actions";
import { AuthActions } from "../auth/actions";

export const RsvpState = {
  name: "rsvp",
  persist: false,
  defaults: {
    enrollment: {
      loading: false,
    },
    submitEnrollment: {
      loading: false,
    },

    submitEvent: {
      loading: false,
    },

    eventSchedules: {
      loading: false,
    },
    eventSchedulesBasic: {
      loading: false,
    },
    eventSchedule: {
      loading: false,
    },

    studentRegistrations: {
      loading: false,
    },
    submitStudentRegistration: {
      loading: false,
    },
    removeStudentRegistration: {
      loading: false,
    },

    submitResource: {
      loading: false,
    },

    resources: {
      loading: false,
    },
    resourcesBasic: {
      loading: false,
    },
    resource: {
      loading: false,
    },

    studentResourceRegistrations: {
      loading: false,
    },
    submitStudentResourceRegistration: {
      loading: false,
    },
    removeStudentResourceRegistration: {
      loading: false,
    },
  },
  handlers: {
    [RsvpActions.SET_RSVP_ENROLLMENT_LOADING]: (state, action) => {
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_RSVP_ENROLLMENT]: (state, action) => {
      return {
        ...state,
        enrollment: action.payload,
      };
    },
    [RsvpActions.SET_RSVP_ENROLLMENT_QUESTIONS]: (state, action) => {
      return {
        ...state,
        enrollment: {
          data: {
            ...state.enrollment?.data,
            registrationQuestions: action.payload,
          },
        },
      };
    },

    [RsvpActions.SET_SUBMIT_RSVP_ENROLLMENT_LOADING]: (state, action) => {
      return {
        ...state,
        submitEnrollment: {
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_SUBMIT_RSVP_ENROLLMENT]: (state, action) => {
      return {
        ...state,
        submitEnrollment: action.payload,
      };
    },

    [RsvpActions.SET_SUBMIT_RSVP_EVENT_LOADING]: (state, action) => {
      return {
        ...state,
        submitEvent: {
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_SUBMIT_RSVP_EVENT]: (state, action) => {
      return {
        ...state,
        submitEvent: action.payload,
      };
    },

    [RsvpActions.SET_RSVP_EVENT_SCHEDULES_LOADING]: (state, action) => {
      return {
        ...state,
        eventSchedules: {
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_RSVP_EVENT_SCHEDULES]: (state, action) => {
      return {
        ...state,
        eventSchedules: action.payload,
      };
    },

    [RsvpActions.SET_RSVP_EVENT_SCHEDULES_BASIC_LOADING]: (state, action) => {
      return {
        ...state,
        eventSchedulesBasic: {
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_RSVP_EVENT_SCHEDULES_BASIC]: (state, action) => {
      return {
        ...state,
        eventSchedulesBasic: action.payload,
      };
    },

    [RsvpActions.SET_RSVP_EVENT_SCHEDULE_LOADING]: (state, action) => {
      return {
        ...state,
        eventSchedule: {
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_RSVP_EVENT_SCHEDULE]: (state, action) => {
      return {
        ...state,
        eventSchedule: action.payload,
      };
    },
    [RsvpActions.CLEAR_RSVP_EVENT_SCHEDULE]: (state, action) => {
      return {
        ...state,
        eventSchedule: RsvpState.defaults.eventSchedule,
      };
    },

    [RsvpActions.SET_RSVP_EVENT_REGISTRATIONS_LOADING]: (state, action) => {
      return {
        ...state,
        studentRegistrations: {
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_RSVP_EVENT_REGISTRATIONS]: (state, action) => {
      return {
        ...state,
        studentRegistrations: action.payload,
      };
    },

    [RsvpActions.SET_SUBMIT_RSVP_STUDENT_REGISTRATION_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        submitStudentRegistration: {
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_SUBMIT_RSVP_STUDENT_REGISTRATION]: (state, action) => {
      return {
        ...state,
        submitStudentRegistration: action.payload,
      };
    },

    [RsvpActions.SET_REMOVE_RSVP_STUDENT_REGISTRATION_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        removeStudentRegistration: {
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_REMOVE_RSVP_STUDENT_REGISTRATION]: (state, action) => {
      return {
        ...state,
        removeStudentRegistration: action.payload,
      };
    },

    [RsvpActions.SET_SUBMIT_RSVP_RESOURCE_LOADING]: (state, action) => {
      return {
        ...state,
        submitResource: {
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_SUBMIT_RSVP_RESOURCE]: (state, action) => {
      return {
        ...state,
        submitResource: action.payload,
      };
    },

    [RsvpActions.SET_RSVP_RESOURCES_LOADING]: (state, action) => {
      return {
        ...state,
        resources: {
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_RSVP_RESOURCES]: (state, action) => {
      return {
        ...state,
        resources: action.payload,
      };
    },

    [RsvpActions.SET_RSVP_RESOURCES_BASIC_LOADING]: (state, action) => {
      return {
        ...state,
        resourcesBasic: {
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_RSVP_RESOURCES_BASIC]: (state, action) => {
      return {
        ...state,
        resourcesBasic: action.payload,
      };
    },

    [RsvpActions.SET_RSVP_RESOURCE_LOADING]: (state, action) => {
      return {
        ...state,
        resource: {
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_RSVP_RESOURCE]: (state, action) => {
      return {
        ...state,
        resource: action.payload,
      };
    },
    [RsvpActions.CLEAR_RSVP_RESOURCE]: (state) => {
      return {
        ...state,
        resource: RsvpState.defaults.resource,
      };
    },

    [RsvpActions.SET_RSVP_RESOURCE_REGISTRATIONS_LOADING]: (state, action) => {
      return {
        ...state,
        studentResourceRegistrations: {
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_RSVP_RESOURCE_REGISTRATIONS]: (state, action) => {
      return {
        ...state,
        studentResourceRegistrations: action.payload,
      };
    },

    [RsvpActions.SET_SUBMIT_RSVP_STUDENT_RESOURCE_REGISTRATION_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        submitStudentResourceRegistration: {
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_SUBMIT_RSVP_STUDENT_RESOURCE_REGISTRATION]: (
      state,
      action,
    ) => {
      return {
        ...state,
        submitStudentResourceRegistration: action.payload,
      };
    },

    [RsvpActions.SET_REMOVE_RSVP_STUDENT_RESOURCE_REGISTRATION_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        removeStudentResourceRegistration: {
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_REMOVE_RSVP_STUDENT_RESOURCE_REGISTRATION]: (
      state,
      action,
    ) => {
      return {
        ...state,
        removeStudentResourceRegistration: action.payload,
      };
    },

    [AuthActions.DO_SIGNOUT]: (state, action) => {
      return RsvpState.defaults; //reset RSVP enrollment on user signout
    },
  },
};
