import React, { memo } from "react";
import { Typography } from "@material-ui/core";
import { PageLink, PageNavLink } from "../lib";

const ProgramMenuLink = ({ route, type }) => {
  return (
    <PageLink
      key={route.scheduleID}
      to={`${route.baseUrl}/${route.scheduleID}`}
      className="fw-500 link-text-secondary line-height-double"
    >
      {type === routeTypes.Current
        ? route.programScheduleName
        : route.programName}
    </PageLink>
  );
};

const routeTypes = {
  Current: "Current",
  Managed: "Managed",
};

function DynamicProgramsMenu({ routes }) {
  if (!routes) return null;

  const currentProgramRoutes = routes.filter(
    (r) => r.types.indexOf(routeTypes.Current) >= 0,
  );
  const managedProgramRoutes = routes.filter(
    (r) => r.types.indexOf(routeTypes.Managed) >= 0,
  );

  return (
    <div className="program-dropdown-box dropdown-box">
      <PageNavLink
        to={"/programs"}
        className="link-text small-text"
        activeClassName="active-nav-link"
      >
        View All Programs
      </PageNavLink>
      <div className="two-column-grid">
        {!!currentProgramRoutes.length && (
          <div>
            <Typography variant="subtitle2">Current Programs:</Typography>
            {currentProgramRoutes
              .sort((r1, r2) =>
                r1.programScheduleName > r2.programScheduleName ? 1 : -1,
              )
              .map((route, index) => (
                <ProgramMenuLink
                  key={index}
                  route={route}
                  type={routeTypes.Current}
                />
              ))}
          </div>
        )}
        {!!managedProgramRoutes.length && (
          <div>
            <Typography variant="subtitle2">My Programs:</Typography>
            {managedProgramRoutes
              .sort((r1, r2) => (r1.programName > r2.programName ? 1 : -1))
              .map((route, index) => (
                <ProgramMenuLink
                  key={index}
                  route={route}
                  type={routeTypes.Managed}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(DynamicProgramsMenu);
