import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class TimePicker extends React.PureComponent {
  render() {
    const {
      className,
      disabled,
      hoursValue,
      minutesValue,
      name,
      onChange,
      readOnly,
      timeIntervals,
    } = this.props;

    return (
      <ReactDatePicker
        className={className}
        dateFormat={"h:mm a"}
        disabled={disabled}
        id={name}
        onChange={(date) =>
          onChange(name, {
            hours: date ? date.getHours() : "",
            minutes: date ? date.getMinutes() : "",
          })
        }
        readOnly={readOnly}
        selected={
          hoursValue || minutesValue
            ? new Date(1, 1, 1, hoursValue, minutesValue)
            : null
        }
        showTimeSelect
        showTimeSelectOnly
        timeCaption="Time"
        timeIntervals={timeIntervals || 15}
      />
    );
  }
}
