import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  Typography,
} from "@material-ui/core";
import { Group, Lock } from "@material-ui/icons";
import { getBannersToShow, Navigation } from "../../lib";
import { useStyles } from "./EngagementSideBar.styles";
import { activityMenuItems, eventMenuItems, smsMenuItems } from "./menuItems";
import SystemShortcutsMenu from "../../components/SystemShortcutsMenu";
import SmsInfoSidebar from "../rsvp/myRsvp/sms/SmsInfoSidebar";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { SystemSelectors, AuthSelectors } from "../../state";

interface EngagementSideBarButtonProps {
  url: string;
  text: string;
  activeUrl?: string;
  disabled?: boolean;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

const EngagementSideBarButton = memo(function ({
  url,
  text,
  Icon,
  activeUrl,
  disabled = false,
}: EngagementSideBarButtonProps) {
  const classes = useStyles({});
  const { pathname } = useLocation();
  return (
    <ListItem
      button
      disabled={disabled}
      className={classes.listItem}
      key={text}
      onClick={() => Navigation.go(url)}
      selected={
        pathname.includes(url) || !!(activeUrl && pathname === activeUrl)
      }
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={text} />
      {disabled && <Lock fontSize="small" className={classes.lockIcon} />}
    </ListItem>
  );
});

function EngagementSideBar() {
  const {
    isEnrolledInEngagement,
    isEnrolledInRsvp,
    isEnrolledInMessaging,
    isPendingForRsvpEnrollment,
  } = useSelector(AuthSelectors.engagementAccess);

  const { pathname } = useLocation();
  const banners = useSelector(SystemSelectors.banners);
  const bannersToShow = getBannersToShow(banners, Navigation.location.pathname);
  const classes = useStyles({ bannersToShow: bannersToShow?.length });

  return (
    <Box className={classes.sidebar}>
      <Drawer
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
        anchor="left"
      >
        <SystemShortcutsMenu
          buttonText="Create New"
          btnSize="large"
          className={classes.btn}
        />
        <EngagementSideBarButton
          key="Students"
          url={"/engagement/students"}
          text="Students"
          Icon={Group}
        />
        <List>
          <Typography className={classes.groupTitle}>Activity</Typography>
          {activityMenuItems.map(
            ({
              url,
              text,
              icon,
              activeUrl,
              isEnrolledInEngagementRequired = false,
            }) => (
              <EngagementSideBarButton
                key={text}
                url={url}
                activeUrl={activeUrl}
                text={text}
                Icon={icon}
                disabled={
                  isEnrolledInEngagementRequired && !isEnrolledInEngagement
                }
              />
            ),
          )}
        </List>
        <List>
          <Typography className={classes.groupTitle}>RSVP</Typography>
          {eventMenuItems.map(({ text, icon, url }) => (
            <EngagementSideBarButton
              key={text}
              url={url}
              text={text}
              Icon={icon}
              disabled={!isEnrolledInRsvp && !isPendingForRsvpEnrollment}
            />
          ))}
        </List>
        <List>
          <Typography className={classes.groupTitle}>Sms messages</Typography>
          {smsMenuItems.map(({ url, text, icon }) => (
            <EngagementSideBarButton
              key={text}
              disabled={!isEnrolledInMessaging}
              url={url}
              text={text}
              Icon={icon}
            />
          ))}
        </List>

        {pathname.includes("messaging") && isEnrolledInMessaging && (
          <SmsInfoSidebar />
        )}
      </Drawer>
    </Box>
  );
}

export default memo(EngagementSideBar);
