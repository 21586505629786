import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import PWAInstallPrompt from "./components/PWAInstallPrompt";
import Modal from "react-modal";
import { ThemeProvider, StylesProvider, jssPreset } from "@material-ui/core";
import { create as createJSS } from "jss";

import {
  AppRouter,
  ErrorSentry,
  setSentryUser,
  hasAuthRequestToken,
  setAuthRequestToken,
  onAuthFailed,
  getImpersonationAuthCookie,
  hasImpersonationAuthCookie,
  appInsightsInit,
  gaInit,
  frontChatInit,
  loadPolyfills,
  logRocketInit,
} from "./lib";

import { AppArea } from "./pages";
import { AuthActions, activateStore, store } from "./state";
import * as serviceWorker from "./serviceWorker";
import serviceWorkerConfig from "./serviceWorkerConfig";
import { defaultTheme } from "./themes";
import "./assets/css/styles.scss";

const jss = createJSS({
  ...jssPreset(),
  // See "Other HTML Element" at https://material-ui.com/styles/advanced/#insertionpoint
  insertionPoint: document.getElementById("jss-insertion-point"),
});

loadPolyfills();

appInsightsInit();

gaInit();

logRocketInit();

AppRouter.configure({
  loginCheck: hasAuthRequestToken,
  loginPath: "/login",
  loginRedirectParam: "after",
  pageOptions: {
    anon: false,
    pathExact: true,
  },
  rootArea: AppArea,
});

async function main() {
  await activateStore();
  Modal.setAppElement("body");
  preloadAuthToken();
  render();
}

function preloadAuthToken() {
  onAuthFailed(async (err) => {
    return store.dispatch(AuthActions.handleAuthFailure(err));
  });

  const {
    auth: {
      accountID,
      token,
      expiration,
      isImpersonated,
      person,
      userEmail,
      userEmailHash,
    } = {},
  } = store.getState();

  let hasImpersonationAuthToken = false;
  //if has impersonation auth cookie, sign in with impersonation auth or load auth token if previously authenticated
  if (hasImpersonationAuthCookie()) {
    const impersonationAuth = getImpersonationAuthCookie();

    //first check if current auth matches impersonation auth cookie
    hasImpersonationAuthToken =
      isImpersonated &&
      token &&
      expiration === impersonationAuth.expiration &&
      userEmail === impersonationAuth.userEmail;

    //if not currently authenticated, sign in with impersonation auth
    if (!hasImpersonationAuthToken) {
      return store.dispatch(
        AuthActions.doImpersonationSignin(impersonationAuth),
      );
    }
  } else if (isImpersonated) {
    //if current auth is impersonated and there is no impersonation auth cookie present (session ended), sign out of account to ensure that impersonation does not extend past a single browser session
    return store.dispatch(AuthActions.doSignout(false)); //no need to disable google auto-select when auto-signing out of an impersonated user account
  }

  const setAuthToken = isImpersonated ? hasImpersonationAuthToken : !!token;
  if (setAuthToken) {
    setAuthRequestToken(token, expiration);
    if (accountID) {
      setSentryUser(accountID, person, userEmail);
    }
  }

  frontChatInit(userEmail, userEmailHash);
}
// preloadAuthToken(); // removing this method invocation bec it is invoked in main();

function render() {
  let iOSVersion, mobileOperatingSystem, mobileUserAgent;
  if (process.env.NODE_ENV === "production") {
    ({ iOSVersion, mobileOperatingSystem, mobileUserAgent } =
      store.getState().ui);
  }

  ReactDOM.render(
    <ErrorSentry>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={defaultTheme}>
          <ReduxProvider store={store}>
            <AppRouter />
          </ReduxProvider>
          {process.env.NODE_ENV === "production" && (
            <PWAInstallPrompt
              iOSVersion={iOSVersion}
              mobileOperatingSystem={mobileOperatingSystem}
              mobileUserAgent={mobileUserAgent}
            />
          )}
        </ThemeProvider>
      </StylesProvider>
    </ErrorSentry>,
    document.getElementById("root"),
  );
}
if (process.env.NODE_ENV === "production") {
  serviceWorker.register(serviceWorkerConfig);
}
main();
