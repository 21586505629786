import React, { forwardRef, useMemo, useRef } from "react";
import {
  InputAdornment,
  MenuItem,
  Select,
  Typography,
  styled,
} from "@material-ui/core";
import {
  CountryIso2,
  defaultCountries,
  FlagEmoji,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import { TextInput, TextInputProps } from "./TextInput";
import {
  getCountryIdByCode,
  getDefaultCountryCode,
  getSupportedCountryCodes,
} from "../../../lib";
import { useSelector } from "react-redux";
import { SystemSelectors } from "../../../state";

const InputAdornmentStyled = styled(InputAdornment)(() => ({
  marginRight: "2px",
  "& .MuiSelect-selectMenu": {
    display: "flex",
    alignItems: "center",
  },
  "& .MuiInput-underline:before, .MuiInput-underline:after": {
    borderBottom: "none",
  },
}));

export type PhoneInputProps = {
  value: string;
  countryId: number | null;
  defaultCountryName?: string | null;
  onChange: (phone: string, countryId: number | null) => void;
} & Omit<TextInputProps, "onChange">;

export const PhoneInput = React.memo(
  forwardRef(
    /**
     *
     */
    function PhoneInput(
      {
        countryId,
        defaultCountryName,
        label,
        onChange,
        value,
        ...passProps
      }: PhoneInputProps,
      ref: React.Ref<any> | null,
    ) {
      const countries = useSelector(SystemSelectors.countries);

      const defaultCountryCode = useMemo(
        () => getDefaultCountryCode(countries, countryId, defaultCountryName),
        [countries, countryId, defaultCountryName],
      );

      const supportedCountries = useMemo(() => {
        const supportedCountryCodes = getSupportedCountryCodes(countries);
        return defaultCountries.filter((c) => {
          const code = c[1];
          return (
            code === defaultCountryCode || // the country corresponding to the defaultCountryCode must be included
            supportedCountryCodes.includes(code)
          );
        });
      }, [countries, defaultCountryCode]);

      // the onChange callback provided to usePhoneInput is called immediately upon input mount, without any manual input change
      // we therefore maintain this ref, which is set to true in the manual change handlers below, to ensure that the initial change callback is disregarded
      const changeActivated = useRef(false);

      const { country, handlePhoneValueChange, inputRef, phone, setCountry } =
        usePhoneInput({
          countries: supportedCountries,
          defaultCountry: defaultCountryCode,
          onChange: ({ country, phone }) => {
            if (changeActivated.current) {
              const countryId = country ? getCountryIdByCode(country) : null;
              onChange(phone, countryId);
            }
          },
          value,
          disableDialCodeAndPrefix: true,
        });

      return (
        <TextInput
          label={label}
          onChange={(e) => {
            if (!changeActivated.current) changeActivated.current = true;
            handlePhoneValueChange(e);
          }}
          inputRef={inputRef}
          InputProps={{
            startAdornment: (
              <InputAdornmentStyled position="start">
                <Select
                  MenuProps={{
                    style: {
                      height: "300px",
                      width: "360px",
                      top: "10px",
                      left: "-34px",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                  value={country || ""}
                  onChange={(e) => {
                    if (!changeActivated.current)
                      changeActivated.current = true;
                    setCountry(e.target.value as CountryIso2);
                  }}
                  renderValue={(value: any) => <FlagEmoji iso2={value} />}
                >
                  {supportedCountries.map((c) => {
                    const country = parseCountry(c);
                    return (
                      <MenuItem key={country.iso2} value={country.iso2}>
                        <FlagEmoji
                          iso2={country.iso2}
                          style={{ height: "24px", marginRight: "8px" }}
                        />
                        <Typography style={{ marginRight: "8px" }}>
                          {country.name}
                        </Typography>
                        <Typography>+{country.dialCode}</Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </InputAdornmentStyled>
            ),
          }}
          type="tel"
          ref={ref}
          value={phone}
          {...passProps}
        />
      );
    },
  ),
);
