import Raffle from "./Raffle.vm";
import { InternPermissions } from "../../lib";

export const RafflePages = {
  raffle: {
    path: "/grand-draw/:programScheduleID?",
    title: "My Grand Draw",
    type: "PAGE_RAFFLE",
    view: Raffle,
    externalUserAuthorization: [InternPermissions.manageRaffleDonations],
  },
};
export default RafflePages;

export const RaffleArea = {
  pages: RafflePages,
};
