import React from "react";
import ConfirmationModal from "../../../../../components/ConfirmationModal";
import FadeOutErrorMessage from "../../../../../components/FadeOutErrorMessage";
import { ApiCallErrorMessageHandler } from "../../../../../lib";

export default class MessageStudentsButton extends React.PureComponent {
  state = {
    errorMessage: "",
    loading: false,
    messageType: null,
    showConfirmMessageStudents: false,
    studentsContactsList: [],
  };

  messageTypes = {
    Email: "Email",
    Text: "Text",
  };

  getAllStudents = async () => {
    try {
      const results = await this.props.getStudents();
      if (!results || !results.length) {
        this.setState({ errorMessage: "No students found" });
      } else {
        return results;
      }
    } catch (err) {
      this.setState({ errorMessage: ApiCallErrorMessageHandler(err) });
    }
  };

  onEmailTextStudents = async (messageType) => {
    if (this.props.disabled || this.state.loading) {
      return;
    }

    this.setState({ loading: true, messageType });

    let studentsContactsList = [];

    const studentsList = await this.getAllStudents();
    if (studentsList && studentsList.length) {
      studentsContactsList = studentsList
        .filter(
          (s) =>
            !!(messageType === this.messageTypes.Text
              ? s.studentCell
              : s.studentEmail) &&
            s.courseScheduleEnrollmentStatus === "Approved",
        )
        .map((s) => ({
          name: `${s.studentFirstName} ${s.studentLastName}`,
          contact:
            messageType === this.messageTypes.Text
              ? s.studentCell
              : s.studentEmail,
        }));
    }

    this.setState({
      loading: false,
      showConfirmMessageStudents: studentsContactsList.length > 0,
      studentsContactsList,
    });
  };

  onCancelEmailTextStudents = () =>
    this.setState({
      messageType: null,
      showConfirmMessageStudents: false,
      studentsContactsList: [],
    });

  emailTextStudents = async () => {
    const {
      ui: { iOSVersion },
    } = this.props;
    const { messageType, studentsContactsList } = this.state;

    if (studentsContactsList && studentsContactsList.length) {
      const studentsContacts = studentsContactsList
        .map((s) => s.contact)
        .join(iOSVersion ? "," : ";");

      if (messageType === this.messageTypes.Text) {
        window.location.href = iOSVersion
          ? `sms://open?addresses=${studentsContacts}`
          : `sms:${studentsContacts}`;
      } else {
        window.location.href = `mailto:${studentsContacts}`;
      }

      // <a href="sms:7326749792;9175334330;8584444444">Send an SMS ANDROID</a>
      // <a href="sms://open?addresses=1-408-555-1212,1-408-555-2121,1-408-555-1221">Send an SMS IOS</a>
    }

    this.onCancelEmailTextStudents();
  };

  render() {
    const {
      disabled,
      ui: { isPhone },
    } = this.props;

    const {
      errorMessage,
      loading,
      messageType,
      showConfirmMessageStudents,
      studentsContactsList,
    } = this.state;

    return (
      <div
        className="flex relative"
        style={{ flex: "1", flexDirection: "column" }}
      >
        {isPhone ? (
          <div className="dropdown-container mr-16 mobile-mr-0">
            <button
              className="btn btn-accent flex flex-align-center"
              disabled={disabled}
            >
              Send Message
              <i className="material-icons ml-8">arrow_drop_down</i>
            </button>
            {!disabled && (
              <div className="dropdown btn-dropdown">
                <p
                  className="small-text link-text-secondary"
                  onClick={() =>
                    this.onEmailTextStudents(this.messageTypes.Email)
                  }
                >
                  {loading && messageType === this.messageTypes.Email
                    ? "Preparing Email..."
                    : "Email Students"}
                </p>
                <p
                  className="small-text link-text-secondary mb-0 mt-16"
                  onClick={() =>
                    this.onEmailTextStudents(this.messageTypes.Text)
                  }
                >
                  {loading && messageType === this.messageTypes.Text
                    ? "Preparing Text..."
                    : "Text Students"}
                </p>
              </div>
            )}
          </div>
        ) : (
          <button
            className="btn btn-accent mr-16 mobile-mr-0"
            disabled={disabled || loading}
            onClick={() => this.onEmailTextStudents(this.messageTypes.Email)}
          >
            {loading ? "Preparing Email..." : "Email Students"}
          </button>
        )}
        {errorMessage && (
          <FadeOutErrorMessage
            message={errorMessage}
            onTimeout={() => this.setState({ errorMessage: "" })}
          />
        )}

        <ConfirmationModal
          cancel={this.onCancelEmailTextStudents}
          confirm={() => this.emailTextStudents()}
          message={`Confirm that you'd like to prepare ${
            messageType === this.messageTypes.Text ? "a text" : "an email"
          } message for the following students:\n\n${
            studentsContactsList &&
            studentsContactsList
              .map((sc) => `${sc.name} - ${sc.contact}`)
              .join("\n")
          }${
            messageType === this.messageTypes.Text
              ? "\n\nPlease note that your messaging app's maximum recipients limitation will apply to this message.  Please review the recipient list before sending to confirm that all students are included."
              : ""
          }`}
          show={showConfirmMessageStudents}
          title="Message Students"
        />
      </div>
    );
  }
}
