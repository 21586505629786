import React from "react";
import Pages from "..";
import { PageLink } from "../../lib";

export default class LifeInsuranceInfoCard extends React.PureComponent {
  state = {
    fullInfoView: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({ fullInfoView: false });
    }
  }

  render() {
    const {
      policy: {
        officeStaff,
        programDescription,
        programID,
        programName,
        programScheduleID,
      },
    } = this.props;

    const { fullInfoView } = this.state;

    return (
      <div className="card life-insurance-sidebar-card">
        <div className="flex flex-justify-space flex-align-center">
          <p className="medium-text fw-700 line-height-double">
            {programName || "Life Insurance"} Info
          </p>
          <i
            className="material-icons desktop-hidden pointer"
            style={{ fontSize: "18px" }}
            onClick={() => this.setState({ fullInfoView: !fullInfoView })}
          >
            {fullInfoView ? "keyboard_arrow_up" : "keyboard_arrow_down"}
          </i>
        </div>

        {fullInfoView && (
          <React.Fragment>
            <p className="mt-16 line-height-double">{programDescription}</p>

            {officeStaff && (
              <React.Fragment>
                <div className="mb-8 mt-24">
                  <label>Program Coordinator</label>
                  <p>{officeStaff.fullName}</p>
                </div>
                <div className="program-contact-info">
                  {officeStaff.primaryPhone && (
                    <p>
                      <i className="material-icons">phone</i>
                      <a
                        href={`tel:${officeStaff.primaryPhone}${
                          officeStaff.primaryPhoneExt
                            ? `, ${officeStaff.primaryPhoneExt} : ""`
                            : ""
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {officeStaff.primaryPhone}
                        {officeStaff.primaryPhoneExt && (
                          <span className="ml-8 small-text">
                            Ext: {officeStaff.primaryPhoneExt}
                          </span>
                        )}
                      </a>
                    </p>
                  )}

                  {officeStaff.primaryEmail && (
                    <p>
                      <i className="material-icons">email</i>
                      <a
                        href={`mailto:${officeStaff.primaryEmail}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {officeStaff.primaryEmail}
                      </a>
                    </p>
                  )}
                </div>
              </React.Fragment>
            )}

            <p className="life-insurance-pricing-details line-height-double">
              Contract will automatically renew annually.
            </p>

            <p className="flex flex-align-center">
              <i className="material-icons large-text accent-text mr-8">
                open_in_new
              </i>
              <PageLink
                to={Pages.programs.programDetails}
                params={{ programId: programID || 0 }}
                query={{ sched: programScheduleID }}
                className="link-text"
                target="_blank"
                rel="noopener noreferrer"
              >
                {programName || "Life Insurance"} Info
              </PageLink>
            </p>
          </React.Fragment>
        )}
      </div>
    );
  }
}
