import { AuthRequest, ApiCallErrorMessageHandler } from "../../lib";

const defaultState = {
  loading: false,
  success: true,
  error: null,
  errorMessage: null,
};

export async function deleteScheduledMessage(id) {
  let newState = { ...defaultState };

  await AuthRequest.delete(`sms/messages/scheduled/${id}`).catch((err) => {
    newState.success = false;
    newState.error = err;
    newState.errorMessage = ApiCallErrorMessageHandler(err);
  });

  return newState;
}
