import { AuthRequest } from "../../lib";
import { ActivityActions } from "../activity/actions";

export const ContactActions = {
  SET_CONTACT_LOADING: "SET_CONTACT_LOADING",
  UPDATE_CONTACT: "UPDATE_CONTACT",

  getContact(id, trackShliachView) {
    return async (dispatch, getState) => {
      dispatch(ContactActions.setContactLoading(true));

      const newState = {
        loading: false,
        success: true,
      };

      try {
        const { data } = await AuthRequest.get(`Shluchim/${id}`);
        newState.chabadHouses = data.chabadHouses;
        newState.contactMethods = data.contactMethods;
        newState.dateStarted = data.dateStarted;
        newState.details = data.person;
        newState.id = data.id;
        newState.position = data.position;
        newState.spouse = data.spouse;
        newState.yearsOnShlichus = data.yearsOnShlichus;
      } catch (err) {
        newState.success = false;
      }

      dispatch(ContactActions.updateContact(newState));

      if (newState.success && trackShliachView) {
        dispatch(ActivityActions.trackShliachView(id));
      }
    };
  },
  setContactLoading(payload) {
    return {
      type: ContactActions.SET_CONTACT_LOADING,
      payload,
    };
  },
  updateContact(payload) {
    return {
      type: ContactActions.UPDATE_CONTACT,
      payload,
    };
  },
};
