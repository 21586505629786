import React from "react";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Dropzone from "react-dropzone";
import FadeOutErrorMessage from "../../../components/FadeOutErrorMessage";
import FilePreviewModal from "../../../components/FilePreviewModal";

export default class BeneficiaryDesignationDocumentUpload extends React.PureComponent {
  state = {
    docForUpload: null,
    errorMessage: "",
    loading: false,
    showConfirmUploadModal: false,
    showFilePreviewModal: false,
    successMessage: "",
  };

  closeConfirmUploadModal = () => {
    this.setState({
      docForUpload: null,
      showConfirmUploadModal: false,
    });
  };

  selectDocument = (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];

      const acceptedFileTypes = [".pdf"];
      if (
        acceptedFileTypes.some(
          (fileType) => file.name.toLowerCase().indexOf(fileType) > 0,
        )
      ) {
        const { enrollmentId } = this.props;

        this.setState({
          errorMessage: "",
        });

        const fileWithPreview = Object.assign(file, {
          previewURL: URL.createObjectURL(file),
        });
        if (!enrollmentId) {
          //new enrollment, no immediate upload to API so no confirmation necessary
          this.props.submitDocument(fileWithPreview);
        } else {
          this.setState({
            docForUpload: fileWithPreview,
            showConfirmUploadModal: true, //confirmation for existing enrollment - imed upload to API
          });
        }
      } else {
        this.setState({
          docForUpload: null,
          errorMessage: "Unsupported file type. Please upload a PDF file.",
        });
      }
    } else {
      this.setState({
        errorMessage: "File could not be uploaded. Please try again.",
      });
    }
  };

  submitDocument = () => {
    const { docForUpload } = this.state;

    this.closeConfirmUploadModal();

    this.setState(
      {
        errorMessage: "",
        loading: true,
        successMessage: "",
      },
      async () => {
        let newState = { loading: false };
        try {
          const uploadResponse = await this.props.submitDocument(docForUpload);

          if (uploadResponse) {
            //imediate doc upload
            const { error, errorMessage } = uploadResponse;

            if (error) {
              newState.errorMessage =
                errorMessage ||
                "Sorry, something went wrong and your form could not be uploaded.  Please try again.";
            } else {
              newState.successMessage =
                "Beneficiary Designation Form has been updated";
            }
          }
        } catch (err) {
          newState.errorMessage =
            "Sorry, something went wrong and your form could not be uploaded.  Please try again.";
        }

        this.setState({ ...newState });
      },
    );
  };

  render() {
    const { documentURL, disabled, error, isSecure, title } = this.props;
    const {
      errorMessage,
      loading,
      showConfirmUploadModal,
      showFilePreviewModal,
      successMessage,
    } = this.state;

    return (
      <div>
        <p className="medium-text fw-700 mb-16">{title || ""}</p>

        <Dropzone
          accept="application/pdf"
          className={`dropzone${disabled ? " disabled" : ""}`}
          disabled={disabled || loading}
          onDrop={this.selectDocument}
        >
          {({ getRootProps, getInputProps }) => (
            <p
              {...getRootProps()}
              className={`uppercase-text fw-500 link-text ${
                error ? " error" : ""
              }`}
            >
              <input {...getInputProps()} />
              Upload {documentURL ? "New Form" : "Form"}
            </p>
          )}
        </Dropzone>

        {(errorMessage || successMessage) && (
          <FadeOutErrorMessage
            className={successMessage ? "success-message" : ""}
            message={errorMessage || successMessage}
            onTimeout={() =>
              this.setState({
                errorMessage: "",
                successMessage: "",
              })
            }
          />
        )}

        {loading ? (
          <div className="accent-text">
            Uploading Beneficiary Designation Form...
          </div>
        ) : (
          documentURL && (
            <p
              className="flex flex-align-center accent-text mt-16 link-text"
              onClick={() => this.setState({ showFilePreviewModal: true })}
            >
              <i className="material-icons mr-8">description</i>
              Beneficiary Designation
            </p>
          )
        )}

        <FilePreviewModal
          close={() => this.setState({ showFilePreviewModal: false })}
          isPrivate={true}
          isSecure={isSecure}
          show={showFilePreviewModal}
          title={"Beneficiary Designation Form Preview"}
          type={"application/pdf"}
          url={documentURL}
        />

        <ConfirmationModal
          cancel={this.closeConfirmUploadModal}
          confirm={this.submitDocument}
          message={`Upload${
            documentURL ? " new" : ""
          } beneficiary designation form?`}
          show={showConfirmUploadModal}
        />
      </div>
    );
  }
}
