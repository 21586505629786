import React from "react";

export default ({
  allowNoOption,
  className,
  disabled,
  error,
  name,
  noOptionValue,
  onChange,
  options,
  style,
  type,
  value,
}) => (
  <div
    className={`radio-container ${className || ""} ${
      disabled ? "disabled" : ""
    } ${error ? "error" : ""}`}
    style={style}
  >
    {options.map((option) => (
      <React.Fragment key={option.value}>
        <input
          type={type || "radio"}
          id={`${name}-${option.display}`}
          name={name}
          value={option.value === null ? undefined : option.value}
          checked={value === option.value}
          onChange={() => onChange(name, option.value)} //to avoid default toString value
          onClick={
            allowNoOption && value === option.value //'deselect' handler when no option is allowed
              ? () => onChange(name, noOptionValue || "")
              : undefined
          }
          disabled={disabled || option.disabled}
        />
        <label
          className={disabled || option.disabled ? "disabled" : ""}
          htmlFor={`${name}-${option.display}`}
          style={{ minWidth: "96px", padding: "0 16px" }}
        >
          {option.display}
        </label>
      </React.Fragment>
    ))}
  </div>
);
