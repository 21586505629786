import { Navigation, PageURL, stringToColor } from "../../../lib";
import parsePhoneNumber from "libphonenumber-js";

export function getNamesForList(record) {
  return {
    name: record.firstPersonName,
    extraRecipients: record.totalRecipients - 1,
  };
}

export function getPhoneFormatted(phoneNumber) {
  const withCountryCode =
    phoneNumber.charAt(0) === "+" ? phoneNumber : `+${phoneNumber}`;
  const phone = parsePhoneNumber(withCountryCode);
  return phone?.formatNational() ?? null;
}

export function stringAvatar(name) {
  return {
    style: {
      backgroundColor: stringToColor(name),
      marginRight: 16,
    },
    children: `${name.charAt(0)}`,
  };
}

export const rsvpMessageTypes = {
  newEvent: "newEvent",
  updatedEvent: "updatedEvent",
  newResource: "newResource",
  updatedResource: "updatedResource",
};

export async function sendEventSmsMessage(
  eventScheduleId,
  eventStatus,
  actions,
) {
  const filters = {
    includeArchived: false,
    isJewish: true,
  };

  actions.clearSms(true);
  await actions.setSmsFilters(filters);
  await actions.getBulkRecipients(filters);

  Navigation.go(
    PageURL.to("/sms/wizard/composeMessage", null, {
      persist: true,
      edit: true,
      eventDetails: eventScheduleId,
      messageType: eventStatus,
    }),
  );
}

export async function sendResourceSmsMessage(
  resourceId,
  resourceStatus,
  actions,
) {
  const filters = {
    includeArchived: false,
    isJewish: true,
  };

  actions.clearSms(true);
  await actions.setSmsFilters(filters);
  await actions.getBulkRecipients(filters);

  Navigation.go(
    PageURL.to("/sms/wizard/composeMessage", null, {
      persist: true,
      edit: true,
      resourceDetails: resourceId,
      messageType: resourceStatus,
    }),
  );
}

export const messagesView = {
  inbox: "inbox",
  sent: "sent",
  scheduled: "scheduled",
};
