import { AuthRequest, ApiCallErrorMessageHandler } from "../../lib";
import queryString from "query-string";

export const RsvpActions = {
  SET_RSVP_CHABAD_HOUSES_LOADING: "SET_RSVP_CHABAD_HOUSES_LOADING",
  SET_RSVP_CHABAD_HOUSES: "SET_RSVP_CHABAD_HOUSES",

  SET_RSVP_ENROLLMENT_LOADING: "SET_RSVP_ENROLLMENT_LOADING",
  SET_RSVP_ENROLLMENT: "SET_RSVP_ENROLLMENT",

  SET_SUBMIT_RSVP_ENROLLMENT_LOADING: "SET_SUBMIT_RSVP_ENROLLMENT_LOADING",
  SET_SUBMIT_RSVP_ENROLLMENT: "SET_SUBMIT_RSVP_ENROLLMENT",
  SET_RSVP_ENROLLMENT_QUESTIONS: "SET_RSVP_ENROLLMENT_QUESTIONS",

  SET_SUBMIT_RSVP_EVENT_LOADING: "SET_SUBMIT_RSVP_EVENT_LOADING",
  SET_SUBMIT_RSVP_EVENT: "SET_SUBMIT_RSVP_EVENT",

  SET_RSVP_EVENT_SCHEDULES_LOADING: "SET_RSVP_EVENT_SCHEDULES_LOADING",
  SET_RSVP_EVENT_SCHEDULES: "SET_RSVP_EVENT_SCHEDULES",

  SET_RSVP_EVENT_SCHEDULES_BASIC_LOADING:
    "SET_RSVP_EVENT_SCHEDULES_BASIC_LOADING",
  SET_RSVP_EVENT_SCHEDULES_BASIC: "SET_RSVP_EVENT_SCHEDULES_BASIC",

  SET_RSVP_EVENT_SCHEDULE_LOADING: "SET_RSVP_EVENT_SCHEDULE_LOADING",
  SET_RSVP_EVENT_SCHEDULE: "SET_RSVP_EVENT_SCHEDULE",
  CLEAR_RSVP_EVENT_SCHEDULE: "CLEAR_RSVP_EVENT_SCHEDULE",

  SET_RSVP_EVENT_REGISTRATIONS_LOADING: "SET_RSVP_EVENT_REGISTRATIONS_LOADING",
  SET_RSVP_EVENT_REGISTRATIONS: "SET_RSVP_EVENT_REGISTRATIONS",

  SET_SUBMIT_RSVP_STUDENT_REGISTRATION_LOADING:
    "SET_SUBMIT_RSVP_STUDENT_REGISTRATION_LOADING",
  SET_SUBMIT_RSVP_STUDENT_REGISTRATION: "SET_SUBMIT_RSVP_STUDENT_REGISTRATION",

  SET_REMOVE_RSVP_STUDENT_REGISTRATION_LOADING:
    "SET_REMOVE_RSVP_STUDENT_REGISTRATION_LOADING",
  SET_REMOVE_RSVP_STUDENT_REGISTRATION: "SET_REMOVE_RSVP_STUDENT_REGISTRATION",

  SET_SUBMIT_RSVP_RESOURCE_LOADING: "SET_SUBMIT_RSVP_RESOURCE_LOADING",
  SET_SUBMIT_RSVP_RESOURCE: "SET_SUBMIT_RSVP_RESOURCE",

  SET_RSVP_RESOURCES_LOADING: "SET_RSVP_RESOURCES_LOADING",
  SET_RSVP_RESOURCES: "SET_RSVP_RESOURCES",

  SET_RSVP_RESOURCES_BASIC_LOADING: "SET_RSVP_RESOURCES_BASIC_LOADING",
  SET_RSVP_RESOURCES_BASIC: "SET_RSVP_RESOURCES_BASIC",

  SET_RSVP_RESOURCE_LOADING: "SET_RSVP_RESOURCE_LOADING",
  SET_RSVP_RESOURCE: "SET_RSVP_RESOURCE",
  CLEAR_RSVP_RESOURCE: "CLEAR_RSVP_RESOURCE",

  SET_RSVP_RESOURCE_REGISTRATIONS_LOADING:
    "SET_RSVP_RESOURCE_REGISTRATIONS_LOADING",
  SET_RSVP_RESOURCE_REGISTRATIONS: "SET_RSVP_RESOURCE_REGISTRATIONS",

  SET_SUBMIT_RSVP_STUDENT_RESOURCE_REGISTRATION_LOADING:
    "SET_SUBMIT_RSVP_STUDENT_RESOURCE_REGISTRATION_LOADING",
  SET_SUBMIT_RSVP_STUDENT_RESOURCE_REGISTRATION:
    "SET_SUBMIT_RSVP_STUDENT_RESOURCE_REGISTRATION",

  SET_REMOVE_RSVP_STUDENT_RESOURCE_REGISTRATION_LOADING:
    "SET_REMOVE_RSVP_STUDENT_RESOURCE_REGISTRATION_LOADING",
  SET_REMOVE_RSVP_STUDENT_RESOURCE_REGISTRATION:
    "SET_REMOVE_RSVP_STUDENT_RESOURCE_REGISTRATION",

  getRsvpChabadHouses() {
    return async (dispatch) => {
      dispatch(RsvpActions.setRsvpChabadHousesLoading(true));

      let newState = {};
      let success = true;

      const response = await AuthRequest.get(
        "RsvpEnrollments/allowedChabadHouses",
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setRsvpChabadHouses(newState));
    };
  },
  setRsvpChabadHousesLoading(payload) {
    return {
      type: RsvpActions.SET_RSVP_CHABAD_HOUSES_LOADING,
      payload,
    };
  },
  setRsvpChabadHouses(payload) {
    return {
      type: RsvpActions.SET_RSVP_CHABAD_HOUSES,
      payload,
    };
  },

  getRsvpEnrollment() {
    return async (dispatch) => {
      dispatch(RsvpActions.setRsvpEnrollmentLoading(true));

      let newState = {};
      let success = true;

      const response = await AuthRequest.get(
        "RsvpEnrollments/forChabadHouse",
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setRsvpEnrollment(newState));

      return { success, data: response?.data };
    };
  },
  setRsvpEnrollmentLoading(payload) {
    return {
      type: RsvpActions.SET_RSVP_ENROLLMENT_LOADING,
      payload,
    };
  },
  setRsvpEnrollment(payload) {
    return {
      type: RsvpActions.SET_RSVP_ENROLLMENT,
      payload,
    };
  },

  submitRsvpEnrollment(enrollment) {
    return async (dispatch) => {
      dispatch(RsvpActions.setSubmitRsvpEnrollmentLoading(true));

      let newState = {};
      let success = true;

      const response = await AuthRequest.post(
        "RsvpEnrollments",
        enrollment,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setSubmitRsvpEnrollment(newState));

      if (success) {
        newState.data = response.data.payload;

        //update response data as necessary to match GET enrollment reponse:

        // bec computed hasScheduledEvents property is not recalculated for the enrollment update response, set the value to the submitted enrollment hasScheduledEvents value
        newState.data.hasScheduledEvents = enrollment.hasScheduledEvents;

        // bec registrationQuestions includes inactive questions for the enrollment update response, filter out inactive registrationQuestions bec this field is accessed throughout RSVP (specifically for students export columns) and standard enrollment GET does not include inactive questions
        const { registrationQuestions } = newState.data;
        if (registrationQuestions) {
          newState.data.registrationQuestions = registrationQuestions.filter(
            (q) => q.isActive,
          );
        }

        dispatch(RsvpActions.setRsvpEnrollment(newState));
      }
    };
  },
  setSubmitRsvpEnrollmentLoading(payload) {
    return {
      type: RsvpActions.SET_SUBMIT_RSVP_ENROLLMENT_LOADING,
      payload,
    };
  },
  setSubmitRsvpEnrollment(payload) {
    return {
      type: RsvpActions.SET_SUBMIT_RSVP_ENROLLMENT,
      payload,
    };
  },

  setRsvpEnrollmentQuestions(payload) {
    return {
      type: RsvpActions.SET_RSVP_ENROLLMENT_QUESTIONS,
      payload,
    };
  },

  getRsvpEventSchedules(page, results, filters) {
    return async (dispatch, getState) => {
      dispatch(RsvpActions.setRsvpEventSchedulesLoading(true));

      let newState = {};
      let success = true;
      const {
        includeChabadHouseAddress,
        occurrenceStatus,
        settingStates = ["Active", "Paused"],
        search,
        sortBy: sortByOption,
      } = filters || {};
      const params = {
        export: !!filters?.export,
        includeChabadHouseAddress: !!includeChabadHouseAddress,
        occurrenceStatus,
        sortByOption,
        settingStates,
        page,
        results,
        text: search,
      };

      const { id: rsvpEnrollmentId } = getState().rsvp.enrollment.data || {};
      const response = await AuthRequest.get(
        `RsvpEnrollments/${rsvpEnrollmentId}/EventSchedules?${queryString.stringify(
          params,
        )}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setRsvpEventSchedules(newState));
      return response.data;
    };
  },
  setRsvpEventSchedulesLoading(payload) {
    return {
      type: RsvpActions.SET_RSVP_EVENT_SCHEDULES_LOADING,
      payload,
    };
  },
  setRsvpEventSchedules(payload) {
    return {
      type: RsvpActions.SET_RSVP_EVENT_SCHEDULES,
      payload,
    };
  },

  getRsvpEventSchedulesBasic() {
    return async (dispatch, getState) => {
      dispatch(RsvpActions.setRsvpEventSchedulesBasicLoading(true));

      let newState = {};
      let success = true;

      const { id: rsvpEnrollmentId } = getState().rsvp.enrollment.data || {};
      const response = await AuthRequest.get(
        `RsvpEnrollments/${rsvpEnrollmentId}/eventSchedules/basic`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setRsvpEventSchedulesBasic(newState));
    };
  },
  setRsvpEventSchedulesBasicLoading(payload) {
    return {
      type: RsvpActions.SET_RSVP_EVENT_SCHEDULES_BASIC_LOADING,
      payload,
    };
  },
  setRsvpEventSchedulesBasic(payload) {
    return {
      type: RsvpActions.SET_RSVP_EVENT_SCHEDULES_BASIC,
      payload,
    };
  },

  getRsvpEventSchedule(scheduleId, isRefresh = false) {
    return async (dispatch, getState) => {
      if (!isRefresh) {
        // hide loading state on refresh to avoid loading indication in UI
        dispatch(RsvpActions.setRsvpEventScheduleLoading(true));
      }

      let newState = {};
      let success = true;

      const { id: rsvpEnrollmentId } = getState().rsvp.enrollment.data || {};
      const response = await AuthRequest.get(
        `RsvpEnrollments/${rsvpEnrollmentId}/eventSchedules/${scheduleId}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setRsvpEventSchedule(newState));
    };
  },
  setRsvpEventScheduleLoading(payload) {
    return {
      type: RsvpActions.SET_RSVP_EVENT_SCHEDULE_LOADING,
      payload,
    };
  },
  setRsvpEventSchedule(payload) {
    return {
      type: RsvpActions.SET_RSVP_EVENT_SCHEDULE,
      payload,
    };
  },

  clearRsvpEventSchedule() {
    return {
      type: RsvpActions.CLEAR_RSVP_EVENT_SCHEDULE,
    };
  },

  submitRsvpEvent(eventSettingsOrSchedule) {
    return async (dispatch, getState) => {
      dispatch(RsvpActions.setSubmitRsvpEventLoading(true));

      let newState = {};
      let success = true;

      const { id: rsvpEnrollmentId } = getState().rsvp.enrollment.data || {};
      //when creating an event we post to 'eventSettings' endpoint
      //this will create an event settings with associated schedules, once an event is created we can update individual event schedules
      //when updating an event schedule we posts to 'eventSchedules' endpoint
      const response = await AuthRequest.post(
        `RsvpEnrollments/${rsvpEnrollmentId}/${
          !eventSettingsOrSchedule.eventSettingID
            ? "eventSettings"
            : "eventSchedules"
        }`,
        eventSettingsOrSchedule,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setSubmitRsvpEvent(newState));

      //when creating an event or updating a schedule, update eventSchedule state
      if (success) {
        newState.data = response.data.payload;
        dispatch(RsvpActions.setRsvpEventSchedule(newState));
        dispatch(
          RsvpActions.setRsvpEnrollmentQuestions(
            newState.data.allRegistrationQuestions,
          ),
        );
      }
    };
  },
  setSubmitRsvpEventLoading(payload) {
    return {
      type: RsvpActions.SET_SUBMIT_RSVP_EVENT_LOADING,
      payload,
    };
  },
  setSubmitRsvpEvent(payload) {
    return {
      type: RsvpActions.SET_SUBMIT_RSVP_EVENT,
      payload,
    };
  },

  getRsvpEventRegistrations(page, results, filters) {
    return async (dispatch, getState) => {
      dispatch(RsvpActions.setRsvpEventRegistrationsLoading(true));

      let newState = {};
      let success = true;

      const {
        schedule: eventScheduleId,
        search,
        status,
        sortBy,
      } = filters || {};
      const params = {
        eventScheduleId,
        page,
        results,
        status,
        text: search,
        sortBy,
      };

      const { id: rsvpEnrollmentId } = getState().rsvp.enrollment.data || {};
      const response = await AuthRequest.get(
        `RsvpEnrollments/${rsvpEnrollmentId}/events/registrations?${queryString.stringify(
          params,
        )}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setRsvpEventRegistrations(newState));
    };
  },
  setRsvpEventRegistrationsLoading(payload) {
    return {
      type: RsvpActions.SET_RSVP_EVENT_REGISTRATIONS_LOADING,
      payload,
    };
  },
  setRsvpEventRegistrations(payload) {
    return {
      type: RsvpActions.SET_RSVP_EVENT_REGISTRATIONS,
      payload,
    };
  },

  submitRsvpStudentRegistration(registration) {
    return async (dispatch) => {
      dispatch(RsvpActions.setSubmitRsvpStudentRegistrationLoading(true));

      let newState = {};
      let success = true;
      const { selectedStudentPersonIds, eventScheduleID, ...rest } =
        registration;

      if (selectedStudentPersonIds.length > 1) {
        await AuthRequest.post("RsvpRegistrations/bulk", {
          eventScheduleId: eventScheduleID,
          personIds: selectedStudentPersonIds,
        }).catch((err) => {
          success = false;
          newState = {
            error: err,
            errorMessage: ApiCallErrorMessageHandler(err),
          };
        });
      } else {
        await AuthRequest.post("RsvpRegistrations", {
          eventScheduleID,
          personID: selectedStudentPersonIds[0],
          ...rest,
        }).catch((err) => {
          success = false;
          newState = {
            error: err,
            errorMessage: ApiCallErrorMessageHandler(err),
          };
        });
      }

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setSubmitRsvpStudentRegistration(newState));
    };
  },
  setSubmitRsvpStudentRegistrationLoading(payload) {
    return {
      type: RsvpActions.SET_SUBMIT_RSVP_STUDENT_REGISTRATION_LOADING,
      payload,
    };
  },
  setSubmitRsvpStudentRegistration(payload) {
    return {
      type: RsvpActions.SET_SUBMIT_RSVP_STUDENT_REGISTRATION,
      payload,
    };
  },

  removeRsvpStudentRegistration(studentRegistrationId) {
    return async (dispatch) => {
      dispatch(RsvpActions.setRemoveRsvpStudentRegistrationLoading(true));

      let newState = {};
      let success = true;

      await AuthRequest.delete(
        `RsvpRegistrations/${studentRegistrationId}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setRemoveRsvpStudentRegistration(newState));
    };
  },
  setRemoveRsvpStudentRegistrationLoading(payload) {
    return {
      type: RsvpActions.SET_REMOVE_RSVP_STUDENT_REGISTRATION_LOADING,
      payload,
    };
  },
  setRemoveRsvpStudentRegistration(payload) {
    return {
      type: RsvpActions.SET_REMOVE_RSVP_STUDENT_REGISTRATION,
      payload,
    };
  },

  submitRsvpResource(resource) {
    return async (dispatch, getState) => {
      dispatch(RsvpActions.setSubmitRsvpResourceLoading(true));

      let newState = {};
      let success = true;

      const { id: rsvpEnrollmentId } = getState().rsvp.enrollment.data || {};
      const response = await AuthRequest.post(
        `RsvpEnrollments/${rsvpEnrollmentId}/resources`,
        resource,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setSubmitRsvpResource(newState));

      if (success) {
        newState.data = response.data.payload;
        dispatch(RsvpActions.setRsvpResource(newState));
        dispatch(
          RsvpActions.setRsvpEnrollmentQuestions(
            newState.data.allRegistrationQuestions,
          ),
        );
      }
    };
  },
  setSubmitRsvpResourceLoading(payload) {
    return {
      type: RsvpActions.SET_SUBMIT_RSVP_RESOURCE_LOADING,
      payload,
    };
  },
  setSubmitRsvpResource(payload) {
    return {
      type: RsvpActions.SET_SUBMIT_RSVP_RESOURCE,
      payload,
    };
  },

  getRsvpResources(page, results, filters) {
    return async (dispatch, getState) => {
      dispatch(RsvpActions.setRsvpResourcesLoading(true));

      let newState = {};
      let success = true;

      const {
        includeChabadHouseAddress,
        search,
        sortBy: sortByOption,
      } = filters || {};
      const params = {
        export: !!filters?.export,
        includeChabadHouseAddress: !!includeChabadHouseAddress,
        sortByOption,
        page,
        results,
        text: search,
      };

      const { id: rsvpEnrollmentId } = getState().rsvp.enrollment.data || {};
      const response = await AuthRequest.get(
        `RsvpEnrollments/${rsvpEnrollmentId}/resources?${queryString.stringify(
          params,
        )}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setRsvpResources(newState));
      return response.data;
    };
  },
  setRsvpResourcesLoading(payload) {
    return {
      type: RsvpActions.SET_RSVP_RESOURCES_LOADING,
      payload,
    };
  },
  setRsvpResources(payload) {
    return {
      type: RsvpActions.SET_RSVP_RESOURCES,
      payload,
    };
  },

  getRsvpResourcesBasic() {
    return async (dispatch, getState) => {
      dispatch(RsvpActions.setRsvpResourcesBasicLoading(true));

      let newState = {};
      let success = true;

      const { id: rsvpEnrollmentId } = getState().rsvp.enrollment.data || {};
      const response = await AuthRequest.get(
        `RsvpEnrollments/${rsvpEnrollmentId}/resources/basic`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setRsvpResourcesBasic(newState));
    };
  },
  setRsvpResourcesBasicLoading(payload) {
    return {
      type: RsvpActions.SET_RSVP_RESOURCES_BASIC_LOADING,
      payload,
    };
  },
  setRsvpResourcesBasic(payload) {
    return {
      type: RsvpActions.SET_RSVP_RESOURCES_BASIC,
      payload,
    };
  },

  getRsvpResource(resourceId, isRefresh = false) {
    return async (dispatch, getState) => {
      if (!isRefresh) {
        // hide loading state on refresh to avoid loading indication in UI
        dispatch(RsvpActions.setRsvpResourceLoading(true));
      }

      let newState = {};
      let success = true;

      const { id: rsvpEnrollmentId } = getState().rsvp.enrollment.data || {};
      const response = await AuthRequest.get(
        `RsvpEnrollments/${rsvpEnrollmentId}/resources/${resourceId}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setRsvpResource(newState));
    };
  },
  setRsvpResourceLoading(payload) {
    return {
      type: RsvpActions.SET_RSVP_RESOURCE_LOADING,
      payload,
    };
  },
  setRsvpResource(payload) {
    return {
      type: RsvpActions.SET_RSVP_RESOURCE,
      payload,
    };
  },

  clearRsvpResource() {
    return {
      type: RsvpActions.CLEAR_RSVP_RESOURCE,
    };
  },

  getRsvpResourceRegistrations(page, results, filters) {
    return async (dispatch, getState) => {
      dispatch(RsvpActions.setRsvpResourceRegistrationsLoading(true));

      let newState = {};
      let success = true;

      const { resource: resourceId, search, status, sortBy } = filters || {};
      const params = {
        resourceId,
        page,
        results,
        status,
        text: search,
        sortBy,
      };

      const { id: rsvpEnrollmentId } = getState().rsvp.enrollment.data || {};
      const response = await AuthRequest.get(
        `RsvpEnrollments/${rsvpEnrollmentId}/resources/registrations?${queryString.stringify(
          params,
        )}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setRsvpResourceRegistrations(newState));
    };
  },
  setRsvpResourceRegistrationsLoading(payload) {
    return {
      type: RsvpActions.SET_RSVP_RESOURCE_REGISTRATIONS_LOADING,
      payload,
    };
  },
  setRsvpResourceRegistrations(payload) {
    return {
      type: RsvpActions.SET_RSVP_RESOURCE_REGISTRATIONS,
      payload,
    };
  },

  submitRsvpStudentResourceRegistration(registration) {
    return async (dispatch) => {
      dispatch(
        RsvpActions.setSubmitRsvpStudentResourceRegistrationLoading(true),
      );

      let newState = {};
      let success = true;

      const { selectedStudentPersonIds, resourceId, ...rest } = registration;

      if (selectedStudentPersonIds.length > 1) {
        await AuthRequest.post("RsvpRegistrations/resources/bulk", {
          resourceId,
          personIds: selectedStudentPersonIds,
        }).catch((err) => {
          success = false;
          newState = {
            error: err,
            errorMessage: ApiCallErrorMessageHandler(err),
          };
        });
      } else {
        await AuthRequest.post("RsvpRegistrations/resources", {
          resourceId,
          personID: selectedStudentPersonIds[0],
          ...rest,
        }).catch((err) => {
          success = false;
          newState = {
            error: err,
            errorMessage: ApiCallErrorMessageHandler(err),
          };
        });
      }

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setSubmitRsvpStudentResourceRegistration(newState));
    };
  },
  setSubmitRsvpStudentResourceRegistrationLoading(payload) {
    return {
      type: RsvpActions.SET_SUBMIT_RSVP_STUDENT_RESOURCE_REGISTRATION_LOADING,
      payload,
    };
  },
  setSubmitRsvpStudentResourceRegistration(payload) {
    return {
      type: RsvpActions.SET_SUBMIT_RSVP_STUDENT_RESOURCE_REGISTRATION,
      payload,
    };
  },

  removeRsvpStudentResourceRegistration(studentRegistrationId) {
    return async (dispatch) => {
      dispatch(
        RsvpActions.setRemoveRsvpStudentResourceRegistrationLoading(true),
      );

      let newState = {};
      let success = true;

      await AuthRequest.delete(
        `RsvpRegistrations/resources/${studentRegistrationId}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      newState.loading = false;
      newState.success = success;

      dispatch(RsvpActions.setRemoveRsvpStudentResourceRegistration(newState));
    };
  },
  setRemoveRsvpStudentResourceRegistrationLoading(payload) {
    return {
      type: RsvpActions.SET_REMOVE_RSVP_STUDENT_RESOURCE_REGISTRATION_LOADING,
      payload,
    };
  },
  setRemoveRsvpStudentResourceRegistration(payload) {
    return {
      type: RsvpActions.SET_REMOVE_RSVP_STUDENT_RESOURCE_REGISTRATION,
      payload,
    };
  },
};
