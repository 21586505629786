export const interactionTypeNames = {
  AffixMezuzah: "Affix Mezuzah",
  LightShabbatCandles: "Light Shabbat Candles",
  PutOnTefillin: "Put on Tefillin",
};

export const immersiveInteractionCategories = [
  "Immersive Experience",
  "Immersive Education",
];
export const interactionTypeClassifications = {
  Experience: "Experience",
};
