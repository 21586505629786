import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../types";
import { EduProgramBasicResponse } from "./types";
import { EduProgramTypes } from "../../pages/edu/eduUtils";

const emptyEduProgramBasicResponseArray: EduProgramBasicResponse[] = [];
const defaultObject: any = {};

export const EduSelectors = {
  jewishUProgramDetails: createSelector(
    (state: AppState) =>
      state.edu.enrolledPrograms?.data || emptyEduProgramBasicResponseArray,
    (enrolledPrograms: any) =>
      enrolledPrograms.find(
        (p: EduProgramBasicResponse) => p.type === EduProgramTypes.JewishU,
      ) || defaultObject,
  ),
  yourIsraelProgramDetails: createSelector(
    (state: AppState) =>
      state.edu.enrolledPrograms?.data || emptyEduProgramBasicResponseArray,
    (enrolledPrograms: any) =>
      enrolledPrograms.find(
        (p: EduProgramBasicResponse) => p.type === EduProgramTypes.YourIsrael,
      ) || defaultObject,
  ),
  studentEnrollmentStatuses(state: AppState) {
    return state.edu.studentEnrollmentStatuses;
  },
};
