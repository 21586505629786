import React, { useState, useEffect, useMemo, memo } from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { EngagementSelectors, SystemSelectors } from "../../../../state";
import Loader from "../../../../components/Loader";
import { formatNumber, sum } from "../../../../lib";
import {
  getInteractionDataKey,
  getMetricDetailsAccessor,
  getMonthYearLabel,
} from "./metricHelpers";
import { InteractionSettingTypes } from "../shared";
import InteractionGoalChartHeader from "./InteractionMetricChartHeader";
import InteractionsBarChart from "./InteractionsBarChart";
import NoMetrics from "./NoMetrics";
import ColoredLegendEntry from "../../ColoredLegendEntry";

function formatData(data, interactionCategories) {
  return interactionCategories
    .map((category) => category.id)
    .map((interactionCategoryID) => {
      const interactionCategoryData = {
        interactionCategoryID,
        total: 0,
      };

      data
        .filter(
          (metrics) => metrics.interactionCategoryID === interactionCategoryID,
        )
        .forEach((metric) => {
          const { communalTotal, individualTotal, monthYear } = metric;
          const formatMonthYear = new Date(monthYear).toISOString();

          interactionCategoryData[
            getInteractionDataKey(
              formatMonthYear,
              InteractionSettingTypes.Individual,
            )
          ] = individualTotal;

          interactionCategoryData[
            getInteractionDataKey(
              formatMonthYear,
              InteractionSettingTypes.Communal,
            )
          ] = communalTotal;

          interactionCategoryData[getMetricDetailsAccessor(formatMonthYear)] = {
            ...metric,
            monthYear: formatMonthYear,
          };

          interactionCategoryData.total += communalTotal + individualTotal;
        });

      return interactionCategoryData;
    });
}

function InteractionMetricsChart(props) {
  const { selectedDates, getColors, monthYearsArray } = props;

  const [formattedData, setFormattedData] = useState([]);
  const [interactionSettingFilter, setInteractionSettingFilter] = useState("");

  const { data, loading, errorMessage } = useSelector(
    EngagementSelectors.interactionMetrics,
  );
  const interactionCategories =
    useSelector(SystemSelectors.interactionCategories) || []; // value is undefined on signout

  useEffect(
    function updateLocalData() {
      if (data) {
        //we are setting the data in local state per this issue where the bar labels do not appear on initial mount when reading directly from props https://github.com/recharts/recharts/issues/829
        setFormattedData(formatData(data, interactionCategories));
      }
    },
    [data, interactionCategories],
  );

  const formatTickY = (key, data) => {
    const categoryDisplay =
      interactionCategories.find((c) => c.id === key)?.name || "";
    const total = data.find((d) => d.interactionCategoryID === key)?.total;

    return `${categoryDisplay}\nTotal: ${formatNumber(total)}`;
  };

  const dataTotal = useMemo(
    () => sum(formattedData.map((d) => d.total)),
    [formattedData],
  );

  const dataMax = useMemo(
    () =>
      formattedData?.reduce(
        (prev, current) => (prev?.total > current?.total ? prev : current),
        null,
      ),
    [formattedData],
  );

  const rowHeight =
    selectedDates?.length * (interactionSettingFilter ? 20 : 30);
  const minRowHeight = 60;

  const chartHeight =
    formattedData.length *
    (rowHeight >= minRowHeight ? rowHeight : minRowHeight);

  return (
    <div className="graph-outline">
      <InteractionGoalChartHeader
        dataTotal={dataTotal}
        loading={loading}
        setInteractionSettingFilter={setInteractionSettingFilter}
        interactionSettingFilter={interactionSettingFilter}
      />
      <div className="mt-16 mb-16">
        {loading ? (
          <Loader />
        ) : errorMessage ? (
          <p className="error-text small-text text-center">{errorMessage}</p>
        ) : !formattedData.length ? (
          <NoMetrics message="No engagement metrics found" />
        ) : (
          <>
            <Box className="flex flex-align-center mobile-block">
              {selectedDates.map((monthYear, index) => (
                <ColoredLegendEntry
                  getColors={getColors}
                  id={monthYear}
                  name={getMonthYearLabel(monthYearsArray, monthYear)}
                  key={index}
                />
              ))}
            </Box>
            <InteractionsBarChart
              chartHeight={chartHeight}
              data={formattedData}
              tickYFormatter={(key) => formatTickY(key, formattedData)}
              metrics={selectedDates}
              interactionSettingFilter={interactionSettingFilter}
              getColors={getColors}
              isGoalData={false}
              xDomain={[0, dataMax || 100]}
              tickYDataKey={"interactionCategoryID"}
              tickXFormatter={(key) => key}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default memo(InteractionMetricsChart);
