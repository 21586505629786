import Edu from "./Edu.vm";
import StudentEnrollmentAction from "./myEdu/students/StudentEnrollmentAction";
import { InternPermissions } from "../../lib";

export const EduPages = {
  eduStudentEnrollmentAction: {
    anon: true,
    path: "/edu/:programScheduleId/students/enrollmentAction",
    title: "Edu Student Enrollment",
    type: "PAGE_EDU_STUDENT_ENROLLMENT_ACTION",
    view: StudentEnrollmentAction,
  },
  edu: {
    anon: false,
    path: "/edu/:programScheduleId?/:pageView?/:courseId?/:courseView?/:courseScheduleId?",
    title: "Edu Learning Initiative",
    type: "PAGE_EDU",
    view: Edu,
    key: { params: ["programScheduleId"] },
    // intern authorization for specific pageViews is managed in MyEdu.jsx
    // intern authorization for specific courseViews is managed in CourseContainer.jsx
    externalUserAuthorization: [
      InternPermissions.eduAttendance,
      InternPermissions.eduSchedule,
      InternPermissions.eduStudents,
    ],
  },
};
export default EduPages;

export const EduArea = {
  pages: EduPages,
};
