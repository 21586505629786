import { AuthRequest, ApiCallErrorMessageHandler } from "../../lib";
import queryString from "query-string";

export const SmsActions = {
  UPDATE_SMS_TEXT: "UPDATE_SMS_TEXT",
  UPDATE_SMS_IMAGE: "UPDATE_SMS_IMAGE",
  UPDATE_SMS_BALANCE: "UPDATE_SMS_BALANCE",
  SET_SUBMIT_SMS_CREDIT_LOADING: "SET_SUBMIT_SMS_CREDIT_LOADING",
  SUBMIT_SMS_CREDIT: "SUBMIT_SMS_CREDIT",
  SET_SUBMIT_SMS_LOADING: "SET_SUBMIT_SMS_LOADING",
  SET_SUBMIT_SMS: "SET_SUBMIT_SMS",
  SET_BULK_RECIPIENTS_LOADING: "SET_BULK_RECIPIENTS_LOADING",
  SET_BULK_RECIPIENTS: "SET_BULK_RECIPIENTS",
  CLEAR_SMS: "CLEAR_SMS",
  CLEAR_SMS_FILTERS: "CLEAR_SMS_FILTERS",
  REMOVE_RECIPIENTS: "REMOVE_RECIPIENTS",
  GET_PAGINATED_RECIPIENTS: "GET_PAGINATED_RECIPIENTS",

  SET_SMS_STATEMENT_LOADING: "SET_SMS_STATEMENT_LOADING",
  SET_SMS_ACCOUNT_DATA_LOADING: "SET_SMS_ACCOUNT_DATA_LOADING",
  SET_SMS_STATEMENT: "SET_SMS_STATEMENT",
  SET_SMS_ACCOUNT_DATA: "SET_SMS_ACCOUNT_DATA",
  SET_SMS_SINGLE_RECIPIENTS: "SET_SMS_SINGLE_RECIPIENTS",
  SET_SMS_FILTERS: "SET_SMS_FILTERS",
  SET_SMS_OUTBOX: "SET_SMS_OUTBOX",
  SET_SMS_INBOX: "SET_SMS_INBOX",
  SET_SMS_SCHEDULED_MESSAGES: "SET_SMS_SCHEDULED_MESSAGES",
  SET_SMS_INBOX_LOADING: "SET_SMS_INBOX_LOADING",
  SET_SMS_OUTBOX_LOADING: "SET_SMS_OUTBOX_LOADING",
  SET_SMS_MESSAGE_THREAD_LOADING: "SET_SMS_MESSAGE_THREAD_LOADING",
  SET_SMS_SCHEDULED_MESSAGES_LOADING: "SET_SMS_SCHEDULED_MESSAGES_LOADING",
  CLEAR_SMS_INBOX: "CLEAR_SMS_INBOX",
  CLEAR_SMS_OUTBOX: "CLEAR_SMS_OUTBOX",
  CLEAR_SMS_SCHEDULED_MESSAGES: "CLEAR_SMS_SCHEDULED_MESSAGES",
  CLEAR_SMS_MESAGE_THREAD: "CLEAR_SMS_MESAGE_THREAD",
  SET_SMS_MESSAGE_THREAD: "SET_SMS_MESSAGE_THREAD",

  setSmsFilters(payload) {
    return {
      type: SmsActions.SET_SMS_FILTERS,
      payload,
    };
  },
  setSubmitSmsLoading(payload) {
    return {
      type: SmsActions.SET_SUBMIT_SMS_LOADING,
      payload,
    };
  },
  setSmsSingleRecipients(payload) {
    return {
      type: SmsActions.SET_SMS_SINGLE_RECIPIENTS,
      payload,
    };
  },
  updateSmsText(payload) {
    return {
      type: SmsActions.UPDATE_SMS_TEXT,
      payload,
    };
  },
  updateSmsImage(payload) {
    return {
      type: SmsActions.UPDATE_SMS_IMAGE,
      payload,
    };
  },
  setBulkRecipientsLoading(payload) {
    return {
      type: SmsActions.SET_BULK_RECIPIENTS_LOADING,
      payload,
    };
  },
  setBulkRecipients(payload) {
    return {
      type: SmsActions.SET_BULK_RECIPIENTS,
      payload,
    };
  },
  clearSms(fullReset = true) {
    let newState = {
      bulkRecipients: [],
      recipientPage: [],
      text: "",
      image: undefined,
    };
    if (fullReset) {
      newState.singleRecipients = [];
      newState.filters = null;
    }
    return {
      type: SmsActions.CLEAR_SMS,
      payload: newState,
    };
  },
  clearSmsFilters() {
    return {
      type: SmsActions.CLEAR_SMS,
      payload: { filters: null },
    };
  },
  removeRecipients(students, page, pageView = 10) {
    return async (dispatch, getState) => {
      const studentIDs = students.map((s) => s.studentID);
      const {
        sms: {
          singleRecipients,
          bulkRecipients,
          bulkRecipients: { recipients: bulkRecipientArray } = {},
        } = {},
      } = getState();

      let newSingleRecipients = singleRecipients ? [...singleRecipients] : [];
      newSingleRecipients = newSingleRecipients.filter(
        (s) => !studentIDs.includes(s.studentID),
      );

      let newBulkRecipients = bulkRecipientArray ? [...bulkRecipientArray] : [];
      newBulkRecipients = newBulkRecipients.filter(
        (s) => !studentIDs.includes(s.studentID),
      );

      let newState = {
        singleRecipients: newSingleRecipients,
        bulkRecipients: { ...bulkRecipients, recipients: newBulkRecipients },
      };
      const currentPage = SmsActions.getRecipientsPage(
        page,
        newState,
        pageView,
      );
      newState = { ...newState, ...currentPage };

      dispatch({
        type: SmsActions.REMOVE_RECIPIENTS,
        payload: newState,
      });
    };
  },
  getBulkRecipients(filters) {
    return async (dispatch, getState) => {
      dispatch(SmsActions.setBulkRecipientsLoading(true));

      let newState = {};
      let success = true;

      const {
        campusIDs,
        classes,
        graduationYears,
        chabadHouseTags,
        excludeParticipatedInRsvpEvents,
        excludeParticipatedInRsvpResources,
        excludeParticipatedInRsvpTypes,
        excludeParticipatedInPrograms,
        includeArchived,
        isJewish,
        gender,
        programsParticipated,
        rsvpEventsParticipated,
        rsvpResourcesParticipated,
        rsvpTypesParticipated,
      } = filters || {};
      const params = {
        campusID: campusIDs,
        classes,
        excludeParticipatedInRsvpEvents,
        excludeParticipatedInRsvpResources,
        excludeParticipatedInRsvpTypes,
        excludeParticipatedInPrograms,
        graduationYear: graduationYears,
        chabadHouseTags,
        includeArchived,
        isJewish,
        gender,
        rsvpEventsParticipated,
        rsvpResourcesParticipated,
        rsvpTypesParticipated,
        programsParticipated,
        isExport: true,
        includeDetails: true,
      };
      const { sms: { singleRecipients } = {} } = getState();
      const response = await AuthRequest.get(
        `Students/byShliach?${queryString.stringify(params)}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        let recipients = response.data.payload.results;
        let singleStudentIDs = singleRecipients?.map((s) => s.studentID) || [];
        recipients = recipients.filter(
          (s) => !singleStudentIDs.includes(s.studentID),
        );

        newState.recipients = recipients;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(SmsActions.setBulkRecipients(newState));
    };
  },
  getSmsStatement(chabadHouseId, startDate, endDate) {
    return async (dispatch) => {
      dispatch(SmsActions.setSmsStatementLoading(true));
      let newState = {};
      let success = true;
      const params = {
        startDate,
        endDate,
      };
      const response = await AuthRequest.get(
        `ChabadHouses/${chabadHouseId}/smsStatement?${queryString.stringify(
          params,
        )}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data;
      }

      newState.loading = false;
      newState.success = success;
      dispatch(SmsActions.setSmsStatement(newState));
      return success && response?.data ? response.data : [];
    };
  },
  getSmsAccountData(chabadHouseID) {
    return async (dispatch) => {
      dispatch(SmsActions.setSmsAccountDataLoading, true);
      let newState = {};
      let success = true;
      const response = await AuthRequest.get(
        `ChabadHouses/${chabadHouseID}/smsAccount`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });
      if (success && response.data) {
        newState.data = response.data;
      }
      newState.loading = false;
      newState.success = success;
      dispatch(SmsActions.setSmsAccountData(newState));
      return success && response?.data ? response.data : [];
    };
  },
  updateSmsBalance(payload) {
    return {
      type: SmsActions.UPDATE_SMS_BALANCE,
      payload,
    };
  },
  setSubmitSmsCreditLoading(payload) {
    return {
      type: SmsActions.SET_SUBMIT_SMS_CREDIT_LOADING,
      payload,
    };
  },
  submitSmsCredit(payload, chabadHouseID) {
    return async (dispatch) => {
      dispatch(SmsActions.setSubmitSmsCreditLoading(true));

      try {
        const response = await AuthRequest.post(
          `ChabadHouses/${chabadHouseID}/addSmsCredit`,
          payload,
        );
        dispatch({
          type: SmsActions.SUBMIT_SMS_CREDIT,
          payload: {
            success: true,
            error: "",
            errorMessage: "",
            loading: false,
          },
        });
        dispatch(SmsActions.setSmsAccountData({ data: response?.data }));
      } catch (err) {
        dispatch({
          type: SmsActions.SUBMIT_SMS_CREDIT,
          payload: {
            success: false,
            error: err,
            errorMessage: ApiCallErrorMessageHandler(err),
            loading: false,
          },
        });
        return;
      }
    };
  },
  setSmsOutbox(payload) {
    return {
      type: SmsActions.SET_SMS_OUTBOX,
      payload,
    };
  },
  setSmsInbox(payload) {
    return {
      type: SmsActions.SET_SMS_INBOX,
      payload,
    };
  },
  setSmsScheduledMessages(payload) {
    return {
      type: SmsActions.SET_SMS_SCHEDULED_MESSAGES,
      payload,
    };
  },
  setSmsInboxLoading(payload) {
    return {
      type: SmsActions.SET_SMS_INBOX_LOADING,
      payload,
    };
  },
  setSmsOutboxLoading(payload) {
    return {
      type: SmsActions.SET_SMS_OUTBOX_LOADING,
      payload,
    };
  },
  setSmsMessageThreadLoading(payload) {
    return {
      type: SmsActions.SET_SMS_MESSAGE_THREAD_LOADING,
      payload,
    };
  },
  setSmsScheduledMessagesLoading(payload) {
    return {
      type: SmsActions.SET_SMS_SCHEDULED_MESSAGES_LOADING,
      payload,
    };
  },
  clearSmsOutbox() {
    return {
      type: SmsActions.CLEAR_SMS_OUTBOX,
    };
  },
  clearSmsInbox() {
    return {
      type: SmsActions.CLEAR_SMS_INBOX,
    };
  },
  clearSmsScheduledMessages() {
    return {
      type: SmsActions.CLEAR_SMS_SCHEDULED_MESSAGES,
    };
  },
  clearSmsMessageThread() {
    return {
      type: SmsActions.CLEAR_SMS_MESAGE_THREAD,
    };
  },
  setSmsMessageThread(payload) {
    return {
      type: SmsActions.SET_SMS_MESSAGE_THREAD,
      payload,
    };
  },
  setSmsAccountData(payload) {
    return {
      type: SmsActions.SET_SMS_ACCOUNT_DATA,
      payload,
    };
  },
  setSmsStatement(payload) {
    return {
      type: SmsActions.SET_SMS_STATEMENT,
      payload,
    };
  },
  setSmsStatementLoading(payload) {
    return {
      type: SmsActions.SET_SMS_STATEMENT_LOADING,
      payload,
    };
  },
  setSmsAccountDataLoading(payload) {
    return {
      type: SmsActions.SET_SMS_ACCOUNT_DATA_LOADING,
      payload,
    };
  },
  submitSms(payload) {
    return async (dispatch) => {
      dispatch(SmsActions.setSubmitSmsLoading(true));
      let newState = {
        loading: false,
        success: true,
        ...payload,
      };
      try {
        const response = await AuthRequest.post("sms/sendBlast", payload);
        newState.data = response.data;
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }

      dispatch(SmsActions.setSubmitSms(newState));
    };
  },
  setSubmitSms(payload) {
    return {
      type: SmsActions.SET_SUBMIT_SMS,
      payload,
    };
  },
  getPaginatedRecipients(page, resultsView = 10) {
    return async (dispatch, getState) => {
      const { sms } = getState();
      const currentPage = SmsActions.getRecipientsPage(page, sms, resultsView);
      dispatch({
        type: SmsActions.GET_PAGINATED_RECIPIENTS,
        page: currentPage,
      });
    };
  },
  getRecipientsPage(page, state, resultsView = 10) {
    const {
      singleRecipients,
      bulkRecipients: { recipients: bulkRecipients } = {},
    } = state;

    resultsView = parseInt(resultsView);
    const all = [...(singleRecipients || []), ...(bulkRecipients || [])];
    page -= 1;
    let p = page * resultsView;

    const endPage =
      p + resultsView >= all.length ? all.length : p + resultsView;
    p = all.slice(p, endPage);
    return {
      recipientPage: p,
    };
  },
  getMessageThread(chabadHouseId, threadId, personId, isOutbox, smsBlastId) {
    return async (dispatch) => {
      dispatch(SmsActions.setSmsMessageThreadLoading(true));
      const params = { chabadHouseId, personId, isOutbox, smsBlastId };
      let newState = {};
      let success = true;
      const response = await AuthRequest.get(
        `sms/thread/${threadId}?${queryString.stringify(params)}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });
      if (success && response.data) {
        newState.data = response.data;
      }
      newState.loading = false;
      newState.success = success;

      dispatch(SmsActions.setSmsMessageThread(newState));
      return { success, data: response?.data };
    };
  },
  getScheduledMessage(messageId) {
    return async (dispatch) => {
      let newState = {};
      let success = true;
      const response = await AuthRequest.get(
        `sms/messages/scheduled/${messageId}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });
      if (success && response.data) {
        newState.data = response.data;
      }
      newState.loading = false;
      newState.success = success;

      dispatch(SmsActions.setSmsMessageThread(newState));
      return { success, data: response?.data };
    };
  },
  getMailboxMessages(page, results, chabadHouseId, messageDirection, text) {
    return async (dispatch) => {
      const params = { page, results, chabadHouseId, messageDirection, text };
      const loadingFunc =
        messageDirection === 1
          ? SmsActions.setSmsOutboxLoading
          : SmsActions.setSmsInboxLoading;
      dispatch(loadingFunc(true));
      let newState = {};
      let success = true;
      const response = await AuthRequest.get(
        `sms/mailbox?${queryString.stringify(params)}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data;
      }
      newState.loading = false;
      newState.success = success;
      newState.page = page;
      if (messageDirection === 1) {
        dispatch(SmsActions.setSmsOutbox(newState));
      }
      if (messageDirection === 2) {
        dispatch(SmsActions.setSmsInbox(newState));
      }
    };
  },
  getSmsScheduledMessages(page, results, chabadHouseId, text) {
    return async (dispatch) => {
      dispatch(SmsActions.setSmsScheduledMessagesLoading(true));
      let newState = {};
      let success = true;
      const response = await AuthRequest.get(
        `sms/messages/scheduled?${queryString.stringify({
          chabadHouseId,
          page,
          results,
          text,
        })}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });
      if (success && response.data) {
        newState.data = response.data.payload;
      }
      newState.loading = false;
      newState.success = success;
      newState.page = page;
      dispatch(SmsActions.setSmsScheduledMessages(newState));
    };
  },
};
