import React from "react";

export default class AddStudentButton extends React.PureComponent {
  state = {
    showAddStudentModal: false,
  };

  render() {
    const { showAddStudentModal } = this.state;

    const {
      eventSchedule: { eventState, eventSettingState, isFullyBooked },
      registerStudentModal,
    } = this.props;

    return (
      <React.Fragment>
        <div className="tooltip-container ml-8 mobile-ml-0">
          <button
            className="btn btn-accent mr-8"
            disabled={
              isFullyBooked ||
              eventState === "Canceled" ||
              eventSettingState === "Paused"
            }
            onClick={() => this.setState({ showAddStudentModal: true })}
          >
            Add Students
          </button>
          {(isFullyBooked ||
            eventState === "Canceled" ||
            eventSettingState === "Paused") && (
            <span className="tooltip">
              Students cannot be added to a{" "}
              {isFullyBooked
                ? "fully booked"
                : eventState === "Canceled"
                ? "cancelled"
                : "paused"}{" "}
              event
            </span>
          )}
        </div>

        {React.cloneElement(registerStudentModal, {
          close: () => this.setState({ showAddStudentModal: false }),
          show: showAddStudentModal,
        })}
      </React.Fragment>
    );
  }
}
