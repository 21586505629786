import React from "react";

interface RaffleErrorPageProps {
  errorMessage?: any;
}

export default function RaffleErrorPage(props: RaffleErrorPageProps) {
  const { errorMessage } = props;

  return (
    <div className="raffle-cardfull-width">
      <div className="text-center">
        <img src="/images/error.svg" alt="error-robot" height="320" />
        <p className="text-center error-message mt-24 relative">
          {errorMessage && errorMessage.data?.messages?.length
            ? errorMessage.data.messages[0]
            : "Something went wrong. Please try again."}
        </p>
      </div>
    </div>
  );
}
