import React from "react";
import Loader from "../../../../components/Loader";
import StudentCourses from "./StudentCourses";
import StudentLatestCourse from "./StudentLatestCourse";
import StudentRewards from "./StudentRewards";
import { EduProgramTypes } from "../../../edu/eduUtils";
import { Chip } from "@material-ui/core";
import StudentStatusUpdateModal from "../../../edu/myEdu/students/list/StudentStatusUpdateModal";
export default class StudentEduEnrollment extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      approveRejectStudentAction: null,
      approveRejectStudentErrorMessage: "",
      selectedStudent: {},
      showUpdateStatusModal: false,
      // when returning to an enrollment tab from a non-enrollment tab, we need to ensure that we do not imed mount the subtabs before enrollment 'loading' starts bec we have stale enrollment details from prior props
      // t/f we set this initialized property to false and do not mount tabs until it's set true - after the refreshed enrollment details have been retrieved
      tabs: [
        { component: StudentCourses, id: "courses", title: "Course History" },
        { component: StudentRewards, id: "rewards", title: "Reward History" },
      ],
    };
  }

  approveRejectActions = {
    approve: "approve",
    reject: "reject",
  };

  componentDidMount() {
    this.getStudentEnrollmentDetails();
  }

  componentDidUpdate(prevProps) {
    // get updated student enrollment details when enrollment tab is set/changed
    if (this.props.studentEnrollmentId !== prevProps.studentEnrollmentId) {
      this.getStudentEnrollmentDetails();
    }
  }

  getStudentEnrollmentDetails = async () => {
    await this.props.getStudentEnrollment(this.props.studentEnrollmentId);

    if (!this.state.initialized) {
      this.setState({ initialized: true });
    }
  };

  getCurrentTab = () => {
    const { subTabId } = this.props;
    const { tabs } = this.state;

    if (!subTabId) return tabs[0];

    return tabs.find((t) => t.id === subTabId) || tabs[0];
  };

  toTab = (tabId) => this.props.toSubTab(tabId);

  onApproveRejectStudent = (approve, student) => {
    this.setState({
      approveRejectStudentAction: approve
        ? this.approveRejectActions.approve
        : this.approveRejectActions.reject,
      approveRejectStudentErrorMessage: "",
      showUpdateStatusModal: true,
      selectedStudent: student || this.props.selectedStudent,
    });
  };

  onCancelApproveRejectStudent = () => {
    this.setState({
      approveRejectStudentAction: null,
      approveRejectStudentErrorMessage: "",
      showUpdateStatusModal: false,
      selectedStudent: null,
    });
  };

  updateStudentEnrollmentStatus = async (values) => {
    await this.props.submitStudentEnrollmentStatus(values);

    const {
      getStudentEnrollment,
      submitStudentEnrollment: { errorMessage, success },
    } = this.props;

    if (success) {
      getStudentEnrollment(values.eduStudentEnrollmentID);
      this.setState({
        approveRejectStudentAction: null,
        selectedStudent: null,
        showUpdateStatusModal: false,
      });
    } else {
      this.setState({
        approveRejectStudentErrorMessage:
          errorMessage || "Something went wrong.  Please try again.",
      });
    }
  };

  render() {
    const {
      getStudentCourses,
      submitStudentEnrollmentStatus,
      getStudentRewards,
      isStudentsByCourseView,
      mobileView,
      studentCourses,
      studentEnrollment: {
        data: studentEnrollment = {},
        error,
        errorMessage,
        loading,
      },
      studentEnrollmentId,
      studentRewards,
      pageRoute: { params = {} },
    } = this.props;

    const {
      initialized,
      approveRejectStudentAction,
      approveRejectStudentErrorMessage,
      selectedStudent,
      showUpdateStatusModal,
      tabs,
    } = this.state;
    const tab = this.getCurrentTab();

    const { eduStudentEnrollmentID, eduProgramType, status, eduProgramName } =
      studentEnrollment;

    return (
      <div>
        {!initialized || loading ? (
          <div className="flex flex-justify-center" style={{ height: "100%" }}>
            <Loader />
          </div>
        ) : error ? (
          <div
            className="mt-24 mb-24 fw-500 medium-text text-center"
            style={{ height: "100%" }}
          >
            {/* if a 403 is returned for this request, when viewing student details from students-by-course table,
                the most likely reason is due to the student having left the campus associated with this shliach's CH, after having already enrolled in a course schedule there;
                he t/f appears in the students-by-course list but the shlaich no longer has access to view his details  */}
            {isStudentsByCourseView &&
            error.response &&
            error.response.status === 403
              ? "Student detail view unavailable for students who have left campus"
              : errorMessage ||
                "Something went wrong and we could not retrieve student details.  Please try again."}
          </div>
        ) : (
          studentEnrollmentId === eduStudentEnrollmentID && ( // ensure we don't mount until correct enrollment details are fetched for current enrollment tab
            <div>
              <div className="flex flex-justify-end full-width">
                {status === "Rejected" && (
                  <Chip
                    label={`Rejected from ${eduProgramName}`}
                    className="rejected-chip"
                  />
                )}
              </div>
              {eduProgramType !== EduProgramTypes.JewishU ? (
                <StudentLatestCourse
                  getStudentCourses={getStudentCourses}
                  studentCourses={studentCourses}
                  studentEnrollment={studentEnrollment}
                  onApproveRejectStudent={this.onApproveRejectStudent}
                />
              ) : (
                <div className="full-width">
                  <div className="student-details-tabs">
                    {tabs.map((t) => (
                      <p
                        className={`medium-text link-text-secondary ${
                          t.id === tab.id ? "active" : ""
                        }`}
                        key={t.id}
                        onClick={() => this.toTab(t.id)}
                      >
                        {t.title}
                      </p>
                    ))}
                  </div>
                  {!mobileView ? (
                    <div className="full-width">
                      {React.createElement(tab.component, {
                        studentEnrollment,
                        ...(tab.id === "courses"
                          ? {
                              getStudentCourses,
                              studentCourses,
                              onApproveRejectStudent:
                                this.onApproveRejectStudent,
                            }
                          : tab.id === "rewards"
                          ? { getStudentRewards, studentRewards }
                          : {}),
                      })}
                    </div>
                  ) : (
                    <div className="mobile-full-width">
                      {tabs.map((t) => (
                        <div className="mb-24" key={t.id}>
                          <p className="fw-700 mb-16 medium-text">{t.title}</p>
                          {React.createElement(t.component, {
                            studentEnrollment,
                            mobileView,
                            ...(t.id === "courses"
                              ? {
                                  getStudentCourses,
                                  studentCourses,
                                  onApproveRejectStudent:
                                    this.onApproveRejectStudent,
                                }
                              : t.id === "rewards"
                              ? {
                                  getStudentRewards,
                                  studentRewards,
                                }
                              : {}),
                          })}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        )}
        {/* Confirm approve/reject student */}
        {showUpdateStatusModal && (
          <StudentStatusUpdateModal
            programScheduleId={params.programScheduleId}
            loading={submitStudentEnrollmentStatus.loading}
            handleClose={this.onCancelApproveRejectStudent}
            show={showUpdateStatusModal}
            data={selectedStudent}
            handleSubmit={this.updateStudentEnrollmentStatus}
            errorMessage={approveRejectStudentErrorMessage}
            statusType={
              this.approveRejectActions[approveRejectStudentAction] ===
              this.approveRejectActions.approve
                ? "approve"
                : "reject"
            }
          />
        )}
      </div>
    );
  }
}
