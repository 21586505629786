import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SmsActions } from "../../../state/sms/actions";
import { RsvpActions } from "../../../state/rsvp/actions";
import ComposeMessage from "./ComposeMessage";

export default connect(
  function mapState(state) {
    return {
      ...state.sms,
      ...state.rsvp,
    };
  },
  (dispatch) => {
    return {
      actions: bindActionCreators({ ...SmsActions, ...RsvpActions }, dispatch),
    };
  },
)(ComposeMessage);
