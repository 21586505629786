import React from "react";
import ExportCSVButton from "../../../../../../components/ExportCSVButton";
import moment from "moment";
import {
  currencyCodes,
  formatCurrency,
  distinctObjects,
} from "../../../../../../lib";

export default function ResourcesStudentsExportButton(props) {
  const { className, disabled, resource, getStudentRegistrations } = props;

  return (
    <ExportCSVButton
      className={className}
      disabled={disabled}
      fileName={
        resource
          ? `${resource.name.replace(/\s+/g, "-")}-Resource-Registrations`
          : "RSVP-Resources-Registrations"
      }
      getExportData={getStudentRegistrations}
      getHeaders={(data) => {
        const registrationQuestions = distinctObjects(
          data.reduce(
            (acc, d) =>
              d.registrationQuestionResponses
                ? [
                    ...acc,
                    ...d.registrationQuestionResponses.map((rq) => ({
                      questionText: rq.questionText,
                      id: rq.registrationQuestionID,
                    })),
                  ]
                : [...acc],
            [],
          ),
          (q) => q.id,
        );
        return [
          ...(!resource
            ? [{ label: "Resource Name", value: "resourceName" }]
            : []),
          { label: "First Name", value: "firstName" },
          { label: "Last Name", value: "lastName" },
          { label: "Email", value: "email" },
          { label: "Phone", value: "cell" },
          {
            label: "Student Identifies as Jewish",
            value: ({ isJewish }) => (isJewish ? "Yes" : "No"),
          },
          { label: "Classification", value: "personClassificationDisplay" },
          { label: "School Student ID", value: "studentIDNumber" },
          { label: "Demographic", value: "class" },
          { label: "Grad Year", value: "graduationYear" },
          { label: "Major", value: "major" },
          { label: "Double Major", value: "doubleMajor" },
          { label: "Gender", value: "gender" },
          { label: "Address 1", value: "address1" },
          { label: "Address 2", value: "address2" },
          { label: "City", value: "city" },
          { label: "State", value: "state" },
          { label: "Country", value: "country" },
          { label: "Zip", value: "zip" },
          {
            label: "Registration Date",
            value: ({ registrationDate }) =>
              moment(registrationDate).format("MM/DD/YYYY h:mm a"),
          },
          {
            label: "Registration Source",
            value: "source",
          },
          { label: "Status", value: "statusDisplay" },
          {
            label: "Registration Fee",
            value: ({ registrationFeeAmount }) =>
              formatCurrency(registrationFeeAmount, currencyCodes.USD),
          },
          {
            label: "Donation Amount",
            value: ({ donationAmount }) =>
              formatCurrency(donationAmount, currencyCodes.USD),
          },
          { label: "Notes", value: "notes" },
          ...registrationQuestions.map((rq) => ({
            label: rq.questionText,
            value: ({ registrationQuestionResponses }) => {
              var questionResponse =
                registrationQuestionResponses &&
                registrationQuestionResponses.find(
                  (rqr) => rqr.registrationQuestionID === rq.id,
                );
              if (questionResponse) {
                return questionResponse.responseType === "YesNoToggle" &&
                  questionResponse.value
                  ? questionResponse.value === "True"
                    ? "Yes"
                    : "No"
                  : questionResponse.value;
              }
              return "";
            },
          })),
          {
            label: "Allergies and Dietary Restrictions",
            value: ({ allergiesDisplay }) =>
              allergiesDisplay ? allergiesDisplay.join(", ") : "",
          },
          {
            label:
              "Additional Dietary Information and/or Severity of Allergies",
            value: "dietaryNotes",
          },
        ];
      }}
      title="Export Registrations"
    />
  );
}
