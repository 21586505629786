import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { withAppInsights } from "../../lib";
import { RsvpActions, useAction } from "../../state";
import SmsMailbox from "./SmsMailbox";

function SMSMessagingPage() {
  const getRsvpEnrollment = useAction(RsvpActions.getRsvpEnrollment);
  const params = useParams();

  useEffect(() => {
    getRsvpEnrollment();
  }, [getRsvpEnrollment]);

  return <SmsMailbox params={params} />;
}

export default withAppInsights(SMSMessagingPage);
