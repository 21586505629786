import React from "react";

export default class CourseNotFound extends React.PureComponent {
  render() {
    const { display, logoSrc, title } = this.props;

    return (
      <div className="flex flex-align-center flex-justify-center full-page-state">
        {logoSrc && <img src={logoSrc} alt="" height="100" />}
        <p className="fw-700 xxl-text mt-32 mb-40">
          {title || "Sorry, this course no longer exists..."}
        </p>
        {display}
      </div>
    );
  }
}
