import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { AuthActions } from "../../state";
import Login from "./Login";

export default connect(
  function mapState(state) {
    return {
      //This includes login loading and success states
      ...state.login,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(AuthActions, dispatch),
    };
  },
)(Login);
