import React, { useState, useEffect, useCallback, memo } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { Navigation, PageURL } from "../../../../../lib";

function RsvpItemTabs(props) {
  const {
    pageRoute: {
      query: { tab },
      page,
      params,
    },
    tabs,
  } = props;
  const [tabValue, setTabValue] = useState(tab ?? tabs[0]?.id);

  const toTab = useCallback(
    (tabId, redirect = false) => {
      const url = PageURL.to(page, params, {
        tab: tabId,
      });
      setTabValue(tabId);
      redirect ? Navigation.redirect(url) : Navigation.go(url);
    },
    [page, params],
  );

  useEffect(() => {
    if (!tab) {
      toTab(tabs[0].id, true); //redirect nav instead of pushing new route for initial load
    } else {
      setTabValue(tab);
    }
  }, [tab, tabs, toTab]);

  return (
    <Tabs
      indicatorColor="primary"
      textColor="primary"
      value={tabValue}
      style={{
        marginBottom: 16,
        marginTop: 8,
      }}
      onChange={(e, value) => toTab(value)}
    >
      {tabs.map((t) => (
        <Tab label={t.title} value={t.id} key={t.id} />
      ))}
    </Tabs>
  );
}

export default memo(RsvpItemTabs);
