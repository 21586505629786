import React, { forwardRef } from "react";
import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormHelperText,
  styled,
} from "@material-ui/core";

export type CheckboxInputProps = {
  checked: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  label?: string | JSX.Element;
  name: string;
  onChange: (name: string, val: boolean) => void;
} & Omit<CheckboxProps, "onChange">;

const CheckboxContainerStyled = styled(Box)(({ theme }) => ({
  "&.error .MuiFormControlLabel-label": {
    color: theme.palette.error.main,
  },
  "&.error .MuiCheckbox-root svg": {
    color: theme.palette.error.main,
  },
}));

export const CheckboxInput = React.memo(
  forwardRef(
    /**
     *
     */
    function CheckboxInput(
      {
        checkedIcon,
        disabled,
        error,
        helperText,
        icon,
        id,
        label,
        name,
        onChange,
        size = "small",
        ...passProps
      }: CheckboxInputProps,
      ref: React.Ref<any> | null,
    ) {
      return (
        <CheckboxContainerStyled className={error ? "error" : ""}>
          {label ? (
            <FormControlLabel
              control={
                <Checkbox
                  id={id || name}
                  name={name}
                  onChange={(e) => onChange(name, e.target.checked)}
                  onClick={(e) => e.stopPropagation()}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  size={size}
                  {...passProps}
                />
              }
              disabled={disabled}
              label={label}
              ref={ref}
            />
          ) : (
            <Checkbox
              disabled={disabled}
              id={id || name}
              name={name}
              onChange={(e) => onChange(name, e.target.checked)}
              onClick={(e) => e.stopPropagation()}
              icon={icon}
              checkedIcon={checkedIcon}
              ref={ref}
              size={size}
              {...passProps}
            />
          )}
          {helperText && (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          )}
        </CheckboxContainerStyled>
      );
    },
  ),
);
