import { AuthActions } from "../auth/actions";
import { SysActions } from "./actions";

export const SysState = {
  name: "sys",
  persist: true,
  defaults: {
    /*
    genders: [{intValue: 1, enumValue: "Male", displayValue: "Male"}, ...],
    titles: [{ intValue, enumValue, displayValue }],
    schoolTypes: [{ intValue, enumValue, displayValue }],
    activityTypes: [{ intValue, enumValue, displayValue }],
    positions: [{ intValue, enumValue, displayValue }],
    countries: [{
      id: 34,
      name: USA,
      states: [{ id: 1, name: "Alabama", code: "AL" }, ...],
    }],
    shluchimContactMethodTypes: [{ intValue, enumValue, displayValue }],
    chabadHouseContactMethodTypes:
    chabadHouseDemographics
    timeZones: [{ id: 1, name: "Eastern" }, ...]
    regions: [{ id, name }]
  */
  },
  handlers: {
    [AuthActions.DO_SIGNOUT]: function onLogout(state, action) {
      return SysState.defaults;
    },
    [SysActions.GET_SYSTEM_DATA]: function onSysData(state, action) {
      return action.payload;
    },
  },
};
