import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { StudentsActions } from "../../state/students/actions";
import Students from "./Students";

export default connect(
  function mapState(state) {
    return {
      ...state.students,
      viewStudentsByGroup: true,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...StudentsActions,
        },
        dispatch,
      ),
    };
  },
)(Students);
