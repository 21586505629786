import React from "react";
import ActivateEnrollment from "./ActivateEnrollment";
import EnrollmentSettings from "../myEdu/settings/EnrollmentSettings";
import { EduProgramTypes } from "../eduUtils";

export default class EnrollmentForm extends React.PureComponent {
  render() {
    const {
      enrollment: {
        data: { eduProgramType },
      },
    } = this.props;

    return eduProgramType === EduProgramTypes.JewishU ||
      eduProgramType === EduProgramTypes.YourIsrael ? (
      <EnrollmentSettings {...this.props} newEnrollment={true} />
    ) : (
      <ActivateEnrollment {...this.props} />
    );
  }
}
