import { AuthRequest, ApiCallErrorMessageHandler } from "../../lib";
import moment from "moment";

export const ProfileActions = {
  SET_PROFILE_LOADING: "SET_PROFILE_LOADING",
  SET_PROFILE_CHABAD_HOUSE_LOADING: "SET_PROFILE_CHABAD_HOUSE_LOADING",
  SET_PROFILE_CAMPUS_LOADING: "SET_PROFILE_CAMPUS_LOADING",
  SUBMIT_PROFILE: "SUBMIT_PROFILE",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_AUTH_INCOMPLETE_PROFILE: "UPDATE_AUTH_INCOMPLETE_PROFILE",
  UPDATE_AUTH_PROFILE: "UPDATE_AUTH_PROFILE",

  getProfileNew(shliachId, checkProfileIncomplete) {
    //TODO: rename method once delete old code
    return async () => {
      let errorMessage = "";
      const response = await AuthRequest.get(
        `ShliachProfile/${shliachId}`,
      ).catch((e) => {
        errorMessage = ApiCallErrorMessageHandler(e);
      });
      if (errorMessage) {
        return { errorMessage };
      } else {
        return { data: response?.data?.payload };
      }
    };
  },
  submitStudentProfileNew(shliachProfile, checkProfileIncomplete) {
    //TODO: rename method once delete old code
    return async () => {
      let errorMessage = false;
      const response = await AuthRequest.post(
        `ShliachProfile/${shliachProfile.id}`,
        shliachProfile,
      ).catch((e) => {
        errorMessage = ApiCallErrorMessageHandler(e);
      });
      if (errorMessage) {
        return { errorMessage };
      } else {
        return { data: response?.data?.payload };
      }
    };
  },
  getProfile(id, checkProfileIncomplete) {
    //TODO: delete old code
    return async (dispatch) => {
      dispatch(ProfileActions.setProfileLoading(true));
      let errors = {};
      let data = {
        shliach: {},
        family: {},
        schools: [],
        outreach: {},
      };
      await Promise.all([
        AuthRequest.get(`Shluchim/${id}`)
          .then((res) => (data.shliach = res.data))
          .catch((e) => (errors.shliach = true)),
        AuthRequest.get(`Shluchim/${id}/campuses/basic`)
          .then((res) => (data.schools = res.data && res.data.payload))
          .catch((e) => (errors.schools = true)),
        AuthRequest.get(`Shluchim/${id}/chabadHouses/basic`)
          .then((res) => (data.chabadHouses = res.data && res.data.payload))
          .catch((e) => (errors.chabadHouses = true)),
        AuthRequest.get(`Shluchim/${id}/outreach`)
          .then((res) => (data.outreach = res.data && res.data.payload))
          .catch((e) => (errors.outreach = true)),
      ]);
      const { person: { familyID } = {} } = data.shliach;
      await AuthRequest.get(`Families/${familyID}`)
        .then((res) => {
          res.data.children.sort(
            (a, b) =>
              new moment(a.person.dob).format("YYYYMMDD") -
              new moment(b.person.dob).format("YYYYMMDD"),
          );
          data.family = res.data;
        })
        .catch((e) => (errors.family = true));
      const success = Object.keys(errors).length === 0;

      const newState = {
        ...data,
        loading: false,
        success,
        errors,
      };
      dispatch(ProfileActions.updateProfile(newState, checkProfileIncomplete));
    };
  },
  submitProfile(payload, studentOutreachChanges) {
    //TODO: delete old code
    return async (dispatch) => {
      const { family, outreach, ...post1 } = payload;
      let errors = {};
      let data = {
        shliach: { ...post1 },
        family: { ...family },
        outreach: { ...outreach },
      };
      const { error: err1 } = await AuthRequest.post(`Shluchim`, post1)
        .then((res) => (data.shliach = res.data.payload))
        .catch((error) => {
          dispatch({
            type: ProfileActions.SUBMIT_PROFILE,
            success: false,
            error: error,
          });
          setTimeout(() => {
            dispatch({
              type: ProfileActions.SUBMIT_PROFILE,
              success: undefined,
            });
          }, 5000);
          return { error };
        });
      if (err1) {
        return;
      }

      if (!family.familyMemberIDs) family.familyMemberIDs = null;
      if (!family.husband) family.husband = null;

      if (studentOutreachChanges) {
        await Promise.all([
          AuthRequest.post(`Families`, family)
            .then((res) => (data.family = res.data))
            .catch((error) => {
              errors.family = true;
              dispatch({
                type: ProfileActions.SUBMIT_PROFILE,
                success: false,
                error: error,
              });
              setTimeout(() => {
                dispatch({
                  type: ProfileActions.SUBMIT_PROFILE,
                  success: undefined,
                });
              }, 5000);
              return { error };
            }),
          AuthRequest.post(`Shluchim/${data.shliach.id}/outreach`, outreach)
            .then((res) => (data.outreach = res.data.payload))
            .catch((error) => {
              errors.outreach = true;
              dispatch({
                type: ProfileActions.SUBMIT_PROFILE,
                success: false,
                error: error,
              });
              setTimeout(() => {
                dispatch({
                  type: ProfileActions.SUBMIT_PROFILE,
                  success: undefined,
                });
              }, 5000);
              return { error };
            }),
        ]);
      } else {
        AuthRequest.post(`Families`, family)
          .then((res) => (data.family = res.data))
          .catch((error) => {
            errors.family = true;
            dispatch({
              type: ProfileActions.SUBMIT_PROFILE,
              success: false,
              error: error,
            });
            setTimeout(() => {
              dispatch({
                type: ProfileActions.SUBMIT_PROFILE,
                success: undefined,
              });
            }, 5000);
            return { error };
          });
      }

      if (Object.keys(errors).length === 0) {
        const newState = {
          shliach: data.shliach,
          family: data.family,
          outreach: data.outreach,
          loading: false,
          success: true,
        };
        dispatch({
          type: ProfileActions.SUBMIT_PROFILE,
          success: true,
        });
        dispatch(ProfileActions.updateProfile(newState, true));
        dispatch(ProfileActions.updateAuthProfile(newState.shliach.person.id));
        dispatch(ProfileActions.getProfile(newState.shliach.id, true));
        setTimeout(() => {
          dispatch({
            type: ProfileActions.SUBMIT_PROFILE,
            success: undefined,
          });
        }, 5000);
      }
    };
  },
  getProfileChabadHouse(id) {
    return async (dispatch) => {
      dispatch(ProfileActions.setProfileChabadHouseLoading(true));

      let success = true;
      const { data } = await AuthRequest.get(`ChabadHouses/${id}`).catch(
        (err) => {
          success = false;
          return {};
        },
      );

      const newState = {
        chabadHouseData: {
          chabadHouse: data,
          loading: false,
          success,
        },
      };

      dispatch(ProfileActions.updateProfile(newState));
    };
  },
  submitProfileChabadHouse(payload, shliachId) {
    //TODO: delete old code
    return async (dispatch) => {
      const { data, error } = await AuthRequest.post(
        `ChabadHouses`,
        payload,
      ).catch((error) => {
        setTimeout(() => {
          dispatch({
            type: ProfileActions.SUBMIT_PROFILE,
            success: undefined,
          });
        }, 5000);
        return { error };
      });
      if (error) {
        dispatch({
          type: ProfileActions.SUBMIT_PROFILE,
          success: false,
          error: error,
        });
        return;
      }
      dispatch({
        type: ProfileActions.SUBMIT_PROFILE,
        success: !error,
        error: error,
      });
      if (!error) {
        const newState = {
          chabadHouseData: {
            chabadHouse: data && data.payload,
            loading: false,
            success: true,
          },
        };
        //retrieving chabad houses list for menu to reset names and isComplete values
        await AuthRequest.get(`Shluchim/${shliachId}/chabadHouses/basic`)
          .then((res) => (newState.chabadHouses = res.data && res.data.payload))
          .catch((err) => {});
        dispatch(ProfileActions.updateProfile(newState, true));
        setTimeout(() => {
          dispatch({
            type: ProfileActions.SUBMIT_PROFILE,
            success: undefined,
          });
        }, 5000);
      }
    };
  },
  getProfileCampus(id) {
    return async (dispatch) => {
      dispatch(ProfileActions.setProfileCampusLoading(true));

      let success = true;
      const { data } = await AuthRequest.get(`Campuses/${id}`).catch((err) => {
        success = false;
        return {};
      });

      const chabadHouseID = data.chabadHouses[0].chabadHouseID;

      const { data: attributes } = await AuthRequest.get(
        `ChabadHouses/${chabadHouseID}/Attributes?CampusID=${id}`,
      ).catch((err) => {
        success = false;
        return {};
      });

      data.attributes = attributes.attributes;

      const { data: attributesUI } = await AuthRequest.get(
        `ChabadHouseAttributes`,
      ).catch((err) => {
        success = false;
        return {};
      });

      const newState = {
        campusData: {
          campus: data,
          attributes: attributesUI,
          loading: false,
          success,
        },
      };

      dispatch(ProfileActions.updateProfile(newState));
    };
  },
  submitProfileCampus(payload, shliachId) {
    return async (dispatch) => {
      const { data: data1, error: err1 } = await AuthRequest.post(
        `Campuses`,
        payload,
      ).catch((error) => {
        setTimeout(() => {
          dispatch({
            type: ProfileActions.SUBMIT_PROFILE,
            success: undefined,
          });
        }, 5000);
        return { error };
      });
      if (err1) {
        dispatch({
          type: ProfileActions.SUBMIT_PROFILE,
          success: false,
          error: err1,
        });
        return;
      }

      const chabadHouseID = payload.chabadHouses[0].chabadHouseID;

      payload.attributes.map((a) => {
        if (a.isPerCampus === true && a.value !== false) {
          a.campusID = payload.id;
        }
        return payload.attributes;
      });

      const { data: data2, error: err2 } = await AuthRequest.post(
        `ChabadHouses/${chabadHouseID}/Attributes`,
        payload.attributes,
      ).catch((error) => {
        setTimeout(() => {
          dispatch({
            type: ProfileActions.SUBMIT_PROFILE,
            success: undefined,
          });
        }, 5000);
        return { error };
      });
      dispatch({
        type: ProfileActions.SUBMIT_PROFILE,
        success: !err2,
        error: err2,
      });

      const data = data1.payload;
      data.attributes = data2.attributes;

      const { data: attributesUI } = await AuthRequest.get(
        `ChabadHouseAttributes`,
      ).catch((err) => {
        return { err };
      });

      if (!err2) {
        const newState = {
          campusData: {
            campus: data,
            attributes: attributesUI,
            loading: false,
            success: true,
          },
        };
        //retrieving campuses list for menu to reset names and isComplete values
        await AuthRequest.get(`Shluchim/${shliachId}/campuses/basic`)
          .then((res) => (newState.schools = res.data && res.data.payload))
          .catch((err) => {});
        dispatch(ProfileActions.updateProfile(newState, true));
        setTimeout(() => {
          dispatch({
            type: ProfileActions.SUBMIT_PROFILE,
            success: undefined,
          });
        }, 5000);
      }
    };
  },
  setProfileLoading(payload) {
    return {
      type: ProfileActions.SET_PROFILE_LOADING,
      payload,
    };
  },
  setProfileChabadHouseLoading(payload) {
    return {
      type: ProfileActions.SET_PROFILE_CHABAD_HOUSE_LOADING,
      payload,
    };
  },
  setProfileCampusLoading(payload) {
    return {
      type: ProfileActions.SET_PROFILE_CAMPUS_LOADING,
      payload,
    };
  },
  updateProfile(payload, checkProfileIncomplete) {
    //TODO remove old code
    return async (dispatch, getState) => {
      dispatch({
        type: ProfileActions.UPDATE_PROFILE,
        payload,
      });

      //update incompletePersonProfile info if 'checkProfileIncomplete' & if incompletePersonProfile is not already set to empty
      const updateIncompletePersonProfile =
        checkProfileIncomplete &&
        getState().auth.incompletePersonProfile !== [];
      if (updateIncompletePersonProfile) {
        const {
          chabadHouses = [],
          outreach = {},
          schools = [],
          shliach = {},
        } = getState().profile;
        const incompletePersonProfile = [
          !shliach.isComplete || !outreach.isComplete ? "Personal" : "",
          chabadHouses && chabadHouses.some((ch) => !ch.isComplete)
            ? "Chabad House"
            : "",
          schools && schools.some((s) => !s.isComplete) ? "School" : "",
        ].filter((ps) => ps);

        //this action updates auth incompletePersonProfile info to determine whether the user can access the general portal which is blocked to shluchim with incomplete profiles
        dispatch({
          type: ProfileActions.UPDATE_AUTH_INCOMPLETE_PROFILE,
          payload: incompletePersonProfile,
        });
      }
    };
  },
  //this action updates auth profile info to be displayed in the layout header
  updateAuthProfile(id) {
    return async (dispatch) => {
      let success = true;
      const { data } = await AuthRequest.get(`People/${id}/profile`).catch(
        (err) => {
          success = false;
          return {};
        },
      );
      if (success) {
        dispatch({
          type: ProfileActions.UPDATE_AUTH_PROFILE,
          payload: data.person,
        });
      }
    };
  },
};
