import React from "react";
import { useWatch, UseWatchProps } from "react-hook-form";

export type FormFieldWatchProps = {
  children: (value: any) => any;
  name: string | string[];
};

export const FormFieldWatch = React.memo(
  /**
   *
   */
  function FormFieldWatch({ children, name }: FormFieldWatchProps) {
    const value = useWatch({ name } as UseWatchProps<any>);

    return children(value);
  },
);
