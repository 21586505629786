import React from "react";

export default ({
  className,
  disabled,
  error,
  name,
  onChange,
  options,
  value,
  perRadioClassName,
}) => (
  <div
    className={`radio-button-container mb-8 ${disabled ? "disabled" : ""} ${
      error ? "error" : ""
    } ${className || ""}`}
  >
    {options.map((option) => (
      <React.Fragment key={option.value}>
        <div
          className={`radio-button-container mb-8 ${perRadioClassName || ""}`}
        >
          <div className="flex">
            <input
              type="checkbox"
              id={`${name}-${option.value}`}
              name={name}
              value={option.value}
              checked={value === option.value}
              onChange={() => onChange(name, option.value)} //to avoid default toString value
              disabled={disabled || option.disabled}
            />
            <label
              className={disabled || option.disabled ? "disabled" : ""}
              htmlFor={`${name}-${option.value}`}
              style={{ paddingLeft: "32px" }}
            >
              <span className="fw-700 mr-8">{option.displayBold}</span>
              {option.display}
            </label>
            {!!option.tag && option.tag}
          </div>
          {!!option.content && option.content}
        </div>
      </React.Fragment>
    ))}
  </div>
);
