import React from "react";
import Select from "react-select";
import { Navigation, PageURL } from "../../../../../../lib";

export default class CourseSchedulesDropdown extends React.PureComponent {
  componentDidMount() {
    this.props.getCourseSchedules();
  }

  onSelectSchedule = (schedId) => {
    const {
      pageRoute: { query, page, params },
    } = this.props;

    Navigation.go(
      PageURL.to(
        page,
        {
          ...params,
          courseScheduleId: schedId,
        },
        {
          tab: query.tab,
        },
      ),
    );
  };

  render() {
    const {
      courseSchedules: { data: courseSchedules, error, errorMessage, loading },
      pageRoute: {
        params: { courseScheduleId },
      },
    } = this.props;

    const courseScheduleOptions = courseSchedules
      ? courseSchedules.map((s) => ({
          value: s.id,
          label: s.name,
        }))
      : [];

    return (
      <div>
        <Select
          className="edu-course-schedule-select"
          classNamePrefix="edu-course-schedule-select"
          isSearchable={false}
          onChange={(val) => this.onSelectSchedule(val.value)}
          options={courseScheduleOptions}
          placeholder={loading ? "Loading Schedules..." : "Select Schedule..."}
          value={
            courseScheduleId &&
            courseScheduleOptions.find(
              (s) => s.value.toString() === courseScheduleId.toString(),
            )
          }
        />
        {!loading && error && (
          <p className="error-message" style={{ bottom: "-20px", right: 0 }}>
            {errorMessage ||
              "Something went wrong and course schedules could not be retrieved."}
          </p>
        )}
      </div>
    );
  }
}
