import React, { useCallback } from "react";
import EventScheduleStudents from "./students/EventScheduleStudents";
import EventScheduleSettings from "./settings/EventSettings";
import EventSchedulesDropdown from "./EventSchedulesDropdown";
import ManageRsvpItemContainer from "../../shared/manage/ManageRsvpItemContainer";
import { useSelector } from "react-redux";
import { AuthSelectors } from "../../../../../state";

const tabs = [
  {
    component: EventScheduleStudents,
    id: "registrations",
    title: "Registrations",
  },
  { component: EventScheduleSettings, id: "settings", title: "Settings" },
];

const getSmsFilterQueryParam = (rsvpItemId) => ({
  filterByEventId: rsvpItemId,
});

export default function ManageEventScheduleContainer(props) {
  const {
    actions: {
      getRsvpEventSchedule,
      getRsvpEventSchedulesBasic,
      clearRsvpEventSchedule,
    },
    eventSchedule,
    eventSchedulesBasic,
    createEventInteractionsForChabadHouse: createInteractionsResult,
    pageRoute,
  } = props;

  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);

  //getting full event schedule name (with formatted date) from event schedules dropdown list (per CT)
  const getEventScheduleFullName = useCallback(
    (eventSchedule) =>
      (
        (eventSchedule &&
          eventSchedulesBasic &&
          eventSchedulesBasic.data &&
          eventSchedulesBasic.data.find(
            (s) => s.id === eventSchedule.eventScheduleID,
          )) ||
        {}
      ).name,
    [eventSchedulesBasic],
  );

  return (
    <ManageRsvpItemContainer
      rsvpType="event schedule"
      tabs={tabs}
      DropdownComponent={EventSchedulesDropdown}
      getRsvpItem={getRsvpEventSchedule}
      getRsvpItemsBasic={getRsvpEventSchedulesBasic}
      clearRsvpItem={clearRsvpEventSchedule}
      createInteractionsResult={createInteractionsResult}
      rsvpItem={eventSchedule}
      rsvpItemsBasic={eventSchedulesBasic}
      getItemFullName={getEventScheduleFullName}
      getSmsFilterQueryParam={getSmsFilterQueryParam}
      pageRoute={pageRoute}
      isShliach={isShliach}
      {...props}
    />
  );
}
