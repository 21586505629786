import { AuthRequest } from "../../lib";

export const SchoolsActions = {
  SET_SCHOOLS_LOADING: "SET_SCHOOLS_LOADING",
  UPDATE_SCHOOLS: "UPDATE_SCHOOLS",

  getSchools(id) {
    return async (dispatch, getState) => {
      dispatch(SchoolsActions.setSchoolsLoading(true));

      const newState = {
        loading: false,
        schools: [],
        success: true,
      };

      try {
        const { data } = await AuthRequest.get(`Shluchim/${id}/campuses`);
        newState.schools = data;
      } catch (err) {
        newState.success = false;
      }

      dispatch(SchoolsActions.updateSchools(newState));
    };
  },
  setSchoolsLoading(payload) {
    return {
      type: SchoolsActions.SET_SCHOOLS_LOADING,
      payload,
    };
  },
  updateSchools(payload) {
    return {
      type: SchoolsActions.UPDATE_SCHOOLS,
      payload,
    };
  },
};
