import React, { useState } from "react";
import Checkbox from "../../../../components/form/Checkbox";
import Select from "../../../../components/form/CustomSelect";
import Toggle from "../../../../components/form/Toggle";
import ValidatedInput from "../../../../components/form/ValidatedInput";
import ConfirmationModal from "../../../../components/ConfirmationModal";

const JewishEducationInfo = (props) => {
  const {
    hebrewLevels,
    getProfileFieldLabel,
    isProfileFieldRequired,
    jewishAffiliationTypes,
    jewishEducationTypes,
    onChangeProfile,
    onChangeProfileEvt,
    profile: {
      hebrewLevel,
      jewishEducations,
      hasNoJewishEducation,
      hadBarBatMitzvah,
    } = {},
    showFormValidation,
  } = props;

  const [
    showNoJewishEducationConfirmationModal,
    setShowNoJewishEducationConfirmationModal,
  ] = useState(false);

  return (
    <div className="profile-form-section">
      <p className="profile-section-title">Jewish educational background</p>
      <div>
        {jewishEducationTypes &&
          jewishEducationTypes.map((educationType, index) => {
            let studentJewishEducationIndex;
            let studentJewishEducation;
            if (jewishEducations) {
              studentJewishEducationIndex = jewishEducations.findIndex(
                (je) => je.type === educationType.enumValue,
              );
              studentJewishEducation =
                jewishEducations[studentJewishEducationIndex];
            }
            return (
              <div key={index} className="medium-text mb-24">
                <Checkbox
                  checked={!!studentJewishEducation}
                  label={educationType.displayValue}
                  name={educationType.enumValue}
                  onChange={(jewishEducationType, value) => {
                    onChangeProfile("hasNoJewishEducation", false);
                    onChangeProfile(
                      "jewishEducations",
                      value
                        ? [
                            ...jewishEducations,
                            {
                              affiliation: "",
                              name: "",
                              type: jewishEducationType,
                            },
                          ]
                        : jewishEducations.filter(
                            (je) => je.type !== jewishEducationType,
                          ),
                    );
                  }}
                />
                {studentJewishEducation && (
                  <div className="profile-form-grid mt-16">
                    <ValidatedInput
                      className="input-container"
                      label="Name"
                      name={`jewishEducations[${studentJewishEducationIndex}].name`}
                      onChange={onChangeProfileEvt}
                      required={true}
                      showRequired={showFormValidation}
                      value={studentJewishEducation.name}
                    />
                    <ValidatedInput
                      className="input-container"
                      input={
                        <Select
                          classNamePrefix="input-container-select"
                          isClearable={true}
                          options={jewishAffiliationTypes.map((ja) => ({
                            label: ja.displayValue,
                            value: ja.enumValue,
                          }))}
                        />
                      }
                      label="Affiliation"
                      name={`jewishEducations[${studentJewishEducationIndex}].affiliation`}
                      onChange={onChangeProfile}
                      required={true}
                      showRequired={showFormValidation}
                      value={studentJewishEducation.affiliation}
                    />
                  </div>
                )}
              </div>
            );
          })}
        <div className="medium-text mb-24">
          <Checkbox
            checked={hasNoJewishEducation}
            label="None"
            name="hasNoJewishEducation"
            onChange={(name, value) => {
              if (value && jewishEducations.length) {
                setShowNoJewishEducationConfirmationModal(true);
              } else {
                onChangeProfile(name, value);
              }
            }}
          />
        </div>
        <div className="profile-form-grid">
          <ValidatedInput
            className="input-container"
            input={
              <Select
                classNamePrefix="input-container-select"
                isClearable={true}
                options={hebrewLevels.map((hl) => ({
                  label: hl.displayValue,
                  value: hl.enumValue,
                }))}
              />
            }
            label={getProfileFieldLabel("Hebrew language", "hebrewLevel")}
            name="hebrewLevel"
            onChange={onChangeProfile}
            required={isProfileFieldRequired("hebrewLevel")}
            showRequired={showFormValidation}
            value={hebrewLevel}
          />
          <ValidatedInput
            input={
              <Toggle
                className="toggle-container"
                allowNoOption={true}
                options={[
                  {
                    value: true,
                    display: "Yes",
                  },
                  {
                    value: false,
                    display: "No",
                  },
                ]}
              />
            }
            label={getProfileFieldLabel(
              "Had Bar/Bat Mitzvah",
              "hadBarBatMitzvah",
            )}
            labelClassName="uppercase-text xs-text accent-text mb-4 block"
            name="hadBarBatMitzvah"
            onChange={onChangeProfile}
            required={isProfileFieldRequired("hadBarBatMitzvah")}
            showRequired={
              showFormValidation &&
              hadBarBatMitzvah !== true &&
              hadBarBatMitzvah !== false
            }
            value={hadBarBatMitzvah}
          />
        </div>
      </div>
      {showNoJewishEducationConfirmationModal && (
        <ConfirmationModal
          cancel={() => setShowNoJewishEducationConfirmationModal(false)}
          confirm={() => {
            //clear out Jewish educations list
            onChangeProfile("jewishEducations", []);
            onChangeProfile("hasNoJewishEducation", true);
            setShowNoJewishEducationConfirmationModal(false);
          }}
          message="Selecting 'None' will clear out all Jewish educational background information. Are you sure you want to make this change?"
          show={true}
          title="Confirm Jewish Education Change"
        />
      )}
    </div>
  );
};
export default React.memo(JewishEducationInfo);
