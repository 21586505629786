import React from "react";
import Loader from "../../../components/Loader";
import StudentInteractionModal from "../../students/activity/StudentInteractionModal.vm";
import { formatNumber } from "../../../lib";
import moment from "moment";

export default class LamplightersScheduleDashboard extends React.PureComponent {
  state = {
    showStudentInteractionModal: false,
  };

  componentDidMount() {
    this.getDataPercentage();
  }

  getDataPercentage = async () => {
    const {
      actions: { getLamplightersDataPercentage },
      schedule: {
        data: { id: scheduleId },
      },
    } = this.props;
    await getLamplightersDataPercentage(scheduleId);
  };

  render() {
    const {
      dataPercentage: {
        data: dataPercentage,
        errorMessage: dataPercentageError,
        loading: dataPercentageLoading,
      },
      enrollment: { data: enrollment = {}, refreshing },
      getGrantName,
      schedule: {
        data: { deadlines, programScheduleName, programStartDate },
      },
    } = this.props;

    const { showStudentInteractionModal } = this.state;

    const {
      allocations,
      amountAllocated,
      amountPaid,
      amountSubmitted,
      submittedPercentage,
    } = enrollment;

    const timelineDates = [
      {
        date: programStartDate,
        name: "Program Begins\nBegin Tracking Data",
      },
      ...deadlines,
    ];

    return refreshing ? (
      <Loader />
    ) : (
      <div>
        <div className="page-title flex flex-align-center flex-justify-space mobile-flex-justify-end">
          <div className="mobile-hidden archivo-black">
            {programScheduleName || "Lamplighters"}
          </div>
          <button
            className="btn btn-accent flex flex-align-center lamplighters-log-interaction-btn"
            onClick={() => this.setState({ showStudentInteractionModal: true })}
          >
            Log Interaction
          </button>
        </div>
        <div className="flex flex-align-center flex-justify-space mb-40">
          <div className="flex full-width">
            {timelineDates &&
              timelineDates.length > 1 && //only show timeline if there are deadline dates
              timelineDates
                .sort((d1, d2) =>
                  moment(d1.date).isBefore(moment(d2.date)) ? -1 : 1,
                )
                .map((deadline, index) => (
                  <div
                    className={`small-text accent-text text-center lamplighters-deadline-point full-width relative montserrat ${
                      moment(deadline.date).isSameOrBefore(moment())
                        ? "completed"
                        : ""
                    } ${
                      moment().isBetween(
                        moment(deadline.date),
                        moment(
                          timelineDates[index + 1] &&
                            timelineDates[index + 1].date,
                        ),
                      )
                        ? "final-completed"
                        : ""
                    }`}
                    key={index}
                  >
                    <span className="lamplighters-deadline-point-circle" />
                    {moment(deadline.date).format("MMM D")}
                    <br />
                    <span className="pre-wrap">{deadline.name}</span>
                  </div>
                ))}
          </div>
        </div>
        {allocations && (
          <div className="flex flex-justify-space mobile-block">
            <div className="full-width mr-24">
              <div className="flex mobile-block flex-column">
                {allocations &&
                  allocations.map((allocation, index) => (
                    <div
                      className="lamplighters-allocation-card mobile-ml-0 mobile-mt-8 mb-16"
                      key={index}
                    >
                      <div className="mb-16 montserrat fw-600 flex flex-align-center allocation-total-mobile">
                        {getGrantName(
                          allocation.grantTypeDisplay,
                          allocation.grantDescription,
                        )}
                        <div className="small-text accent-text ml-16 mt-2 fw-300">
                          {formatNumber(allocation.quantity)} Total
                        </div>
                      </div>
                      <div className="allocation-wrapper">
                        <div className="allocation-grouping">
                          <div className="allocation-section">
                            <div className="flex flex-align-center">
                              <div className="allocation-quantity active-grant-text">
                                {formatNumber(allocation.activeQuantity)}
                              </div>
                              <span className="montserrat small-text flex flex-align-center">
                                Active
                                <span className="tooltip-container">
                                  <i className="material-icons medium-text accent-text ml-4">
                                    info
                                  </i>
                                  <span className="tooltip">
                                    Submitted grants are not included in active
                                    total
                                  </span>
                                </span>
                              </span>
                            </div>
                            {allocation.activeFlaggedQuantity > 0 && (
                              <div className="fw300 flex flex-align-center italic-text">
                                (
                                <span className="flagged-grant-text allocation-quantity mobile-flagged-quantity">
                                  {formatNumber(
                                    allocation.activeFlaggedQuantity,
                                  )}
                                </span>
                                flagged)
                              </div>
                            )}
                          </div>
                          <i className="material-icons circle-icon mr-4 ml-4">
                            circle
                          </i>
                          <div className="allocation-section">
                            <div className="flex flex-align-center">
                              <div className="allocation-quantity submitted-grant-text">
                                {formatNumber(allocation.submittedQuantity)}
                              </div>
                              <span className="montserrat small-text">
                                Submitted
                              </span>
                            </div>
                            {allocation.submittedFlaggedQuantity > 0 && (
                              <div className="fw300 flex flex-align-center italic-text">
                                (
                                <span className="flagged-grant-text allocation-quantity mobile-flagged-quantity">
                                  {formatNumber(
                                    allocation.submittedFlaggedQuantity,
                                  )}
                                </span>
                                flagged)
                              </div>
                            )}
                          </div>
                          <i className="material-icons circle-icon mr-4 ml-4">
                            circle
                          </i>
                          <div className="flex flex-align-center">
                            <div className="allocation-quantity total-text">
                              {formatNumber(allocation.availableQuantity)}
                            </div>
                            <span className="montserrat small-text">
                              Available
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <div className="lamplighters-payments-card">
                <p className="fw-600 mb-24 montserrat">My Payments</p>
                <div className="mb-24">
                  <p className="archivo-black mb-8" style={{ fontSize: 34 }}>
                    ${formatNumber(amountAllocated)}
                  </p>
                  <p className="montserrat">Total allocations</p>
                </div>
                <div className="mb-24">
                  <p className="archivo-black mb-8" style={{ fontSize: 34 }}>
                    ${formatNumber(amountSubmitted)}
                  </p>
                  <p className="montserrat">Grants submitted</p>
                </div>
                <div>
                  <p className="archivo-black mb-8" style={{ fontSize: 34 }}>
                    ${formatNumber(amountPaid)}
                  </p>
                  <p className="montserrat">Paid</p>
                </div>
              </div>
              <div className="montserrat line-height-double">
                {dataPercentageLoading ? (
                  <span className="accent-text">
                    Loading progress percentage...
                  </span>
                ) : dataPercentageError ? (
                  <span className="error-text">
                    Something went wrong and progress percentage could not be
                    retrieved
                  </span>
                ) : (
                  <div className="lamplighters-goal-card montserrat fw-600">
                    You are {dataPercentage || 0}% of the way towards your
                    Recording Goal
                  </div>
                )}
                <div className="lamplighters-goal-card montserrat fw-600">
                  {submittedPercentage || 0}% of your grants have been submitted
                </div>
              </div>
            </div>
          </div>
        )}

        <StudentInteractionModal
          close={() => this.setState({ showStudentInteractionModal: false })}
          onSubmitInteraction={this.getDataPercentage} //reload data percentage upon interaction log to get updated data progress
          show={showStudentInteractionModal}
        />
      </div>
    );
  }
}
