import { AuthRequest, ApiCallErrorMessageHandler } from "../../lib";
import queryString from "query-string";

export const EduActions = {
  SET_EDU_PROGRAMS_LOADING: "SET_EDU_PROGRAMS_LOADING",
  SET_EDU_PROGRAMS: "SET_EDU_PROGRAMS",

  SET_EDU_ENROLLED_PROGRAMS_LOADING: "SET_EDU_ENROLLED_PROGRAMS_LOADING",
  SET_EDU_ENROLLED_PROGRAMS: "SET_EDU_ENROLLED_PROGRAMS",

  SET_EDU_ENROLLMENT_LOADING: "SET_EDU_ENROLLMENT_LOADING",
  SET_EDU_ENROLLMENT: "SET_EDU_ENROLLMENT",

  SET_SUBMIT_EDU_ENROLLMENT_LOADING: "SET_SUBMIT_EDU_ENROLLMENT_LOADING",
  SET_SUBMIT_EDU_ENROLLMENT: "SET_SUBMIT_EDU_ENROLLMENT",

  SET_EDU_COURSES_LOADING: "SET_EDU_COURSES_LOADING",
  SET_EDU_COURSES: "SET_EDU_COURSES",

  SET_EDU_COURSE_LOADING: "SET_EDU_COURSE_LOADING",
  SET_EDU_COURSE: "SET_EDU_COURSE",
  CLEAR_EDU_COURSE: "CLEAR_EDU_COURSE",

  SET_EDU_COURSE_SCHEDULES_LOADING: "SET_EDU_COURSE_SCHEDULES_LOADING",
  SET_EDU_COURSE_SCHEDULES: "SET_EDU_COURSE_SCHEDULES",

  SET_EDU_COURSE_SCHEDULE_LOADING: "SET_EDU_COURSE_SCHEDULE_LOADING",
  SET_EDU_COURSE_SCHEDULE: "SET_EDU_COURSE_SCHEDULE",
  CLEAR_EDU_COURSE_SCHEDULE: "CLEAR_EDU_COURSE_SCHEDULE",

  SET_SUBMIT_EDU_COURSE_SCHEDULE_LOADING:
    "SET_SUBMIT_EDU_COURSE_SCHEDULE_LOADING",
  SET_SUBMIT_EDU_COURSE_SCHEDULE: "SET_SUBMIT_EDU_COURSE_SCHEDULE",

  SET_EDU_COURSE_SCHEDULE_ATTENDANCE_LOADING:
    "SET_EDU_COURSE_SCHEDULE_ATTENDANCE_LOADING",
  SET_EDU_COURSE_SCHEDULE_ATTENDANCE: "SET_EDU_COURSE_SCHEDULE_ATTENDANCE",

  SET_SUBMIT_EDU_COURSE_SCHEDULE_ATTENDANCE_LOADING:
    "SET_SUBMIT_EDU_COURSE_SCHEDULE_ATTENDANCE_LOADING",
  SET_SUBMIT_EDU_COURSE_SCHEDULE_ATTENDANCE:
    "SET_SUBMIT_EDU_COURSE_SCHEDULE_ATTENDANCE",

  SET_EDU_STUDENTS_LOADING: "SET_EDU_STUDENTS_LOADING",
  SET_EDU_STUDENTS: "SET_EDU_STUDENTS",

  SET_EDU_STUDENT_ENROLLMENTS_LOADING: "SET_EDU_STUDENT_ENROLLMENTS_LOADING",
  SET_EDU_STUDENT_ENROLLMENTS: "SET_EDU_STUDENT_ENROLLMENTS",

  SET_EDU_STUDENT_ENROLLMENT_LOADING: "SET_EDU_STUDENT_ENROLLMENT_LOADING",
  SET_EDU_STUDENT_ENROLLMENT: "SET_EDU_STUDENT_ENROLLMENT",

  SET_EDU_STUDENT_ENROLLMENT_STATUSES_LOADING:
    "SET_EDU_STUDENT_ENROLLMENT_STATUSES_LOADING",
  SET_EDU_STUDENT_ENROLLMENT_STATUSES: "SET_EDU_STUDENT_ENROLLMENT_STATUSES",

  SET_SUBMIT_EDU_STUDENT_ENROLLMENT_LOADING:
    "SET_SUBMIT_EDU_STUDENT_ENROLLMENT_LOADING",
  SET_SUBMIT_EDU_STUDENT_ENROLLMENT: "SET_SUBMIT_EDU_STUDENT_ENROLLMENT",

  SET_EDU_STUDENT_COURSES_LOADING: "SET_EDU_STUDENT_COURSES_LOADING",
  SET_EDU_STUDENT_COURSES: "SET_EDU_STUDENT_COURSES",

  SET_EDU_STUDENT_REWARDS_LOADING: "SET_EDU_STUDENT_REWARDS_LOADING",
  SET_EDU_STUDENT_REWARDS: "SET_EDU_STUDENT_REWARDS",

  getEduPrograms() {
    return async (dispatch) => {
      dispatch(EduActions.setEduProgramsLoading(true));

      let newState = {};
      let success = true;

      const response = await AuthRequest.get("EduPrograms").catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setEduPrograms(newState));
    };
  },
  setEduProgramsLoading(payload) {
    return {
      type: EduActions.SET_EDU_PROGRAMS_LOADING,
      payload,
    };
  },
  setEduPrograms(payload) {
    return {
      type: EduActions.SET_EDU_PROGRAMS,
      payload,
    };
  },

  getEduEnrolledPrograms() {
    return async (dispatch) => {
      dispatch(EduActions.setEduEnrolledProgramsLoading(true));

      let newState = {};
      let success = true;

      const response = await AuthRequest.get(
        "EduPrograms/forChabadHouse",
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setEduEnrolledPrograms(newState));
    };
  },
  setEduEnrolledProgramsLoading(payload) {
    return {
      type: EduActions.SET_EDU_ENROLLED_PROGRAMS_LOADING,
      payload,
    };
  },
  setEduEnrolledPrograms(payload) {
    return {
      type: EduActions.SET_EDU_ENROLLED_PROGRAMS,
      payload,
    };
  },

  getEduEnrollment(eduProgramScheduleId) {
    return async (dispatch) => {
      dispatch(EduActions.setEduEnrollmentLoading(true));

      let newState = {};
      let success = true;

      const response = await AuthRequest.get(
        `EduChabadHouses/byProgramSchedule?programScheduleId=${eduProgramScheduleId}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setEduEnrollment(newState));
    };
  },
  setEduEnrollmentLoading(payload) {
    return {
      type: EduActions.SET_EDU_ENROLLMENT_LOADING,
      payload,
    };
  },
  setEduEnrollment(payload) {
    return {
      type: EduActions.SET_EDU_ENROLLMENT,
      payload,
    };
  },

  submitEduEnrollment(enrollment) {
    return async (dispatch) => {
      dispatch(EduActions.setSubmitEduEnrollmentLoading(true));

      let newState = {};
      let success = true;

      const response = await AuthRequest.post(
        "EduChabadHouses",
        enrollment,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setSubmitEduEnrollment(newState));

      if (success) {
        newState.data = response.data.payload;
        dispatch(EduActions.setEduEnrollment(newState));
      }
    };
  },
  setSubmitEduEnrollmentLoading(payload) {
    return {
      type: EduActions.SET_SUBMIT_EDU_ENROLLMENT_LOADING,
      payload,
    };
  },
  setSubmitEduEnrollment(payload) {
    return {
      type: EduActions.SET_SUBMIT_EDU_ENROLLMENT,
      payload,
    };
  },

  getEduCourses(page, results, filters, myCourses) {
    return async (dispatch, getState) => {
      dispatch(EduActions.setEduCoursesLoading(true));

      let newState = {};
      let success = true;

      const { category, level, search, sortBy: sortByOption } = filters || {};
      const params = {
        category,
        level,
        sortByOption,
        page,
        results,
        text: search,
      };
      if (myCourses) {
        params.enrolledCoursesOnly = true;
        params.limitToLatestCourseSchedule = false;
      }

      const { id: eduEnrollmentId } = getState().edu.enrollment.data || {};
      const response = await AuthRequest.get(
        `EduChabadHouses/${eduEnrollmentId}/Courses?${queryString.stringify(
          params,
        )}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setEduCourses(newState));
    };
  },
  setEduCoursesLoading(payload) {
    return {
      type: EduActions.SET_EDU_COURSES_LOADING,
      payload,
    };
  },
  setEduCourses(payload) {
    return {
      type: EduActions.SET_EDU_COURSES,
      payload,
    };
  },

  getEduCourse(courseId, hideLoadingState = false) {
    return async (dispatch, getState) => {
      if (!hideLoadingState) {
        dispatch(EduActions.setEduCourseLoading(true));
      }

      let newState = {};
      let success = true;

      const { eduProgramID } = getState().edu.enrollment.data || {};
      const response = await AuthRequest.get(
        `EduPrograms/${eduProgramID}/Courses/${courseId}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setEduCourse(newState));
    };
  },
  setEduCourseLoading(payload) {
    return {
      type: EduActions.SET_EDU_COURSE_LOADING,
      payload,
    };
  },
  setEduCourse(payload) {
    return {
      type: EduActions.SET_EDU_COURSE,
      payload,
    };
  },
  clearEduCourse() {
    return {
      type: EduActions.CLEAR_EDU_COURSE,
    };
  },

  getEduCourseSchedules() {
    return async (dispatch, getState) => {
      dispatch(EduActions.setEduCourseSchedulesLoading(true));

      let newState = {};
      let success = true;

      const { id: eduEnrollmentId } = getState().edu.enrollment.data || {};
      const response = await AuthRequest.get(
        `EduChabadHouses/${eduEnrollmentId}/CourseSchedules`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setEduCourseSchedules(newState));
    };
  },
  setEduCourseSchedulesLoading(payload) {
    return {
      type: EduActions.SET_EDU_COURSE_SCHEDULES_LOADING,
      payload,
    };
  },
  setEduCourseSchedules(payload) {
    return {
      type: EduActions.SET_EDU_COURSE_SCHEDULES,
      payload,
    };
  },

  getEduCourseSchedule(scheduleId, shouldGetCourse = null) {
    return async (dispatch, getState) => {
      dispatch(EduActions.setEduCourseScheduleLoading(true));

      let newState = {};
      let success = true;

      const { id: eduEnrollmentId } = getState().edu.enrollment.data || {};
      const response = await AuthRequest.get(
        `EduChabadHouses/${eduEnrollmentId}/CourseSchedules/${scheduleId}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;

        if (shouldGetCourse && shouldGetCourse(newState.data.eduCourseID)) {
          //check if should get course details for schedule
          await dispatch(
            EduActions.getEduCourse(newState.data.eduCourseID, true),
          );
        }
      }

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setEduCourseSchedule(newState));
    };
  },
  setEduCourseScheduleLoading(payload) {
    return {
      type: EduActions.SET_EDU_COURSE_SCHEDULE_LOADING,
      payload,
    };
  },
  setEduCourseSchedule(payload) {
    return {
      type: EduActions.SET_EDU_COURSE_SCHEDULE,
      payload,
    };
  },
  clearEduCourseSchedule() {
    return {
      type: EduActions.CLEAR_EDU_COURSE_SCHEDULE,
    };
  },

  submitEduCourseSchedule(courseSchedule) {
    return async (dispatch, getState) => {
      dispatch(EduActions.setSubmitEduCourseScheduleLoading(true));

      let newState = {};
      let success = true;

      const { id: eduEnrollmentId } = getState().edu.enrollment.data || {};
      const response = await AuthRequest.post(
        `EduChabadHouses/${eduEnrollmentId}/courseSchedules`,
        courseSchedule,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setSubmitEduCourseSchedule(newState));

      if (success) {
        newState.data = response.data.payload;
        dispatch(EduActions.setEduCourseSchedule(newState));
      }
    };
  },
  setSubmitEduCourseScheduleLoading(payload) {
    return {
      type: EduActions.SET_SUBMIT_EDU_COURSE_SCHEDULE_LOADING,
      payload,
    };
  },
  setSubmitEduCourseSchedule(payload) {
    return {
      type: EduActions.SET_SUBMIT_EDU_COURSE_SCHEDULE,
      payload,
    };
  },

  getEduCourseScheduleAttendance(courseScheduleId) {
    return async (dispatch, getState) => {
      dispatch(EduActions.setEduCourseScheduleAttendanceLoading(true));

      let newState = {};
      let success = true;

      const { id: eduEnrollmentId } = getState().edu.enrollment.data || {};

      const response = await AuthRequest.get(
        `EduChabadHouses/${eduEnrollmentId}/CourseSchedules/${courseScheduleId}/Attendance`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setEduCourseScheduleAttendance(newState));
    };
  },
  setEduCourseScheduleAttendanceLoading(payload) {
    return {
      type: EduActions.SET_EDU_COURSE_SCHEDULE_ATTENDANCE_LOADING,
      payload,
    };
  },
  setEduCourseScheduleAttendance(payload) {
    return {
      type: EduActions.SET_EDU_COURSE_SCHEDULE_ATTENDANCE,
      payload,
    };
  },

  submitEduCourseScheduleAttendance(
    courseScheduleId,
    courseScheduleAttendance,
  ) {
    return async (dispatch, getState) => {
      dispatch(EduActions.setSubmitEduCourseScheduleAttendanceLoading(true));

      let newState = {};
      let success = true;

      const { id: eduEnrollmentId } = getState().edu.enrollment.data || {};
      const response = await AuthRequest.post(
        `EduChabadHouses/${eduEnrollmentId}/CourseSchedules/${courseScheduleId}/Attendance`,
        courseScheduleAttendance,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setSubmitEduCourseScheduleAttendance(newState));

      if (success) {
        newState.data = response.data.payload;
        dispatch(EduActions.setEduCourseScheduleAttendance(newState));
      }
    };
  },
  setSubmitEduCourseScheduleAttendanceLoading(payload) {
    return {
      type: EduActions.SET_SUBMIT_EDU_COURSE_SCHEDULE_ATTENDANCE_LOADING,
      payload,
    };
  },
  setSubmitEduCourseScheduleAttendance(payload) {
    return {
      type: EduActions.SET_SUBMIT_EDU_COURSE_SCHEDULE_ATTENDANCE,
      payload,
    };
  },

  getEduStudents(page, results, filters, isCourse = false) {
    return async (dispatch, getState) => {
      dispatch(EduActions.setEduStudentsLoading(true));

      let newState = {};
      let success = true;

      const { schedule, status, search } = filters || {};

      const params = {
        page,
        results,
        courseScheduleId: schedule,
        text: search,
      };

      isCourse
        ? (params.courseScheduleEnrollmentStatuses = status)
        : (params.studentEnrollmentStatus = status);

      if (!page && !results) {
        params.noPage = true;
      }

      const { id: eduEnrollmentId } = getState().edu.enrollment.data || {};
      const response = await AuthRequest.get(
        `EduChabadHouses/${eduEnrollmentId}/Students?${queryString.stringify(
          params,
        )}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setEduStudents(newState));
    };
  },
  setEduStudentsLoading(payload) {
    return {
      type: EduActions.SET_EDU_STUDENTS_LOADING,
      payload,
    };
  },
  setEduStudents(payload) {
    return {
      type: EduActions.SET_EDU_STUDENTS,
      payload,
    };
  },

  getEduStudentEnrollments(studentId) {
    return async (dispatch) => {
      dispatch(EduActions.setEduStudentEnrollmentsLoading(true));

      let newState = {};
      let success = true;

      const response = await AuthRequest.get(
        `EduStudents/enrollments/${studentId}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setEduStudentEnrollments(newState));
    };
  },
  setEduStudentEnrollmentsLoading(payload) {
    return {
      type: EduActions.SET_EDU_STUDENT_ENROLLMENTS_LOADING,
      payload,
    };
  },
  setEduStudentEnrollments(payload) {
    return {
      type: EduActions.SET_EDU_STUDENT_ENROLLMENTS,
      payload,
    };
  },

  getEduStudentEnrollment(studentEnrollmentId) {
    return async (dispatch) => {
      dispatch(EduActions.setEduStudentEnrollmentLoading(true));

      let newState = {};
      let success = true;

      const response = await AuthRequest.get(
        `EduStudents/${studentEnrollmentId}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setEduStudentEnrollment(newState));
    };
  },
  setEduStudentEnrollmentLoading(payload) {
    return {
      type: EduActions.SET_EDU_STUDENT_ENROLLMENT_LOADING,
      payload,
    };
  },
  setEduStudentEnrollment(payload) {
    return {
      type: EduActions.SET_EDU_STUDENT_ENROLLMENT,
      payload,
    };
  },
  submitStudentEnrollmentStatus(values) {
    return async (dispatch) => {
      dispatch(EduActions.setSubmitEduStudentEnrollmentLoading(true));

      let newState = {};
      let success = true;

      await AuthRequest.post("EduStudents/courseSchedules", values).catch(
        (err) => {
          success = false;
          newState = {
            error: err,
            errorMessage: ApiCallErrorMessageHandler(err),
          };
        },
      );

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setSubmitEduStudentEnrollment(newState));
    };
  },
  setSubmitEduStudentEnrollmentLoading(payload) {
    return {
      type: EduActions.SET_SUBMIT_EDU_STUDENT_ENROLLMENT_LOADING,
      payload,
    };
  },
  setSubmitEduStudentEnrollment(payload) {
    return {
      type: EduActions.SET_SUBMIT_EDU_STUDENT_ENROLLMENT,
      payload,
    };
  },

  getEduStudentEnrollmentStatuses(
    enrollmentCourseScheduleID,
    accountID,
    token,
  ) {
    return async (dispatch) => {
      dispatch(EduActions.setEduStudentEnrollmentStatusesLoading(true));

      let newState = {};
      let success = true;

      const params = {
        enrollmentCourseScheduleID,
        accountID,
        token,
      };
      const response = await AuthRequest.get(
        `EduStudents/status?${queryString.stringify(params)}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setEduStudentEnrollmentStatuses(newState));
    };
  },

  setEduStudentEnrollmentStatusesLoading(payload) {
    return {
      type: EduActions.SET_EDU_STUDENT_ENROLLMENT_STATUSES_LOADING,
      payload,
    };
  },
  setEduStudentEnrollmentStatuses(payload) {
    return {
      type: EduActions.SET_EDU_STUDENT_ENROLLMENT_STATUSES,
      payload,
    };
  },
  getEduStudentCourses(studentEnrollmentId, page, results) {
    return async (dispatch) => {
      dispatch(EduActions.setEduStudentCoursesLoading(true));

      let newState = {};
      let success = true;

      const params = {
        eduStudentEnrollmentID: studentEnrollmentId,
        enrolledCoursesOnly: true,
        page,
        results,
      };

      const response = await AuthRequest.get(
        `EduStudents/Courses?${queryString.stringify(params)}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setEduStudentCourses(newState));
    };
  },
  setEduStudentCoursesLoading(payload) {
    return {
      type: EduActions.SET_EDU_STUDENT_COURSES_LOADING,
      payload,
    };
  },
  setEduStudentCourses(payload) {
    return {
      type: EduActions.SET_EDU_STUDENT_COURSES,
      payload,
    };
  },

  getEduStudentRewards(studentEnrollmentId, page, results) {
    return async (dispatch, getState) => {
      dispatch(EduActions.setEduStudentRewardsLoading(true));

      let newState = {};
      let success = true;

      const { eduProgramID } = getState().edu.studentEnrollment.data || {};
      const params = {
        eduStudentEnrollmentId: studentEnrollmentId,
        page,
        results,
      };

      const response = await AuthRequest.get(
        `EduPrograms/${eduProgramID}/RewardRequests?${queryString.stringify(
          params,
        )}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(EduActions.setEduStudentRewards(newState));
    };
  },
  setEduStudentRewardsLoading(payload) {
    return {
      type: EduActions.SET_EDU_STUDENT_REWARDS_LOADING,
      payload,
    };
  },
  setEduStudentRewards(payload) {
    return {
      type: EduActions.SET_EDU_STUDENT_REWARDS,
      payload,
    };
  },
};
