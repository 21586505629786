import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { SysActions } from "../../state";

import Dropzone from "react-dropzone";
import ImageCropperModal from "../ImageCropperModal";
import Loader from "../Loader";
import { PROFILE_PLACEHOLDER_URL } from "../../lib";

class ImageWithCropUpload extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      droppedImage: null,
      loading: false,
      showCropperModal: false,
      showError: false,
    };

    this.dropzoneRef = React.createRef();
  }

  onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const fileWithPreview = Object.assign(acceptedFiles[0], {
        previewURL: URL.createObjectURL(acceptedFiles[0]),
      });
      this.setState({ droppedImage: fileWithPreview, showCropperModal: true });
    }
  };

  onDropRejected = () => {
    this.setState({ showError: true });
  };

  onFinishCrop = async (croppedImgData) => {
    const {
      actions: { uploadFile },
      imageType,
      name,
      onChange,
    } = this.props;
    const { droppedImage } = this.state;

    this.setState({ loading: true });

    const file = croppedImgData
      ? new File([croppedImgData], droppedImage.name, { type: "image/png" })
      : droppedImage;

    const fileURL = await uploadFile(file, imageType);
    onChange(name, fileURL);

    this.setState({ loading: false, showCropperModal: false });
  };
  onCancelCrop = () => {
    this.setState({ showCropperModal: false });
  };

  imageError = (touched, errors, errorName) => {
    return (
      touched &&
      touched.person &&
      touched.person.profileImageURL === true &&
      errors &&
      errors[errorName || "missingImageURL"] === true
    );
  };

  render() {
    const {
      anyCropAspect,
      cropAspectHeight,
      cropAspectWidth,
      disabled,
      errorName,
      errors,
      forceCrop,
      imageName,
      imageURL,
      label,
      person,
      touched,
      required,
      uploadedLabel,
      hideDefaultImage,
    } = this.props;
    const { droppedImage, loading, showCropperModal, showError } = this.state;
    const error = this.imageError(touched, errors, errorName) && required;

    return (
      <div
        className={`flex flex-align-center dropzone ${
          disabled ? "disabled" : ""
        }`}
      >
        <Dropzone
          accept={"image/png,image/jpeg,image/gif,image/bmp,image/jpg"}
          disabled={disabled}
          multiple={false}
          onDrop={this.onDrop}
          onDropRejected={this.onDropRejected}
          ref={this.dropzoneRef}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className="profile-pic-container flex flex-align-center"
            >
              <input {...getInputProps()} />
              {loading ? (
                <Loader />
              ) : (
                !hideDefaultImage && (
                  <img
                    src={
                      imageURL || droppedImage
                        ? imageURL || droppedImage.previewURL
                        : person
                        ? PROFILE_PLACEHOLDER_URL
                        : "/images/placeholder.svg"
                    }
                    alt=""
                  />
                )
              )}
            </div>
          )}
        </Dropzone>
        {!(imageURL || droppedImage) || hideDefaultImage ? (
          <div
            className={`link-text uppercase-text relative ${
              error ? "error" : ""
            }`}
            onClick={() => this.dropzoneRef.current.open()}
          >
            {label}
            <span
              className="error-message no-wrap"
              style={{
                left: "0",
                top: "16px",
                textTransform: "none",
              }}
            >
              {showError ? "Something went wrong, please try again" : ""}
            </span>
          </div>
        ) : (
          <div
            className={`link-text uppercase-text relative`}
            onClick={() => this.dropzoneRef.current.open()}
          >
            {uploadedLabel}
            <span
              className="error-message no-wrap"
              style={{
                left: "0",
                top: "16px",
                textTransform: "none",
              }}
            >
              {showError ? "Something went wrong, please try again" : ""}
            </span>
          </div>
        )}
        {droppedImage && (
          <ImageCropperModal
            anyCropAspect={anyCropAspect}
            cropAspectHeight={cropAspectHeight}
            cropAspectWidth={cropAspectWidth}
            forceCrop={forceCrop}
            imageName={imageName}
            imageURL={droppedImage.previewURL}
            show={showCropperModal}
            submitCrop={this.onFinishCrop}
            cancelCrop={this.onCancelCrop}
          />
        )}
      </div>
    );
  }
}
export default connect(null, function mapDispatch(dispatch) {
  return {
    actions: {
      ...bindActionCreators(SysActions, dispatch),
    },
  };
})(ImageWithCropUpload);
