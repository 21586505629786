import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  submitBtn: {
    margin: 16,
    backgroundColor: (props: any) =>
      props.statusType === "reject"
        ? theme.palette.error.main
        : theme.palette.success.main,
    "&:hover": {
      backgroundColor: (props: any) =>
        props.statusType === "reject"
          ? theme.palette.error.dark
          : theme.palette.success.dark,
    },
  },
  sendEmailText: { marginLeft: 28, color: "#747579" },
  box: { border: "1px solid #D8D8DD", padding: 8, marginBottom: 40 },
  previewBtn: {
    marginLeft: 20,
    display: "flex",
    marginBottom: 16,
    alignItems: "center",
    "& .MuiButton-label": { fontSize: 12, display: "flex", gap: 8 },
  },
  alert: { marginBottom: 16, color: theme.palette.error.main },
  radioContainer: { marginBottom: 16 },
  boldLabel: {
    fontSize: "1rem",
    lineHeight: 1.4,
    fontFamily: "AvenirHeavy",
  },
}));
