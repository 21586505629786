import React from "react";
import { TextInput, TextInputProps } from "./TextInput";
import NumberFormat from "react-number-format";
import { currencyCodes, getCurrencySymbol } from "../../../lib";

export type NumberInputProps = {
  currencyCode?: string;
  decimals?: number;
  isCurrency?: boolean;
  isNumericString?: boolean;
  label?: string;
  onChange: (name: string, val: any) => void;
  thousandSeparator?: boolean;
} & Omit<TextInputProps, "onChange">;

export const NumberInput = React.memo(
  /**
   *
   */
  function NumberInput({
    isCurrency,
    currencyCode = currencyCodes.USD,
    decimals = isCurrency ? 2 : 0,
    id,
    isNumericString,
    name = "",
    onBlur,
    onChange,
    thousandSeparator = true,
    value,
    ...passProps
  }: NumberInputProps) {
    return (
      <NumberFormat
        {...(passProps as any)}
        id={id}
        customInput={TextInput}
        decimalScale={decimals}
        inputMode="numeric"
        isNumericString={isNumericString}
        name={name}
        thousandSeparator={thousandSeparator}
        onBlur={onBlur}
        onValueChange={({ floatValue, value }) =>
          onChange(name, (isNumericString ? value : floatValue) || "")
        }
        prefix={isCurrency ? getCurrencySymbol(currencyCode) : undefined}
        value={value}
      />
    );
  },
);
