import React from "react";
import RsvpEnrollmentCompleted from "./enrollment/EnrollmentCompleted";
import RsvpEnrollmentForm from "./enrollment/EnrollmentForm";
import ErrorDisplay from "../../components/ErrorDisplay";
import Loader from "../../components/Loader";
import MyRsvp from "./myRsvp/MyRsvp";
import NoAccess from "../shared/NoAccess";
import { IsApiErrorStatus, Navigation, PageURL } from "../../lib";
import { withAppInsights } from "../../lib/AppInsights";

class Rsvp extends React.PureComponent {
  state = {
    enrollmentId: null,
    showEnrollmentCompleted: false,
  };

  componentDidMount() {
    this.getRsvpEnrollment();
  }

  getRsvpEnrollment = async () => {
    const {
      actions: { getRsvpEnrollment },
      pageRoute: { page, params },
    } = this.props;

    await getRsvpEnrollment();

    //after enrollment is retrieved, if no/innacurate program schedule id is provided in the page route, set the correct programScheduleId param
    const {
      enrollment: {
        data: { programScheduleID: rsvpSystemProgramScheduleId } = {},
      },
    } = this.props;

    if (
      !params.programScheduleId ||
      !rsvpSystemProgramScheduleId ||
      params.programScheduleId.toString() !==
        rsvpSystemProgramScheduleId.toString()
    ) {
      Navigation.redirect(
        PageURL.to(
          page,
          {
            ...params,
            programScheduleId: rsvpSystemProgramScheduleId,
          },
          null,
        ),
      );
    }
  };

  toggleShowEnrollmentCompleted = () => {
    const {
      pageRoute: { page, params },
    } = this.props;

    const showEnrollmentCompleted = !this.state.showEnrollmentCompleted;

    //redirect to all courses when toggle off showEnrollmentCompleted to get started
    if (!showEnrollmentCompleted) {
      Navigation.redirect(
        PageURL.to(
          page,
          {
            pageView: "events",
            programScheduleId: params.programScheduleId,
          },
          null,
        ),
      );
    }
    setTimeout(
      () =>
        this.setState({
          showEnrollmentCompleted,
        }),
      0,
    );
  };

  render() {
    const {
      enrollment: { data: enrollment, error, errorMessage, loading },
      pageRoute: {
        params: { programScheduleId },
      },
    } = this.props;

    const { showEnrollmentCompleted } = this.state;

    //don't mount Enrollment components until enrollment is retrieved and matching program schedule is set in route
    const canMountRsvpEnrollment =
      enrollment &&
      programScheduleId &&
      programScheduleId.toString() === enrollment.programScheduleID.toString();

    return (
      <div className="rsvp-page page">
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : error ? (
          IsApiErrorStatus(error, 403) ? (
            <NoAccess systemName="the RSVP System" />
          ) : (
            <ErrorDisplay
              className="program-error-page"
              errorMessage={
                errorMessage ||
                "Something went wrong and we could not retrieve RSVP System information. Please try again."
              }
            />
          )
        ) : (
          canMountRsvpEnrollment &&
          (!enrollment.id ? (
            <RsvpEnrollmentForm
              {...this.props}
              toggleShowEnrollmentCompleted={this.toggleShowEnrollmentCompleted}
            />
          ) : showEnrollmentCompleted ? (
            <RsvpEnrollmentCompleted
              enrollment={enrollment}
              toggleShowEnrollmentCompleted={this.toggleShowEnrollmentCompleted}
            />
          ) : (
            <MyRsvp {...this.props} />
          ))
        )}
      </div>
    );
  }
}

export default withAppInsights(Rsvp);
