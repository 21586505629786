import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { TripSelectors } from "../../../../state/trip/selectors"
  ;
import { Button, Modal } from '@material-ui/core';
import Loader from "../../../../components/Loader";
import { AuthRequest, formatFullName } from "../../../../lib";

function TourOptOutStudentsModal(props) {
  const {
    tour: {
      id: tourID,
      name: tourName,
    },
    onClose,
  } = props;

  const [registeredStudents, setRegisteredStudents] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const eventID = useSelector(TripSelectors.eventID);
  const enrollmentID = useSelector(TripSelectors.enrollmentID);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const applicableStatuses = ["Accepted", "CheckedIn", "Pending"];

      const params = {
        page: 1,
        results: 100,
        statuses: applicableStatuses,
        tripEnrollmentID: enrollmentID,
        text: "",
        tripEventID: eventID,
        tourIDs: tourID,
      };

      const response = await AuthRequest.get(
        `TripRegistrations/byEvent?${queryString.stringify(params)}`,
      ).catch(() => {
        setErrorMessage("Error retrieving students.");
      });
      const _registeredStudents = response?.data?.payload?.results || [];

      setRegisteredStudents(_registeredStudents);
      setLoading(false);
    })();
  }, [eventID, enrollmentID, tourID]);

  return (
    <Modal open={true}>
      <div className="modal-container tour-opt-out-modal">
        <div className="modal-card card large-modal">
          {loading ? (
            <Loader />
          ) : (
            <div>
              <p className="large-text fw-700 mb-32">Registered Students for {tourName}</p>
              {registeredStudents.length ? (
                <>
                  {registeredStudents.map(student => (
                    <div key={student.id} className="student-registration">
                      <p>
                        {formatFullName(student.studentFirstName, student.studentLastName)}
                        {student.status === "Pending" && (<span>*</span>)}
                      </p>
                    </div>
                  ))}
                  {registeredStudents.some(student => student.status === "Pending") && (
                    <div className="fs-13 italic-text mt-32 warning-color">*Pending registration.</div>
                  )}
                </>
              ) : errorMessage ? (
                <div className="accent-text mt-16 mb-32 line-height-double pre-wrap">
                  {errorMessage}
                </div>
              ) : (
                <div className="accent-text mt-16 mb-32 line-height-double pre-wrap">
                  There are no registered students for this tour. Any rejected students will be removed from the tour.
                </div>
              )}
              < hr className="modal-separator" />
              <div className="modal-btns relative button-wrapper">
                <Button
                  variant="contained"
                  color="primary"
                  className="confirm-btn"
                  onClick={onClose}
                >
                  Ok
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal >
  );
}
export default React.memo(TourOptOutStudentsModal);
