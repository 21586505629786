import React from "react";
import FadeOutErrorMessage from "./FadeOutErrorMessage";
import json2csv from "json2csv";

export default class ExportCSVButton extends React.PureComponent {
  state = {
    errorMessage: "",
    loading: false,
    success: true,
  };

  downloadCSV = (data, fileName) => {
    const { getHeaders } = this.props;
    const csv = json2csv({ data, fields: getHeaders(data), withBOM: true });

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  exportCSV = () => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        success: true,
      },
      async () => {
        const { fileName, getExportData } = this.props;

        try {
          const data = await getExportData();
          if (data && data.length) {
            this.downloadCSV(
              data,
              `${fileName}-${new Date()
                .toLocaleDateString()
                .replace(/\//g, "-")}.csv`,
            );

            this.setState({
              loading: false,
            });
          } else {
            this.setState({
              errorMessage: "No results for export",
              loading: false,
              success: false,
            });
          }
        } catch (err) {
          console.log(err);
          this.setState({
            loading: false,
            success: false,
          });
        }
      },
    );
  };

  render() {
    const { className, busyTitle, disabled, style, title } = this.props;
    const { errorMessage, loading, success } = this.state;

    const disableExport = disabled || loading;

    return (
      <div
        className="flex relative"
        style={{ flex: "1", flexDirection: "column" }}
      >
        <p
          className={`${className || ""}${disableExport ? " disabled" : ""}`}
          onClick={disableExport ? null : this.exportCSV}
          style={style}
        >
          {loading ? busyTitle || "Exporting..." : title || "CSV Export"}
        </p>

        {!loading && !success && (
          <FadeOutErrorMessage
            message={errorMessage || "Something went wrong.  Please try again."}
            style={{ bottom: "-14px", whiteSpace: "nowrap", right: 0 }}
            onTimeout={() =>
              this.setState({
                errorMessage: "",
                success: true,
              })
            }
          />
        )}
      </div>
    );
  }
}
