import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  text: { marginTop: 16 },
  subText: { marginTop: 16, color: "#747579" },
}));

function _NoMessages() {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <img src="/images/sms.svg" alt="no messages" height="108" />
      <Typography variant="h5" className={classes.text}>
        No messages selected
      </Typography>
      <Typography variant="body1" className={classes.subText}>
        Select an item in the list on the left to view it's message content
      </Typography>
    </Box>
  );
}

export const NoMessages = React.memo(_NoMessages);
