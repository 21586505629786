import React from "react";
import {
  ResponsiveContainer,
  Bar,
  BarChart,
  Cell,
  XAxis,
  YAxis,
} from "recharts";
import { formatCurrency } from "../../../lib";

const barColors = [
  { dark: "#6369D1", light: "rgba(99,105,209,0.08)" },
  { dark: "#63C674", light: "rgba(99,198,116,0.08)" },
  { dark: "#EC5150", light: "rgba(236,81,80,0.08)" },
  { dark: "#F3893D", light: "rgba(243,137,61,0.08)" },
  { dark: "#2774AE", light: "rgba(83,183,232,0.08)" },
];

function getBarColor(index) {
  return (
    barColors[index < 5 ? index : index % 5] || {
      dark: "rgba(0,0,0,0.87)",
      light: "#F4F4F4",
    }
  );
}

class CustomSalesByReferrerLabel extends React.Component {
  render() {
    const { data, height, index, value, x, y } = this.props;
    const fontSize = 14;
    const fill = getBarColor(index).dark;

    return (
      <text
        dx={5}
        dy={height / 2 + fontSize / 2}
        fill={fill}
        fontSize={fontSize}
        fontFamily="sans-serif"
        textAnchor="start"
        x={x ? x : ""}
        y={y}
      >
        {data[index].referrerName} ({value})
      </text>
    );
  }
}

export default class EnrollmentSalesByReferrerChart extends React.Component {
  state = {
    timeframe: "7 days",
  };

  formatSales = (amount) => formatCurrency(amount, this.props.currencyCode);

  getChartHeight = (dataLength) => {
    let baseHeight = 350;
    if (dataLength <= 15) {
      return baseHeight;
    }
    //add 25px to the base height for every referrer over 15 to accomodate the data within the chart
    return baseHeight + 25 * (dataLength - 15);
  };

  render() {
    const { data } = this.props;
    const height = this.getChartHeight(data.length);
    return (
      <div className="graph-outline ml-24">
        <p className="medium-text fw-700">Sales By Team</p>

        {!data.length ? (
          <div className="chart-no-results">
            <img src="/images/no_results.svg" alt="no results" height="120" />
            <p className="accent-text mt-8">No sales found</p>
          </div>
        ) : (
          <ResponsiveContainer height={height} width="94%">
            <BarChart data={data} layout="vertical">
              <Bar
                dataKey="salesByReferrer"
                fill="#8884d8"
                barSize={32}
                label={
                  <CustomSalesByReferrerLabel
                    data={data}
                    formatValue={this.formatSales}
                  />
                }
              >
                {data.map((entry, index) => {
                  const fill = getBarColor(index).light;
                  return <Cell key={index} fill={fill} />;
                })}
              </Bar>

              <YAxis type="category" hide />

              <XAxis dataKey="salesByReferrer" type="number" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    );
  }
}
