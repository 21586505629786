import React from "react";
import ConfirmationModal from "../../../../../../components/ConfirmationModal";
import PaginatedTable from "../../../../../../components/PaginatedTableDeprecated";
import { PageURL, formatFullName, formatCurrency } from "../../../../../../lib";
import { RsvpRegistrationStatuses } from "../../../../RsvpHelpers";

export default class StudentsList extends React.PureComponent {
  state = {
    mobileDetailsOpened: {},
    selectedPerson: null,
    selectedPersonAction: null,
    showPersonActionModal: false,
    personActionErrorMessage: "",
  };

  personActions = {
    Cancel: "Cancel",
    Register: "Register",
  };

  getURLForPagination = (pg, resultsView) => {
    const {
      pageRoute: { query, page, params },
    } = this.props;
    return PageURL.to(page, params, {
      ...query,
      p: pg,
      r: resultsView || query.r,
    });
  };

  onSelectStudent = (person, action) => {
    this.setState({
      selectedPerson: person,
      selectedPersonAction: action,
      showPersonActionModal: true,
      personActionErrorMessage: "",
    });
  };

  onCancelStudentAction = () => {
    this.setState({
      selectedPerson: null,
      selectedPersonAction: null,
      showPersonActionModal: false,
      personActionErrorMessage: "",
    });
  };

  removeStudent = async () => {
    const {
      selectedPerson: { registrationID },
    } = this.state;

    await this.props.doRemoveStudentRegistration(registrationID);

    const {
      removeStudentRegistration: { errorMessage, success },
      reloadStudents,
    } = this.props;

    if (success) {
      reloadStudents();
      this.setState({
        selectedPerson: null,
        selectedPersonAction: null,
        showPersonActionModal: false,
      });
    } else {
      this.setState({
        personActionErrorMessage:
          errorMessage || "Something went wrong.  Please try again.",
      });
    }
  };

  toggleMobileDetails = (id) => {
    const { mobileDetailsOpened } = this.state;
    this.setState({
      mobileDetailsOpened: {
        ...mobileDetailsOpened,
        [id]: !mobileDetailsOpened[id],
      },
    });
  };

  render() {
    const {
      filterComponent,
      HeaderComponent,
      RowComponent,
      getStudents,
      pageRoute: {
        query: { p: page = 1, r: resultsView = 10 },
      },
      showPaidDonated,
      students: { data: { results, numberOfRows } = {}, loading, success },
      registerStudentModal,
      removeStudentRegistration,
      hasLinkedSubmittedInteraction,
    } = this.props;

    const {
      mobileDetailsOpened,
      selectedPerson,
      selectedPersonAction,
      showPersonActionModal,
      personActionErrorMessage,
    } = this.state;

    return (
      <React.Fragment>
        <PaginatedTable
          className="rsvp-students-table"
          filterComponent={filterComponent}
          filterComponentClassName={"mobile-flex-align-top mobile-flex-column"}
          getURL={this.getURLForPagination}
          loadData={getStudents}
          loading={loading}
          name="Event Registrations"
          page={page}
          records={results || []}
          renderHeaderRow={() => (
            <HeaderComponent showPaidDonated={showPaidDonated} />
          )}
          renderRow={(person, index) => (
            <RowComponent
              key={index}
              mobileDetailsOpened={mobileDetailsOpened}
              onRegisterStudent={() =>
                this.onSelectStudent(person, this.personActions.Register)
              }
              onRemoveStudent={() =>
                this.onSelectStudent(person, this.personActions.Cancel)
              }
              showPaidDonated={showPaidDonated}
              person={person}
              toggleMobileDetails={this.toggleMobileDetails}
              hasLinkedSubmittedInteraction={hasLinkedSubmittedInteraction}
            />
          )}
          resultsIncrement={10}
          resultsView={resultsView}
          showResultsView={true}
          success={success}
          totalCount={numberOfRows}
        />

        {/* Register student */}
        {React.cloneElement(registerStudentModal, {
          close: this.onCancelStudentAction,
          show:
            showPersonActionModal &&
            selectedPersonAction === this.personActions.Register,
          waitlistedRegistration: selectedPerson && {
            registrationID: selectedPerson.registrationID,
            personID: selectedPerson.personID,
            studentName: formatFullName(
              selectedPerson.firstName,
              selectedPerson.lastName,
            ),
          },
        })}

        {/* Confirm remove student */}
        {showPersonActionModal &&
          selectedPersonAction === this.personActions.Cancel && (
            <ConfirmationModal
              cancel={this.onCancelStudentAction}
              confirm={this.removeStudent}
              errorMessage={personActionErrorMessage}
              loading={removeStudentRegistration.loading}
              message={`Are you sure you want to remove ${
                (selectedPerson &&
                  formatFullName(
                    selectedPerson.firstName,
                    selectedPerson.lastName,
                  )) ||
                "this person"
              } from this event${
                selectedPerson.registrationFeeAmount &&
                selectedPerson.status !==
                  RsvpRegistrationStatuses.PendingPayment
                  ? " and refund the " +
                    formatCurrency(
                      selectedPerson.registrationFeeAmount,
                      "USD",
                    ) +
                    " registration fee"
                  : ""
              }?  This cannot be undone.`}
              show={
                showPersonActionModal &&
                selectedPersonAction === this.personActions.Cancel
              }
              title="Remove Registration Confirmation"
            />
          )}
      </React.Fragment>
    );
  }
}
