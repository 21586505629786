import React from "react";

export default class CustomCheckbox extends React.Component {
  render() {
    const {
      className,
      disabled,
      index,
      name,
      onChange,
      options,
      readOnly,
      values,
    } = this.props;
    return (
      options &&
      options.map((option) => (
        <div
          className={`flex custom-checkbox-container mb-8 ${disabled || option.disabled ? "disabled" : ""
            } ${className ? className : ""}`}
          key={option.value}
        >
          <input
            checked={values && values.indexOf(option.value) >= 0}
            className="custom-checkbox"
            disabled={disabled || option.disabled}
            id={`${name}-${option.value}`}
            name={name}
            onChange={
              readOnly
                ? null
                : (e) => onChange(name, option.value, e.target.checked, index)
            }
            readOnly={readOnly}
            type="checkbox"
          />
          <label htmlFor={`${name}-${option.value}`}>
            <span className="fw-700 mr-8 no-wrap">{option.displayBold}</span>
            {option.display}
          </label>
        </div>
      ))
    );
  }
}
