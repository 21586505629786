import React from "react";

import {
  getCociLogo,
  getCociLogoMobile,
  handleInputState,
  handlePreventDefault,
} from "../../lib";
import { withAppInsights } from "../../lib/AppInsights";
import Loader from "../../components/Loader";
import { GoogleIdentitySignin } from "../../components/GoogleIdentitySignin";

class Login extends React.PureComponent {
  state = {
    email: "",
    password: "",
    showBasicLogin: false,
  };

  emailChanged = handleInputState(this, "email");
  passwordChanged = handleInputState(this, "password");

  handleLogin401ErrorMessage = (error) => {
    return (
      (error.data && error.data.defaultMessage) ||
      "Email and/or password is incorrect"
    );
  };

  submitForm = handlePreventDefault(() => {
    const { email, password } = this.state;
    this.props.actions.doBasicSignin({ email, password });
  });

  inputFocus(e) {
    let inputContainer = e.target.parentNode;
    inputContainer.classList.add("input-container-outline");
  }

  inputBlur(e) {
    let inputContainer = e.target.parentNode;
    inputContainer.classList.remove("input-container-outline");
  }

  render() {
    const { email, password, showBasicLogin } = this.state;
    const { loading, error } = this.props;
    return (
      <div className="login-page account-page">
        <div className="account-page-left">
          <div>
            <img
              src={getCociLogo()}
              alt="logo"
              className="logo mobile-hidden"
            />
            <div className="account-header-mobile desktop-hidden tablet-hidden">
              <img src={getCociLogoMobile()} alt="logo" />
            </div>
          </div>
          <div>
            <h1 className="account-page-title">Welcome Back!</h1>
            <GoogleIdentitySignin />
            {!showBasicLogin ? (
              <p className="accent-text text-center mt-24">
                Don't have a google login? Sign in{" "}
                <span
                  className="link-text pointer"
                  onClick={() => this.setState({ showBasicLogin: true })}
                >
                  here
                </span>
              </p>
            ) : (
              <React.Fragment>
                <p className="mt-24 mb-24 text-center accent-text medium-text">
                  OR
                </p>

                <form className="account-form">
                  <div className="input-container">
                    <label>Email</label>
                    <input
                      value={email}
                      onChange={this.emailChanged}
                      onFocus={(e) => this.inputFocus(e)}
                      onBlur={(e) => this.inputBlur(e)}
                    />
                  </div>
                  <div className="input-container">
                    <label>Password</label>
                    <input
                      type="password"
                      value={password}
                      onChange={this.passwordChanged}
                      onFocus={(e) => this.inputFocus(e)}
                      onBlur={(e) => this.inputBlur(e)}
                    />
                  </div>
                  {!loading ? (
                    <button
                      type="submit"
                      className="btn btn-large btn-fullWidth btn-accent mt-16"
                      disabled={!email || !password}
                      onClick={this.submitForm}
                    >
                      Sign in
                    </button>
                  ) : (
                    <div className="btn-large-loader">
                      <Loader />
                    </div>
                  )}
                </form>
              </React.Fragment>
            )}
            {error &&
              (error.config.url.indexOf("google") > 0 ? (
                <div className="error-message-container mt-4">
                  <span className="error-message full-width text-center mt-8">
                    Google account is not linked to shliach account.
                  </span>
                </div>
              ) : error.status === 401 ? (
                <div className="error-message-container mt-4">
                  <span className="error-message full-width text-center mt-8">
                    {this.handleLogin401ErrorMessage(error)}
                  </span>
                </div>
              ) : (
                <div className="error-message-container mt-4">
                  <span className="error-message full-width text-center mt-8">
                    There was an error signing in. Please try again.
                  </span>
                </div>
              ))}
          </div>
          <div className="small-text flex">
            {/* <PageLink to={''} className="link-text no-wrap">
              Terms and Conditions
            </PageLink> */}
            {/* <p className="accent-text-light ml-16 mr-16">|</p> */}
            <p className="flex flex-align-center">
              <span className="accent-text-dark block small-text">
                <a
                  href="mailto:help@chabadoncampus.org"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-text"
                >
                  <span>help@chabadoncampus.org</span>
                </a>
              </span>
              <span className="accent-text mr-8 ml-8">|</span>
              <span className="accent-text-dark block small-text">
                <a href="tel:718-510-8181" className="link-text">
                  <span className="no-wrap">718-510-8181 ext 0</span>
                </a>
              </span>
            </p>
          </div>
        </div>
        <div className="account-page-right">
          <img src="/images/account-background.jpg" alt="background" />
        </div>
      </div>
    );
  }
}

export default withAppInsights(Login);
