import React from "react";
import AttendanceList from "./AttendanceList";
import ErrorDisplay from "../../../../../../components/ErrorDisplay";
import Loader from "../../../../../../components/Loader";

export default class Attendance extends React.PureComponent {
  componentDidMount() {
    this.getCourseDetails();
  }

  getCourseDetails = () => {
    const {
      courseSchedule: { data: { id: courseScheduleId } = {} },
    } = this.props;

    this.getCourseStudents(courseScheduleId);
    this.getCourseAttendance(courseScheduleId);
  };

  getCourseStudents = (courseScheduleId) => {
    this.props.actions.getEduStudents(
      undefined,
      undefined,
      {
        schedule: courseScheduleId,
        status: "Approved",
      },
      true,
    );
  };

  getCourseAttendance = (courseScheduleId) => {
    this.props.actions.getEduCourseScheduleAttendance(courseScheduleId);
  };

  render() {
    const {
      actions,
      courseSchedule: { data: { id: courseScheduleId, classSchedules } = {} },
      courseScheduleAttendance,
      enrollment: {
        data: { eduProgramType },
      },
      pageRoute,
      students: courseScheduleStudents,
      submitCourseScheduleAttendance,
    } = this.props;

    return (
      <div className="card full-width mobile-flat-card attendance-card">
        {classSchedules &&
          classSchedules.length > 0 &&
          (courseScheduleAttendance.loading ||
          courseScheduleStudents.loading ? (
            <Loader />
          ) : courseScheduleAttendance.error || courseScheduleStudents.error ? (
            <ErrorDisplay
              errorMessage={
                courseScheduleAttendance.errorMessage ||
                courseScheduleStudents.errorMessage
              }
            />
          ) : (
            courseScheduleAttendance.data &&
            courseScheduleStudents.data && (
              <AttendanceList
                doSubmitCourseScheduleAttendance={(attendance) =>
                  actions.submitEduCourseScheduleAttendance(
                    courseScheduleId,
                    attendance,
                  )
                }
                classSchedules={classSchedules}
                courseScheduleAttendance={courseScheduleAttendance.data}
                courseScheduleStudents={courseScheduleStudents.data}
                eduProgramType={eduProgramType}
                pageRoute={pageRoute}
                submitCourseScheduleAttendance={submitCourseScheduleAttendance}
              />
            )
          ))}
      </div>
    );
  }
}
