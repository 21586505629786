import React from "react";

export default ({ isStudentsByCourseView }) => (
  <div className="paginated-table-header-row students-table-row">
    <p>Name</p>
    <p>Email</p>
    {isStudentsByCourseView ? (
      <React.Fragment>
        <p>Phone</p>
        <p>Attendance</p>
        <p>Review</p>
        <p>Status</p>
      </React.Fragment>
    ) : (
      <p>
        Course Schedule Name
        <span className="block small-text italic-text">Course name</span>
      </p>
    )}
  </div>
);
