import React from "react";
import { Button } from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export default function StudentInteractionModalButtons(props: any) {
  const {
    isGeneratedFromEvent,
    isSubmittedForGrant,
    isEditingInteraction,
    deleteLoading,
    loading,
    onClose,
    saveDisabled,
    onSubmit,
    deleteErrorMessage,
    errorMessage,
    submitAttempted,
    confirmDelete,
  } = props;

  return (
    <>
      <div className="student-interaction-modal-btns flex flex-justify-space flex-align-center mt-24">
        {!isSubmittedForGrant &&
        !isGeneratedFromEvent &&
        isEditingInteraction ? (
          <button
            className="btn btn-light btn-medium"
            disabled={deleteLoading || loading}
            onClick={confirmDelete}
          >
            {deleteLoading ? "Deleting Interaction..." : "Delete Interaction"}
          </button>
        ) : (
          <div />
        )}
        <div className="flex flex-align-center mr-32">
          <Button variant="contained" disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <div
            className={`${
              isEditingInteraction || saveDisabled ? "" : "dropdown-container"
            } ml-16`}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={saveDisabled}
              onClick={isEditingInteraction ? () => onSubmit() : undefined} // no save menu when editing an interaction
            >
              {loading ? "Saving..." : "Save"}
              {!isEditingInteraction && <ArrowDropDownIcon className="ml-8" />}
            </Button>
            <div className="dropdown btn-dropdown small-text">
              <div
                className="link-text-secondary line-height-double mb-16"
                onClick={() => onSubmit(true)}
              >
                <p>Save and add new</p>
                <p className="accent-text">
                  Add an interaction for different student(s)
                </p>
              </div>
              <div
                className="link-text-secondary line-height-double mb-16"
                onClick={() => onSubmit(true, true)}
              >
                <p>Save and add another</p>
                <p className="accent-text">
                  Add another interaction for the same student(s)
                </p>
              </div>
              <div
                className="link-text-secondary line-height-double"
                onClick={() => onSubmit()}
              >
                <p>Save</p>
                <p className="accent-text">Save this interaction</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-justify-space flex-align-center small-text error-text mt-4">
        <p>{deleteErrorMessage}</p>
        {submitAttempted && <p>{errorMessage}</p>}
      </div>
    </>
  );
}
