import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  sidebar: { width: 300 },
  lockIcon: { marginLeft: 4, fontSize: 14 },
  drawerPaper: {
    width: 300,
    marginTop: (props: any) => (props.bannersToShow ? 122 : 74),
    height: (props: any) =>
      `calc(100vh - ${props.bannersToShow ? "122px" : "74px"})`,
  },
  btn: { margin: 24, maxWidth: 150 },
  listItem: {
    "&.Mui-selected": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
      "& .MuiSvgIcon-root": {
        fill: theme.palette.primary.main,
      },
    },
  },
  groupTitle: {
    display: "flex",
    justifyItems: "center",
    fontSize: 12,
    paddingLeft: 16,
    fontWeight: 500,
    textTransform: "uppercase",
    marginTop: 16,
    color: "#747579",
    fontFamily: "Archivo",
  },
}));
