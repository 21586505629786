import React, { useState, useEffect, useCallback, memo } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Box, Typography, Button } from "@material-ui/core";
import { formatCurrency } from "../../../../lib";
import SmsAddCreditModal from "../../../sms/SmsAddCreditModal.vm";
import StripeProvider from "../../../../components/StripeProvider";
import { AuthSelectors, SmsActions, useAction } from "../../../../state";
import { useSelector } from "react-redux";
import { RsvpSelectors } from "../../../../state/rsvp/selectors";
import { SmsSelectors } from "../../../../state/sms/selectors";
import { UiSelectors } from "../../../../state/ui/selectors";
import { useStyles } from "./SmsInfoSidebar.styles";
const { REACT_APP_STRIPE_API_KEY } = process.env;

const date = new Date();

function _SmsInfoSidebar() {
  const classes = useStyles();

  const getSmsStatementAction = useAction(SmsActions.getSmsStatement);

  const forChabadHouseID = useSelector(RsvpSelectors.forChabadHouseID);
  const statementData = useSelector(SmsSelectors.statementData);
  const isChabadHouseEnrolledInOutgoingMessaging = useSelector(
    RsvpSelectors.isChabadHouseEnrolledInOutgoingMessaging,
  );
  const isPhone = useSelector(UiSelectors.isPhone);
  const { isEnrolledInMessaging } = useSelector(AuthSelectors.engagementAccess);

  const [showSmsInfo, setShowSmsInfo] = useState(true);
  const [showPeriodInfo, setShowPeriodInfo] = useState(true);
  const [showSmsAddCreditModal, setShowSmsAddCreditModal] = useState(false);
  const [smsStatementDates, setSmsStatementDates] = useState([
    new Date(date.getFullYear(), date.getMonth(), 1),
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  ]);

  const {
    phoneNumber,
    amountPaid,
    messageCount,
    pricePerSegment,
    pricePerMmsSegment,
    balance,
    totalCost,
  } = statementData;

  const getSmsStatement = useCallback(
    (smsStatementDates) => {
      setSmsStatementDates(smsStatementDates);
      getSmsStatementAction(
        forChabadHouseID,
        ...smsStatementDates.map((v) => v.toLocaleDateString("en-US")),
      );
    },
    [forChabadHouseID, getSmsStatementAction],
  );

  useEffect(() => {
    if (forChabadHouseID && isEnrolledInMessaging) {
      getSmsStatement(smsStatementDates);
    }
    // componentDidMount - don't want to rerender on smsStatementDates changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forChabadHouseID, getSmsStatement, isEnrolledInMessaging]);

  const toggleSmsInfo = () => {
    setShowSmsInfo(isPhone || !showSmsInfo);
  };

  const togglePeriodInfo = () => {
    setShowPeriodInfo(isPhone || !showPeriodInfo);
  };

  return (
    <div className="mb-16">
      {forChabadHouseID && isChabadHouseEnrolledInOutgoingMessaging && (
        <StripeProvider apiKey={REACT_APP_STRIPE_API_KEY}>
          <SmsAddCreditModal
            chabadHouseID={forChabadHouseID}
            show={showSmsAddCreditModal}
            onPaymentComplete={() => getSmsStatement(smsStatementDates)}
            onClose={() => setShowSmsAddCreditModal(false)}
          />
        </StripeProvider>
      )}
      <Box className={classes.titleContainer} onClick={toggleSmsInfo}>
        <Typography variant="h6">My SMS Info</Typography>
        <i
          className="material-icons pointer mobile-hidden"
          style={{ fontSize: "18px" }}
        >
          {showSmsInfo ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </i>
      </Box>
      {showSmsInfo && (
        <Box className={classes.infoContainer}>
          <Box className={classes.section}>
            <Typography className={classes.label}>
              Chabad house SMS number
            </Typography>
            <span className="accent-text mb-12">{phoneNumber}</span>
          </Box>
          <Box className={classes.section}>
            <Typography className={classes.label}>
              Price per message segment
            </Typography>
            <Typography variant="body2">${pricePerSegment}</Typography>
          </Box>
          <Box className={classes.section}>
            <Typography className={classes.label}>
              Price per mms message segment
            </Typography>
            <Typography variant="body2">${pricePerMmsSegment}</Typography>
          </Box>
          <Box className={classes.section}>
            <Typography className={classes.label}>Remaining credit</Typography>
            <Typography variant="body2">
              ${!!balance && Math.floor(balance * 10000) / 10000}
            </Typography>
          </Box>
        </Box>
      )}

      <Box className={classes.titleContainer} onClick={togglePeriodInfo}>
        <Typography variant="h6">This period</Typography>
        <i
          className="material-icons pointer mobile-hidden"
          style={{ fontSize: "18px" }}
        >
          {showPeriodInfo ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </i>
      </Box>

      {showPeriodInfo && (
        <Box className={classes.infoContainer}>
          <Box className={classes.paddingContainer}>
            <DateRangePicker
              className="date-range"
              value={smsStatementDates}
              clearIcon={null}
              onChange={(value) => {
                getSmsStatement(value);
              }}
            />
          </Box>

          <Box className={classes.paddingContainer}>
            <Box className={classes.bottomSection}>
              <Typography variant="subtitle2">Total credit added</Typography>
              <Typography variant="body2">
                ${formatCurrency(amountPaid)}
              </Typography>
            </Box>
            <Box className={classes.bottomSection}>
              <Typography variant="subtitle2">Total messages</Typography>
              <Typography variant="body2">{messageCount || 0}</Typography>
            </Box>
            <Box className={classes.bottomSection}>
              <Typography variant="subtitle2">Total spent</Typography>
              <Typography variant="body2">
                ${!!totalCost && Math.ceil(totalCost * 10000) / 10000}
              </Typography>
            </Box>
          </Box>

          <Button
            variant="text"
            color="primary"
            onClick={() => setShowSmsAddCreditModal(true)}
          >
            ADD Credit
          </Button>
        </Box>
      )}
    </div>
  );
}

export default memo(_SmsInfoSidebar);
