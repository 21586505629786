import React from "react";

import CustomSelect from "../../../../components/form/CustomSelect";

export default class BillingAddress extends React.PureComponent {
  toggleUseAddress = (event, address) => {
    const { onChange } = this.props;

    const billingAddress = event.target.checked
      ? {
          id: address.id,
          address1: address.address1 || "",
          address2: address.address2 || "",
          city: address.city || "",
          state: address.state || "",
          zip: address.zip || "",
          country: address.country || "",
          discriminator: address.discriminator,
        }
      : {
          id: 0,
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          discriminator: "None",
        };

    onChange("billing.address", billingAddress);
  };

  render() {
    const {
      chabadHouseAddress,
      countries,
      enrollmentSettings: { billing },
      familyAddress,
      modal,
      onChange,
      validation,
    } = this.props;

    return (
      <div className="trip-page-form-section billing-address-section">
        <p className="medium-text fw-700 mb-16">Billing Address</p>
        {!modal &&
          [
            {
              address: chabadHouseAddress,
              label: "Billing address same as Chabad house address",
            },
            {
              address: familyAddress,
              label: "Billing address same as personal address",
            },
          ].map((add, index) => (
            <div
              key={index}
              className={`flex custom-checkbox-container mb-8 ${
                !add.address ? "disabled" : ""
              }`}
            >
              <input
                checked={add.address && billing.address.id === add.address.id}
                className="custom-checkbox"
                disabled={!add.address}
                id={`use-address=${index}`}
                onChange={(event) => this.toggleUseAddress(event, add.address)}
                type="checkbox"
              />
              <label htmlFor={`use-address=${index}`}>{add.label}</label>
            </div>
          ))}

        <form className="billing-address-form mt-24">
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="trip-page-form-label">Street Address</label>
            <input
              className={`custom-input ${
                validation.hasCompleteBillingAddress &&
                !billing.address.address1
                  ? "error"
                  : ""
              }`}
              disabled={billing.address.id}
              name="billing.address.address1"
              onChange={(e) => onChange(e.target.name, e.target.value)}
              placeholder="Street Address"
              type="text"
              value={billing.address.address1}
            />
          </div>
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="trip-page-form-label">Street Address 2</label>
            <input
              className="custom-input"
              disabled={billing.address.id}
              name="billing.address.address2"
              onChange={(e) => onChange(e.target.name, e.target.value)}
              placeholder="Street Address 2 (optional)"
              type="text"
              value={billing.address.address2}
            />
          </div>
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="trip-page-form-label">Country</label>
            <CustomSelect
              className={`custom-input ${
                validation.hasCompleteBillingAddress && !billing.address.country
                  ? "error"
                  : ""
              }`}
              disabled={billing.address.id}
              isClearable={true}
              name="billing.address.country"
              placeholder="Country"
              onChange={(name, value) => onChange(name, value)}
              options={
                countries &&
                countries.map((c) => ({
                  key: c.name,
                  value: c.name,
                  label: c.name,
                }))
              }
              value={billing.address.country}
            />
          </div>
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="trip-page-form-label">City</label>
            <div className="flex">
              <input
                className={`custom-input ${
                  validation.hasCompleteBillingAddress && !billing.address.city
                    ? "error"
                    : ""
                }`}
                disabled={billing.address.id}
                name="billing.address.city"
                onChange={(e) => onChange(e.target.name, e.target.value)}
                placeholder="City"
                type="text"
                value={billing.address.city}
              />
            </div>
          </div>
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="trip-page-form-label">State</label>
            <CustomSelect
              disabled={billing.address.id}
              isClearable={true}
              name="billing.address.state"
              onChange={onChange}
              options={(
                (countries &&
                  countries
                    .filter((c) => c.name === billing.address.country)
                    .map((c) => c.states)[0]) ||
                []
              ).map((s) => ({
                key: s.code,
                value: s.name,
                label: s.name,
              }))}
              placeholder="State"
              value={billing.address.state}
            />
          </div>
          <div className="flex flex-align-center mobile-block">
            <label className="trip-page-form-label">Zip</label>
            <input
              className="custom-input"
              disabled={billing.address.id}
              name="billing.address.zip"
              onChange={(e) => onChange(e.target.name, e.target.value)}
              placeholder="Zip"
              type="text"
              value={billing.address.zip}
            />
          </div>
        </form>
      </div>
    );
  }
}
