import React, { memo } from "react";
import { Box } from "@material-ui/core";
import { formatNumber } from "../../../../../lib";

function RsvpItemTotals(props) {
  const {
    rsvpItem: {
      numberOfRegisteredPeople,
      numberOfPendingPeople,
      numberOfWaitlistedPeople,
      maxCapacity,
    },
  } = props;

  return (
    <Box className="flex">
      <div className="flex flex-align-center mr-16 tablet-mt-0 tablet-mr-8">
        <p className="fw-700 mr-8">Capacity:</p>
        {maxCapacity ? formatNumber(maxCapacity) : "Unlimited"}
      </div>
      <div className="flex flex-align-center mr-16 tablet-mt-0 tablet-mr-8">
        <p className="fw-700 mr-8">Registered:</p>
        {formatNumber(numberOfRegisteredPeople)}
      </div>
      {numberOfPendingPeople > 0 && (
        <div className="flex flex-align-center mr-16 tablet-mt-0 tablet-mr-8 mobile-mr-0 ">
          <p className="fw-700 mr-8">Pending:</p>
          {formatNumber(numberOfPendingPeople)}
        </div>
      )}
      {numberOfWaitlistedPeople > 0 && (
        <div className="flex flex-align-center mr-16 tablet-mt-0 tablet-mr-8 mobile-mr-0 ">
          <p className="fw-700 mr-8">Waitlisted:</p>
          {formatNumber(numberOfWaitlistedPeople)}
        </div>
      )}
    </Box>
  );
}

export default memo(RsvpItemTotals);
