import React from "react";
import Modal from "../../../components/Modal";
import Dropzone from "react-dropzone";

import _cloneDeep from "lodash.clonedeep";

export default class BulkImportModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      loading: false,
      fileForUpload: null,
      uploadProgress: 0,
    };

    this.state.initialState = _cloneDeep(this.state);
  }

  onClose = () => {
    this.setState(this.state.initialState, this.props.close);
    this.setState({ submitAttempted: false });
  };

  selectFile = (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      this.setState({
        errorMessage: "",
        fileForUpload: file,
      });
    }
  };

  uploadBulkOrders = () => {
    const { enrollmentID } = this.props;
    const { fileForUpload } = this.state;
    this.setState({
      submitAttempted: true,
      loading: true,
      uploadProgress: 0,
    });
    this.props
      .importBulkOrders(enrollmentID, fileForUpload, (progressEvent) => {
        let progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        this.setState({
          uploadProgress: progress > 100 ? 100 : progress,
        });
      })
      .then(() => {
        if (
          this.props.raffle.bulkImportOrders &&
          this.props.raffle.bulkImportOrders.success
        ) {
          this.props.getRaffleOrders(
            1,
            10,
            this.props.pageRoute.query.q,
            enrollmentID,
          );
          this.onClose();
        } else {
          this.setState({
            uploadProgress: 0,
            loading: false,
            errorMessage: this.props.raffle.bulkImportOrders.errorMessage,
          });
        }
      });
  };

  render() {
    const { bulkOrderTemplateURL, show } = this.props;
    const { errorMessage, fileForUpload, loading, uploadProgress } = this.state;

    return (
      <Modal show={show}>
        <div className="modal-container" key="bulkImportModal">
          <div className="modal-card card import-modal">
            <p className="xl-text fw-700">Bulk Import</p>
            <p className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
              Please refrain from using this feature unless completely
              necessary. There will be a fee for each ticket that is uploaded
              and there is no way to delete a transaction once uploaded. You can
              also enter transactions by clicking the Add Order button.
            </p>
            <p className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
              Do you have the bulk import template? If not, you can{" "}
              <a download href={bulkOrderTemplateURL} className="link-text">
                download the template here
              </a>
              . When you’re ready, upload your file below.
            </p>
            <p className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
              Note that first name, last name, email, phone, quantity, and
              payment method are required. The rest of the information is
              optional; include it if you'll want it for future reference.
            </p>
            <p className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
              Furthermore, emails will not be sent out for these orders.
            </p>

            <div className="flex mt-24 mb-24 flex-justify-space">
              <Dropzone
                // accept={'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                className="dropzone"
                onDrop={this.selectFile}
              >
                {({ getRootProps, getInputProps }) => (
                  <p
                    {...getRootProps()}
                    className="uppercase-text fw-500 link-text"
                  >
                    <input {...getInputProps()} />
                    {fileForUpload ? "Change " : "Select"} File
                  </p>
                )}
              </Dropzone>

              {fileForUpload && (
                <div className="uploading">
                  <div className="flex flex-justify-space flex-align-center">
                    <p className="fw-700 mr-8 mb-8 flex flex-align-center">
                      <i className="material-icons accent-text mr-8">
                        description
                      </i>
                      Upload orders from {fileForUpload.name}?
                    </p>
                  </div>
                  <div className="percentage-bar relative">
                    <div className="bar" />
                    <div
                      className="filled-in"
                      style={{ width: `${uploadProgress}%` }}
                    />
                    <div className="labels flex flex-justify-space mt-8">
                      <p />
                      <p className="accent-text small-text">
                        {uploadProgress}%
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="raffle-modal-btns flex flex-justify-end flex-align-center mt-24 relative">
              <button
                className="btn btn-light btn-medium"
                onClick={this.onClose}
              >
                Cancel
              </button>
              <button
                className="btn btn-accent btn-medium ml-16"
                disabled={!fileForUpload}
                onClick={this.uploadBulkOrders}
              >
                {!loading ? "Upload Orders" : "Uploading..."}
              </button>
            </div>
            {this.props.raffle.success && this.state.message && (
              <p>{this.state.message}</p>
            )}
            {this.state.submitAttempted &&
              this.props.raffle.bulkImportOrders &&
              this.props.raffle.bulkImportOrders.errorMessage && (
                <p className="error-text small-text text-right mt-8">
                  {errorMessage}
                </p>
              )}
          </div>
        </div>
      </Modal>
    );
  }
}
