import React, { useState, useEffect, memo } from "react";
import StudentInteractionModal from "../../../../../students/activity/StudentInteractionModal.vm";
import { pluralizeText, usePrevious } from "../../../../../../lib";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationModal from "../../../../../../components/ConfirmationModal";

function EventInteractionButton(props) {
  const {
    eventData: {
      chabadHouseId,
      eventState,
      occurrenceStatus,
      numberOfRegisteredStudents,
      linkedInteractionId,
    },
    eventHasChanges,
    createInteractionsAction,
    createInteractionsResult: {
      loading: createInteractionsLoading,
      success: successfullyCreatedInteractions,
      data: numCreatedInteractions,
    },
    refreshEvent,
    reinitializeSettings,
    rsvpProgramScheduleId,
  } = props;

  const [showInteractionModal, setShowInteractionModal] = useState(false);
  const [
    showConfirmChangesWillBeLostModal,
    setShowConfirmChangesWillBeLostModal,
  ] = useState(false);

  const onClickViewInteraction = () => {
    if (eventHasChanges) {
      setShowConfirmChangesWillBeLostModal(true);
    } else {
      setShowInteractionModal(true);
    }
  };

  const viewInteractionButton = () => (
    <button
      className="btn btn-accent event-interaction-btn"
      disabled={!linkedInteractionId}
      onClick={onClickViewInteraction}
    >
      <i className="material-icons large-text">open_in_new</i>
      View Interaction
    </button>
  );

  const createInteractionButton = () => {
    const interactionCannotBeCreatedReasons = [
      {
        condition: eventState === "Canceled",
        text: "This event was cancelled",
      },
      {
        condition: occurrenceStatus !== "Completed",
        text: "This event has not yet occurred",
      },
      {
        condition: numberOfRegisteredStudents === 0,
        text: "No students are registered for this event",
      },
    ].filter((c) => c.condition);

    const canManuallyCreateInteraction =
      interactionCannotBeCreatedReasons.length === 0;
    const tooltipText = canManuallyCreateInteraction ? (
      <p>
        Interactions are created every day between 1-2 AM EST for completed
        events with registered students. Don't see an interaction for this
        event? Create interactions for all your qualified events now.
      </p>
    ) : (
      interactionCannotBeCreatedText(interactionCannotBeCreatedReasons)
    );

    return (
      <div className="flex flex-align-center">
        <button
          className={
            "btn btn-accent event-interaction-btn" +
            (!canManuallyCreateInteraction ? " disabled" : "")
          }
          disabled={!canManuallyCreateInteraction}
          onClick={async () => {
            await createInteractionsAction(chabadHouseId);
          }}
        >
          <i className="material-icons large-text">loop</i>
          Create Interaction
        </button>
        <span className="tooltip-container">
          <i className="material-icons link-text no-hover xl-text ml-8">info</i>
          <span className="event-interaction-btn-tooltip">{tooltipText}</span>
        </span>
      </div>
    );
  };

  const interactionCannotBeCreatedText = (conditionsToCreateInteraction) => (
    <>
      <p>An interaction has not been created for this event because:</p>
      <ul>
        {conditionsToCreateInteraction.map((c, i) => (
          <li className="event-interaction-not-generated-reason" key={i}>
            {c.text}
          </li>
        ))}
      </ul>
    </>
  );

  // effect to run after clicking the Create Interaction button
  const prevCreateInteractionsLoading = usePrevious(createInteractionsLoading);
  useEffect(() => {
    const refreshEventData = async () => {
      await refreshEvent();
    };

    if (prevCreateInteractionsLoading && !createInteractionsLoading) {
      if (successfullyCreatedInteractions) {
        toast.success(
          `${numCreatedInteractions} ${pluralizeText(
            "interaction",
            numCreatedInteractions,
          )} successfully created!`,
        );
        refreshEventData();
      } else {
        toast.error("Error creating interactions");
      }
    }
  }, [
    prevCreateInteractionsLoading,
    createInteractionsLoading,
    successfullyCreatedInteractions,
    numCreatedInteractions,
    refreshEvent,
  ]);

  const button = !!linkedInteractionId
    ? viewInteractionButton()
    : createInteractionButton();

  return (
    <>
      <div className="flex tablet-block mobile-block flex-align-top">
        <div className="mobile-ml-16 mobile-mr-16 mobile-mt-16">{button}</div>
        <ToastContainer
          position="bottom-left"
          theme="colored"
          autoClose={3000}
          hideProgressBar
        />
        {showInteractionModal && (
          <StudentInteractionModal
            close={() => setShowInteractionModal(false)}
            onSubmitInteraction={refreshEvent}
            show={showInteractionModal}
            interactionId={linkedInteractionId}
            isLaunchedFromEvent
            rsvpProgramScheduleId={rsvpProgramScheduleId}
          />
        )}
        {showConfirmChangesWillBeLostModal && (
          <ConfirmationModal
            show={true}
            message="Your changes have not been saved. Are you sure you want to leave this page?"
            confirm={() => {
              reinitializeSettings();
              setShowConfirmChangesWillBeLostModal(false);
              setShowInteractionModal(true);
            }}
            cancel={() => setShowConfirmChangesWillBeLostModal(false)}
          />
        )}
      </div>
    </>
  );
}

export default memo(EventInteractionButton);
