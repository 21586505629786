import React, { useState, useEffect } from "react";
import Modal from "../../../components/Modal";
import Select from "react-select";
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  Grid,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Divider,
  Typography,
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import ImageUpload from "../../../components/ImageUpload";
import { ToastContainer } from "react-toastify";
import { Navigation, PageURL, formatCurrency } from "./../../../lib";
import FadeOutErrorMessage from "../../../components/FadeOutErrorMessage";
import { rsvpMessageTypes } from "../../rsvp/myRsvp/RsvpMessageUtils";
import Loader from "../../../components/Loader";

export default class ComposeMessage extends React.PureComponent {
  componentDidMount() {
    this.loadPageData();
  }

  state = {
    submitted: false,
    segments: "",
    price: "",
    personalizePopoverPosition: "",
    scheduleSendPopoverPosition: "",
    scheduleSendDate: "",
    showPersonalizePopover: false,
    showScheduleSendPopover: false,
    showScheduleSendDate: false,
    allRecipients: [],
    showInsertEventDetailsModal: false,
    showInsertResourceDetailsModal: false,
    errorMessage: "",
    touched: true,
  };
  toggleScheduleSendPopover = (event) => {
    this.setState({
      scheduleSendPopoverPosition: event?.currentTarget?.parentElement,
      showScheduleSendPopover: !this.state.showScheduleSendPopover,
    });
  };
  openPersonalizePopover = (event) => {
    this.setState({
      personalizePopoverPosition: event.currentTarget,
      showPersonalizePopover: !this.state.showPersonalizePopover,
    });
  };
  insertPersonalization(variable) {
    const {
      actions: { updateSmsText },
      text,
    } = this.props;
    const currentText = text || "";
    const textBeforeCursorPosition = currentText.substring(
      0,
      this.textarea.selectionEnd,
    );
    const textAfterCursorPosition = currentText.substring(
      this.textarea.selectionEnd,
      currentText.length,
    );
    updateSmsText(
      textBeforeCursorPosition + variable + textAfterCursorPosition,
    );
    this.setState({
      showPersonalizePopover: false,
    });
  }

  loadPageData = async () => {
    const {
      actions: { updateSmsText },
      pageRoute: {
        query: { eventDetails, resourceDetails },
      },
      text,
    } = this.props;

    if (!text) {
      if (eventDetails) {
        const eventStatus = this.getEventResourceStatus();
        const eventInfo = await this.getEventDetails([parseInt(eventDetails)]);
        updateSmsText("Hi [first_name],\n" + eventStatus + eventInfo);
      } else if (resourceDetails) {
        const resourceStatus = this.getEventResourceStatus();
        const resourceInfo = await this.getResourceDetails([
          parseInt(resourceDetails),
        ]);
        updateSmsText("Hi [first_name],\n" + resourceStatus + resourceInfo);
      }
    }
  };

  getSmsAccountData = async () => {
    const {
      actions: { getSmsAccountData },
      enrollment: { data: { forChabadHouseID } = {} },
    } = this.props;
    return await getSmsAccountData(forChabadHouseID);
  };

  getEventResourceStatus = () => {
    const {
      pageRoute: { query: { messageType } = {} },
      text,
    } = this.props;

    let t = "";
    switch (messageType) {
      case rsvpMessageTypes.newEvent:
        t += "There's a new exciting event available at Chabad!";
        break;
      case rsvpMessageTypes.updatedEvent:
        t += "The event has been updated:";
        break;
      case rsvpMessageTypes.newResource:
        t += "There's a new resource available at Chabad!";
        break;
      case rsvpMessageTypes.updatedResource:
        t += "The resource has been updated:";
        break;
      default:
    }
    return (text ? text : "") + t + "\n";
  };

  getRsvpEventSchedules = async () => {
    const {
      actions: { getRsvpEventSchedules },
    } = this.props;
    const response = await getRsvpEventSchedules(1, 50, {
      export: true,
      occurrenceStatus: "upcoming",
      includeDetails: true,
      includeChabadHouseAddress: true,
    });
    return response.payload.results;
  };

  insertEventDetails = async (eventIds) => {
    const {
      actions: { updateSmsText },
    } = this.props;
    const text = await this.getEventDetails(eventIds);
    updateSmsText(text);
  };

  getEventDetails = async (eventIds) => {
    const { text } = this.props;

    const loadedEvents = await this.getRsvpEventSchedules();

    // TODO use String.fromCodePoint to insert emojis}
    const selectedEvents = loadedEvents.filter((ev) =>
      eventIds.includes(ev.eventScheduleID),
    );

    let t = selectedEvents?.map((e) => {
      let m = "";
      m += `${e.name} (Reply ${e.primarySmsCode} to RSVP)\n`;
      m += e.shortDescription + "\n";
      m += `Date: ${e.formattedDate}\n`;
      m += `Time: ${e.formattedEventScheduleTime}`;
      m +=
        e.location === "Other"
          ? `\nWhere: ${e.locationName ? e.locationName : ""}${
              e.address1
                ? e.locationName
                  ? " - " + e.address1
                  : e.address1
                : ""
            }${e.address2 ? " " + e.address2 : ""}`
          : e.location === "Virtual"
          ? "\nVirtually"
          : "\nWhere: " + e.locationDisplay;
      m += e.registrationFeeAmount
        ? `\nFee: ${formatCurrency(e.registrationFeeAmount, "USD")}`
        : "";
      return m;
    });
    const returnText = (text ? text + "\n\n" : "") + t?.join("\n\n");
    return returnText;
  };

  getRsvpResources = async () => {
    const {
      actions: { getRsvpResources },
    } = this.props;
    const response = await getRsvpResources(1, 50, {
      export: true,
      includeDetails: true,
    });

    return response.payload.results;
  };

  insertResourceDetails = async (resourceIds) => {
    const {
      actions: { updateSmsText },
    } = this.props;
    const text = await this.getResourceDetails(resourceIds);
    updateSmsText(text);
  };

  getResourceDetails = async (resourceIds) => {
    const { text } = this.props;

    const loadedResources = await this.getRsvpResources();

    // TODO use String.fromCodePoint to insert emojis
    const selectedResources = loadedResources?.filter((r) =>
      resourceIds.includes(r.resourceID),
    );
    let t = selectedResources?.map((r) => {
      let m = "";
      m += `${r.name} (Reply ${r.primarySmsCode} to let us know you are in!)\n`;
      m += r.shortDescription;
      m += r.registrationFeeAmount
        ? `\nFee: ${formatCurrency(r.registrationFeeAmount, "USD")}`
        : "";
      return m;
    });
    return (text ? text + "\n\n" : "") + t?.join("\n\n");
  };

  setShowInsertEventDetailsModal = (value) => {
    this.setState({ showInsertEventDetailsModal: value });
  };

  setShowInsertResourceDetailsModal = (value) => {
    this.setState({ showInsertResourceDetailsModal: value });
  };

  onClickCalculate = async () => {
    const msgData = await this.props.calculateMessageData();
    this.setState(msgData);
  };

  submitSms = async (sendDateTime = null) => {
    const {
      actions: { submitSms },
      calculateMessageData,
      enrollment: {
        data: { forChabadHouseID, timezoneID },
      },
      goToNextStep,
      page: { nextStep },
      pageRoute: { query: { thread: threadId } = {} },
      submitSms: { loading } = {},
      text,
      image,
    } = this.props;
    if (loading) {
      return;
    }
    if (!text && !image) {
      this.setState({
        errorMessage: "Please enter message text or upload an image to send.",
        touched: false,
      });
      return;
    }

    let { allRecipients, price, segments } = await calculateMessageData();
    const { balance } = await this.getSmsAccountData();

    if (price > balance) {
      goToNextStep(nextStep, false);
    } else {
      const payload = {
        studentIDs: allRecipients.map((s) => s.studentID),
        text,
        chabadHouseID: forChabadHouseID,
        timezoneID,
        segments: segments,
        threadId,
      };
      if (sendDateTime) {
        payload.timeToSend = sendDateTime;
      }
      if (image) {
        payload.media = [image];
      }
      await submitSms(payload);
      if (this.props.submitSms.success) {
        goToNextStep(nextStep, true);
      }
    }
  };
  scheduleSendOptions = [
    {
      name: "Tomorrow at 9:00 AM",
      value: moment()
        .add(1, "day")
        .hours(9)
        .minutes(0)
        .seconds(0)
        .milliseconds(0),
    },
    {
      name: "Monday at 9:00 AM",
      value: moment().day(8).hours(9).minutes(0).seconds(0).milliseconds(0),
    },
  ];

  render() {
    const {
      actions: { updateSmsText, updateSmsImage },
      eventSchedules: { loading } = {},
      pageRoute: { page, query, query: { edit } = {} },
      text,
      image,
    } = this.props;

    const {
      allRecipients,
      errorMessage,
      showInsertEventDetailsModal,
      showInsertResourceDetailsModal,
      showPersonalizePopover,
      showScheduleSendPopover,
      showScheduleSendDate,
      personalizePopoverPosition,
      price,
      segments,
      scheduleSendPopoverPosition,
      touched,
    } = this.state;

    const textLengthError = text?.length > 1600;

    return (
      <div className="page">
        {showInsertEventDetailsModal && (
          <InsertEventDetailsModal
            eventSchedulesloading={loading}
            getRsvpEventSchedules={this.getRsvpEventSchedules}
            showInsertEventDetailsModal
            setShowInsertEventDetailsModal={this.setShowInsertEventDetailsModal}
            insertEventDetails={this.insertEventDetails}
          />
        )}
        {showInsertResourceDetailsModal && (
          <InsertResourcesDetailsModal
            resourcesloading={loading}
            getRsvpResources={this.getRsvpResources}
            showInsertResourceDetailsModal
            setShowInsertResourceDetailsModal={
              this.setShowInsertResourceDetailsModal
            }
            insertResourceDetails={this.insertResourceDetails}
          />
        )}
        {/* we use toast in the MessageStatus component which is directing the user back here after they add credits */}
        <ToastContainer
          position="bottom-left"
          theme="colored"
          autoClose={3000}
          hideProgressBar
        />
        <div className="sms-wizard-container">
          <Typography variant="h6" className="mb-24">
            Compose Message
          </Typography>
        </div>
        <div>
          <div className="mt-24 sms-wizard-container">
            <div>
              <div className="mb-12 accent-text">
                Create a new message from scratch or insert{" "}
                <span
                  style={{ cursor: "pointer" }}
                  className="link-text"
                  onClick={() =>
                    this.setState({ showInsertEventDetailsModal: true })
                  }
                >
                  event
                </span>{" "}
                or{" "}
                <span
                  style={{ cursor: "pointer" }}
                  className="link-text"
                  onClick={() =>
                    this.setState({ showInsertResourceDetailsModal: true })
                  }
                >
                  resource
                </span>{" "}
                details
              </div>
              <div className="mb-12 accent-text">
                You can also{" "}
                <span
                  style={{ cursor: "pointer" }}
                  className="link-text"
                  onClick={this.openPersonalizePopover}
                >
                  insert personalization.
                </span>
                <Popover
                  open={showPersonalizePopover}
                  onClose={() => {
                    this.setState({
                      showPersonalizePopover: false,
                    });
                  }}
                  anchorEl={personalizePopoverPosition}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <List>
                    <ListItem
                      button
                      onClick={() => {
                        this.insertPersonalization("[student_first_name]");
                      }}
                    >
                      <ListItemText
                        className="accent-text"
                        primary="First name"
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        this.insertPersonalization("[student_full_name]");
                      }}
                    >
                      <ListItemText
                        className="accent-text"
                        primary="Full name"
                      />
                    </ListItem>
                  </List>
                </Popover>
              </div>
              <div className="flex flex-column">
                <div className="compose-message-section fw mb-16">
                  <div className="sms-text-area-label">
                    <label
                      className={`accent-text ${
                        textLengthError && "error-text"
                      }`}
                    >
                      Message text
                    </label>
                  </div>
                  <textarea
                    className={`custom-input ${textLengthError && "error"}`}
                    value={text}
                    ref={(el) => (this.textarea = el)}
                    placeholder="Type message here..."
                    onChange={(e) => {
                      updateSmsText(e.target.value);
                      this.setState({ touched: true });
                    }}
                  />
                  <div className="total-characters">
                    <p className={textLengthError && "error-text"}>
                      {text?.length ?? 0} / {1600}
                    </p>
                  </div>
                </div>
                <div
                  className={`accent-text small-text flex flex-justify-end ${
                    textLengthError && "error-text"
                  }`}
                >
                  Note that Twilio has a character limit of 1600 characters,
                  emojis count as 2 characters.
                </div>
              </div>
              <div>
                <div>
                  <Typography variant="h6">Add image</Typography>
                  <div className="add-image-text">
                    Attaching an image converts the SMS to a MMS message. MMS
                    messages will be charged MMS pricing.
                  </div>
                </div>
                <div className="upload-image-div">
                  <ImageUpload
                    errorStyle={{ position: "initial", margin: "20px" }}
                    label="Attach Image"
                    imageType="sms_img_attachment"
                    imageURL={image}
                    hidePlaceholder={true}
                    accept="image/jpeg, image/jpg, image/gif, image/png, image/bmp, image/tiff"
                    maxSize={5000000}
                    onChange={(n, v) => {
                      updateSmsImage(v);
                    }}
                  />
                  {!!image && (
                    <div
                      onClick={() => {
                        updateSmsImage(undefined);
                      }}
                      className="link-text uppercase-text relative"
                      style={{ left: "-60px" }}
                    >
                      Remove
                    </div>
                  )}
                </div>
              </div>
              <div className="compose-message-section compose-message-calculate-header">
                <Typography variant="h6" className="mr-24">
                  Message info
                </Typography>
                <Button
                  color="secondary"
                  size="small"
                  variant="contained"
                  onClick={this.onClickCalculate}
                >
                  Calculate
                </Button>
              </div>
              <div className="compose-message-calculate">
                <div className="compose-message-row mb-12 ">
                  <label className="accent-text mr-20">
                    Estimated message segments
                  </label>
                  <span className="compose-message-calculate-val">
                    {segments || ""}
                  </span>
                </div>
                <div className="compose-message-row mb-12 ">
                  <span className="accent-text mr-20">
                    Number of recipients
                  </span>
                  <span className="compose-message-calculate-val">
                    {allRecipients?.length || ""}
                  </span>
                </div>
                <div className="compose-message-row mb-12">
                  <label className="accent-text mr-16">Estimated cost</label>
                  <span className="compose-message-calculate-val">
                    {price && `$${price}`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-justify-space flex-align-center mt-24">
          <div>
            <Button
              disableElevation
              variant="contained"
              size="large"
              className="sms-button-secondary"
              onClick={() => {
                if (edit) {
                  Navigation.go(
                    PageURL.to(
                      page,
                      {
                        pageView: "reviewRecipients",
                      },
                      query,
                    ),
                  );
                } else {
                  Navigation.goBack();
                }
              }}
            >
              Back
            </Button>
          </div>
          <div>
            <div>
              <Grid container direction="column" alignItems="center">
                <Grid item xs={12}>
                  <ButtonGroup
                    variant="contained"
                    aria-label="split button"
                    color="primary"
                    disableElevation
                    className={textLengthError && "disabled-button"}
                    disabled={textLengthError}
                  >
                    <Button
                      disableElevation
                      className="sms-send-button"
                      onClick={() => {
                        this.submitSms();
                      }}
                    >
                      Send Message
                    </Button>
                    <Button
                      disableElevation
                      onClick={this.toggleScheduleSendPopover}
                      className="sms-send-button"
                      size="small"
                      aria-controls={
                        showScheduleSendPopover
                          ? "split-button-menu"
                          : undefined
                      }
                      aria-expanded={
                        showScheduleSendPopover ? "true" : undefined
                      }
                      aria-haspopup="menu"
                    >
                      <i className="material-icons">arrow_drop_down</i>
                    </Button>
                  </ButtonGroup>
                  <Popper
                    open={showScheduleSendPopover}
                    anchorEl={scheduleSendPopoverPosition}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener
                            onClickAway={() => {
                              if (!showScheduleSendDate)
                                this.toggleScheduleSendPopover();
                            }}
                          >
                            <MenuList id="split-button-menu">
                              <MenuItem disabled={true}>Send later</MenuItem>
                              <Divider />
                              {this.scheduleSendOptions.map((option, index) => (
                                <MenuItem
                                  key={option.name}
                                  onClick={(event) => {
                                    this.submitSms(
                                      option.value.format(
                                        "YYYY-MM-DDTHH:mm:ss",
                                      ),
                                    );
                                    this.toggleScheduleSendPopover();
                                  }}
                                >
                                  {option.name}
                                </MenuItem>
                              ))}
                              <MenuItem
                                style={{ color: "#2774AE" }}
                                onClick={() => {
                                  this.setState({
                                    showScheduleSendDate: true,
                                  });
                                }}
                              >
                                <span>Choose custom time</span>
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Grid>
              </Grid>
            </div>
            {!touched && (
              <FadeOutErrorMessage
                message={errorMessage}
                onTimeout={() => {
                  this.setState({ errorMessage: "" });
                }}
              />
            )}
          </div>
        </div>
        <div style={{ display: "none" }}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider
              theme={createTheme({
                palette: {
                  primary: { main: "#2774ae" },
                },
              })}
            >
              <DateTimePicker
                open={showScheduleSendDate}
                onChange={(d) => {
                  this.setState({
                    showScheduleSendDate: false,
                    scheduleSendDate: d,
                  });
                  this.submitSms(d.format("YYYY-MM-DDTHH:mm:ss"));
                }}
                onClose={() => {
                  this.setState({
                    showScheduleSendDate: false,
                  });
                }}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </div>
      </div>
    );
  }
}

function InsertEventDetailsModal(props) {
  const {
    eventSchedulesloading,
    getRsvpEventSchedules,
    insertEventDetails,
    showInsertEventDetailsModal,
    setShowInsertEventDetailsModal,
  } = props;

  const [messageEventsSelected, setMessageEventsSelected] = useState(false);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getEvents();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getEvents = async () => {
    const events = await getRsvpEventSchedules();
    setEvents(events);
    setLoading(false);
  };

  return (
    <Modal show={showInsertEventDetailsModal}>
      <div className="student-details-modal-container modal-container full-page-mobile-modal-container">
        <div className="sms-compose-mes-modal">
          {loading ? (
            <Loader />
          ) : (
            <div>
              <div className="large-text">Insert Event Details</div>

              <div className="mt-20">
                <span className="accent-text">Choose events</span>
                <div className="mt-10">
                  <Select
                    placeholder=""
                    isMulti={true}
                    options={events?.map((e, i) => ({
                      key: i,
                      value: i,
                      label: `${e.name} - ${e.formattedDate}`,
                      eventScheduleID: e.eventScheduleID,
                    }))}
                    name={"recipients"}
                    value={messageEventsSelected}
                    onChange={(v) => {
                      setMessageEventsSelected(v);
                    }}
                  />
                </div>
              </div>
              <div className="buttons-section">
                <button
                  className="btn btn-light mr-16"
                  onClick={() => {
                    setShowInsertEventDetailsModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    insertEventDetails(
                      messageEventsSelected.map((e) => e.eventScheduleID),
                    );
                    setShowInsertEventDetailsModal(false);
                  }}
                  className="btn btn-accent"
                >
                  {eventSchedulesloading ? "Loading..." : "Insert"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

function InsertResourcesDetailsModal(props) {
  const {
    resourcesloading,
    getRsvpResources,
    insertResourceDetails,
    showInsertResourceDetailsModal,
    setShowInsertResourceDetailsModal,
  } = props;

  const [messageResourcesSelected, setMessageResourcesSelected] =
    useState(false);
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getResources();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getResources = async () => {
    const resources = await getRsvpResources();
    setResources(resources);
    setLoading(false);
  };

  return (
    <Modal show={showInsertResourceDetailsModal}>
      <div className="student-details-modal-container modal-container full-page-mobile-modal-container">
        <div className="sms-compose-mes-modal">
          {loading ? (
            <Loader />
          ) : (
            <div>
              <div className="large-text">Insert Resource Details</div>

              <div className="mt-20">
                <span className="accent-text">Choose resources</span>
                <div className="mt-10">
                  <Select
                    placeholder=""
                    isMulti={true}
                    options={resources?.map((r, i) => ({
                      key: i,
                      value: i,
                      label: `${r.name}`,
                      resourceID: r.resourceID,
                    }))}
                    name={"recipients"}
                    value={messageResourcesSelected}
                    onChange={(v) => {
                      setMessageResourcesSelected(v);
                    }}
                  />
                </div>
              </div>
              <div className="buttons-section">
                <button
                  className="btn btn-light mr-16"
                  onClick={() => {
                    setShowInsertResourceDetailsModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    insertResourceDetails(
                      messageResourcesSelected.map((r) => r.resourceID),
                    );
                    setShowInsertResourceDetailsModal(false);
                  }}
                  className="btn btn-accent"
                >
                  {resourcesloading ? "Loading..." : "Insert"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
