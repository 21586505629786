import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SmsActions } from "../../state/sms/actions";
import { RsvpActions } from "../../state/rsvp/actions";
import { StudentsActions } from "../../state/students/actions";
import Wizard from "./Wizard";

export default connect(
  function mapState(state) {
    return {
      sys: state.sys,
      ui: state.ui,
      rsvp: { ...state.rsvp.enrollment?.data },
      ...state.sms,
    };
  },
  (dispatch) => {
    return {
      actions: bindActionCreators(
        { ...SmsActions, ...RsvpActions, ...StudentsActions },
        dispatch,
      ),
    };
  },
)(Wizard);
