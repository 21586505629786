import React from "react";

import ChabadCommunityInvolvementDemographics from "./ChabadCommunityInvolvementDemographics";
import ChildhoodJewishInvolvementDemographics from "./ChildhooodJewishInvolvementDemographics";
import CurrentJewishInvolvementDemographics from "./CurrentJewishInvolvementDemographics";
import GeneralDemographics from "./GeneralDemographics";
import OtherDemographics from "./OtherDemographics";
import ProgramOrgInvolvementDemographics from "./ProgramOrgInvolvementDemographics";
import StudentGroupDemographics from "./StudentGroupsDemographics";

export default class StudentDemographics extends React.PureComponent {
  onChangeDemographics = (name, value) => {
    this.props.onChangeProfile(`demographics.[${name}]`, value);
  };

  onChangeDemographicsEvt = (e) => {
    this.props.onChangeProfile(
      `demographics.[${e.target.name}]`,
      e.target.value,
    );
  };

  render() {
    const {
      profile: {
        demographics = {},
        demographics: { chabadHouseTags } = {},
        person: { gender: studentGender } = {},
      },
      studentGroupOptions,
      sys: {
        halachicJewishStatuses = [],
        jewishnessStatuses = [],
        interests = [],
        jewishCommunityTypes = [],
        jewishFamilyAffiliationTypes = [],
        jewishObservanceStatuses = [],
        jewishRelationshipStatuses = [],
        observanceStatuses = [],
        participationStatuses = [],
        pastShabbosHolidayObservanceStatuses = [],
        personalities = [],
        regions = [],
        shabbosHolidayObservanceStatuses = [],
        studentOrgInvolvementTypes = [],
        studentProgramParticipationTypes = [],
        studentReferralSources = [],
      },
    } = this.props;

    return (
      <form className="profile-form">
        <div className="container">
          <p className="small-text accent-text text-center mb-16">
            Review and edit student’s demographics information. This information
            is not visible to students.
          </p>
          <StudentGroupDemographics
            onChangeStudentGroups={this.onChangeDemographics}
            selectedStudentGroups={chabadHouseTags}
            studentGroupOptions={studentGroupOptions}
          />
          <GeneralDemographics
            demographics={demographics}
            halachicJewishStatuses={halachicJewishStatuses}
            jewishCommunityTypes={jewishCommunityTypes}
            jewishFamilyAffiliationTypes={jewishFamilyAffiliationTypes}
            jewishnessStatuses={jewishnessStatuses}
            jewishRelationshipStatuses={jewishRelationshipStatuses}
            onChange={this.onChangeDemographics}
            onChangeEvt={this.onChangeDemographicsEvt}
            regions={regions}
          />
          <ChildhoodJewishInvolvementDemographics
            demographics={demographics}
            observanceStatuses={observanceStatuses}
            jewishObservanceStatuses={jewishObservanceStatuses}
            onChange={this.onChangeDemographics}
            participationStatuses={participationStatuses}
            pastShabbosHolidayObservanceStatuses={
              pastShabbosHolidayObservanceStatuses
            }
            studentGender={studentGender}
          />
          <CurrentJewishInvolvementDemographics
            demographics={demographics}
            jewishFamilyAffiliationTypes={jewishFamilyAffiliationTypes}
            observanceStatuses={observanceStatuses}
            jewishObservanceStatuses={jewishObservanceStatuses}
            onChange={this.onChangeDemographics}
            participationStatuses={participationStatuses}
            shabbosHolidayObservanceStatuses={shabbosHolidayObservanceStatuses}
            studentGender={studentGender}
          />
          <ChabadCommunityInvolvementDemographics
            demographics={demographics}
            onChange={this.onChangeDemographics}
            participationStatuses={participationStatuses}
            studentReferralSources={studentReferralSources}
          />
          <ProgramOrgInvolvementDemographics
            demographics={demographics}
            onChange={this.onChangeDemographics}
            studentOrgInvolvementTypes={studentOrgInvolvementTypes}
            studentProgramParticipationTypes={studentProgramParticipationTypes}
          />
          <OtherDemographics
            demographics={demographics}
            interests={interests}
            onChange={this.onChangeDemographics}
            onChangeEvt={this.onChangeDemographicsEvt}
            personalities={personalities}
          />
        </div>
      </form>
    );
  }
}
