import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ContactActions } from "../../../state/contact/actions";
import { ChabadHousesActions } from "../../../state/chabadHouses/actions";
import DirectoryDetails from "./DirectoryDetails";
import { SchoolsActions } from "../../../state/schools/actions";

export default connect(
  function mapState(state) {
    return {
      chabadHousesObject: state.chabadHouses,
      contact: state.contact,
      schoolsObject: state.schools,
      studyAreas: state.sys.studyAreas,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          getChabadHouses: ChabadHousesActions.getChabadHouses,
          getContact: ContactActions.getContact,
          getSchools: SchoolsActions.getSchools,
        },
        dispatch,
      ),
    };
  },
)(DirectoryDetails);
