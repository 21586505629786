import React from "react";

const maxConfirmationMessageLength = 250;

export default function ReservationConfirmationMessageSettings({
  overrideDefaultConfirmationMessage,
  customConfirmationMessage,
  onChangeState,
  onChangeSettingsEvt,
  onChangeSettings,
}) {
  return (
    <div className="program-form-section">
      <div className="mb-24">
        <p className="medium-text fw-700 mb-16">
          Reservation Confirmation Message
        </p>
        <div className="radio-button-container mb-16 mt-24">
          <input
            type="radio"
            id="default"
            name="overrideDefaultConfirmationMessage"
            value={false}
            checked={overrideDefaultConfirmationMessage === "false"}
            onChange={(e) => {
              onChangeState(e.target.name, e.target.value);
              onChangeSettings("customConfirmationMessage", "");
            }}
          />
          <label className="fs-14" htmlFor="default">
            Use the default confirmation message
          </label>
        </div>
        <div className="relative">
          <div className="radio-button-container mb-16">
            <input
              type="radio"
              id="custom"
              name="overrideDefaultConfirmationMessage"
              value={true}
              checked={overrideDefaultConfirmationMessage === "true"}
              onChange={(e) => onChangeState(e.target.name, e.target.value)}
            />
            <label className="fs-14" htmlFor="custom">
              Create custom confirmation message
            </label>
          </div>
          <textarea
            className="custom-input custom-message ml-32"
            maxLength={maxConfirmationMessageLength}
            disabled={overrideDefaultConfirmationMessage === "false"}
            value={customConfirmationMessage}
            placeholder="Type custom confirmation message"
            name="customConfirmationMessage"
            onChange={onChangeSettingsEvt}
          />
          {overrideDefaultConfirmationMessage === "true" && (
            <p className="total-characters">
              {customConfirmationMessage?.length ?? 0} /{" "}
              {maxConfirmationMessageLength}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
