import { FieldErrors, UseFormReturn } from "react-hook-form";
import { flattenObj } from "../../../lib";

export type FormFieldProps = {
  name: string;
};

export const getSetFieldValueOptions = (form: UseFormReturn<any>) => {
  return {
    shouldValidate: form.formState.isSubmitted,
    shouldDirty: true,
    shouldTouch: true,
  };
};

export const scrollToFormErrors = (errors: FieldErrors<any>) => {
  setTimeout(() => {
    if (!Object.keys(errors).length) return;

    const flattenedErrors = flattenObj(errors, (v) => v.message);
    const errorElementsQuerySelector = `#${Object.keys(flattenedErrors)
      .join(", #")
      .replaceAll(".", "\\.")}`;
    const errorElements = document.querySelectorAll(errorElementsQuerySelector);

    if (errorElements.length)
      errorElements[0].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  }, 0);
};
