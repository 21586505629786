import React from "react";
import RsvpItemNotFound from "./RsvpItemNotFound";
import RsvpItemTabs from "./RsvpItemTabs";
import ErrorDisplay from "../../../../../components/ErrorDisplay";
import Loader from "../../../../../components/Loader";
import RsvpBreadcrumbsHeader from "../../RsvpBreadcrumbsHeader";
import { IsApiErrorStatus, Navigation, PageURL } from "../../../../../lib";
import { Add } from "@material-ui/icons";
import { Box, Button } from "@material-ui/core";
import RsvpItemTotals from "./RsvpItemTotals";

export default class ManageRsvpItemContainer extends React.PureComponent {
  getRsvpItem = async (isRefresh = false) => {
    const {
      getRsvpItem,
      pageRoute: {
        params: { rsvpItemId },
      },
    } = this.props;

    if (rsvpItemId) {
      await getRsvpItem(rsvpItemId, isRefresh);
    }
  };

  componentDidMount() {
    //scroll to top of page on mount for when navigating from low scroll position within event schedules list
    if (window.scrollY > 0) {
      window.scrollTo(0, 0);
    }

    this.getRsvpItem();
  }

  componentWillUnmount() {
    this.props.clearRsvpItem();
  }

  componentDidUpdate(prevProps) {
    const {
      pageRoute: {
        params: { rsvpItemId },
      },
    } = this.props;

    if (prevProps.pageRoute.params.rsvpItemId !== rsvpItemId) {
      this.getRsvpItem();
    }
  }

  render() {
    const {
      rsvpType,
      tabs,
      getRsvpItemsBasic,
      rsvpItem: { data: rsvpItem, error, errorMessage, loading },
      rsvpItemsBasic,
      getItemFullName,
      DropdownComponent,
      getSmsFilterQueryParam,
      pageRoute,
      isShliach,
    } = this.props;

    const {
      query: { tab },
    } = pageRoute;

    const currentTab = tabs.find((t) => t.id === tab);

    const rsvpItemFullName = getItemFullName(rsvpItem);
    const smsWizardQuery = getSmsFilterQueryParam(pageRoute.params.rsvpItemId);
    return (
      <div className="full-width">
        <Box className="flex flex-justify-space flex-align-center mobile-flex-column mobile-flex-align-top mb-8">
          <RsvpBreadcrumbsHeader
            rsvpItemName={rsvpItemFullName}
            tabs={tabs}
            pageRoute={pageRoute}
          />

          {isShliach && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                Navigation.go(PageURL.to("/sms/wizard", null, smsWizardQuery));
              }}
            >
              <Add /> Sms message
            </Button>
          )}
        </Box>

        <Box className="flex flex-justify-space flex-align-center mobile-flex-column mobile-flex-align-top">
          <div className="program-dropdown-select">
            <DropdownComponent
              getRsvpItems={getRsvpItemsBasic}
              rsvpItems={rsvpItemsBasic}
              pageRoute={pageRoute}
            />
          </div>
          {rsvpItem && <RsvpItemTotals rsvpItem={rsvpItem} />}
        </Box>

        <div className="mr-24 tablet-mr-0 mobile-mr-0">
          {rsvpItem && (
            <RsvpItemTabs
              getSmsFilterQueryParam={getSmsFilterQueryParam}
              tabs={tabs}
              pageRoute={pageRoute}
            />
          )}
        </div>

        <div className="flex tablet-block flex-align-top">
          {loading ? (
            <div className="full-page-loader full-width">
              <Loader />
            </div>
          ) : error ? (
            IsApiErrorStatus(error, 404) ? (
              <RsvpItemNotFound rsvpType={rsvpType} />
            ) : (
              <ErrorDisplay
                errorMessage={
                  errorMessage ||
                  `Something went wrong and we could not retrieve ${rsvpType} information. Please try again.`
                }
              />
            )
          ) : rsvpItem && currentTab ? (
            <div className="program-management-content-card">
              {React.createElement(currentTab.component, {
                ...this.props,
                rsvpItemFullName,
                refreshRsvpItem: () => this.getRsvpItem(true),
              })}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
