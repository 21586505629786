import React, { useState, useEffect, useCallback } from "react";
import { Typography } from "@material-ui/core";
import { useMobile } from "../../../themes";
import { Navigation, useDebounce, PageURL } from "../../../lib";
import { flattenArray, formatCurrency } from "../../../lib/utils";
import ExportCSVButton from "../../../components/ExportCSVButton";
import DonorsTable from "./DonorsTable";

const Donors = (props) => {
  const {
    pageRoute: {
      params,
      query: { p = 1, r = 10, q = "", so = "", tf = "", all = false },
      location: { pathname },
    },
    pageRoute,
    sys,
    getRaffleDonors,
    getRaffleDonorDetails,
    raffle,
  } = props;
  const isMobileView = useMobile();
  const [searchInputValue, setSearchInputValue] = useState(
    q ? decodeURIComponent(q) : "",
  );
  const [searchFocused, setSearchFocused] = useState(false);

  const getDonors = useCallback(
    (page, resultsView, searchTerm) => {
      //page & resultsView sent with loadData from paginatedTable must override pageRoute query params, because data is fetched prior to params being refreshed
      getRaffleDonors(
        page || p,
        resultsView || r,
        searchTerm || q,
        so && decodeURIComponent(so),
        false,
        !!all,
        tf && decodeURIComponent(tf),
      );
    },
    [getRaffleDonors, p, q, r, so, all, tf],
  );
  const toggleShowAllYears = useCallback(
    (value) => {
      Navigation.redirect(
        PageURL.to(pathname, params, {
          ...pageRoute?.query,
          p: 1,
          all: value,
        }),
      );
    },
    [pageRoute.query, params, pathname],
  );
  const search = useCallback(() => {
    Navigation.redirect(
      PageURL.to(pathname, params, {
        ...pageRoute?.query,
        p: 1,
        q: searchInputValue,
      }),
    );
  }, [pathname, searchInputValue, pageRoute.query, params]);

  const debouncedSearch = useDebounce(search);

  const onSearch = (debounced = false) => {
    if (debounced) {
      debouncedSearch();
    } else {
      search();
    }
  };

  const getExportHeaders = useCallback((data) => {
    let years = [];
    data &&
      data.map((obj) => {
        obj.donationsByYear.forEach((donation) => {
          if (!years.includes(donation.gregorianYear)) {
            years.push(donation.gregorianYear);
          }
        });
        return years;
      });
    years = years.sort();
    return [
      {
        label: "First Name",
        value: "donorFirstName",
      },
      {
        label: "Last Name",
        value: "donorLastName",
      },
      {
        label: "Email",
        value: "donorEmail",
      },
      {
        label: "Phone",
        value: "donorPhone",
      },
      {
        label: "Address 1",
        value: "billingAddress1",
      },
      {
        label: "Address 2",
        value: "billingAddress2",
      },
      {
        label: "City",
        value: "billingCity",
      },
      {
        label: "State",
        value: "billingState",
      },
      {
        label: "Country",
        value: "billingCountry",
      },
      {
        label: "Zip",
        value: "billingZip",
      },
      ...flattenArray(
        years.map((year) => [
          {
            key: `${year}-ticket-qty`,
            label: `${year} Ticket Qty`,
            value: ({ donationsByYear }) => {
              const qty = donationsByYear
                .filter((d) => d.gregorianYear === year)
                .map((d) => d.quantity)
                .reduce((a, b) => a + b, 0);
              return qty > 0 ? qty : "";
            },
          },
          {
            key: `${year}-dollar-value`,
            label: `${year} Dollar Value`,
            value: ({ donationsByYear }) => {
              const total = donationsByYear
                .filter((d) => d.gregorianYear === year)
                .map((d) => d.total)
                .reduce((a, b) => a + b, 0);
              return total > 0 ? formatCurrency(total) : "";
            },
          },
        ]),
      ),
    ];
  }, []);

  const getDonorsForExport = useCallback(async () => {
    const results = await getRaffleDonors(null, null, q, so, true);
    return results;
  }, [getRaffleDonors, q, so]);

  useEffect(() => {
    getDonors();
  }, [getDonors]);

  return (
    <>
      <Typography variant="h5" className="mb-8">
        All Donors
      </Typography>
      <Typography variant="body2" style={{ color: "#747579" }}>
        Donors are grouped based on identical{" "}
        <span className="fw-700">email address</span> from previous year
        donations
      </Typography>
      <Typography variant="body2" style={{ color: "#747579" }}>
        Typos or incorrect data may prevent the donor from being grouped to same
        donor from previous years - use the search box to help identify such
        instances
      </Typography>

      <div className="flex flex-justify-space flex-align-center mb-16 mobile-mb-0 mt-24 mobile-block">
        <div
          className={`search-input ${
            searchFocused ? " search-input-focused" : ""
          }`}
        >
          <input
            type="text"
            placeholder="Search Donor Name"
            onFocus={() => setSearchFocused(!searchFocused)}
            onBlur={() => setSearchFocused(!searchFocused)}
            onChange={(e) => {
              setSearchInputValue(e.target.value);
              onSearch(true);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") onSearch();
            }}
            value={searchInputValue}
          />
          <i
            className="material-icons accent-text-secondary pointer"
            onClick={onSearch}
          >
            search
          </i>
        </div>
        <div className="flex flex-align-center flex-justify-space">
          <div className="custom-checkbox-container new-order-checkbox">
            <input
              id="show-all-years-checkbox"
              className="custom-checkbox"
              name="showAllYears"
              onChange={(e) => toggleShowAllYears(e.target.checked || "")}
              type="checkbox"
              checked={!!all}
            />
            <label
              htmlFor="show-all-years-checkbox"
              className="flex flex-align-center"
            >
              <span style={{ marginTop: "2px" }}>View all years</span>
            </label>
          </div>
          <div style={{ width: 120 }}>
            <ExportCSVButton
              className="btn btn-accent ml-16 mobile-ml-0 text-center"
              fileName="Raffle Donors"
              getExportData={getDonorsForExport}
              getHeaders={getExportHeaders}
              title="CSV Export"
            />
          </div>
        </div>
      </div>
      <DonorsTable
        getDonors={getDonors}
        getDonorDetails={getRaffleDonorDetails}
        raffle={raffle}
        pageRoute={pageRoute}
        sys={sys}
        isMobileView={isMobileView}
      />
    </>
  );
};
export default React.memo(Donors);
