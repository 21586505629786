import { ProfileActions } from "./actions";

export const ProfileState = {
  name: "profile",
  persist: false,
  defaults: {
    loading: false,
    profile: {
      shliach: {
        person: {
          profileImageURL: "",
        },
      },
    },
  },
  handlers: {
    [ProfileActions.SET_PROFILE_LOADING]: function setProfileLoading(
      state,
      action,
    ) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [ProfileActions.SET_PROFILE_CHABAD_HOUSE_LOADING]:
      function setProfileChabadHouseLoading(state, action) {
        return {
          ...state,
          chabadHouseData: {
            loading: !!action.payload,
          },
        };
      },
    [ProfileActions.SET_PROFILE_CAMPUS_LOADING]:
      function setProfileCampusLoading(state, action) {
        return {
          ...state,
          campusData: {
            loading: !!action.payload,
          },
        };
      },
    [ProfileActions.UPDATE_PROFILE]: function updateProfile(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    [ProfileActions.SUBMIT_PROFILE]: (state, action) => {
      return {
        ...state,
        submitted: action.success,
        submitError: action.error,
      };
    },
  },
};
