import React from "react";
import { Navigation, PageURL } from "../../../lib";

export default class LamplightersScheduleTabs extends React.PureComponent {
  toTab = (tab) => {
    const {
      pageRoute: { page, params },
    } = this.props;
    Navigation.go(PageURL.to(page, params, { tab }));
  };

  render() {
    const { currentTab, tabs } = this.props;

    return (
      <div className="card trip-sidebar-card trip-sidebar-menu-card">
        <ul>
          {tabs.map((tab, index) => (
            <li key={index}>
              <p
                className={`trip-link ${
                  currentTab.id === tab.id ? "active-trip-link" : ""
                } ${index > 0 && index < tabs.length - 1 ? "mt-24 mb-24" : ""}`}
                name={tab.id}
                onClick={() => this.toTab(tab.id)}
              >
                {tab.title}
              </p>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
