import React from "react";

const Loader = () => (
  <svg id="loading" viewBox="0 0 100 80">
    <g transform="translate(50 0) scale(0.707 0.707) rotate(45 0 0)">
      <rect
        className="rect"
        id="rect1"
        x="0"
        y="0"
        width="30"
        height="30"
        rx="1"
        ry="1"
        fill="#007AB5"
      />
      <rect
        className="rect"
        id="rect2"
        x="0"
        y="0"
        width="30"
        height="30"
        rx="1"
        ry="1"
        fill="#1C96D2"
      />
      <rect
        className="rect"
        id="rect3"
        x="0"
        y="0"
        width="30"
        height="30"
        rx="1"
        ry="1"
        fill="#2774AE"
      />
      <rect
        className="rect"
        id="rect4"
        x="0"
        y="0"
        width="30"
        height="30"
        rx="1"
        ry="1"
        fill="#81C9EC"
      />
      <rect
        className="rect"
        id="rect5"
        x="0"
        y="0"
        width="30"
        height="30"
        rx="1"
        ry="1"
        fill="#9BD3EF"
      />
      <rect
        className="rect"
        id="rect6"
        x="0"
        y="0"
        width="30"
        height="30"
        rx="1"
        ry="1"
        fill="#C4E4F3"
      />
      <rect
        className="rect"
        id="rect7"
        x="0"
        y="0"
        width="30"
        height="30"
        rx="1"
        ry="1"
        fill="#DEEEF6"
      />
    </g>
  </svg>
);

export default Loader;
