import React, { memo } from "react";
import { useSelector } from "react-redux";
import { AuthSelectors } from "../../../../../state";
import { PageLink } from "../../../../../lib";

function NoMyCourses(props) {
  const {
    actionLink,
    eduProgramName,
    pageRoute: { page, params },
  } = props;

  const canScheduleCourses = useSelector(AuthSelectors.canManageEduSchedule);

  const getActionLink = () => {
    if (actionLink) {
      return actionLink;
    }

    if (canScheduleCourses) {
      return (
        <PageLink
          className="btn btn-accent"
          to={page}
          params={params}
          query={{ type: "all" }}
          style={{ margin: "auto", width: "200px" }}
        >
          View all courses
        </PageLink>
      );
    }

    return null;
  };

  return (
    <div className="text-center">
      <img src="/images/edu-courses.svg" alt="" />
      <p className="mt-32 mb-16 fw-700 large-text">
        Hmmm, it seems like you don't have any courses scheduled yet
      </p>
      <p className="mb-32 medium-text">
        Schedule some courses to get your {eduProgramName} up and running
      </p>
      {getActionLink()}
    </div>
  );
}

export default memo(NoMyCourses);
