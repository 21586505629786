import React, { useCallback, useEffect } from "react";
import Loader from "../../../../components/Loader";
import { formatNumber } from "../../../../lib";
import ApproveReject from "../../../edu/components/ApproveRejects";
import moment from "moment";

export default function StudentLatestCourse(props) {
  const {
    onApproveRejectStudent,
    getStudentCourses,
    studentEnrollment,
    studentCourses: { data: { results = [] } = {}, errorMessage, loading },
  } = props;

  useEffect(() => {
    getLatestCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLatestCourse = useCallback(() => {
    getStudentCourses(studentEnrollment.eduStudentEnrollmentID, 1, 1);
  }, [getStudentCourses, studentEnrollment.eduStudentEnrollmentID]);

  const latestCourse = results[0];
  const {
    courseScheduleName,
    didCompleteSurvey,
    firstClassDate,
    lastClassDate,
    numOfClassesAttended,
    numOfClassesScheduled,
    studentCourseScheduleEnrollmentStatus,
  } = latestCourse || {};

  return (
    <div className="full-width">
      <p className="fw-900 medium-text mb-16">Course History</p>
      {loading ? (
        <Loader />
      ) : errorMessage ? (
        <div className="full-page-error-text error-text">
          <img src="/images/error.svg" alt="error robot" height="240" />
          <p>
            {errorMessage ||
              "Sorry, something went wrong and course details could not be retrieved."}
          </p>
        </div>
      ) : (
        latestCourse && (
          <div
            className="two-column-grid"
            style={{ gridTemplateColumns: "160px 1fr", gridGap: 16 }}
          >
            <p className="fw-700">Course</p>
            <p>{courseScheduleName}</p>
            <p className="fw-700">Start & End Date</p>
            <p>
              {!firstClassDate
                ? "TBD"
                : `${moment(firstClassDate).format("M/D/YY")} - ${moment(
                    lastClassDate,
                  ).format("M/D/YY")}`}
            </p>
            <p className="fw-700">Classes Completed</p>
            <p>
              {formatNumber(numOfClassesAttended)}/
              {formatNumber(numOfClassesScheduled)}
            </p>
            <p className="fw-700">Review Completed</p>
            <p>{didCompleteSurvey ? "Yes" : "No"}</p>
            <p className="fw-700">Status</p>
            <p>
              {studentCourseScheduleEnrollmentStatus === "Pending" ? (
                <ApproveReject
                  data={latestCourse}
                  onApproveRejectStudent={onApproveRejectStudent}
                />
              ) : (
                <>
                  {studentEnrollment.status === "Rejected"
                    ? "Rejected"
                    : studentCourseScheduleEnrollmentStatus}
                </>
              )}
            </p>
          </div>
        )
      )}
    </div>
  );
}
