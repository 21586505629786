import React from "react";
import PaginatedList from "../../../../../components/PaginatedList";
import { PageURL, Navigation } from "../../../../../lib";

export default class RsvpItemsList extends React.PureComponent {
  getRsvpRecords = (pg) => {
    const {
      getRsvpItems,
      pageRoute: { query, page, params },
    } = this.props;

    const url = PageURL.to(page, params, {
      ...query,
      p: pg > 1 ? pg : undefined,
    });
    Navigation.redirect(url);

    setTimeout(() => getRsvpItems(pg), 0);
  };

  render() {
    const {
      rsvpType,
      rsvpItems: {
        data: { results = [], numberOfRows = 0 } = {},
        errorMessage,
        loading,
        success,
      },
      noRecordsDisplay,
      renderCardComponent,
      gridClass,
      pageRoute,
      resultsView,
    } = this.props;

    const {
      query: { p: page = 1 },
    } = pageRoute;

    return (
      <PaginatedList
        className={`rsvp-events-list ${gridClass} flex flex-justify-center`}
        errorMessage={errorMessage}
        loadData={this.getRsvpRecords}
        loading={loading}
        name={rsvpType}
        noRecordsDisplay={noRecordsDisplay}
        page={page}
        paginationClassName="events-list-pagination"
        records={results}
        renderRow={(es, esIndex) => renderCardComponent(es, esIndex)}
        resultsView={resultsView}
        success={success}
        totalCount={numberOfRows}
      />
    );
  }
}
