import React from "react";
import { Box } from "@material-ui/core";

import { withAppInsights, PageLink, Navigation } from "../lib";
import Pages from "../pages";
import { LayoutProps } from "../state/types";
import SimpleAppBar from "./SimpleAppBar";
import { useStyles } from "./InternsAppBar.styles";

function InternsAppBar({ children }: LayoutProps) {
  const classes = useStyles();

  return (
    <Box className={classes.layoutContainer}>
      <SimpleAppBar />
      <Box className={classes.contentContainer}>
        {Navigation.location.pathname !== Pages.main.home.path && (
          <PageLink
            to={Pages.main.home}
            className="uppercase-text link-text underline-text"
          >
            Home
          </PageLink>
        )}
        {children}
      </Box>
    </Box>
  );
}

export default withAppInsights(InternsAppBar);
