import React, { useCallback } from "react";
import { Select, MenuItem } from "@material-ui/core";
import { REMINDER_MINUTE_VALUE_LIST } from "../../RsvpHelpers";

function getMenuItems() {
  return REMINDER_MINUTE_VALUE_LIST.map((o) => (
    <MenuItem key={o.value} value={o.value}>
      {o.label}
    </MenuItem>
  ));
}

const selectStyle = { width: 90 };

function ReminderTimeSelect({
  isRegistered,
  fieldValue,
  fieldName,
  setReminderMinutes,
  disabled,
}) {
  const onChangeReminderMinutes = useCallback(
    (_, object) => setReminderMinutes(fieldName, object.props.value),
    [fieldName, setReminderMinutes],
  );

  return (
    <div>
      <span>
        Remind all {isRegistered ? "registered" : "unregistered"} students
      </span>
      <span className="mr-8 ml-8">
        <Select
          value={fieldValue}
          style={selectStyle}
          onChange={onChangeReminderMinutes}
          disabled={disabled}
        >
          {getMenuItems()}
        </Select>
      </span>
      <span> before start time</span>
    </div>
  );
}
export default React.memo(ReminderTimeSelect);
