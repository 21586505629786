import * as signalR from "@microsoft/signalr";
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";
import axios from "axios";

const { REACT_APP_SIGNALR_API_URL } = process.env;

let messageConnection;
const apiSignal = axios.CancelToken.source();
export async function connectToSignalRMessages(
  onConnectionSuccess,
  onMessageReceived,
) {
  messageConnection = new signalR.HubConnectionBuilder()
    .withUrl(REACT_APP_SIGNALR_API_URL, {
      accessTokenFactory: () => apiSignal.token,
    })
    .withAutomaticReconnect()
    .withHubProtocol(new MessagePackHubProtocol())
    .build();

  messageConnection.on("SendSignalRMessage", function (data) {
    onMessageReceived(data);
  });

  try {
    await messageConnection.start();
    console.log("Connected");
    onConnectionSuccess();
  } catch (error) {
    console.log(error);
  }

  // Called when reconnecting failed
  messageConnection.onclose((error) => {
    console.log(error);
  });

  // Called when connection was lost and attempting to reconnect
  messageConnection.onreconnecting((error) => {
    console.log(error);
  });

  messageConnection.onreconnected(async (connectionId) => {
    onConnectionSuccess();
  });
}

export async function subscribeToMessages(sellerEnrollmentID) {
  if (!messageConnection)
    return { Status: "Failure", Message: "Not connected" };
  try {
    const res = await messageConnection.invoke(
      "SubscribeToMessages",
      "Raffles",
      sellerEnrollmentID,
    );
    return res;
  } catch (error) {
    return { Status: "Failure", Message: "Something went wrong" };
  }
}

export async function unsubscribeFromMessages(sellerEnrollmentID) {
  if (!messageConnection)
    return { Status: "Failure", Message: "Not connected" };
  try {
    const res = await messageConnection.invoke(
      "UnsubscribeFromMessages",
      "Raffles",
      sellerEnrollmentID,
    );
    return res;
  } catch (error) {
    return { Status: "Failure", Message: "Something went wrong" };
  }
}
