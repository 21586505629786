import React from "react";
import DateTimePicker from "../../../../components/DateTimePicker";
import NumberFormat from "react-number-format";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";

import { parseNumber, removeTimezoneFormatFromDate } from "../../../../lib";

import Modal from "../../../../components/Modal";
import ValidatedInput from "../../../../components/form/ValidatedInput";

export default class PromoCodeModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      errorMessage: "",
      promoCode: {
        code: "",
        discountAmount: "",
        expirationDate: "",
        threshold: "",
        title: "",
        appliesTo: "Students",
        timezoneID: props.timezoneID,
      },
      submitAttempted: false,
    };

    state.initialState = _cloneDeep(state);

    this.state = state;
  }

  componentDidMount() {
    const { editPromoIndex, promoCodes } = this.props;
    if (editPromoIndex !== null) {
      this.setState({ promoCode: promoCodes[editPromoIndex] });
    }
  }

  onChangePromoCode = (name, value) => {
    this.setState({
      promoCode: {
        ...this.state.promoCode,
        [name]: value,
      },
    });
  };

  onCancel = () => {
    this.props.togglePromoCodeModal();
    this.setState({
      promoCode: {
        code: "",
        discountAmount: "",
        expirationDate: "",
        threshold: "",
        title: "",
        appliesTo: "Students",
        timezoneID: this.props.timezoneID,
      },
      submitAttempted: false,
    });
  };

  validateUniqueCode = (code) => {
    const { promoCodes } = this.props;
    const unique = promoCodes.map((promoCode) => {
      if (promoCode.code.toLowerCase() === code.toLowerCase()) {
        return true;
      } else {
        return false;
      }
    });
    return unique.indexOf(true) === -1;
  };

  onSave = () => {
    this.setState(
      {
        submitAttempted: true,
      },
      () => {
        const {
          promoCode: { code, discountAmount },
        } = this.state;

        if (!code || !discountAmount) {
          return;
        }

        const promoToSave = { ...this.state.promoCode };
        //trim leading/trailing whitespace from codes
        if (/^\s|\s$/.test(code)) {
          //regex to check if code contains leading/trailing whitespace
          promoToSave.code = code.trim();
        }
        promoToSave.discountAmount = parseInt(discountAmount, 10);

        if (this.props.editPromoIndex !== null) {
          let promoCodes = this.props.promoCodes;
          promoCodes[this.props.editPromoIndex] = promoToSave;
          this.props.onChange("promoCodes", promoCodes);
          this.props.togglePromoCodeModal();
          this.setState({
            submitAttempted: false,
          });
        } else {
          const validatedUniqueCode = this.validateUniqueCode(code);
          if (validatedUniqueCode) {
            this.props.onArrayChange("promoCodes", promoToSave, true);
            this.props.togglePromoCodeModal();
            this.setState({
              submitAttempted: false,
            });
          } else {
            this.setState({
              errorMessage: `Promo code "${code}" already exists`,
            });
            setTimeout(() => {
              this.setState({ errorMessage: "" });
            }, 3000);
          }
        }
      },
    );
  };

  render() {
    const { editPromoIndex, show } = this.props;
    const {
      initialState: { promoCode: initialPromoCode },
      promoCode,
      promoCode: { code, title, discountAmount, expirationDate, threshold },
      submitAttempted,
    } = this.state;

    return (
      <Modal show={show}>
        <div className="promo-code-modal-container modal-container full-page-mobile-modal-container">
          <div className="modal-card card promo-code-modal relative">
            <div className="desktop-hidden tablet-hidden modal-mobile-header">
              <i
                className="material-icons pointer link-text-secondary"
                onClick={this.onCancel}
              >
                close
              </i>
            </div>
            <p className="large-text fw-500 mb-16">
              {editPromoIndex !== null ? "Edit" : "New"} Promo Code
            </p>
            <div className="flex flex-align-center mb-12 mobile-block">
              <label className="accent-text-dark required">Code</label>
              <ValidatedInput
                input={
                  <input type="text" className="custom-input full-width" />
                }
                name="code"
                onChange={(e) =>
                  this.onChangePromoCode(e.target.name, e.target.value)
                }
                required={true}
                showRequired={submitAttempted}
                value={code}
              />
            </div>
            <div className="flex flex-align-center mb-12 mobile-block">
              <label className="accent-text-dark">Title</label>
              <input
                className="custom-input full-width"
                name="title"
                onChange={(e) =>
                  this.onChangePromoCode(e.target.name, e.target.value)
                }
                type="text"
                value={title || ""}
              />
            </div>
            <div className="flex flex-align-center mb-12 mobile-block">
              <label className="accent-text-dark required">Discount</label>
              <ValidatedInput
                input={
                  <NumberFormat
                    className="custom-input dollar-input full-width"
                    decimalScale={2}
                    thousandSeparator={true}
                  />
                }
                name="discountAmount"
                onChange={(e) =>
                  this.onChangePromoCode(
                    e.target.name,
                    parseNumber(e.target.value),
                  )
                }
                required={true}
                showRequired={submitAttempted}
                value={discountAmount}
              />
            </div>
            <div className="flex flex-align-center mb-12 mobile-block">
              <label className="accent-text-dark">Expiration</label>
              <DateTimePicker
                className="custom-input"
                name="expirationDate"
                onChange={(nm, val) =>
                  this.onChangePromoCode(
                    nm,
                    val && removeTimezoneFormatFromDate(val),
                  )
                }
                showTime={true}
                value={expirationDate}
              />
            </div>
            <div className="flex flex-align-center mb-12 mobile-block">
              <label className="accent-text-dark">Limit</label>
              <NumberFormat
                className="custom-input limit-input"
                name="threshold"
                value={threshold}
                decimalScale={0}
                type="text"
                allowNegative={false}
                allowLeadingZeros={false}
                onValueChange={(e) => {
                  if (e.floatValue === 0) {
                    this.onChangePromoCode("threshold", "");
                  } else {
                    this.onChangePromoCode("threshold", e.value);
                  }
                }}
              />
            </div>
            <div className="modal-btns flex flex-align-center flex-justify-end mt-16">
              <button
                className="btn link-text uppercase-text fw-500"
                onClick={this.onCancel}
              >
                Cancel
              </button>
              <button
                className="btn link-text uppercase-text fw-500 ml-24"
                disabled={_isEqual(initialPromoCode, promoCode)}
                onClick={this.onSave}
              >
                Save
              </button>
              <p className="error-message no-wrap" style={{ bottom: "16px" }}>
                {this.state.errorMessage}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
