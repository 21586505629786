import React from "react";
import RegistrationQuestions from "../../../../shared/manage/students/registerStudent/RegistrationQuestions";

export default function ResourceRegistrationQuestions(props) {
  const {
    onChangeRegistration,
    registration,
    resourceDetailsErrorMessage,
    resourceDetailsLoading,
    submitAttempted,
  } = props;

  return (
    <div className="mt-24 separatorTop">
      {resourceDetailsLoading ? (
        <p className="mt-24 accent-text fw-700">Loading resource settings...</p>
      ) : resourceDetailsErrorMessage ? (
        <p className="mt-24 error-text fw-700">{resourceDetailsErrorMessage}</p>
      ) : (
        <RegistrationQuestions
          onChangeRegistration={onChangeRegistration}
          registration={registration}
          submitAttempted={submitAttempted}
        />
      )}
    </div>
  );
}
