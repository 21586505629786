import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { EduActions } from "../../state/edu/actions";
import Edu from "./Edu";

export default connect(
  function mapState(state) {
    return {
      ...state.edu,
      auth: state.auth,
      programRoutes: state.programs && state.programs.routes,
      sys: state.sys,
      ui: state.ui,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...EduActions,
        },
        dispatch,
      ),
    };
  },
)(Edu);
