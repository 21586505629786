import React from "react";

const MergeDuplicateAccountDetails = ({
  isSelectedEmailField,
  studentIndex,
  students,
}) => {
  const student = students[studentIndex];
  const showStudentAccountWarning =
    isSelectedEmailField &&
    !student.didStudentCreateAccount &&
    students.some((s) => s.didStudentCreateAccount);
  return (
    <div className="merge-duplicates-account-details">
      <div
        className={`small-text ${
          showStudentAccountWarning ? " error-text" : ""
        }`}
      >
        <div className="flex flex-align-center">
          <i
            className="material-icons medium-text mr-4"
            style={{
              color: student.didStudentCreateAccount ? "#228b22" : "#e94746",
            }}
          >
            {student.didStudentCreateAccount ? "check_circle" : "cancel"}
          </i>
          {!student.didStudentCreateAccount ? "No " : ""}User Account
          <div className="tooltip-container merge-duplicates-tooltip-container ml-4">
            <i className="material-icons link-text-secondary small-text">
              info
            </i>
            <span className="tooltip line-height-double" style={{ zIndex: 1 }}>
              The merged student will receive the user account and profile
              verification status of the student with the selected email.
              {!student.didStudentCreateAccount &&
                " If this email is selected, the merged student will NOT have an account."}
            </span>
          </div>
        </div>
        {showStudentAccountWarning && (
          <p className="mt-4" style={{ lineHeight: 1 }}>
            The merged student will need to create a new account.
          </p>
        )}
      </div>
      <div className="small-text flex flex-align-center">
        <i
          className="material-icons medium-text mr-4"
          style={{
            color: student.didStudentVerifyProfile ? "#228b22" : "#e94746",
          }}
        >
          {student.didStudentVerifyProfile ? "check_circle" : "cancel"}
        </i>
        Profile {!student.didStudentVerifyProfile ? "Not " : ""}
        Verified
      </div>
    </div>
  );
};

export default React.memo(MergeDuplicateAccountDetails);
