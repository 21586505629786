import React from "react";

import {
  getCociLogo,
  getCociLogoMobile,
  handleInputState,
  handlePreventDefault,
  PageLink,
} from "../../lib";
import Pages from "../index";
import Loader from "../../components/Loader";

export default class ResetPass extends React.PureComponent {
  state = {
    currentPass: "",
    newPass1: "",
    newPass2: "",
    formSubmitted: false,
    errorMessage: "",
  };

  currentPassChanged = handleInputState(this, "currentPass");
  newPass1Changed = handleInputState(this, "newPass1");
  newPass2Changed = handleInputState(this, "newPass2");

  inputFocus(e) {
    let inputContainer = e.target.parentNode;
    inputContainer.classList.add("input-container-outline");
  }

  inputBlur(e) {
    let inputContainer = e.target.parentNode;
    inputContainer.classList.remove("input-container-outline");
  }

  passwordBlur(e) {
    this.inputBlur(e);

    const { newPass1, newPass2 } = this.refs;

    if (newPass1.value && newPass1.value.length < 8) {
      this.setState({
        errorMessage: "Password must contain at least 8 characters",
      });
    } else if (
      newPass1.value &&
      newPass2.value &&
      newPass1.value !== newPass2.value
    ) {
      this.setState({ errorMessage: "Passwords do not match, please retype" });
    } else {
      this.setState({ errorMessage: "" });
    }
  }

  submitForm = handlePreventDefault(() => {
    this.setState({ formSubmitted: true });
    const {
      pageRoute: {
        query: { token },
      },
      actions: { doResetPass },
    } = this.props;
    if (!doResetPass) return;
    const { currentPass, newPass1 } = this.state;
    doResetPass({
      currentPass,
      newPass1,
      token,
    });
  });

  reloadForm() {
    this.setState({ formSubmitted: false });
  }

  render() {
    const {
      state: { currentPass, newPass1, newPass2, errorMessage, formSubmitted },
    } = this;
    const { loading, success } = this.props.resetPass;
    return (
      <div className="reset-pass-page account-page">
        <div className="account-page-left">
          <div>
            <img
              src={getCociLogo()}
              alt="logo"
              className="logo mobile-hidden"
            />
            <div className="account-header-mobile desktop-hidden tablet-hidden">
              <img src={getCociLogoMobile()} alt="logo" />
            </div>
          </div>
          <div>
            <h1 className="account-page-title-small">Reset Password</h1>
            {!loading ? (
              !formSubmitted ? (
                <React.Fragment>
                  <form className="account-form">
                    <div className="input-container">
                      <label>Current Password</label>
                      <input
                        type="password"
                        value={currentPass}
                        onChange={this.currentPassChanged}
                        onFocus={(e) => this.inputFocus(e)}
                        onBlur={(e) => this.inputBlur(e)}
                      />
                    </div>
                    <div className="input-container">
                      <label>New Password</label>
                      <input
                        type="password"
                        ref="newPass1"
                        value={newPass1}
                        onChange={this.newPass1Changed}
                        onFocus={(e) => this.inputFocus(e)}
                        onBlur={(e) => {
                          this.passwordBlur(e);
                        }}
                      />
                    </div>
                    <div className="input-container">
                      <label>Confirm New Password</label>
                      <input
                        type="password"
                        ref="newPass2"
                        value={newPass2}
                        onChange={this.newPass2Changed}
                        onFocus={(e) => this.inputFocus(e)}
                        onBlur={(e) => {
                          this.passwordBlur(e);
                        }}
                      />
                    </div>
                    {!loading ? (
                      <button
                        type="submit"
                        className="btn btn-large btn-fullWidth btn-accent mt-16"
                        disabled={
                          !currentPass || !newPass1 || !newPass2 || errorMessage
                        }
                        onClick={this.submitForm}
                      >
                        Reset Password
                      </button>
                    ) : (
                      <div className="btn-large-loader">
                        <Loader />
                      </div>
                    )}
                    <div className="error-message-container mt-4">
                      <span className="error-message">{errorMessage}</span>
                    </div>
                  </form>
                  <div>
                    <PageLink
                      to={Pages.profile.profile}
                      className="accent-text link-text-secondary underline-text block text-center mt-32"
                    >
                      Back to account
                    </PageLink>
                  </div>
                </React.Fragment>
              ) : success === true ? (
                <div>
                  <p className="accent-text">
                    Your password has been reset successfully.
                  </p>
                  <p className="accent-text">Login with your new password.</p>
                  <img src="/images/reset_pass_success.svg" alt="lock icon" />
                  {!loading ? (
                    <PageLink
                      to={Pages.account.login}
                      style={{ display: "block", textAlign: "center" }}
                      type="submit"
                      className="btn btn-large btn-fullWidth btn-accent mt-16"
                    >
                      Sign In
                    </PageLink>
                  ) : (
                    <div className="btn-large-loader">
                      <Loader />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <p className="accent-text">
                    There was an error while trying to reset your password.
                  </p>
                  <p className="accent-text">Please try again.</p>
                  <img src="/images/reset_pass_error.svg" alt="lock icon" />
                  {!loading ? (
                    <button
                      type="submit"
                      className="btn btn-large btn-fullWidth btn-accent mt-16"
                      onClick={() => this.reloadForm()}
                    >
                      Try Again
                    </button>
                  ) : (
                    <div className="btn-large-loader">
                      <Loader />
                    </div>
                  )}
                </div>
              )
            ) : (
              <Loader />
            )}
          </div>
          <div className="small-text flex flex-align-center">
            {/* <PageLink to={''} className="link-text no-wrap">
              Terms and Conditions
            </PageLink> */}
            {/* <p className="accent-text-light ml-16 mr-16">|</p> */}
            <p className="flex flex-align-center">
              <span className="accent-text-dark block small-text">
                <a
                  href="mailto:help@chabadoncampus.org"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-text"
                >
                  <span>help@chabadoncampus.org</span>
                </a>
              </span>
              <span className="accent-text ml-8 mr-8">|</span>
              <span className="accent-text-dark block small-text">
                <a href="tel:718-510-8181" className="link-text">
                  <span className="no-wrap">718-510-8181 ext 0</span>
                </a>
              </span>
            </p>
          </div>
        </div>
        <div className="account-page-right">
          <img src="/images/account-background.jpg" alt="background" />
        </div>
      </div>
    );
  }
}
