import React from "react";
import StudentEngagementGoals from "./engagement/StudentEngagementGoals";
import { withAppInsights } from "../../lib/AppInsights";

function StudentEngagementPage(props) {
  return (
    <div className="student-engagement-page">
      <StudentEngagementGoals pageRoute={props.pageRoute} />
    </div>
  );
}

export default withAppInsights(StudentEngagementPage);
