import React from "react";
import RsvpItemsDropdown from "../../shared/manage/RsvpItemsDropdown";

export default function EventSchedulesDropdown(props) {
  return (
    <RsvpItemsDropdown
      rsvpType="event schedule"
      getOptionLabel={(s) =>
        s.eventState === "Canceled" ? (
          <p className="canceled-event">{s.name} (Canceled)</p>
        ) : (
          s.name
        )
      }
      {...props}
    />
  );
}
