import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  bottomSection: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "calc(100vh - 118px)",
  },
  drawerFooter: {
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
  },
  drawerHeader: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 2),
  },
  drawerPaper: { height: "100vh" },
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerBox: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    padding: 8,
    marginBottom: 8,
  },
  link: { padding: 0, fontSize: 12, textTransform: "capitalize" },
}));
