import { EngagementActions } from "./actions";
import { AuthActions } from "../auth/actions";

const defaultState = {
  loading: false,
  success: true,
  data: {},
  error: null,
  errorMessage: null,
};

export const EngagementState = {
  name: "engagement",
  persist: false,
  defaults: {
    engagementPeriods: {
      loading: false,
    },
    engagementGoals: {
      loading: false,
    },
    submitEngagementGoals: {
      loading: false,
    },
    engagementStudentGoalMetrics: {
      loading: false,
    },
    engagementStudentMetrics: {
      loading: false,
    },
    engagementInteractionGoalMetrics: {
      loading: false,
    },
    engagementInteractionMetrics: {
      loading: false,
    },
    jewishStudentCount: {
      loading: false,
    },
    studentInteractionsSummary: { ...defaultState },
  },
  handlers: {
    [EngagementActions.SET_ENGAGEMENT_PERIODS_LOADING]: (state, action) => {
      return {
        ...state,
        engagementPeriods: {
          loading: !!action.payload,
        },
      };
    },
    [EngagementActions.SET_ENGAGEMENT_PERIODS]: (state, action) => {
      return {
        ...state,
        engagementPeriods: action.payload,
      };
    },
    [EngagementActions.SET_ENGAGEMENT_GOALS_LOADING]: (state, action) => {
      return {
        ...state,
        engagementGoals: {
          loading: !!action.payload,
        },
      };
    },
    [EngagementActions.SET_ENGAGEMENT_GOALS]: (state, action) => {
      return {
        ...state,
        engagementGoals: action.payload,
      };
    },

    [EngagementActions.SET_SUBMIT_ENGAGEMENT_GOALS_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        submitEngagementGoals: {
          loading: !!action.payload,
        },
      };
    },
    [EngagementActions.SET_SUBMIT_ENGAGEMENT_GOALS]: (state, action) => {
      return {
        ...state,
        submitEngagementGoals: action.payload,
      };
    },

    [EngagementActions.SET_ENGAGEMENT_STUDENT_GOAL_METRICS_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        engagementStudentGoalMetrics: {
          loading: !!action.payload,
        },
      };
    },
    [EngagementActions.SET_ENGAGEMENT_STUDENT_GOAL_METRICS]: (
      state,
      action,
    ) => {
      return {
        ...state,
        engagementStudentGoalMetrics: action.payload,
      };
    },
    [EngagementActions.SET_ENGAGEMENT_STUDENT_METRICS_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        engagementStudentMetrics: {
          loading: !!action.payload,
        },
      };
    },
    [EngagementActions.SET_ENGAGEMENT_STUDENT_METRICS]: (state, action) => {
      return {
        ...state,
        engagementStudentMetrics: action.payload,
      };
    },

    [EngagementActions.SET_ENGAGEMENT_INTERACTION_GOAL_METRICS_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        engagementInteractionGoalMetrics: {
          loading: !!action.payload,
        },
      };
    },
    [EngagementActions.SET_ENGAGEMENT_INTERACTION_GOAL_METRICS]: (
      state,
      action,
    ) => {
      return {
        ...state,
        engagementInteractionGoalMetrics: action.payload,
      };
    },

    [EngagementActions.SET_ENGAGEMENT_INTERACTION_METRICS_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        engagementInteractionMetrics: {
          loading: !!action.payload,
        },
      };
    },
    [EngagementActions.SET_ENGAGEMENT_INTERACTION_METRICS]: (state, action) => {
      return {
        ...state,
        engagementInteractionMetrics: action.payload,
      };
    },

    [EngagementActions.SET_ENGAGEMENT_JEWISH_STUDENTS_COUNT_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        jewishStudentCount: {
          loading: !!action.payload,
        },
      };
    },
    [EngagementActions.SET_ENGAGEMENT_JEWISH_STUDENTS_COUNT]: (
      state,
      action,
    ) => {
      return {
        ...state,
        jewishStudentCount: action.payload,
      };
    },

    [EngagementActions.SET_ENGAGEMENT_STUDENT_INTERACTIONS_SUMMARY_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        studentInteractionsSummary: {
          ...defaultState,
          loading: !!action.payload,
        },
      };
    },
    [EngagementActions.SET_ENGAGEMENT_STUDENT_INTERACTIONS_SUMMARY]: (
      state,
      action,
    ) => {
      return {
        ...state,
        studentInteractionsSummary: {
          ...defaultState,
          ...action.payload,
        },
      };
    },

    [AuthActions.DO_SIGNOUT]: (state, action) => {
      return EngagementState.defaults;
    },
  },
};
