import React, { memo } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";

interface NoResultsFoundProps {
  title: string;
  subtitle?: string;
  imgSrc?: string;
  imgHeight?: string | number;
}

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    marginTop: 8,
    fontWeight: 400,
    fontFamily: "AvenirRoman",
  },
  subtitle: {
    marginTop: 8,
    fontFamily: "AvenirMedium",
    color: "rgba(0, 0, 0, 0.48)",
  },
}));

function NoResultsFound(props: NoResultsFoundProps) {
  const classes = useStyles();

  const {
    title,
    subtitle,
    imgSrc = "/images/no_results.svg",
    imgHeight = "120",
  } = props;

  return (
    <Box className={classes.container}>
      <img src={imgSrc} alt="no results" height={imgHeight} />
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="subtitle1" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
}

export default memo(NoResultsFound);
