import React from "react";
import { rsvpMessageTypes, sendEventSmsMessage } from "../../RsvpMessageUtils";
import RsvpItemCreatedModal from "../../shared/manage/RsvpItemCreatedModal";

export default function EventCreatedModal(props) {
  const {
    enrollment: { data: { chabadHouseRsvpURL } = {} },
    eventSchedule: { data: { eventScheduleID } = {} },
    actions,
  } = props;

  return (
    <RsvpItemCreatedModal
      rsvpType="event"
      directUrl={`${chabadHouseRsvpURL}/events/${eventScheduleID}`}
      sendSmsMessage={() =>
        sendEventSmsMessage(eventScheduleID, rsvpMessageTypes.newEvent, actions)
      }
      {...props}
    />
  );
}
