import React from "react";
import { makeStyles, Typography, Breadcrumbs } from "@material-ui/core";
import { PageLink, toTitleCase } from "../../../lib";

export const useStyles = makeStyles((theme) => ({
  link: {
    color: "#747579",
    fontFamily: "AvenirRoman",
    fontSize: 16,
    letterSpacing: 0,
    lineHeight: "22px",
    display: "inline",
  },
  breadcrumbsContainer: {
    minHeight: 28,
    paddingBottom: 16,
    [theme.breakpoints.down("md")]: {
      marginTop: 32,
      minHeight: 12,
      padding: 0,
    },
    [theme.breakpoints.down("sm")]: { marginTop: 0, marginBottom: 8 },
  },
}));

export default function RsvpBreadcrumbsHeader(props) {
  const {
    rsvpItemName,
    tabs,
    pageRoute: {
      page,
      params: { rsvpItemId, pageView, programScheduleId },
      query: { tab: manageRsvpTab },
    },
  } = props;
  const classes = useStyles();

  return (
    <Breadcrumbs className={classes.breadcrumbsContainer}>
      <PageLink
        className={classes.link}
        params={{
          rsvpItemId: undefined,
          pageView,
          programScheduleId,
        }}
        to={page}
      >
        {toTitleCase(pageView)}
      </PageLink>
      {pageView === "settings" ? (
        <Typography variant="body1" className={classes.link}>
          Settings
        </Typography>
      ) : rsvpItemId === "new" ? (
        <Typography variant="body1" className={classes.link}>
          Add New {pageView === "events" ? "Event" : "Resource"}
        </Typography>
      ) : (
        rsvpItemName && (
          <PageLink
            className={classes.link}
            params={{
              rsvpItemId,
              pageView: pageView,
              programScheduleId,
            }}
            query={{ tab: "settings" }}
            to={page}
          >
            {rsvpItemName}
          </PageLink>
        )
      )}

      {rsvpItemId !== "new" && rsvpItemName && (
        <Typography variant="body1" className={classes.link}>
          {(tabs.find((t) => t.id === manageRsvpTab) || tabs[0]).title}
        </Typography>
      )}
    </Breadcrumbs>
  );
}
