import React from "react";

export default () => (
  <div className="paginated-table-header-row students-table-row">
    <p>Course Name</p>
    <p>Start & End Dates</p>
    <p>Students</p>
    <p>Status</p>
  </div>
);
