import React, { memo } from "react";
import { PageNavLink } from "../lib";
import Pages from "../pages";

function DynamicDirectoryMenu() {
  return (
    <div className="program-dropdown-box dropdown-box" style={{ width: 125 }}>
      <PageNavLink
        to={Pages.directory.directory}
        exact={true}
        className="fw-500 link-text-secondary line-height-double"
        activeClassName="active-nav-link"
      >
        Shluchim
      </PageNavLink>
      <PageNavLink
        to={Pages.directory.teamsDirectory}
        exact={true}
        className="fw-500 link-text-secondary line-height-double"
        activeClassName="active-nav-link"
      >
        COCI Team
      </PageNavLink>
    </div>
  );
}

export default memo(DynamicDirectoryMenu);
