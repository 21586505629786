import React from "react";

export default class AlreadyRegistered extends React.Component {
  render() {
    return (
      <div className="kinus-page-form-state">
        <img src="/images/enrollmentComplete.svg" alt="" height="240" />
        <p className="fw-700 mt-24" style={{ fontSize: "34px" }}>
          You have already registered
        </p>
        <p className="mt-16 large-text">
          Questions? Please contact us at{" "}
          <a
            href="mailto:help@chabadoncampus.org"
            className="link-text"
            target="_blank"
            rel="noopener noreferrer"
          >
            help@chabadoncampus.org
          </a>{" "}
          or{" "}
          <a href="tel:+1-718-510-8181,0" className="link-text">
            718 510 8181 x 0
          </a>
        </p>
      </div>
    );
  }
}
