import React from "react";
import moment from "moment";

export default ({
  enrollment: { id: enrollmentId, status, statusDisplay, terminationDate },
  enrollmentStatuses,
  programName,
}) => (
  <div className="flex flex-align-center flex-justify-space mb-32 life-insurance-card-header">
    <div>
      <h1 className="xxl-text fw-900">
        {enrollmentId
          ? `My ${programName || "Life Insurance"}`
          : `Enroll in ${programName || "Life Insurance"}`}
      </h1>
      <h2 className="error-text mt-12">
        Enrolling only one spouse? Our system charges everyone $490. For single
        enrollments, $245 will automatically be refunded to your card.
      </h2>
    </div>
    {enrollmentId && status && (
      <div className="relative">
        <div className={`tag small life-insurance-status-tag-${status}`}>
          {statusDisplay}
        </div>
        {status === enrollmentStatuses.FailedRenewal ? (
          <p
            className="error-text small-text mt-8 absolute no-wrap"
            style={{ right: "0" }}
          >
            Please enter a new credit card below
          </p>
        ) : status === enrollmentStatuses.Terminated ? (
          <p className="mt-8 absolute no-wrap" style={{ right: "0" }}>
            {moment(terminationDate).format("MMMM D, YYYY")}
          </p>
        ) : (
          ""
        )}
      </div>
    )}
  </div>
);
