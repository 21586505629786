import React from "react";
import NoRsvpItems from "../../shared/list/NoRsvpItems";

export default function NoEventSchedules(props) {
  const { pageRoute } = props;

  return (
    <NoRsvpItems
      rsvpType="event"
      textBlanks={{
        noItemsMessage:
          "Hmmm, it seems like you don't have any events scheduled yet",
        addItemsMessage:
          "Add your events to the RSVP system to run your Chabad House's events smoothly",
      }}
      pageRoute={pageRoute}
    />
  );
}
