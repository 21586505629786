import Kinus from "./Kinus.vm";

export const KinusPages = {
  kinus: {
    anon: false,
    path: "/kinus-legacy",
    title: "Chabad on Campus",
    type: "PAGE_KINUS",
    view: Kinus,
  },
};
export default KinusPages;

export const KinusArea = {
  pages: KinusPages,
};
