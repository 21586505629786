import React from "react";
import PaginatedList from "../../../../components/PaginatedList";
import { formatNumber, getCurrencySymbol } from "../../../../lib";
import moment from "moment";

export default class StudentRewards extends React.PureComponent {
  state = {
    page: 1,
    resultsView: 10,
  };

  getRewards = (page) => {
    this.setState({ page });

    const { getStudentRewards, studentEnrollment } = this.props;
    getStudentRewards(
      studentEnrollment.eduStudentEnrollmentID,
      page,
      this.state.resultsView,
    );
  };

  render() {
    const {
      mobileView,
      studentEnrollment: { numOfCreditsAvailable, numOfCreditsReceived },
      studentRewards: {
        data: { results = [], numberOfRows = 0 } = {},
        loading,
        success,
      },
    } = this.props;

    const { page, resultsView } = this.state;

    return (
      <div>
        <div className="flex flex-align-center flex-justify-space tablet-block mb-24">
          <p className="fw-900 medium-text tablet-mb-8 mobile-hidden">
            Reward History
          </p>
          <div className="flex flex-align-center flex-justify-end medium-text mobile-block">
            <div className="flex flex-align-center mobile-mb-16">
              <p className="fw-700 mr-8">Total Credits</p>
              <p>{formatNumber(numOfCreditsReceived)}</p>
            </div>
            <div className="flex flex-align-center">
              <p className="fw-700 mr-8 ml-16 mobile-ml-0">Available Credits</p>
              <p>{formatNumber(numOfCreditsAvailable)}</p>
            </div>
          </div>
        </div>
        <div>
          <PaginatedList
            loadData={this.getRewards}
            loading={loading}
            name="rewards"
            page={page}
            records={results}
            renderHeaderRow={() => (
              <div className="student-rewards-table-header-row">
                <p>Name</p>
                <p>Credits / Total Value</p>
                <p>Date Requested</p>
                <p>Status</p>
              </div>
            )}
            renderRow={(rewardRequest, index) => (
              <div className="student-rewards-table-row" key={index}>
                <p>{rewardRequest.rewardName}</p>
                {!mobileView ? (
                  <React.Fragment>
                    <div>
                      <p className="fw-700">
                        {formatNumber(rewardRequest.creditsToRedeem)}
                      </p>
                      <p>
                        {getCurrencySymbol(rewardRequest.currencyCode)}
                        {formatNumber(
                          rewardRequest.creditValue *
                            rewardRequest.creditsToRedeem,
                        )}
                      </p>
                    </div>
                    <p>
                      {moment(rewardRequest.createdOn).format("MM/DD/YYYY")}
                    </p>
                    <p>{rewardRequest.statusDisplay}</p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="flex flex-align-center">
                      <p
                        className="small-text accent-text"
                        style={{ width: 144 }}
                      >
                        Credits / Total Value
                      </p>
                      <p className="small-text accent-text">
                        {formatNumber(rewardRequest.creditsToRedeem)} /{" "}
                        {getCurrencySymbol(rewardRequest.currencyCode)}
                        {formatNumber(
                          rewardRequest.creditValue *
                            rewardRequest.creditsToRedeem,
                        )}
                      </p>
                    </div>
                    <div className="flex flex-align-center">
                      <p
                        className="small-text accent-text"
                        style={{ width: 144 }}
                      >
                        Date Requested
                      </p>
                      <p className="small-text accent-text">
                        {moment(rewardRequest.createdOn).format("MM/DD/YYYY")}
                      </p>
                    </div>
                    <div className="flex flex-align-center">
                      <p
                        className="small-text accent-text"
                        style={{ width: 144 }}
                      >
                        Status
                      </p>
                      <p className="small-text accent-text">
                        {rewardRequest.statusDisplay}
                      </p>
                    </div>
                  </React.Fragment>
                )}
              </div>
            )}
            resultsView={resultsView}
            success={success}
            totalCount={numberOfRows}
          />
        </div>
      </div>
    );
  }
}
