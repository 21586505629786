import React from "react";
import NumberFormat from "react-number-format";
import {
  formatCurrency,
  formatDateTimeForInput,
  formatNumber,
  removeTimezoneFormatFromDate,
  sum,
  isMobileView,
} from "../../../../lib";
import moment from "moment";

import BillingAddress from "../payment/BillingAddress";
import CreditCardInfo from "../payment/CreditCardInfo";
import DeletePromoModal from "./DeletePromoModal";
import PromoCodeModal from "./PromoCodeModal";

export default class Pricing extends React.PureComponent {
  state = {
    deletePromoIndex: null,
    editPromoIndex: null,
    promoDetailsOpened: {},
    showDeletePromoModal: false,
    showPromoCodeModal: false,
    showPricingInfo: true,
    submitAttempted: false,
  };

  componentDidMount() {
    if (window.innerWidth < 560 && this.props.hasEnrollment) {
      this.setState({ showPricingInfo: false });
    }
  }

  navigate = (tab) => {
    this.props.navigate(tab);
    this.setState({ showPricingInfo: true });
  };

  togglePromoCodeModal = () => {
    this.setState({ showPromoCodeModal: !this.state.showPromoCodeModal });
    if (!this.state.showPromoCodeModal) {
      this.setState({ editPromoIndex: null });
    }
  };

  toggleDeletePromoModal = () => {
    this.setState({ showDeletePromoModal: !this.state.showDeletePromoModal });
  };

  togglePromoDetails = (id) => {
    this.setState({
      promoDetailsOpened: {
        ...this.state.promoDetailsOpened,
        [id]: !this.state.promoDetailsOpened[id],
      },
    });
  };

  onDeletePromoCode = (index) => {
    this.setState({
      deletePromoIndex: index,
      showDeletePromoModal: true,
    });
  };

  deletePromoCode = (promoCode) => {
    const { onArrayChange } = this.props;
    onArrayChange("promoCodes", promoCode, false);

    this.setState({
      deletePromoIndex: null,
      showDeletePromoModal: false,
    });
  };

  onEditPromoCode = (index) => {
    this.setState({
      editPromoIndex: index,
      showPromoCodeModal: true,
    });
  };

  addPromoCode = (promoCode) => {
    const { onArrayChange } = this.props;
    onArrayChange("promoCodes", promoCode, true);

    this.setState({
      showPromoCodeModal: false,
    });
  };

  renderPromoCode = (promoCode, index) => {
    const { promoDetailsOpened } = this.state;
    return (
      <div
        className={`hover-delete-container flex flex-align-center full-width ${
          promoDetailsOpened[index] === true ? "open" : "closed"
        }`}
        key={index}
      >
        <div className="promo-codes-grid-row">
          <div className="flex flex-align-center flex-justify-space desktop-hidden tablet-hidden">
            <div className="flex">
              <p className="fw-700 mr-16">{promoCode.code}</p>
              <p>{promoCode.title}</p>
            </div>
            <i
              className="material-icons link-text"
              onClick={() => this.togglePromoDetails(index)}
            >
              {promoDetailsOpened[index] === true
                ? "keyboard_arrow_up"
                : "keyboard_arrow_down"}
            </i>
          </div>
          <p className="fw-700 mobile-hidden">{promoCode.code}</p>
          <p className="mobile-hidden">{promoCode.title}</p>
          <div className="promo-code-details">
            <p className="mobile-mt-8">
              ${formatCurrency(promoCode.discountAmount)} Off
            </p>
            <p className="mobile-mt-8">
              {promoCode.expirationDate && (
                <React.Fragment>
                  <span>Exp</span>{" "}
                  <span>
                    {moment(promoCode.expirationDate).format("M/D")}{" "}
                    <br className="mobile-hidden" />
                    {moment(promoCode.expirationDate).format("h:mm a")}
                  </span>
                </React.Fragment>
              )}
            </p>
            <p className="mobile-mt-8">
              {/* differentiate between no limit set and no more available */}
              {promoCode.threshold > 0 || promoCode.threshold === 0
                ? promoCode.counter
                  ? `${formatNumber(promoCode.threshold - promoCode.counter)}`
                  : `${formatNumber(promoCode.threshold)} Avail`
                : "No Limit"}
            </p>
          </div>
        </div>
        <p className="flex flex-align-center">
          <i
            className="material-icons large-text link-text hover-delete ml-16"
            onClick={() => this.onEditPromoCode(index)}
          >
            edit
          </i>
          <i
            className="material-icons large-text link-text hover-delete ml-16"
            onClick={() => this.onDeletePromoCode(index)}
          >
            delete
          </i>
        </p>
      </div>
    );
  };

  isCCRequired = () => {
    const { enrollmentSettings } = this.props;
    const { totalCost } = this.state;
    const enrollmentFee = totalCost > 0;
    const promoCodesTotal = sum(
      enrollmentSettings.promoCodes.map((p) => p.discountAmount),
    );
    const mayOweMoneyForTrack = enrollmentSettings.tracks.map((track) => {
      if (
        track.earlyBirdPriceOverride - promoCodesTotal <
          track.earlyBirdPlaceholder ||
        track.regularPriceOverride - promoCodesTotal < track.regularPlaceholder
      ) {
        return true;
      } else {
        return false;
      }
    });
    const ccRequired = enrollmentFee || mayOweMoneyForTrack.indexOf(true) > -1;
    return ccRequired;
  };

  render() {
    const {
      account: { credCardInfo, familyAddress, primaryChabadHouse } = {},
      countries,
      enrollmentSettings,
      enrollmentSettings: {
        latestCancellationDateOverride,
        promoCodes: enrollmentPromoCodes,
        specialInstructions,
        studentRegistrationEndDateOverride,
        tracks,
      },
      enrollment: { chabadHouseTimezoneID },
      event: {
        canShluchimIssuePromoCodes,
        canShluchimOverridePrices,
        latestCancellationDate,
        studentRegistrationEndDate,
      },
      onArrayChange,
      onChange,
      selectedTab,
      hasEnrollment,
      validation,
    } = this.props;
    const ccRequired = this.isCCRequired();
    return (
      <div>
        {(!hasEnrollment || isMobileView()) && (
          <div className="trip-page-form-section">
            <p
              className="xxl-text fw-500"
              onClick={
                hasEnrollment && isMobileView()
                  ? selectedTab === "pricing"
                    ? () =>
                        this.setState({
                          showPricingInfo: !this.state.showPricingInfo,
                        })
                    : () => this.navigate("pricing")
                  : null
              }
            >
              Pricing
            </p>
          </div>
        )}
        {this.state.showPricingInfo && (
          <React.Fragment>
            <div className="trip-page-form-section">
              <p className="medium-text fw-700 mb-16">Fees</p>
              {canShluchimOverridePrices && (
                <React.Fragment>
                  {!hasEnrollment && (
                    <p className="accent-text-dark line-height-double mt-8 mb-4">
                      You can keep the default COCI student registration prices
                      below and continue or customize your own student
                      registration pricing.
                    </p>
                  )}
                  <p className="accent-text-dark line-height-double mb-16">
                    Note that COCI will be retaining the default registration
                    fee. If you set the price higher, you will receive the
                    additional amount that the student pays. If you set the
                    price lower, you will be required to pay the difference.
                  </p>
                </React.Fragment>
              )}
              <div className="trip-form-pricing-inputs">
                {tracks &&
                  tracks.map((track, index) => {
                    const regularPriceRequiredError =
                      validation[`track-${track.trackID}-regularPriceOverride`];
                    return (
                      <React.Fragment key={track.trackID}>
                        {track.earlyBirdStudentDeadline &&
                          track.earlyBirdPlaceholder && (
                            <div className="flex flex-align-center mobile-block mb-16">
                              <label className="accent-text line-height-double">
                                {track.trackName} Early Bird
                                {!!track.earlyBirdStudentDeadline && (
                                  <p className="small-text accent-text-secondary-color">
                                    Deadline:{" "}
                                    {moment(
                                      track.earlyBirdStudentDeadline,
                                    ).format("MMMM DD, YYYY hh:mm a")}{" "}
                                    EST
                                  </p>
                                )}
                              </label>
                              <NumberFormat
                                allowNegative={false}
                                className="custom-input dollar-input"
                                decimalScale={2}
                                isAllowed={({ floatValue }) => floatValue !== 0}
                                name={`tracks[${index}].earlyBirdPriceOverride`}
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.value)
                                }
                                placeholder={track.earlyBirdPlaceholder}
                                readOnly={!canShluchimOverridePrices}
                                type="text"
                                value={track.earlyBirdPriceOverride}
                              />
                              {!!track.earlyBirdPriceOverride && (
                                <React.Fragment>
                                  {track.earlyBirdPriceOverride <
                                    track.earlyBirdPlaceholder && (
                                    <p className="accent-text-dark ml-24 small-text">
                                      * You set the price lower than COCI's
                                      price of{" "}
                                      <span
                                        className="fw-900"
                                        style={{ marginLeft: "1px" }}
                                      >
                                        {" "}
                                        $
                                        {formatCurrency(
                                          track.earlyBirdPlaceholder,
                                        )}
                                        .
                                      </span>
                                    </p>
                                  )}
                                  {track.earlyBirdPriceOverride >
                                    track.earlyBirdPlaceholder && (
                                    <p className="accent-text-dark ml-24 small-text">
                                      * You set the price higher than COCI's
                                      price of{" "}
                                      <span
                                        className="fw-900"
                                        style={{ marginLeft: "1px" }}
                                      >
                                        {" "}
                                        $
                                        {formatCurrency(
                                          track.earlyBirdPlaceholder,
                                        )}
                                        .
                                      </span>
                                    </p>
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                          )}
                        <div className="flex flex-align-center mobile-block mb-16">
                          <label className="accent-text line-height-double">
                            {track.trackName} Regular
                          </label>
                          <NumberFormat
                            allowNegative={false}
                            className={`custom-input dollar-input ${
                              regularPriceRequiredError ? "error" : ""
                            }`}
                            decimalScale={2}
                            name={`tracks[${index}].regularPriceOverride`}
                            onChange={(e) =>
                              onChange(e.target.name, e.target.value)
                            }
                            placeholder={track.regularPlaceholder}
                            readOnly={!canShluchimOverridePrices}
                            type="text"
                            value={track.regularPriceOverride}
                          />
                          {track.regularPriceOverride ||
                          track.regularPriceOverride === 0 ? (
                            <React.Fragment>
                              {track.regularPriceOverride <
                                track.regularPlaceholder && (
                                <p className="accent-text-dark ml-24 small-text">
                                  * You set the price lower than COCI's price of{" "}
                                  <span
                                    className="fw-900"
                                    style={{ marginLeft: "1px" }}
                                  >
                                    {" "}
                                    ${formatCurrency(track.regularPlaceholder)}.
                                  </span>
                                </p>
                              )}
                              {track.regularPriceOverride >
                                track.regularPlaceholder && (
                                <p className="accent-text-dark ml-24 small-text">
                                  * You set the price higher than COCI's price
                                  of{" "}
                                  <span
                                    className="fw-900"
                                    style={{ marginLeft: "1px" }}
                                  >
                                    {" "}
                                    ${formatCurrency(track.regularPlaceholder)}.
                                  </span>
                                </p>
                              )}
                            </React.Fragment>
                          ) : regularPriceRequiredError ? (
                            <p className="error-text ml-24 small-text">
                              * Fee is required. Enter{" "}
                              {formatNumber(track.regularPlaceholder)} to match
                              COCI's price
                              {track.regularPlaceholder !== 0
                                ? ", or 0 to set the price to $0"
                                : ""}
                              .
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
            <div className="trip-page-form-section">
              <p className="medium-text fw-700 mb-16">Dates</p>
              {moment(studentRegistrationEndDateOverride).isSame(
                moment(studentRegistrationEndDate),
              ) &&
                moment(latestCancellationDateOverride).isSame(
                  moment(latestCancellationDate),
                ) && (
                  <p className="accent-text-dark mb-16">
                    The dates below were set by COCI. You can set them earlier,
                    not later.
                  </p>
                )}
              <div className="trip-form-dates-inputs">
                <div className="flex flex-align-center mobile-block mb-16">
                  <label className="accent-text">
                    Registration End
                    <span className="small-text ml-8">(EST)</span>
                  </label>
                  <input
                    className="custom-input"
                    defaultValue={formatDateTimeForInput(
                      studentRegistrationEndDateOverride,
                    )}
                    max={formatDateTimeForInput(studentRegistrationEndDate)}
                    name="studentRegistrationEndDateOverride"
                    onChange={(e) =>
                      onChange(
                        e.target.name,
                        removeTimezoneFormatFromDate(e.target.value),
                      )
                    }
                    type="datetime-local"
                  />
                  {moment(studentRegistrationEndDateOverride).isBefore(
                    moment(studentRegistrationEndDate),
                  ) && (
                    <p className="accent-text-dark ml-24 small-text line-height-double">
                      You have set the registration end date earlier than COCI's
                      date of{" "}
                      {new Date(studentRegistrationEndDate).toLocaleString(
                        "en-US",
                        {
                          month: "numeric",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        },
                      )}
                      .
                    </p>
                  )}
                  {moment(studentRegistrationEndDateOverride).isAfter(
                    moment(studentRegistrationEndDate),
                  ) && (
                    <p className="error-text ml-24 small-text line-height-double">
                      You have set the registration end date later than COCI's
                      date of{" "}
                      {new Date(studentRegistrationEndDate).toLocaleString(
                        "en-US",
                        {
                          month: "numeric",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        },
                      )}
                      .
                    </p>
                  )}
                </div>
                <div className="flex flex-align-center mobile-block">
                  <label className="accent-text">
                    Latest Cancellation
                    <span className="small-text ml-8">(EST)</span>
                  </label>
                  <input
                    className="custom-input"
                    defaultValue={formatDateTimeForInput(
                      latestCancellationDateOverride,
                    )}
                    max={formatDateTimeForInput(latestCancellationDate)}
                    name="latestCancellationDateOverride"
                    onChange={(e) =>
                      onChange(
                        e.target.name,
                        removeTimezoneFormatFromDate(e.target.value),
                      )
                    }
                    type="datetime-local"
                  />
                  {moment(latestCancellationDateOverride).isBefore(
                    moment(latestCancellationDate),
                  ) && (
                    <p className="accent-text-dark ml-24 small-text line-height-double">
                      You have set the latest cancellation date earlier than
                      COCI's date of{" "}
                      {new Date(latestCancellationDate).toLocaleString(
                        "en-US",
                        {
                          month: "numeric",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        },
                      )}
                      .
                    </p>
                  )}
                  {moment(latestCancellationDateOverride).isAfter(
                    moment(latestCancellationDate),
                  ) && (
                    <p className="error-text ml-24 small-text line-height-double">
                      You have set the latest cancellation date later than
                      COCI's date of{" "}
                      {new Date(latestCancellationDate).toLocaleString(
                        "en-US",
                        {
                          month: "numeric",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        },
                      )}
                      .
                    </p>
                  )}
                </div>
              </div>
            </div>
            {canShluchimIssuePromoCodes && (
              <div className="trip-page-form-section">
                <p className="medium-text fw-700 mb-16 flex flex-align-center">
                  Student Promo Codes
                  <i
                    className="material-icons link-text ml-8 large-text"
                    onClick={() => this.togglePromoCodeModal()}
                  >
                    add_circle
                  </i>
                </p>
                <p className="accent-text-dark mb-4 line-height-double">
                  You can set up promo codes for your students to register at
                  discounted prices.
                </p>
                <p className="accent-text-dark mb-4 line-height-double">
                  Promo code expiration dates will be based in your Chabad
                  House's local timezone.
                </p>
                <p className="accent-text-dark mb-16 line-height-double">
                  Note that if you add any promo codes that will set the price
                  lower than the regular price, you will be required to pay the
                  difference. You will not be responsible for the cost of any
                  public promo codes that are set up by COCI.
                </p>
                <div
                  className={`promo-codes-grid" ${
                    hasEnrollment ? "settings-promo-codes-grid" : ""
                  }`}
                >
                  {!!(enrollmentPromoCodes && enrollmentPromoCodes.length) &&
                    enrollmentPromoCodes.map((promoCode, index) =>
                      this.renderPromoCode(promoCode, index),
                    )}
                </div>
              </div>
            )}
            {this.state.showPromoCodeModal && (
              <PromoCodeModal
                show={this.state.showPromoCodeModal}
                togglePromoCodeModal={this.togglePromoCodeModal}
                onArrayChange={onArrayChange}
                onChange={onChange}
                promoCodes={enrollmentPromoCodes}
                editPromoIndex={this.state.editPromoIndex}
                timezoneID={chabadHouseTimezoneID}
              />
            )}
            {this.state.showDeletePromoModal && (
              <DeletePromoModal
                onArrayChange={onArrayChange}
                index={this.state.deletePromoIndex}
                promoCodes={enrollmentPromoCodes}
                show={this.state.showDeletePromoModal}
                toggleDeletePromoModal={this.toggleDeletePromoModal}
                deletePromoCode={this.deletePromoCode}
              />
            )}
            <div className="trip-page-form-section">
              <p className="medium-text fw-700 mb-16">Special Instructions</p>
              <p className="accent-text-dark mb-16 line-height-double">
                Enter any special instructions that you have for your students
                (custom travel arrangements and public promo codes, etc) that
                you want them to see on the registration form.
              </p>
              <textarea
                className="custom-input"
                name="specialInstructions"
                onChange={(e) => onChange(e.target.name, e.target.value)}
                placeholder="Type here..."
                value={specialInstructions}
              />
            </div>
            {hasEnrollment && ccRequired && !credCardInfo && (
              <div>
                <p className="mb-16 fw-700 medium-text">Payment Method</p>
                <p className="accent-text-dark line-height-double mb-24">
                  Your credit card will be stored on file to cover any expenses
                  from custom pricing or student promo codes.
                </p>
                <CreditCardInfo
                  enrollmentSettings={enrollmentSettings}
                  onChange={onChange}
                  validation={validation}
                />
                <BillingAddress
                  chabadHouseAddress={
                    primaryChabadHouse && primaryChabadHouse.address
                  }
                  countries={countries}
                  enrollmentSettings={enrollmentSettings}
                  familyAddress={familyAddress}
                  onChange={onChange}
                  validation={validation}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
