import { Typography } from "@material-ui/core";
import React from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements";
import Radio from "../../../../components/form/Radio";

export default class CreditCardInfo extends React.PureComponent {
  state = {
    incompleteCardFields: {
      cardNumber: true,
      cardExpiry: true,
      cardCvc: true,
    },
  };

  onChangeCC = ({ elementType, complete }) => {
    this.setState(
      {
        incompleteCardFields: {
          ...this.state.incompleteCardFields,
          [elementType]: !complete,
        },
      },
      () => {
        this.props.onChange(
          "billing.hasCompleteCardInfo",
          !Object.keys(this.state.incompleteCardFields).filter(
            (cardField) => this.state.incompleteCardFields[cardField],
          ).length,
        );
      },
    );
  };

  toggleUseCardOnFile = async (name, useCard) => {
    const { onChange } = this.props;

    onChange(name, useCard);

    if (useCard) {
      this.setState(
        {
          incompleteCardFields: {
            cardNumber: true,
            cardExpiry: true,
            cardCvc: true,
          },
        },
        () => {
          onChange("billing.hasCompleteCardInfo", false, {
            "billing.address": {
              address1: "",
              address2: "",
              country: "",
              city: "",
              state: "",
              zip: "",
            },
            "billing.cardHolderFullName": "",
          });
        },
      );
    }
  };

  render() {
    const {
      billing,
      cardOnFile,
      getCcIcon,
      isReplacingPaymentMethod,
      onChangeEvt,
      submitAttempted,
    } = this.props;
    const { incompleteCardFields } = this.state;

    return (
      <div className="life-insurance-payment-section">
        <Typography className="mb-24" variant="h6">
          Credit Card Info
        </Typography>
        {cardOnFile && !isReplacingPaymentMethod && (
          <Radio
            name="billing.useCardOnFile"
            options={[
              {
                content: cardOnFile && (
                  <div className="flex flex-align-center ml-32 mt-8 mb-16">
                    {getCcIcon(cardOnFile.cardBrand)}
                    {cardOnFile.cardBrand} ending in {cardOnFile.lastFour}
                  </div>
                ),
                display: "Use card on file",
                value: true,
              },
              {
                display: "Enter new card and replace card on file",
                value: false,
              },
            ]}
            onChange={this.toggleUseCardOnFile}
            value={billing.useCardOnFile}
          />
        )}

        {!billing.useCardOnFile && (
          <div className="flex mt-16">
            <form className="cc-info-form full-width">
              <div className="flex flex-align-center mb-12 mobile-block">
                <label className="small-text accent-text">Card Number</label>
                <CardNumberElement
                  className={`custom-input ${
                    submitAttempted && incompleteCardFields.cardNumber
                      ? "error"
                      : ""
                  }`}
                  onChange={this.onChangeCC}
                />
              </div>
              <div className="flex flex-align-center mb-12 mobile-block">
                <label className="small-text accent-text">
                  Cardholder Full Name
                </label>
                <input
                  type="text"
                  className={`custom-input ${
                    submitAttempted && !billing.cardHolderFullName
                      ? "error"
                      : ""
                  }`}
                  placeholder="Full Name"
                  name="billing.cardHolderFullName"
                  value={billing.cardHolderFullName}
                  onChange={onChangeEvt}
                />
              </div>
              <div className="flex flex-align-center mb-12 mobile-block">
                <label className="small-text accent-text">Exp Date</label>
                <CardExpiryElement
                  className={`custom-input ${
                    submitAttempted && incompleteCardFields.cardExpiry
                      ? "error"
                      : ""
                  }`}
                  onChange={this.onChangeCC}
                />
              </div>
              <div className="flex flex-align-center mobile-block">
                <label className="small-text accent-text">CVC Code</label>
                <CardCVCElement
                  className={`custom-input ${
                    submitAttempted && incompleteCardFields.cardCvc
                      ? "error"
                      : ""
                  }`}
                  onChange={this.onChangeCC}
                />
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}
