import React from "react";
import CustomSelect from "../../../../components/form/CustomSelect";
import { RafflePaymentTypes } from "../../constants";

export default function EditOrderPaymentInfo(props) {
  const {
    paymentType,
    cashTypeID,
    cashTypes,
    ccLastFour,
    ccBrand,
    disabled,
    onChange,
    onChangeEvent,
    paymentNotes,
  } = props;

  return (
    <div className="mb-24 new-order-payment-method">
      <p className="fw-700 mb-16">Payment Method</p>
      {paymentType === RafflePaymentTypes.CreditCard ? (
        <p className="small-text">
          Credit Card: {ccBrand || "CC"} *{ccLastFour}
        </p>
      ) : (
        <>
          <div className="flex flex-align-center">
            <label
              className="accent-text small-text mr-24"
              style={{ width: 144, minWidth: "auto" }}
            >
              Payment Method
            </label>
            <CustomSelect
              menuPlacement="top"
              clearable={false}
              name="cashTypeID"
              options={cashTypes.map((type) => ({
                key: type.id,
                value: type.id,
                label: type.name,
              }))}
              onChange={onChange}
              value={cashTypeID}
              disabled={disabled}
            />
          </div>
        </>
      )}
      <div className="flex flex-align-center flex-justify-space mobile-block mt-16">
        <label
          className="accent-text small-text mr-24"
          style={{ width: 144, minWidth: "auto" }}
        >
          Payment Notes (optional)
        </label>
        <input
          type="text"
          className="custom-input full-width"
          name="paymentNotes"
          placeholder="Payment Notes (optional)"
          onChange={onChangeEvent}
          value={paymentNotes}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
