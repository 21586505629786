import React, { useMemo } from "react";
import { formatNumber } from "../../lib/utils";
import moment from "moment";

const ProgressStatusBar = (props) => {
  const {
    daysRemaining,
    endDate,
    startDate, //raffleDays
    raffleGoal,
    isInBonusRound,
    ticketsSold,
    totalRaffleGoal, //raffleGoal
    donorGoal,
    donors, //donorGoal
    type,
    className,
  } = props;

  const { endTag, startTag, progress, bonusProgress } = useMemo(() => {
    let endTag, startTag, progress, bonusProgress;

    if (type === "raffleDays") {
      const raffleDays = moment(endDate).diff(moment(startDate), "days");
      const daysPassed = raffleDays - daysRemaining;
      progress =
        daysPassed > raffleDays
          ? 100
          : daysPassed <= 0
          ? 0
          : (daysPassed / raffleDays) * 100;

      startTag = `${progress ? "Started" : "Starts"} ${moment(startDate).format(
        "MMMM DD",
      )}`;
      endTag = `${!daysRemaining ? "Ended" : "Ends"} ${moment(endDate).format(
        "MMMM DD",
      )}`;
    } else if (type === "raffleGoal") {
      progress =
        ticketsSold >= raffleGoal
          ? (raffleGoal / totalRaffleGoal) * 100
          : (ticketsSold / totalRaffleGoal) * 100;

      startTag = `${formatNumber(ticketsSold)} Ticket${
        ticketsSold === 1 ? "" : "s"
      } Sold`;
      endTag = `Goal: ${formatNumber(raffleGoal)}`;

      if (isInBonusRound) {
        bonusProgress =
          ticketsSold >= totalRaffleGoal
            ? 100
            : (ticketsSold / totalRaffleGoal) * 100;

        endTag = `Original ${endTag} (achieved). Bonus Goal: ${formatNumber(
          totalRaffleGoal,
        )}`;
      }
    } else if (type === "donorGoal") {
      progress = donors >= donorGoal ? 100 : (donors / donorGoal) * 100;

      startTag = `${formatNumber(donors)} Donor${donors === 1 ? "" : "s"}`;
      endTag = `Goal: ${formatNumber(donorGoal)}`;
    }
    return { endTag, startTag, progress, bonusProgress };
  }, [
    daysRemaining,
    donorGoal,
    donors,
    endDate,
    isInBonusRound,
    raffleGoal,
    startDate,
    ticketsSold,
    totalRaffleGoal,
    type,
  ]);

  return (
    <div className={`percentage-bar relative ${className || ""}`}>
      <div className="bar" />
      {!!bonusProgress && (
        <div
          className="filled-in bonus"
          style={{ width: `${bonusProgress}%` }}
        />
      )}
      {!!progress && (
        <div className="filled-in" style={{ width: `${progress}%` }} />
      )}
      <div className="labels flex flex-justify-space mt-8">
        <p className="accent-text small-text">{startTag}</p>
        <p className="accent-text small-text">{endTag}</p>
      </div>
    </div>
  );
};

export default React.memo(ProgressStatusBar);
