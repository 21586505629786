import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { StudentsActions } from "../../../state/students/actions";
import StudentsActivity from "./StudentsActivity";

export default connect(
  function mapState(state) {
    return {
      ...state.students,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...StudentsActions,
        },
        dispatch,
      ),
    };
  },
)(StudentsActivity);
