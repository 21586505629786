const defaultObject: any = {};
export const RsvpSelectors = {
  enrollment(state: any) {
    return state.rsvp.enrollment?.data || defaultObject;
  },
  forChabadHouseID(state: any) {
    return state.rsvp.enrollment?.data?.forChabadHouseID;
  },
  isChabadHouseEnrolledInOutgoingMessaging(state: any) {
    return state.rsvp.enrollment?.data
      ?.isChabadHouseEnrolledInOutgoingMessaging;
  },
  timezoneID(state: any) {
    return state.rsvp.enrollment?.data?.timezoneID;
  },
};
