import React from "react";
import Modal from "react-modal";

export default class ConfidentialityPolicyModal extends React.Component {
  render() {
    const { close, show } = this.props;

    return (
      <Modal isOpen={show} className="modal-container">
        <div className="modal-card card small-modal">
          <p className="large-text fw-700 mb-16">
            COCI's Confidentiality Policy
          </p>
          <div>
            <p className="fw-700 mb-8">
              Shluchim Personal Information Confidentiality Policy
            </p>
            <p className="small-text line-height-double mb-16">
              COCI does not share Shluchim's personal information with any other
              organization or business. In select cases, COCI will share only
              information that is on public record: Mosad and Shliach/Shlucha
              names, Mosad address, Mosad phone number, and Mosad website.
            </p>
            <p className="fw-700 mb-8">
              Information Available Upon Request & Consent
            </p>
            <p className="small-text line-height-double">
              If partner organizations wish to communicate directly with the
              network, COCI will send that request to the network. Each Shliach
              and Shlucha will have the option to opt-in to that correspondence.
            </p>
          </div>
          <div className="modal-btns text-right mt-32">
            <p className="link-text uppercase-text" onClick={close}>
              OK
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}
