import React, { useEffect, useState, createRef } from "react";
import { formatNumber, PageLink } from "../../../../../lib";

export default function EventScheduleCard(props) {
  const {
    eventSchedule: {
      eventScheduleID,
      formattedDate,
      imageURL,
      name,
      numberOfRegistrations,
      numberOfPendingRegistrations,
      occurrenceStatus,
      scheduleType,
      scheduleTypeDisplay,
      totalCapacity,
      eventSettingState,
    },
    pageRoute: { page, params },
  } = props;

  const [eventImageHeight, setEventImageHeight] = useState("auto");

  const eventCardRef = createRef();

  const settingStates = {
    active: "Active",
    paused: "Paused",
    canceled: "Canceled",
  };

  useEffect(() => {
    if (eventCardRef.current) {
      setEventImageHeight(eventCardRef.current.clientWidth / 1.5);
    }
  }, [eventCardRef]);

  return (
    <div className="rsvp-card card" ref={eventCardRef}>
      <img
        alt={name}
        src={imageURL || "/images/rsvp-event-card-placeholder.png"}
        style={{ height: eventImageHeight }}
      />
      <div className="flex flex-justify-space">
        <p className="medium-text fw-700 mb-8">{name}</p>
        <span className="tooltip-container">
          <PageLink
            to={page}
            params={{
              ...params,
              rsvpItemId: "new",
            }}
            query={{
              cloneEventScheduleId: eventScheduleID,
            }}
          >
            <i className="material-icons x-large-text accent-text link-text-secondary ml-8">
              content_copy
            </i>
            <span
              className="tooltip text-center"
              style={{ width: 300, whiteSpace: "normal" }}
            >
              Clone this event and its details. If this is a weekly event, set
              it up as recurring instead.
            </span>
          </PageLink>
        </span>
      </div>

      <div className="flex flex-align-center mb-8">
        <p className="mr-16">
          {scheduleType === "Recurring"
            ? occurrenceStatus === "Upcoming"
              ? "Next event"
              : "Last event"
            : "Date"}
        </p>
        <p>{formattedDate}</p>
      </div>
      <div className="flex flex-justify-space mb-8">
        <div className="flex flex-align-center">
          <p className="mr-16">Registered</p>
          <p>
            {formatNumber(numberOfRegistrations + numberOfPendingRegistrations)}
            {totalCapacity > 0 && ` / ${formatNumber(totalCapacity)}`}
          </p>
        </div>
        {numberOfPendingRegistrations > 0 && (
          <p className="accent-text">
            {formatNumber(numberOfPendingRegistrations)} Pending
          </p>
        )}
      </div>

      <div className="rsvp-card-footer flex flex-justify-space">
        <p
          style={{
            color:
              scheduleTypeDisplay === "One Time"
                ? "#007AB5"
                : eventSettingState === settingStates.paused
                ? "#FFC602"
                : "#63C674",
          }}
        >
          {scheduleTypeDisplay === "Recurring" &&
          eventSettingState === settingStates.paused ? (
            <>{eventSettingState}</>
          ) : (
            <>{scheduleTypeDisplay} </>
          )}
        </p>
        <PageLink
          className="link-text ml-24"
          to={page}
          params={{
            ...params,
            rsvpItemId: eventScheduleID,
          }}
        >
          Manage
        </PageLink>
      </div>
    </div>
  );
}
