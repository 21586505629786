import React from "react";
import { formatNumber, PageLink } from "../../../../../lib";
import { ResourceStates } from "../../../RsvpHelpers";

export default function ResourceCard(props) {
  const {
    resource: {
      resourceID,
      imageURL,
      name,
      numberOfRegistrations,
      numberOfPendingRegistrations,
      totalCapacity,
      state,
    },
    pageRoute: { page, params },
  } = props;

  return (
    <div className="rsvp-resource-card">
      <div className="flex flex-row">
        <img
          alt={name}
          src={imageURL || "/images/rsvp-event-card-placeholder.png"}
        />
        <div className="flex flex-column mt-8">
          <div className="flex flex-align-center mb-8">
            <p className="medium-text fw-700">{name}</p>
            <p className={`resource-status-tag ${state} ml-8`}>
              {state === ResourceStates.Available ? "Active" : "Inactive"}
            </p>
          </div>
          <div className="flex flex-column mb-8">
            <div className="flex flex-align-center">
              <p>
                {formatNumber(
                  numberOfRegistrations + numberOfPendingRegistrations,
                )}
                {totalCapacity > 0 && ` / ${formatNumber(totalCapacity)}`}{" "}
                Registered
              </p>
            </div>
            {numberOfPendingRegistrations > 0 && (
              <p className="accent-text mt-4">
                {formatNumber(numberOfPendingRegistrations)} Pending
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-align-center mobile-flex-justify-end">
        <PageLink
          className="link-text uppercase-text ml-24"
          to={page}
          params={{
            ...params,
            rsvpItemId: resourceID,
          }}
        >
          Manage
        </PageLink>
      </div>
    </div>
  );
}
