import React, { memo } from "react";
import { ListItem, ListItemText, makeStyles } from "@material-ui/core";
import Pages from "../../pages";
import { SubMenuProps } from "./types";

const useStyles = makeStyles((theme) => ({
  title: { paddingLeft: theme.spacing(8) },
}));

function _DirectorySubMenu({ handleMenuClick }: SubMenuProps) {
  const classes = useStyles();

  return (
    <>
      <ListItem
        className={classes.title}
        button
        onClick={() => handleMenuClick(Pages.directory.directory.path)}
      >
        <ListItemText primary="Shluchim" />
      </ListItem>
      <ListItem
        className={classes.title}
        button
        onClick={() => handleMenuClick(Pages.directory.teamsDirectory.path)}
      >
        <ListItemText primary="COCI Team" />
      </ListItem>
    </>
  );
}

export const DirectorySubMenu = memo(_DirectorySubMenu);
