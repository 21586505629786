import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    marginTop: 8,
    padding: "16px 24px 0px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #D8D8DD",
    [theme.breakpoints.down("sm")]: {
      borderTop: "none",
    },
  },
  infoContainer: { padding: "0px 24px" },
  section: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginTop: 8,
    color: "#747579",
    fontFamily: "AvenirMedium",
    fontSize: 12,
    letterSpacing: 0,
  },
  paddingContainer: {
    margin: "8px 0px ",
  },
  bottomSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
