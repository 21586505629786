import Login from "./Login.vm";
import ResetPass from "./ResetPass.vm";

export const AccountPages = {
  login: {
    anon: true,
    path: "/login",
    title: "Login Required",
    type: "PAGE_LOGIN",
    view: Login,
    layout: null,
  },
  resetPass: {
    anon: true,
    path: "/reset-password",
    title: "Reset Password",
    type: "PAGE_RESET_PASS",
    view: ResetPass,
    layout: null,
  },
};
export default AccountPages;

export const AccountArea = {
  pages: AccountPages,
};
