import React from "react";
import EventScheduleCard from "./EventScheduleCard";
import NoEventSchedules from "./NoEventSchedules";
import RsvpItemsList from "../../shared/list/RsvpItemsList";

export default function EventSchedulesList(props) {
  const {
    getEventSchedules,
    eventSchedules,
    hasScheduledEvents,
    pageRoute,
    resultsView,
  } = props;

  return (
    <RsvpItemsList
      rsvpType="events"
      getRsvpItems={getEventSchedules}
      rsvpItems={eventSchedules}
      noRecordsDisplay={
        !hasScheduledEvents && <NoEventSchedules pageRoute={pageRoute} />
      }
      renderCardComponent={(es, esIndex) => (
        <EventScheduleCard
          eventSchedule={es}
          key={esIndex}
          pageRoute={pageRoute}
        />
      )}
      gridClass="rsvp-events-cards-grid"
      pageRoute={pageRoute}
      resultsView={resultsView}
    />
  );
}
