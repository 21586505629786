import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import ColoredLegendEntry from "./ColoredLegendEntry";
import { getJewishInterestLevelColors } from "./engagement/shared";

interface JewishInterestLevelType {
  enumValue: string;
}

export default function JewishInterestLevelsLegend({
  levelsSelector,
  mobileDisplay,
}: any) {
  const jewishInterestLevels: JewishInterestLevelType[] =
    useSelector(levelsSelector);
  return (
    <Box
      className={`flex flex-align-center ${mobileDisplay || "mobile-block"}`}
    >
      {jewishInterestLevels?.map(({ enumValue }, index: number) => (
        <ColoredLegendEntry
          getColors={getJewishInterestLevelColors}
          id={enumValue}
          name={enumValue}
          key={index}
        />
      ))}
    </Box>
  );
}
