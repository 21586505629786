import React from "react";
import StudentsActivity from "./activity/StudentsActivity.vm";
import { withAppInsights } from "../../lib/AppInsights";
import { Box, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  pageContainer: {
    padding: 24,
    paddingBottom: 80,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingBottom: 90,
    },
  },
}));

function StudentsActivityPage(props) {
  const classes = useStyles();
  return (
    <Box className={classes.pageContainer}>
      <StudentsActivity {...props} />
    </Box>
  );
}

export default withAppInsights(StudentsActivityPage);
