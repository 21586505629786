import React from "react";
import { ResourceStates } from "../../../RsvpHelpers";
import RsvpItemsDropdown from "../../shared/manage/RsvpItemsDropdown";

export default function ResourcesDropdown(props) {
  return (
    <RsvpItemsDropdown
      rsvpType="resource"
      getOptionLabel={(s) =>
        s.state === ResourceStates.Unavailable ? (
          <p className="inactive-resource">{s.name} (Inactive)</p>
        ) : (
          s.name
        )
      }
      {...props}
    />
  );
}
