import React from "react";
import { ResourceStates } from "../../../../RsvpHelpers";

export default class AddStudentToResourceButton extends React.PureComponent {
  state = {
    showAddStudentModal: false,
  };

  render() {
    const { showAddStudentModal } = this.state;

    const {
      resource: { isFullyBooked, state },
      registerStudentModal,
    } = this.props;

    return (
      <React.Fragment>
        <div className="tooltip-container ml-8 mobile-ml-0">
          <button
            className="btn btn-accent mr-8"
            disabled={isFullyBooked || state === ResourceStates.Unavailable}
            onClick={() => this.setState({ showAddStudentModal: true })}
          >
            Add Students
          </button>
          {isFullyBooked && (
            <span className="tooltip">
              Students cannot be added to a full resource
            </span>
          )}
          {state === ResourceStates.Unavailable && (
            <span className="tooltip">
              Students cannot be added to an inactive resource
            </span>
          )}
        </div>

        {React.cloneElement(registerStudentModal, {
          close: () => this.setState({ showAddStudentModal: false }),
          show: showAddStudentModal,
        })}
      </React.Fragment>
    );
  }
}
