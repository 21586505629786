import React from "react";
import CourseNotFound from "./CourseNotFound";
import CourseOverview from "./CourseOverview";
import ErrorDisplay from "../../../../../components/ErrorDisplay";
import Loader from "../../../../../components/Loader";
import ManageCourseSchedule from "./ManageCourseSchedule";
import OfferCourse from "./OfferCourse";
import {
  AuthTypes,
  InternPermissions,
  IsApiErrorStatus,
  Navigation,
  PageLink,
  PageURL,
  doArraysIntersect,
} from "../../../../../lib";
import { EduProgramTypes, getEduProgramLogoSrc } from "../../../eduUtils";
import { EduCourseViews, EduCourseListTypes } from "../../../constants";

const courseViews = {
  [EduCourseViews.manage]: {
    component: ManageCourseSchedule,
    externalUserAuthorization: [
      InternPermissions.eduAttendance,
      InternPermissions.eduSchedule,
      InternPermissions.eduStudents,
    ],
  },
  [EduCourseViews.offer]: {
    component: OfferCourse,
    externalUserAuthorization: [InternPermissions.eduSchedule],
  },
  [EduCourseViews.overview]: {
    component: CourseOverview,
    externalUserAuthorization: [InternPermissions.eduSchedule],
  },
};

export default class CourseContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.isShliach = props.auth.loggedInAs === AuthTypes.shliach;
    this.userClaims = props.auth.permissionClaims;
  }

  componentDidMount() {
    this.getCourse();
  }

  // verify non-shliach is authorized for the selected courseView. otherwise, redirect
  componentDidUpdate() {
    const {
      pageRoute: {
        params: { courseView },
      },
    } = this.props;
    if (!!courseView && !this.isShliach) {
      const isAuthorizedForCourseView = doArraysIntersect(
        this.userClaims,
        courseViews[courseView]?.externalUserAuthorization,
      );
      if (!isAuthorizedForCourseView) {
        Navigation.redirect("/404");
      }
    }
  }

  componentWillUnmount() {
    this.props.actions.clearEduCourse();
    this.props.actions.clearEduCourseSchedule();
  }

  getCourse = () => {
    const {
      pageRoute: {
        params: { courseId },
      },
    } = this.props;

    this.props.actions.getEduCourse(courseId);
  };

  getCourseSchedule = async () => {
    const {
      course: { data: { id: prevCourseId } = {} },
      pageRoute: { page, params, query },
    } = this.props;
    const { courseScheduleId } = params;

    if (courseScheduleId) {
      let updatedCourseId;

      await this.props.actions.getEduCourseSchedule(
        courseScheduleId,
        //shouldGetCourse param: to retrieve course details if schedule selection is for different course from previous schedule
        (courseId) => {
          if (courseId && courseId.toString() !== prevCourseId.toString()) {
            updatedCourseId = courseId;
            return true;
          }
        },
      );

      //if course has been updated for schedule selection, update navigation param
      if (updatedCourseId) {
        Navigation.redirect(
          PageURL.to(
            page,
            {
              ...params,
              courseId: updatedCourseId,
            },
            query,
          ),
        );
      }
    }
  };

  render() {
    const {
      course: { error, errorMessage, loading, data: course },
      enrollment: {
        data: { eduProgramType },
      },
      pageRoute: { page, params },
    } = this.props;
    const { courseView } = params;

    return loading ? (
      <div className="full-page-loader">
        <Loader />
      </div>
    ) : error ? (
      IsApiErrorStatus(error, 404) ? (
        <CourseNotFound logoSrc={getEduProgramLogoSrc(eduProgramType)} />
      ) : (
        <ErrorDisplay
          errorMessage={
            errorMessage ||
            "Something went wrong and we could not retrieve course information. Please try again."
          }
        />
      )
    ) : course ? (
      !course.isOpen && courseViews[courseView] !== courseViews.manage ? (
        //do not allow access to course overview or offer course pages for closed course
        <div>
          <CourseNotFound
            display={
              eduProgramType === EduProgramTypes.JewishU && (
                <div className="large-text">
                  <PageLink
                    className="btn btn-accent btn-large"
                    style={{ padding: "0 40px", maxWidth: "98%" }}
                    params={{
                      ...params,
                      courseId: undefined,
                      courseView: undefined,
                    }}
                    query={{ type: EduCourseListTypes.all }}
                    to={page}
                  >
                    Explore available courses
                  </PageLink>
                </div>
              )
            }
            logoSrc={getEduProgramLogoSrc(eduProgramType)}
            title="Sorry, this course is no longer available"
          />
        </div>
      ) : (
        React.createElement(
          courseViews[courseView]?.component || courseViews.overview.component,
          {
            ...this.props,
            getCourseSchedule: this.getCourseSchedule,
          },
        )
      )
    ) : null;
  }
}
