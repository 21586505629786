import React, { memo } from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
import { formatNumber, pluralizeText } from "../../../../lib";

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  total: { marginBottom: 8, color: "#747579" },
}));

interface ChartHeaderProps {
  dataTotal: number;
  loading: boolean;
}

function _StudentsMetricsChartHeader(props: ChartHeaderProps) {
  const { loading, dataTotal } = props;
  const classes = useStyles();
  return (
    <Box className={classes.headerContainer}>
      <Typography variant="subtitle1">Students</Typography>
      <Typography variant="body2" className={classes.total}>
        Unique Students Total:{" "}
        {!loading
          ? `${formatNumber(dataTotal)} ${pluralizeText("student", dataTotal)}`
          : ""}
      </Typography>
    </Box>
  );
}

export const StudentsMetricsChartHeader = memo(_StudentsMetricsChartHeader);
