import { Box, Typography } from "@material-ui/core";
import React, { memo } from "react";

interface NoMetricsProps {
  message: string;
}

function NoMetrics({ message }: NoMetricsProps) {
  return (
    <Box className="chart-no-results">
      <img src="/images/no_results.svg" alt="no results" height="120" />
      <Typography className="accent-text mt-8">{message}</Typography>
    </Box>
  );
}

export default memo(NoMetrics);
