import React from "react";
import { Box, Typography, DialogTitle, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

interface DialogHeaderProps {
  title?: string;
  disableClose?: boolean;
  handleClose: () => void;
}

function _DialogHeader(props: DialogHeaderProps) {
  const { title = "", handleClose, disableClose = false } = props;

  return (
    <DialogTitle>
      <Box className="flex flex-align-center flex-justify-space">
        <Typography variant="h4">{title}</Typography>
        <IconButton size="small" disabled={disableClose} onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
    </DialogTitle>
  );
}

export const DialogHeader = React.memo(_DialogHeader);
