import React from "react";
import Modal from "react-modal";

export default class CancelRecurringSettingsModal extends React.PureComponent {
  render() {
    const { onSubmit, show, onCancel } = this.props;

    return (
      <Modal isOpen={show} className="modal-container">
        <div className="modal-card card small-modal">
          <div className="flex flex-justify-space">
            <p className="large-text fw-700 mb-24">Cancel Recurring Event</p>
            <i className="material-icons pointer" onClick={onCancel}>
              close
            </i>
          </div>
          <p className="line-height-double">
            The event that you are canceling is a recurring event. Would you
            like to cancel all future events?
          </p>
          <div className="modal-btns relative">
            <p className="link-text" onClick={() => onSubmit(false)}>
              <span className="block fw-700 uppercase-text mb-4">No</span>{" "}
              <span className="small-text">Cancel just this event</span>
            </p>
            <p className="link-text ml-40" onClick={() => onSubmit(true)}>
              <span className="block fw-700 uppercase-text mb-4">Yes</span>{" "}
              <span className="small-text">Cancel future events</span>
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}
