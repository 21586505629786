import React from "react";
import { getNameDisplay } from "../../../../lib/utils";

export default ({ child }) => (
  <div className="kinus-form-section child">
    <p className="medium-text fw-700 mb-24">
      {getNameDisplay(child.person.firstName, child.person.lastName)}
    </p>
    <p className="accent-text-dark">
      We don't offer childcare for children under 4 months of age.
    </p>
  </div>
);
