import React from "react";
import { AuthRequest, PageLink } from "../lib";
import Pages from "../pages";

export default class ProgramBreadcrumbsHeader extends React.PureComponent {
  state = {
    error: null,
    loading: false,
    programSchedule: null,
  };

  _mounted = true;

  componentDidMount() {
    if (this.props.scheduleId) {
      this.getProgramSchedule();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(prevProps) {
    const { scheduleId } = this.props;

    if (prevProps.scheduleId !== scheduleId) {
      this.getProgramSchedule();
    }
  }

  getProgramSchedule = async () => {
    if (!this._mounted) {
      return;
    }

    const { scheduleId } = this.props;
    if (scheduleId) {
      try {
        //For now we are disregarding error and loading - not going to show anything if this fails
        this.setState({
          error: null,
          loading: true,
        });

        const {
          data: { payload: programSchedule },
        } = await AuthRequest.get(`programs/schedules/${scheduleId}/basic`);

        if (this._mounted) {
          this.setState({
            loading: false,
            programSchedule,
          });
        }
      } catch (err) {
        if (this._mounted) {
          this.setState({
            error: err,
            loading: false,
            programSchedule: null,
          });
        }
      }
    } else if (this._mounted) {
      this.setState({
        error: null,
        loading: false,
        programSchedule: null,
      });
    }
  };

  render() {
    const { className, getPageTitle, getPrevPages, pageTitle, prevPages } =
      this.props;

    const { programSchedule } = this.state;
    const { programID, programName, scheduleID, scheduleName } =
      programSchedule || {};

    return (
      <div
        className={`accent-text-dark small-text mb-16 breadcrumbs-container line-height-double ${
          className || ""
        }`}
      >
        {!!programSchedule && (
          <>
            <PageLink className="link-text" to={Pages.programs.programs}>
              Programs
            </PageLink>
            {!!programID && programName && (
              <>
                {" "}
                &gt;{" "}
                <PageLink
                  className="link-text"
                  to={Pages.programs.programDetails}
                  params={{ programId: programID }}
                  query={{ sched: scheduleID }}
                >
                  {programName}
                </PageLink>
              </>
            )}
            {((getPrevPages && getPrevPages(programSchedule)) || prevPages) &&
              (
                (getPrevPages && getPrevPages(programSchedule)) ||
                prevPages
              ).map((page, index) => (
                <React.Fragment key={index}>
                  {" "}
                  &gt;{" "}
                  <PageLink className="link-text" to={page.path}>
                    {page.title}
                  </PageLink>
                </React.Fragment>
              ))}
            {(getPageTitle || pageTitle || scheduleName) && (
              <>
                {" "}
                &gt;{" "}
                <span>
                  {(getPageTitle && getPageTitle(programSchedule)) ||
                    pageTitle ||
                    scheduleName}
                </span>
              </>
            )}
          </>
        )}
      </div>
    );
  }
}
