import React from "react";
import EventSchedulesList from "./EventSchedulesList";
import EventSchedulesListFilters from "./EventSchedulesListFilters";

export default class EventSchedulesListContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.listResultsView = props.ui.isPhone ? 10 : 18;
  }

  componentDidUpdate(prevProps) {
    const {
      pageRoute: {
        query: { type: listType },
      },
    } = this.props;
    if (listType !== prevProps.pageRoute.query.type) {
      this.getEventSchedules();
    }
  }

  getEventSchedules = () => {
    const {
      pageRoute: {
        query: { oc = "upcoming", p = 1, se, so = "date", ss = ["Active"] },
      },
    } = this.props;

    this.props.actions.getRsvpEventSchedules(p, this.listResultsView, {
      [this.eventSchedulesFilterDefinitions.oc]: oc,
      [this.eventSchedulesFilterDefinitions.se]: se && decodeURIComponent(se),
      [this.eventSchedulesFilterDefinitions.so]: so,
      [this.eventSchedulesFilterDefinitions.ss]: ss,
    });
  };

  eventSchedulesFilterDefinitions = {
    oc: "occurrenceStatus",
    p: "page",
    se: "search",
    so: "sortBy",
    ss: "settingStates",
  };

  render() {
    const {
      enrollment: {
        data: {
          chabadHouseName,
          hasScheduledEvents,
          id: rsvpEnrollmentID,
        } = {},
      },
      eventSchedules,
      pageRoute,
    } = this.props;

    return (
      <React.Fragment>
        <div
          className="flex mobile-block flex-justify-space rsvp-events-list-card"
          style={{ width: "100%" }}
        >
          <div className="rsvp-list-container">
            <div>
              <EventSchedulesListFilters
                chabadHouseName={chabadHouseName}
                getEventSchedules={this.getEventSchedules}
                hasScheduledEvents={hasScheduledEvents}
                pageRoute={pageRoute}
                rsvpEnrollmentID={rsvpEnrollmentID}
              />
            </div>
            <div className="program-form-section full-width">
              <EventSchedulesList
                eventSchedules={eventSchedules}
                getEventSchedules={this.getEventSchedules}
                hasScheduledEvents={hasScheduledEvents}
                pageRoute={pageRoute}
                resultsView={this.listResultsView}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
