import React from "react";
import { getNameDisplay } from "../../../../lib/utils";

export default ({ child }) => (
  <div className="kinus-form-section child">
    <p className="medium-text fw-700 mb-24">
      {getNameDisplay(child.person.firstName, child.person.lastName)}
    </p>
    <p className="accent-text-dark">
      Your child's age group is unrecognized. For assistance, please contact us
      at{" "}
      <a
        href="mailto:help@chabadoncampus.org"
        className="link-text"
        target="_blank"
        rel="noopener noreferrer"
      >
        help@chabadoncampus.org
      </a>{" "}
      or{" "}
      <a href="tel:+1-718-510-8181,0" className="link-text">
        718 510 8181 x 0
      </a>
      .
    </p>
  </div>
);
