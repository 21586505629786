import React, { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  SwipeableDrawer,
  IconButton,
  Divider,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  Avatar,
  Typography,
} from "@material-ui/core";
import { ArrowBack, ArrowForward, Close, Menu } from "@material-ui/icons";
import { useStyles } from "./MobileMenu.styles";
import { Navigation } from "../../lib";
import { AuthActions, useAction } from "../../state";
import {
  DirectorySubMenu,
  EngagementSubMenu,
  ProgramsSubMenu,
  subMenuOptions,
  getMenuItems,
} from "./";
import { AuthSelectors } from "../../state/auth/selectors";
import Pages from "../../pages";
import SystemShortcutsMenu from "../SystemShortcutsMenu";
import { ShortcutsSubMenu } from "./ShortcutsSubMenu";

function MobileMenu({ routes }) {
  const classes = useStyles();

  const { firstName, lastName } = useSelector(AuthSelectors.user);
  const avatarText = `${firstName?.substring(0, 1) || ""}${
    lastName?.substring(0, 1) || ""
  }`;

  const signout = useAction(AuthActions.doSignout);

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const handleOpen = useCallback((event) => {
    setMenuAnchor(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const handleMenuClick = useCallback(
    (url) => {
      Navigation.go(url);
      handleClose();
    },
    [handleClose],
  );

  return (
    <>
      <Button onClick={handleOpen}>
        <Menu />
      </Button>

      <SwipeableDrawer
        anchor="top"
        open={!!menuAnchor}
        onClose={handleClose}
        onOpen={handleOpen}
        classes={{ paper: classes.drawerPaper }}
      >
        <Box className={classes.drawerHeader}>
          <Box className={classes.flexBox}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
            <Typography>ב״ה</Typography>
          </Box>
          <Box className={classes.headerBox}>
            <Avatar className={classes.avatar} size="small">
              <Typography variant="subtitle2">{avatarText}</Typography>
            </Avatar>
            <Box>
              <Typography variant="subtitle2">
                {firstName} {lastName}
              </Typography>
              <Button
                variant="text"
                size="small"
                color="primary"
                onClick={() => handleMenuClick(Pages.profile.profile.path)}
                className={classes.link}
              >
                View Account
              </Button>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className={classes.bottomSection}>
          <List>
            {activeSubMenu ? (
              <Collapse in={!!activeSubMenu} timeout="auto" unmountOnExit>
                <ListItem
                  button
                  onClick={() =>
                    activeSubMenu === subMenuOptions.shortcutsMenu
                      ? setActiveSubMenu(subMenuOptions.engagementMenu)
                      : setActiveSubMenu("")
                  }
                >
                  <IconButton>
                    <ArrowBack />
                  </IconButton>
                  <ListItemText primary={activeSubMenu} />
                </ListItem>
                {activeSubMenu === subMenuOptions.directoryMenu ? (
                  <DirectorySubMenu handleMenuClick={handleMenuClick} />
                ) : activeSubMenu === subMenuOptions.engagementMenu ? (
                  <EngagementSubMenu
                    setActiveSubMenu={setActiveSubMenu}
                    handleMenuClick={handleMenuClick}
                  />
                ) : activeSubMenu === subMenuOptions.programMenu ? (
                  <ProgramsSubMenu
                    routes={routes}
                    handleMenuClick={handleMenuClick}
                  />
                ) : activeSubMenu === subMenuOptions.shortcutsMenu ? (
                  <ShortcutsSubMenu handleMenuClick={handleMenuClick} />
                ) : (
                  <></>
                )}
              </Collapse>
            ) : (
              <>
                {getMenuItems().map(({ text, url, subMenu }) => (
                  <ListItem
                    key={text}
                    button
                    onClick={() => {
                      subMenu
                        ? setActiveSubMenu(subMenu)
                        : handleMenuClick(url);
                    }}
                  >
                    <ListItemText primary={text} />
                    {subMenu && (
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          onClick={() => setActiveSubMenu(subMenu)}
                        >
                          <ArrowForward />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}

                <ListItem button onClick={signout}>
                  <ListItemText primary="Sign out" />
                </ListItem>

                <ListItem>
                  <SystemShortcutsMenu buttonText="Add" btnSize="small" />
                </ListItem>
              </>
            )}
          </List>
          <Box className={classes.drawerFooter}>
            <p className="flex flex-align-center">
              <span className="block">
                <a
                  href="mailto:help@chabadoncampus.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>help@chabadoncampus.org</span>
                </a>
              </span>
              <span className="accent-text ml-8 mr-8">|</span>
              <span className="block">
                <a href="tel:718-510-8181">
                  <span className="no-wrap">718-510-8181 ext 0</span>
                </a>
              </span>
            </p>
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default memo(MobileMenu);
