import Cookies from "js-cookie";

const cookieName = "shliach-impersonation-auth";

const getCookieAttributes = () => {
  const domainParts = window.location.hostname.split(".");
  const rootDomain = "." + domainParts.slice(1, domainParts.length).join(".");

  return {
    domain: rootDomain,
    sameSite: "Strict",
    secure: true,
  };
};

export function hasImpersonationAuthCookie() {
  return !!Cookies.get(cookieName);
}

export function getImpersonationAuthCookie() {
  const authCookie = Cookies.get(cookieName);
  return authCookie ? JSON.parse(authCookie) : null;
}

export function removeImpersonationAuthCookie() {
  Cookies.remove(cookieName, getCookieAttributes());
}
