import { createBrowserHistory } from "history";
import { addLeadingSlash } from "./PathUtils";
import ScrollBehavior from "scroll-behavior";

import ScrollStateStorage from "./ScrollStateStorage";

/** @type {string} */
let _currentKey;
/** @type {{params:object,isExact:boolean,path:string,url:string}} */
let _currentMatch;
/** @type {{anon:boolean,path:string,type:string,view:React.ComponentType}} */
let _currentPage;
/** @type {{[x:string]:string}} */
let _currentParams;
/** @type {{[x:string]:string}} */
let _currentQuery;
/** @type {History} */
let _history;
/** @type {import('scroll-behavior').default} */
let _scrollBehavior;

export const Navigation = {
  /** The navigation history API.
   * @type {History}
   */
  get history() {
    return _history;
  },
  /** The current location.
   * @type {Location}
   */
  get location() {
    return _history.location;
  },
  /** The current relative URL (pathname + search + hash). */
  get locationURL() {
    const loc = _history.location;
    return loc.pathname + loc.search + loc.hash;
  },

  /** Initialize navigation.
   * @param {NavigationOptions} [config]
   */
  init(config = {}) {
    configureHistory(config);
    configureScrollBehavior(config);
    return Navigation;
  },

  // #region Core Navigation
  /** Navigates to the given `url` via the HTML5 History API.
   * @param {string} url
   * @param {object} [state]
   */
  go(url, state) {
    _history.push(url, state);
    return true;
  },
  /** Navigates backward via the HTML5 History API. */
  goBack() {
    _history.goBack();
    return true;
  },
  /** Navigates forward via the HTML5 History API. */
  goForward() {
    _history.goForward();
    return true;
  },
  /** Navigates to the given `url` via `window.location.assign`.
   *
   * **Warning: This unloads the current browser window.**
   *
   * @param {string} url
   */
  load(url) {
    window.location.assign(url);
    return true;
  },
  /** Navigates to the given `url`, replacing the current spot in history
   * with the new `url` via the HTML5 History API.
   * @param {string} url
   * @param {object} [state]
   */
  redirect(url, state) {
    _history.replace(url, state);
    return true;
  },
  /** Navigates to the given `url` via `window.location.replace`.
   *
   * **Warning: This unloads the current browser window.**
   *
   * @param {string} url
   */
  reload(url) {
    window.location.replace(url);
    return true;
  },
  // #endregion

  // #region Current Route Info

  /** The current route key. */
  get key() {
    return _currentKey;
  },
  /** The current route match. */
  get match() {
    return _currentMatch;
  },
  /** The current page. */
  get page() {
    return _currentPage;
  },
  /** The current route path params. */
  get params() {
    return _currentParams;
  },
  /** The current route query params. */
  get query() {
    return _currentQuery;
  },

  /** Call after the route changes, to update route properties.
   * @param {ExtendedRouteProps} route Extended route props (from PageLoader).
   */
  routeChanged(route) {
    const { key, match, page, params, query } = route;
    _currentKey = key;
    _currentMatch = match;
    _currentPage = page;
    _currentParams = params;
    _currentQuery = query;
  },
  // #endregion

  /** Call after a page transition to start track the scroll position for
   * the current URL and possibly restore the scroll position.
   * @param {{location:object}} prevRoute The previous route.
   * @param {{location:object}} route The current route.
   */
  updateScroll(prevRoute, route) {
    const prevLoc = prevRoute ? prevRoute.location : {};
    const loc = route ? route.location : {};
    _scrollBehavior.updateScroll(prevLoc, loc);
  },
};
export default Navigation;

/** @param {NavigationOptions} config */
function configureHistory(config) {
  let { basename = process.env.REACT_APP_BASENAME || "/", listen } = config;
  _history = createBrowserHistory({
    basename: addLeadingSlash(basename),
  });
  //listen function for google analytics page tracking
  //because we are using app-insights withTracking HOC for other analytics we will be tracking google analytics through the browser navigation instead of using GA withTracking HOC
  if (listen) {
    _history.listen(listen);
  }
}

function configureScrollBehavior() {
  let stateStorage = new ScrollStateStorage();
  _scrollBehavior = new ScrollBehavior({
    addTransitionHook: _history.listen,
    stateStorage,
    getCurrentLocation: getCurrentLocationForScrollBehavior,
  });
}

function getCurrentLocationForScrollBehavior() {
  return _history.location;
}

if (process.env.NODE_ENV === "development") {
  window.Navigation = Navigation;
}

// #region Typedefs
/** @typedef {import("history").BrowserHistoryBuildOptions} BrowserHistoryBuildOptions */
/** @typedef {(confirmed:boolean)=> void} ConfirmationCallback */
/** @typedef {import("history").History} History */
/** @typedef {'INITIAL' | import("history").Action} HistoryAction */
/** @typedef {import("history").Location} Location */
/** @typedef {object} NavigationOptions
 * @property {string} [basename] The base URL of the app.
 * @property {boolean} [forceRefresh] Set true to force full page refreshes.
 * @property {(message:string,callback:ConfirmationCallback)=> void} [getUserConfirmation] A function to use to confirm navigation with the user.
 * @property {} [keyLength] The length of `location.key`.
 */
/** @typedef {object} ExtendedRouteProps
 * @property {History} history
 * @property {string} key
 * @property {{key:string,pathname:string,search:string,hash:string,state:object}} [location]
 * @property {{params:object,isExact:boolean,path:string,url:string}} [match]
 * @property {{anon:boolean,path:string,type:string,view:React.ComponentType}} page
 * @property {{[x:string]:string}} params
 * @property {{[x:string]:string}} query
 */
// #endregion
