import { pluralizeText } from "../../lib";
import moment from "moment";

export const getGrantsRequirements = (grantSchedule, grantId) => {
  const { grants } = grantSchedule;

  if (grantId) {
    const grant = grants.find((g) => g.id === grantId);

    if (!grant) {
      return "";
    }

    return getRequirements(grantSchedule, grant);
  }

  return grants.map((grant) => ({
    grantName: grant.name,
    requirements: getRequirements(grantSchedule, grant),
  }));
};

const getRequirements = (grantSchedule, grant) => {
  const { programEndDate, programStartDate } = grantSchedule;
  const {
    classificationRequirements,
    maxStudentAge,
    minInteractionsRangeWeeks,
    minStudentAge,
    type,
    typeDisplay,
  } = grant;

  const grantRequirements = [];

  //student-related requirements;
  grantRequirements.push(
    ...[
      "Student must be Halachically Jewish",
      `Student cannot have participated in a past ${typeDisplay} grant`,
    ],
  );
  if (type === "Learning") {
    grantRequirements.push(
      "Student cannot be enrolled in a current or past Sinai Scholars Program, or plan to enroll in the future",
    );
  }
  // interaction-related requirements
  grantRequirements.push(
    ...[
      `Interactions must take place within the program dates of ${moment(
        programStartDate,
      ).format("MMM D, YYYY")} - ${
        programEndDate ? moment(programEndDate).format("MMM D, YYYY") : "TBD"
      }`,
      `Interactions must take place over a range of at least ${minInteractionsRangeWeeks} ${pluralizeText(
        "week",
        minInteractionsRangeWeeks,
      )}`,
    ],
  );
  if (minStudentAge || maxStudentAge) {
    const ageRequirementDescription =
      minStudentAge && maxStudentAge
        ? `between ${minStudentAge} and ${maxStudentAge} years`
        : minStudentAge
        ? `older than ${minStudentAge}`
        : `younger than ${maxStudentAge}`;
    grantRequirements.push(
      `Student must be ${ageRequirementDescription} at the time of the interactions`,
    );
  }
  if (classificationRequirements && classificationRequirements.length) {
    classificationRequirements.forEach((classificationRequirement) => {
      const {
        classifications,
        israelRelatedMin,
        minDurationDisplay,
        numRequiredInteractions,
        shabbosMealMax,
      } = classificationRequirement;
      grantRequirements.push(
        `At least ${numRequiredInteractions} ${classifications.join(
          " or ",
        )} ${pluralizeText("interaction", numRequiredInteractions)} ${
          numRequiredInteractions > 1 ? "are required, each" : "is required,"
        } with a minimum duration of ${minDurationDisplay}`,
      );
      if (israelRelatedMin) {
        grantRequirements.push(
          `At least ${israelRelatedMin} Israel/Antisemitism related ${classifications.join(
            " or ",
          )} ${pluralizeText("interaction", israelRelatedMin)} ${
            israelRelatedMin > 1 ? "are" : "is"
          } required`,
        );
      }
      if (shabbosMealMax) {
        grantRequirements.push(
          `A maximum of ${shabbosMealMax} Shabbos meal ${classifications.join(
            " or ",
          )} ${pluralizeText("interaction", shabbosMealMax)} ${
            shabbosMealMax > 1 ? "are" : "is"
          } allowed`,
        );
      }
    });
  }

  return grantRequirements;
};
