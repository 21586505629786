import React from "react";
import BeneficiaryDesignationDocuments from "./enrollment/BeneficiaryDesignationDocuments";
import ConfirmationModal from "../../components/ConfirmationModal";
import ConfirmEnrollmentInfoModal from "./enrollment/ConfirmEnrollmentInfoModal";
import EnrollmentActions from "./enrollment/EnrollmentActions";
import EnrollmentHeader from "./enrollment/EnrollmentHeader";
import Payment from "./enrollment/payment/Payment";
import PendingRenewalModal from "./enrollment/PendingRenewalModal";
import Profile from "./enrollment/Profile";

import _cloneDeep from "lodash.clonedeep";
import _set from "lodash.set";
import { injectStripe } from "react-stripe-elements";
import { getFormattedValuesForForm, replaceValuesInObject } from "../../lib";

class MyLifeInsurance extends React.PureComponent {
  constructor(props) {
    super(props);

    const { enrollment } = this.props;

    this.state = {
      ...this.getInitializedEnrollment(enrollment),
      enrollmentActionType: null,

      errorMessage: "",
      loading: false,
      //Maintaining loading and errormessage in local state for optimal control

      showConfirmEnrollmentActionModal: false,
      showConfirmEnrollmentInformationModal: false,
      showPendingRenewalModal: false,
      showReplacePaymentMethod: false,
      showReplacePaymentMethodSuccessMessage: false,

      submitAttempted: false,
      subscriptionAgreementAccepted: false,
    };
  }

  incompleteErrorMessage = "Please complete required fields";

  enrollmentActionTypes = {
    Enrollment: "Enrollment",
    TerminationRequest: "TerminationRequest",
    UpdatePaymentMethod: "UpdatePaymentMethod",
    UpdatePaymentMethodAndProcess: "UpdatePaymentMethodAndProcess",
  };

  enrollmentStatuses = {
    Active: "Active",
    FailedRenewal: "FailedRenewal",
    PendingActivation: "PendingActivation",
    PendingTermination: "PendingTermination",
    Terminated: "Terminated",
  };

  getInitializedEnrollment = (enrollment) => {
    const initializedEnrollment = _cloneDeep(enrollment);
    if (!enrollment.id) {
      initializedEnrollment.shliach = enrollment.shliach
        ? getFormattedValuesForForm(enrollment.shliach)
        : "";
      initializedEnrollment.shlucha = enrollment.shlucha
        ? getFormattedValuesForForm(enrollment.shlucha)
        : "";
      initializedEnrollment.billing = this.getInitializedBilling(enrollment);
    } else {
      //instantiating billing/shliach/shlucha to empty strings if no initilization is needed for destructuring purposes
      initializedEnrollment.billing = "";
      if (!enrollment.shliach) initializedEnrollment.shliach = "";
      if (!enrollment.shlucha) initializedEnrollment.shlucha = "";
    }

    return {
      enrollment: initializedEnrollment,
      initialEnrollment: _cloneDeep(initializedEnrollment),
    };
  };

  getInitializedBilling = (enrollment) => {
    const { account } = this.props;

    return {
      address: {
        address1: "",
        address2: "",
        country: "",
        city: "",
        state: "",
        zip: "",
      },
      cardHolderFullName: "",
      useCardOnFile: !enrollment.id && !!(account && account.credCardInfo),
    };
  };

  onChangeEnrollment = (name, value, otherChanges) => {
    let enrollment = _cloneDeep(this.state.enrollment);
    _set(enrollment, name, value);

    if (otherChanges) {
      Object.keys(otherChanges).forEach((change) =>
        _set(enrollment, change, otherChanges[change]),
      );
    }

    return new Promise((resolve, reject) => {
      this.setState({ enrollment }, () => {
        resolve();

        //if required fields message is shown, re-validate on change
        const { errorMessage } = this.state;
        if (errorMessage && errorMessage === this.incompleteErrorMessage) {
          const isValid = this.validateEnrollment();
          if (isValid) {
            this.setState({
              errorMessage: "",
            });
          }
        }
      });
    });
  };

  onChangeEnrollmentEvt = ({ target: { name, value } }) => {
    return this.onChangeEnrollment(name, value);
  };

  onCancelEnrollmentChanges = () => {
    this.setState({ showConfirmCancelEnrollmentChangesModal: true });
  };

  cancelEnrollmentChanges = () => {
    this.setState({
      enrollment: _cloneDeep(this.state.initialEnrollment),
      errorMessage: "",
      showConfirmCancelEnrollmentChangesModal: false,
    });
  };

  onSubmitEnrollment = async (enrollmentActionType) => {
    this.setState({
      errorMessage: "",
      submitAttempted: true,
    });

    const isValid = this.validateEnrollment(enrollmentActionType);
    if (!isValid) {
      this.setState({
        errorMessage: this.incompleteErrorMessage,
      });
      return;
    }

    const {
      enrollment: { billing },
    } = this.state;

    //get stripe token for payment method processing
    if (
      !billing.useCardOnFile &&
      (enrollmentActionType === this.enrollmentActionTypes.Enrollment ||
        enrollmentActionType ===
          this.enrollmentActionTypes.UpdatePaymentMethod ||
        enrollmentActionType ===
          this.enrollmentActionTypes.UpdatePaymentMethodAndProcess)
    ) {
      this.setState({ loading: true });

      const {
        stripe: { createToken },
      } = this.props;

      const stripeValues = {
        name: billing.cardHolderFullName,
        address_line1: billing.address.address1,
        address_line2: billing.address.address2,
        address_city: billing.address.city,
        address_state: billing.address.state,
        address_zip: billing.address.zip,
        address_country: billing.address.country,
      };
      if (createToken) {
        try {
          let { token } = await createToken(stripeValues);
          if (token) {
            this.onChangeEnrollment("billing.stripeToken", token);
          }
        } catch (err) {
          this.setState({
            errorMessage:
              "Sorry, something went wrong and your payment could not be processed.  Please try again.",
            loading: false,
          });
          return;
        }
      }

      this.setState({ loading: false });
    }

    this.setState({
      enrollmentActionType,
      showConfirmEnrollmentActionModal: true,
    });
  };

  validateEnrollment = (enrollmentActionType) => {
    if (
      enrollmentActionType === this.enrollmentActionTypes.TerminationRequest
    ) {
      return true; //No validation for Termination Request bec no enrollment info is changed
    }

    const {
      enrollment: {
        billing: {
          address: { address1, city, country },
          cardHolderFullName,
          hasCompleteCardInfo,
          useCardOnFile,
        },
        shliach: {
          beneficiaryDesignationDocument: shliachBeneficiaryDesignationDocument,
          beneficiaryDesignationDocumentURL:
            shliachBeneficiaryDesignationDocumentURL,
          dob: shliachDob,
          isEnrolled: isShliachEnrolled,
          legalFirstName: shliachLegalFirstName,
          legalLastName: shliachLegalLastName,
        },
        shlucha: {
          beneficiaryDesignationDocument: shluchaBeneficiaryDesignationDocument,
          beneficiaryDesignationDocumentURL:
            shluchaBeneficiaryDesignationDocumentURL,
          dob: shluchaDob,
          isEnrolled: isShluchaEnrolled,
          legalFirstName: shluchaLegalFirstName,
          legalLastName: shluchaLegalLastName,
        },
      },
      subscriptionAgreementAccepted,
    } = this.state;

    if (
      (!useCardOnFile &&
        (!address1 ||
          !city ||
          !country ||
          !hasCompleteCardInfo ||
          !cardHolderFullName)) ||
      (enrollmentActionType === this.enrollmentActionTypes.Enrollment &&
        (!subscriptionAgreementAccepted ||
          (!isShliachEnrolled && !isShluchaEnrolled) ||
          (isShliachEnrolled &&
            ((!shliachBeneficiaryDesignationDocument &&
              !shliachBeneficiaryDesignationDocumentURL) ||
              !shliachDob ||
              !shliachLegalFirstName ||
              !shliachLegalLastName)) ||
          (isShluchaEnrolled &&
            ((!shluchaBeneficiaryDesignationDocument &&
              !shluchaBeneficiaryDesignationDocumentURL) ||
              !shluchaLegalFirstName ||
              !shluchaLegalLastName ||
              !shluchaDob))))
    ) {
      return false;
    }

    return true;
  };

  submitEnrollment = async () => {
    const { enrollment, enrollmentActionType } = this.state;
    const enrollmentToSubmit = _cloneDeep(enrollment);

    this.setState({
      loading: true,
      showConfirmEnrollmentActionModal: false,
      submitAttempted: false,
    });

    //new enrollments - handle uploading beneficiary designation docs
    if (enrollmentActionType === this.enrollmentActionTypes.Enrollment) {
      try {
        const {
          shliach: {
            beneficiaryDesignationDocument:
              shliachBeneficiaryDesignationDocument,
            isEnrolled: isShliachEnrolled,
          },
          shlucha: {
            beneficiaryDesignationDocument:
              shluchaBeneficiaryDesignationDocument,
            isEnrolled: isShluchaEnrolled,
          },
        } = enrollment;

        if (isShliachEnrolled) {
          enrollmentToSubmit.shliach.beneficiaryDesignationDocumentURL =
            await this.uploadBeneficiaryDesignationDocument(
              shliachBeneficiaryDesignationDocument,
            );
          delete enrollmentToSubmit.shliach.beneficiaryDesignationDocument;
        }
        if (isShluchaEnrolled) {
          enrollmentToSubmit.shlucha.beneficiaryDesignationDocumentURL =
            await this.uploadBeneficiaryDesignationDocument(
              shluchaBeneficiaryDesignationDocument,
            );
          delete enrollmentToSubmit.shlucha.beneficiaryDesignationDocument;
        }
      } catch (err) {
        this.setState({
          enrollmentActionType: null,
          errorMessage:
            "Something went wrong and we could not process your beneficiary designation form upload.  Please try again.",
          loading: false,
        });
        return;
      }
    }

    let activity;
    if (
      enrollmentActionType === this.enrollmentActionTypes.TerminationRequest
    ) {
      activity = "TerminationRequest";
    }

    await this.submitEnrollmentAction(enrollmentToSubmit, activity);

    const {
      enrollment: updatedEnrollment,
      submitEnrollment: { errorMessage, loading, success },
    } = this.props;

    this.setState({ loading, errorMessage });

    if (success) {
      if (enrollmentActionType === this.enrollmentActionTypes.Enrollment) {
        this.props.onEnrollmentCompleted();
      } else {
        this.setState({
          ...this.getInitializedEnrollment(updatedEnrollment),
          enrollmentActionType: null,
          showPendingRenewalModal:
            enrollmentActionType ===
            this.enrollmentActionTypes.UpdatePaymentMethodAndProcess,
          showReplacePaymentMethod: false,
          showReplacePaymentMethodSuccessMessage:
            enrollmentActionType ===
            this.enrollmentActionTypes.UpdatePaymentMethod,
        });
      }
    }
  };

  uploadBeneficiaryDesignationDocument = async (document) => {
    const documentURL = await this.props.uploadDocument(
      document,
      "life_insurance_bdf",
      true,
    );

    return documentURL;
  };

  uploadAndSubmitBeneficiaryDesignationDocument = async (docName, doc) => {
    let docURL;
    try {
      docURL = await this.uploadBeneficiaryDesignationDocument(doc);
    } catch (err) {
      return { error: err };
    }

    const enrollmentForUpload = _cloneDeep(this.state.initialEnrollment);
    _set(enrollmentForUpload, docName, docURL);

    await this.submitEnrollmentAction(enrollmentForUpload);

    const {
      enrollment: updatedEnrollment,
      submitEnrollment: { error, errorMessage, success },
    } = this.props;

    if (success) {
      this.setState({
        ...this.getInitializedEnrollment(updatedEnrollment),
      });
    }

    return { error, errorMessage };
  };

  submitEnrollmentAction = async (enrollment, activity) => {
    //replacing empty strings with null before posting
    replaceValuesInObject(
      enrollment,
      (p) => p === "",
      () => null,
    );

    return await this.props.submitEnrollment.action(enrollment, activity);
  };

  toggleReplacePaymentMethodState = (
    showReplacePaymentMethod,
    replacePaymentMethodStateUpdate,
  ) => {
    const { enrollment, initialEnrollment } = this.state;

    if (replacePaymentMethodStateUpdate) {
      this.setState(replacePaymentMethodStateUpdate);
    } else {
      this.setState({
        enrollment: {
          ...enrollment,
          billing: showReplacePaymentMethod
            ? this.getInitializedBilling(enrollment)
            : initialEnrollment.billing,
        },
        errorMessage: "", //clear out error message (if there is one) when showing/hiding buttons
        showReplacePaymentMethod,
      });
    }
  };

  toggleSubscriptionAgreement = () => {
    this.setState(
      {
        subscriptionAgreementAccepted:
          !this.state.subscriptionAgreementAccepted,
      },
      () => {
        //if accepted agreement, and required fields message is shown, re-validate on change
        const { errorMessage, subscriptionAgreementAccepted } = this.state;
        if (
          subscriptionAgreementAccepted &&
          errorMessage &&
          errorMessage === this.incompleteErrorMessage
        ) {
          const isValid = this.validateEnrollment();
          if (isValid) {
            this.setState({
              errorMessage: "",
            });
          }
        }
      },
    );
  };

  render() {
    const {
      account,
      policy: {
        beneficiaryDesignationFormTemplateURL,
        price: policyPrice,
        programName,
      },
      shliachID,
      sys,
    } = this.props;

    const {
      enrollment,
      enrollmentActionType,
      errorMessage,
      initialEnrollment,
      loading,
      showConfirmCancelEnrollmentChangesModal,
      showConfirmEnrollmentActionModal,
      showPendingRenewalModal,
      showReplacePaymentMethod,
      showReplacePaymentMethodSuccessMessage,
      submitAttempted,
      subscriptionAgreementAccepted,
    } = this.state;

    return (
      <div className="full-width">
        <div className="card life-insurance-card full-width">
          <EnrollmentHeader
            enrollment={enrollment}
            enrollmentStatuses={this.enrollmentStatuses}
            programName={programName}
          />

          <Profile
            enrollment={enrollment}
            enrollmentStatuses={this.enrollmentStatuses}
            initialEnrollment={initialEnrollment}
            onChange={this.onChangeEnrollment}
            onChangeEvt={this.onChangeEnrollmentEvt}
            submitAttempted={submitAttempted}
          />

          {enrollment.status !== this.enrollmentStatuses.Terminated && (
            <React.Fragment>
              <BeneficiaryDesignationDocuments
                documentTemplateURL={beneficiaryDesignationFormTemplateURL}
                enrollment={enrollment}
                onChange={this.onChangeEnrollment}
                submitAttempted={submitAttempted}
                uploadAndSubmitUpdatedDocument={
                  this.uploadAndSubmitBeneficiaryDesignationDocument
                }
              />

              <Payment
                account={account}
                countries={sys.countries || []}
                enrollment={enrollment}
                enrollmentStatuses={this.enrollmentStatuses}
                onChange={this.onChangeEnrollment}
                onChangeEvt={this.onChangeEnrollmentEvt}
                policyPrice={policyPrice}
                shliachID={shliachID}
                showReplacePaymentMethod={showReplacePaymentMethod}
                showReplacePaymentMethodSuccessMessage={
                  showReplacePaymentMethodSuccessMessage
                }
                submitAttempted={submitAttempted}
                subscriptionAgreementAccepted={subscriptionAgreementAccepted}
                toggleReplacePaymentMethodState={
                  this.toggleReplacePaymentMethodState
                }
                toggleSubscriptionAgreement={this.toggleSubscriptionAgreement}
              />
            </React.Fragment>
          )}

          {/* Confirm enrollment info for Enrollment action */}
          <ConfirmEnrollmentInfoModal
            account={account}
            enrollment={enrollment}
            cancel={() =>
              this.setState({
                enrollmentActionType: null,
                showConfirmEnrollmentActionModal: false,
                submitAttempted: false,
              })
            }
            confirm={this.submitEnrollment}
            show={
              showConfirmEnrollmentActionModal &&
              enrollmentActionType === this.enrollmentActionTypes.Enrollment
            }
          />

          {/* Confirm other enrollment actions */}
          <ConfirmationModal
            cancel={() =>
              this.setState({
                enrollmentActionType: null,
                showConfirmEnrollmentActionModal: false,
                submitAttempted: false,
              })
            }
            confirm={this.submitEnrollment}
            message={`Are you sure you'd like to ${
              enrollmentActionType ===
              this.enrollmentActionTypes.TerminationRequest
                ? "request to terminate your plan"
                : enrollmentActionType ===
                  this.enrollmentActionTypes.UpdatePaymentMethod
                ? `update your payment method to use ${
                    enrollment.billing &&
                    enrollment.billing.stripeToken &&
                    enrollment.billing.stripeToken.card
                      ? `${enrollment.billing.stripeToken.card.brand} ending in ${enrollment.billing.stripeToken.card.last4}`
                      : ""
                  } for your future policy renewals`
                : enrollmentActionType ===
                  this.enrollmentActionTypes.UpdatePaymentMethodAndProcess
                ? `update your payment method to use ${
                    enrollment.billing &&
                    enrollment.billing.stripeToken &&
                    enrollment.billing.stripeToken.card
                      ? `${enrollment.billing.stripeToken.card.brand} ending in ${enrollment.billing.stripeToken.card.last4}`
                      : ""
                  } and process payment now`
                : ""
            }?`}
            show={
              showConfirmEnrollmentActionModal &&
              enrollmentActionType !== this.enrollmentActionTypes.Enrollment
            }
          />

          {/* Confirm cancel changes */}
          <ConfirmationModal
            cancel={() =>
              this.setState({
                showConfirmCancelEnrollmentChangesModal: false,
              })
            }
            confirm={this.cancelEnrollmentChanges}
            message="Are you sure you want to cancel your enrollment?  Your changes will be lost."
            show={showConfirmCancelEnrollmentChangesModal}
          />

          <PendingRenewalModal
            close={() => this.setState({ showPendingRenewalModal: false })}
            show={showPendingRenewalModal}
          />
        </div>

        <EnrollmentActions
          enrollment={enrollment}
          enrollmentActionType={enrollmentActionType}
          enrollmentActionTypes={this.enrollmentActionTypes}
          enrollmentStatuses={this.enrollmentStatuses}
          errorMessage={errorMessage}
          initialEnrollment={initialEnrollment}
          loading={loading}
          onCancel={
            showReplacePaymentMethod
              ? () => this.toggleReplacePaymentMethodState(false)
              : this.onCancelEnrollmentChanges
          }
          onSubmit={this.onSubmitEnrollment}
          showReplacePaymentMethod={showReplacePaymentMethod}
        />
      </div>
    );
  }
}

export default injectStripe(MyLifeInsurance);
