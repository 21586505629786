import { ProgramsActions } from "./actions";

export const ProgramsState = {
  name: "programs",
  persist: false,
  defaults: {
    loading: false,
    routes: [],
  },
  handlers: {
    [ProgramsActions.SET_PROGRAMS_LOADING]: function setProgramsLoading(
      state,
      action,
    ) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [ProgramsActions.SET_CHABAD_HOUSE_PROGRAMS_LOADING]: (state, action) => {
      return {
        ...state,
        chabadHousePrograms: {
          ...state.chabadHousePrograms,
          loading: !!action.payload,
        },
      };
    },
    [ProgramsActions.SET_DISPLAYED_PROGRAMS_LOADING]:
      function setDisplayedProgramsLoading(state, action) {
        return {
          ...state,
          displayedPrograms: {
            loading: !!action.payload,
          },
        };
      },
    [ProgramsActions.UPDATE_PROGRAMS]: function updatePrograms(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    [ProgramsActions.UPDATE_CHABAD_HOUSE_PROGRAMS]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
