import React from "react";

export default class EnrollmentCompleted extends React.PureComponent {
  render() {
    const {
      onViewEnrollment,
      policy: { programName },
    } = this.props;

    return (
      <div className="flex flex-align-center flex-justify-center full-page-state">
        <img src="/images/enrollmentComplete.svg" alt="" height="240" />
        <p className="fw-700 xxl-text mt-24 mb-40">Thank you for enrolling!</p>
        <p className="large-text mb-32">
          You have successfully enrolled in our{" "}
          {programName || "Life Insurance"} plan. You will be joined with the
          next billing cycle.
        </p>
        <button
          className="btn btn-accent btn-large"
          style={{ padding: "0 40px", maxWidth: "98%" }}
          onClick={onViewEnrollment} //prop in state that triggers showEnrollmentCompleted piece, once click to view set that to false and view enrollment.  and when refresh that state property will be reset.
        >
          My {programName || "Life Insurance"}
        </button>
      </div>
    );
  }
}
