import React from "react";
import ResourceCreatedModal from "./ResourceCreatedModal";
import ResourceSettings from "./settings/ResourceSettings";
import CreateRsvpItem from "../../shared/manage/CreateRsvpItem";

export default function CreateResource(props) {
  return (
    <CreateRsvpItem
      {...props}
      SettingsComponent={ResourceSettings}
      ModalComponent={ResourceCreatedModal}
    />
  );
}
