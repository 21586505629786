import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainContainer: { display: "flex" },
  component: {
    "& .student-details-page.page.container": { maxWidth: "100%" },
    width: `calc(100% - 300px)`,
    //TODO: move this styling to correct rsvp page once remove rsvp navigation from program menu
    "& .rsvp-page": {
      "& .rsvp-events-list-card": { border: "none" },
    },
    [theme.breakpoints.down("xs")]: {
      "& .page-wrapper": { marginLeft: 0, height: "calc(100vh - 56px)" },
      width: "100%",
    },
  },
}));
