import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import ActivityListFilters from "./ActivityListFilters";
import ActivityListRow from "./ActivityListRow";
import Loader from "../../../../components/Loader";
import PaginatedTable from "../../../../components/PaginatedTable";
import { PageURL } from "../../../../lib";
import { StudentsSelectors } from "../../../../state";

export default function ActivityList(props) {
  const {
    getActivity,
    onCloneInteraction,
    onEditInteraction,
    isLamplightersActivity,
    pageRoute,
    pageRoute: {
      query,
      query: { p: resultsPage = 1, r: resultsView = 15 },
      page,
      params,
    },
  } = props;

  const {
    data: { results, numberOfRows } = {},
    loading,
    success,
  } = useSelector(StudentsSelectors.activity);

  const getNavigationURL = useCallback(
    (queryUpdates) => {
      return PageURL.to(page, params, {
        ...query,
        ...queryUpdates,
      });
    },
    [page, params, query],
  );

  return (
    <PaginatedTable
      className="students-activity-table"
      getURL={(resultsPage, resultsView) =>
        getNavigationURL({ p: resultsPage, r: resultsView })
      }
      filterComponent={<ActivityListFilters pageRoute={pageRoute} />}
      filterComponentClassName="students-filters-container mobile-block mobile-mb-8"
      loadData={getActivity}
      loaderComponent={
        isLamplightersActivity && (
          <div className="flex flex-column flex-align-center">
            <Loader />
            <p
              className="accent-text line-height-double text-center mt-40 mb-40"
              style={{ maxWidth: "480px" }}
            >
              Please hold while we check activity details and validate activity
              eligibility for Lamplighters...
            </p>
          </div>
        )
      }
      loading={loading}
      name="Activity"
      page={resultsPage}
      pageRoute={pageRoute}
      records={results || []}
      renderRow={(activity, index) => (
        <ActivityListRow
          activity={activity}
          key={index}
          onEditInteraction={() => onEditInteraction(activity)}
          onCloneInteraction={() => onCloneInteraction(activity)}
        />
      )}
      resultsIncrement={15}
      resultsView={resultsView}
      showResultsTotal={true}
      showResultsView={true}
      success={success}
      totalCount={numberOfRows}
    />
  );
}
