import React from "react";
import ResourcesDropdown from "./ResourcesDropdown";
import ResourceSettings from "./settings/ResourceSettings";
import ResourcesStudentsListContainer from "./students/ResourcesStudentsListContainer";
import ManageRsvpItemContainer from "../../shared/manage/ManageRsvpItemContainer";
import { useSelector } from "react-redux";
import { AuthSelectors } from "../../../../../state";

const tabs = [
  {
    component: ResourcesStudentsListContainer,
    id: "students",
    title: "Registrations",
  },
  { component: ResourceSettings, id: "settings", title: "Settings" },
];

const getItemFullName = (resource) => resource?.name;

const getSmsFilterQueryParam = (rsvpItemId) => ({
  filterByResourceId: rsvpItemId,
});

export default function ManageResourceContainer(props) {
  const {
    actions: { getRsvpResource, getRsvpResourcesBasic, clearRsvpResource },
    resource,
    resourcesBasic,
    pageRoute,
  } = props;

  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);

  return (
    <ManageRsvpItemContainer
      rsvpType="resource"
      tabs={tabs}
      DropdownComponent={ResourcesDropdown}
      getRsvpItem={getRsvpResource}
      getRsvpItemsBasic={getRsvpResourcesBasic}
      clearRsvpItem={clearRsvpResource}
      rsvpItem={resource}
      rsvpItemsBasic={resourcesBasic}
      getItemFullName={getItemFullName}
      getSmsFilterQueryParam={getSmsFilterQueryParam}
      pageRoute={pageRoute}
      isShliach={isShliach}
      {...props}
    />
  );
}
