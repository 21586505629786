import React from "react";
import { Box, Checkbox, MenuItem, Popper } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { TextInput, TextInputProps } from "../inputs/TextInput";
import { Controller, useFormContext } from "react-hook-form";
import { FormFieldProps } from "../form";
import { Option } from "../../../lib/types";

interface AutocompleteFieldOption extends Option {
  disabled?: boolean;
  isAddedOption?: boolean;
}

type FormAutocompleteFieldProps = {
  allowBulkSelection?: boolean; //applies when multiple
  autoFocus?: boolean;
  defaultValue?: any; // default value set when selection is cleared - for non-multiple use
  disableClearable?: boolean;
  disableCloseOnSelect?: boolean;
  dividers?: boolean;
  filterSelectedOptions?: boolean;
  hidePopupIcon?: boolean;
  multiple?: boolean;
  multipleShortenedDisplay?: boolean;
  noOptionsText?: string | React.ReactNode;
  onValueChange?: (obj: any) => void; // optional callback for value change - in addition to the default handler
  openOnFocus?: boolean;
  options: AutocompleteFieldOption[];
  placeholder?: string;
  tagsMaxWidth?: string;
  truncateSelection?: boolean;
} & FormFieldProps &
  TextInputProps;

export const FormAutocompleteField = React.memo(
  /**
   *
   */
  function FormAutocompleteField({
    allowBulkSelection,
    autoFocus,
    defaultValue = null,
    disableClearable,
    disableCloseOnSelect,
    disabled,
    dividers,
    filterSelectedOptions,
    hidePopupIcon,
    multiple,
    name,
    noOptionsText,
    onValueChange,
    openOnFocus,
    options,
    placeholder,
    tagsMaxWidth,
    truncateSelection,
    ...passProps
  }: FormAutocompleteFieldProps) {
    const { control } = useFormContext();

    const showBulkOptions = multiple && allowBulkSelection;

    // customizing popper component to handle width of short/long items in list
    const CustomPopper = (props: any) => {
      return (
        <Popper
          {...props}
          placement="bottom-start"
          style={{ minWidth: props.anchorEl.clientWidth, width: "fit-content" }}
        />
      );
    };

    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => {
          const { onChange, ...fieldProps } = field;
          const { error } = fieldState;

          const selection = multiple
            ? options.filter((o) => field.value.includes(o.id))
            : options.find((o) => field.value === o.id) || null;

          const allSelected =
            showBulkOptions &&
            (selection as any[]).length ===
              options.filter((o) => !o.disabled).length;

          const showSelectAll = showBulkOptions && !allSelected;
          const showDeselectAll = showBulkOptions && allSelected;

          const handleChange = (val: any) => {
            let changeValue;

            if (multiple) {
              const all = val.find((v: any) => v.id === "all");
              const none = val.find((v: any) => v.id === "none");
              changeValue = all
                ? options.filter((o) => !o.disabled).map((o) => o.id)
                : none
                ? ""
                : val.map((v: any) => v.id);
            } else {
              changeValue = val?.id || defaultValue;
            }

            onChange({ target: { name, value: changeValue } });
            onValueChange && onValueChange(val);
          };

          return (
            <Autocomplete
              id={name}
              key={name}
              disabled={disabled}
              disableClearable={disableClearable}
              disableCloseOnSelect={multiple || disableCloseOnSelect}
              filterSelectedOptions={filterSelectedOptions}
              getOptionLabel={(option: AutocompleteFieldOption) => option.name}
              getOptionDisabled={(option: AutocompleteFieldOption) =>
                !!option.disabled
              }
              multiple={multiple}
              noOptionsText={noOptionsText}
              onChange={(_, val) => handleChange(val)}
              {...(hidePopupIcon && { popupIcon: "" })}
              openOnFocus={openOnFocus}
              options={[
                ...(showSelectAll
                  ? [{ id: "all", name: "Select all" }]
                  : showDeselectAll
                  ? [{ id: "none", name: "Deselect all" }]
                  : []),
                ...options,
              ]}
              PopperComponent={CustomPopper}
              renderInput={(params) => (
                <TextInput
                  autoFocus={autoFocus}
                  {...params}
                  {...fieldProps}
                  {...passProps}
                  {...(error
                    ? {
                        error: true,
                        helperText: error.message,
                      }
                    : {})}
                  placeholder={placeholder}
                />
              )}
              renderOption={(option, { selected }) => (
                <MenuItem
                  divider={dividers}
                  key={option.id}
                  data-id={option.id}
                >
                  {multiple && (
                    <Checkbox
                      checked={selected}
                      style={{ marginRight: 1, padding: "2px" }}
                    />
                  )}
                  {option.display || option.name}
                </MenuItem>
              )}
              renderTags={(value) =>
                truncateSelection ? (
                  <Box
                    className="autocomplete-tags"
                    margin="7.5px 4px 0 6px"
                    maxWidth={tagsMaxWidth}
                  >
                    {value
                      .map((option) => option.display ?? option.name)
                      .join(", ")}
                  </Box>
                ) : (
                  <Box paddingLeft={1}>
                    {value.length > 0 &&
                      (value.length > 1
                        ? `${value[0].display ?? value[0].name} +${
                            value.length - 1
                          }`
                        : value[0].display ?? value[0].name)}
                  </Box>
                )
              }
              value={selection}
            />
          );
        }}
      />
    );
  },
);
