import React from "react";
import CourseCard from "./CourseCard";
import MyCourseCard from "./MyCourseCard";
import NoMyCourses from "./NoMyCourses";
import PaginatedList from "../../../../../components/PaginatedList";
import { PageURL, Navigation } from "../../../../../lib";

export default class CoursesList extends React.PureComponent {
  getCategorizedCourses = (courses) => {
    const categorizedCourses = {};

    courses.forEach((course) => {
      if (!categorizedCourses.hasOwnProperty(course.categoryDisplay)) {
        categorizedCourses[course.categoryDisplay] = [];
      }
      categorizedCourses[course.categoryDisplay].push(course);
    });

    return categorizedCourses;
  };

  renderCategorizedCourses = (courses) => {
    const categorizedCourses = this.getCategorizedCourses(courses);

    return (
      <div className="edu-courses-cards-grid edu-courses-cards-category-grid">
        {Object.keys(categorizedCourses).map((categoryKey, categoryIndex) => (
          <div key={categoryIndex}>
            <h4 className="edu-courses-section-title">
              {categoryKey || "Other"}
            </h4>
            {this.renderCourses(categorizedCourses[categoryKey])}
          </div>
        ))}
      </div>
    );
  };

  renderCourses = (courses) => (
    <div className="edu-courses-cards-grid">
      {courses.map((course, courseIndex) =>
        this.props.isMyCourses ? (
          <MyCourseCard course={course} key={courseIndex} />
        ) : (
          <CourseCard course={course} key={courseIndex} />
        ),
      )}
    </div>
  );

  getCourseRecords = (pg) => {
    const {
      getCourses,
      pageRoute: { query, page, params },
    } = this.props;

    const url = PageURL.to(page, params, {
      ...query,
      p: pg > 1 ? pg : undefined,
    });
    Navigation.redirect(url);

    setTimeout(getCourses, 0);
  };

  render() {
    const {
      courses: {
        data: { results = [], numberOfRows = 0 } = {},
        errorMessage,
        loading,
        success,
      },
      eduProgramName,
      isCategorizedCourses,
      isMyCourses,
      pageRoute,
      resultsView,
    } = this.props;

    const {
      query: { ca, le, p: page = 1, se },
    } = pageRoute;

    return (
      <PaginatedList
        className="courses-list flex flex-justify-center"
        errorMessage={errorMessage}
        loadData={this.getCourseRecords}
        loading={loading}
        name="rewards"
        noRecordsDisplay={
          isMyCourses &&
          !ca &&
          !le &&
          !se && (
            <NoMyCourses
              eduProgramName={eduProgramName}
              pageRoute={pageRoute}
            />
          )
        }
        page={page}
        paginationClassName="courses-list-pagination"
        records={results}
        renderRecords={
          isCategorizedCourses
            ? this.renderCategorizedCourses
            : this.renderCourses
        }
        resultsView={resultsView}
        success={success}
        totalCount={numberOfRows}
      />
    );
  }
}
