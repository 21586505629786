import React, { useEffect, useState } from "react";

import Loader from "../../../components/Loader";
import Radio from "../../../components/form/Radio";
import Attendees from "./Attendees";
import { isMobileView } from "../../../lib";
import EmailRecipientsSection from "../../../components/EmailRecipientsSection";

function GeneralSettings(props) {
  const {
    hasEnrollment,
    enrollmentSettings,
    enrollmentSettings: {
      attendees,
      campuses,
      acceptancePolicyOverride,
      emailRecipients,
    },
    event: {
      adminAcceptancePolicyOption,
      termsAndConditions = [],
      studentRegistrationEndDate,
    },
    onArrayChange,
    onChange,
    selectedTab,
    submitAttempted,
    validation,
    trip: {
      enrollment: { chabadHouseID },
      settings: {
        loading,
        schools = [],
        staff: { payload: staffList = [] } = {},
      } = {},
    } = {},
  } = props;

  const [showGeneralSettingsInfo, setShowGeneralSettingsInfo] = useState(true);

  useEffect(() => {
    if (isMobileView() && hasEnrollment) {
      setShowGeneralSettingsInfo(false);
    }
  }, [hasEnrollment]);

  const navigate = (tab) => {
    props.navigate(tab);
    setShowGeneralSettingsInfo(true);
  };

  const onClickGeneralTab = () => {
    if (!hasEnrollment || !isMobileView()) return null;

    selectedTab === "general"
      ? setShowGeneralSettingsInfo(!showGeneralSettingsInfo)
      : navigate("general");
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      {(!hasEnrollment || isMobileView()) && (
        <div className="trip-page-form-section">
          <p className="xxl-text fw-500" onClick={onClickGeneralTab}>
            General
          </p>
          {!isMobileView() && (
            <p className="accent-text-dark mt-16 line-height-double mobile-hidden">
              Please note you can come back to change these settings later
            </p>
          )}
        </div>
      )}
      {showGeneralSettingsInfo && (
        <React.Fragment>
          <div className="trip-page-form-section">
            <div className="relative">
              <p className="medium-text fw-700 mb-16">Participating Schools</p>
              <p className="accent-text-dark mb-24 line-height-double">
                Select all schools that will be participating.{" "}
                <span
                  className={
                    "fs-14" && validation && validation.campuses
                      ? "error-message relative"
                      : ""
                  }
                >
                  At least one school is required.
                </span>
              </p>
            </div>
            <div>
              {schools.map((s) => (
                <div
                  className="flex custom-checkbox-container mb-12"
                  key={s.name}
                >
                  {hasEnrollment ? (
                    <input
                      checked={campuses.findIndex((i) => i.id === s.id) > -1}
                      className="custom-checkbox"
                      id={`school-${s.name}`}
                      name="campuses"
                      onChange={(e) =>
                        onArrayChange(e.target.name, s, e.target.checked)
                      }
                      type="checkbox"
                    />
                  ) : (
                    <input
                      defaultChecked={true}
                      className="custom-checkbox"
                      id={`school-${s.name}`}
                      name="campuses"
                      onChange={(e) =>
                        onArrayChange(e.target.name, s, e.target.checked)
                      }
                      type="checkbox"
                    />
                  )}
                  <label
                    htmlFor={`school-${s.name}`}
                    className="flex-align-center"
                  >
                    {s.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <Attendees
            staffList={staffList}
            attendees={attendees}
            termsAndConditions={termsAndConditions}
            onChange={onChange}
            validation={validation}
            studentRegistrationEndDate={studentRegistrationEndDate}
          />
          {adminAcceptancePolicyOption !== "NoAcceptance" && (
            <div className="trip-page-form-section">
              <p className="medium-text fw-700 mb-16">Acceptance Process</p>
              <p className="accent-text-dark mb-24 line-height-double">
                You can choose to either have students automatically accepted
                when they register, or you can choose to have an acceptance
                process and once they register, you will have to accept them to
                complete their registration.
                {adminAcceptancePolicyOption === "YesAcceptance" && (
                  <span className="fw-700">
                    <br />
                    The acceptance process for this trip has been required by
                    Admin and cannot be overridden.
                  </span>
                )}
              </p>
              <Radio
                disabled={adminAcceptancePolicyOption === "YesAcceptance"}
                name="acceptancePolicyOverride"
                onChange={onChange}
                options={[
                  {
                    value: "OptIn",
                    display: "Yes, I would like an acceptance process",
                  },
                  {
                    value: "OptOut",
                    display: "No thanks",
                  },
                ]}
                value={
                  adminAcceptancePolicyOption === "YesAcceptance"
                    ? "OptIn"
                    : enrollmentSettings && acceptancePolicyOverride
                }
              />
            </div>
          )}
          <EmailRecipientsSection
            chabadHouseID={chabadHouseID}
            emailRecipients={emailRecipients || []}
            formSectionClassName="trip-page-form-section shluchim-section"
            onChange={(recipientsChange) =>
              onChange("emailRecipients", recipientsChange)
            }
            submitAttempted={submitAttempted}
          />
        </React.Fragment>
      )}
    </div>
  );
}
export default React.memo(GeneralSettings);
