import { LifeInsuranceActions } from "./actions";
import { AuthActions } from "../auth/actions";

export const LifeInsuranceState = {
  name: "lifeInsurance",
  persist: false,
  defaults: {
    lifeInsurancePolicy: {
      loading: false,
    },
    lifeInsuranceEnrollment: {
      loading: false,
    },
    submitEnrollment: {
      loading: false,
    },
  },
  handlers: {
    [LifeInsuranceActions.SET_LIFE_INS_POLICY_LOADING]: (state, action) => {
      return {
        ...state,
        lifeInsurancePolicy: {
          loading: !!action.payload,
        },
      };
    },
    [LifeInsuranceActions.SET_LIFE_INS_POLICY]: (state, action) => {
      return {
        ...state,
        lifeInsurancePolicy: action.payload,
      };
    },
    [LifeInsuranceActions.SET_LIFE_INS_ENROLLMENT_LOADING]: (state, action) => {
      return {
        ...state,
        lifeInsuranceEnrollment: {
          loading: !!action.payload,
        },
      };
    },
    [LifeInsuranceActions.SET_LIFE_INS_ENROLLMENT]: (state, action) => {
      return {
        ...state,
        lifeInsuranceEnrollment: action.payload,
      };
    },
    [LifeInsuranceActions.SET_SUBMIT_LIFE_INS_ENROLLMENT_LOADING]: (
      state,
      action,
    ) => {
      return {
        ...state,
        submitEnrollment: {
          loading: !!action.payload,
        },
      };
    },
    [LifeInsuranceActions.SET_SUBMIT_LIFE_INS_ENROLLMENT]: (state, action) => {
      return {
        ...state,
        submitEnrollment: action.payload,
      };
    },
    [AuthActions.DO_SIGNOUT]: (state, action) => {
      return LifeInsuranceState.defaults; //reset LI policy and enrollment on user signout
    },
  },
};
