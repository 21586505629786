import { ResetPassActions } from "../resetPass/actions";

export const ResetPassState = {
  name: "resetPass",
  persist: false,
  defaults: {
    loading: false,
    success: undefined,
  },
  handlers: {
    [ResetPassActions.RESET_PASS_ERROR]: function handleAuthError(
      state,
      action,
    ) {
      return {
        ...state,
        loading: false,
        success: false,
      };
    },
    [ResetPassActions.DO_RESET_PASSWORD]: function (state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    [ResetPassActions.SET_RESET_PASS_LOADING]: function setContactsLoading(
      state,
      action,
    ) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [ResetPassActions.RESET_PASS_COMPLETE]: function (state, action) {
      return {
        ...state,
        loading: false,
        success: true,
      };
    },
  },
};
