import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import {
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Collapse,
  Box,
} from "@material-ui/core";
import { useStyles } from "./StudentEnrollmentAction.styles";
import Loader from "../../../../components/Loader";
import { ExpandLess, ExpandMore, Visibility } from "@material-ui/icons";
import { getEduEmailPreviewText } from "../../../../lib";
import { EduActions, EduSelectors } from "../../../../state";
import { Statuses } from "../../constants";
const { REACT_APP_API_URL } = process.env;

const StudentEnrollmentAction = (props) => {
  const {
    pageRoute: {
      query: {
        enrollmentCourseScheduleID,
        newCourseScheduleEnrollmentStatus,
        programName,
        programType,
        accountID,
        token,
      },
    },
  } = props;

  const studentStatuses = useSelector(EduSelectors.studentEnrollmentStatuses);

  const [state, setState] = useState({
    errorMessage: "",
    submitted: false,
    loading: false,
    success: true,
    sendStudentEmail: true,
  });

  const { errorMessage, loading, success, submitted, sendStudentEmail } = state;

  const classes = useStyles();
  const [showPreview, setShowPreview] = useState(false);
  const [rejectionType, setRejectionType] = useState("course_reject");
  const [studentEnrollmentStatus, setStudentEnrollmentStatus] = useState();
  const [courseScheduleEnrollmentStatus, setCourseScheduleEnrollmentStatus] =
    useState();
  const dispatch = useDispatch();
  const isYourIsrael = programType === "YourIsrael";
  const isApproval = newCourseScheduleEnrollmentStatus === Statuses.Approved;
  const isGlobalRejectionForm =
    studentEnrollmentStatus === Statuses.Pending &&
    !isApproval &&
    !isYourIsrael;
  const handleTogglePreview = useCallback(() => {
    setShowPreview(!showPreview);
  }, [showPreview]);

  const handleChange = useCallback((event) => {
    setRejectionType(event.target.value);
  }, []);

  const studentEnrollmentAction = useCallback(() => {
    const approveRejectStudent = async () => {
      setState((s) => ({ ...s, loading: true }));

      let errorMessage = "";
      let success = true;

      if (
        !accountID ||
        !enrollmentCourseScheduleID ||
        !newCourseScheduleEnrollmentStatus ||
        !token
      ) {
        success = false;
      } else {
        const values = {
          accountID,
          token,
          id: enrollmentCourseScheduleID,
          eduCourseScheduleEnrollmentStatus: newCourseScheduleEnrollmentStatus,
          sendStudentEmail,
        };
        if (studentEnrollmentStatus === Statuses.Pending) {
          values.eduStudentEnrollmentStatus =
            newCourseScheduleEnrollmentStatus === Statuses.Approved ||
            rejectionType === "course_reject"
              ? "Approved"
              : "Rejected";
        }
        try {
          await axios.post(
            `${REACT_APP_API_URL}EduStudents/StatusWithToken`,
            values,
          );
        } catch (err) {
          success = false;
          if (err.response && err.response.status === 401) {
            errorMessage = "Unauthorized Request";
          } else if (
            err?.response?.data?.messages &&
            err?.response?.data?.messages[0]
          ) {
            errorMessage = err.response.data.messages[0];
          }
        }
      }

      setState((s) => ({
        ...s,
        errorMessage,
        loading: false,
        submitted: true,
        success,
      }));
    };
    approveRejectStudent();
  }, [
    accountID,
    newCourseScheduleEnrollmentStatus,
    enrollmentCourseScheduleID,
    rejectionType,
    sendStudentEmail,
    studentEnrollmentStatus,
    token,
  ]);

  useEffect(() => {
    if (accountID && enrollmentCourseScheduleID && token) {
      // retrieving current studentStatuses data
      dispatch(
        EduActions.getEduStudentEnrollmentStatuses(
          enrollmentCourseScheduleID,
          accountID,
          token,
        ),
      );
    }
  }, [accountID, enrollmentCourseScheduleID, token, dispatch]);

  useEffect(() => {
    const studentStatusData = studentStatuses?.data;
    if (studentStatuses?.success && Object.keys(studentStatusData).length) {
      //initialize student enrollment status and course schedule enrollment status from retrieved studentStatuses data
      setStudentEnrollmentStatus(studentStatusData?.eduStudentEnrollmentStatus);
      setCourseScheduleEnrollmentStatus(
        studentStatusData?.eduStudentCourseScheduleEnrollmentStatus,
      );
    }
  }, [studentStatuses, isApproval, isYourIsrael]);

  useEffect(() => {
    if (isYourIsrael) setRejectionType("global_reject");
  }, [isYourIsrael]);

  return (
    <div
      className="flex flex-justify-center flex-align-center"
      style={{ minHeight: "100vh", background: "#0C425D" }}
    >
      <div
        className="card flex flex-align-center flex-justify-center text-center"
        style={{
          width: "600px",
          maxWidth: "98%",
          minHeight: "420px",
          maxHeight: "calc(100% - 64px)",
          flexDirection: "column",
        }}
      >
        {loading || studentStatuses?.loading ? (
          <Loader />
        ) : !success || !studentStatuses?.success ? (
          <React.Fragment>
            <img
              alt="error-robot"
              height="240"
              src="/images/error.svg"
              style={{ marginBottom: "64px" }}
            />
            <p
              className="error-message medium-text"
              style={{ position: "relative" }}
            >
              {errorMessage ||
                "Something went wrong and this action could not be completed.  Refresh the page to try again."}
            </p>
          </React.Fragment>
        ) : courseScheduleEnrollmentStatus ? (
          courseScheduleEnrollmentStatus !== Statuses.Pending ? (
            <React.Fragment>
              <img
                alt={courseScheduleEnrollmentStatus}
                src={`/images/${
                  courseScheduleEnrollmentStatus === Statuses.Approved
                    ? "check"
                    : "x"
                }-circle.svg`}
                style={{ marginBottom: "64px" }}
              />
              <p className="fw-700 medium-text">
                Student has already been{" "}
                {studentEnrollmentStatus === Statuses.Rejected
                  ? "rejected from JewishU"
                  : courseScheduleEnrollmentStatus?.toLowerCase()}
              </p>
            </React.Fragment>
          ) : !submitted ? (
            <React.Fragment>
              <p className="fw-700 xl-text mb-16">
                {isGlobalRejectionForm
                  ? `This student is new to ${programName}. Choose the rejection status for this student.`
                  : `Click the button below to ${
                      isApproval ? "approve" : "reject"
                    } this student`}
              </p>
              {isGlobalRejectionForm && (
                <RadioGroup
                  aria-label="reject"
                  name="reject"
                  value={rejectionType}
                  onChange={handleChange}
                  className={classes.radioContainer}
                >
                  <FormControlLabel
                    value="course_reject"
                    control={<Radio color="primary" />}
                    label={
                      <Typography>
                        Reject from this course{" "}
                        <span className={classes.boldLabel}>only</span> (and
                        accept to {programName}).
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="global_reject"
                    control={<Radio color="primary" />}
                    label={
                      <Typography>
                        Reject from{" "}
                        <span className={classes.boldLabel}>all</span>{" "}
                        {programName} courses
                      </Typography>
                    }
                  />
                </RadioGroup>
              )}
              <Collapse in={rejectionType === "global_reject" && !isYourIsrael}>
                <Alert severity="error" className={classes.alert}>
                  The student will not be allowed to register for any{" "}
                  {programName} courses.
                </Alert>
              </Collapse>
              {rejectionType === "course_reject" && isGlobalRejectionForm && (
                <Typography variant="body2" className={classes.sendEmailText}>
                  Email notifies of acceptance to {programName} but not this
                  course
                </Typography>
              )}
              <p
                className={`tooltip-container flex flex-align-center ${
                  isApproval ? "mb-40" : "mb -32"
                } mt-32`}
                onClick={() => {
                  setState((s) => ({
                    ...s,
                    sendStudentEmail: !s.sendStudentEmail,
                  }));
                }}
              >
                <i
                  className={`material-icons`}
                  style={{
                    color: sendStudentEmail ? "#2774ae" : "rgba(0, 0, 0, 0.16)",
                    cursor: "pointer",
                    marginRight: "8px",
                  }}
                >
                  {sendStudentEmail ? "check_box" : "check_box_outline_blank"}
                </i>
                Send {isApproval ? " approval " : " rejection "} email to
                student
              </p>
              {!isApproval && (
                <Button
                  onClick={handleTogglePreview}
                  color="primary"
                  disableRipple
                  className={classes.previewBtn}
                >
                  <Visibility fontSize="small" /> Preview Email
                  {showPreview ? (
                    <ExpandLess fontSize="small" />
                  ) : (
                    <ExpandMore fontSize="small" />
                  )}
                </Button>
              )}
              <Collapse in={showPreview}>
                <Box className={`${classes.box}`}>
                  <Typography variant="body2">
                    {getEduEmailPreviewText(rejectionType, programName)}
                  </Typography>
                </Box>
              </Collapse>
              <p
                className="btn btn-accent flex flex-align-center"
                onClick={studentEnrollmentAction}
                style={{ padding: "0px 16px" }}
              >
                <i className="material-icons mr-8 xl-text">
                  thumb_{isApproval ? "up" : "down"}
                </i>
                {isApproval ? "Approve" : "Reject"} Student
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <img
                alt={newCourseScheduleEnrollmentStatus}
                src={`/images/${isApproval ? "check" : "x"}-circle.svg`}
                style={{ marginBottom: "64px" }}
              />
              <p className="fw-700 medium-text">
                Student has been successfully{" "}
                {newCourseScheduleEnrollmentStatus?.toLowerCase()}
              </p>
            </React.Fragment>
          )
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(StudentEnrollmentAction);
