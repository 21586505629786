import React from "react";
import ShluchimDirectoryTable from "./shluchim/ShluchimDirectoryTable";
import TeamsDirectoryTable from "./teams/TeamsDirectoryTable";
import Pages from "..";
import { withAppInsights } from "../../lib/AppInsights";

class Directory extends React.PureComponent {
  componentWillUnmount() {
    this.props.actions.clearContacts();
  }

  directoryTypes = {
    Shluchim: "Shluchim",
    Teams: "Teams",
  };

  getDirectoryType = () => {
    const {
      pageRoute: {
        page: { type },
      },
    } = this.props;
    return type === Pages.directory.teamsDirectory.type
      ? this.directoryTypes.Teams
      : this.directoryTypes.Shluchim;
  };

  render() {
    const {
      contacts: { contactType },
    } = this.props;

    const directoryType = this.getDirectoryType();

    return (
      <div
        className={
          !this.props.ui.isPhone
            ? "directory-page page container"
            : "directory-page page container overflow-hidden"
        }
      >
        <h2 className="page-title mobile-hidden">{directoryType} Directory</h2>
        {(!contactType || contactType === directoryType) &&
          React.createElement(
            directoryType === this.directoryTypes.Teams
              ? TeamsDirectoryTable
              : ShluchimDirectoryTable,
            {
              ...this.props,
              getContacts: (...passProps) =>
                this.props.actions.getContacts(directoryType, ...passProps),
            },
          )}
      </div>
    );
  }
}

export default withAppInsights(Directory);
