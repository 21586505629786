import React from "react";

import Loader from "../../components/Loader";
import StripeProvider from "../../components/StripeProvider";
import ProgramBreadcrumbsHeader from "../../components/ProgramBreadcrumbsHeader";
import AccountInfoHeader from "../../components/AccountInfoHeader";
import KinusForm from "./KinusForm";
import AlreadyRegistered from "./registrationStates/AlreadyRegistered";
import RegistrationClosed from "./registrationStates/RegistrationClosed";

import { Navigation, PageURL } from "../../lib";
import { withAppInsights } from "../../lib/AppInsights";
import Pages from "../../pages";

const { REACT_APP_STRIPE_API_KEY } = process.env;

class Kinus extends React.PureComponent {
  state = {
    initialized: false,
    navigating: false,
  };

  componentDidMount() {
    this.getKinusInformation();
  }

  navigate = (page, params, query) => {
    this.setState({ navigating: true }, () => {
      const url = PageURL.to(page, params, query);
      Navigation.go(url);
      window.scrollTo({ behavior: "smooth", left: 0, top: 0 });

      if (page === Navigation.page) {
        this.setState({ navigating: false });
      }
    });
  };

  goToProfile = () => {
    this.navigate(Pages.profile.profile, null, {
      after: encodeURIComponent(Navigation.locationURL),
    });
  };

  getKinusInformation = async () => {
    await Promise.all([
      this.props.actions.getKinusProfile(this.props.user.id),
      this.props.actions.getKinusSettings(),
    ]);
    this.setState({ initialized: true });
  };

  navigate = (page, params, query) => {
    this.setState({ navigating: true }, () => {
      const url = PageURL.to(page, params, query);
      Navigation.go(url);
      window.scrollTo({ behavior: "smooth", left: 0, top: 0 });

      if (page === Navigation.page) {
        this.setState({ navigating: false });
      }
    });
  };

  goToProfile = () => {
    this.navigate(Pages.profile.profile, null, {
      after: encodeURIComponent(Navigation.locationURL),
    });
  };

  render() {
    const {
      actions,
      error,
      loading,
      profile,
      registration,
      settings,
      sys,
      user,
    } = this.props;
    const { initialized, navigating } = this.state;

    return (
      <>
        {!initialized || loading ? (
          <div style={{ marginTop: "120px" }}>
            <Loader />
          </div>
        ) : (
          <div className="kinus-page page">
            {!!(settings && settings.eventSettings) && (
              <ProgramBreadcrumbsHeader
                className="ml-16"
                scheduleId={settings.eventSettings.programScheduleID}
              />
            )}
            {error ? (
              <div className="kinus-page-form-state">
                <p className="xxl-text mt-24 mb-16">Something went wrong</p>
                <p className="medium-text accent-text fw-500">
                  Sorry for the inconvenience. Our server is being stubborn,
                  please try again.
                </p>
              </div>
            ) : (
              <React.Fragment>
                {profile &&
                  settings &&
                  (!settings.eventSettings.canRegister ? (
                    <RegistrationClosed />
                  ) : profile.isRegistered ? (
                    <AlreadyRegistered
                      kinusBanner={sys.portalBanners.find(
                        (banner) => banner.type === "Kinus",
                      )}
                      userId={user.id}
                    />
                  ) : (
                    <React.Fragment>
                      <AccountInfoHeader
                        editProfile={this.goToProfile}
                        profile={profile}
                      />

                      {settings && (
                        <StripeProvider apiKey={REACT_APP_STRIPE_API_KEY}>
                          <KinusForm
                            editProfile={this.goToProfile}
                            navigating={navigating}
                            profile={profile}
                            registration={registration}
                            registerForKinus={actions.registerForKinus}
                            settings={settings}
                            sys={sys}
                            userId={user.id}
                          />
                        </StripeProvider>
                      )}
                    </React.Fragment>
                  ))}
              </React.Fragment>
            )}
          </div>
        )}
      </>
    );
  }
}

export default withAppInsights(Kinus);
