import React from "react";
import { AuthRequest } from "../../../../../lib";
import ResourcesStudentsExportButton from "../manage/students/ResourcesStudentsExportButton";
import RsvpListActions from "../../shared/list/RsvpListActions";

export default function ResourcesListActions(props) {
  const { hasResources, rsvpEnrollmentID, pageRoute } = props;

  const getRegistrationsForExport = async () => {
    const response = await AuthRequest.get(
      `RsvpEnrollments/${rsvpEnrollmentID}/resources/registrations?export=true`,
    );
    return response.data.payload && response.data.payload.results;
  };

  return (
    <RsvpListActions
      rsvpType="resource"
      hasItems={hasResources}
      paramsForNew={{
        rsvpItemId: "new",
        pageView: "resources",
      }}
      ExportButtonComponent={ResourcesStudentsExportButton}
      getRegistrationsForExport={getRegistrationsForExport}
      pageRoute={pageRoute}
    />
  );
}
