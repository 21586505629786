import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useFormState } from "react-hook-form";

export interface FormPromptProps {
  noPrompt?: boolean;
  promptMessage?: string;
}

const beforeUnloadHandler = (e: Event) => e.preventDefault();

export function FormPrompt({ noPrompt, promptMessage }: FormPromptProps) {
  const { isDirty } = useFormState();

  useEffect(() => {
    if (isDirty) {
      window.addEventListener("beforeunload", beforeUnloadHandler);
    } else {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    }

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [isDirty]);

  return (
    <Prompt
      when={isDirty && !noPrompt}
      message={
        promptMessage ||
        "Are you sure you want to leave this page? Any changes you made here will not be saved."
      }
    />
  );
}
