import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useStyles } from "./resourceTab.styles";
import { Categories, FolderItemType } from "./constants";

export function ResourceFile({ category, file, navigate, togglePreviewModal }) {
  const classes = useStyles();
  const { id, itemType, name, thumbnailLink, mimeType } = file;
  const [imgSrc, setImgSrc] = useState(thumbnailLink);

  const onFileClick = () => {
    itemType === FolderItemType.Folder
      ? navigate(id)
      : togglePreviewModal(true, file);
  };

  const setDefaultImg = useCallback(() => {
    setImgSrc(() => {
      if (mimeType.includes("folder")) {
        return "/images/closed_folder.svg";
      } else if (mimeType.includes("spreadsheet")) {
        return "/images/google_sheets.png";
      } else if (mimeType.includes("presentation")) {
        return "/images/google_slides.png";
      } else if (mimeType.includes("form")) {
        return "/images/google_forms.png";
      } else if (category === Categories.Audios) {
        return "/images/audio_icon.png";
      } else if (category === Categories.Videos) {
        return "/images/video_icon.png";
      } else {
        return "/images/google_docs.png";
      }
    });
  }, [category, mimeType]);

  useEffect(() => {
    if (!thumbnailLink || mimeType.includes("google-apps")) {
      // thumbnail previews are not available for google app docs so we set the default image imed
      setDefaultImg();
    }
  }, [mimeType, thumbnailLink, setDefaultImg]);

  const renderThumbnail = () => {
    return (
      <Fragment>
        <div className={classes.thumbnailContainer}>
          <img
            alt={name}
            src={imgSrc}
            className={classes.thumbnail}
            style={{ height: imgSrc !== thumbnailLink ? 96 : 150 }}
            onError={setDefaultImg}
            referrerPolicy="no-referrer"
          />
        </div>
        <p className={classes.fileName}>{name}</p>
      </Fragment>
    );
  };

  return (
    <div className={classes.file}>
      <div onClick={onFileClick}>{renderThumbnail()}</div>
    </div>
  );
}
