import { AuthRequest } from "../../lib";
import { SysActions } from "../sys/actions";

export const KinusActions = {
  SET_KINUS_PROFILE_LOADING: "SET_KINUS_PROFILE_LOADING",
  SET_KINUS_SETTINGS_LOADING: "SET_KINUS_SETTINGS_LOADING",
  SET_KINUS_PROFILE: "SET_KINUS_PROFILE",
  SET_KINUS_SETTINGS: "SET_KINUS_SETTINGS",
  SUBMIT_KINUS_REGISTRATION: "SUBMIT_KINUS_REGISTRATION",

  getKinusProfile(personId) {
    return async (dispatch) => {
      dispatch(KinusActions.setKinusProfileLoading(true));

      let success = true;
      const response = await AuthRequest.get(`Kinus/People/${personId}`).catch(
        (err) => {
          success = false;
        },
      );

      const data = success ? response.data : {};

      //default registration form request - not using for now
      // const profileRegistrationResponse = await AuthRequest
      //     .get(`Kinus/People/${personId}/Registration`)
      //     .catch(err => {
      //         success = false;
      //     });

      // const data = success ? {
      //     ...profileResponse.data,
      //     registrationForm: profileRegistrationResponse.data.payload
      // } : {};

      const newState = {
        ...data,
        loading: false,
        success,
      };

      dispatch(KinusActions.setKinusProfile(newState));
    };
  },
  getKinusSettings() {
    return async (dispatch) => {
      dispatch(KinusActions.setKinusSettingsLoading(true));

      let success = true;
      const response = await AuthRequest.get("Kinus/Settings").catch((err) => {
        success = false;
      });

      const data = success ? response.data : {};

      const newState = {
        ...data,
        loading: false,
        success,
      };

      dispatch(KinusActions.setKinusSettings(newState));
    };
  },
  registerForKinus(
    registrationValues,
    stripeValues,
    createStripeToken,
    refreshBanners,
  ) {
    return async (dispatch, getState) => {
      const attemptId = (getState().kinus.registration.attemptId || 0) + 1;

      dispatch(
        KinusActions.submitKinusRegistration({
          attemptId,
          loading: true,
        }),
      );

      let success = true;
      let message = "";

      if (!registrationValues.billing.useCardOnFile && createStripeToken) {
        let { token } = await createStripeToken(stripeValues);

        if (token) {
          registrationValues.billing.stripeToken = token;
        } else {
          success = false;
        }
      }

      if (success && registrationValues.invoice.applyPromoCode) {
        const promocodeResponse = await AuthRequest.get(
          "Kinus/Promocode",
        ).catch((err) => {
          success = false;
        });
        if (!success || !promocodeResponse.data.payload.isBelowThreshold) {
          success = false;
          message = "Promocode is invalid.  Please try again.";
        }
      }

      if (success) {
        await AuthRequest.post("/Kinus/Register", registrationValues).catch(
          (err) => {
            success = false;

            if (
              err.response &&
              err.response.data &&
              err.response.data.messages
            ) {
              message = err.response.data.messages.join(" \n");
            }
          },
        );
      }

      dispatch(
        KinusActions.submitKinusRegistration({
          loading: false,
          message,
          success,
        }),
      );

      if (refreshBanners) {
        dispatch(SysActions.getSystemData());
      }

      if (!success) {
        setTimeout(() => {
          if (getState().kinus.registration.attemptId === attemptId) {
            dispatch(
              KinusActions.submitKinusRegistration({
                success: undefined,
                message: "",
              }),
            );
          }
        }, 5000);
      }
    };
  },
  setKinusProfileLoading(payload) {
    return {
      type: KinusActions.SET_KINUS_PROFILE_LOADING,
      payload,
    };
  },
  setKinusProfile(payload) {
    return {
      type: KinusActions.SET_KINUS_PROFILE,
      payload,
    };
  },
  setKinusSettingsLoading(payload) {
    return {
      type: KinusActions.SET_KINUS_SETTINGS_LOADING,
      payload,
    };
  },
  setKinusSettings(payload) {
    return {
      type: KinusActions.SET_KINUS_SETTINGS,
      payload,
    };
  },
  submitKinusRegistration(payload) {
    return {
      type: KinusActions.SUBMIT_KINUS_REGISTRATION,
      payload,
    };
  },
};
