import React from "react";
import Modal from "../../components/Modal";
import { Navigation } from "../../lib";

export function ProgramDetailsTab({
  pageRoute,
  scheduleContents,
  jotformQueryString,
  getContentSectionId,
  toggleProgramSection,
  scrollToSection,
  state,
  setState,
}) {
  // const stringToHTML = function (str) {
  //   var parser = new DOMParser();
  //   var doc = parser.parseFromString(str, "text/html");
  //   return doc.body;
  // };

  const displayHTML = (content, jotformQueryString, contentSectionId) => {
    let contentHtml = content;
    if (
      content &&
      jotformQueryString &&
      content.indexOf("form.jotform.com") >= 0
    ) {
      contentHtml = content.replace(
        /(href|src)="https:\/\/form.jotform.com\/(.*?)"/g,
        (jotformUrl) =>
          `${jotformUrl.substring(
            0,
            jotformUrl.length - 1,
          )}${jotformQueryString}"`,
      );

      const el = document.createElement("div");
      el.innerHTML = contentHtml;

      const children = Object.keys(el.childNodes).map((k) => el.childNodes[k]);
      // Admin will need to create top level p tag with 'jotform-link' id containing the jotform link text
      // followed by a top level p tag with 'jotform' id containing the jotform iframe
      // jotform iframe height should be set to 100%

      let jotformLinkIndex = 0;

      return (
        <React.Fragment>
          {children.map((childNode, index) => {
            var jfModalName = `showJotformModal${contentSectionId}.${
              jotformLinkIndex || ""
            }`;
            if (childNode.id === "jotform-link") {
              return (
                <div
                  className="flex flex-align-center mt-8 mb-8 jotform-link"
                  key={index}
                >
                  <button
                    className="btn btn-accent"
                    dangerouslySetInnerHTML={{ __html: childNode.innerHTML }}
                    onClick={() => setState(jfModalName, true)}
                  />
                  {contentSectionId && (
                    <i
                      className="fa fa-link accent-text-secondary small-text ml-16"
                      onClick={() => {
                        scrollToSection(contentSectionId, true, jfModalName);
                      }}
                    />
                  )}
                </div>
              );
            } else if (childNode.id === "jotform") {
              jotformLinkIndex = ++jotformLinkIndex;

              const {
                location: { hash: routeHash, pathname },
                query: { sched },
              } = pageRoute;

              return (
                <Modal key={index} show={!!state[jfModalName]}>
                  <div className="modal-container full-page-mobile-modal-container">
                    <div className="modal modal-card card program-jotform-modal">
                      <div className="flex flex-justify-end mobile-flex-justify-start mb-8">
                        <i
                          className="material-icons pointer link-text-secondary"
                          onClick={() => {
                            setState(jfModalName, false);
                            Navigation.redirect(
                              `${pathname}?sched=${sched}${routeHash}`,
                            );
                          }}
                        >
                          close
                        </i>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: childNode.innerHTML,
                        }}
                        style={{
                          height: "calc(100% - 72px)",
                          paddingBottom: 72,
                        }}
                      />
                    </div>
                  </div>
                </Modal>
              );
            } else {
              return (
                <div
                  dangerouslySetInnerHTML={{ __html: childNode.innerHTML }}
                  key={index}
                />
              );
            }
          })}
        </React.Fragment>
      );
    }

    return <div dangerouslySetInnerHTML={{ __html: contentHtml }} />;
  };

  const toggleAllProgramSections = (programScheduleContents, expand) => {
    if (programScheduleContents && programScheduleContents.length) {
      programScheduleContents.forEach((psc) => {
        const sectionId = getContentSectionId(psc);
        const section = document.getElementById(sectionId);

        if (
          section &&
          ((expand && !section.classList.contains("open")) ||
            (!expand && section.classList.contains("open")))
        ) {
          section.classList.toggle("open");
        }
      });
    }
  };

  return (
    <div>
      {scheduleContents.length > 0 && (
        <div className="flex flex-justify-end">
          <span
            className="link-text-secondary accent-text"
            onClick={() => toggleAllProgramSections(scheduleContents, true)}
          >
            Expand all
          </span>
          <span className="accent-text mr-16 ml-16 small-text">|</span>
          <span
            className="link-text-secondary accent-text"
            onClick={() => toggleAllProgramSections(scheduleContents, false)}
          >
            Collapse all
          </span>
        </div>
      )}
      {scheduleContents.length
        ? scheduleContents.map((content) => {
            const contentSectionId = getContentSectionId(content);
            return (
              <div className="section" key={content.id} id={contentSectionId}>
                <div
                  className="section-title"
                  onClick={() => toggleProgramSection(contentSectionId)}
                >
                  <div className="flex">
                    <h4>{content.name}</h4>
                    <i
                      className="fas fa-link accent-text-secondary copy-link"
                      onClick={(e) => {
                        e.stopPropagation();
                        scrollToSection(contentSectionId, true);
                      }}
                    />
                  </div>
                  <i className="material-icons accent-text-secondary closed">
                    keyboard_arrow_down
                  </i>
                  <i className="material-icons accent-text-secondary open">
                    keyboard_arrow_up
                  </i>
                </div>
                <div className="section-body">
                  {displayHTML(
                    content.content,
                    jotformQueryString,
                    contentSectionId,
                  )}
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
}
