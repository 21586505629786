import React from "react";

export default class HotelRooms extends React.PureComponent {
  constructor(props) {
    super(props);

    const { pricing } = props;

    this.state = {
      busPricing: {
        familyBussingOneWay: pricing.find(
          (item) => item.priceCode === "FamilyBussingOneWay",
        ).subsidizedCost,
        familyBussingTwoWays: pricing.find(
          (item) => item.priceCode === "FamilyBussingTwoWays",
        ).subsidizedCost,
        childBussing: pricing.find((item) => item.priceCode === "ChildBussing")
          .subsidizedCost,
        adultBussing: pricing.find((item) => item.priceCode === "AdultBussing")
          .subsidizedCost,
      },
    };
  }

  render() {
    const {
      busLeavingCrownHeightsDate,
      busLeavingCrownHeightsTime,
      busLeavingKinusDate,
      busLeavingKinusTime,
      childrenAttending,
      parentsAttending,
      formProps: { onChange, values },
    } = this.props;

    const { busPricing } = this.state;

    return (
      <div className="kinus-form-section">
        <p className="medium-text fw-700 mb-16">Bus Transportation</p>
        <p className="accent-text-dark mb-24" style={{ lineHeight: "1.5" }}>
          {`Crown Heights to Hotel: ${busLeavingCrownHeightsDate}, at ${busLeavingCrownHeightsTime}.`}
          <br />
          {`Hotel to Crown Heights: ${busLeavingKinusDate} at ${busLeavingKinusTime}.`}
          <br />
          <br />
          {`Cost per way: ${
            busPricing.childBussing === busPricing.adultBussing
              ? `$${busPricing.childBussing} per person.`
              : `$${busPricing.childBussing} per child, $${busPricing.adultBussing} per adult.`
          }
                    Maximum per family: $${
                      busPricing.familyBussingOneWay
                    } one way, 
                        $${
                          busPricing.familyBussingTwoWays
                        } round trip. Limited availability.`}
        </p>
        <div className="flex flex-align-center transportation-row">
          <p className="small-text accent-text">Crown Heights to Hotel</p>
          <select
            className="custom-select mr-16"
            name="toKinusBussing.numberOfAdults"
            value={values.toKinusBussing.numberOfAdults}
            onChange={(event) =>
              onChange(event.target.name, parseInt(event.target.value, 10))
            }
            disabled={!parentsAttending}
          >
            <option defaultChecked={true}>0 Adults</option>
            {Array(parentsAttending)
              .fill()
              .map((_, i) => i + 1)
              .map((number) => (
                <option key={number} value={number}>
                  {number} {`Adult${number !== 1 ? "s" : ""}`}
                </option>
              ))}
          </select>
          <select
            className="custom-select mr-16"
            name="toKinusBussing.numberOfChildren"
            value={values.toKinusBussing.numberOfChildren}
            onChange={(event) =>
              onChange(event.target.name, parseInt(event.target.value, 10))
            }
            disabled={!childrenAttending}
          >
            <option defaultChecked={true}>0 Children</option>
            {Array(childrenAttending)
              .fill()
              .map((_, i) => i + 1)
              .map((number) => (
                <option key={number} value={number}>
                  {number} {`Child${number !== 1 ? "ren" : ""}`}
                </option>
              ))}
          </select>
        </div>
        <div className="flex flex-align-center transportation-row">
          <p className="small-text accent-text">Hotel to Crown Heights</p>
          <select
            className="custom-select mr-16"
            name="fromKinusBussing.numberOfAdults"
            value={values.fromKinusBussing.numberOfAdults}
            onChange={(event) =>
              onChange(event.target.name, parseInt(event.target.value, 10))
            }
            disabled={!parentsAttending}
          >
            <option defaultChecked={true}>0 Adults</option>
            {Array(parentsAttending)
              .fill()
              .map((_, i) => i + 1)
              .map((number) => (
                <option key={number} value={number}>
                  {number} {`Adult${number !== 1 ? "s" : ""}`}
                </option>
              ))}
          </select>
          <select
            className="custom-select mr-16"
            name="fromKinusBussing.numberOfChildren"
            value={values.fromKinusBussing.numberOfChildren}
            onChange={(event) =>
              onChange(event.target.name, parseInt(event.target.value, 10))
            }
            disabled={!childrenAttending}
          >
            <option defaultChecked={true}>0 Children</option>
            {Array(childrenAttending)
              .fill()
              .map((_, i) => i + 1)
              .map((number) => (
                <option key={number} value={number}>
                  {number} {`Child${number !== 1 ? "ren" : ""}`}
                </option>
              ))}
          </select>
        </div>
      </div>
    );
  }
}
