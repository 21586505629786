import React from "react";
import Pages from "../../../pages";
import ChabadHouseCard from "./ChabadHouseCard";
import Loader from "../../../components/Loader";
import { PROFILE_PLACEHOLDER_URL, PageLink } from "../../../lib";
import { withAppInsights } from "../../../lib/AppInsights";

class DirectoryDetails extends React.PureComponent {
  componentDidMount() {
    const { getChabadHouses, getContact, getSchools } = this.props.actions;
    const { contactId } = this.props.pageRoute.params;
    getChabadHouses(contactId);
    getContact(contactId, true);
    getSchools(contactId);
  }

  renderPosition = (chabadHouses) => {
    for (let i = 0; i < chabadHouses.length; i++) {
      if (chabadHouses[i].shliachPosition !== "Unknown") {
        return (
          <p className="small-text">
            <i className="fas fa-crown mr-8" />{" "}
            {chabadHouses[i].shliachPositionDisplay}
          </p>
        );
      }
    }
  };

  renderEmail = (email, icon) => {
    if (email) {
      return (
        <span className="email">
          <a
            href={`mailto:${email.value}`}
            target="_blank"
            rel="noopener noreferrer"
            className={
              icon
                ? "link-with-icon underline-text"
                : "link-text-secondary underline-text"
            }
          >
            {icon ? <i className="material-icons">email</i> : null}
            <span>{email.value}</span>
          </a>
        </span>
      );
    }
  };

  renderPhone = (phone, icon) => {
    if (phone) {
      const formattedPhoneNumber = `+${phone.callingCode} ${phone.value}`;
      return (
        <span className="phone mr-32 inline-block no-wrap">
          <a
            href={`tel:${formattedPhoneNumber}, ${phone.phoneExtension}`}
            target="_blank"
            rel="noopener noreferrer"
            className={
              icon
                ? "link-with-icon underline-text"
                : "link-text-secondary underline-text"
            }
          >
            {icon ? <i className="material-icons">phone</i> : null}
            <span>{formattedPhoneNumber}</span>
            {phone.phoneExtension ? (
              <span className="small-text">
                <span style={{ marginLeft: "4px" }}>ext:</span>{" "}
                {phone.phoneExtension}
              </span>
            ) : null}
          </a>
        </span>
      );
    }
  };

  renderChabadHouses = (chabadHouse) => {
    if (chabadHouse !== null) {
      if (this.props.pageRoute.query.view === "school") {
        let { chabadHouseId } = this.props.pageRoute.query;
        // eslint-disable-next-line
        if (chabadHouse.id == chabadHouseId) {
          return (
            <ChabadHouseCard
              {...this.props}
              chabadHouse={chabadHouse}
              contactId={this.props.pageRoute.params.contactId}
              key={chabadHouse.id}
              renderEmail={this.renderEmail}
              renderPhone={this.renderPhone}
            />
          );
        }
      } else {
        return (
          <ChabadHouseCard
            {...this.props}
            chabadHouse={chabadHouse}
            contactId={this.props.pageRoute.params.contactId}
            key={chabadHouse.id}
            renderEmail={this.renderEmail}
            renderPhone={this.renderPhone}
          />
        );
      }
    }
  };

  render() {
    const chabadHousesArray = this.props.chabadHousesObject.chabadHouses || [];
    const { chabadHouses, loading, spouse, yearsOnShlichus } =
      this.props.contact;
    const {
      addresses,
      firstName,
      lastName,
      profileImageURL,
      titleDisplay,
      personalPhone,
      personalPhoneCallingCode,
      personalEmail,
    } = this.props.contact.details || {};
    return (
      //NOTE: this component had goback={true} passed into layout but doesn't look like it did anything
      <div className="directory-details-page page container">
        <p
          onClick={this.props.pageRoute.history.goBack}
          className="link-text mobile-hidden"
        >
          <i className="material-icons back-arrow">arrow_back</i>
          Go back
        </p>
        {!loading ? (
          <React.Fragment>
            <div className="cd-card directory-card">
              <div className="cd-card-left">
                <div className="cd-profile-pic mobile-hidden">
                  <img
                    src={
                      profileImageURL
                        ? profileImageURL
                        : PROFILE_PLACEHOLDER_URL
                    }
                    alt="contact profile pic"
                  />
                </div>
                <div>
                  <div>
                    <div className="cd-profile-pic desktop-hidden tablet-hidden">
                      <img src={profileImageURL} alt="contact profile pic" />
                    </div>
                    <div>
                      <p className="fw-900 xxl-text mb-8">
                        {titleDisplay} {firstName} {lastName}
                      </p>
                      {chabadHouses && this.renderPosition(chabadHouses)}
                    </div>
                  </div>
                  <div>
                    <div className="cd-contact-info mt-24 flex">
                      <span className="phone mr-32 inline-block no-wrap">
                        <a
                          href={`tel:+${personalPhoneCallingCode} ${personalPhone}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link-with-icon"
                        >
                          <i className="material-icons">phone</i>
                          <span>{`+${personalPhoneCallingCode} ${personalPhone}`}</span>
                        </a>
                      </span>
                      <span className="phone mr-32 inline-block no-wrap">
                        <a
                          href={`mailto:${personalEmail}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link-with-icon"
                        >
                          <i className="material-icons">email</i>
                          <span>{personalEmail}</span>
                        </a>
                      </span>
                    </div>
                    <p className="xs-text accent-text mt-24">
                      * Phone is for internal purposes only. Please do not share
                      without permission.
                    </p>
                  </div>
                </div>
              </div>
              <div className="cd-card-divider" />
              <div className="cd-card-right">
                <p>
                  <span className="fw-700">Years on Shlichus:</span>
                  {yearsOnShlichus}
                </p>
                <p>
                  <span className="fw-700">Country:</span>
                  {addresses
                    ? addresses.length > 0
                      ? addresses[0].country !== null
                        ? addresses[0].country
                        : null
                      : null
                    : null}
                </p>
                <div className="cd-spouse">
                  {spouse ? (
                    <React.Fragment>
                      <label className="mt-16 mb-4">Spouse</label>
                      <div className="flex flex-align-center">
                        <img
                          src={
                            spouse.profileImageURL
                              ? spouse.profileImageURL
                              : PROFILE_PLACEHOLDER_URL
                          }
                          alt="spouse"
                          className="profile-pic-small"
                        />
                        <PageLink
                          to={Pages.directory.directoryDetails}
                          params={{ contactId: spouse.shliachID || 0 }}
                          className="ml-8 link-text"
                        >
                          <span>
                            {spouse.titleDisplay && spouse.titleDisplay + " "}
                          </span>
                          <span>
                            {spouse.firstName && spouse.firstName + " "}
                          </span>
                          <span>
                            {spouse.lastName && spouse.lastName + " "}
                          </span>
                        </PageLink>
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
            </div>
            {chabadHousesArray
              ? chabadHousesArray.map((chabadHouse) =>
                  this.renderChabadHouses(chabadHouse),
                )
              : "Loading..."}
          </React.Fragment>
        ) : (
          <div className="full-page-loader">
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

export default withAppInsights(DirectoryDetails);
