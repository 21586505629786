import { CampusesActions } from "./actions";

export const CampusesState = {
  name: "campuses",
  persist: false,
  defaults: {
    list: [],
  },
  handlers: {
    [CampusesActions.GET_CAMPUSES]: function getCampuses(state, action) {
      return {
        list: action.payload || [],
      };
    },
  },
};
