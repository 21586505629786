import React from "react";
import { currencyCodes, formatCurrency } from "../../../../../../../lib/utils";

function RegistrationPayment(props) {
  const {
    scheduleDetails: { registrationFeeAmount },
  } = props;

  return (
    <>
      <div className="separatorTop mt-16" />
      <div className="accent-text-dark mt-16">
        <p>
          This is a paid event. By registering the student you are agreeing to
          waive the registration fee. Entering payment information for a student
          is coming soon.
        </p>
        <div className="flex custom-checkbox-container waive-fee-checkbox mt-8">
          <input
            type="checkbox"
            className="custom-checkbox"
            id="isRegistrationFeeWaived"
            checked={true}
            readOnly={true}
            onChange={() => {
              /* implement when functionality is fully built */
            }}
          />
          <label htmlFor="isRegistrationFeeWaived">
            Waive {formatCurrency(registrationFeeAmount, currencyCodes.USD)}{" "}
            registration fee
          </label>
        </div>
      </div>
    </>
  );
}

export default RegistrationPayment;
