import React from "react";
import UnderFourMonths from "./UnderFourMonths";
import FourToTwentyFourMonths from "./FourToTwentyFourMonths";
import TwoToFourYears from "./TwoToFourYears";
import FiveToSevenYears from "./FiveToSevenYears";
import EightToTwelveYears from "./EightToTwelveYears";
import UnrecognizedAgeGroup from "./UnrecognizedAgeGroup";

export default ({ child, ...props }) => {
  let questionsComponent;
  switch (child.kinusAgeGroupID) {
    case 1:
      questionsComponent = UnderFourMonths;
      break;
    case 2:
      questionsComponent = FourToTwentyFourMonths;
      break;
    case 3:
      questionsComponent = TwoToFourYears;
      break;
    case 4:
      questionsComponent = FiveToSevenYears;
      break;
    case 5:
      questionsComponent = EightToTwelveYears;
      break;
    default:
      questionsComponent = UnrecognizedAgeGroup;
      break;
  }

  return React.createElement(questionsComponent, {
    child,
    ...props,
  });
};
