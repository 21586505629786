import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../types";
import { AuthTypes, InternPermissions } from "../../lib";

const emptyObject = {};
const emptyArray: any[] = [];

export const AuthSelectors = {
  engagementAccess(state: AppState) {
    return state.auth.engagementAccess?.payload?.data || emptyObject;
  },
  engagementAccessLoading(state: AppState) {
    return state.auth.engagementAccess?.payload?.loading;
  },
  claims(state: AppState) {
    return state.auth.permissionClaims || emptyArray;
  },
  incompleteUserProfile(state: AppState) {
    return state.auth.incompletePersonProfile;
  },
  isLoggedInAsIntern: createSelector(
    (state: AppState) => state.auth.loggedInAs,
    (loggedInAs: string) => loggedInAs === AuthTypes.intern,
  ),
  isLoggedInAsShliach: createSelector(
    (state: AppState) => state.auth.loggedInAs,
    (loggedInAs: string) => loggedInAs === AuthTypes.shliach,
  ),
  loggedInAs(state: AppState) {
    return state.auth.loggedInAs;
  },
  shliachID(state: AppState) {
    return state.auth.shliachID;
  },
  user(state: AppState) {
    return state.auth.person || emptyObject;
  },
  userHasClaim: createSelector(
    [
      (state: AppState) => state.auth.permissionClaims,
      (_: AppState, claim: string) => claim,
    ],
    (claims: string[], claim: string) => claims.includes(claim),
  ),

  /**** INTERN-SPECIFIC SELECTORS ****/
  canManageStudents: createSelector(
    (state: AppState) => state.auth,
    (auth: any) =>
      auth.loggedInAs === AuthTypes.shliach ||
      auth.permissionClaims.includes(InternPermissions.manageStudents),
  ),
  canManageActivity: createSelector(
    (state: AppState) => state.auth,
    (auth: any) =>
      auth.loggedInAs === AuthTypes.shliach ||
      auth.permissionClaims.includes(InternPermissions.manageActivity),
  ),
  canManageRsvp: createSelector(
    (state: AppState) => state.auth,
    (auth: any) =>
      auth.loggedInAs === AuthTypes.shliach ||
      auth.permissionClaims.includes(InternPermissions.manageRsvp),
  ),
  canManageEduAttendance: createSelector(
    (state: AppState) => state.auth,
    (auth: any) =>
      auth.loggedInAs === AuthTypes.shliach ||
      auth.permissionClaims.includes(InternPermissions.eduAttendance),
  ),
  canManageEduSchedule: createSelector(
    (state: AppState) => state.auth,
    (auth: any) =>
      auth.loggedInAs === AuthTypes.shliach ||
      auth.permissionClaims.includes(InternPermissions.eduSchedule),
  ),
  canManageEduStudents: createSelector(
    (state: AppState) => state.auth,
    (auth: any) =>
      auth.loggedInAs === AuthTypes.shliach ||
      auth.permissionClaims.includes(InternPermissions.eduStudents),
  ),
  canManageRaffleDonations: createSelector(
    (state: AppState) => state.auth,
    (auth: any) =>
      auth.loggedInAs === AuthTypes.shliach ||
      auth.permissionClaims.includes(InternPermissions.manageRaffleDonations),
  ),
};
