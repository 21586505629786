import React from "react";
import ExportCSVButton from "../../../../../components/ExportCSVButton";
import ExportStudentSurveyResponsesButton from "./ExportStudentSurveyResponsesButton";
import MessageStudentsButton from "./MessageStudentsButton";
import StudentsList from "./StudentsList";
import StudentsListFilters from "./StudentsListFilters";
import { AuthRequest, AuthTypes } from "../../../../../lib";
import moment from "moment";
import queryString from "query-string";

export default class StudentsListContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.isShliach = props.auth.loggedInAs === AuthTypes.shliach;
  }

  componentDidMount() {
    this.getStudents();
  }

  getStudents = (page, resultsView) => {
    //page & resultsView sent with loadData from paginatedTable must override pageRoute query params, because data is fetched prior to params being refreshed
    const {
      pageRoute: {
        query: { p = 1, r = 10 },
      },
    } = this.props;

    this.props.actions.getEduStudents(
      page || p,
      resultsView || r,
      this.getListFilters(),
      this.props.pageRoute.params.courseScheduleId, //query is different if its a specific course
    );
  };

  getStudentsForAction = async (isExport) => {
    const {
      enrollment: { data: { id: eduEnrollmentId } = {} },
      pageRoute: {
        params: { courseScheduleId }, //for individual course schedule students list
      },
    } = this.props;

    const { schedule, search, status } = this.getListFilters();

    const params = {
      courseScheduleId: schedule,
      text: search,
    };

    courseScheduleId
      ? (params.courseScheduleEnrollmentStatuses = status)
      : (params.studentEnrollmentStatus = status);

    if (isExport) {
      params.isExport = true;
    } else {
      params.noPage = true;
    }

    const response = await AuthRequest.get(
      `EduChabadHouses/${eduEnrollmentId}/Students?${queryString.stringify(
        params,
      )}`,
    );
    return response.data.payload && response.data.payload.results;
  };

  getListFilters = () => {
    const {
      pageRoute: {
        query: { sc, se, st },
        params: { courseScheduleId }, //for individual course schedule students list
      },
    } = this.props;

    return {
      [this.studentsFilterDefinitions.sc]: courseScheduleId || sc,
      [this.studentsFilterDefinitions.se]: se && decodeURIComponent(se),
      [this.studentsFilterDefinitions.st]: st,
    };
  };

  studentsFilterDefinitions = {
    p: "page",
    r: "resultsView",
    sc: "schedule",
    se: "search",
    st: "status",
  };

  render() {
    const {
      actions,
      courseSchedule: {
        data: {
          didClassesEnd,
          name: courseScheduleName,
          hasStudentSurveyResponses,
        } = {},
      },
      courseSchedules,
      enrollment: {
        data: { eduProgramName, eduProgramType, id: eduEnrollmentId } = {},
      },
      pageRoute,
      students,
      submitStudentEnrollment,
      sys: { approvalStatuses = [] },
      ui,
    } = this.props;

    const {
      params: { courseScheduleId }, //courseScheduleId param indicates individual course schedule students list
      query: { sc: courseScheduleFilter }, //sc query param is schedule filter (dropdown filter) for general students list
    } = pageRoute;

    return (
      <div className="flex tablet-block mobile-block flex-align-top">
        <div className="card full-width edu-students-card mobile-flat-card">
          <div className="flex flex-align-center flex-justify-space mobile-block mb-24 edu-students-header">
            <h2 className="page-title mb-0">
              {!courseScheduleId ? "My " : ""}Students
            </h2>
            <div className="flex flex-align-center mobile-mt-8">
              {this.isShliach && (
                <MessageStudentsButton
                  disabled={!students.data || !students.data.numberOfRows}
                  getStudents={() => this.getStudentsForAction()}
                  ui={ui}
                />
              )}
              <ExportCSVButton
                className="btn btn-accent students-export-btn"
                disabled={!students.data || !students.data.numberOfRows}
                fileName={`${eduProgramName}${
                  (courseScheduleId &&
                    courseScheduleName &&
                    "-" +
                      courseScheduleName
                        .trim()
                        .replace(/-/g, " ")
                        .replace(/\s+/g, "-")) ||
                  ""
                }-Students`}
                getExportData={() => this.getStudentsForAction(true)}
                getHeaders={() => [
                  { label: "First Name", value: "studentFirstName" },
                  { label: "Last Name", value: "studentLastName" },
                  { label: "Email", value: "studentEmail" },
                  { label: "Phone", value: "studentCell" },
                  {
                    label: "Status",
                    value: !!courseScheduleId
                      ? "courseScheduleEnrollmentStatus"
                      : "studentEnrollmentStatusDisplay",
                  },
                  {
                    label: "Member Since",
                    value: ({ memberSinceDate }) =>
                      memberSinceDate
                        ? moment(memberSinceDate).format("MM/DD/YYYY")
                        : "",
                  },
                  ...(courseScheduleId || courseScheduleFilter
                    ? [
                        {
                          label: "Course Schedule",
                          value: "courseScheduleName",
                        },
                        {
                          label: "Classes Attended",
                          value: "numOfClassesAttended",
                        },
                        {
                          label: "Classes Scheduled",
                          value: "numOfClassesScheduled",
                        },
                        {
                          label: "Review Completed",
                          value: ({ didCompleteSurvey }) =>
                            didCompleteSurvey ? "Yes" : "No",
                        },
                      ]
                    : [
                        {
                          label: "Latest Course Schedule",
                          value: "courseScheduleName",
                        },
                      ]),
                ]}
                title="Export Students"
              />
              {courseScheduleId &&
                hasStudentSurveyResponses &&
                this.isShliach && (
                  <ExportStudentSurveyResponsesButton
                    courseScheduleId={courseScheduleId}
                    disabled={!students.data || !students.data.numberOfRows}
                    eduEnrollmentId={eduEnrollmentId}
                    fileName={`${eduProgramName}${
                      (courseScheduleId &&
                        courseScheduleName &&
                        "-" +
                          courseScheduleName
                            .trim()
                            .replace(/-/g, " ")
                            .replace(/\s+/g, "-")) ||
                      ""
                    }-Student-Reviews`}
                  />
                )}
            </div>
          </div>
          <StudentsList
            didClassesEnd={didClassesEnd}
            doSubmitStudentEnrollment={actions.submitStudentEnrollmentStatus}
            eduProgramType={eduProgramType}
            filterComponent={
              <StudentsListFilters
                approvalStatuses={approvalStatuses}
                getStudents={this.getStudents}
                pageRoute={pageRoute}
                showStatusFilter={!!courseScheduleId}
                showScheduleFilter={!courseScheduleId}
                courseSchedules={courseSchedules}
                getEduCourseSchedules={actions.getEduCourseSchedules}
              />
            }
            getStudents={this.getStudents}
            isStudentsByCourseView={!!courseScheduleId}
            pageRoute={pageRoute}
            students={students}
            submitStudentEnrollment={submitStudentEnrollment}
          />
        </div>
      </div>
    );
  }
}
