import React from "react";
import PaginatedTable from "../../../components/PaginatedTableDeprecated";
import { PageLink, PageURL } from "../../../lib";
import Pages from "../../../pages";

export default class ShluchimDirectoryTable extends React.PureComponent {
  renderPrimarySchool(contact) {
    for (let i = 0; i < contact.campuses.length; i++) {
      if (contact.campuses[i].isPrimary === true) {
        return <p className="table-data">{contact.campuses[i].name}</p>;
      }
    }
  }

  renderSecondarySchools(contact) {
    if (contact.campuses.length > 1) {
      return (
        <p className="table-data-sub">
          + {contact.campuses.length - 1} secondary
          {contact.campuses.length === 2 ? " school" : " schools"}
        </p>
      );
    }
  }

  getTableRow = (contact, index) => {
    return (
      <PageLink
        to={Pages.directory.directoryDetails}
        params={{ contactId: contact.id }}
        key={index}
      >
        <div className="table-row directory-table-row">
          <div>
            <p className="table-label tablet-hidden mobile-hidden">
              {contact.gender === "Male" || contact.gender === "Unknown"
                ? "Shliach"
                : "Shlucha"}
            </p>
            <p className="table-data directory-contact-name">
              {contact.title} {contact.firstName} {contact.lastName}
            </p>
          </div>
          <div className="mobile-hidden">
            <p className="table-label">Chabad House</p>
            <p className="table-data">{contact.chabadHouseName}</p>
          </div>
          <div className="mobile-hidden">
            <p className="table-label">School (Primary)</p>
            {this.renderPrimarySchool(contact)}
            {this.renderSecondarySchools(contact)}
          </div>
          <div className="directory-contact-contact-info">
            <div>
              <p className="table-label tablet-hidden mobile-hidden">
                Cell Phone
              </p>
              <p className="table-data">
                {/* <a href={`tel:${contact.personalPhone}`}> */}
                <i className="material-icons accent-text-secondary desktop-hidden">
                  phone
                </i>
                {contact.personalPhoneCallingCode &&
                contact.personalPhoneCallingCode !== "0"
                  ? `+${contact.personalPhoneCallingCode} ${
                      contact.personalPhone || ""
                    }`
                  : `${contact.personalPhone || ""}`}{" "}
                {/* </a> */}
              </p>
            </div>
            <div>
              <p className="table-label tablet-hidden mobile-hidden">Email</p>
              <p className="table-data email">
                <i className="material-icons accent-text-secondary desktop-hidden">
                  email
                </i>
                <span>{contact.personalEmail}</span>
              </p>
            </div>
          </div>
        </div>
      </PageLink>
    );
  };

  getNavigationURL = (page) => {
    const {
      pageRoute,
      pageRoute: {
        query: { q: searchTerm = "", r: resultsView = 15 },
      },
    } = this.props;
    return PageURL.to(pageRoute.page, null, {
      q: searchTerm,
      p: page,
      r: resultsView,
    });
  };

  render() {
    const {
      contacts: { total = 0, records = [], loading, success },
      getContacts,
      pageRoute,
      pageRoute: {
        query: { q: searchTerm = "", p: page = 1, r: resultsView = 15 },
      },
      ui: { isPhone },
    } = this.props;

    return (
      <PaginatedTable
        autocompleteEndpoint="AutoComplete/shluchim"
        getURL={this.getNavigationURL}
        isPhone={isPhone}
        loadData={getContacts}
        loading={loading}
        name="Contacts"
        page={page}
        pageRoute={pageRoute}
        records={records}
        renderRow={this.getTableRow}
        resultsIncrement={15}
        resultsView={resultsView}
        searchTerm={searchTerm}
        success={success}
        totalCount={total}
      />
    );
  }
}
