import React, { Fragment, useEffect, useState } from "react";
import ErrorDisplay from "../../components/ErrorDisplay";
import Loader from "../../components/Loader";
import { ResourceFile } from "./ResourceFile";
import { ResourcePreviewModal } from "./ResourcePreviewModal";
import NoAccess from "./NoAccess";
import { Categories, FolderItemType } from "./constants";
import {
  Navigation,
  ApiCallErrorMessageHandler,
  AuthRequest,
  PageURL,
} from "../../lib";
import queryString from "query-string";
import { useStyles } from "./resourceTab.styles";

export function ResourceTab({ id, resourcesFolderID, pageRoute, programName }) {
  const classes = useStyles();
  const {
    page,
    params,
    query: { folderId, fileId, tab, sched },
  } = pageRoute;
  const [files, setFiles] = useState([]);
  const [folderDetails, setFolderDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [noAccessError, setNoAccessError] = useState(false);
  const [previewModal, setPreviewModal] = useState({
    show: false,
    file: null,
  });

  const categorizeFiles = () => {
    return Object.keys(Categories)
      .map((c) => ({
        category: c,
        files: getFilesByCategory(c),
      }))
      .filter((c) => c.files.length > 0);
  };

  const getFilesByCategory = (category) => {
    switch (category) {
      case Categories.Folders:
        return files.filter((file) => file.itemType === FolderItemType.Folder);
      case Categories.Images:
        return files.filter((file) => file.mimeType.includes("image"));
      case Categories.Audios:
        return files.filter((file) => file.mimeType.includes("audio"));
      case Categories.Videos:
        return files.filter((file) => file.mimeType.includes("video"));
      case Categories.Documents:
        return files.filter(
          (file) =>
            file.itemType === FolderItemType.File &&
            (file.mimeType.includes("application") ||
              file.mimeType.includes("text")),
        );
      default:
        return;
    }
  };

  useEffect(() => {
    (async function () {
      if (id && folderId) {
        setLoading(true);
        try {
          const response = await AuthRequest.get(
            `Programs/${id}/resources/folder/${folderId}`,
          );
          const { files } = response.data.payload;
          setFiles(files);
          await getFolderDetails(folderId);
          if (fileId) {
            setPreviewModal({
              show: true,
              file: files.find((f) => f.id === fileId),
            });
          }
          setLoading(false);
        } catch (err) {
          setErrorMessage(ApiCallErrorMessageHandler(err));
          setNoAccessError(
            err.response &&
              (err.response.status === 403 || err.response.status === 404),
          );
          setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, folderId]);

  const getFolderDetails = async (folderId) => {
    const response = await AuthRequest.get(
      `Programs/${id}/resources/file/${folderId}`,
    );
    setFolderDetails(response.data.payload);
  };

  const togglePreviewModal = (show, selectedFile = {}) => {
    const params = { folderId, tab, sched };
    if (selectedFile.id) {
      params.fileId = selectedFile.id;
    }
    window.history.pushState(
      null,
      "",
      `${window.location.pathname}?${queryString.stringify(params)}`,
    );
    setPreviewModal({
      show,
      file: selectedFile,
    });
  };

  const navigate = (folderId) => {
    Navigation.go(PageURL.to(page, params, { folderId, tab, sched }));
  };

  const trackFileView = async (file, type = "view") => {
    try {
      await AuthRequest.post(
        `Programs/${id}/resources/views?type=${type}`,
        file,
      );
    } catch (e) {
      console.log("Error tracking file view", e);
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : noAccessError ? (
        <NoAccess
          name={
            folderDetails.name ? folderDetails.name : programName + " resources"
          }
        />
      ) : errorMessage ? (
        <ErrorDisplay
          errorMessage={
            errorMessage === "Event404"
              ? "No folder found"
              : "Our server is being stubborn. Please try again."
          }
          imgSrc={`/images/${
            errorMessage === "Event404" ? "not_available" : "error"
          }.svg`}
        />
      ) : (
        <Fragment>
          <div className={classes.folderName}>
            {folderDetails.id !== resourcesFolderID && (
              <i
                className="material-icons link-text xl-text mr-12"
                onClick={() => navigate(folderDetails.parentID)}
              >
                arrow_back
              </i>
            )}
            <p>{folderDetails.name}</p>
          </div>
          {categorizeFiles().map((c, index) => (
            <Fragment key={index}>
              <div className={classes.section}>{c.category}</div>
              <div className={classes.resourceGrid}>
                {c.files.map((f, i) => (
                  <ResourceFile
                    key={i}
                    category={c.category}
                    file={f}
                    folderId={folderId}
                    navigate={navigate}
                    togglePreviewModal={togglePreviewModal}
                  />
                ))}
              </div>
            </Fragment>
          ))}
          {previewModal.show && previewModal.file && (
            <ResourcePreviewModal
              show={previewModal.show}
              file={previewModal.file}
              handleClose={togglePreviewModal}
              trackFileView={trackFileView}
            />
          )}
        </Fragment>
      )}
    </div>
  );
}
