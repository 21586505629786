import React from "react";
import { RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export default function MaterialRadioGroup(props) {
  const {
    color,
    checkedColor,
    labelColor,
    labelStyle,
    options,
    name,
    fieldValue,
    onChange,
    radioGroupProps,
    radioProps,
  } = props;

  const StyledRadio = withStyles({
    root: {
      color: color || "#2774ae",
      "&$checked": {
        color: checkedColor || "#2774ae",
      },
    },
    checked: {},
  })((styleProps) => <Radio color="default" {...styleProps} {...radioProps} />);

  return (
    <RadioGroup
      name={name}
      value={fieldValue}
      onChange={onChange}
      {...radioGroupProps}
    >
      {options.map((option, i) => {
        const { label, value } = option;
        return (
          <div key={i}>
            {option.label ? (
              <FormControlLabel
                style={{
                  color: labelColor || "rgba(0, 0, 0, 0.54)",
                  ...(labelStyle || {}),
                }}
                control={<StyledRadio />}
                label={label}
                value={value}
              />
            ) : (
              <StyledRadio />
            )}
          </div>
        );
      })}
    </RadioGroup>
  );
}
