import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { UpdatesActions } from "../../state/updates/actions";
import Updates from "./Updates";

export default connect(
  function mapState(state) {
    return {
      loading: state.updates.loading,
      posts: state.updates.posts,
      totalPosts: state.updates.totalPosts,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        { getPosts: UpdatesActions.getPosts },
        dispatch,
      ),
    };
  },
)(Updates);
