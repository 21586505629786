import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Typography, Breadcrumbs } from "@material-ui/core";
import StudentMergeDuplicates from "./StudentMergeDuplicates";
import { withAppInsights } from "../../../lib/AppInsights";

function StudentMergeDuplicatesPage(props) {
  const history = useHistory();

  return (
    <div className="page container student-details-page">
      <Breadcrumbs className="breadcrumbs-container">
        <span className="link pointer" onClick={history.goBack}>
          My Students
        </span>
        <span>Manage duplicates</span>
      </Breadcrumbs>

      <StudentMergeDuplicates pageRoute={props.pageRoute} />
    </div>
  );
}

export default withAppInsights(StudentMergeDuplicatesPage);
