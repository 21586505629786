import React from "react";
import BeneficiaryDesignationDocumentUpload from "./BeneficiaryDesignationDocumentUpload";
import { pluralizeText } from "../../../lib";

export default class BeneficiaryDesignationDocuments extends React.PureComponent {
  submitBeneficiaryDesignationDocument = async (name, doc) => {
    const {
      enrollment: { id: enrollmentId },
      onChange,
      uploadAndSubmitUpdatedDocument,
    } = this.props;

    if (!enrollmentId) {
      //New enrollment - doc is uploaded to azure and URL is submitted only at time when enrollment is submitted
      //saved to enrollment as docname without url, and is uploaded to azure and prop deleted and replaced with URL prop when enrollment is submitted
      onChange(name.replace("URL", ""), doc);
    } else {
      //Existing enrollment - doc is uploaded to azure and URL is updated imed
      return await uploadAndSubmitUpdatedDocument(name, doc);
    }
  };

  render() {
    const {
      documentTemplateURL,
      enrollment: {
        id: enrollmentId,
        shliach: {
          beneficiaryDesignationDocument: shliachBeneficiaryDesignationDocument,
          beneficiaryDesignationDocumentURL:
            shliachBeneficiaryDesignationDocumentURL,
          isEnrolled: isShliachEnrolled,
          shliachID: shliachId,
        },
        shlucha: {
          beneficiaryDesignationDocument: shluchaBeneficiaryDesignationDocument,
          beneficiaryDesignationDocumentURL:
            shluchaBeneficiaryDesignationDocumentURL,
          isEnrolled: isShluchaEnrolled,
          shliachID: shluchaId,
        },
      },
      submitAttempted,
    } = this.props;

    const shliachDocURL = shliachBeneficiaryDesignationDocument
      ? shliachBeneficiaryDesignationDocument.previewURL
      : shliachBeneficiaryDesignationDocumentURL;
    const shluchaDocURL = shluchaBeneficiaryDesignationDocument
      ? shluchaBeneficiaryDesignationDocument.previewURL
      : shluchaBeneficiaryDesignationDocumentURL;

    return (
      <div className="life-insurance-form-section">
        <div className="section-title">
          <h2>Beneficiary Designation Form</h2>
          {submitAttempted &&
            ((isShliachEnrolled && !shliachDocURL) ||
              (isShluchaEnrolled && !shluchaDocURL)) && (
              <p className="error-text small-text mt-8">
                *{" "}
                {[
                  isShliachEnrolled ? "Shliach" : "",
                  isShluchaEnrolled ? "Shlucha" : "",
                ]
                  .filter((s) => s)
                  .join(" and ")}{" "}
                Beneficiary Designation Form
                {isShliachEnrolled && isShluchaEnrolled ? "s are" : " is"}{" "}
                required
              </p>
            )}
        </div>
        <div>
          <p className="accent-text-dark mb-24">
            You can{" "}
            <a
              className="link-text line-height-double"
              href={documentTemplateURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              download the template here
            </a>
            . When you're ready, upload your{" "}
            {pluralizeText(
              "file",
              isShliachEnrolled && isShluchaEnrolled ? 2 : 1,
            )}{" "}
            below.
          </p>
          <div className="life-insurance-two-column-grid">
            {!!shliachId && (!enrollmentId || isShliachEnrolled) && (
              <BeneficiaryDesignationDocumentUpload
                disabled={!isShliachEnrolled}
                documentURL={shliachDocURL}
                enrollmentId={enrollmentId}
                error={
                  submitAttempted &&
                  isShliachEnrolled &&
                  !shliachBeneficiaryDesignationDocument &&
                  !shliachBeneficiaryDesignationDocumentURL
                }
                isSecure={!!shliachBeneficiaryDesignationDocumentURL} //if upload was not submitted yet, viewing preview and do not need secure access
                title="Shliach"
                submitDocument={(doc) =>
                  this.submitBeneficiaryDesignationDocument(
                    "shliach.beneficiaryDesignationDocumentURL",
                    doc,
                  )
                }
              />
            )}
            {!!shluchaId && (!enrollmentId || isShluchaEnrolled) && (
              <BeneficiaryDesignationDocumentUpload
                disabled={!isShluchaEnrolled}
                documentURL={shluchaDocURL}
                enrollmentId={enrollmentId}
                error={
                  submitAttempted &&
                  isShluchaEnrolled &&
                  !shluchaBeneficiaryDesignationDocument &&
                  !shluchaBeneficiaryDesignationDocumentURL
                }
                isSecure={!!shluchaBeneficiaryDesignationDocumentURL} //if upload was not submitted yet, viewing preview and do not need secure access
                title="Shlucha"
                submitDocument={(doc) =>
                  this.submitBeneficiaryDesignationDocument(
                    "shlucha.beneficiaryDesignationDocumentURL",
                    doc,
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
