import React from "react";
import CoursesList from "./CoursesList";
import CoursesListFilters from "./CoursesListFilters";
import { EduCourseListTypes } from "../../../constants";
import { Navigation, PageURL, canManageEduSchedule } from "../../../../../lib";

export default class CoursesListContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.coursesListResultsView = props.ui.isPhone ? 10 : 18;
    this.canViewAllCourses = canManageEduSchedule(props.auth);
  }

  componentDidUpdate(prevProps) {
    const {
      pageRoute: {
        page,
        params,
        query,
        query: { type: listType },
      },
    } = this.props;

    if (listType === EduCourseListTypes.all && !this.canViewAllCourses) {
      const url = PageURL.to(page, params, {
        ...query,
        type: EduCourseListTypes.my,
      });
      Navigation.redirect(url);
    }

    if (listType !== prevProps.pageRoute.query.type) {
      this.getCourses();
    }
  }

  getCourses = () => {
    const {
      pageRoute: {
        query: {
          ca,
          le,
          p = 1,
          se,
          so = this.getSortDefault(),
          type: listType,
        },
      },
    } = this.props;

    this.props.actions.getEduCourses(
      p,
      this.coursesListResultsView,
      {
        [this.coursesFilterDefinitions.ca]: ca,
        [this.coursesFilterDefinitions.le]: le,
        [this.coursesFilterDefinitions.se]: se && decodeURIComponent(se),
        [this.coursesFilterDefinitions.so]: so,
      },
      listType !== EduCourseListTypes.all,
    );
  };

  isMyCourses = () => {
    const {
      query: { type: listType },
    } = this.props.pageRoute;

    return listType !== EduCourseListTypes.all; //default to my courses, unless 'all' type specified
  };

  getSortDefault = () => (this.isMyCourses() ? "date" : "category"); //sort defaults to date for my courses and category for all courses

  coursesFilterDefinitions = {
    ca: "category",
    le: "level",
    p: "page",
    se: "search",
    so: "sortBy",
  };

  render() {
    const {
      courses,
      enrollment: {
        data: { eduProgramName },
      },
      pageRoute,
      sys: { eduCourseCategories = [], eduLevels = [] },
    } = this.props;

    const isMyCourses = this.isMyCourses();
    const sortDefault = this.getSortDefault();

    const {
      query: { so: sortBy = sortDefault },
    } = pageRoute;

    return (
      <React.Fragment>
        <div className="edu-courses-header">
          <h2 className="page-title">{isMyCourses ? "My" : "All"} Courses</h2>
          <CoursesListFilters
            courseCategories={eduCourseCategories}
            courseLevels={eduLevels}
            getCourses={this.getCourses}
            pageRoute={pageRoute}
            sortDefault={sortDefault}
          />
          <hr className="edu-courses-header-border" />
        </div>
        <CoursesList
          courses={courses}
          eduProgramName={eduProgramName}
          getCourses={this.getCourses}
          isCategorizedCourses={sortBy === "category"}
          isMyCourses={isMyCourses}
          pageRoute={pageRoute}
          resultsView={this.coursesListResultsView}
        />
      </React.Fragment>
    );
  }
}
