import React, {
  memo,
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AppBar,
  Box,
  Toolbar,
  createStyles,
  makeStyles,
  useScrollTrigger,
} from "@material-ui/core";

import Bh from "./Bh";
import ProfileDropdown from "./ProfileDropdown";

import { PageLink, getCociLogo } from "../lib";
import { useMobile } from "../themes";

import Pages from "../pages";
import { GenericObject } from "../state/types";

interface ElevationScrollProps {
  children: React.ReactElement;
}

function ElevationScroll(props: ElevationScrollProps) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      backgroundColor: "#fff",
      borderBottom: "1px solid #d3d3d354",
      color: "#000",
      boxShadow: ({ showShadow }: GenericObject) =>
        showShadow
          ? "0 2px 5px -2px rgba(0, 0, 0, 0.12), 0 2px 18px 2px rgba(0, 0, 0, 0.08);"
          : "",
      [theme.breakpoints.down("xs")]: {
        borderBottom: "none",
      },
    },
    headerRight: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      },
    },
    toolbar: {
      height: 72,
      justifyContent: "space-between",
      padding: "0px 40px",
      [theme.breakpoints.down("xs")]: {
        height: 56,
        padding: "0px 16px",
      },
    },
  }),
);

function SimpleAppBar() {
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const [showShadow, setShowShadow] = useState(false);

  const classes = useStyles({ showShadow });

  const toggleDropdown = useCallback(() => {
    setAccountDropdownOpen(!accountDropdownOpen);
  }, [accountDropdownOpen]);

  useEffect(() => {
    const scrollHandler = () => setShowShadow(window.pageYOffset > 10);
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  const isMobileView = useMobile();
  const cociLogo = useMemo(
    () =>
      isMobileView ? (
        <img
          src="/images/flame-solid.png"
          alt="logo"
          height="32px"
          width="32px"
        />
      ) : (
        <img src={getCociLogo()} alt="logo" />
      ),
    [isMobileView],
  );

  return (
    <ElevationScroll>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <PageLink to={Pages.main.home}>{cociLogo}</PageLink>
          <Box className={classes.headerRight}>
            <ProfileDropdown
              accountDropdownOpen={accountDropdownOpen}
              showAccountLink={false}
              showSendSmsIcon={false}
              toggleDropdown={toggleDropdown}
            />
            <Bh />
          </Box>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
}

export default memo(SimpleAppBar);
