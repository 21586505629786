import React from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements";

export default class CreditCardInfo extends React.PureComponent {
  state = {
    incompleteCardFields: {
      cardNumber: true,
      cardExpiry: true,
      cardCvc: true,
    },
  };

  onChangeCC = ({ elementType, complete }) => {
    this.setState(
      {
        incompleteCardFields: {
          ...this.state.incompleteCardFields,
          [elementType]: !complete,
        },
      },
      () => {
        this.props.onChange(
          "billing.hasCompleteCardInfo",
          !Object.keys(this.state.incompleteCardFields).filter(
            (cardField) => this.state.incompleteCardFields[cardField],
          ).length,
        );
      },
    );
  };

  render() {
    const {
      enrollmentSettings: { billing },
      modal,
      onChange,
      validation,
    } = this.props;
    const { incompleteCardFields } = this.state;
    return (
      <div className="trip-page-form-section">
        {!modal && <p className="medium-text fw-700 mb-16">Credit Card Info</p>}

        <div className="flex">
          <form className="cc-info-form">
            <div className="flex flex-align-center mb-12 mobile-block">
              <label className="trip-page-form-label">Card Number</label>
              <CardNumberElement
                className={`custom-input ${
                  validation.hasCompleteCardInfo &&
                  incompleteCardFields.cardNumber
                    ? "error"
                    : ""
                }`}
                onChange={this.onChangeCC}
              />
            </div>
            <div className="flex flex-align-center mb-12 mobile-block">
              <label className="trip-page-form-label">
                Cardholder Full Name
              </label>
              <input
                type="text"
                className={`custom-input ${
                  validation.hasCompleteCardInfo && !billing.cardHolderFullName
                    ? "error"
                    : ""
                }`}
                placeholder="Full Name"
                name="billing.cardHolderFullName"
                value={billing.cardHolderFullName}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>
            <div className="flex flex-align-center mb-12 mobile-block">
              <label className="trip-page-form-label">Exp Date</label>
              <CardExpiryElement
                className={`custom-input ${
                  validation.hasCompleteCardInfo &&
                  incompleteCardFields.cardExpiry
                    ? "error"
                    : ""
                }`}
                onChange={this.onChangeCC}
              />
            </div>
            <div className="flex flex-align-center mobile-block">
              <label className="trip-page-form-label">CVC Code</label>
              <CardCVCElement
                className={`custom-input ${
                  validation.hasCompleteCardInfo && incompleteCardFields.cardCvc
                    ? "error"
                    : ""
                }`}
                onChange={this.onChangeCC}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}
