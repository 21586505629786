import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ContactsActions } from "../../state/contacts/actions";
import Directory from "./Directory";

export default connect(
  function mapState(state) {
    return {
      contacts: state.contacts,
      ui: state.ui,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          getContacts: ContactsActions.getContacts,
          clearContacts: ContactsActions.clearContacts,
        },
        dispatch,
      ),
    };
  },
)(Directory);
