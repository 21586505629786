import React from "react";

import AddressInfo from "./AddressInfo";
import AllergyAndDietaryInfo from "./AllergyAndDietaryInfo";
import CurrentEducationInfo from "./CurrentEducationInfo";
import FamilyBackgroundInfo from "./FamilyBackgroundInfo";
import IsraelEducationInfo from "./IsraelEducationInfo";
import JewishEducationInfo from "./JewishEducationInfo";
import OtherInfo from "./OtherInfo";
import ParentContactInfo from "./ParentContactInfo";
import PersonalInfo from "./PersonalInfo";

export default class StudentProfile extends React.PureComponent {
  render() {
    const {
      campuses,
      getProfileFieldLabel,
      initialProfile,
      isCreateStudent,
      isShliach,
      isProfileFieldRequired,
      isSingleTabView,
      onChangeProfile,
      onChangeProfileEvt,
      profile,
      profileValidationErrors,
      showFormValidation,
      sys: {
        allergyTypes = [],
        countries = [],
        hebrewLevels = [],
        jewishAffiliationTypes = [],
        jewishBackgroundTypes = [],
        jewishEducationTypes = [],
        jewishIsraelKnowledgeLevels = [],
        majors = [],
        studentClasses = [],
        tShirtSizes = [],
      },
      updateProfileValidation,
    } = this.props;

    return (
      <form className="profile-form">
        <div className="container">
          <p
            className={`small-text accent-text mb-16 ${
              isSingleTabView ? "mt-24" : "text-center"
            }`}
          >
            Review and edit student’s profile information. This information is
            visible to, and can be edited by, the student via student portal.
            {isShliach &&
              " For more detailed profile information, see the Demographics tab."}
          </p>
          <PersonalInfo
            countries={countries}
            getProfileFieldLabel={getProfileFieldLabel}
            initialProfile={initialProfile}
            isCreate={isCreateStudent}
            isShliach={isShliach}
            isProfileFieldRequired={isProfileFieldRequired}
            onChangeProfile={onChangeProfile}
            onChangeProfileEvt={onChangeProfileEvt}
            profile={profile}
            profileValidationErrors={profileValidationErrors}
            showFormValidation={showFormValidation}
            updateProfileValidation={updateProfileValidation}
          />
          <AddressInfo
            countries={countries}
            getProfileFieldLabel={getProfileFieldLabel}
            isProfileFieldRequired={isProfileFieldRequired}
            onChangeProfile={onChangeProfile}
            onChangeProfileEvt={onChangeProfileEvt}
            profile={profile}
            showFormValidation={showFormValidation}
          />

          <CurrentEducationInfo
            campuses={campuses}
            getProfileFieldLabel={getProfileFieldLabel}
            isProfileFieldRequired={isProfileFieldRequired}
            majors={majors}
            onChangeProfile={onChangeProfile}
            onChangeProfileEvt={onChangeProfileEvt}
            profile={profile}
            showFormValidation={showFormValidation}
            studentClasses={studentClasses}
          />

          <AllergyAndDietaryInfo
            allergyTypes={allergyTypes}
            onChangeProfile={onChangeProfile}
            onChangeProfileEvt={onChangeProfileEvt}
            profile={profile}
            showFormValidation={showFormValidation}
          />

          <FamilyBackgroundInfo
            getProfileFieldLabel={getProfileFieldLabel}
            isProfileFieldRequired={isProfileFieldRequired}
            jewishBackgroundTypes={jewishBackgroundTypes}
            onChangeProfile={onChangeProfile}
            profile={profile}
            showFormValidation={showFormValidation}
          />

          <ParentContactInfo
            countries={countries}
            getProfileFieldLabel={getProfileFieldLabel}
            isProfileFieldRequired={isProfileFieldRequired}
            onChangeProfile={onChangeProfile}
            onChangeProfileEvt={onChangeProfileEvt}
            profile={profile}
            profileValidationErrors={profileValidationErrors}
            showFormValidation={showFormValidation}
            updateProfileValidation={updateProfileValidation}
          />

          <JewishEducationInfo
            getProfileFieldLabel={getProfileFieldLabel}
            hebrewLevels={hebrewLevels}
            isProfileFieldRequired={isProfileFieldRequired}
            jewishAffiliationTypes={jewishAffiliationTypes}
            jewishEducationTypes={jewishEducationTypes}
            onChangeProfile={onChangeProfile}
            onChangeProfileEvt={onChangeProfileEvt}
            profile={profile}
            showFormValidation={showFormValidation}
          />

          <IsraelEducationInfo
            getProfileFieldLabel={getProfileFieldLabel}
            isProfileFieldRequired={isProfileFieldRequired}
            jewishIsraelKnowledgeLevels={jewishIsraelKnowledgeLevels}
            onChangeProfile={onChangeProfile}
            profile={profile}
            showFormValidation={showFormValidation}
          />

          <OtherInfo
            getProfileFieldLabel={getProfileFieldLabel}
            isProfileFieldRequired={isProfileFieldRequired}
            onChangeProfile={onChangeProfile}
            profile={profile}
            showFormValidation={showFormValidation}
            tShirtSizes={tShirtSizes}
          />
        </div>
      </form>
    );
  }
}
