import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { LamplightersActions } from "../../state";
import Lamplighters from "./Lamplighters";

export default connect(
  function mapState(state) {
    return {
      lamplighters: state.lamplighters,
      sys: state.sys,
      ui: state.ui,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...LamplightersActions,
        },
        dispatch,
      ),
    };
  },
)(Lamplighters);
