import React from "react";

export default class PWAInstallPrompt extends React.PureComponent {
  state = {
    showInstallMessageIOS: false,
    showInstallMessageAndroid: false,
  };

  componentDidMount() {
    if (this.props.mobileOperatingSystem === "iOS") {
      this.checkShowInstallMessageIOS();
    } else {
      this.checkShowInstallMessageAndroid();
    }
  }

  installPromptAndroid = null;
  checkShowInstallMessageAndroid() {
    window.addEventListener("beforeinstallprompt", (e) => {
      // For older browsers
      e.preventDefault();
      this.installPromptAndroid = e;

      //TODO - do we want to do this on android?  or does chrome take care of not calling event repeatedly?
      if (this.installMessageHidden()) {
        return false;
      }

      // If in the pwa, set localStorage var to ensure they're not prompted again from the browser (on Android browser and PWA state is shared)
      if (
        (window.matchMedia &&
          window.matchMedia("(display-mode: standalone)").matches) ||
        window.navigator.standalone === true
      ) {
        localStorage.setItem("hide-coci-pwa-install-message", "hide");
        return false;
      }

      // Set the state variable to make button visible
      this.setState({
        showInstallMessageAndroid: true,
      });
    });
  }

  installAppAndroid = async () => {
    if (!this.installPromptAndroid) return false;

    // Hide the button
    this.hideInstallMessage(); //TODO - do we need to do this session storage thing for android, or will it take care of not asking too often etc?

    this.installPromptAndroid.prompt();
    let outcome = await this.installPromptAndroid.userChoice;
    if (outcome.outcome === "accepted") {
      console.log("App Installed");
    } else {
      console.log("App not installed");
    }
    // Remove the event reference
    this.installPromptAndroid = null;
  };

  checkShowInstallMessageIOS = () => {
    //show prompt only in Safari on iOS v12.2 or later
    const { iosVersion, mobileOperatingSystem, mobileUserAgent } = this.props;
    if (
      mobileOperatingSystem !== "iOS" ||
      mobileUserAgent !== "Safari" ||
      iosVersion < 12.2
    ) {
      return;
    }

    const isInStandaloneMode =
      "standalone" in window.navigator && window.navigator.standalone;

    // do not show if in PWA or if hid install message
    if (!isInStandaloneMode && !this.installMessageHidden()) {
      this.setState({ showInstallMessageIOS: true });
    }
  };

  hideInstallMessage = (dontAskAgain) => {
    this.setState({
      showInstallMessageAndroid: false,
      showInstallMessageIOS: false,
    });

    if (dontAskAgain) {
      localStorage.setItem("hide-coci-pwa-install-message", "hide");
    } else {
      sessionStorage.setItem("hide-coci-pwa-install-message", "hide");
    }
  };

  installMessageHidden = () => {
    return !!(
      sessionStorage.getItem("hide-coci-pwa-install-message") ||
      localStorage.getItem("hide-coci-pwa-install-message")
    );
  };

  render() {
    let { showInstallMessageAndroid, showInstallMessageIOS } = this.state;

    return showInstallMessageAndroid ? (
      <div className="install-banner install-banner-android card">
        <div className="flex flex-justify-space mb-8">
          <div className="flex flex-align-center">
            <img
              src="/images/coci_app_icon_192x192.png?v=1"
              alt="COCI app icon"
            />
            <p className="ml-16 medium-text fw-700">Chabad on Campus</p>
          </div>
          <i
            className="material-icons medium-text link-text-secondary"
            onClick={() => this.hideInstallMessage()}
          >
            close
          </i>
        </div>
        <div
          className="flex flex-align-center flex-justify-end"
          style={{ flexWrap: "wrap-reverse" }}
        >
          <p
            className="small-text accent-text-secondary link-text-secondary dismiss-label"
            onClick={() => this.hideInstallMessage(true)}
          >
            Don't Show Again on This Device
          </p>
          <button className="btn btn-accent" onClick={this.installAppAndroid}>
            Add To Home Screen
          </button>
        </div>
      </div>
    ) : showInstallMessageIOS ? (
      <div className="install-banner install-banner-ios card">
        <div className="flex flex-justify-space">
          <div className="message">
            To install this web app on your device: Tap{" "}
            <img src="/images/ios_share.png" alt="Share" /> below and then{" "}
            <span className="fw-700">Add to Home Screen</span>.
          </div>
          <i
            className="material-icons medium-text link-text-secondary"
            onClick={() => this.hideInstallMessage()}
          >
            close
          </i>
        </div>
        <p
          className="small-text accent-text-secondary link-text-secondary dismiss-label mr-8 mt-4"
          onClick={() => this.hideInstallMessage(true)}
        >
          Don't Show Again on This Device
        </p>
      </div>
    ) : null;
  }
}
