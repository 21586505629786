import { AuthRequest, ApiCallErrorMessageHandler } from "../../lib";
import { ErrorResponse } from "../types";

export async function getJewishInterestsOverTime(studentId: number) {
  try {
    const response = await AuthRequest.get(
      `Students/${studentId}/jewishInterestsOverTime`,
    );
    return {
      data: response.data.payload,
    };
  } catch (err) {
    return {
      error: err,
      errorMessage: ApiCallErrorMessageHandler(err),
    } as ErrorResponse;
  }
}
