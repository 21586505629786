import React from "react";
import { Navigation, PageURL } from "../../../lib";
import debounce from "lodash.debounce";

export default class TeamsDirectorySearch extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      pageRoute: {
        query: { q = "" },
      },
    } = this.props;
    this.state = {
      searchQuery: q ? decodeURIComponent(q) : "",
    };
  }

  onChange = (name, val, debounce) => {
    this.setState({ [name]: val }, () => {
      if (debounce) {
        this.onFilterDebounce();
      } else {
        this.onFilter();
      }
    });
  };

  onFilter = () => {
    const {
      pageRoute: { query, page, params },
    } = this.props;

    const { searchQuery } = this.state;
    const url = PageURL.to(page, params, {
      ...query,
      q: searchQuery ? encodeURIComponent(searchQuery) : undefined,
      p: undefined, //reset page to 1
    });
    Navigation.redirect(url);
  };

  onFilterDebounce = debounce(this.onFilter, 500);

  render() {
    const { searchQuery } = this.state;

    return (
      <div>
        <div className="search-input">
          <input
            name="searchQuery"
            onChange={(event) =>
              this.onChange(event.target.name, event.target.value, true)
            }
            placeholder="Search Contact name or position"
            type="text"
            value={decodeURIComponent(searchQuery)}
          />
          <i className="material-icons accent-text-secondary pointer">search</i>
        </div>
      </div>
    );
  }
}
