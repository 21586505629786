import Trip from "./Trip.vm";
import StudentRegistrationAction from "./students/StudentRegistrationAction";

export const TripPages = {
  trip: {
    anon: false,
    path: "/trips/:programScheduleID?",
    title: "Trips",
    type: "PAGE_TRIP",
    view: Trip,
  },
  studentRegistrationAction: {
    anon: true,
    path: "/trips/students/registrationAction",
    title: "Trip Student Registration",
    type: "PAGE_TRIP_STUDENT_REGISTRATION",
    view: StudentRegistrationAction,
  },
};
export default TripPages;

export const TripArea = {
  pages: TripPages,
};
