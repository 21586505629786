import React, { useState, useEffect, useMemo, useCallback, memo } from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { EngagementSelectors, SystemSelectors } from "../../../../state";
import { palette } from "../shared";
import Loader from "../../../../components/Loader";
import { formatNumber, isDateEqual, sum } from "../../../../lib";
import {
  getMetricDetailsAccessor,
  getInteractionDataKey,
  getMonthYearLabel,
} from "./metricHelpers";
import { InteractionSettingTypes } from "../shared";
import InteractionGoalChartHeader from "./InteractionMetricChartHeader";
import InteractionsBarChart from "./InteractionsBarChart";
import NoMetrics from "./NoMetrics";
import ColoredLegendEntry from "../../ColoredLegendEntry";

function formatData(data, selectedDates) {
  return selectedDates.map((monthYear) => {
    const monthData = {
      monthYear,
      total: 0,
    };

    data
      .filter((metrics) => isDateEqual(metrics.monthYear, monthYear))
      .forEach((metric) => {
        const {
          communalTotal,
          interactionCategoryID,
          individualTotal,
          monthYear,
        } = metric;

        const formatMonthYear = new Date(monthYear).toISOString();

        monthData[
          getInteractionDataKey(
            interactionCategoryID,
            InteractionSettingTypes.Individual,
          )
        ] = individualTotal;

        monthData[
          getInteractionDataKey(
            interactionCategoryID,
            InteractionSettingTypes.Communal,
          )
        ] = communalTotal;

        monthData[getMetricDetailsAccessor(interactionCategoryID)] = {
          ...metric,
          monthYear: formatMonthYear,
        };

        monthData.total += individualTotal + communalTotal;
      });

    return monthData;
  });
}

function InteractionMetricsChartByMonth(props) {
  const { selectedDates, monthYearsArray } = props;

  const [formattedData, setFormattedData] = useState([]);
  const [interactionSettingFilter, setInteractionSettingFilter] = useState("");

  const { data, loading, errorMessage } = useSelector(
    EngagementSelectors.interactionMetrics,
  );
  const interactionCategories =
    useSelector(SystemSelectors.interactionCategories) || []; // value is undefined on signout

  useEffect(
    function updateLocalData() {
      if (data) {
        //we are setting the data in local state per this issue where the bar labels do not appear on initial mount when reading directly from props https://github.com/recharts/recharts/issues/829
        setFormattedData(formatData(data, selectedDates));
      }
    },
    [data, selectedDates],
  );

  const getColors = useCallback(
    (value) => {
      const index = interactionCategories.findIndex(({ id }) => id === value);
      return palette[index] || {};
    },
    [interactionCategories],
  );

  const formatTickY = useCallback(
    (key, data) => {
      const monthYearDisplay = getMonthYearLabel(monthYearsArray, key);
      const total = data.find((d) => isDateEqual(d.monthYear, key))?.total;
      return `${monthYearDisplay}\nTotal: ${formatNumber(total)}`;
    },
    [monthYearsArray],
  );

  const dataTotal = useMemo(
    () => sum(formattedData.map((d) => d.total)),
    [formattedData],
  );

  const dataMax = useMemo(
    () =>
      formattedData?.reduce(
        (prev, current) => (prev?.total > current?.total ? prev : current),
        null,
      ),
    [formattedData],
  );

  const rowHeight =
    interactionCategories?.length * (interactionSettingFilter ? 20 : 30);

  const minRowHeight = 80;

  const chartHeight =
    formattedData.length *
    (rowHeight >= minRowHeight ? rowHeight : minRowHeight);

  return (
    <div className="graph-outline">
      <InteractionGoalChartHeader
        dataTotal={dataTotal}
        loading={loading}
        setInteractionSettingFilter={setInteractionSettingFilter}
        interactionSettingFilter={interactionSettingFilter}
      />
      <div className="mt-16 mb-16">
        {loading ? (
          <Loader />
        ) : errorMessage ? (
          <p className="error-text small-text text-center">{errorMessage}</p>
        ) : !formattedData.length ? (
          <NoMetrics message="No engagement metrics found" />
        ) : (
          <>
            <Box className="flex flex-align-center mobile-block">
              {interactionCategories.map(({ id, name }) => (
                <ColoredLegendEntry
                  getColors={getColors}
                  id={id}
                  name={name}
                  key={id}
                />
              ))}
            </Box>
            <InteractionsBarChart
              chartHeight={chartHeight}
              data={formattedData}
              tickYFormatter={(key) => formatTickY(key, formattedData)}
              metrics={interactionCategories}
              interactionSettingFilter={interactionSettingFilter}
              getColors={getColors}
              isGoalData={false}
              groupByMonth={true}
              tickYDataKey={"monthYear"}
              tickXFormatter={(key) => key}
              xDomain={[0, dataMax || 100]}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default memo(InteractionMetricsChartByMonth);
