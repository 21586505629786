import React, { memo, useState } from "react";
import {
  Box,
  Checkbox,
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { isDateEqual } from "../../../../lib";
import Loader from "../../../../components/Loader";
import NoEngagementGoals from "../NoEngagementGoals";
import EngagementPeriodsSelect from "../EngagementPeriodsSelect";
import MonthYearsSelect from "../MonthYearsSelect";
import InteractionGoalMetricsChart from "./InteractionGoalMetricsChart";
import StudentGoalMetricsChart from "./StudentGoalMetricsChart";
import StudentMetricsChart from "./StudentMetricsChart";
import StudentGoalMetricsChartByMonth from "./StudentGoalMetricsChartByMonth";
import StudentMetricsChartByMonth from "./StudentMetricsChartByMonth";
import InteractionGoalMetricsChartByMonth from "./InteractionGoalMetricsChartByMonth";
import { palette, getValuesForMonthsYearsSelect } from "../shared";
import InteractionMetricsChart from "./InteractionMetricsChart";
import InteractionMetricsChartByMonth from "./InteractionMetricsChartByMonth";

const monthYearsArray = getValuesForMonthsYearsSelect(60);

function EngagementGoalsMetrics(props) {
  const {
    engagementPeriods,
    engagementPeriodsLoading,
    engagementPeriodIdsFilter = [],
    selectedDates = [],
    filterMetrics,
  } = props;

  const [groupBy, setGroupBy] = useState("type");
  const [showGoalDataOnly, setShowGoalDataOnly] = useState(true);

  const getEngagementPeriodColors = (periodId) => {
    const index = engagementPeriodIdsFilter.findIndex(
      (id) => id.toString() === periodId.toString(),
    );
    return palette[index] || {};
  };

  const getMonthYearColors = (monthYear) => {
    const index = selectedDates.findIndex((value) =>
      isDateEqual(value, monthYear),
    );
    return palette[index] || {};
  };

  const handleGroupByChange = (event) => setGroupBy(event.target.value);

  const handleShowGoalDataChange = (event) =>
    setShowGoalDataOnly(event.target.checked);

  return (
    <div className="full-width">
      {engagementPeriodsLoading ? (
        <Loader />
      ) : engagementPeriods?.length ? (
        <div>
          <Typography variant="h4">Metrics</Typography>
          <Box className="flex flex-row flex-align-center">
            <FormControl className="flex flex-row flex-align-center">
              <Typography variant="subtitle1" className="mr-16 secondary-text">
                Group By
              </Typography>
              <RadioGroup row onChange={handleGroupByChange} value={groupBy}>
                <FormControlLabel
                  value="month"
                  control={<Radio size="small" />}
                  label="Month"
                />
                <FormControlLabel
                  value="type"
                  control={<Radio size="small" />}
                  label="Student/engagement type"
                />
              </RadioGroup>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={showGoalDataOnly}
                  onChange={handleShowGoalDataChange}
                  color="secondary"
                />
              }
              label="Show only goal data"
            />
          </Box>

          {showGoalDataOnly ? (
            <EngagementPeriodsSelect
              engagementPeriods={engagementPeriods}
              getColors={() => ({ dark: "#1E1E1C", light: "#F4F4F5" })}
              onChange={(vals) =>
                filterMetrics(
                  vals.map((v) => v.value),
                  showGoalDataOnly,
                )
              }
              value={engagementPeriodIdsFilter.map((id) => id.toString())}
            />
          ) : (
            <MonthYearsSelect
              monthYearsArray={monthYearsArray}
              getColors={() => ({ dark: "#1E1E1C", light: "#F4F4F5" })}
              onChange={(vals) =>
                filterMetrics(
                  vals.map((v) => v.value),
                  showGoalDataOnly,
                )
              }
              values={selectedDates}
            />
          )}

          {groupBy === "month" ? (
            showGoalDataOnly ? (
              <>
                <StudentGoalMetricsChartByMonth
                  engagementPeriods={engagementPeriods}
                  engagementPeriodIds={engagementPeriodIdsFilter}
                />
                <InteractionGoalMetricsChartByMonth
                  engagementPeriodIds={engagementPeriodIdsFilter}
                  engagementPeriods={engagementPeriods}
                />
              </>
            ) : (
              <>
                <StudentMetricsChartByMonth
                  monthYearsArray={monthYearsArray}
                  selectedDates={selectedDates}
                  getColors={getMonthYearColors}
                />
                <InteractionMetricsChartByMonth
                  monthYearsArray={monthYearsArray}
                  selectedDates={selectedDates}
                />
              </>
            )
          ) : showGoalDataOnly ? (
            <>
              <StudentGoalMetricsChart
                selectedEngagementPeriodIds={engagementPeriodIdsFilter}
                engagementPeriods={engagementPeriods}
                getColors={getEngagementPeriodColors}
              />
              <InteractionGoalMetricsChart
                selectedEngagementPeriodIds={engagementPeriodIdsFilter}
                engagementPeriods={engagementPeriods}
                getColors={getEngagementPeriodColors}
              />
            </>
          ) : (
            <>
              <StudentMetricsChart
                monthYearsArray={monthYearsArray}
                selectedDates={selectedDates}
                getColors={getMonthYearColors}
              />
              <InteractionMetricsChart
                monthYearsArray={monthYearsArray}
                selectedDates={selectedDates}
                getColors={getMonthYearColors}
              />
            </>
          )}
        </div>
      ) : (
        <NoEngagementGoals />
      )}
    </div>
  );
}

export default memo(EngagementGoalsMetrics);
