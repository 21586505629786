import React from "react";
import { PageLink, pluralizeText, formatNumber } from "../../../../../lib";

export default class CourseOverview extends React.PureComponent {
  componentDidMount() {
    //scroll to top of page on mount for when navigating from low scroll position within courses list
    if (window.scrollY > 0) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      course: { data: course },
      pageRoute: { history, page, params },
    } = this.props;

    const {
      categoryDisplay,
      classes,
      description,
      imageURL,
      levelDisplay,
      name,
      notes,
      resourceURL,
    } = course;

    const offerCourseLink = (
      <PageLink
        className="btn btn-accent text-center"
        to={page}
        params={{
          ...params,
          courseView: "offer",
        }}
        style={{ width: "216px", display: "inline-block" }}
      >
        Offer this course
      </PageLink>
    );

    return (
      <div className="edu-card card course-details-card">
        <p
          className="desktop-hidden tablet-hidden fw-700 flex flex-align-center mb-8 ml-16 link-text-secondary"
          style={{ fontSize: "15px", paddingTop: 16 }}
          onClick={() => history.goBack()}
        >
          <i className="material-icons mr-8 medium-text">arrow_back</i>Back to
          courses
        </p>
        <div className="course-details-header">
          <img src={imageURL} alt="" />
        </div>
        <div className="container">
          <p
            className="mobile-hidden fw-700 flex flex-align-center mb-24 link-text-secondary"
            style={{ fontSize: "15px" }}
            onClick={() => history.goBack()}
          >
            <i className="material-icons mr-8 medium-text">arrow_back</i>Back to
            courses
          </p>
          <div className="flex flex-justify-space mobile-block">
            <div>
              <p className="xxl-text fw-700 mb-8 inline-block" dir="auto">
                {name}
              </p>
              <div className="mobile-flex mobile-flex-justify-space">
                <p className="medium-text fw-700 mb-8">{categoryDisplay}</p>
                {levelDisplay && (
                  <p className="level-tag inline-block mb-8">{levelDisplay}</p>
                )}
              </div>
              {classes && (
                <p className="accent-text-dark medium-text fw-700">
                  {formatNumber(classes.length) || "No"}{" "}
                  {pluralizeText("class", classes.length, "classes")}
                </p>
              )}
            </div>
            <div className="course-details-actions">
              {offerCourseLink}
              {resourceURL && (
                <a
                  className="btn btn-accent mt-16"
                  href={resourceURL}
                  style={{ width: "136px" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Resources
                  <i className="material-icons ml-8 large-text">open_in_new</i>
                </a>
              )}
            </div>
          </div>
          <div className="course-details-mobile-divider"></div>
          <div className="mt-32">
            <p className="xl-text fw-700 mb-16">Public Description</p>
            <p className="large-text line-height-double pre-wrap" dir="auto">
              {description}
            </p>
          </div>
          <div className="mt-32">
            <p className="xl-text fw-700 mb-16">Note to Shluchim</p>
            <p className="large-text line-height-double pre-wrap" dir="auto">
              {notes}
            </p>
          </div>
          <div className="course-details-classes mt-40">
            {classes &&
              classes
                .sort((c1, c2) => c1.sortOrder - c2.sortOrder)
                .map((clss, index) => (
                  <div key={index} className="mb-16">
                    <p
                      className="medium-text fw-700 mb-8 class-title relative"
                      dir="auto"
                    >
                      {clss.title}
                    </p>
                    <p
                      className="accent-text-dark medium-text line-height-double class-description relative pre-wrap"
                      dir="auto"
                    >
                      {clss.description}
                    </p>
                  </div>
                ))}
          </div>
          <div className="text-center mt-24 mb-16">{offerCourseLink}</div>
        </div>
      </div>
    );
  }
}
