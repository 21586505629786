import { AuthRequest } from "../../lib";

export const CampusesActions = {
  GET_CAMPUSES: "GET_CAMPUSES",

  getCampuses() {
    return async (dispatch, getState) => {
      const { data } = await AuthRequest.get(`Campuses`).catch((err) => {
        return { data: [] };
      });
      dispatch({ type: CampusesActions.GET_CAMPUSES, payload: data });
    };
  },
};
