import React from "react";

export default function NoAccess({ systemName }) {
  return (
    <div className="flex flex-align-center flex-justify-center full-page-state">
      <img alt="" height="240" src="/images/not_eligible.svg" />
      <p className="fw-700 xxl-text mt-32 mb-40 text-center">
        You do not have access to {systemName}
      </p>
      <p className="accent-text large-text">
        Contact your administrator to request access
      </p>
    </div>
  );
}
