export const EngagementSelectors = {
  engagementPeriods(state) {
    return state.engagement.engagementPeriods;
  },
  engagementGoals(state) {
    return state.engagement.engagementGoals;
  },
  submittedEngagementGoals(state) {
    return state.engagement.submitEngagementGoals;
  },
  studentGoalMetrics(state) {
    return state.engagement.engagementStudentGoalMetrics;
  },
  studentMetrics(state) {
    return state.engagement.engagementStudentMetrics;
  },
  interactionGoalMetrics(state) {
    return state.engagement.engagementInteractionGoalMetrics;
  },
  interactionMetrics(state) {
    return state.engagement.engagementInteractionMetrics;
  },
  jewishStudentCount(state) {
    return state.engagement.jewishStudentCount;
  },
  studentInteractionsSummary(state) {
    return state.engagement.studentInteractionsSummary;
  },
};
