import React from "react";
import { getBannersToShow, Navigation, PageLink, pluralizeText } from "../lib";

export default class Banners extends React.PureComponent {
  state = {
    showBannerCards: false,
  };

  toggleBannerCards = () => {
    this.setState({ showBannerCards: !this.state.showBannerCards });
  };

  render() {
    const { banners, isPhone } = this.props;
    const { showBannerCards } = this.state;

    const bannersToShow = getBannersToShow(
      banners,
      Navigation.location.pathname,
    );

    return bannersToShow ? (
      bannersToShow.length > (isPhone ? 0 : 1) ? (
        <div className="banner-wrapper">
          {showBannerCards && (
            <div className="banner-cards">
              <div className="container">
                {bannersToShow.map((banner, index) => {
                  const { bannerText, buttonText, linkURL } = banner;
                  return (
                    <div key={index} className="banner-card">
                      <p className="banner-card-txt">{bannerText}</p>
                      <PageLink className="btn btn-accent" to={linkURL}>
                        {buttonText}
                      </PageLink>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="banner">
            <p>
              You have {bannersToShow.length}{" "}
              {pluralizeText("Message", bannersToShow.length)}
            </p>
            <button className="btn" onClick={() => this.toggleBannerCards()}>
              {!showBannerCards ? "SHOW" : "HIDE"}
            </button>
          </div>
        </div>
      ) : (
        bannersToShow.map((banner, index) => {
          const {
            bannerText,
            buttonText,
            linkURL,
            textColor,
            backgroundColor,
          } = banner;
          return (
            <div key={index} className="banner-wrapper">
              <div className="banner" style={{ backgroundColor }}>
                <p style={{ color: textColor }} className="line-height-double">
                  {bannerText}
                </p>
                <PageLink className="btn" to={linkURL}>
                  {buttonText}
                </PageLink>
              </div>
            </div>
          );
        })
      )
    ) : null;
  }
}
