import React from "react";
import moment from "moment";
import { formatFullName } from "../../../lib";

export default class Profile extends React.PureComponent {
  toggleIsEnrolled = (event) => {
    const {
      target: { checked, name },
    } = event;

    const { initialEnrollment, onChange } = this.props;

    const enrolleeType = name.split(".")[0];
    const initialEnrolleeInfo = !checked
      ? {
          [enrolleeType]: {
            ...initialEnrollment[enrolleeType],
            isEnrolled: false,
          },
        }
      : null;

    onChange(name, checked, initialEnrolleeInfo);
  };

  render() {
    const {
      enrollment: {
        id: enrollmentId,
        enrollmentDate,
        lastRenewalDate,
        nextRenewalDate,
        shliach: {
          dob: shliachDob,
          isEnrolled: isShliachEnrolled,
          legalFirstName: shliachLegalFirstName,
          legalMiddleName: shliachLegalMiddleName,
          legalLastName: shliachLegalLastName,
          shliachID: shliachId,
        },
        shlucha: {
          dob: shluchaDob,
          isEnrolled: isShluchaEnrolled,
          legalFirstName: shluchaLegalFirstName,
          legalMiddleName: shluchaLegalMiddleName,
          legalLastName: shluchaLegalLastName,
          shliachID: shluchaId,
        },
        status,
      },
      enrollmentStatuses,
      onChangeEvt,
      submitAttempted,
    } = this.props;

    return !enrollmentId ? (
      <div className="life-insurance-form-section">
        <h2 className="section-title">Profile</h2>
        {submitAttempted && !isShliachEnrolled && !isShluchaEnrolled && (
          <span className="error-text">
            * At least one enrollee is required
          </span>
        )}
        <div className="life-insurance-profile-inputs life-insurance-two-column-grid">
          {!!shliachId && (
            <div>
              <div className="mb-24">
                <div className="flex flex-align-center flex-justify-space">
                  <p className="medium-text fw-700">Shliach Information</p>
                  <div className="flex custom-checkbox-container ml-24">
                    <input
                      checked={isShliachEnrolled}
                      className="custom-checkbox"
                      id="li-shliach-enrolled"
                      name="shliach.isEnrolled"
                      onChange={this.toggleIsEnrolled}
                      type="checkbox"
                    />
                    <label htmlFor="li-shliach-enrolled">Enroll</label>
                  </div>
                </div>
                {!isShliachEnrolled && (
                  <p
                    className="small-text accent-text-secondary-color mt-4"
                    style={{ position: "absolute" }}
                  >
                    Shliach cannot be added later
                  </p>
                )}
              </div>
              <div className="flex flex-align-center mb-12 mobile-block">
                <label className="small-text accent-text">
                  Legal First Name
                </label>
                <input
                  className={`custom-input ${
                    submitAttempted &&
                    isShliachEnrolled &&
                    !shliachLegalFirstName
                      ? "error"
                      : ""
                  }`}
                  disabled={!isShliachEnrolled}
                  name="shliach.legalFirstName"
                  onChange={onChangeEvt}
                  value={shliachLegalFirstName}
                />
              </div>
              <div className="flex flex-align-center mb-12 mobile-block">
                <label className="small-text accent-text">
                  Legal Middle Name
                </label>
                <input
                  className="custom-input"
                  disabled={!isShliachEnrolled}
                  name="shliach.legalMiddleName"
                  onChange={onChangeEvt}
                  value={shliachLegalMiddleName}
                />
              </div>
              <div className="flex flex-align-center mb-12 mobile-block">
                <label className="small-text accent-text">
                  Legal Last Name
                </label>
                <input
                  className={`custom-input ${
                    submitAttempted &&
                    isShliachEnrolled &&
                    !shliachLegalLastName
                      ? "error"
                      : ""
                  }`}
                  disabled={!isShliachEnrolled}
                  name="shliach.legalLastName"
                  onChange={onChangeEvt}
                  value={shliachLegalLastName}
                />
              </div>
              <div className="flex flex-align-center mb-12 mobile-block">
                <label className="small-text accent-text">Date of Birth</label>
                <input
                  className={`custom-input ${
                    submitAttempted && isShliachEnrolled && !shliachDob
                      ? "error"
                      : ""
                  }`}
                  disabled={!isShliachEnrolled}
                  name="shliach.dob"
                  onChange={onChangeEvt}
                  type="date"
                  value={shliachDob}
                />
              </div>
            </div>
          )}
          {!!shluchaId && (
            <div>
              <div className="mb-24">
                <div className="flex flex-align-center flex-justify-space">
                  <p className="medium-text fw-700">Shlucha Information</p>
                  <div className="flex custom-checkbox-container ml-24">
                    <input
                      checked={isShluchaEnrolled}
                      className="custom-checkbox"
                      id="li-shlucha-enrolled"
                      name="shlucha.isEnrolled"
                      onChange={this.toggleIsEnrolled}
                      type="checkbox"
                    />
                    <label htmlFor="li-shlucha-enrolled">Enroll</label>
                  </div>
                </div>
                {!isShluchaEnrolled && (
                  <p
                    className="small-text accent-text-secondary-color mt-4"
                    style={{ position: "absolute" }}
                  >
                    Shlucha cannot be added later
                  </p>
                )}
              </div>
              <div className="flex flex-align-center mb-12 mobile-block">
                <label className="small-text accent-text">
                  Legal First Name
                </label>
                <input
                  className={`custom-input ${
                    submitAttempted &&
                    isShluchaEnrolled &&
                    !shluchaLegalFirstName
                      ? "error"
                      : ""
                  }`}
                  disabled={!isShluchaEnrolled}
                  name="shlucha.legalFirstName"
                  onChange={onChangeEvt}
                  value={shluchaLegalFirstName}
                />
              </div>
              <div className="flex flex-align-center mb-12 mobile-block">
                <label className="small-text accent-text">
                  Legal Middle Name
                </label>
                <input
                  className="custom-input"
                  disabled={!isShluchaEnrolled}
                  name="shlucha.legalMiddleName"
                  onChange={onChangeEvt}
                  value={shluchaLegalMiddleName}
                />
              </div>
              <div className="flex flex-align-center mb-12 mobile-block">
                <label className="small-text accent-text">
                  Legal Last Name
                </label>
                <input
                  className={`custom-input ${
                    submitAttempted &&
                    isShluchaEnrolled &&
                    !shluchaLegalLastName
                      ? "error"
                      : ""
                  }`}
                  disabled={!isShluchaEnrolled}
                  name="shlucha.legalLastName"
                  onChange={onChangeEvt}
                  value={shluchaLegalLastName}
                />
              </div>
              <div className="flex flex-align-center mb-12 mobile-block">
                <label className="small-text accent-text">Date of Birth</label>
                <input
                  className={`custom-input ${
                    submitAttempted && isShluchaEnrolled && !shluchaDob
                      ? "error"
                      : ""
                  }`}
                  disabled={!isShluchaEnrolled}
                  name="shlucha.dob"
                  onChange={onChangeEvt}
                  type="date"
                  value={shluchaDob}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    ) : (
      <div
        className={`life-insurance-form-section ${
          status === enrollmentStatuses.Terminated ? "terminated" : ""
        }`}
      >
        <div className="life-insurance-two-column-grid life-insurance-profile-section">
          {isShliachEnrolled && (
            <div>
              <p className="accent-text-dark uppercase-text fw-500 mb-16">
                Shliach Information
              </p>
              <div className="flex mb-8">
                <p className="fw-700">Legal Name</p>
                <p>
                  {formatFullName(
                    shliachLegalFirstName,
                    shliachLegalLastName,
                    shliachLegalMiddleName,
                  )}
                </p>
              </div>
              <div className="flex mb-8">
                <p className="fw-700">Date of Birth</p>
                <p>
                  {shliachDob ? moment(shliachDob).format("MMMM D, YYYY") : ""}
                </p>
              </div>
            </div>
          )}
          {isShluchaEnrolled && (
            <div>
              <p className="accent-text-dark uppercase-text fw-500 mb-16">
                Shlucha Information
              </p>
              <div className="flex mb-8">
                <p className="fw-700">Legal Name</p>
                <p>
                  {formatFullName(
                    shluchaLegalFirstName,
                    shluchaLegalLastName,
                    shluchaLegalMiddleName,
                  )}
                </p>
              </div>
              <div className="flex mb-8">
                <p className="fw-700">Date of Birth</p>
                <p>
                  {shluchaDob ? moment(shluchaDob).format("MMMM D, YYYY") : ""}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="life-insurance-profile-section">
          <div className="flex mb-8">
            <p className="fw-700">Date Joined</p>
            <p>
              {enrollmentDate
                ? moment(enrollmentDate).format("MMMM D, YYYY")
                : "—"}
            </p>
          </div>
          {status !== enrollmentStatuses.Terminated && (
            <React.Fragment>
              <div className="flex mb-8">
                <p className="fw-700">Last Renewal</p>
                <p>
                  {lastRenewalDate
                    ? moment(lastRenewalDate).format("MMMM D, YYYY")
                    : "—"}
                </p>
              </div>
              <div className="flex mb-8">
                <p
                  className={`fw-700 ${
                    status === enrollmentStatuses.FailedRenewal
                      ? "error-text"
                      : ""
                  }`}
                >
                  Next Renewal
                </p>
                <p
                  className={
                    status === enrollmentStatuses.FailedRenewal
                      ? "error-text"
                      : ""
                  }
                >
                  {nextRenewalDate
                    ? moment(nextRenewalDate).format("MMMM D, YYYY")
                    : "—"}
                </p>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
