import Programs from "./Programs.vm";
import ProgramDetails from "./ProgramDetails.vm";

export const ProgramPages = {
  programs: {
    path: "/programs",
    title: "Programs",
    type: "PAGE_PROGRAMS",
    view: Programs,
  },
  programDetails: {
    path: "/programs/:programId",
    title: "Program Details",
    type: "PAGE_PROGRAM_DETAILS",
    view: ProgramDetails,
  },
};
export default ProgramPages;

export const ProgramsArea = {
  pages: ProgramPages,
};
