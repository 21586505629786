import React, { memo } from "react";
import {
  formatNumber,
  PageLink,
  PageURL,
  pluralizeText,
} from "../../../../lib";
import Pages from "../../../../pages";
import { EduProgramTypes } from "../../../edu/eduUtils";
import moment from "moment";
import { useSelector } from "react-redux";
import { AuthSelectors } from "../../../../state";

function ActivityListRow(props) {
  const {
    activity,
    isShliach,
    isLamplightersEligibleInteraction,
    isStudentActivityView,
    onCloneInteraction,
    onEditInteraction,
    studentName,
  } = props;

  const {
    activityTypeDisplay,
    categoryRating,
    classification,
    dateTime,
    interactionID,
    isTypeAutoGenerateOnly,
    numberOfStudents,
    programName,
    programScheduleName,
    programType,
    studentHachlata,
    unknownStudents,
    programRegistrationID,
    programScheduleID,
    studentID,
  } = activity;
  //because this table pulls student activity list both by student and globally, the activity object formats are different, so we configure the following specific properties:
  const interactionName = isStudentActivityView
    ? activity.interactionName
    : activity.name;
  const interactionType = isStudentActivityView
    ? activity.interactionType
    : activity.type;
  const isProgramActivity = isStudentActivityView && !interactionID;

  let programActivityUrl;
  if (isProgramActivity) {
    if (programActivityUrl === "Trip") {
      programActivityUrl = PageURL.to(
        Pages.trips.trip,
        { programScheduleID },
        {
          studentId: programRegistrationID,
          tab: "students",
        },
      );
    } else if (programType === "Lamplighters") {
      programActivityUrl = PageURL.to(
        Pages.lamplighters.lamplighters,
        {
          programScheduleId: programScheduleID,
        },
        {
          se: encodeURIComponent(studentName),
          tab: "students",
        },
      );
    } else if (EduProgramTypes[programType]) {
      programActivityUrl = PageURL.to(
        Pages.edu.edu,
        {
          programScheduleId: programScheduleID,
          pageView: "students",
        },
        {
          studentId: studentID,
          studentTab: programType,
        },
      );
    }
  }
  const programActivityDescription = isProgramActivity
    ? `${activityTypeDisplay} ${programScheduleName || programName}`
    : "";

  const { isEnrolledInEngagement } = useSelector(
    AuthSelectors.engagementAccess,
  );

  return (
    <div className="students-activity-table-row">
      <div className="flex flex-align-center full-width">
        <div>
          {programType && programType !== "Rsvp" ? (
            <img
              alt=""
              src={`/images/${programType.toLowerCase()}-logo.jpg`}
              style={{ width: "24px", height: "40px", objectFit: "contain" }}
            />
          ) : (
            <i
              className="material-icons"
              style={{ opacity: "24%", fontSize: "24px", width: "24px" }}
            >
              groups
            </i>
          )}
        </div>
        <div className="ml-16">
          {isProgramActivity ? (
            // non-shluchim cannot follow program links - will get a 404
            programActivityUrl && isShliach ? (
              <PageLink
                className="medium-text link-text-secondary fw-700"
                to={programActivityUrl}
              >
                {programActivityDescription}
              </PageLink>
            ) : (
              <p className="medium-text fw-700">{programActivityDescription}</p>
            )
          ) : (
            <p
              className="medium-text link-text-secondary fw-700"
              onClick={onEditInteraction}
            >
              {[interactionName, interactionType]
                .filter((n) => !!n)
                .join(" - ")}
            </p>
          )}
          {isStudentActivityView ? (
            <React.Fragment>
              {studentHachlata && (
                <p className="small-text flex flex-align-center italic-text mt-4">
                  <span className="fw-700 mr-4">Hachlata:</span>
                  {studentHachlata}
                </p>
              )}
              {/* non-shluchim cannot see lamplighters-related information */}
              {isLamplightersEligibleInteraction &&
                isLamplightersEligibleInteraction(activity) &&
                isShliach && (
                  <p className="small-text flex flex-align-center mt-4">
                    <i
                      className="material-icons small-text mr-4"
                      style={{ color: "#FEC609" }}
                    >
                      check_circle
                    </i>
                    <span className="accent-text italic-text">
                      Lamplighters Eligible
                    </span>
                  </p>
                )}
            </React.Fragment>
          ) : (
            <div className="flex mt-8">
              <p className="small-text">
                {formatNumber(numberOfStudents)}{" "}
                {pluralizeText("Student", numberOfStudents)}
              </p>
              {isEnrolledInEngagement && unknownStudents > 0 && (
                <p className="small-text accent-text ml-4">
                  {"+"}
                  {formatNumber(unknownStudents)}
                  {" Unspecified"}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-align-center flex-justify-end full-width mobile-block">
        <div className="text-right mobile-text-left mobile-mt-8">
          <p>{dateTime ? moment(dateTime).format("MMM D, YYYY") : ""}</p>
          {!isProgramActivity && (
            <p className="flex flex-align-center italic-text mt-8">
              {classification} Interaction
              <span className="interaction-category-rating ml-8">
                {Array(8)
                  .fill()
                  .map((_, i) => (
                    <img
                      alt=""
                      className="mr-4"
                      key={i}
                      src={`/images/flame-${
                        categoryRating > i ? "solid" : "fade"
                      }.png`}
                    />
                  ))}
              </span>
            </p>
          )}
        </div>
        {!isProgramActivity && (
          <div className="flex flex-justify-end">
            <div className="tooltip-container">
              <i
                className="material-icons large-text accent-text link-text-secondary ml-24"
                onClick={onEditInteraction}
              >
                edit
              </i>
              <span className="tooltip tooltip-small">Edit</span>
            </div>
            <div
              style={isTypeAutoGenerateOnly ? { visibility: "hidden" } : {}}
              className="tooltip-container"
            >
              <i
                className="material-icons large-text accent-text link-text-secondary ml-8"
                onClick={onCloneInteraction}
              >
                content_copy
              </i>
              <span className="tooltip tooltip-small">Clone</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(ActivityListRow);
