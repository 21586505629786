import React from "react";
import { Prompt } from "react-router-dom";
import ConfirmationModal from "../../../components/ConfirmationModal";
import FadeOutMessage from "../../../components/FadeOutErrorMessage";
import Loader from "../../../components/Loader";
import StudentDemographics from "./demographics/StudentDemographics";
import StudentProfile from "./profile/StudentProfile";
import { AuthTypes, Navigation } from "../../../lib";
import {
  getFormattedValuesForForm,
  handlePreventDefault,
  replaceValuesInObject,
  TwoWayMap,
} from "../../../lib";
import _cloneDeep from "lodash.clonedeep";
import _get from "lodash.get";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";

export default class StudentProfileForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.isShliach = props.loggedInAs === AuthTypes.shliach;
    this.tabs = [
      {
        component: StudentProfile,
        id: "profile",
        title: "Student Profile",
        condition: true,
      },
      {
        component: StudentDemographics,
        id: "demographics",
        title: "Demographics",
        condition: this.isShliach,
      },
    ].filter((c) => c.condition);

    //hard-coded profile settings for shliach's student profile form
    const profileSettings = [
      { field: "FirstName", isRequired: true },
      { field: "LastName", isRequired: true },
      { field: "Campus", isRequired: true },
      { field: "IsArchived", isRequired: true },
    ];

    this.state = {
      initialProfile: null,
      loading: false,
      navigating: false,
      profile: null,
      profileSettings,
      profileValidationErrors: [],
      showFormValidation: false,
      showSubmitSuccessMessage: false,
      showUpdateEmailConfirmationModal: false,
      showDeleteConfirmationModal: false,
      submitErrorMessage: "",
      submitFormAttempted: false,
      deleteErrorMessage: "",
      tab: this.tabs[0],
      updateEmailConfirmed: false,
    };
  }

  componentDidMount() {
    this.getStudentProfile();
    // interns do not have access to the screens where editDemographicsMode is set to true
    if (this.props.editDemographicsMode) {
      this.setState({
        tab: this.tabs[1],
      });
    }
  }

  getStudentProfile = async () => {
    this.setState({ loading: true }); //managing loading in local state so as not to mount the profile form until profile settings have been loaded

    const { studentId, studentProfile } = this.props;

    let campuses = this.props.campuses.data;
    //first load campuses if not yet loaded
    if (!campuses || !campuses.length) {
      await this.props.actions.getStudentCampuses();
      campuses = this.props.campuses.data;
    }

    let profile = {};
    if (studentId) {
      const retrievedProfile = await this.props.actions.getStudent(studentId);

      const {
        profile: { success },
      } = this.props;

      if (!success || !retrievedProfile) {
        this.setState({ loading: false });
        return;
      }

      profile = getFormattedValuesForForm(retrievedProfile, ["person.dob"]);
    } else if (studentProfile) {
      //studentProfile pass in with props from student details page
      profile = getFormattedValuesForForm(studentProfile, ["person.dob"]);
    } else {
      //initialize studentProfile for create student
      profile = {
        address: {
          address1: "",
          address2: "",
          city: "",
          country: "",
          state: "",
          zip: "",
        },
        allergies: "",
        dietaryNotes: "",
        campus: campuses && campuses[0],
        class: "",
        demographics: {
          chabadHouseTags: [],
          childhoodKosherStatus: "",
          childhoodShabbosHolidayStatus: "",
          childhoodSynagogueAttendanceStatus: "",
          childhoodTefillinStatus: "",
          childhoodTorahStudyStatus: "",
          cityOfOrigin: "",
          cityOfOriginJewishCommunityType: "",
          cityOfOriginRegionID: "",
          familyJewishAffiliation: "",
          halachicJewishStatus: "",
          interests: [],
          jewishInterestLevel: "",
          jewishRelationshipStatus: "",
          learningEventsParticipation: "",
          notes: "",
          orgInvolvementTypes: [],
          personality: [],
          personalShliachShluchaTimeParticipation: "",
          programParticipationTypes: [],
          referralSourceID: "",
          shabbatDinnerParticipation: "",
          socialEventsParticipation: "",
        },
        graduationYear: "",
        studentIDNumber: "",
        majorID: "",
        doubleMajorID: "",
        fatherBackground: "",
        motherBackground: "",
        hasTakenIsraelCourses: "",
        jewishEducations: [],
        hasNoJewishEducation: "",
        jewishIsraelKnowledgeLevel: "",
        doParentsShareResidence: null,
        father: {
          name: "",
          email: "",
          phoneNumber: "",
          phoneCountryID: "",
        },
        mother: {
          name: "",
          email: "",
          phoneNumber: "",
          phoneCountryID: "",
        },
        cell: "",
        cellCountryID: "",
        email: "",
        isArchived: false,
        isJewish: null,
        person: {
          dob: "",
          firstName: "",
          gender: "Unknown",
          hebrewName: "",
          lastName: "",
          profileImageURL: "",
        },
        tShirtSize: "",
        canDelete: true,
      };
    }
    if (profile.id) {
      await this.props.actions.getChabadHouseTags(profile.id);
    }
    if (profile.demographics?.chabadHouseTags) {
      profile.demographics.chabadHouseTags.forEach((g) => {
        g.label = g.tagName;
        g.value = g.tagName;
      });
    }

    const studentGroupOptions = [
      ...(this.props.profile?.chabadHouseTags?.data || []),
    ].map((g) => {
      return { ...g, label: g.tagName, value: g.tagName };
    });

    const lamplightersRequiredFields = profile.cannotRemoveDobAndCell
      ? [
          { field: "Dob", isRequired: profile.person.dob },
          { field: "Cell", isRequired: profile.cell },
        ]
      : [];
    const updatedProfileSettings = this.state.profileSettings.concat(
      lamplightersRequiredFields,
    );

    this.setState(
      {
        initialProfile: profile,
        profile: _cloneDeep(profile),
        loading: false,
        studentGroupOptions,
        profileSettings: updatedProfileSettings,
      },
      // async () => {
      //   //retrieve profile settings based on student campus or shliach's campus
      //   if (profile.campus) {
      //     await this.getProfileSettings(profile.campus.id);
      //   }
      //   this.setState({ loading: false });
      // }
    );
  };

  // note: we are removing the 'dynamic profile settings based on admin's (in-db) requirements' for now, we can adjust and reinsert if shliach required fields become dynamic in the future
  // getProfileSettings = async (campusId) => {
  //   await this.props.actions.getStudentProfileSettings(null, null, campusId);
  // };

  isIncompleteProfile = () => {
    const { profile, profileValidationErrors } = this.state;

    if (!profile) return;

    const { profileSettings } = this.state;

    //custom required fields validation
    if (
      profileSettings &&
      profileSettings
        .filter((ps) => ps.isRequired)
        .some((ps) => {
          var profileFieldAccessor = this.profileFieldsMap.revGet(ps.field);
          var profileFieldValue = _get(profile, profileFieldAccessor);
          return (
            (!profileFieldValue &&
              profileFieldValue !== true &&
              profileFieldValue !== false) ||
            (profileFieldAccessor === "person.gender" &&
              profileFieldValue === "Unknown")
          );
        })
    ) {
      return true;
    }

    //other validation
    const {
      jewishEducations,
      hasNoJewishEducation,
      person: { firstName, lastName } = {},
      allergies,
      hasAllergies,
      dietaryNotes,
    } = profile;

    let allergiesComplete =
      !hasAllergies || (allergies && allergies.length > 0);
    if (allergies.includes("Other") && !dietaryNotes) {
      allergiesComplete = false;
    }
    if (!allergiesComplete) {
      return true;
    }

    return (
      //names must be at least 2 characters
      (firstName && firstName.length < 2) ||
      (lastName && lastName.length < 2) ||
      //TODO COC-3699 - require Jewish education fields
      /* (!hasNoJewishEducation && !jewishEducations.length) ||
       */
      //Jewish education - if 'none' no other fields can be selected
      (hasNoJewishEducation && jewishEducations.length) ||
      //jewish education fields must be complete if any are provided
      !!(
        jewishEducations &&
        jewishEducations.length &&
        jewishEducations.find((j) => !j.affiliation || !j.name)
      ) ||
      profileValidationErrors.length
    );
  };

  profileFieldsMap = new TwoWayMap({
    "person.profileImageURL": "ProfileImageURL",
    "person.firstName": "FirstName",
    "person.lastName": "LastName",
    email: "Email",
    cell: "Cell",
    "person.hebrewName": "HebrewName",
    "person.dob": "Dob",
    "person.gender": "Gender",
    isArchived: "IsArchived",
    isJewish: "IsJewish",
    "address.address1": "Address1",
    "address.address2": "Address2",
    "address.city": "City",
    "address.state": "State",
    "address.zip": "Zip",
    "address.country": "Country",
    campus: "Campus",
    class: "Class",
    graduationYear: "GraduationYear",
    studentIDNumber: "StudentIDNumber",
    majorID: "Major",
    doubleMajorID: "DoubleMajor",
    fatherBackground: "FatherBackground",
    motherBackground: "MotherBackground",
    "father.name": "FatherName",
    "mother.name": "MotherName",
    "father.email": "FatherEmail",
    "mother.email": "MotherEmail",
    "father.phoneNumber": "FatherPhone",
    "mother.phoneNumber": "MotherPhone",
    doParentsShareResidence: "DoParentsShareResidence",
    hebrewLevel: "HebrewLevel",
    hadBarBatMitzvah: "HadBarBatMitzvah",
    hasTakenIsraelCourses: "HasTakenIsraelCourses",
    jewishIsraelKnowledgeLevel: "JewishIsraelKnowledgeLevel",
    tShirtSize: "TShirtSize",
  });

  isProfileFieldRequired = (fieldAccessor) => {
    const { profileSettings } = this.state;

    return !!(
      profileSettings &&
      profileSettings.find(
        (ps) =>
          ps.field === this.profileFieldsMap.get(fieldAccessor) &&
          ps.isRequired,
      )
    );
  };

  getProfileFieldLabel = (label, fieldName) => (
    <React.Fragment>
      <span>{label}</span>
      {this.isProfileFieldRequired(fieldName) && (
        <span className="ml-4 required-text">*</span>
      )}
    </React.Fragment>
  );

  onChangeProfileEvt = (event) => {
    this.onChangeProfile(event.target.name, event.target.value);
  };

  onChangeProfile = (name, value, other) => {
    this.setState(
      // because subsequent setStates are called to update phoneCountryID from the PhoneInput component, use a state updater func to ensure that updates are executed in the order they are called and previous state updates are not overridden(https://duncanleung.com/avoiding-react-setstate-pitfalls/#solution-use-the-updater-code-classlanguage-textfunctioncode-form-to-queue-state-updates)
      (prevState) => {
        let profile = _cloneDeep(prevState.profile);
        _set(profile, name, value);
        if (other) {
          Object.keys(other).forEach((update) =>
            _set(profile, update, other[update]),
          );
        }
        return { profile };
      },
      // () => {
      //   //refresh profile settings on campus change
      //   if (name === "campus" && value) {
      //     this.getProfileSettings(value.id);
      //   }
      // }
    );
  };

  onSaveProfile = handlePreventDefault(() => {
    this.setState(
      {
        showFormValidation: true,
        submitErrorMessage: "",
        submitFormAttempted: true,
        showSubmitSuccessMessage: false,
      },
      async () => {
        if (this.isIncompleteProfile()) {
          //go to profile tab (where required fields are) if not currently on the tab
          const { tab } = this.state;
          if (tab.id !== "profile") {
            this.setState({ tab: this.tabs.find((t) => t.id === "profile") });
          }
          return;
        }

        const { initialProfile, profile, updateEmailConfirmed } = this.state;

        if (
          initialProfile.email &&
          profile.email !== initialProfile.email &&
          !updateEmailConfirmed
        ) {
          this.setState({
            showUpdateEmailConfirmationModal: true,
          });
          return;
        }

        const profileForSubmission = _cloneDeep(profile);
        replaceValuesInObject(
          profileForSubmission,
          (val) => val === "",
          () => null,
        );
        await this.props.actions.submitStudent(profileForSubmission);

        const {
          submitProfile: { data: submittedProfile, errorMessage, success },
        } = this.props;

        if (success) {
          if (this.props.close) {
            this.props.close();
          } else if (submittedProfile) {
            const formattedProfile = getFormattedValuesForForm(
              submittedProfile,
              ["person.dob"],
            );
            this.setState({
              initialProfile: formattedProfile,
              profile: _cloneDeep(formattedProfile),
              showSubmitSuccessMessage: true,
              submitFormAttempted: false,
              updateEmailConfirmed: false,
            });
          }
          if (this.props.goToOnSubmitProfile) {
            Navigation.redirect(this.props.goToOnSubmitProfile);
          }
          if (this.props.onSubmit) {
            this.props.onSubmit(submittedProfile);
          }
        } else {
          this.setState({
            submitErrorMessage:
              errorMessage ||
              "Something went wrong and student profile could not be saved.",
          });
        }
      },
    );
  });

  onDeleteStudent = handlePreventDefault(async () => {
    const { profile } = this.state;
    const deleteState = await this.props.actions.deleteStudent(profile.id);

    const { errorMessage, success } = deleteState;

    if (success) {
      if (this.props.close) {
        this.props.close();
        Navigation.goBack();
      } else {
        this.setState({
          showDeleteConfirmationModal: false,
          deleteErrorMessage: "",
        });
      }
    } else {
      this.setState({
        deleteErrorMessage:
          errorMessage ||
          "Something went wrong and student could not be deleted.",
      });
    }
  });

  updateProfileValidation = (name, isValid) => {
    const { profileValidationErrors } = this.state;
    this.setState({
      profileValidationErrors: isValid
        ? profileValidationErrors.filter((err) => err !== name)
        : [...profileValidationErrors, name],
    });
  };

  render() {
    const {
      campuses,
      close,
      profile: { error: profileError },
      // profileSettings: { error: profileSettingsError },
      submitProfile: { loading: submitProfileLoading },
      sys,
    } = this.props;

    const {
      initialProfile,
      loading, // loading profile or initial profile settings
      navigating,
      profile,
      profileValidationErrors,
      showFormValidation,
      showSubmitSuccessMessage,
      showUpdateEmailConfirmationModal,
      showDeleteConfirmationModal,
      studentGroupOptions,
      submitErrorMessage,
      submitFormAttempted,
      deleteErrorMessage,
      tab,
    } = this.state;

    const isCreateStudent = !this.props.studentId && !this.props.studentProfile;

    return loading ? (
      <div className="full-page-loader">
        <Loader />
      </div>
    ) : profileError ? (
      <div>
        Sorry, something went wrong and your profile information could not be
        retrieved.
      </div>
    ) : (
      !!profile && (
        <div
          className={`relative${isCreateStudent ? " new-student-profile" : ""}`}
        >
          <Prompt
            when={!navigating && !_isEqual(profile, initialProfile)}
            message="Are you sure you want to leave this page before submitting?  Your changes will be lost."
          />
          <div className="mobile-mb-8 profile-form-header">
            <div className="flex flex-align-center flex-justify-space">
              <p className="xl-text fw-700 mb-16 mt-8">
                {isCreateStudent ? "Create New" : "Edit"} Student
              </p>
              <div className="save-btn-container relative flex flex-align-center mobile-hidden">
                {!isCreateStudent && (
                  <div className="tooltip-container">
                    <div
                      className={`mr-24 pointer ${
                        initialProfile.canDelete ? "error-text" : "accent-text"
                      }`}
                      onClick={() => {
                        if (!initialProfile.canDelete) return;
                        this.setState({
                          showDeleteConfirmationModal: true,
                        });
                      }}
                    >
                      Delete
                    </div>
                    {!initialProfile.canDelete && (
                      <span
                        className="tooltip"
                        style={{ bottom: "75%", zIndex: 1 }}
                      >
                        Student cannot be deleted because there is a
                        login/activity associated with their account.
                      </span>
                    )}
                  </div>
                )}
                {close && (
                  <button
                    className="btn btn-medium btn-light mr-16"
                    disabled={submitProfileLoading}
                    onClick={close}
                    style={{ width: "120px" }}
                  >
                    Cancel
                  </button>
                )}
                <button
                  className="btn btn-medium btn-accent"
                  disabled={
                    submitProfileLoading || _isEqual(initialProfile, profile)
                  }
                  onClick={this.onSaveProfile}
                  style={{ width: "120px" }}
                  type="submit"
                >
                  {submitProfileLoading ? "Saving..." : "Save"}
                </button>
                {!submitProfileLoading && (
                  <>
                    <FadeOutMessage
                      className="success-text"
                      message={
                        showSubmitSuccessMessage
                          ? "Changes have been saved"
                          : ""
                      }
                      onTimeout={() =>
                        this.setState({
                          showSubmitSuccessMessage: false,
                        })
                      }
                      timeout={20000}
                    />
                    <span className="error-message">
                      {submitFormAttempted &&
                        (showFormValidation && this.isIncompleteProfile()
                          ? "Please complete required/incomplete fields"
                          : submitErrorMessage || "")}
                    </span>
                    <span className="error-message">{deleteErrorMessage}</span>
                  </>
                )}
              </div>
            </div>
            {isCreateStudent && (
              <p className="accent-text mt-16" style={{ fontSize: "13px" }}>
                Student will be visible to Shluchim only and will not be aware
                of their account and cannot register for events until they log
                in to the portal and finalize account creation
              </p>
            )}
          </div>

          {this.tabs.length > 1 && (
            <ul className="student-profile-modal-tabs">
              {this.tabs.map((t) => (
                <li
                  className={tab && tab.id === t.id ? "active" : ""}
                  key={t.id}
                  onClick={() => this.setState({ tab: t })}
                >
                  {t.title}
                </li>
              ))}
            </ul>
          )}
          {tab &&
            React.createElement(tab.component, {
              campuses,
              getProfileFieldLabel: this.getProfileFieldLabel,
              initialProfile,
              isCreateStudent,
              isShliach: this.isShliach,
              isProfileFieldRequired: this.isProfileFieldRequired,
              isSingleTabView: this.tabs.length === 1,
              onChangeProfile: this.onChangeProfile,
              onChangeProfileEvt: this.onChangeProfileEvt,
              onChangeStudentGroups: this.onChangeStudentGroups,
              studentGroupOptions: studentGroupOptions,
              profile,
              profileValidationErrors,
              showFormValidation,
              sys,
              updateProfileValidation: this.updateProfileValidation,
            })}
          <div
            className="save-btn-container relative flex flex-align-center desktop-hidden tablet-hidden"
            style={{ position: "sticky", bottom: 0 }}
          >
            <button
              className="btn btn-large btn-accent full-width"
              disabled={
                submitProfileLoading ||
                // profileSettingsError ||
                _isEqual(initialProfile, profile)
              }
              onClick={this.onSaveProfile}
              type="submit"
            >
              {submitProfileLoading ? "Saving..." : "Save"}
            </button>
            {!submitProfileLoading && (
              <>
                <FadeOutMessage
                  className="success-text"
                  message={
                    showSubmitSuccessMessage ? "Changes have been saved" : ""
                  }
                  onTimeout={() =>
                    this.setState({ showSubmitSuccessMessage: false })
                  }
                  timeout={20000}
                />
                <span className="error-message">
                  {/* {profileSettingsError ? "Profile settings could not be retrieved. Please refresh the page to try again." : */}
                  {submitFormAttempted &&
                    (showFormValidation && this.isIncompleteProfile()
                      ? "Please complete required/incomplete fields"
                      : submitErrorMessage || "")}
                </span>
                <span className="error-message">{deleteErrorMessage}</span>
              </>
            )}
          </div>

          <ConfirmationModal
            cancel={() =>
              this.setState({ showUpdateEmailConfirmationModal: false })
            }
            confirm={() => {
              this.setState(
                {
                  showUpdateEmailConfirmationModal: false,
                  updateEmailConfirmed: true,
                },
                this.onSaveProfile,
              );
            }}
            message="You changed the email address for this student. This will change the email used for login. Are you sure you want to make this change?"
            show={showUpdateEmailConfirmationModal}
            title="Confirm Email Change"
          />

          <ConfirmationModal
            cancel={() =>
              this.setState({
                showDeleteConfirmationModal: false,
                deleteErrorMessage: "",
              })
            }
            confirm={() => {
              this.onDeleteStudent();
            }}
            message="Are you sure you want to delete this student? This cannot be undone."
            show={showDeleteConfirmationModal}
            title="Delete"
            confirmText="Delete"
            confirmColor="#e94746"
          />
        </div>
      )
    );
  }
}
