import React from "react";
import {
  ResponsiveContainer,
  Label,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Loader from "../../../components/Loader";
import {
  formatCurrency,
  pluralizeText,
  formatNumber,
} from "../../../lib/utils";

export default class EnrollmentSalesOverTimeComparisonChart extends React.Component {
  state = {
    includeAllEvents: false,
    showNumberTicketsSold: false,
  };

  getFormattedTickets = (numberOfTickets) =>
    `(${formatNumber(Math.round(numberOfTickets))} ${pluralizeText(
      "ticket",
      numberOfTickets,
    )})`;

  formatTooltip = (data, amount, eventID) => {
    const eventData = data.find((d) => d.eventID === eventID);
    const numberOfTickets = eventData?.dataPoints?.find(
      (d) => d.amount === amount,
    )?.quantity;
    return [
      `${formatCurrency(amount, this.props.currencyCode)}
       ${
         this.state.showNumberTicketsSold
           ? this.getFormattedTickets(numberOfTickets)
           : ""
       }`,
      eventData.gregorianYear,
    ];
  };

  formatTooltipLabel = (daysDisplay) =>
    daysDisplay === "Last day"
      ? daysDisplay
      : `${daysDisplay} to Grand Draw end`;

  formatTickY = (amount) => formatCurrency(amount, this.props.currencyCode);

  formatTickX = (daysDisplay) =>
    !daysDisplay
      ? ""
      : daysDisplay === "Last day"
      ? daysDisplay
      : daysDisplay.split(" ")[0];

  formatLegend = (data, eventID) => {
    const raffleEvent = data.find((d) => d.eventID === eventID);
    const dataPoint = raffleEvent.dataPoints[raffleEvent.dataPoints.length - 1];
    const eventTotal = dataPoint?.amount || 0;
    const numberOfTickets = dataPoint?.quantity || 0;

    return `${raffleEvent.gregorianYear}: 
    ${formatCurrency(eventTotal, this.props.currencyCode)}
    ${
      this.state.showNumberTicketsSold
        ? this.getFormattedTickets(numberOfTickets)
        : ""
    }`;
  };

  formatData = (raffleEventsData, raffleDaysToEnd) => {
    const dataByDays = raffleDaysToEnd
      .sort((d1, d2) => d2.intValue - d1.intValue)
      .map(({ displayValue, enumValue }) => ({
        daysToEnd: enumValue,
        daysToEndDisplay: displayValue,
      }));

    raffleEventsData.forEach(({ eventID, dataPoints }) => {
      dataByDays.forEach((dataByDay) => {
        const salesByDay = dataPoints.find(
          ({ daysToEnd }) => daysToEnd === dataByDay.daysToEnd,
        );
        if (salesByDay) {
          dataByDay[eventID] = salesByDay.amount;
        }
      });
    });

    return dataByDays;
  };

  strokeColors = [
    "#F3893D",
    "#63C674",
    "#EC5150",
    "#6369D1",
    "#53B7E8",
    "#F6AC77",
    "#91D79D",
    "#F18584",
    "#9196DE",
    "#86CCEE",
    "#AA5F2A",
    "#458A51",
    "#A53838",
    "#454992",
    "#3A80A2",
    "#79441E",
    "#B0E2B9",
    "#F5A8A7",
    "#B0B4E7",
    "#A8DAF3",
    "#F8C49E",
    "#31623A",
    "#762828",
    "#313468",
    "#295B74",
  ];

  getLineStroke = (index) =>
    this.strokeColors[index < this.strokeColors.length ? index : index % 5] ||
    "rgba(0,0,0,0.87)";

  onToggleIncludeAllEvents = (includeAllEvents) => {
    this.setState({ includeAllEvents }, () => {
      this.props.getRaffleSalesComparison(
        this.props.id,
        includeAllEvents,
        this.props.raffleId,
      );
    });
  };

  render() {
    const { includeAllEvents, showNumberTicketsSold } = this.state;
    const {
      data = [],
      daysRemaining,
      errorMessage,
      loading,
      raffleDaysToEnd = [],
      raffleTitle,
    } = this.props;

    const formattedData = this.formatData(data, raffleDaysToEnd);

    return (
      <div className="mt-40 graph-outline" style={{ minHeight: "488px" }}>
        <div className="flex flex-justify-space flex-align-center mb-24 mobile-block">
          <div className="medium-text fw-700 mobile-mb-16">
            Sales Over Time Year to Year Comparison
            <p className="small-text accent-text mt-4">
              By days to Grand Draw end
              {daysRemaining > 0 && (
                <span className="block mt-4 fw-500 italic-text">
                  {daysRemaining} {pluralizeText("day", daysRemaining)}{" "}
                  remaining for {raffleTitle}
                </span>
              )}
            </p>
          </div>
          <div>
            <div className="flex flex-align-center custom-checkbox-container raffle-metrics-checkbox">
              <input
                className="custom-checkbox"
                checked={includeAllEvents}
                id="all-events"
                onChange={(e) =>
                  this.onToggleIncludeAllEvents(e.target.checked)
                }
                type="checkbox"
              />
              <label className="flex-align-center" htmlFor="all-events">
                Include all raffle years
              </label>
            </div>
            <div className="flex flex-align-center custom-checkbox-container raffle-metrics-checkbox mt-12">
              <input
                className="custom-checkbox"
                checked={showNumberTicketsSold}
                id="show-number-tickets"
                onChange={(e) =>
                  this.setState({ showNumberTicketsSold: e.target.checked })
                }
                type="checkbox"
              />
              <label
                className="flex-align-center"
                htmlFor="show-number-tickets"
              >
                Show number of tickets sold
              </label>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : errorMessage ? (
          <p className="error-text">{errorMessage}</p>
        ) : !data.length ? (
          <div className="chart-no-results chart-no-results-small">
            <img src="/images/no_results.svg" alt="no results" height="120" />
            <p className="accent-text mt-8">No sales found for comparison</p>
          </div>
        ) : (
          <ResponsiveContainer height={400} width="94%">
            <LineChart data={formattedData}>
              {data.map(({ eventID }, index) => (
                <Line
                  dataKey={eventID}
                  key={index}
                  type="monotone"
                  stroke={this.getLineStroke(index)}
                />
              ))}

              <Tooltip
                formatter={(...passProps) =>
                  this.formatTooltip(data, ...passProps)
                }
                itemSorter={() => 1} //maintain order of data
                labelFormatter={this.formatTooltipLabel}
                {...(includeAllEvents
                  ? {
                      position: { y: data.length * -10 }, //position to align bottom of tooltip with chart bottom
                      wrapperStyle: { fontSize: 10, paddingBottom: 16 },
                    }
                  : {})}
              />

              <Legend
                formatter={(...passProps) =>
                  this.formatLegend(data, ...passProps)
                }
                verticalAlign="bottom"
                wrapperStyle={{ paddingBottom: 24, bottom: -16 }}
              />

              <XAxis
                dataKey="daysToEndDisplay"
                tickFormatter={this.formatTickX}
              >
                <Label value="Days left" offset={-2} position="insideBottom" />
              </XAxis>

              <YAxis
                domain={["dataMin", "dataMax"]}
                tickFormatter={this.formatTickY}
                type="number"
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    );
  }
}
