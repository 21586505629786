import { AuthRequest } from "../../lib";

export const ResetPassActions = {
  DO_RESET_PASS: "DO_RESET_PASS",
  RESET_PASS_ERROR: "RESET_PASS_ERROR",
  RESET_PASS_COMPLETE: "RESET_PASS_COMPLETE",
  SET_RESET_PASS_LOADING: "SET_RESET_PASS_LOADING",

  doResetPass(payload) {
    return async function doingResetPass(dispatch, getState) {
      dispatch(ResetPassActions.setResetPassLoading(true));
      const response = await AuthRequest.post("Account/ResetPw", {
        oldPW: payload.currentPass,
        newPW: payload.newPass1,
      }).catch((err) => {
        dispatch({ type: ResetPassActions.RESET_PASS_ERROR, payload: err });
        return undefined;
      });
      if (!response) {
        return;
      }
      dispatch(ResetPassActions.doResetPassComplete());
    };
  },
  setResetPassLoading(payload) {
    return {
      type: ResetPassActions.SET_RESET_PASS_LOADING,
      payload,
    };
  },
  doResetPassComplete(payload) {
    return {
      type: ResetPassActions.RESET_PASS_COMPLETE,
      payload,
    };
  },
};
