import React from "react";
import Select from "react-select";
import {
  Navigation,
  PageURL,
  pluralizeText,
  toTitleCase,
} from "../../../../../lib";

export default class RsvpItemsDropdown extends React.PureComponent {
  componentDidMount() {
    this.props.getRsvpItems();
  }

  onSelectItem = (id) => {
    const {
      pageRoute: {
        query: { tab },
        page,
        params,
      },
    } = this.props;

    Navigation.go(
      PageURL.to(
        page,
        {
          ...params,
          rsvpItemId: id,
        },
        {
          tab,
        },
      ),
    );
  };

  render() {
    const {
      rsvpType,
      rsvpItems: { data: items, error, errorMessage, loading },
      getOptionLabel,
      pageRoute: {
        params: { rsvpItemId },
      },
    } = this.props;

    const options = (items || []).map((item) => ({
      value: item.id,
      label: getOptionLabel(item),
    }));

    return (
      <div>
        <Select
          className="program-management-select"
          classNamePrefix="program-management-select"
          isSearchable={false}
          onChange={(val) => this.onSelectItem(val.value)}
          options={options}
          placeholder={
            loading
              ? `Loading ${pluralizeText(toTitleCase(rsvpType))}...`
              : `Select ${toTitleCase(rsvpType)}...`
          }
          value={
            rsvpItemId &&
            options.find((s) => s.value.toString() === rsvpItemId.toString())
          }
        />
        {!loading && error && (
          <p className="error-message" style={{ bottom: "-20px", right: 0 }}>
            {errorMessage ||
              `Something went wrong and ${pluralizeText(
                rsvpType,
              )} could not be retrieved.`}
          </p>
        )}
      </div>
    );
  }
}
