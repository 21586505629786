import React from "react";
import moment from "moment";
import { pluralizeText } from "../../../../lib";
import { Typography } from "@material-ui/core";

export default function GrantCard({
  studentGrant,
  onSubmitStudentGrant,
  didScheduleEnd,
  onToggleStudentGrant,
  showPrimaryGrant,
  onSetPrimaryStudentGrant,
  width,
  shouldDisplaySubmittedScheduleTitle,
}) {
  const {
    ineligibleStudentReasons,
    isEligibleForSubmission,
    isEnrolledInThisGrant,
    isPrimary,
    isStudentEligibleForGrant,
    isSubmitted,
    minInteractionsRangeWeeks,
    requirements,
    submittedDate,
    enrolledInGrantProgramScheduleTitle,
  } = studentGrant;

  const {
    isFlagged,
    flags,
    primaryFlagClassName,
    showInvalidInteractionsRangeFlag,
  } = getGrantFlags(studentGrant);

  const getSubmittedDisplay = () => {
    const mainText = shouldDisplaySubmittedScheduleTitle
      ? `Submitted ${moment(submittedDate).format("MM/DD/YYYY")}`
      : "Submitted";

    const subText = shouldDisplaySubmittedScheduleTitle
      ? enrolledInGrantProgramScheduleTitle
      : moment(submittedDate).format("MM/DD/YYYY");

    return (
      <div className="flex flex-align-center centered-card">
        <p className="fw-600 mb-12" style={{ fontSize: 14 }}>
          {mainText}
        </p>
        <p className="small-text">{subText}</p>
      </div>
    );
  };

  return (
    <div
      className={`card grant-card ${
        isFlagged
          ? "flagged"
          : isSubmitted
          ? "submitted"
          : !isStudentEligibleForGrant
          ? "ineligible"
          : !isEnrolledInThisGrant
          ? "inactive"
          : "active"
      }-grant-card ${isFlagged ? primaryFlagClassName : ""}`}
      style={{ width: width }}
    >
      {!isEnrolledInThisGrant && !isStudentEligibleForGrant ? (
        <div className="flex flex-align-center flex-column">
          <Typography variant="subtitle2" className="mb-8">
            Ineligible
          </Typography>
          <div className="small-text text-center">
            {ineligibleStudentReasons &&
              ineligibleStudentReasons.map((reason, index) => (
                <p className="mb-8" key={index}>
                  {reason}
                </p>
              ))}
          </div>
        </div>
      ) : isSubmitted ? (
        getSubmittedDisplay()
      ) : (
        <div>
          <div className="accent-text mb-8 mt-8 ml-4">
            <div style={{ lineHeight: "14px" }}>
              {requirements?.map((r, i) => (
                <div className="mb-12" key={i}>
                  {r.numRequiredInteractions > 0 && (
                    <p className="small-text fw-600">
                      {r.interactionsCount || 0}/{r.numRequiredInteractions}{" "}
                      {r.classifications.join(" or ")}
                      {" Interactions"}
                    </p>
                  )}
                  <div className="italic-text xs-text">
                    {r.israelRelatedMin > 0 && (
                      <p>
                        {r.israelRelatedCount || 0}/{r.israelRelatedMin}{" "}
                        required Israel/Antisemitism Related
                      </p>
                    )}
                    {r.shabbosMealMax > 0 && (
                      <p>
                        {r.shabbosMealCount || 0} Shabbos{" "}
                        {pluralizeText("Meal", r.shabbosMealCount)} (max{" "}
                        {r.shabbosMealMax})
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className="grant-card-separator"
            style={{ width: width - 2 }}
          ></div>
          <div
            className="flex flex-align-center flex-justify-space mt-8"
            style={{ flexFlow: "wrap-reverse" }}
          >
            <div className="flex flex-justify-space flex-align-center">
              {!didScheduleEnd && !isSubmitted && (
                <>
                  <div className="card-action-button flex flex-align-center flex-justify-center tooltip-container  mr-8">
                    <i
                      className={`material-icons accent-text-light xl-text ${
                        isEnrolledInThisGrant ? "blue-filled" : ""
                      }`}
                      onClick={(e) =>
                        onToggleStudentGrant(
                          studentGrant,
                          !isEnrolledInThisGrant,
                        )
                      }
                    >
                      {isEnrolledInThisGrant ? "toggle_on" : "toggle_off"}
                    </i>
                    <span className="tooltip">
                      <span className="block">
                        {isEnrolledInThisGrant
                          ? "Toggle to deactivate"
                          : "Toggle to activate"}
                      </span>
                    </span>
                  </div>

                  {showPrimaryGrant && isEnrolledInThisGrant && (
                    <div className="card-action-button tooltip-container mr-8 flex flex-align-center flex-justify-center">
                      <i
                        className={`material-icons accent-text-light xl-text ${
                          isPrimary ? "yellow-filled" : ""
                        }`}
                        onClick={() => onSetPrimaryStudentGrant(studentGrant)}
                      >
                        {isPrimary ? "star" : "star_outline"}
                      </i>
                      <span className="tooltip">
                        <span className="block">Set as primary</span>
                      </span>
                    </div>
                  )}
                </>
              )}
              {isFlagged && <FlagsList flags={flags} />}
              {showInvalidInteractionsRangeFlag && (
                <div className="tooltip-container mr-8">
                  <i
                    className="material-icons accent-text-dark profile-add-icon"
                    style={{ color: "#FEC609" }}
                  >
                    schedule
                  </i>
                  <span className="tooltip">
                    Interactions must take place over a range of at least{" "}
                    {minInteractionsRangeWeeks}{" "}
                    {pluralizeText("week", minInteractionsRangeWeeks)}
                  </span>
                </div>
              )}
            </div>

            {!didScheduleEnd && isEligibleForSubmission && (
              <button
                className="btn btn-accent"
                onClick={() => onSubmitStudentGrant(studentGrant)}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      )}
      {isSubmitted && <FlagsList flags={flags} />}
    </div>
  );
}

const FlagsList = ({ flags }) => {
  return flags.map((flag, flagIndex) => (
    <div className="tooltip-container mr-8 flex" key={flagIndex}>
      <i
        className={`material-icons accent-text-dark profile-add-icon flag-icon ${flag.className}`}
      >
        flag
      </i>
      <span
        className="tooltip pre-wrap"
        style={{
          width: 180,
        }}
      >
        {flag.flaggedReasons &&
          flag.flaggedReasons.map((reason, reasonIndex) => (
            <span className="block" key={reasonIndex}>
              {reason}
            </span>
          ))}
      </span>
    </div>
  ));
};

const getGrantFlags = (studentGrant) => {
  const {
    ineligibleFlaggedReasons,
    isIneligibleFlagged,
    isMissingDataFlagged,
    isStudentEligibleForGrant,
    isStudentPotentialDuplicateFlagged,
    meetsAllInteractionsCounts,
    meetsMinInteractionsRangeWeeks,
    missingDataFlaggedReasons,
  } = studentGrant;
  const isFlagged =
    isIneligibleFlagged ||
    isMissingDataFlagged ||
    isStudentPotentialDuplicateFlagged;

  const flags = [];
  if (isIneligibleFlagged) {
    flags.push({
      className: "eligibility-flag",
      flaggedReasons: ineligibleFlaggedReasons,
      isIneligibleFlagged,
    });
  }
  if (isMissingDataFlagged) {
    flags.push({
      className: "missing-data-flag",
      flaggedReasons: missingDataFlaggedReasons,
      isMissingDataFlagged,
    });
  }
  if (isStudentPotentialDuplicateFlagged) {
    flags.push({
      className: "duplicate-flag",
      flaggedReasons: [
        "Student is a potential duplicate, please contact COCI Admin to resolve duplicate status.",
      ],
      isStudentPotentialDuplicateFlagged,
    });
  }

  const showInvalidInteractionsRangeFlag = //show invalid time range flag when interactions time range is blocking the submission eligibility
    isStudentEligibleForGrant &&
    meetsAllInteractionsCounts &&
    !meetsMinInteractionsRangeWeeks;

  const primaryFlagClassName = isFlagged
    ? flags[0].className
    : showInvalidInteractionsRangeFlag
    ? "time-range-flag"
    : "";

  return {
    isFlagged,
    flags,
    primaryFlagClassName,
    showInvalidInteractionsRangeFlag,
  };
};
