import React from "react";
import Modal from "react-modal";
import Loader from "../../../components/Loader";
import moment from "moment";
import { formatAddressDisplay, formatFullName } from "../../../lib";

export default class ConfirmEnrollmentInfoModal extends React.Component {
  render() {
    const {
      account: { credCardInfo },
      cancel,
      confirm,
      enrollment: {
        billing: {
          address: { address1, address2, city, country, state, zip } = {},
          stripeToken: {
            card: { brand: cardBrand, last4: cardLast4 } = {},
          } = {},
          useCardOnFile,
        },
        shliach: {
          dob: shliachDob,
          isEnrolled: isShliachEnrolled,
          legalFirstName: shliachLegalFirstName,
          legalMiddleName: shliachLegalMiddleName,
          legalLastName: shliachLegalLastName,
        },
        shlucha: {
          dob: shluchaDob,
          isEnrolled: isShluchaEnrolled,
          legalFirstName: shluchaLegalFirstName,
          legalMiddleName: shluchaLegalMiddleName,
          legalLastName: shluchaLegalLastName,
        },
      },
      errorMessage,
      loading,
      show,
    } = this.props;

    return (
      <Modal isOpen={show} className="modal-container">
        <div className="modal-card card large-modal">
          <p className="large-text fw-500 mb-16">Confirm Your Information</p>
          <p className="accent-text mb-24 line-height-double">
            Please review all your information and ensure that it is correct as
            it can not be edited once you save. If you are not enrolling both
            Shliach and Shlucha now, you will not be able to add the other
            later.
          </p>
          <div className="flex three-column-grid confirm-info-details">
            {isShliachEnrolled && (
              <div>
                <p className="medium-text fw-700">Shliach Information</p>
                <p>
                  {formatFullName(
                    shliachLegalFirstName,
                    shliachLegalLastName,
                    shliachLegalMiddleName,
                  )}
                </p>
                <p>{moment(shliachDob).format("MMMM D, YYYY")}</p>
              </div>
            )}
            {isShluchaEnrolled && (
              <div>
                <p className="medium-text fw-700">Shlucha Information</p>
                <p>
                  {formatFullName(
                    shluchaLegalFirstName,
                    shluchaLegalLastName,
                    shluchaLegalMiddleName,
                  )}
                </p>
                <p>{moment(shluchaDob).format("MMMM D, YYYY")}</p>
              </div>
            )}
            <div>
              <p className="medium-text fw-700">Payment Information</p>
              {useCardOnFile ? (
                <React.Fragment>
                  <p>Card on File:</p>
                  <p>
                    {credCardInfo.cardBrand} ending in {credCardInfo.lastFour}
                  </p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <p>
                    {cardBrand} ending in {cardLast4}
                  </p>
                  <p className="line-height-double">
                    {formatAddressDisplay(
                      address1,
                      address2,
                      city,
                      state,
                      zip,
                      country,
                    )}
                  </p>
                </React.Fragment>
              )}
            </div>
          </div>
          {loading ? (
            <div className="modal-btns-loader">
              <Loader />
            </div>
          ) : (
            <div className="text-right">
              <div className="modal-btns relative">
                <p className="link-text uppercase-text" onClick={cancel}>
                  Cancel
                </p>
                <p className="link-text uppercase-text ml-40" onClick={confirm}>
                  Confirm & Save
                </p>
              </div>

              {!!errorMessage && (
                <span className="error-text">{errorMessage}</span>
              )}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
