import React from "react";
import Select from "react-select";
import { Chip, Typography } from "@material-ui/core";

import { withAppInsights } from "../../lib/AppInsights";
import { Navigation, PageLink } from "../../lib";
import Pages from "..";

import Loader from "../../components/Loader";
import NoResultsFound from "../../components/NoResultsFound";

class Programs extends React.PureComponent {
  state = {
    searchFocused: false,
    searchTerm: "",
    audiences: [],
    categories: [],
  };

  componentDidMount = () => {
    this.setSearchState();
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.pageRoute.location.key !== this.props.pageRoute.location.key
    ) {
      this.setSearchState();
    }
  }

  getProgramSections = (programs) => {
    let programSections = {};
    programs &&
      programs.forEach((program) => {
        if (!programSections.hasOwnProperty(program.audience.displayValue)) {
          programSections[program.audience.displayValue] = {};
        }
        if (
          !programSections[program.audience.displayValue].hasOwnProperty(
            program.category.displayValue,
          )
        ) {
          programSections[program.audience.displayValue][
            program.category.displayValue
          ] = [];
        }
        programSections[program.audience.displayValue][
          program.category.displayValue
        ].push(program);
      });
    return programSections;
  };

  setSearchState = () => {
    const { s, a, c } = this.props.pageRoute.query;
    let audiences = [];
    let categories = [];
    if (a) {
      audiences = JSON.parse(a).map((value) => {
        const index = this.props.sys.programAudiences.findIndex(
          (audience) => audience.enumValue === value,
        );
        return {
          label: this.props.sys.programAudiences[index].displayValue,
          value: this.props.sys.programAudiences[index].enumValue,
        };
      });
    }
    if (c) {
      categories = JSON.parse(c).map((value) => {
        const index = this.props.sys.programCategories.findIndex(
          (category) => category.enumValue === value,
        );
        return {
          label: this.props.sys.programCategories[index].displayValue,
          value: this.props.sys.programCategories[index].enumValue,
        };
      });
    }
    this.setState(
      {
        searchTerm: s || "",
        audiences,
        categories,
      },
      () => {
        this.searchPrograms();
      },
    );
  };

  searchPrograms = () => {
    let { s, a, c } = this.props.pageRoute.query;
    let { searchTerm, audiences, categories } = this.state;
    audiences = audiences.map((audience) => audience.value);
    categories = categories.map((category) => category.value);
    this.props.actions.searchPrograms(searchTerm, audiences, categories);
    if (
      s !== searchTerm ||
      JSON.parse(a).length !== audiences.length ||
      JSON.parse(c).length !== categories.length
    ) {
      Navigation.redirect(
        `${
          this.props.pageRoute.location.pathname
        }?s=${searchTerm}&a=${encodeURIComponent(
          JSON.stringify(audiences),
        )}&c=${encodeURIComponent(JSON.stringify(categories))}`,
      );
    }
  };

  filterPrograms = (e, filterType) => {
    this.setState({ [filterType]: e }, () => this.searchPrograms());
  };

  addHoverStyle = (name) => {
    let card = document.getElementById(name).parentNode;
    card.classList.add("hover");
  };

  removeHoverStyle = (name) => {
    let card = document.getElementById(name).parentNode;
    card.classList.remove("hover");
  };

  setTagColor = (tag) => {
    let background;
    switch (tag) {
      case "Education":
        background = "#FFC600";
        break;
      case "Trips":
        background = "#2774AE";
        break;
      case "Grants":
        background = "#9AE19D";
        break;
      case "Events":
        background = "#537A5A";
        break;
      case "Funding":
        background = "#9ED0E6";
        break;
      case "Programs":
        background = "#4ECDC4";
        break;
      case "Student Engagement":
        background = "#416b8c";
        break;
      case "Shlichus Advancement":
        background = "#FF6B6B";
        break;
      case "Shluchim-Shluchos Support":
        background = "#AC3931";
        break;
      case "General":
        background = "#E9AFA3";
        break;
      case "Other":
        background = "#533B4D";
        break;
      case "Local Programs":
        background = "#B796AC";
        break;
      default:
        background = "#1E1C1C";
    }
    return background;
  };

  render() {
    const { audiences, categories } = this.state;
    const {
      programs: { loading, displayedPrograms },
      sys: { programAudiences, programCategories },
    } = this.props;
    const programSections = this.getProgramSections(
      displayedPrograms && displayedPrograms.data,
    );
    return (
      <>
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : (
          <>
            <div className="programs-page-header">
              <div className="container">
                <Typography variant="h4" className="mobile-hidden mb-24">
                  Programs
                </Typography>
                <div
                  className={`mb-16 ${
                    this.state.searchFocused
                      ? "search-input search-input-focused"
                      : "search-input"
                  }`}
                >
                  <input
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => {
                      this.setState({ searchTerm: e.target.value });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") this.searchPrograms();
                    }}
                    value={this.state.searchTerm}
                  />
                  <i
                    className="material-icons accent-text-secondary pointer"
                    onClick={() => this.searchPrograms()}
                  >
                    search
                  </i>
                </div>
                <div className="flex flex-align-center ">
                  <p className="mr-8">Filter by Audience</p>
                  <Select
                    className="program-select"
                    classNamePrefix="program-select"
                    isClearable={false}
                    isSearchable={false}
                    isMulti={true}
                    placeholder=""
                    options={programAudiences.map((audience) => ({
                      label: audience.displayValue,
                      value: audience.enumValue,
                    }))}
                    onChange={(e) => this.filterPrograms(e, "audiences")}
                    styles={{
                      multiValueLabel: (styles, { data }) => ({
                        ...styles,
                        backgroundColor: this.setTagColor(data.label),
                      }),
                      multiValueRemove: (styles, { data }) => ({
                        ...styles,
                        backgroundColor: this.setTagColor(data.label),
                        "&:hover": {
                          backgroundColor: this.setTagColor(data.label),
                        },
                      }),
                    }}
                    value={audiences}
                  />
                </div>
                <div className="flex flex-align-center">
                  <p className="mr-8">Filter by Category</p>
                  <Select
                    className="program-select"
                    classNamePrefix="program-select"
                    isClearable={false}
                    isSearchable={false}
                    isMulti={true}
                    placeholder=""
                    options={programCategories.map((category) => ({
                      label: category.displayValue,
                      value: category.enumValue,
                    }))}
                    onChange={(e) => this.filterPrograms(e, "categories")}
                    styles={{
                      multiValueLabel: (styles, { data }) => ({
                        ...styles,
                        backgroundColor: this.setTagColor(data.label),
                      }),
                      multiValueRemove: (styles, { data }) => ({
                        ...styles,
                        backgroundColor: this.setTagColor(data.label),
                        "&:hover": {
                          backgroundColor: this.setTagColor(data.label),
                        },
                      }),
                    }}
                    value={categories}
                  />
                </div>
              </div>
            </div>
            <div className="programs-page page container">
              {displayedPrograms && displayedPrograms.loading ? (
                <Loader />
              ) : displayedPrograms &&
                displayedPrograms.data &&
                displayedPrograms.data.length > 0 ? (
                <div className="program-cards-grid">
                  {Object.keys(programSections).map((section) => (
                    <div key={section}>
                      <Typography
                        variant="h6"
                        className="program-section-title"
                      >
                        {section}
                      </Typography>
                      {Object.keys(programSections[section]).map((category) => (
                        <React.Fragment key={category}>
                          <Typography
                            className="program-section-subtitle"
                            style={{ color: this.setTagColor(category) }}
                          >
                            {category}
                          </Typography>
                          {programSections[section][category].map((program) => (
                            <div
                              key={program.programID}
                              className="program-card card"
                            >
                              <PageLink
                                to={Pages.programs.programDetails}
                                params={{ programId: program.programID }}
                                key={program.id}
                                state={{ test: true }}
                                id={program.name}
                                onMouseEnter={() => {
                                  this.addHoverStyle(program.name);
                                }}
                                onMouseLeave={() => {
                                  this.removeHoverStyle(program.name);
                                }}
                              >
                                <div className="flex flex-justify-space">
                                  <p className="program-name fw-700 large-text mb-8">
                                    {program.name}
                                  </p>
                                </div>
                                <p className="program-description small-text accent-text-dark">
                                  {program.description}
                                </p>
                              </PageLink>
                              {program.officeStaff ? (
                                <div className="program-coordinator mb-8 mt-32">
                                  <label>Program Coordinator</label>
                                  <p className="fw-500">
                                    {program.officeStaff.fullName}
                                  </p>
                                </div>
                              ) : null}
                              <div className="program-contact-info">
                                {program.officeStaff ? (
                                  <>
                                    {program.officeStaff.primaryPhone ? (
                                      <p>
                                        <i className="material-icons">phone</i>
                                        <a
                                          className="link-text-secondary"
                                          href={`tel:${program.officeStaff.primaryPhone}, ${program.officeStaff.primaryPhoneExt}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {program.officeStaff.primaryPhone}
                                          {program.officeStaff
                                            .primaryPhoneExt ? (
                                            <span className="ml-8 small-text">
                                              Ext:{" "}
                                              {
                                                program.officeStaff
                                                  .primaryPhoneExt
                                              }
                                            </span>
                                          ) : null}
                                        </a>
                                      </p>
                                    ) : null}
                                    <p>
                                      <i className="material-icons">email</i>
                                      {program.email ? (
                                        <a
                                          className="link-text-secondary"
                                          href={`mailto:${program.email}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {program.email}
                                        </a>
                                      ) : (
                                        <a
                                          className="link-text-secondary"
                                          href={`mailto:${program.officeStaff.primaryEmail}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {program.officeStaff.primaryEmail}
                                        </a>
                                      )}
                                    </p>
                                  </>
                                ) : null}
                                {program.marketingMaterialURL ? (
                                  <p>
                                    <i className="material-icons">save_alt</i>
                                    <a
                                      className="link-text-secondary"
                                      href={program.marketingMaterialURL}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Download marketing material
                                    </a>
                                  </p>
                                ) : null}
                                <div className="program-tags">
                                  <Chip
                                    className="mr-8"
                                    style={{
                                      backgroundColor: this.setTagColor(
                                        program.audience.displayValue,
                                      ),
                                      color: "#fff",
                                    }}
                                    label={program.audience.displayValue}
                                    size="small"
                                  />
                                  <Chip
                                    style={{
                                      color: this.setTagColor(
                                        program.category.displayValue,
                                      ),
                                      borderColor: this.setTagColor(
                                        program.category.displayValue,
                                      ),
                                    }}
                                    label={program.category.displayValue}
                                    size="small"
                                    variant="outlined"
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </React.Fragment>
                      ))}
                    </div>
                  ))}
                </div>
              ) : (
                <NoResultsFound
                  imgHeight={210}
                  title="No results found"
                  subtitle="Try adjusting your search terms"
                />
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

export default withAppInsights(Programs);
