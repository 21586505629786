import React, { useState, useEffect, useCallback, useMemo, memo } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import { formatNumber, isDateEqual } from "../../../../lib";
import { EngagementSelectors, SystemSelectors } from "../../../../state";
import {
  getMetricDetailsAccessor,
  getMonthYearLabel,
  StudentsMetricsChartHeader,
} from ".";
import MetricsBarChart from "./MetricsBarChart";
import NoMetrics from "./NoMetrics";
import { getJewishInterestLevelColors } from "../shared";
import JewishInterestLevelsLegend from "../../JewishInterestLevelsLegend";

const minRowHeight = 80;

function formatData(data, selectedDates) {
  if (!data) return [];
  return selectedDates.map((monthYear) => {
    const monthData = {
      monthYear,
      total: 0,
    };

    data
      .filter((metrics) => isDateEqual(metrics.monthYear, monthYear))
      .forEach((metric) => {
        const { monthYear, total, jewishInterestLevel } = metric;
        const formatMonthYear = new Date(monthYear).toISOString();
        monthData[jewishInterestLevel] = total;
        monthData[getMetricDetailsAccessor(jewishInterestLevel)] = {
          ...metric,
          monthYear: formatMonthYear,
        };

        monthData.total += total;
      });
    return monthData;
  });
}

function StudentMetricsChartByMonth(props) {
  const { selectedDates, monthYearsArray } = props;
  const [formattedData, setFormattedData] = useState([]);

  const { data, total, loading, errorMessage } = useSelector(
    EngagementSelectors.studentMetrics,
  );

  const jewishInterestLevels = useSelector(
    SystemSelectors.engagementJewishInterestLevels,
  );

  useEffect(
    function updateLocalData() {
      if (data) {
        //we are setting the data in local state per this issue where the bar labels do not appear on initial mount when reading directly from props https://github.com/recharts/recharts/issues/829
        setFormattedData(formatData(data, selectedDates));
      }
    },
    [data, selectedDates],
  );

  const formatTickY = useCallback(
    (key, data) => {
      const monthYearDisplay = getMonthYearLabel(monthYearsArray, key);

      const total = data.find((d) => isDateEqual(d.monthYear, key))?.total;

      return `${monthYearDisplay}\nTotal: ${formatNumber(total)}`;
    },
    [monthYearsArray],
  );

  const dataMax = useMemo(
    () =>
      formattedData?.reduce(
        (prev, current) => (prev?.total > current?.total ? prev : current),
        null,
      ),
    [formattedData],
  );

  const chartHeight = formattedData.length * minRowHeight;

  return (
    <div className="graph-outline mb-24">
      <StudentsMetricsChartHeader loading={loading} dataTotal={total} />

      <div className="mt-8 mb-16">
        {loading ? (
          <Loader />
        ) : errorMessage ? (
          <p className="error-text small-text text-center">{errorMessage}</p>
        ) : !formattedData.length ? (
          <NoMetrics message="No student metrics found" />
        ) : (
          <>
            <JewishInterestLevelsLegend
              levelsSelector={SystemSelectors.engagementJewishInterestLevels}
            />
            <MetricsBarChart
              getColors={getJewishInterestLevelColors}
              groupByMonth={true}
              isGoalData={false}
              chartHeight={chartHeight}
              data={formattedData}
              tickYFormatter={(key) => formatTickY(key, formattedData)}
              tickYDataKey={"monthYear"}
              tickXFormatter={(key) => key}
              metrics={jewishInterestLevels}
              xDomain={[0, dataMax || 100]}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default memo(StudentMetricsChartByMonth);
