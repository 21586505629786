import Pages from "../../pages";

export { getMenuItems, subMenuOptions };

const subMenuOptions = {
  directoryMenu: "Directory",
  programMenu: "Programs",
  engagementMenu: "Engagement",
  shortcutsMenu: "Create new",
};

const getMenuItems = () => [
  {
    text: "Directory",
    url: Pages.directory.directory.path,
    subMenu: subMenuOptions.directoryMenu,
  },
  {
    text: "Programs",
    url: Pages.programs.programs.path,
    subMenu: subMenuOptions.programMenu,
  },
  {
    text: "Engagement",
    url: Pages.engagement.engagement.path,
    subMenu: subMenuOptions.engagementMenu,
  },
  {
    text: "Updates",
    url: Pages.updates.updates.path,
  },
];
