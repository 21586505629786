import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import ErrorDisplay from "../../../../components/ErrorDisplay";
import Loader from "../../../../components/Loader";
import StudentInteractionModal from "../../activity/StudentInteractionModal.vm";
import { PageLink, formatFullName, Navigation } from "../../../../lib";
import pages from "../../../../pages";
import GrantCard from "../../../lamplighters/schedule/students/GrantCard";

export default class StudentLamplightersGrants extends React.PureComponent {
  state = {
    action: null,
    actionErrorMessage: "",
    actionPayload: null,
    actionValidationMessage: "",
    showActionConfirmationModal: false,
    showStudentInteractionModal: false,
  };

  componentDidMount() {
    this.getStudentGrants();
  }

  getStudentGrants = async () => {
    const {
      getStudentGrants,
      student: { id: studentId },
    } = this.props;
    await getStudentGrants(studentId);
  };

  studentGrantActions = {
    Activate: "Activate",
    Deactivate: "Deactivate",
    SetPrimary: "SetPrimary",
    Submit: "Submit",
    LogInteraction: "LogInteraction",
  };

  onStudentGrantAction = (action, actionPayload) => {
    //validate availability for student grant activation
    if (action === this.studentGrantActions.Activate) {
      const {
        enrollment: { data: { allocations } = {} },
      } = this.props;
      const { grantID, grantTypeDisplay } = actionPayload;
      const grantAllocation =
        (allocations && allocations.find((a) => a.grantID === grantID)) || "";
      if (!grantAllocation.availableQuantity) {
        this.setState({
          actionValidationMessage: `This student cannot be activated for this ${grantTypeDisplay} grant because you have exceeded the available quantity for this grant`,
        });
      }
    }

    this.setState({
      action,
      actionErrorMessage: "",
      actionPayload,
      [action === this.studentGrantActions.LogInteraction
        ? "showStudentInteractionModal"
        : "showActionConfirmationModal"]: true,
    });
  };

  getGrantActionConfirmationTitle = () => {
    const { action } = this.state;

    let actionName = this.studentGrantActions[action];
    if (action === this.studentGrantActions.SetPrimary) {
      actionName = "Set Primary";
    }

    return `${actionName} Student Grant Confirmation`;
  };

  getGrantActionConfirmationMessage = () => {
    const { action, actionPayload } = this.state;
    const { student } = this.props;

    const actionGrantType =
      (actionPayload && actionPayload.grantTypeDisplay) || "";

    const studentName =
      (student && formatFullName(student.firstName, student.lastName)) ||
      "this student";

    if (action === this.studentGrantActions.SetPrimary) {
      return `Are you sure you want to set this ${actionGrantType} grant to the primary grant for ${studentName}?`;
    }

    const actionName = (this.studentGrantActions[action] || "").toLowerCase();

    const actionDescriptor =
      this.studentGrantActions[action] === this.studentGrantActions.Deactivate
        ? " from the"
        : this.studentGrantActions[action] === this.studentGrantActions.Activate
        ? " for the"
        : "'s";

    let actionDisclaimer = "";
    if (
      this.studentGrantActions[action] === this.studentGrantActions.Activate &&
      actionGrantType === "Learning"
    ) {
      actionDisclaimer = `\n\nPlease confirm that ${studentName}: \n • is not currently enrolled in Sinai Scholars \n • did not participate in Sinai Scholars in the past \n • does not have plans to participate in Sinai Scholars in the future`;
    } else if (
      this.studentGrantActions[action] === this.studentGrantActions.Submit &&
      !actionPayload.cityOfOrigin
    ) {
      actionDisclaimer = (
        <span>
          <br />
          <br />
          Student’s city of origin is not set. Setting the city of origin prior
          to submission is strongly recommended.
          <br />
          Click{" "}
          <PageLink
            className="link-text"
            params={{ studentId: actionPayload.studentID }}
            query={{
              editDemographicsMode: true,
              goToOnSubmitProfile: Navigation.locationURL,
            }}
            to={pages.students.studentDetails}
          >
            here
          </PageLink>{" "}
          to view student profile and set city of origin.
        </span>
      );
    }

    return (
      <p>
        Are you sure you want to {actionName} {studentName}
        {actionDescriptor} {actionGrantType} grant?{actionDisclaimer}
      </p>
    );
  };

  onCloseStudentGrantAction = () => {
    this.setState({
      action: null,
      actionErrorMessage: "",
      actionPayload: null,
      actionValidationMessage: "",
      showActionConfirmationModal: false,
      showStudentInteractionModal: false,
    });
  };

  submitStudentGrantAction = async () => {
    const {
      action,
      actionPayload: {
        grantID,
        isPrimary,
        isSubmitted,
        studentGrantID,
        studentID,
      },
    } = this.state;

    const studentGrant = {
      grantID,
      id: studentGrantID,
      isEnrolled: action !== this.studentGrantActions.Deactivate,
      isPrimary:
        action === this.studentGrantActions.SetPrimary ? true : isPrimary,
      isSubmitted:
        action === this.studentGrantActions.Submit ? true : isSubmitted,
      studentID,
    };

    await this.props.doSubmitStudentGrant(studentGrant);

    const {
      submitStudentGrant: { errorMessage, success },
    } = this.props;

    if (success) {
      this.getStudentGrants();
      this.setState({
        action: null,
        actionPayload: null,
        showActionConfirmationModal: false,
      });
    } else {
      this.setState({
        actionErrorMessage:
          errorMessage || "Something went wrong.  Please try again.",
      });
    }
  };

  render() {
    const {
      mobileView,
      student: { id: studentId },
      studentGrants: { data: studentGrants, loading, errorMessage },
      submitStudentGrant: { loading: submittingStudentGrant },
    } = this.props;

    const {
      action,
      actionErrorMessage,
      actionValidationMessage,
      showActionConfirmationModal,
      showStudentInteractionModal,
    } = this.state;

    // show primary grant indication if there are more than one active unsubmitted grants for the current schedule
    const showPrimaryGrantIndication =
      studentGrants &&
      studentGrants.filter(
        (s) =>
          s.isGrantScheduleCurrent && s.isEnrolledInThisGrant && !s.isSubmitted,
      ).length > 1;

    return (
      <div>
        <div className="flex flex-align-center flex-justify-space mb-24">
          <div
            className="flex flex-align-center archivo-black"
            style={{ fontSize: 20 }}
          >
            <img
              alt=""
              className="mr-16"
              src="/images/lamplighters-logo.jpg"
              style={{ height: "22px" }}
            />
            Lamplighters
          </div>
          <Button
            variant="contained"
            className="ml-16"
            color="secondary"
            onClick={() => this.setState({ showStudentInteractionModal: true })}
          >
            {mobileView ? "Log" : "Log Interaction"}
          </Button>
        </div>
        <div>
          {loading ? (
            <Loader />
          ) : errorMessage ? (
            <ErrorDisplay
              errorMessage={
                errorMessage ||
                "Something went wrong and we could not retrieve student grants. Please try again."
              }
            />
          ) : (
            studentGrants &&
            (!studentGrants.length ? (
              <p className="accent-text text-center mt-32 mb-32">
                No Lamplighters grants available for this student
              </p>
            ) : (
              <Grid container spacing={4}>
                {studentGrants.map((studentGrant, index) => (
                  <Grid item md={6} xs={12} key={index} className="mb-16">
                    <Typography variant="subtitle1" className="mb-16">
                      {studentGrant.grantTypeDisplay} grant
                      {!!studentGrant.grantDescription &&
                        ` - ${studentGrant.grantDescription}`}
                    </Typography>
                    <GrantCard
                      didScheduleEnd={false}
                      onToggleStudentGrant={(grant, value) => {
                        if (value) {
                          this.onStudentGrantAction(
                            this.studentGrantActions.Activate,
                            studentGrant,
                          );
                        } else {
                          this.onStudentGrantAction(
                            this.studentGrantActions.Deactivate,
                            studentGrant,
                          );
                        }
                      }}
                      onSetPrimaryStudentGrant={(grant) =>
                        this.onStudentGrantAction(
                          this.studentGrantActions.SetPrimary,
                          studentGrant,
                        )
                      }
                      onSubmitStudentGrant={() =>
                        this.onStudentGrantAction(
                          this.studentGrantActions.Submit,
                          studentGrant,
                        )
                      }
                      showPrimaryGrant={showPrimaryGrantIndication}
                      studentGrant={studentGrant}
                      shouldDisplaySubmittedScheduleTitle
                    />
                  </Grid>
                ))}
              </Grid>
            ))
          )}
        </div>
        <StudentInteractionModal
          close={this.onCloseStudentGrantAction}
          onSubmitInteraction={this.getStudentGrants}
          show={showStudentInteractionModal}
          studentId={studentId}
        />
        <ConfirmationModal
          cancel={this.onCloseStudentGrantAction}
          cancelText={actionValidationMessage && "OK"}
          confirm={this.submitStudentGrantAction}
          errorMessage={actionErrorMessage}
          loading={submittingStudentGrant}
          message={
            actionValidationMessage || this.getGrantActionConfirmationMessage()
          }
          noConfirm={!!actionValidationMessage}
          show={showActionConfirmationModal}
          title={
            actionValidationMessage
              ? `Cannot ${action} Student Grant`
              : this.getGrantActionConfirmationTitle()
          }
        />
      </div>
    );
  }
}
