import React, { useState, memo } from "react";
import { Typography } from "@material-ui/core";
import { Navigation, PageURL } from "../../../lib";
import Pages from "../../../pages";
import ConfirmationModal from "../../../components/ConfirmationModal";

interface HeaderProps {
  isEditingInteraction: boolean;
  isSubmittedForGrant: boolean;
  linkedEventScheduleId?: number;
  isLaunchedFromEvent?: boolean;
  interactionHasChanges?: boolean;
  isShliach: boolean;
  allowRsvpAccess: boolean;
  onClose: () => {};
  rsvpProgramScheduleId: number;
}

function StudentInteractionHeaderText(props: HeaderProps) {
  const {
    isSubmittedForGrant,
    isEditingInteraction,
    linkedEventScheduleId,
    isLaunchedFromEvent,
    rsvpProgramScheduleId,
    interactionHasChanges,
    isShliach,
    allowRsvpAccess,
    onClose,
  } = props;

  const [
    showConfirmChangesWillBeLostModal,
    setShowConfirmChangesWillBeLostModal,
  ] = useState(false);

  const isGeneratedFromEvent = !!linkedEventScheduleId;

  const bottomMargin =
    isGeneratedFromEvent || isSubmittedForGrant || !isEditingInteraction
      ? "mb-16"
      : "mb-32"; // artificial whitespace if there's no header text so tooltips display correctly on Type field

  const onClickEventLink = () => {
    if (interactionHasChanges) {
      setShowConfirmChangesWillBeLostModal(true);
    } else {
      navigateToEvent();
    }
  };

  const navigateToEvent = () => {
    if (isLaunchedFromEvent) {
      // just close the dialog, no new navigation
      return onClose();
    }

    const path = Pages.engagement.rsvp.path;
    const params = {
      programScheduleId: rsvpProgramScheduleId,
      pageView: "events",
      rsvpItemId: linkedEventScheduleId,
    };
    const query = { tab: "settings" };
    const url = PageURL.to(path, params, query);
    Navigation.go(url);
  };

  const generatedFromEventText = allowRsvpAccess ? (
    <>
      was generated from an{" "}
      <span className="link-text" onClick={onClickEventLink}>
        event
      </span>
    </>
  ) : (
    "was generated from an event"
  );
  const submittedForGrantText = isShliach
    ? "has been submitted for a student grant"
    : "has been locked";

  return (
    <div className={bottomMargin}>
      {(isGeneratedFromEvent || isSubmittedForGrant) && (
        <Typography variant="body2" style={{ color: "#747579" }}>
          This interaction {isGeneratedFromEvent && generatedFromEventText}
          {isGeneratedFromEvent && isSubmittedForGrant && " and "}
          {isSubmittedForGrant && submittedForGrantText}; therefore, some fields
          cannot be edited.
        </Typography>
      )}

      {!isEditingInteraction && (
        <Typography variant="body2" style={{ color: "#747579" }}>
          The following interactions are imported once daily:
          <br />
          JewishU Course Attendance, Your Israel Course Attendance, Pegisha,
          RSVP Events (Registered students are imported on the day following the
          event)
        </Typography>
      )}

      {showConfirmChangesWillBeLostModal && (
        <ConfirmationModal
          show={true}
          message="Your changes have not been saved. Are you sure you want to leave this page?"
          confirm={() => {
            setShowConfirmChangesWillBeLostModal(false);
            navigateToEvent();
          }}
          cancel={() => setShowConfirmChangesWillBeLostModal(false)}
        />
      )}
    </div>
  );
}

export default memo(StudentInteractionHeaderText);
