export const ResourceStates = {
  Available: "Available",
  Unavailable: "Unavailable",
};

export const RsvpRegistrationStatuses = {
  Registered: "Registered",
  Waitlisted: "Waitlisted",
  PendingPayment: "PendingPayment",
};

export const REMINDER_MINUTE_VALUE_LIST = [
  { label: "", value: "" },
  { label: "1/2 hour", value: 30 },
  { label: "1 hour", value: 60 },
  { label: "2 hours", value: 120 },
  { label: "3 hours", value: 180 },
  { label: "4 hours", value: 240 },
  { label: "5 hours", value: 300 },
  { label: "6 hours", value: 360 },
  { label: "12 hours", value: 720 },
  { label: "15 hours", value: 900 },
  { label: "24 hours", value: 1440 },
  { label: "36 hours", value: 2160 },
  { label: "48 hours", value: 2880 },
  { label: "60 hours", value: 3600 },
  { label: "72 hours", value: 4320 },
  { label: "4 days", value: 5760 },
  { label: "5 days", value: 7200 },
  { label: "6 days", value: 8640 },
  { label: "1 week", value: 10080 },
  { label: "2 weeks", value: 20160 },
  { label: "3 weeks", value: 30240 },
  { label: "30 days", value: 43200 },
];

export const DEFAULT_REGISTERED_STUDENTS_REMINDER_MINUTES = 720;
export const DEFAULT_UNREGISTERED_STUDENTS_REMINDER_MINUTES = 1440;

export const REMINDER_SETTING_TYPES = {
  global: "Global",
  custom: "Custom",
  none: "None",
};
