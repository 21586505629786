import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Loader from "../../../../components/Loader";
import {
  isDateEqual,
  isMobileView,
  isSmallTabletView,
  isTabletView,
} from "../../../../lib";
import { SystemSelectors } from "../../../../state";
import { palette } from "../shared";

const boxGlyph = <>&#9632;</>;

function formatData(data) {
  if (!data) return [];

  return data.map(({ monthYear, kpis }) => {
    // if category score is 0 set to a minimal number so that something shows up on the chart
    const formattedCategories = kpis.reduce(
      (obj, kpi) => ({ ...obj, [kpi.category]: kpi.score || 0.04 }),
      {},
    );

    const formattedData = {
      monthYear,
      ...formattedCategories,
    };

    return formattedData;
  });
}

export default function VibrancyMetricsBreakdownChart(props) {
  const { metrics, errorMessage, loading, getEngagementPeriodLabel } = props;

  const kpis = useSelector(SystemSelectors.engagementKpis);

  const [formattedData, setFormattedData] = useState([]);
  const [hoveredKpiId, setHoveredKpiId] = useState("");

  const [barSize, setBarSize] = useState(16);
  const [barGap, setBarGap] = useState(8);

  useEffect(
    function updateLocalData() {
      if (metrics) {
        // we are setting the data in local state per this issue where the bar labels do not appear on initial mount
        // when reading directly from props https://github.com/recharts/recharts/issues/829
        const _formattedData = formatData(metrics);
        setFormattedData(_formattedData);

        const lotsOfDataCutoff = isMobileView()
          ? 0
          : isSmallTabletView()
          ? 3
          : isTabletView()
          ? 4
          : 5;
        const hasLotsOfData = _formattedData.length >= lotsOfDataCutoff;

        setBarSize(hasLotsOfData ? 12 : 16);
        setBarGap(hasLotsOfData ? 6 : 8);
      }
    },
    [metrics],
  );

  const getKpiColor = useCallback(
    (kpiId) => {
      const index = kpis.findIndex(({ enumValue }) => enumValue === kpiId);
      return palette[index] || {};
    },
    [kpis],
  );

  const getKpiLabel = useCallback(
    (kpiId) => kpis.find(({ enumValue }) => enumValue === kpiId).displayValue,
    [kpis],
  );

  const formatTickX = useCallback(
    (key) => getEngagementPeriodLabel(key),
    [getEngagementPeriodLabel],
  );

  const formatLegend = useCallback(
    (id) => <span style={{ color: "#747579" }}>{getKpiLabel(id)}</span>,
    [getKpiLabel],
  );

  const CustomTooltip = (tooltipData) => {
    if (!hoveredKpiId || !tooltipData?.label) {
      return "";
    }

    const { formattedValue, score: categoryScore } = metrics
      .find((m) => isDateEqual(m.monthYear, tooltipData.label))
      .kpis.find((c) => c.category === hoveredKpiId);

    const color = getKpiColor(hoveredKpiId).dark;

    return (
      <div className="engagement-metrics-chart-tooltip">
        <p className="fw-900">{categoryScore}</p>
        <p className="flex flex-align-center">
          <span className="colored-box" style={{ color }}>
            {boxGlyph}
          </span>
          {getKpiLabel(hoveredKpiId)}
        </p>
        <p className="accent-text">{formattedValue}</p>
      </div>
    );
  };

  const content = () => {
    if (loading) {
      return <Loader />;
    }

    if (errorMessage) {
      return (
        <p className="error-text small-text text-center">{errorMessage}</p>
      );
    }

    if (!formattedData.length) {
      return (
        <div className="chart-no-results">
          <img src="/images/no_results.svg" alt="no results" height="120" />
          <p className="accent-text mt-8">No vibrancy metrics found</p>
        </div>
      );
    }

    return (
      <ResponsiveContainer height={375} width="94%">
        <BarChart
          barSize={barSize}
          barGap={barGap}
          data={formattedData}
          layout="horizontal"
          style={isMobileView() ? { left: -20 } : {}}
        >
          <XAxis
            dataKey="monthYear"
            tickLine={false}
            tickMargin={10}
            tickFormatter={formatTickX}
            type="category"
          />

          <YAxis
            domain={[0, 2]}
            tickCount={11} // display ticks for every .2
            tickLine={false}
            tickMargin={10}
            type="number"
          />

          {/* show gridlines */}
          <CartesianGrid vertical={false} />

          <Tooltip
            content={<CustomTooltip />}
            cursor={false} // don't shade background of bar group when displaying tooltip
          />

          {/* one bar per kpi per engagement period */}
          {kpis?.map(({ enumValue }, index) => (
              <Bar
                key={index}
                dataKey={enumValue}
                fill={getKpiColor(enumValue).dark}
                radius={[3, 3, 0, 0]} // round the corners
                onMouseOver={() => setHoveredKpiId(enumValue)}
                onMouseOut={() => setHoveredKpiId("")}
              />
            ))}

          <Legend
            layout="horizontal"
            verticalAlign="bottom"
            wrapperStyle={
              isMobileView()
                ? { bottom: -25, left: 25 }
                : { bottom: -25, left: 65 }
            }
            iconType="square"
            iconSize={16}
            formatter={formatLegend}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="mt-24">
      <div className="mt-16 mb-16">{content()}</div>
    </div>
  );
}
