import React, { memo, useState } from "react";
import { useSelector } from "react-redux";

import Modal from "../../components/Modal";
import Dropzone from "react-dropzone";
import Select from "react-select";

import { AuthSelectors } from "../../state";

function StudentsImportModal(props) {
  const {
    close: onClose,
    importStudents,
    reloadStudents,
    show,
    studentCampuses: {
      data: campuses = [],
      errorMessage: campusesErrorMessage,
      loading: loadingCampuses,
    },
  } = props;

  const [campusId, setCampusId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [fileForUpload, setFileForUpload] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);
  const importStudentsTemplateURL = isShliach
    ? "https://cocistorage.blob.core.windows.net/templates/Student-Import-Template.xlsx"
    : "https://cocistorage.blob.core.windows.net/templates/Student-Import-Basic-Template.xlsx";

  const selectFile = (acceptedFiles) => {
    if (acceptedFiles.length) {
      setErrorMessage("");
      setFileForUpload(acceptedFiles[0]);
    }
  };

  const uploadStudents = async (campusId) => {
    setErrorMessage("");
    setSubmitAttempted(true);

    if (!campusId || !fileForUpload) {
      return;
    }

    setLoading(true);

    const { success, errorMessage } = await importStudents(
      fileForUpload,
      campusId,
    );

    if (success) {
      reloadStudents();
      onClose();
    } else {
      setErrorMessage(errorMessage);
      setLoading(false);
    }
  };

  const campusOptions = campuses
    ? campuses.map((c) => ({
        label: c.name,
        value: c.id,
      }))
    : [];

  return (
    <Modal show={show}>
      <div className="modal-container" key="bulkImportModal">
        <div
          className="modal-card card import-modal"
          style={{ maxHeight: "96vh", overflow: "auto" }}
        >
          <p className="xl-text fw-700">Import Students</p>

          <p className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
            Use this feature to upload a spreadsheet of multiple students.
          </p>
          <p className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
            For this import only first name and last name are required, the rest
            of the information is optional.
          </p>
          <p className="accent-text-dark" style={{ lineHeight: "1.5" }}>
            Although not required for the import, we strongly encourage you to
            put in an email and phone number for data accuracy purposes.
          </p>
          <p className="error-text" style={{ lineHeight: "1.5" }}>
            Any student account imported here will be visible to Shluchim only
            and student will not be aware of their account and cannot register
            for events until they log in to the portal and finalize account
            creation.
          </p>
          <p className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
            Do you have the student import template? If not, you can{" "}
            <a download href={importStudentsTemplateURL} className="link-text">
              download the template here
            </a>
            .{" "}
            {isShliach &&
              "(This template contains sensitive information. Please be careful not to share it with interns.) "}
            When you’re ready, upload your file below.
          </p>
          <p className="accent-text mt-16" style={{ lineHeight: "1.5" }}>
            File must meet the following requirements before uploading: <br />
            * File must be saved in CSV or Excel format <br />
            * Delete any blank rows in your file <br />
            * Dates must be formatted with valid date formatting <br />* Please
            take notice of column header notes outlining column validation rules
          </p>

          <div className="mt-32">
            <p
              className={`fw-700 uppercase-text small-text accent-text-dark mb-8 ${
                submitAttempted && !campusOptions.length ? "error-text" : ""
              }`}
            >
              Select Campus for the students in the file
            </p>
            <Select
              className={`custom-select ${
                submitAttempted && !campusOptions.length ? "error" : ""
              }`}
              classNamePrefix="custom-select"
              isSearchable={true}
              onChange={({ value }) => setCampusId(value)}
              options={campusOptions}
              placeholder={
                loadingCampuses ? "Loading Campuses..." : "Select Campus..."
              }
              value={
                campusId
                  ? campusOptions.find(
                      (c) => c.value.toString() === campusId.toString(),
                    )
                  : campusOptions[0]
              }
            />
            {campusesErrorMessage && (
              <p className="small-text error-text mt-8">
                Something went wrong and campus options could not be retrieved.
                Refresh the page to try again.
              </p>
            )}
          </div>

          <div className="mt-24">
            <p
              className={`fw-700 uppercase-text small-text accent-text-dark mb-8 ${
                submitAttempted && !fileForUpload ? "error-text" : ""
              }`}
            >
              Select Student Import file
            </p>
            <div className="flex mb-24 flex-justify-space">
              <Dropzone
                // accept={'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                className="dropzone"
                onDrop={selectFile}
              >
                {({ getRootProps, getInputProps }) => (
                  <p
                    {...getRootProps()}
                    className="uppercase-text fw-500 link-text"
                  >
                    <input {...getInputProps()} />
                    {fileForUpload ? "Change " : "Select"} File
                  </p>
                )}
              </Dropzone>
              {fileForUpload && (
                <div className="uploading">
                  <div className="flex flex-justify-space flex-align-center">
                    <p className="fw-700 mt-8 flex flex-align-center">
                      <i className="material-icons accent-text mr-8">
                        description
                      </i>
                      {fileForUpload.name}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="students-import-modal-btns flex flex-justify-end flex-align-center mt-24">
            <button className="btn btn-light btn-medium" onClick={onClose}>
              Cancel
            </button>
            <button
              className="btn btn-accent btn-medium ml-16"
              disabled={loading || !!campusesErrorMessage}
              onClick={() =>
                uploadStudents(
                  campusOptions.length
                    ? campusId || campusOptions[0].value
                    : null,
                )
              }
            >
              {!loading ? "Upload Students" : "Uploading..."}
            </button>
          </div>
          {submitAttempted && errorMessage && (
            <div className="text-right mt-4">
              <span className="small-text error-text">{errorMessage}</span>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default memo(StudentsImportModal);
