import React from "react";
import EventSchedulesListActions from "./EventScheduleListActions";
import RsvpListFilters from "../../shared/list/RsvpListFilters";

export default function EventSchedulesListFilters(props) {
  const {
    chabadHouseName,
    getEventSchedules,
    hasScheduledEvents,
    pageRoute,
    rsvpEnrollmentID,
  } = props;

  const soOptions = [
    { label: "Date", value: "date" },
    { label: "Name", value: "name" },
  ];

  const ocOptions = [
    { label: "Upcoming", value: "upcoming" },
    { label: "Completed", value: "completed" },
  ];

  return (
    <RsvpListFilters
      rsvpType="event"
      chabadHouseName={chabadHouseName}
      getRsvpItems={getEventSchedules}
      hasItems={hasScheduledEvents}
      pageRoute={pageRoute}
      soOptions={soOptions}
      ocOptions={ocOptions}
      actionsComponent={
        <EventSchedulesListActions
          hasScheduledEvents={hasScheduledEvents}
          rsvpEnrollmentID={rsvpEnrollmentID}
          pageRoute={pageRoute}
        />
      }
    />
  );
}
