import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getEngagementEmailPreferences,
  submitEngagementEmailPreferences,
} from "../../state/engagement/EngagementApi";
import _isEqual from "lodash.isequal";
import { Button, makeStyles } from "@material-ui/core";
import EmailRecipientsSection from "../../components/EmailRecipientsSection";
import Loader from "../../components/Loader";
import { IsApiErrorStatus, withAppInsights } from "../../lib";
import NoAccess from "../shared/NoAccess";

export interface Preferences {
  chabadHouseID: number | null;
  emailRecipients: any;
}

const initialPreferences = {
  chabadHouseID: null,
  emailRecipients: [],
};

const EngagementEmailPreferencesPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [invalidEntryError, setInvalidEntryError] = useState("");
  const [preferences, setPreferences] =
    useState<Preferences>(initialPreferences);
  const [hasChanges, setHasChanges] = useState(false);
  const [hasAccess, setHasAccess] = useState(true);

  const originalPreferences = useRef<Preferences>();

  const { chabadHouseID, emailRecipients } = preferences;

  useEffect(() => {
    (async function () {
      setLoading(true);
      const { data, error } = await getEngagementEmailPreferences();

      if (error && IsApiErrorStatus(error, 403)) {
        setHasAccess(false);
      } else if (!error && data) {
        setHasChanges(false);
        setPreferences(data as Preferences);
        originalPreferences.current = data as Preferences;
      }
      setLoading(false);
    })();
  }, [dispatch]);

  const onCancel = useCallback(() => {
    setHasChanges(false);
    setPreferences(originalPreferences.current as Preferences);
  }, []);

  const handleChange = useCallback(
    (field: keyof Preferences, value: any) => {
      const fieldChanged = !_isEqual(preferences[field], value);
      setHasChanges(fieldChanged);
      setPreferences((prev) => {
        return {
          ...prev,
          [field]: value,
        };
      });
    },
    [preferences],
  );

  const handleEmailRecipientChange = useCallback(
    (newEmailRecipients) => {
      handleChange("emailRecipients", newEmailRecipients);
    },
    [handleChange],
  );

  const validate = useCallback(() => {
    if (!emailRecipients.length) {
      setSubmitting(false);
      setInvalidEntryError("Please correct any invalid or missing entries.");
      return false;
    }
    return true;
  }, [emailRecipients]);

  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    setSubmitAttempted(true);
    setInvalidEntryError("");

    const isValid = validate();
    if (isValid) {
      await submitEngagementEmailPreferences(preferences);
    }
    setSubmitting(false);
    setHasChanges(false);
  }, [preferences, validate]);

  return (
    <div className={classes.layout}>
      {loading ? (
        <Loader />
      ) : !hasAccess ? (
        <NoAccess systemName="the Engagement Portal" />
      ) : (
        <>
          <h2 className={classes.title}>Settings</h2>
          <EmailRecipientsSection
            chabadHouseID={chabadHouseID}
            emailRecipients={emailRecipients || []}
            formSectionClassName={classes.formSection}
            onChange={handleEmailRecipientChange}
            submitAttempted={submitAttempted}
          />
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              disabled={!hasChanges}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="ml-16"
              disabled={submitting || !hasChanges}
              onClick={onSubmit}
            >
              {submitting ? "Saving..." : "Save"}
            </Button>
          </div>
          {submitAttempted && !submitting && invalidEntryError && (
            <p className="error-message">{invalidEntryError}</p>
          )}
        </>
      )}
    </div>
  );
};

export default memo(withAppInsights(EngagementEmailPreferencesPage));

const useStyles = makeStyles((theme) => ({
  layout: {
    boxShadow: "none",
    border: "none",
    padding: "24px 24px 90px 24px",
    minHeight: "calc(100vh - 72px)",
    maxWidth: "unset",
    [theme.breakpoints.only("xs")]: {
      padding: "16px 16px 90px 16px",
    },
  },
  title: {
    color: "#1E1C1C",
    fontFamily: "Archivo",
    fontSize: 20,
    fontWeight: 800,
    letterSpacing: 0,
    lineHeight: "22px",
    marginBottom: 24,
  },
  formSection: {
    maxWidth: 1040,
    borderTop: "none",
    paddingTop: 0,
    [theme.breakpoints.only("xs")]: {
      maxWidth: "100%",
    },
  },
  buttonContainer: {
    paddingTop: 40,
    paddingBottom: 8,
  },
}));
