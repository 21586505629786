import React, { useState } from "react";
import { getGrantsRequirements } from "../../LamplightersGrantsRequirements.js";
import { Popover } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: 16,
    fontSize: 12,
    lineHeight: 1.5,
    color: "#fff",
    backgroundColor: "#424242",
  },
}));

export default function StudentsListHeader({
  allGrants,
  grantsForDisplay,
  programEndDate,
  programStartDate,
}) {
  const classes = useStyles();
  const [popovers, setPopovers] = useState(
    grantsForDisplay.reduce((obj, grant) => {
      return { ...obj, [grant.name]: false };
    }, {}),
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event, grantName) => {
    setAnchorEl(event.currentTarget);
    setPopovers((p) => ({ ...p, [grantName]: true }));
  };

  const handlePopoverClose = (grantName) => {
    setAnchorEl(null);
    setPopovers((p) => ({ ...p, [grantName]: false }));
  };
  const columns = grantsForDisplay.map(() => "220px");
  return (
    <div
      className="paginated-table-header-row students-table-row lamplighters-students-table-row"
      style={{ gridTemplateColumns: `130px ${columns.join(" ")}` }}
    >
      <p className="fixed-column">Student</p>
      {grantsForDisplay &&
        grantsForDisplay.map((grant) => (
          <div key={grant.id}>
            <p className="flex flex-align-center">
              {grant.name}
              <span
                className="flex flex-align-center pointer"
                onMouseEnter={(e) => handlePopoverOpen(e, grant.name)}
                onMouseLeave={() => handlePopoverClose(grant.name)}
                aria-owns={popovers[grant.name] ? grant.name : undefined}
                aria-haspopup="true"
              >
                <i className="material-icons medium-text accent-text-secondary ml-8">
                  info
                </i>
                <Popover
                  key={grant.name}
                  id={grant.name}
                  className={classes.popover}
                  classes={{
                    paper: classes.paper,
                  }}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={popovers[grant.name]}
                  onClose={() => handlePopoverClose(grant.name)}
                  disableRestoreFocus
                >
                  <p>
                    <p className="fw-700">
                      {grant.name} Qualification Requirements:{" "}
                    </p>
                    {getGrantsRequirements(
                      { grants: allGrants, programEndDate, programStartDate },
                      grant.id,
                    ).map((r, i) => (
                      <span className="block" key={i}>
                        &bull; {r}
                      </span>
                    ))}
                  </p>
                </Popover>
              </span>

              <span className="tooltip line-height-double">
                {grant.name} Qualification Requirements:
                <br />
                {getGrantsRequirements(
                  { grants: allGrants, programEndDate, programStartDate },
                  grant.id,
                ).map((r, i) => (
                  <span className="block" key={i}>
                    &bull; {r}
                  </span>
                ))}
              </span>
            </p>
          </div>
        ))}
    </div>
  );
}
