import { ChabadHousesActions } from "./actions";

export const ChabadHousesState = {
  name: "chabadHouses",
  persist: false,
  defaults: {
    loading: false,
  },
  handlers: {
    [ChabadHousesActions.SET_CHABAD_HOUSES_LOADING]:
      function setChabadHousesLoading(state, action) {
        return {
          ...state,
          loading: !!action.payload,
        };
      },
    [ChabadHousesActions.UPDATE_CHABAD_HOUSES]: function updateChabadHouses(
      state,
      action,
    ) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
