import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AuthActions } from "../../../state/auth/actions";
import { StudentsActions } from "../../../state/students/actions";
import StudentInteractionModal from "./StudentInteractionModal";
import { RsvpActions } from "../../../state";

export default connect(
  function mapState(state) {
    return {
      ...state.students,
      auth: state.auth,
      shliachID: state.auth.shliachID,
      sys: state.sys,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...StudentsActions,
          ...RsvpActions,
          ...AuthActions,
        },
        dispatch,
      ),
    };
  },
)(StudentInteractionModal);
