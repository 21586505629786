import React, { memo } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import StudentDetails from "../../../students/student/StudentDetails.vm";
import { DialogHeader } from "../../../../components";

function StudentDetailsModal(props) {
  const { close, isStudentsByCourseView, pageRoute, show } = props;

  return (
    <Dialog open={show} fullWidth maxWidth="md">
      <DialogHeader handleClose={close} />
      <DialogContent>
        <StudentDetails
          isStudentsByCourseView={isStudentsByCourseView}
          pageRoute={pageRoute}
          showEduTabs={true}
        />
      </DialogContent>
    </Dialog>
  );
}

export default memo(StudentDetailsModal);
