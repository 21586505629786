import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    width: "100%",
    height: "100vh",
  },
  contentContainer: {
    overflow: "auto",
    paddingBottom: 40,
    paddingRight: 40,
    paddingLeft: 20,
    paddingTop: "calc(72px + 40px)", // appBar height + regular padding
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      paddingTop: 56, // mobile has a smaller app bar
    },
  },
}));
