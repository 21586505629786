import MobileDetect from "mobile-detect";

const mobileDetect = new MobileDetect(window.navigator.userAgent);
const isPhone = !!mobileDetect.phone();
const isTablet = !!mobileDetect.tablet();
const iOSVersion = mobileDetect.version("iOS");
const mobileOperatingSystem = mobileDetect.os();
const mobileUserAgent = mobileDetect.userAgent();

export const UIState = {
  name: "ui",
  persist: false,
  defaults: {
    isPhone,
    isTablet,
    iOSVersion,
    mobileOperatingSystem,
    mobileUserAgent,
  },
};
