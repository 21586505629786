import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  InputLabel,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  CheckboxInput,
  FormFieldWatch,
  FormFileUploadField,
  FormSelectField,
  FormTextField,
  getSetFieldValueOptions,
} from "../../../../../components/react-hook-form";
import { ChabadHouseInfoProps } from "./ChabadHouseInfo";
import {
  FormUploadContainerStyled,
  ProfileFormGridItemStyled,
  ProfileFormGridStyled,
  ProfileFormInfoIcon,
  ProfileFormGridHalfRowItemStyled,
} from "../../ProfileForm.styles";
import { SystemSelectors } from "../../../../../state";
import { PROFILE_PLACEHOLDER_URL, slugify } from "../../../../../lib";
import { ChipStyled } from "../../../../../components";
import { ChabadHouse, ShliachProfile } from "../../ProfileFormHelpers";
import { UseFormReturn } from "react-hook-form";
import { useMobile } from "../../../../../themes";
import ConfirmationModal from "../../../../../components/ConfirmationModal";

export const ChabadHouseGeneralInfo = React.memo(
  /**
   *
   */
  function ChabadHouseGeneralInfo(props: ChabadHouseInfoProps) {
    const { chabadHouseIndex, form, initialFormValues } = props;

    const chabadHouseFieldsAccessor = `chabadHouses.${chabadHouseIndex}`;

    const initialChabadHouseProfile =
      initialFormValues.chabadHouses[chabadHouseIndex];

    const regions = useSelector(SystemSelectors.regions);

    const [showSlugChangeWarningPrompt, setShowSlugChangeWarningPrompt] =
      useState(false);

    const handleChangeSlug = useCallback(
      (value: string, blurred = false) => {
        form.setValue(
          `chabadHouses.${chabadHouseIndex}.slug`,
          slugify(value, blurred), // trim trailing slashes on blur
          getSetFieldValueOptions(form),
        );

        if (blurred && value !== initialChabadHouseProfile.slug) {
          setShowSlugChangeWarningPrompt(true);
        }
      },
      [chabadHouseIndex, form, initialChabadHouseProfile.slug],
    );

    const isMobile = useMobile();

    return (
      <ProfileFormGridStyled
        style={{ marginTop: "24px", marginBottom: "42px" }}
      >
        <ProfileFormGridHalfRowItemStyled>
          {/* TODO: upload image to storage UPON SUBMISSION */}
          <FormFileUploadField
            image={true}
            name={`${chabadHouseFieldsAccessor}.logoURL`}
            uploadComponent={(fileOrUrl) => (
              <FormUploadContainerStyled>
                <Avatar
                  alt="logo"
                  src={
                    !fileOrUrl
                      ? PROFILE_PLACEHOLDER_URL
                      : typeof fileOrUrl === "string"
                      ? fileOrUrl
                      : fileOrUrl.previewUrl
                  }
                />
                <Typography variant="body2">
                  {fileOrUrl ? "Change" : "Upload"} logo
                </Typography>
              </FormUploadContainerStyled>
            )}
          />
        </ProfileFormGridHalfRowItemStyled>
        <ProfileFormGridHalfRowItemStyled>
          {/* TODO: upload image to storage UPON SUBMISSION */}
          <FormFileUploadField
            image={true}
            name={`${chabadHouseFieldsAccessor}.buildingImageURL`}
            uploadComponent={(fileOrUrl) => (
              <FormUploadContainerStyled>
                <Avatar
                  alt="logo"
                  src={
                    !fileOrUrl
                      ? PROFILE_PLACEHOLDER_URL
                      : typeof fileOrUrl === "string"
                      ? fileOrUrl
                      : fileOrUrl.previewUrl
                  }
                />
                <Typography variant="body2">
                  {fileOrUrl ? "Change" : "Upload"} building image
                </Typography>
              </FormUploadContainerStyled>
            )}
          />
        </ProfileFormGridHalfRowItemStyled>
        <FormFieldWatch
          name={[
            "person.outreach.demographics",
            "spouse.outreach.demographics",
          ]}
        >
          {([shliachDemographics, spouseDemographics]) => {
            const demographics = Array.from(
              new Set([...shliachDemographics, ...spouseDemographics]),
            );
            return (
              <ProfileFormGridItemStyled>
                <InputLabel>Demographics served</InputLabel>
                <Box>
                  {demographics.map((d: string, i: number) => (
                    <ChipStyled key={i} label={d} />
                  ))}
                </Box>
              </ProfileFormGridItemStyled>
            );
          }}
        </FormFieldWatch>

        <ProfileFormGridItemStyled sm={8}>
          <FormSelectField
            label="Region"
            name={`${chabadHouseFieldsAccessor}.regionID`}
            options={regions.map((r: any) => ({
              id: r.id,
              name: r.name,
            }))}
          />
        </ProfileFormGridItemStyled>
        <ProfileFormGridItemStyled>
          <FormTextField
            label={
              <>
                URL friendly name
                <Tooltip title="This is a URL friendly version of the Chabad House Name for use in web page links">
                  <ProfileFormInfoIcon />
                </Tooltip>
              </>
            }
            name={`${chabadHouseFieldsAccessor}.slug`}
            // slugify the value on blur
            onBlur={(e) => handleChangeSlug(e.target.value, true)}
            // slugify the value on change (override the default change handler)
            onChange={(e) => handleChangeSlug(e.target.value)}
          />
          <FormFieldWatch name={`${chabadHouseFieldsAccessor}.slug`}>
            {(slug) =>
              slug !== initialChabadHouseProfile.slug ? (
                <Typography variant="caption" color="error">
                  Any changes made to this field will affect existing web page
                  links already generated eg. RSVP, Grand Draw, etc. Please
                  update any existing web pages or links.
                </Typography>
              ) : null
            }
          </FormFieldWatch>
          {showSlugChangeWarningPrompt && (
            <ConfirmationModal
              cancel={() => {
                handleChangeSlug(initialChabadHouseProfile.slug as string);
                setShowSlugChangeWarningPrompt(false);
              }}
              cancelText="Discard this change"
              confirm={() => setShowSlugChangeWarningPrompt(false)}
              message={`Changes to the URL friendly name will affect existing web page links already generated eg. RSVP, Grand Draw, etc. Please update any existing web pages or links.\n\nChanges to this field will also require all students that are logged in to the Student app or web portal to refresh their login by logging out of their account. Failure to do so will cause the app to error. If you wish proceed with this change, please notify your students accordingly.`}
              show={showSlugChangeWarningPrompt}
              title={
                <span className="flex flex-align-center">
                  <i
                    className="material-icons mr-8"
                    style={{ color: "#eed202" }}
                  >
                    warning
                  </i>
                  URL friendly name change
                </span>
              }
            />
          )}
        </ProfileFormGridItemStyled>
        <ProfileFormGridItemStyled>
          <FormTextField
            label="Website"
            name={`${chabadHouseFieldsAccessor}.websiteURL`}
          />
        </ProfileFormGridItemStyled>
        <ProfileFormGridItemStyled>
          <FormTextField
            label="Website donation URL"
            name={`${chabadHouseFieldsAccessor}.donateURL`}
          />
        </ProfileFormGridItemStyled>
        <ProfileFormGridHalfRowItemStyled>
          <FormTextField
            label={
              <>
                Chabad house legal name
                <Tooltip
                  enterTouchDelay={0}
                  title="Chabad house legal name will not be displayed on the public directory"
                >
                  <ProfileFormInfoIcon
                    style={{ marginRight: isMobile ? undefined : 32 }}
                  />
                </Tooltip>
                {!isMobile && (
                  <ChabadHouseLegalNameCheckbox
                    initialChabadHouseProfile={initialChabadHouseProfile}
                    chabadHouseIndex={chabadHouseIndex}
                    form={form}
                  />
                )}
              </>
            }
            name={`${chabadHouseFieldsAccessor}.legalName`}
          />
        </ProfileFormGridHalfRowItemStyled>
        {isMobile && (
          <ProfileFormGridItemStyled style={{ paddingTop: 0 }}>
            <ChabadHouseLegalNameCheckbox
              initialChabadHouseProfile={initialChabadHouseProfile}
              chabadHouseIndex={chabadHouseIndex}
              form={form}
            />
          </ProfileFormGridItemStyled>
        )}
      </ProfileFormGridStyled>
    );
  },
);

interface ChabadHouseLegalNameCheckboxProps {
  initialChabadHouseProfile: ChabadHouse;
  chabadHouseIndex: number;
  form: UseFormReturn<ShliachProfile>;
}

function ChabadHouseLegalNameCheckbox(
  props: ChabadHouseLegalNameCheckboxProps,
) {
  const [chabadHouseLegalNameMatchesName, setChabadHouseLegalNameMatchesName] =
    useState(false);

  const { initialChabadHouseProfile, chabadHouseIndex, form } = props;
  return (
    <CheckboxInput
      label="Same as Chabad house name"
      name="legalNameCheckbox"
      checked={chabadHouseLegalNameMatchesName}
      onChange={(_, value) => {
        setChabadHouseLegalNameMatchesName(value);
        form.setValue(
          `chabadHouses.${chabadHouseIndex}.legalName`,
          value ? initialChabadHouseProfile.name : "",
          getSetFieldValueOptions(form),
        );
      }}
    />
  );
}
