import React from "react";

import AccountInfoHeader from "../../components/AccountInfoHeader";
import TripEnrollmentForm from "./TripEnrollmentForm";
import TripSidebar from "./sidebar/TripSidebar";
import StripeProvider from "../../components/StripeProvider";

const { REACT_APP_STRIPE_API_KEY } = process.env;

export default function TripEnrollmentFormContainer(props) {
  const { account, event } = props;
  return (
    <React.Fragment>
      <div className="container">
        <p className="xxl-text fw-700 mb-32 trip-enrollment-title">
          Enroll in {event.programScheduleName}
        </p>
      </div>
      <AccountInfoHeader profile={account} />
      <div className="flex tablet-block mobile-block container trip-form-cards-container">
        {!event.isTravelTrip && (
          <TripSidebar
            getHistoricTripEnrollments={
              props.actions.getHistoricTripEnrollments
            }
            getHistoricTrip={props.actions.getHistoricTrip}
            getTripSchedule={props.actions.getTripSchedule}
            pageRoute={props.pageRoute}
            shliachID={props.shliachID}
            trip={props.trip}
            {...props}
          />
        )}
        <StripeProvider apiKey={REACT_APP_STRIPE_API_KEY}>
          <TripEnrollmentForm
            {...props}
            onEnrollmentSubmitted={props.setEnrollmentSubmitted}
          />
        </StripeProvider>
      </div>
    </React.Fragment>
  );
}
