import React from "react";
import AddStudentButton from "./AddStudentButton";
import MessageStudentsButton from "../../../shared/manage/students/MessageStudentsButton";
import RegisterStudentModal from "./registerStudent/RegisterStudentModal";
import EventsStudentsExportButton from "./EventsStudentsExportButton";
import StudentsList from "../../../shared/manage/students/StudentsList";
import StudentsListFilters from "../../../shared/manage/students/StudentsListFilters";
import EventsStudentsListHeader from "./EventsStudentsListHeader";
import EventsStudentsListRow from "./EventsStudentsListRow";
import {
  AuthRequest,
  AuthTypes,
  canManageStudents,
  isMobileView,
} from "../../../../../../lib";
import queryString from "query-string";
import { RsvpRegistrationStatuses } from "../../../../RsvpHelpers";

export default class EventsStudentsListContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: isMobileView(),
      mobileMenuOpen: false,
    };

    this.isShliach = props.auth.loggedInAs === AuthTypes.shliach;
    this.allowCreateNewStudent = canManageStudents(props.auth);
  }

  filterSortOptions = [
    { label: "Name", value: "Name" },
    { label: "Registration Date Desc", value: "RegistrationDateDesc" },
    { label: "Registration Date Asc", value: "RegistrationDateAsc" },
  ];

  getStudentRegistrations = (page, resultsView) => {
    //page &resultsView sent with loadData from paginatedTable must override pageRoute query params, because data is fetched prior to params being refreshed
    const {
      pageRoute: {
        query: { p = 1, r = 10 },
      },
    } = this.props;

    this.props.actions.getRsvpEventRegistrations(
      page || p,
      resultsView || r,
      this.getListFilters(),
    );
  };

  getListFilters = () => {
    const {
      pageRoute: {
        query: {
          se,
          st,
          // events and resources have different sort option defaults; explicitly set a default here so BE and FE are in sync
          so = this.filterSortOptions[0].value,
        },
        params: { rsvpItemId },
      },
    } = this.props;

    return {
      schedule: rsvpItemId,
      [this.studentsFilterDefinitions.se]: se && decodeURIComponent(se),
      [this.studentsFilterDefinitions.st]: st,
      [this.studentsFilterDefinitions.so]: so,
    };
  };

  getStudentRegistrationsForAction = async (
    rsvpEnrollmentId,
    registeredOnly,
    forMessaging,
  ) => {
    const { schedule: eventScheduleId, search, sortBy } = this.getListFilters();
    const params = {
      eventScheduleId,
      export: true,
      text: search,
      forMessaging,
      sortBy,
    };
    if (registeredOnly) {
      //exclude waitlisted registrations
      params.status = RsvpRegistrationStatuses.Registered;
    }

    const response = await AuthRequest.get(
      `RsvpEnrollments/${rsvpEnrollmentId}/events/registrations?${queryString.stringify(
        params,
      )}`,
    );
    return response.data.payload && response.data.payload.results;
  };

  onResizeWindow = () => {
    let isMobile = this.isMobileView();
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile });
    }
  };

  studentsFilterDefinitions = {
    p: "page",
    r: "resultsView",
    se: "search",
    st: "status",
    so: "sortBy",
  };

  render() {
    const { isMobile, mobileMenuOpen } = this.state;
    const {
      actions,
      enrollment: {
        data: {
          id: rsvpEnrollmentID,
          forChabadHouseID: rsvpEnrollmentChabadHouseID,
        } = {},
      },
      eventSchedule: { data: eventSchedule = {} },
      eventScheduleFullName,
      pageRoute,
      studentRegistrations,
      refreshRsvpItem,
      removeStudentRegistration,
      submitStudentRegistration,
      sys: { rsvpRegistrationStatuses = [] },
      ui,
    } = this.props;

    const registerStudentModal = (
      <RegisterStudentModal
        allowCreateNewStudent={this.allowCreateNewStudent}
        doSubmitStudentRegistration={actions.submitRsvpStudentRegistration}
        eventScheduleID={eventSchedule.eventScheduleID}
        eventScheduleFullName={eventScheduleFullName}
        reloadStudents={() => {
          this.getStudentRegistrations(1);
          refreshRsvpItem();
        }}
        rsvpEnrollmentChabadHouseID={rsvpEnrollmentChabadHouseID}
        submitStudentRegistration={submitStudentRegistration}
      />
    );

    const studentActionButtons = (
      <React.Fragment>
        <AddStudentButton
          eventSchedule={eventSchedule}
          registerStudentModal={registerStudentModal}
        />
        {this.isShliach && (
          <MessageStudentsButton
            disabled={
              !studentRegistrations.data ||
              !studentRegistrations.data.numberOfRows
            }
            getStudents={() =>
              this.getStudentRegistrationsForAction(
                rsvpEnrollmentID,
                true,
                true,
              )
            }
            ui={ui}
          />
        )}
        <EventsStudentsExportButton
          className="btn btn-accent"
          disabled={
            !studentRegistrations.data ||
            !studentRegistrations.data.numberOfRows
          }
          eventSchedule={eventSchedule}
          getStudentRegistrations={() =>
            this.getStudentRegistrationsForAction(rsvpEnrollmentID)
          }
        />
      </React.Fragment>
    );

    return (
      <div className="flex tablet-block mobile-block flex-align-top">
        <div className="full-width">
          <div className="flex flex-align-center flex-justify-space mb-24">
            <h2 className="page-title mb-0">Registrations</h2>
            {!isMobile ? (
              <div className="flex flex-align-center">
                {studentActionButtons}
              </div>
            ) : (
              <div className="relative">
                <i
                  className="material-icons link-text"
                  onClick={() =>
                    this.setState({
                      mobileMenuOpen: !this.state.mobileMenuOpen,
                    })
                  }
                >
                  more_horiz
                </i>
                {mobileMenuOpen && (
                  <div className="dropdown-box open rsvp-student-actions-dropdown-box">
                    {studentActionButtons}
                  </div>
                )}
              </div>
            )}
          </div>
          <StudentsList
            showPaidDonated={
              !!(
                eventSchedule.registrationFeeAmount ||
                eventSchedule.askForDonation
              )
            }
            doRemoveStudentRegistration={actions.removeRsvpStudentRegistration}
            filterComponent={
              <StudentsListFilters
                registrationStatuses={rsvpRegistrationStatuses}
                getStudents={this.getStudentRegistrations}
                sortOptions={this.filterSortOptions}
                pageRoute={pageRoute}
              />
            }
            HeaderComponent={EventsStudentsListHeader}
            RowComponent={EventsStudentsListRow}
            getStudents={this.getStudentRegistrations}
            pageRoute={pageRoute}
            registerStudentModal={registerStudentModal}
            reloadStudents={() => {
              this.getStudentRegistrations(); //when reloading after removing student, get students at current page
              refreshRsvpItem();
            }}
            removeStudentRegistration={removeStudentRegistration}
            students={studentRegistrations}
            hasLinkedSubmittedInteraction={
              !!eventSchedule.hasLinkedSubmittedInteraction
            }
          />
        </div>
      </div>
    );
  }
}
