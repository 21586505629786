import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { FileForUpload } from "../../../lib/types";

type FormFileUploadFieldProps = {
  accept?: string;
  image?: boolean;
  name: string;
  onValueChange?: (value: string | FileForUpload) => void;
  uploadComponent: (value: string | FileForUpload) => React.ReactNode;
};

export const FormFileUploadField = React.memo(
  /**
   *
   */
  function FormFileUploadField({
    accept,
    image,
    name,
    onValueChange,
    uploadComponent,
  }: FormFileUploadFieldProps) {
    const { control } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => {
          const { onChange, value } = field;
          const { error } = fieldState;

          const onSelectFile = async (
            event: React.ChangeEvent<HTMLInputElement>,
          ) => {
            if (event.target.files?.length) {
              const file = event.target.files[0];

              const value = {
                file,
                previewUrl: URL.createObjectURL(file),
              };

              onChange({ target: { name, value } });
              onValueChange && onValueChange(value);
            }
          };

          return (
            <Box component="label">
              {uploadComponent(value)}
              <input
                type="file"
                accept={image ? "image/*" : accept}
                capture={image}
                onChange={onSelectFile}
                style={{
                  clip: "rect(0 0 0 0)",
                  clipPath: "inset(50%)",
                  height: 1,
                  overflow: "hidden",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  whiteSpace: "nowrap",
                  width: 1,
                }}
              />
              {error && (
                <Typography
                  variant="caption"
                  style={{
                    color: "#e94746",
                    display: "inline-block",
                    marginTop: "8px",
                  }}
                >
                  {error.message}
                </Typography>
              )}
            </Box>
          );
        }}
      />
    );
  },
);
