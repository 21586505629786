import { useCallback } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// first overload for thunk action creators
function useAction<
  T extends (
    ...args: any[]
  ) => (dispatch: any, getState: () => any, extraArgument: any) => any,
>(
  actionCreator: T,
  deps?: ReadonlyArray<unknown>,
): T extends (...args: infer A) => (...args: any[]) => infer R
  ? (...args: A) => R
  : never;
// second overload for regular action creators
function useAction<T extends (...args: any[]) => any>(
  actionCreator: T,
  deps?: ReadonlyArray<unknown>,
): T;
// lastly expect a regular action
function useAction<T extends { type: string }>(
  action: T,
  deps?: ReadonlyArray<unknown>,
): () => T;

/** Creates a callback function to dispatch the given action. */
function useAction<T extends Function>(action: T) {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      dispatch(action(...args));
    },
    [dispatch, action],
  );
}

export { useAction };
