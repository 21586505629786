import React from "react";
import moment from "moment";
import { Dialog, DialogContent } from "@material-ui/core";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import { DialogHeader } from "../../../../../../components";

export default class UpdateRecurringSettingsModal extends React.PureComponent {
  state = {
    updateSettings: false,
    wizardStep: 1,
  };

  onApplyRecurringUpdates = () => {
    const { customDaysOfWeek, initialSettings, settings } = this.props;

    let { daysOfWeek, registrationEndMinutes, timeMinutes, timeHours } =
      _cloneDeep(settings);

    const eventDateTime = moment(settings.eventDateTime);
    const initialEventDateTime = moment(initialSettings.eventDateTime);
    const registrationEndDateTime = moment(settings.registrationEndDateTime);

    if (settings.eventDateTime !== initialSettings.eventDateTime) {
      const eventDayOfWeek = eventDateTime.day();
      const initialEventDayOfWeek = initialEventDateTime.day();
      if (eventDayOfWeek !== initialEventDayOfWeek) {
        const prevDayOfWeek = (
          customDaysOfWeek.find(
            (dow) => dow.intValue === initialEventDayOfWeek,
          ) || {}
        ).enumValue;
        const newDayOfWeek = (
          customDaysOfWeek.find((dow) => dow.intValue === eventDayOfWeek) || {}
        ).enumValue;
        daysOfWeek = [
          ...daysOfWeek.filter((dow) => dow !== prevDayOfWeek),
          newDayOfWeek,
        ];
      }

      const eventTimeMinutes = eventDateTime.minutes();
      const initialEventTimeMinutes = initialEventDateTime.minutes();
      if (eventTimeMinutes !== initialEventTimeMinutes) {
        timeMinutes = eventTimeMinutes;
      }

      const eventTimeHours = eventDateTime.hours();
      const initialEventTimeHours = initialEventDateTime.hours();
      if (eventTimeHours !== initialEventTimeHours) {
        timeHours = eventTimeHours;
      }
    }

    if (
      settings.registrationEndDateTime !==
      initialSettings.registrationEndDateTime
    ) {
      registrationEndMinutes = eventDateTime.diff(
        registrationEndDateTime,
        "minutes",
      );
    }

    if (
      !_isEqual(
        {
          daysOfWeek,
          registrationEndMinutes,
          timeMinutes,
          timeHours,
        },
        {
          daysOfWeek: initialSettings.daysOfWeek,
          registrationEndMinutes: initialSettings.registrationEndMinutes,
          timeMinutes: initialSettings.timeMinutes,
          timeHours: initialSettings.timeHours,
        },
      )
    ) {
      this.props.onApplyRecurringDateChanges({
        daysOfWeek,
        registrationEndMinutes,
        timeMinutes,
        timeHours,
      });

      this.setState({
        updateSettings: true,
        wizardStep: 2,
      });
    } else {
      this.setState(
        {
          updateSettings: true,
        },
        this.onSubmit,
      );
    }
  };

  onCancel = () => {
    if (this.state.wizardStep > 1) {
      //reset updated recurring date settings
      const { initialSettings } = this.props;
      this.props.onApplyRecurringDateChanges({
        daysOfWeek: initialSettings.daysOfWeek,
        registrationEndMinutes: initialSettings.registrationEndMinutes,
        timeMinutes: initialSettings.timeMinutes,
        timeHours: initialSettings.timeHours,
      });
    }

    this.props.onCancel();
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.updateSettings);
  };

  render() {
    const { errorMessage, recurringDatesControls } = this.props;
    const { wizardStep } = this.state;

    return (
      <Dialog open={true} fullWidth maxWidth={wizardStep === 1 ? "sm" : "md"}>
        <DialogHeader
          title={`Update ${wizardStep === 1 ? "Recurring" : "Future"} Event`}
          handleClose={this.onCancel}
        />
        <DialogContent>
          {wizardStep === 1 ? (
            <div>
              <p className="line-height-double">
                The event that you are updating is a recurring event. Would you
                like to apply all current event settings to future events?
              </p>
              <div className="modal-btns mb-16 mr-8">
                <p className="link-text" onClick={this.onSubmit}>
                  <span className="block fw-700 uppercase-text mb-4">No</span>{" "}
                  <span className="small-text">Update just this event</span>
                </p>
                <p
                  className="link-text ml-40"
                  onClick={this.onApplyRecurringUpdates}
                >
                  <span className="block fw-700 uppercase-text mb-4">Yes</span>{" "}
                  <span className="small-text">
                    Apply all settings to future events
                  </span>
                </p>
              </div>
              {errorMessage && (
                <p className="error-text small-text text-right mt-8">
                  {errorMessage}
                </p>
              )}
            </div>
          ) : (
            <div className="mb-96">
              <p className="accent-text-dark line-height-double">
                Please review the schedule changes that will be applied to
                future events based on the current event schedule. You can
                adjust these settings for future events below.
              </p>
              <div className="program-form-section">
                {recurringDatesControls}
              </div>

              <div className="modal-btns mr-8">
                <p className="link-text uppercase-text" onClick={this.onCancel}>
                  Cancel
                </p>
                <p
                  className="link-text uppercase-text ml-40"
                  onClick={errorMessage ? null : this.onSubmit}
                >
                  Submit
                </p>
              </div>
              {errorMessage && (
                <p className="error-text small-text text-right mt-8">
                  {errorMessage}
                </p>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}
