import React from "react";
import { TextFieldProps } from "@material-ui/core";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { FormFieldProps } from "../form";
import { PhoneInput } from "../inputs";
/* @ts-ignore */
import get from "lodash.get";

type FormPhoneFieldProps = {
  countryIdFieldName: string;
  defaultCountryName?: string | null;
} & FormFieldProps &
  TextFieldProps;

export const FormPhoneField = React.memo(
  /**
   *
   */
  function FormPhoneField({
    countryIdFieldName,
    name,
    ...passProps
  }: FormPhoneFieldProps) {
    const { control, setValue } = useFormContext();

    const countryId = useWatch({ name: countryIdFieldName });

    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field, fieldState, formState }) => {
          const { onChange, ...fieldProps } = field;
          const countryIdFieldError = get(formState.errors, countryIdFieldName);
          const error = fieldState.error || countryIdFieldError;

          return (
            <PhoneInput
              {...fieldProps}
              id={name}
              {...(passProps as any)}
              countryId={countryId}
              onChange={(phone: any, countryId: any) => {
                setValue(countryIdFieldName, countryId, {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate:
                    formState.isSubmitted || !!countryIdFieldError,
                });
                onChange({ target: { name, value: phone } });
              }}
              {...(error
                ? {
                    error: true,
                    helperText: error.message,
                  }
                : {})}
            />
          );
        }}
      />
    );
  },
);
